/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import PermissionService from '../../../Services/PermissionService';
import { Container, Anchor, Form } from 'react-bootstrap';
import { DataGrid, Selection, Column, Sorting, Paging } from 'devextreme-react/data-grid';
import { ModalErrorMessage } from '../MessageBox/ModalErrorMessage';
import { Icon } from '@ailibs/feather-react-ts';
import { LoadPanel } from 'devextreme-react/load-panel';

const config = require("../../../config.json");

function TeamletHuddleHistory(props:
  {
    permissions: PermissionService;
    switchView: (newView: string) => void;
    huddleID: string;
    teamletID: string;
    teamletName: string;
    setHuddleID: (huddleID: string) => void;
    setMeetingID: (meetingID: string) => void;
    isMemberOfTeamLet: boolean
  }) {
  //  Error Modal
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [huddleSchedule, setHuddleSchedule] = useState<any[]>([]);
  const [rangeStart, setRangeStart] = useState('');
  const [rangeEnd, setRangeEnd] = useState('');
  const [historyCount, setHistoryCount] = useState(10);

  //  Spinner Icon location
  const position = { of: '#huddleScheduleGrid' };
  const [showProgress, setShowProgress] = useState(false);
  const [enableOlderButton, setEnableOlderButton] = useState(true);
  const [enableNewerButton, setEnableNewerButton] = useState(true);
  const [teamletHuddleArray, setTeamletHuddleArray] = useState<any[]>([]);
  const [fwdbck, setFwdbck] = useState('older');
  const [startHuddleID, setStartHuddleID] = useState('');
  const [inclusive, setInclusive] = useState(true);

  //  Get Teamlet Huddles
  const fetchTeamletHuddles = async () => {
    //  Abort controller to close long fetch calls
    const huddleScheduleController = new AbortController();
    const token = sessionStorage.getItem('ACTIVATEtoken');

    try {
      setShowProgress(false);

      //  Get Huddles
      await fetch(`${config.activateServer}:${config.activatePORT}/getHuddlesForTeamlet`, {
        method: 'POST',
        body: JSON.stringify({ token: token, teamletID: props.teamletID }),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        mode: 'cors'
      }).then(res => {
        if (!res.ok) {
          throw Error('Could not fetch the data for the resource');
        }
        return res.json();
      }).then(huddles => {

        const huddlesWithMeetings = huddles.filter((i: any) => { return (i.meetings.length > 0) }).sort(function (a: any, b: any) {
          // @ts-ignore
          return (new Date(a.meetings[0].meetingTime) - new Date(b.meetings[0].meetingTime));
        });


        const newArray = huddlesWithMeetings.sort(function (a: any, b: any) {
          // @ts-ignore
          return (new Date(a.meetings[0].meetingTime) - new Date(b.meetings[0].meetingTime));
        });

        setTeamletHuddleArray(newArray);

      }).catch(err => {
        if (err.name === 'AbortError') {
          //console.log('fetch aborted');
        } else {
          // TODO fill in
        }
      });

      setShowProgress(false);
    } catch (err) {
      setShowErrorModal(true);
      huddleScheduleController.abort();
      setShowProgress(false);
    }
  }

  //  Init
  // Step 1 - get the array of huddles for the teamlet and sort it in date order.  This will establish the newer/older requirements
  useEffect(() => {
    if (props.teamletID === '') {
      return;
    }
    setFwdbck('older');
    setInclusive(true);
    setStartHuddleID(props.huddleID);
    fetchTeamletHuddles().catch(console.error);
  }, [props.teamletID, props.huddleID]);

  // Step 2 - find out where the huddle is in the teamlet huddle array, fetch the next count, and set the paging buttons
  useEffect(() => {
    if (teamletHuddleArray.length === 0) {
      return;
    }

    const huddleindex = teamletHuddleArray.findIndex((k: any) => k.internalID === startHuddleID);
    if (huddleindex === -1) return;

    fetchHuddleSchedule(teamletHuddleArray[huddleindex].meetings[0].meetingTime, fwdbck, inclusive);
  }, [teamletHuddleArray, historyCount]);

  //  Get Huddle Count based on date
  const fetchHuddleSchedule = async (beginningrange: string, fwdbck: string, inclusive: boolean) => {

    //  Abort controller to close long fetch calls
    const huddleScheduleController = new AbortController();
    const token = sessionStorage.getItem('ACTIVATEtoken');
    let thishistoryCount = historyCount;

    fwdbck === 'older' ? thishistoryCount = (-1 * historyCount) : thishistoryCount = historyCount;

    let nextsetStart = beginningrange.slice(0, beginningrange.length - 1).concat('.000Z');

    try {
      setShowProgress(false);
      await fetch(`${config.activateServer}:${config.activatePORT}/getHuddleCountByTeamletID`, {
        method: 'POST',
        body: JSON.stringify({ token: token, teamID: props.teamletID, meetingcount: thishistoryCount, fromdate: nextsetStart, inclusive: inclusive }),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        mode: 'cors'
      }).then(res => {
        if (!res.ok) {
          throw Error('Could not fetch the data for the resource');
        }
        return res.json();
      }).then(huddles => {

        if (huddles.length === 0) {
          setHuddleSchedule(huddles);
          setEnableNewerButton(true);
          setEnableOlderButton(true);
          return;
        }
        let sortedHuddles = huddles.reverse();

        // find out where the index for this huddle is in the array of huddles for the teamlet
        // in setPagingButtons, Older and Newer will be enabled based on this index
        const teamlethuddleindex = teamletHuddleArray.findIndex((k: any) => k.internalID === huddles[0].huddle.internalID);

        setPagingButtons(huddles.length, teamlethuddleindex, fwdbck);

        setHuddleSchedule(() => { return (sortedHuddles) });
        fwdbck === "newer" ? setStartHuddleID(sortedHuddles[0].huddle.internalID) : setStartHuddleID(sortedHuddles[huddles.length - 1].huddle.internalID);
        setRangeStart(sortedHuddles[0].meetingTime);
        setRangeEnd(sortedHuddles[huddles.length - 1].meetingTime);
      }).catch(err => {
        if (err.name === 'AbortError') {
          //console.log('fetch aborted');
        } else {
          // TODO fill in
        }
      });

      setShowProgress(false);
    } catch (err) {
      setShowErrorModal(true);
      huddleScheduleController.abort();
      setShowProgress(false);
    }
  };


  function setPagingButtons(huddleslength: number, teamlethuddleindex: number, fwdbck: string) {
    // if there are no more huddles or if this is the first huddle in the teamlet huddles array
    // or if there is less than a page left and the user selects "older" disable the buttons

    if (huddleslength === 0) {
      setEnableOlderButton(true);
      setEnableNewerButton(true);
      return;
    }

    if (huddleslength === teamletHuddleArray.length) {
      setEnableOlderButton(false);
      setEnableNewerButton(false);
      return;
    } 
    
    if ((teamlethuddleindex === 0 || teamlethuddleindex - historyCount < 0) && fwdbck === 'older') {
      setEnableOlderButton(false);
      setEnableNewerButton(true);
      setInclusive(false);
      return;
    }

    // if there are no more huddles going forward or if this is the last huddle in the teamlet huddles array
    // or if there is less than a page left and the user selects "newer" disable the buttons

    if ((teamlethuddleindex === teamletHuddleArray.length - 1 || (teamlethuddleindex + 1 >= teamletHuddleArray.length && fwdbck === 'newer'))) {
      setEnableOlderButton(true);
      setEnableNewerButton(false);
      setInclusive(false);
      return;
    }
    setEnableOlderButton(true);
    setEnableNewerButton(true);
  }
  /**
   * @name renderPatientCount
   * @description Show the patientcount for each huddle
   * @param grid row
   * @returns 
   */
  function renderPatientCount(e: any) {
    return (
      <p className="actions">
        <Anchor onClick={() => { setRangeStart(''); setRangeEnd(''); props.setHuddleID(e.data.huddle.internalID); props.setMeetingID(e.data.internalID); props.switchView("huddledetails") }}>{e.data.assignedPatientCount}</Anchor>
      </p>
    );
  }

  /**
   * @name getMeetingDate
   * @description Properly extract the date from the huddle datetime
   * @param grid row
   * @returns 
   */
  function getMeetingDate(e: any) {

    const meetingDT = new Date(e.data.meetingTime)
    const huddleID = e.data.huddle.internalID;

    const meetingID = e.data.internalID;
    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let occurrenceDate = formatDate(e.data.meetingTime);
    let occurrenceDay = weekday[meetingDT.getDay()];
    let occurrenceTime = new Date(e.data.meetingTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toLowerCase()

    let dateandtime = `${occurrenceDate} ${occurrenceDay} @ ${occurrenceTime}`;

    return (
      <p className="actions">
        <Anchor onClick={(e) => { props.setHuddleID(huddleID); props.setMeetingID(meetingID); props.switchView("huddledetails"); }}>{dateandtime}</Anchor>
      </p>
    );
  }

  /**
   * @name formatDate
   * @description Formats date in mm/dd/yyy format
   * @param string 
   * @returns 
  */
  function formatDate(dateStringToFormat: string) {
    let date, month, year;
    const inputDate = new Date(dateStringToFormat);
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date.toString().padStart(2, '0');
    month = month.toString().padStart(2, '0');

    return (`${month}/${date}/${year}`);
  }

  /**
* @name setDisplayValues
* @description formats count numbers in dropdown
* @param none
* @returns option list
*/
  function setDisplayValues() {
    return (<>
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="50">50</option>
    </>)
  }

  /**
      * @name getNextMeetingSet
      * @description gets the next {count} set of meetings
      * @returns array
      */
  function getNextMeetingSet(direction: string) {
    let startTime = '';
    direction === "older" ? startTime = rangeEnd : startTime = rangeStart;
    fetchHuddleSchedule(startTime, direction, false);
  }

  /**
    * @name changeHistoryCount
    * @description gets the next {count} set of meetings
    * @returns array
    */
  function changeHistoryCount(e: any) {
    setFwdbck('older');
    setInclusive(true);
    setStartHuddleID(huddleSchedule[0].huddle.internalID);
    setHistoryCount(e.target.value);
  }

  return (
    <Container>
      <div>
        <section>
          <h2>Teamlet Huddle History</h2>
        </section>
        <div className="d-flex">
          <p>
            {rangeStart !== undefined && rangeStart !== '' ? <label>{formatDate(rangeStart)}</label> : null}
            {rangeEnd !== undefined && rangeEnd !== '' ? <>&nbsp;to&nbsp;<label>{formatDate(rangeEnd)}</label></> : null}

          </p><div className="form-group reset alignRight">
            <Anchor onClick={() => { setFwdbck('older'); getNextMeetingSet('older') }} disabled={!enableOlderButton}>
              <Icon size="14" name="arrow-left" className={enableOlderButton ? "btn-link": ""}></Icon>
              Older&nbsp;
            </Anchor>
            <Anchor onClick={() => { setFwdbck('newer'); getNextMeetingSet('newer') }} disabled={!enableNewerButton}>
              Newer
              <Icon size="14" name="arrow-right" className={enableNewerButton ? "btn-link": ""}></Icon>
            </Anchor>

          </div>
        </div>
        <div id="huddleScheduleGrid">
          <LoadPanel visible={showProgress} message={"Loading Huddle Schedule"} showPane={true} position={position} showIndicator={true}></LoadPanel>
          <DataGrid id="grid-container"
            dataSource={huddleSchedule}
            showColumnLines={false}
            showRowLines={false}
            showBorders={false}
            focusStateEnabled={true}
            allowColumnResizing={true}
            showColumnHeaders={true}
            width={'100%'}
            noDataText='No Huddles scheduled at this time'>
            <Selection mode="none" />
            <Paging enabled={false} />
            <Sorting mode="single" />
            <Column dataField="internalID" caption="Meeting Id" visible={false} />
            <Column dataField="huddle.internalID" caption="huddle Id" visible={false} />
            <Column dataField="meetingTime" caption="Date" cellRender={e => getMeetingDate(e)} allowSorting={false} />
            <Column width={'80'} dataField="assignedPatientCount" cellRender={e => renderPatientCount(e)} alignment={'center'} caption="Patients" allowSorting={false} />
          </DataGrid>              </div>
      </div>
      <div className="d-flex">
        <div className="form-group reset">
          <Form.Group>
            <Form.Label htmlFor="countMeetings">Show</Form.Label>
            <Form.Select id="countMeetings"
              onChange={(e) => { changeHistoryCount(e) }}>
              {setDisplayValues()}
            </Form.Select>
          </Form.Group>
        </div>
      </div>

      <ModalErrorMessage
        showErrorModal={showErrorModal}
        errorMessageTitle={"Assigned Patients"}
        errorMessage={"An error occurred retrieving Assigned Patients"}
        errorServerMessage={"See console for error message."}
        errorUserMessage={""}
        messageType="error"
        setShowErrorModal={setShowErrorModal}
      />

    </Container>
  );
}

export default TeamletHuddleHistory;

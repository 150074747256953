import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Breadcrumb, Stack, Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataGrid, MasterDetail, GroupPanel, SearchPanel, Selection, Column} from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

const config = require('../../config.json');

function RPMdeviceModals(props: {switchView: Function}) {
    
    const [deviceDisplayList, setDeviceDisplayList] = useState<any[]>([]);
    const [deviceDisplayListJSON, setDeviceDisplayListJSON] =  useState<any[]>([]);
    const [deviceDetailDisplayListJSON, setDeviceDetailDisplayListJSON] = useState<any[]>([]);

    useEffect(() => {
        loadRPMdevices();
    }, [])

    /**
     * @name loadRPMdevices
     * @description Loads all current RPM devices into JSON
     */
    async function loadRPMdevices(){
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getRPMdevices`, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(async response => {
            //  Check for Success
            if (response.ok)
            {
                try {
                    let _json = await response.json();
                    setDeviceDisplayListJSON(_json)

                    var _data = JSON.parse(JSON.stringify(_json))
                    processDeviceDetails(_data);
                } catch(err){
                    console.log("ERROR " + err);
                }
            }
        })
    }

    //  Loads the Device details for the Master View
    // @ts-ignore
    function processDeviceDetails(_json) {
        var _deviceDetailsList = [];
        
        for (var iLp = 0; iLp < _json.length; iLp++) {
            var temp_specs = _json[iLp].readingSpecifications
            var internalID = _json[iLp].internalID
            
            for (var iLp1 = 0; iLp1 < temp_specs.length ; iLp1++) {
                // @ts-ignore
                var deviceDetails = {
                    "ParentId": internalID,
                    "internalID": temp_specs[iLp1].internalID,
                    "name": temp_specs[iLp1].name,
                    "dataType": temp_specs[iLp1].dataType,
                    "dataUnits": temp_specs[iLp1].dataUnits,
                    "minReading": temp_specs[iLp1].minReading,
                    "maxReading": temp_specs[iLp1].maxReading,
                }

                //console.log(deviceDetails)
                
                // @ts-ignore
                _deviceDetailsList.push(deviceDetails)
            }
        }

        //  Set State
        setDeviceDetailDisplayListJSON(_deviceDetailsList)
    }

    //  Return the Device detail view
    function MasterDetailViewUI(e:any){
        var ds = getTasks(e.key)
        console.log(ds);
        return (
            <pre>
                <Container className="d-flex justify-content-left align-content-left" style={{backgroundColor: "#F8F8F8"}}>  
                    <Stack className="hstack" direction="horizontal" gap={2}>
                        {
                            deviceDetailDisplayListJSON.filter((item) => item.ParentId === e.key).map((item) => (
                                <Card style={{ marginTop : "10px", marginBottom : "10px", width: "250px" }} >
                                <Card.Body>
                                    <Card.Title className='font-face-activate-heading2'>
                                    </Card.Title>
                                    <table style={{ margin : "0 auto" }}>
                                        <tbody>
                                            <tr>
                                                <td><Form.Label className='font-face-activate-heading4'>Name: </Form.Label></td>
                                                <td><Form.Label className='font-face-activate-main-text'>{item.name}</Form.Label></td>
                                            </tr>
                                            <tr>
                                                <td><Form.Label className='font-face-activate-heading4'>Data Type: </Form.Label></td>
                                                <td><Form.Label className='font-face-activate-main-text'>{item.dataType}</Form.Label></td>
                                            </tr>
                                            <tr>
                                                <td><Form.Label className='font-face-activate-heading4'>Units: </Form.Label></td>
                                                <td><Form.Label className='font-face-activate-main-text'>{item.dataUnits}</Form.Label></td>
                                            </tr>
                                            <tr>
                                                <td><Form.Label className='font-face-activate-heading4'>Min Reading: </Form.Label></td>
                                                <td><Form.Label className='font-face-activate-main-text'>{item.minReading}</Form.Label></td>
                                            </tr>
                                            <tr>
                                                <td><Form.Label className='font-face-activate-heading4'>Max Reading: </Form.Label></td>
                                                <td><Form.Label className='font-face-activate-main-text'>{item.maxReading}</Form.Label></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Card.Body>
                                </Card>
                            ))
                        }
                    </Stack>
                </Container>
        </pre>
        );
    }

    //  Filters Sites By Parent
    function getTasks(key:any) {
        return new DataSource({
            store: new ArrayStore({
            data: deviceDetailDisplayListJSON,
            key: 'internalID',
            }),
            filter: ['ParentId', '=', key],
        });
    }

    return(
          <Container fluid>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => props.switchView("configuration")}>Configuration</Breadcrumb.Item>
                <Breadcrumb.Item active>RPM Device Models</Breadcrumb.Item>
              </Breadcrumb>
              <div className="page-panel">
                        <DataGrid id="grid-container" width={"100%"} showColumnLines={false} showRowLines={false}
                            keyExpr="internalID"
                            showBorders={true}
                            focusStateEnabled={true}
                            dataSource={deviceDisplayListJSON}
                            noDataText='No RPM Devices have been added'>
                                <GroupPanel visible={false} />
                                <SearchPanel visible={false} />
                                <Selection mode="single" />
                                <Column dataField="deviceName" width={375} caption="Device Model Name" />
                                <Column dataField="deviceCategory" width={350} caption="Type" />
                                <Column dataField="deviceManufacturer" width={280} caption="Manufacturer" defaultSortOrder="asc" />
                                <Column dataField="deviceModel" width={150} caption="Model Number" />
                                <MasterDetail
                                    enabled={true}
                                    render={MasterDetailViewUI}
                                    autoExpandAll={false}
                                />
                         </DataGrid>
                </div>
            </Container>

    )
} 

export default RPMdeviceModals
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from "react";
import {Anchor, Button, Modal, Row, Col, Breadcrumb, Form, Container, Accordion, ButtonGroup, ToggleButton} from "react-bootstrap";
import { DataGrid, Selection, Column, Paging} from "devextreme-react/data-grid";
import Chart, {ArgumentAxis, CommonSeriesSettings, Legend, SeriesTemplate, Tooltip, ValueAxis,
  Point, Label, Crosshair, Border, Title, Subtitle, VisualRange,
  ConstantLine, Series, LoadingIndicator, Font, Strip} from "devextreme-react/chart";

import DateBox from "devextreme-react/date-box";
import { faMicrochip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Icon} from '@ailibs/feather-react-ts';

import PermissionService from '../../Services/PermissionService';
import PatientHuddleSchedule from '../Shared/Huddle/PatientHuddleSchedule';
import "./PatientInformation.css";
import { PULSERATE, SYSTOLIC, DIASTOLIC, BLOODGLUCOSE, UNSPECIFIED } from '../Shared/constants';
const config = require("../../config.json");

function PatientInformation(props: {
  switchView: (newView: string) => void,
  permissions: PermissionService,
  patientID: string,
  parentOrgID: string,
  setSelectedTeamletID: (Id: string) => void,
  setMeetingID:  (Id: string) => void,
  setHuddleID:  (newID: string) => void,
}) {
  const [username, setUserName] = useState("");

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [preferredName, setPreferredName] = useState("");
  const [prefix, setPrefix] = useState("");
  const [suffix, setSuffix] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [landlinePhone, setLandlinePhone] = useState("");
  const [street, setStreet] = useState("");
  const [street2, setStreet2] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  
  const [DOB, setDOB] = useState("");
  const [MRN, setMRN] = useState("");
  const [birthGender, setBirthGender] = useState("");
  const [genderIdentity, setGenderIdentity] = useState("");
  const [preferredLanguage, setPreferredLanguage] = useState("");
  const [secondaryLanguage, setSecondaryLanguage] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [preferredContact, setPreferredContact] = useState("");
  const [patientID, setPatientID] = useState("");
  const [preferredPronouns, setPreferredPronouns] = useState<any[]>([]);
  const [race, setRace] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [pathways, setPathways] = useState([]);
  const [pathwayList, setPathwayList] = useState([]);

  const [prefixList, setPrefixList] = useState<any[]>([]);
  const [suffixList, setSuffixList] = useState<any[]>([]);
  const [stateDisplayList, setstateDisplayList] = useState<any[]>([]);

  const [teamSummary, setTeamSummary] = useState([]);
  const [monitorReadingThresholds, setMonitorReadingThresholds] = useState([]);
  const [patientMonitors, setPatientMonitors] = useState<any[]>([]);
  const [patientVitals, setPatientVitals] = useState<any[]>([]);
  const [recentPatientVitals, setRecentPatientVitals] = useState<any[]>([]);
  
  const [deviceInfovalidated, setDeviceInfoValidated] = useState(false);
  const inputAttr = { readOnly: true };
  const [deviceList, setDeviceList] = useState<any[]>([]);
  const [deviceToAssignID, setDeviceToAssignID] = useState("");
  const [deviceToAssignName, setDeviceToAssignName] = useState("");
  const [deviceSerialNumber, setDeviceSerialNumber] = useState("");
  const [deviceInternalID, setDeviceInternalID] = useState("");
  const [deviceDateAssigned, setDeviceDateAssigned] = useState("");

  //  Form State
  const [deviceAssignedEdit, setDeviceAssignedEdit] = useState(false);
  const [showAddDeviceDialog, setShowAddDeviceDialog] = useState(false);
  const [showDeleteDeviceDialog, setShowDeleteDeviceDialog] = useState(false);
  const [todaysDate, setTodaysDate] = useState("");
  const [deleteDeviceID, setDeleteDeviceID] = useState("");
  const [pronounList, setPronounList] = useState<any[]>([]);

  // Thresholds
  const [bpmMinThreshold, setBpmMinThreshold] = useState(70);
  const [bpmMaxThreshold, setBpmMaxThreshold] = useState(120);
  const [glucoseMinThreshold, setGlucoseMinThreshold] = useState(60);
  const [glucoseMaxThreshold, setGlucoseMaxThreshold] = useState(90);
  const [systolicMinThreshold, setSystolicMinThreshold] = useState(60);
  const [systolicMaxThreshold, setSystolicMaxThreshold] = useState(90);
  const [diastolicMinThreshold, setDiastolicMinThreshold] = useState(60);
  const [diastolicMaxThreshold, setDiastolicMaxThreshold] = useState(90);
  
  const [radioValue, setRadioValue] = useState('3');
  const [glucoseRadioValue, setGlucoseRadioValue] = useState('6');
  const [patientParentOrgID, setPatientParentOrgID] = useState("");
  const [thresholdType, setThresholdType]= useState("Organization Defaults");
  
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessageTitle, setErrorMessageTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorServerMessage, setErrorServerMessage] = useState("");
  const [errorUserMessage, setErrorUserMessage] = useState("");

  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isMemberOfTeamLet, setIsMemberOfTeamLet] = useState(false);

  // Used for EHR patients
  const [patientSource, setPatientSource] = useState('');
  const [ehrPatientID, setEhrPatientID] = useState('');
  const [PCPNPIidentifier, setPCPCNPIidentifier] = useState('');
  const [PCPname, setPCPname] = useState('');
  const [EHRemail, setEHRemail] = useState('');

  //  Init
  useEffect(() => {
    //  Load Accout Details
    loadPatientAccount(props.patientID);
    //  Load Devices
    loadDevices();
    //  Load Pronoun
    loadLookUp("Pronoun");

    //  Set Default Date for Device assignment
    const today = new Date().toLocaleDateString();
    setTodaysDate(today);
    setDeviceDateAssigned(today);
    setDeviceAssignedEdit(false);
  }, []);

  //	Load Vitals after properties are set
  useEffect(() => {
    if (patientID !== '' && patientParentOrgID !== '') {
      loadPatientVitals(patientID, patientParentOrgID);
    }
  }, [patientParentOrgID]);

  /**
   * @name loadPatientAccount
   * @description Loads the selected Patient's account info
   * @param
   * @returns {json}
  */
  async function loadPatientAccount(patientID: string) {
    const token = sessionStorage.getItem('ACTIVATEtoken');
    const response = await fetch(
      `${config.activateServer}:${config.activatePORT}/getPatientSummaryById`,
      {
        method: "POST",
        body: JSON.stringify({ token: token, patientID: patientID }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
      }
    );

    try {
      const data = await response.json();

      //  Fields that can be updated
      setFirstName(data.firstName);
      setMiddleName(data.middleName);
      setLastName(data.lastName);
      setPreferredName(data.preferredName);
      setPrefix(data.prefix == null ? "" : data.prefix);
      setSuffix(data.suffix == null ? "" : data.suffix);
      
      setEHRemail(data?.ehrEMail?.name);

      setEmailAddress(data.emailAddress.name);
      setCellPhone(data.cellPhone.name);
      setLandlinePhone(data.landlinePhone.name);
      //  Check for address
      if (data.streetAddress != null) {
        setStreet(data.streetAddress.street);
        setStreet2(data.streetAddress.street2);
        setCityName(data.streetAddress.cityName);
        setStateName(data.streetAddress.stateName);
        setZipcode(data.streetAddress.zipcode);
        setAddressLine1(data.streetAddress.street + ' ' + data.streetAddress.street2);
        setAddressLine2(data.streetAddress.cityName + ', ' + (data.streetAddress.stateName !== UNSPECIFIED ? data.streetAddress.stateName:'(Not Provided)') + ' ' + data.streetAddress.zipcode);
      }
      
      setPreferredContact(data.preferredContact);
      setMRN(data.medicalRecordNumber);
      setUserName(data.username);
      setPatientID(data.internalID);
      setDOB(data.dateOfBirth);
      setBirthGender(data.birthGender);
      setPreferredPronouns(data.pronouns);
      setGenderIdentity(data.genderIdentity);
      setPreferredLanguage(data.primaryLanguage);
      setSecondaryLanguage(data.secondaryLanguage);
      setMaritalStatus(data.maritalStatus);

      setRace(data.race);
      setEthnicity(data.ethnicity);

      //  Load Team Summary
      setTeamSummary(() => {return data.pathways; });
      isMember(data.pathways);

      setMonitorReadingThresholds(() => {return data.monitorThresholds; });
      setPatientMonitors(() => {return data.monitors; });
      setPatientParentOrgID(data.organization.internalID);
      setPatientSource(data.patientSource);
      
      //  Check if EHR Organization
      if (props.permissions.isEhrOrganization) {
        setEhrPatientID(data.ehrPatientID);
        setPCPCNPIidentifier(data.ehrPCPID);
        setPCPname(data.ehrPCPName);
        setEHRemail(data.ehrEMail.name);
      }

      //  Set Enrollment Flag
      let _isEnrolled = true;
      
      try {
        if (props.permissions.isEhrOrganization){
          if (data.dateOfBirth === "" || data.dateOfBirth === undefined) {
            _isEnrolled = false;
          }
        } else {
            if (data.medicalRecordNumber === "" || (data.dateOfBirth === "" || data.dateOfBirth === undefined)) {
              _isEnrolled = false;
            }
        }
      } catch (err) {
          console.log("ERROR " + err);
      }
      //  Set Enrolled flag
      setIsEnrolled(_isEnrolled);

    } catch (err) {
      console.log("ERROR " + err);
    }
}

/**
* @name isMember
* @description Checks if user is a member of the Teamlet
* @param none
* @returns 
*/
function isMember(teamLet: any) {
  //  Check for membership
  if (teamLet != null) {
    for (let iLp = 0; iLp < teamLet.length; iLp++) {
      if (teamLet[iLp].healthcareTeam !== undefined) {
        if (teamLet[iLp].healthcareTeam.members){
          if (teamLet[iLp].healthcareTeam.members.some((member: any) => member.internalID === props.permissions.myInternalID)) {
            setIsMemberOfTeamLet(() => {return true});
            break;
          } else {
            setIsMemberOfTeamLet(() => {return false});
          }
        }
        else {
          setIsMemberOfTeamLet(() => {return false});
        }
      } else {
        setIsMemberOfTeamLet(() => {return false});
      }
    }
  } else {
    setIsMemberOfTeamLet(() => {return false});
  }
}

// @ts-ignore
function sortFunctionDesc(a: any, b: any) {
  var dateA = new Date(b.readingTime).getTime();
  var dateB = new Date(a.readingTime).getTime();
  return dateA > dateB ? 1 : -1;  
}

// @ts-ignore
function sortFunctionAsc(a: any, b: any) {
  var dateA = new Date(a.readingTime).getTime();
  var dateB = new Date(b.readingTime).getTime();
  return dateA > dateB ? 1 : -1;  
}

/**
   * @name loadPatientAccount
   * @description Loads the selected Patient's account info
   * @param
   * @returns {json}
*/
async function loadPatientVitals(patientID: string, _parentOrgID: string) {
  
  // @ts-ignore
  const _monitorSpecs = getPatientMonitorSpecs(patientID, _parentOrgID).then(specs => {
    let _convertedVitalsList: React.SetStateAction<any[]> = [];
    
    // Create Most Recent Vitals List
    const _recentVitals = [];

    //  Check for Monitor Specs and load
    if (specs != null) {
      //  Sort List
      specs.sort(sortFunctionDesc);
    
      if (specs[0] != null) {
        let i = 0;
        let continueDateProcessing = true;
        let foundPulseRate = false;
        let foundSystolic = false;
        let foundDiastolicRate = false;
        let pulseRate = 0;
        let systolicRate = 0;
        let diastolicRate = 0;
    
        do {
            //  Start Day Processing
            const dateOfReading = new Date(specs[i].readingTime).toLocaleDateString();
            const timeOfRreading = new Date(specs[i].readingTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const readingName = specs[i].name;
            const deviceReading = specs[i].reading;

            //  Handle Reading
            if (readingName === BLOODGLUCOSE) {
              //  Add to recent Vitals List
              let vitalReading = {
                "date" : dateOfReading,
                "time" : timeOfRreading,
                "reading" : deviceReading,
                "heartrate" : '',
                "bloodpressure" : '',
                "glucose" : deviceReading
              };

              //  Add to review
              _recentVitals.push(vitalReading);

            } else if (readingName === SYSTOLIC) {
              foundSystolic = true;
              systolicRate = deviceReading
            } else if (readingName === DIASTOLIC) {
              foundDiastolicRate = true;
              diastolicRate = deviceReading
            } else if (readingName === PULSERATE) {
              foundPulseRate = true;
              pulseRate = deviceReading
            }

            //  Found all the BP values, save and move on
            if (foundPulseRate === true && 
                  foundSystolic === true && 
                    foundDiastolicRate === true) {
                var vitalReading = {
                  "date" : dateOfReading,
                  "time" : timeOfRreading,
                  "reading" : deviceReading,
                  "heartrate" : pulseRate,
                  "bloodpressure" : systolicRate + '/' + diastolicRate,
                  "glucose" : ''
                }

                //  Add to review
                _recentVitals.push(vitalReading);

                foundSystolic = false;
                foundDiastolicRate = false;
                foundPulseRate = false;
            }
            
            //  Update counter  
            i = i + 1;
        
            //  Check for the end of the specs
            if (i === specs.length ) {
              continueDateProcessing = false;
            } else {
              const nextDate = new Date(specs[i].readingTime).toLocaleDateString();
              if (dateOfReading != nextDate) {
                continueDateProcessing = false;
              }
            }
          
        } while (continueDateProcessing);
     
        //  Loop through monitor reading
        for (let iLp = 0; iLp < specs.length; iLp++) {
          const monitor = specs[iLp];
          monitor.readingTime = new Date(monitor.readingTime).toLocaleDateString();
          monitor.reading = parseInt(monitor.reading);
          _convertedVitalsList.push(monitor);
        }

        // Set Vitals
        setPatientVitals(_convertedVitalsList.sort(sortFunctionAsc));

        setRecentPatientVitals(_recentVitals);
      }
    }

    //  Check for Vital Data and load
    const _vitalData = getVitalDataByPatientID(patientID).then(vitals => {
      //	Check for null object
      if (vitals != null ) {
        if (vitals.length > 0) {
          setThresholdType("Custom for Individual");
          setSystolicMinThreshold(parseInt(vitals.filter((a: any) => a.readingName === SYSTOLIC)[0].dataValues.filter((a: any) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue));
          setSystolicMaxThreshold(parseInt(vitals.filter((a: any) => a.readingName === SYSTOLIC)[0].dataValues.filter((a: any) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue));
          setDiastolicMinThreshold((parseInt(vitals.filter((a: any) => a.readingName === DIASTOLIC)[0].dataValues.filter((a: any) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue)));
          setDiastolicMaxThreshold((parseInt(vitals.filter((a: any) => a.readingName === DIASTOLIC)[0].dataValues.filter((a: any) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue)));
          setBpmMinThreshold(parseInt(vitals.filter((a: any) => a.readingName === PULSERATE)[0].dataValues.filter((a: any) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue));
          setBpmMaxThreshold(parseInt(vitals.filter((a: any) => a.readingName === PULSERATE)[0].dataValues.filter((a: any) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue));
          //  Check for Gluscose Readings
          if (vitals.filter((a: any) => a.readingName === BLOODGLUCOSE).length > 0) {
            setGlucoseMinThreshold(parseInt(vitals.filter((a: any) => a.readingName === BLOODGLUCOSE)[0].dataValues.filter((a: any) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue));
            setGlucoseMaxThreshold(parseInt(vitals.filter((a: any) => a.readingName === BLOODGLUCOSE)[0].dataValues.filter((a: any) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue));
          }
        } else {
          const _vitalOrgData = getVitalDataByOrgID(_parentOrgID).then(_orgThresholdData => {
            //	Check for null object
            if (_orgThresholdData != null ) {
              setThresholdType("Organization Defaults");
              if (_orgThresholdData.length > 0) {
                setSystolicMinThreshold(parseInt(_orgThresholdData.filter((a: any) => a.readingName === SYSTOLIC)[0].dataValues.filter((a: any) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue));
                setSystolicMaxThreshold(parseInt(_orgThresholdData.filter((a: any) => a.readingName === SYSTOLIC)[0].dataValues.filter((a: any) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue));
                setDiastolicMinThreshold((parseInt(_orgThresholdData.filter((a: any) => a.readingName === DIASTOLIC)[0].dataValues.filter((a: any) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue)));
                setDiastolicMaxThreshold((parseInt(_orgThresholdData.filter((a: any) => a.readingName === DIASTOLIC)[0].dataValues.filter((a: any) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue)));
                setBpmMinThreshold(parseInt(_orgThresholdData.filter((a: any) => a.readingName === PULSERATE)[0].dataValues.filter((a: any) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue));
                setBpmMaxThreshold(parseInt(_orgThresholdData.filter((a: any) => a.readingName === PULSERATE)[0].dataValues.filter((a: any) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue));
                setGlucoseMinThreshold(parseInt(_orgThresholdData.filter((a: any) => a.readingName === BLOODGLUCOSE)[0].dataValues.filter((a: any) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue));
                setGlucoseMaxThreshold(parseInt(_orgThresholdData.filter((a: any) => a.readingName === BLOODGLUCOSE)[0].dataValues.filter((a: any) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue));

              } else {
                setThresholdType("(No Devices Assigned)");
                setSystolicMinThreshold(0);
                setSystolicMaxThreshold(0);
                setDiastolicMinThreshold(0);
                setDiastolicMaxThreshold(0);
                setBpmMaxThreshold(0);
                setBpmMinThreshold(0);
                setGlucoseMaxThreshold(0);
                setGlucoseMinThreshold(0);
              }
            }  
          });

        }
    }});
  }).catch((error: { message: any; }) => {
    console.log(error.message); // 'An error has occurred: 404'
  });
}

/**
   * @name getPatientMonitorSpecs
   * @description Get the Patient's Monitor Specs
   * @param
   * @returns {json}
*/
async function getPatientMonitorSpecs(patientID: string, _parentOrgID: string) {
  const token = sessionStorage.getItem('ACTIVATEtoken');
  const response = await fetch(`${config.activateServer}:${config.activatePORT}/getPatientMonitorSpecsByPatientId`,
  {
    method: "POST",
    body: JSON.stringify({ token: token, patientID: patientID }),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    mode: "cors",
  });

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const momitorSpecs = await response.json();
  
  return momitorSpecs;
}

/**
   * @name getVitalDataByPatientID
   * @description Gets the Vital Data Information
   * @param
   * @returns {json}
*/
async function getVitalDataByPatientID(patientID: string) {
  const token = sessionStorage.getItem('ACTIVATEtoken');
  const response = await fetch(`${config.activateServer}:${config.activatePORT}/getVitalData`,
  {
      method: "POST",
      body: JSON.stringify({ token: token, orgID: patientID }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      mode: "cors",
  });

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const vitalsData = await response.json();
  return vitalsData;
}

/**
   * @name getVitalDataByOrgID
   * @description Gets the Vital Data Information
   * @param
   * @returns {json}
*/
async function getVitalDataByOrgID(_parentOrgID: string) {
  const token = sessionStorage.getItem('ACTIVATEtoken');
  const response = await fetch(`${config.activateServer}:${config.activatePORT}/getVitalData`,
  {
      method: "POST",
      body: JSON.stringify({ token: token, orgID: _parentOrgID }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      mode: "cors",
  });

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const vitalsData = await response.json();
  return vitalsData;
}

/**
 * @name loadDevices
 * @description Loads the Devices that can be assigned to the Patient
 * @param
 * @returns {json}
 */
async function loadDevices() {
  const token = sessionStorage.getItem('ACTIVATEtoken');
  await fetch(
    `${config.activateServer}:${config.activatePORT}/getRPMdevices`,
    {
      method: "POST",
      body: JSON.stringify({ token: token }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      mode: "cors",
    }
  ).then(async (_response) => {
    //  Check for Success
    if (_response.ok) {
        try {
          const data = await _response.json();
          //  Check for devices
          if (data != null) {
            const _deviceList = [];
            for (let i = 0; i < data.length; i++) {
              _deviceList.push(
                <option
                  key={i}
                  data-name={data[i].deviceName}
                  data-value={data[i].internalID}>
                  {data[i].deviceName} ({data[i].deviceManufacturer} {data[i].deviceModel})
                </option>
              );
            }

            //  Load Devices
            setDeviceList(_deviceList);
          }
        } catch (err) {
          console.log("ERROR " + err);
        }
    }
  });
}

/**
 * @name loadLookUp
 * @description Loads the lookups for Dropdowns
 * @param
 * @returns {json}
 */
async function loadLookUp(lookup: string) {
  const token = sessionStorage.getItem('ACTIVATEtoken');

  const response = await fetch(
    `${config.activateServer}:${config.activatePORT}/getValues`,
    {
      method: "POST",
      body: JSON.stringify({ token: token, type: lookup }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      mode: "cors",
    }
  );

  try {
    const data = await response.json();
    const _pronounList = [];
    switch (lookup) {
      case "Pronoun":
        for (let i = 0; i < data.length; i++) {
          _pronounList.push(data[i].name);
        }

        //  Load Pronoun List
        setPronounList(_pronounList);
        break;
      default:
        break;
    }
  } catch (err) {
    console.log("ERROR " + err);
  }
}

//  Shows Add Device dialog
function addDevice() {
  // Set DeviceInfo defaults
  let _internalID = "";
  let _deviceName = "";

  //  Set the Default
  if (deviceList.length > 0) {
    const _defaultDevice = deviceList[0];
    if (_defaultDevice != null) {
      _internalID = deviceList[0].props["data-value"];
      _deviceName = deviceList[0].props["data-name"];
    }
  }

  //  Set Device settings
  setDeviceInternalID("");
  setDeviceSerialNumber(""); // monitorUniqueID
  setDeviceToAssignID(_internalID);
  setDeviceToAssignName(_deviceName);
  setDeviceDateAssigned(todaysDate);
  setDeviceAssignedEdit(false);
  setDeviceInfoValidated(false);
  setShowAddDeviceDialog(true);
}

//  Closes Add Device dialog
function closeAddDeviceModal(e: boolean) {
  setShowAddDeviceDialog(e);
}

/**
 * @name renderIcon
 * @description Shows the feather icon
 * @param
 * @returns
 */
function renderIcon(iconName : any, category:string) {
  return (
    <div className={`ui-icon ${category}`}>
      <Icon name={iconName} size="24" />
    </div>
  );
}

/**
 * @name activateTeamList
 * @description Renders ACTIVATE Team list members
 * @param Patient pathways array
 * @returns Team list HTML
 */
function activateTeamList(e: any) {
 //  Check to Healthcare Team
 if (e.length > 0 ) 
 {
   return (
     <ul className="team-list">
       {e.map((data: any, index: number) => {
        if (data.healthcareTeam != null) {
         return (
           <li key={index}>
             {renderIcon("users","people")}
             <p>{data.healthcareTeam.name}<br/>
               <small>{data.name}</small>
             </p>
             {/* if user has permission to delete team members then show the icon
             <p className="actions">
               <a href="#" onClick={() => removeTeamMember()} title="Remove"><Icon name="trash-2" size="18" className="action-icon" /></a>
             </p>
             */}
           </li>
         )} else {
            return(<li>
              {renderIcon("users","people")}<p>ACTIVATE Teamlet(s) NOT assigned<br/><small>{data.name}</small></p><br/></li>)
         }
         }
       )}
   </ul>
   );
 } else {
   return (<>No ACTIVATE Teams found<br/></>);
 }
}

/**
 * @name equipmentList
 * @description Renders patient's assigned equipment list
 * @param Patient pathways array
 * @returns Team list HTML
 */
 function equipmentList(e: any) {
  //  Check for Devices
  if (e != null && e.length > 0) {
    return (
      <ul className="equipment-list"> 
        {e.map((data: any, index: number) => {
          return (
            <li key={index}>
              {renderIcon("cpu","rpm")}
              <p><a className="popup-link" onClick={viewDeviceDetails} id={data.internalID}>{data.deviceModel} {data.deviceName}</a><br/>
                <small>{data.monitorUniqueID}</small>
              </p>
              {/* TODO: add permission check to delete patient equipment */}
              <p className="actions">
              <a onClick={(e) => {trashClick(data.internalID);e.preventDefault();}} title="Remove"><Icon name="trash-2" size="18" className="action-icon" data-internalid={data.internalID} /></a>
              </p>
            </li>
          )}
        )}
    </ul>
    );
  } else {
    return (<>No Devices assigned<br/></>);
  }
}

//  Render Vital Readings
function renderVitalGridReadings(e: any) {
  const _readName = e.data.readingName;
  let _readingTitle = e.data.readingName;

  switch (_readName) {
    case BLOODGLUCOSE:
      _readingTitle = "Blood Glucose";
      break;
    case PULSERATE:
      _readingTitle = "Pulse Rate";
      break;
    case SYSTOLIC:
      _readingTitle = "Systolic Blood Pressure";
      break;
    case DIASTOLIC:
      _readingTitle = "Diastolic Blood Pressure";
      break;
    default:
      _readingTitle = _readName;
  }

  return (
    <>
      <p>{_readingTitle}<br />
      <small>{e.data.dataUnits}</small></p>
    </>
  );
}

//  Render Low Vital Thresholds
function renderLowValues(e: any) {
  const lessThan = e.data.dataValues.filter(
    (a: { thresholdValueType: string }) =>
      a.thresholdValueType === "LESS_THAN"
  );

  const lessThanEqualTo = e.data.dataValues.filter(
    (a: { thresholdValueType: string }) =>
      a.thresholdValueType === "LESS_THAN_OR_EQUAL"
  );

  //  &lt; less than
  //  &#8804; less than or equal to

  //  Check Low Threshold value
  if (lessThan.length > 0 ) {
    return <>&lt; {lessThan[0].thresholdValue}</>;
  } else if (lessThanEqualTo.length > 0 ){
    return <>&#8804; {lessThanEqualTo[0].thresholdValue}</>;
  } else {
    return <>No Low Value</>;
  }
}

//  Render High Vital Thresholds
function renderHighValues(e: any) {
  const greaterThan = e.data.dataValues.filter(
    (a: { thresholdValueType: string }) =>
      a.thresholdValueType === "GREATER_THAN"
  );
  
  const greaterThanEqualTo = e.data.dataValues.filter(
    (a: { thresholdValueType: string }) =>
      a.thresholdValueType === "GREATER_THAN_OR_EQUAL"
  );

  // &#8805; greater than or equal to
  // &gt; greater than

  //  Check High Threshold value
  if (greaterThan.length > 0 ) {
    return <>&gt;{greaterThan[0].thresholdValue}</>;
  } else if (greaterThanEqualTo.length > 0 ){
    return <>&#8805; {greaterThanEqualTo[0].thresholdValue}</>;
  } else {
    return <>No High Value</>;
  }
}

//  Render High Vital Thresholds
function renderDeviceName(e: any) {
  return (
    <>
      <a className="titlesTeamlet" onClick={viewDeviceDetails} id={e.data.internalID}>
        {e.data.deviceModel} {e.data.deviceName}
      </a>
      <br/>
      <label className="subTitles">{e.data.monitorUniqueID}</label>
    </>
  );
}

/**
 * @name viewDeviceDetails
 * @description Shows the Device to edit
 * @param
 * @returns
 */
function viewDeviceDetails(e: any) {
  const deviceID = e.currentTarget.id;
  let _selectedDevice = patientMonitors.filter(
    (p) => p.internalID === deviceID
  );

  if (_selectedDevice != null) {
    // Set InternalID for the update
    setDeviceInternalID(_selectedDevice[0].internalID);

    var assignedDate = new Date(
      _selectedDevice[0].assignmentDateTime
    ).toLocaleDateString();

    setDeviceAssignedEdit(true);

    setDeviceSerialNumber(_selectedDevice[0].monitorUniqueID);
    setDeviceDateAssigned(assignedDate);
    setDeviceToAssignID(_selectedDevice[0].monitorSpecification.internalID);

    // data[i].deviceName} ({data[i].deviceManufacturer} {data[i].deviceModel}
    let _deviceLongName = _selectedDevice[0].deviceName + " (" + _selectedDevice[0].deviceManufacturer + " " + _selectedDevice[0].deviceModel + ")";
    setDeviceToAssignName(_deviceLongName);

    //  UI params
    setDeviceInfoValidated(false);
    setShowAddDeviceDialog(true);
  }
}

function trashClick(id: string) {
  if (id != null) {
    showDeleteModal(id, true);
  }
}

//  Customize ToolTip example
function customizeTooltip(pointInfo: { point: { data: any } }) {
  const { data } = pointInfo.point;
  return {
    text: `Reading: ${data.reading} Date: ${data.readingTime}`,
  };
}

//  Grid Header for BPM
function renderHeartRateBPM() {
  return (
    <>
      <p>Heart Rate<br/>
      <small>bpm</small></p>
    </>
  );
}

//  Grid Header for Blood Pressure
function renderBloodPressure() {
  return (
    <>
      <p>Blood Pressure<br/>
      <small>mmHg</small></p>
    </>
  );
}

//  Grid Header for Blood Glucose
function renderBloodGlucose() {
  return (
    <>
      <p>Blood Glucose<br />
      <small>mg/dl</small></p>
    </>
  );
}

function gridCellClicked(e: any) {
  e.event.stopImmediatePropagation();
}

//  Sample Conditional Formating
function formatMostRecentVitals(e: any) {
  try {
    //  Highlight Highs/Lows Blood Pressue
    if (e.rowType === "data" && e.column.dataField === "bloodpressure") {
      // Check for a value
      if (e.data.bloodpressure.trim().length > 0) {
          const bpReadings = e.data.bloodpressure.split('/');
          const bpSystolic = bpReadings[0];
          const bpDiastolic = bpReadings[1];
          //  Check Highs and Lows
          if (parseInt(bpSystolic) > systolicMaxThreshold ||
                  parseInt(bpDiastolic) > diastolicMaxThreshold) {
              /*  Commented fjr per Anya's request leave until further defined
                e.cellElement.style.backgroundColor = "red";
                e.cellElement.style.color = 'white'
              */
          } else if (parseInt(bpSystolic) < systolicMinThreshold ||
                      parseInt(bpDiastolic) < diastolicMinThreshold) {
              /*  Commented fjr per Anya's request leave until further defined
              e.cellElement.style.backgroundColor = "yellow";
              e.cellElement.style.color = ''
              */
          } else {
            /*  Commented fjr per Anya's request leave until further defined
              e.cellElement.style.backgroundColor = "";
              e.cellElement.style.color = ''
            */
          }
      }
    }
    
    //  Highlight Highs/Lows Glucose
    if (e.rowType === "data" && e.column.dataField === "glucose") {
      // Check for a value
      if (e.data.glucose.trim().length > 0) {
        if (parseInt(e.data.glucose) >= glucoseMaxThreshold) {
          /*  Commented fjr per Anya's request leave until further defined
            e.cellElement.style.backgroundColor = "red";
            e.cellElement.style.color = "white";
          */
        } else if (parseInt(e.data.glucose) <= glucoseMinThreshold) {
          /*  Commented fjr per Anya's request leave until further defined
            e.cellElement.style.backgroundColor = "yellow";
            e.cellElement.style.color = ''
          */  
        } else {
            /*  Commented fjr per Anya's request leave until further defined
            e.cellElement.style.backgroundColor = "";
            e.cellElement.style.color = "black";
            */
        }
      }
    }

    //  Highlight Highs/Lows Heart Rate
    if (e.rowType === "data" && e.column.dataField === "heartrate") {
      // Check for a value
      if (e.data.heartrate.trim().length > 0) {
        if (parseInt(e.data.heartrate) >= bpmMaxThreshold) {
            /*  Commented fjr per Anya's request leave until further defined
              e.cellElement.style.backgroundColor = "red";
              e.cellElement.style.color = 'white'
            */
        } else if (parseInt(e.data.heartrate) <= bpmMinThreshold) {
            /*  Commented fjr per Anya's request leave until further defined
              e.cellElement.style.backgroundColor = "yellow";
              e.cellElement.style.color = ''
            */
        } else {
            /*  Commented fjr per Anya's request leave until further defined
              e.cellElement.style.backgroundColor = "";
              e.cellElement.style.color = "black";
            */
        }
      }
    }
  } catch (err) {
    console.log(err);
  }
  
}

/**
 * @name assignDeviceToPatient
 * @description  Assigns Device to Patient
 * @param
 * @returns
 */
async function assignDeviceToPatient(
  _monitorUniqueID: string,
  _assignmentDateTime: string,
  _monitorSpecification: string,
  _patientID: string
) {
  const token = sessionStorage.getItem('ACTIVATEtoken');
  await fetch(`${config.activateServer}:${config.activatePORT}/assignDeviceToPatient`, {
      method: "POST",
      body: JSON.stringify({
        token: token,
        internalID: "",
        monitorUniqueID: _monitorUniqueID,
        assignmentDateTime: _assignmentDateTime,
        monitorSpecification: _monitorSpecification,
        patient: _patientID,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      mode: "cors",
    }
  ).then(_response => {
      //  Check for Success
      if (_response.ok) {
        try {
          loadPatientAccount(_patientID);
        } catch (err) {
          console.log("Error in assignDeviceToPatient: Assigning Device " +
              JSON.stringify(err)
          );
        }
      } else {
        //  Error returned from data service, display to user
        _response.json().then(data => {
      
          //  Create Message
          const _statusCode = _response.status.toString();
          const _statusmessage = data != null ? data[0].error : "Error Assigning Device";

          //  Create Error Message
          setErrorMessageTitle("Error Assigning Device")
          setErrorMessage("An error occurred assigning the device");
          setErrorServerMessage(_statusmessage + " (Code: " + _statusCode + ")");
          setErrorUserMessage("Please check the data you entered, and re-try.");
                      
          //  Show Modal
          setShowErrorModal(true);
        });
      }
    });
}

/**
 * @name updateAssignedDeviceToPatient
 * @description  Updates Device assigned to Patient
 * @param
 * @returns
 */
async function updateAssignedDeviceToPatient(_monitorUniqueID: string, _assignmentDateTime: string, _monitorSpecification: string, _patientID: string, _internalID: string) {
  const token = sessionStorage.getItem('ACTIVATEtoken');
  await fetch(`${config.activateServer}:${config.activatePORT}/updateAssignedDeviceToPatient`,
    {
      method: "POST",
      body: JSON.stringify({
        token: token,
        internalID: _internalID,
        monitorUniqueID: _monitorUniqueID,
        assignmentDateTime: _assignmentDateTime,
        monitorSpecification: _monitorSpecification,
        patient: _patientID,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      mode: "cors",
    }
  ).then(_response => {
      //  Check for Success
      if (_response.ok) {
        try {
          loadPatientAccount(_patientID);
        } catch (err) {
          console.log(
            "Error in updateDeviceToPatient: Assigning Device " +
              JSON.stringify(err)
          );
        }
      } else {
        //  Error returned from data service, display to user
        _response.json().then(data => {
      
          //  Create Message
          const _statusCode = _response.status.toString();
          const _statusmessage = data != null ? data[0].error : "Error Updating Device";

          //  Create Error Message
          setErrorMessageTitle("Error Updating Device");
          setErrorMessage("An error occurred updating the device");
          setErrorServerMessage(_statusmessage + " (Code: " + _statusCode + ")");
          setErrorUserMessage("Please check the data you entered, and re-try.");
                      
          //  Show Modal
          setShowErrorModal(true);
        });
      }
    });
}

/**
 * @name removeAssignedDeviceFromPatient
 * @description  Removes device from Patient
 * @param
 * @returns
 */
async function removeAssignedDeviceFromPatient(_deviceIdToDelete: string) {
  const token = sessionStorage.getItem('ACTIVATEtoken');
  await fetch(
    `${config.activateServer}:${config.activatePORT}/removeAssignedDeviceFromPatient`,
    {
      method: "POST",
      body: JSON.stringify({
        token: token,
        internalID: _deviceIdToDelete,
        monitorID: _deviceIdToDelete,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      mode: "cors",
    }
  ).then(_response => {
      //  Check for Success
      if (_response.ok) {
        try {
          //  Refresh
          loadPatientAccount(patientID);
          loadPatientVitals(patientID, patientParentOrgID)
        } catch (err) {
            //  Add pop-up here
            console.log("Error in removeAssignedDeviceFromPatient: Removing Device " + JSON.stringify(err)
          );
        }
      } else {
        //  Error returned from data service, display to user
        _response.json().then(data => {
      
          //  Create Message
          const _statusCode = _response.status.toString();
          const _statusmessage = data != null ? data[0].error : "Error Removing Device";

          //  Create Error Message
          setErrorMessageTitle("Error Removing Device")
          setErrorMessage("An error occurred removing the device");
          setErrorServerMessage(_statusmessage + " (Code: " + _statusCode + ")");
          setErrorUserMessage("");
                      
          //  Show Modal
          setShowErrorModal(true);
        });
      }
      //  Hide Delete
      showDeleteModal(_deviceIdToDelete, false);
    });
  }

  //  Delete Pop-up
  function showDeleteModal(id: string, toggle: boolean) {
    //  Show Dialog Capture ID to delete
    if (toggle) {
      setDeleteDeviceID(id);
    } else {
      setDeleteDeviceID("");
    }
    setShowDeleteDeviceDialog(toggle);
  }

  function deleteDevice(deviceIdToDelete: any) {
    removeAssignedDeviceFromPatient(deviceIdToDelete);
  }

  /**
  * @name handleDeviceSubmit
  * @description  Handle Device add to Patient
  * @param
  * @returns
  */
  function handleDeviceSubmit(event: any) {
    const form = event.currentTarget;
    event.preventDefault();

    const _monitorUniqueID = deviceSerialNumber;
    const _assignmentDateTime = new Date(deviceDateAssigned).toISOString();
    const _monitorSpecification = deviceToAssignID;
    const _patientID = props.patientID;
    const _internalID = deviceInternalID;

    //  Check for valid form
    if (form.checkValidity() === false) {
      event.stopPropagation();
      //  Set form validation
      setDeviceInfoValidated(true);
    } else {
      //  Check for Edit or Adding
      if (deviceAssignedEdit === true) {
        //  Update the device to the patient
        updateAssignedDeviceToPatient(
          _monitorUniqueID,
          _assignmentDateTime,
          _monitorSpecification,
          _patientID,
          _internalID
        );
        setDeviceAssignedEdit(false);
      } else {
        //  Save the device to the patient
        assignDeviceToPatient(
          _monitorUniqueID,
          _assignmentDateTime,
          _monitorSpecification,
          _patientID
        );
      }
      setShowAddDeviceDialog(false);
    }
  }

  //  Assign RPM Device here
  function DeviceAssignedDate(e: any) {
    setDeviceDateAssigned(e.value);
  }

  //  Get Selected Device
  function handleDeviceOnDeviceModelChange(e: any) {
    const _internalID = e.target.options[e.target.selectedIndex].getAttribute(
      "data-value"
    )
      ? e.target.options[e.target.selectedIndex].getAttribute("data-value")
      : "";
    const _deviceName = e.target.value;

    setDeviceToAssignID(_internalID);
    setDeviceToAssignName(_deviceName);
  }

  //  Capture Device Serial Number
  function handleDeviceOnSerialNumberChange(e: any) {
    setDeviceSerialNumber(e.target.value);
  }

  //  Equiment Grid Icon
  function EquimentIcon() {
    return (
      <>
        <FontAwesomeIcon
          className="nestLine"
          icon={faMicrochip}
          style={{ color: "green" }}
        ></FontAwesomeIcon>
      </>
    );
  }

  const bloodPressureRadiosButtons = [
    { name: 'Week', value: '1' },
    { name: '2 Weeks', value: '2' },
    { name: 'Month', value: '3' },
  ];

  const glucoseRadiosButtons = [
    { name: 'Week', value: '4' },
    { name: '2 Weeks', value: '5' },
    { name: 'Month', value: '6' },
  ];
  
  const thresholdColors = {
    highBkg : "#FFFDEC",
    highPoint: "#FF7B29",
    highLine: "#FFCD5D",
    point : "#999999",
    line: "#DDE1E5",
    goalRange : "#FDFDFD",
    midRange : "#F8F8F8",
    lowBkg: "#FFEAEA",
    lowPoint: "#F41B1B",
    lowLine: "#FFADAD"
  };

  // @ts-ignore
  //  Change Blood Pressure Filters
  function onWeekMonthChange(e) {
    setRadioValue(e.currentTarget.value);
  }

  // @ts-ignore
  //  Change Blood Pressure Filters
  function onWeekMonthChangeGC(e) {
    setGlucoseRadioValue(e.currentTarget.value);
  }

// @ts-ignore
  function customizeBPSystolicDiastolic(pointInfo : any) {
    if (pointInfo.seriesName === SYSTOLIC) {
      if (pointInfo.value > systolicMaxThreshold) {
        return { color: thresholdColors.highPoint, hoverStyle: { color: "#FFFFFF" } };
      } else {
        if (pointInfo.value <= systolicMinThreshold) {
          return { color: thresholdColors.lowPoint, hoverStyle: { color: "#FFFFFF" } };          
        } else {
          return { color: thresholdColors.point, hoverStyle: { color: thresholdColors.point } };
        }
      }
    }
    
    if (pointInfo.seriesName === DIASTOLIC) {
      if (pointInfo.value > diastolicMaxThreshold) {
        return { color: thresholdColors.highPoint, hoverStyle: { color: "#FFFFFF" } };
      } else {
        if (pointInfo.value <= diastolicMinThreshold) {
          return { color: thresholdColors.lowPoint, hoverStyle: { color: "#FFFFFF" } };        
        } else {
          return { color: thresholdColors.point, hoverStyle: { color: thresholdColors.point } };
        }
      }
    }
 
    return { color: '#000000', hoverStyle: { color: '#000000' } };    
  }

  function customizeBPMPoints(pointInfo : any) {
    if (pointInfo.value > bpmMaxThreshold) {
      return { color: thresholdColors.highPoint, hoverStyle: { color: "#FFFFFF" } };
    } else {
      if (pointInfo.value <= bpmMinThreshold) {
        return { color: thresholdColors.lowPoint, hoverStyle: { color: "#FFFFFF" } };          
      } else {
        return { color: thresholdColors.point, hoverStyle: { color: thresholdColors.point } };
      }
    }
  }
    
  function customizeGlucosePoints(pointInfo : any) {
    if (pointInfo.value > glucoseMaxThreshold) {
      return { color: thresholdColors.highPoint, hoverStyle: { color: "#FFFFFF" } };
    } else {
      if (pointInfo.value <= glucoseMinThreshold) {
        return { color: thresholdColors.lowPoint, hoverStyle: { color: "#FFFFFF" } };
      } else {
        return { color: thresholdColors.point, hoverStyle: { color: thresholdColors.point } };
      }
    }
  }    
   

  return (
    <div>
        <Container fluid>
            <Form>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => props.switchView("patientpanel")}>Patients</Breadcrumb.Item>
                    <Breadcrumb.Item active>{firstName} {middleName} {lastName} {suffix==="Not Provided" ? null : suffix}</Breadcrumb.Item>
              </Breadcrumb>
            <Row>
                <Col md={12} lg={7} >
                    <div className="page-panel profile-information">
                        <div className="panel-header">
                            <h2>Patient Information</h2>
                            {props.permissions.checkFunctionPermission("PATIENT_PANEL", "PATIENT_INFORMATION", "UPDATE") ?
                              <Anchor onClick={() => props.switchView("patientinformationEdit")}>Edit</Anchor>
                            : 
                              <></>
                            }   
                        </div>
                        <Row>
                          <Col md={6}>
                            <section>
                                <h3 className="panel-section">About</h3>
                                <Row>
                                    <Form.Label column>First Name</Form.Label>
                                    <Col><p className="form-control-plaintext">{firstName}</p></Col>
                                </Row>
                                <Row>
                                    <Form.Label column>Middle Name</Form.Label>
                                    <Col><p className="form-control-plaintext">{middleName}</p></Col>
                                </Row>
                                <Row>
                                    <Form.Label column>Last Name</Form.Label>
                                    <Col><p className="form-control-plaintext">{lastName}</p></Col>
                                </Row>
                                <Row>
                                    <Form.Label column>Preferred Name</Form.Label>
                                    <Col><p className="form-control-plaintext">{preferredName}</p></Col>
                                </Row>
                                <Row>
                                    <Form.Label column>Prefix</Form.Label>
                                    <Col><p className="form-control-plaintext">{prefix}</p></Col>
                                </Row>
                                <Row>
                                    <Form.Label column>Suffix</Form.Label>
                                    <Col><p className="form-control-plaintext">{suffix}</p></Col>
                                </Row>
                                {props.permissions.isEhrOrganization ?
                                <Row>
                                    <Form.Label column>EHR Patient ID</Form.Label>
                                    <Col><p className="form-control-plaintext">{ehrPatientID}</p></Col>
                                </Row>
                                : <></>}
                                </section>
                            </Col>
                            <Col md={6}>
                              <section>
                              <h3 className="panel-section">Contact</h3>
                                <Row>
                                    <Form.Label column>ACTIVATE Email Address</Form.Label>
                                    <Col><p className="form-control-plaintext">{emailAddress}</p></Col>
                                </Row>
                                {(props.permissions.isEhrOrganization) ?
                                <Row>
                                    <Form.Label column>EHR Email Address</Form.Label>
                                    <Col><p className="form-control-plaintext">{EHRemail}</p></Col>
                                </Row>
                                : <></>}
                                <Row>
                                    <Form.Label column>Cell Phone</Form.Label>
                                    <Col><p className="form-control-plaintext">{cellPhone}</p></Col>
                                </Row>
                                <Row>
                                    <Form.Label column>Home Phone</Form.Label>
                                    <Col><p className="form-control-plaintext">{landlinePhone}</p></Col>
                                </Row>
                                <Row>
                                    <Form.Label column>Address</Form.Label>
                                    <Col>
                                      <p className="form-control-plaintext">{addressLine1}</p>
                                      <p className="form-control-plaintext">{addressLine2}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Form.Label column>Preferred Contact</Form.Label>
                                    <Col><p className="form-control-plaintext">{preferredContact}</p></Col>
                                </Row>
                              </section>
                            </Col>
                            
                            {isEnrolled ?
                              <section className="mt-3">
                                  <h3 className="panel-section">Identifiers</h3>
                                  <Row>
                                  <Col md={6}>
                                    <Row>
                                        <Form.Label column>MRN</Form.Label>
                                        <Col><p className="form-control-plaintext">{MRN}</p></Col>
                                    </Row>
                                    <Row>
                                        <Form.Label column>ACTIVATE Username</Form.Label>
                                        <Col><p className="form-control-plaintext">{username}</p></Col>
                                    </Row>
                                    <Row>
                                        <Form.Label column>ACTIVATE<br/>Patient ID</Form.Label>
                                        <Col><p className="form-control-plaintext" style={{ overflowWrap :"anywhere"}}>{patientID}</p></Col>
                                    </Row>
                                    {(props.permissions.isEhrOrganization) ?
                                    <Row>
                                      <Form.Label column>PCP NPI Identifier</Form.Label>
                                      <Col><p className="form-control-plaintext" style={{ overflowWrap :"anywhere"}}>{PCPNPIidentifier}</p></Col>
                                    </Row>
                                    : <></>}
                                    {(props.permissions.isEhrOrganization) ?
                                    <Row>
                                      <Form.Label column>PCP Name</Form.Label>
                                      <Col><p className="form-control-plaintext" style={{ overflowWrap :"anywhere"}}>{PCPname}</p></Col>
                                    </Row>
                                    : <></>}
                                  </Col>
                                  
                                  <Col md={6}>
                                    <Row>
                                        <Form.Label column>Date of Birth</Form.Label>
                                        <Col><p className="form-control-plaintext">{DOB}</p></Col>
                                    </Row>
                                    <Row>
                                        <Form.Label column>Sex at Birth</Form.Label>
                                        <Col><p className="form-control-plaintext">{birthGender}</p></Col>
                                    </Row>
                                    <Row>
                                        <Form.Label column>Preferred Pronouns</Form.Label>
                                        <Col><p className="form-control-plaintext">{preferredPronouns}</p></Col>
                                    </Row>
                                    <Row>
                                        <Form.Label column>Gender Identify</Form.Label>
                                        <Col><p className="form-control-plaintext">{genderIdentity}</p></Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </section>
                            : null }

                            {isEnrolled ?
                              <section className="mt-3">
                                  <h3 className="panel-section">Additional Information</h3>
                                  <Row>
                                  <Col md={6}>
                                    <Row>
                                        <Form.Label column>Primary Language</Form.Label>
                                        <Col><p className="form-control-plaintext">{preferredLanguage}</p></Col>
                                    </Row>
                                    <Row>
                                        <Form.Label column>Secondary Language</Form.Label>
                                        <Col><p className="form-control-plaintext">{secondaryLanguage}</p></Col>
                                    </Row>
                                    <Row>
                                        <Form.Label column>Marital Status</Form.Label>
                                        <Col><p className="form-control-plaintext" style={{ overflowWrap :"anywhere"}}>{maritalStatus}</p></Col>
                                    </Row>
                                  </Col>
                                  <Col md={6}>
                                    <Row>
                                        <Form.Label column>Race</Form.Label>
                                        <Col><p className="form-control-plaintext">{race}</p></Col>
                                    </Row>
                                    <Row>
                                        <Form.Label column>Ethnicity</Form.Label>
                                        <Col><p className="form-control-plaintext">{ethnicity}</p></Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </section>
                            : null }
                          </Row>
                        </div>
                        <div className="page-panel">
                              <h2>Equipment</h2>
                              {equipmentList(patientMonitors)}
                              {isEnrolled ?
                              <Anchor onClick={addDevice} id={patientID}>Add Device</Anchor>
                              : null}
                      </div>
              <Row>
                <Col>
                  <div id="VitalsData">
                  {patientVitals?.length > 0 && patientMonitors?.map((monitor) => {
                    if (monitor.deviceName === 'Withings Data Hub')
                      return
                    
                    return React.Children.toArray(
                        <div className="page-panel">
                          <div className="panel-header">
                              <h2>Vitals Data - {monitor.deviceName}</h2><a className="card-link" onClick={() => props.switchView("patientvitalshistory")}>View History</a>
                          </div>
                         {monitor.deviceName === 'Blood Pressure Monitor' ?
                            <>
                              <ButtonGroup className="float-end" size={'sm'} >
                                {bloodPressureRadiosButtons.map((radio, idx) => (
                                  <ToggleButton
                                    key={idx}
                                    id={`radio-${idx}`}
                                    type="radio"
                                    name="radio"
                                    value={radio.value}
                                    variant={radioValue === radio.value ? "primary" : "outline-primary"}
                                    checked={radioValue === radio.value}
                                    onChange={onWeekMonthChange}>
                                    {radio.name}
                                  </ToggleButton>
                                ))}
                              </ButtonGroup>
                                <React.Fragment>
                                  { radioValue === '3' ?
                                    <Chart
                                      id="bpChartMonth" 
                                      customizePoint={customizeBPSystolicDiastolic}
                                      dataSource={patientVitals.filter((p) => (p.name === SYSTOLIC || p.name === DIASTOLIC) && p.patientMonitor === monitor.internalID && (new Date(p.readingTime)) >= (new Date(new Date().setDate(new Date().getDate() - 30))))}>
                                      <Title text={monitor.deviceModel}>
                                        <Subtitle text="(Systolic/Diastolic)" />
                                      </Title>
                                      <SeriesTemplate
                                        nameField="name">
                                      </SeriesTemplate>
                                      <Series type="line"/>
                                      <ArgumentAxis
                                          visible={true}
                                          valueMarginsEnabled={true}
                                          title=""
                                          argumentType={'date'}>
                                        <Label wordWrap="none" overlappingBehavior={'stagger'} />
                                      </ArgumentAxis>
                                        <CommonSeriesSettings
                                          color={thresholdColors.line}
                                          argumentField="readingTime"
                                          valueField="reading"
                                          hoverMode="allArgumentPoints"
                                          width={2} >
                                          <Point size={6} color={thresholdColors.point} />
                                        </CommonSeriesSettings>

                                        <Crosshair enabled={true}>
                                          <Label visible={true} />
                                        </Crosshair>
                                        <ValueAxis maxValueMargin={0.10} valueType="number" >
                                          <Title text="Blood Pressure (mmHg)"></Title>
                                          <VisualRange startValue={60} />

                                        <Strip  
                                          startValue={systolicMaxThreshold}
                                          color={thresholdColors.highBkg}>
                                          <Label text={"Sys. High (>"+systolicMaxThreshold +" mmHg)"} verticalAlignment="top">
                                            <Font size={11}></Font>
                                          </Label>
                                        </Strip >
                                  
                                       <Strip  
                                          startValue={systolicMinThreshold}
                                          endValue={systolicMaxThreshold}
                                          color={thresholdColors.goalRange}>
                                          <Label text="" verticalAlignment="center">
                                            <Font size={11}></Font>
                                          </Label>
                                        </Strip >
                                  
                                       <Strip  
                                          startValue={Math.min(diastolicMaxThreshold,systolicMinThreshold)}
                                          endValue={Math.max(diastolicMaxThreshold,systolicMinThreshold)}
                                          color={thresholdColors.midRange}>
                                          <Label text="" verticalAlignment="center">
                                            <Font size={11}></Font>
                                          </Label>
                                        </Strip >                                  


                                       <Strip  
                                          startValue={diastolicMinThreshold}
                                          endValue={diastolicMaxThreshold}
                                          color={thresholdColors.goalRange}>
                                          <Label text="" verticalAlignment="center">
                                            <Font size={11}></Font>
                                          </Label>
                                        </Strip >

                                        <Strip  
                                          startValue={40}
                                          endValue={diastolicMinThreshold}
                                          color={thresholdColors.lowBkg}>
                                          <Label text={"Dia. Low (≤"+diastolicMinThreshold+" mmHg)"} verticalAlignment="center">
                                            <Font size={11}></Font>
                                          </Label>
                                        </Strip >

                                          <ConstantLine 
                                            value={systolicMaxThreshold} 
                                            color={thresholdColors.highLine}
                                            dashStyle={'dash'} 
                                            width={1.2}
                                            displayBehindSeries={true}>
                                            <Label 
                                              text={"Sys. High<br>("+systolicMaxThreshold +" mmHg)"} 
                                              position="outside"
                                              horizontalAlignment="right"
                                              verticalOffset={40}
                                              visible={false}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>

                                          <ConstantLine 
                                            value={systolicMinThreshold} 
                                            color={thresholdColors.lowLine} 
                                            width={1.2}
                                            displayBehindSeries={true} 
                                            dashStyle={'dash'} >
                                            <Label text={"Sys. Low<br>("+systolicMinThreshold+" mmHg)"}
                                              position="outside"
                                              horizontalAlignment={"right"}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>

                                          <ConstantLine 
                                            value={diastolicMaxThreshold} 
                                            color={thresholdColors.highLine}
                                            dashStyle={'dash'} 
                                            width={1.2}
                                            displayBehindSeries={true}>
                                            <Label 
                                              text={"Dia. High <br>("+diastolicMaxThreshold +" mmHg)"} 
                                              alignment="right"
                                              position="outside"
                                              horizontalAlignment={"right"}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>

                                          <ConstantLine 
                                            value={diastolicMinThreshold} 
                                            color="#FFADAD" 
                                            width={1.2}
                                            displayBehindSeries={true} 
                                            dashStyle={'dash'} >
                                            <Label text={"Dia. Low<br>("+diastolicMinThreshold+" mmHg)"}
                                              position="outside"
                                              visible={false}
                                              horizontalAlignment={"right"}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>

                                        </ValueAxis>
                                    <Legend position="outside" visible={false}>
                                      <Border visible={true} />
                                    </Legend>
                                    <LoadingIndicator enabled={true} />
                                  </Chart>
                                : null}
                                { radioValue === '2' ?
                                    <Chart
                                      id="bpChart2Weeks" 
                                      customizePoint={customizeBPSystolicDiastolic}
                                      dataSource={patientVitals.filter((p) => (p.name === SYSTOLIC || p.name === DIASTOLIC) && (p.patientMonitor === monitor.internalID) && (new Date(p.readingTime)) >= (new Date(new Date().setDate(new Date().getDate() - 14))))}>
                                      <Title text={monitor.deviceModel}>
                                        <Subtitle text="(Systolic/Diastolic)" />
                                      </Title>
                                      <SeriesTemplate
                                        nameField="name">
                                      </SeriesTemplate>
                                      <Series type="line"/>
                                      <ArgumentAxis
                                          visible={true}
                                          valueMarginsEnabled={true}
                                          title=""
                                          argumentType={'date'}>
                                        <Label wordWrap="none" overlappingBehavior={'stagger'} />
                                      </ArgumentAxis>
                                        <CommonSeriesSettings
                                          color={thresholdColors.line}
                                          argumentField="readingTime"
                                          valueField="reading"
                                          hoverMode="allArgumentPoints"
                                          width={2} >
                                          <Point size={6} color={thresholdColors.point} />
                                        </CommonSeriesSettings>

                                        <Crosshair enabled={true}>
                                          <Label visible={true} />
                                        </Crosshair>
                                        <ValueAxis maxValueMargin={0.10} valueType="number" >
                                          <Title text="Blood Pressure (mmHg)"></Title>
                                          <VisualRange startValue={60} />

                                        <Strip  
                                          startValue={systolicMaxThreshold}
                                          color={thresholdColors.highBkg}>
                                          <Label text={"Sys. High (>"+systolicMaxThreshold +" mmHg)"} verticalAlignment="top">
                                            <Font size={11}></Font>
                                          </Label>
                                        </Strip >
                                  
                                       <Strip  
                                          startValue={systolicMinThreshold}
                                          endValue={systolicMaxThreshold}
                                          color={thresholdColors.goalRange}>
                                          <Label text="" verticalAlignment="center">
                                            <Font size={11}></Font>
                                          </Label>
                                        </Strip >
                                  
                                       <Strip  
                                          startValue={Math.min(diastolicMaxThreshold,systolicMinThreshold)}
                                          endValue={Math.max(diastolicMaxThreshold,systolicMinThreshold)}
                                          color={thresholdColors.midRange}>
                                          <Label text="" verticalAlignment="center">
                                            <Font size={11}></Font>
                                          </Label>
                                        </Strip >                                  


                                       <Strip  
                                          startValue={diastolicMinThreshold}
                                          endValue={diastolicMaxThreshold}
                                          color={thresholdColors.goalRange}>
                                          <Label text="" verticalAlignment="center">
                                            <Font size={11}></Font>
                                          </Label>
                                        </Strip >

                                        <Strip  
                                          startValue={40}
                                          endValue={diastolicMinThreshold}
                                          color={thresholdColors.lowBkg}>
                                          <Label text={"Dia. Low (≤"+diastolicMinThreshold+" mmHg)"} verticalAlignment="center">
                                            <Font size={11}></Font>
                                          </Label>
                                        </Strip >

                                          <ConstantLine 
                                            value={systolicMaxThreshold} 
                                            color={thresholdColors.highLine}
                                            dashStyle={'dash'} 
                                            width={1.2}
                                            displayBehindSeries={true}>
                                            <Label 
                                              text={"Sys. High<br>("+systolicMaxThreshold +" mmHg)"} 
                                              position="outside"
                                              horizontalAlignment="right"
                                              verticalOffset={40}
                                              visible={false}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>

                                          <ConstantLine 
                                            value={systolicMinThreshold} 
                                            color={thresholdColors.lowLine} 
                                            width={1.2}
                                            displayBehindSeries={true} 
                                            dashStyle={'dash'} >
                                            <Label text={"Sys. Low<br>("+systolicMinThreshold+" mmHg)"}
                                              position="outside"
                                              horizontalAlignment={"right"}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>

                                          <ConstantLine 
                                            value={diastolicMaxThreshold} 
                                            color={thresholdColors.highLine}
                                            dashStyle={'dash'} 
                                            width={1.2}
                                            displayBehindSeries={true}>
                                            <Label 
                                              text={"Dia. High <br>("+diastolicMaxThreshold +" mmHg)"} 
                                              alignment="right"
                                              position="outside"
                                              horizontalAlignment={"right"}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>

                                          <ConstantLine 
                                            value={diastolicMinThreshold} 
                                            color="#FFADAD" 
                                            width={1.2}
                                            displayBehindSeries={true} 
                                            dashStyle={'dash'} >
                                            <Label text={"Dia. Low<br>("+diastolicMinThreshold+" mmHg)"}
                                              position="outside"
                                              visible={false}
                                              horizontalAlignment={"right"}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>

                                        </ValueAxis>
                                    <Legend position="outside" visible={false}>
                                      <Border visible={true} />
                                    </Legend>
                                    <LoadingIndicator enabled={true} />
                                  </Chart>
                                : null}
                                { radioValue === '1' ?
                                    <Chart
                                      id="bpChartWeek" 
                                      customizePoint={customizeBPSystolicDiastolic}
                                      dataSource={patientVitals.filter((p) => (p.name === SYSTOLIC || p.name === DIASTOLIC) && (p.patientMonitor === monitor.internalID) && (new Date(p.readingTime)) >= (new Date(new Date().setDate(new Date().getDate() - 7))))}>
                                      <Title text={monitor.deviceModel}>
                                        <Subtitle text="(Systolic/Diastolic)" />
                                      </Title>
                                      <SeriesTemplate
                                        nameField="name">
                                      </SeriesTemplate>
                                      <Series type="line"/>
                                      <ArgumentAxis
                                          visible={true}
                                          valueMarginsEnabled={true}
                                          title=""
                                          argumentType={'date'}>
                                        <Label wordWrap="none" overlappingBehavior={'stagger'} />
                                      </ArgumentAxis>
                                        <CommonSeriesSettings
                                          color={thresholdColors.line}
                                          argumentField="readingTime"
                                          valueField="reading"
                                          hoverMode="allArgumentPoints"
                                          width={2} >
                                          <Point size={6} color={thresholdColors.point} />
                                        </CommonSeriesSettings>

                                        <Crosshair enabled={true}>
                                          <Label visible={true} />
                                        </Crosshair>
                                        <ValueAxis maxValueMargin={0.10} valueType="number" >
                                          <Title text="Blood Pressure (mmHg)"></Title>
                                          <VisualRange startValue={60} />

                                        <Strip  
                                          startValue={systolicMaxThreshold}
                                          color={thresholdColors.highBkg}>
                                          <Label text={"Sys. High (>"+systolicMaxThreshold +" mmHg)"} verticalAlignment="top">
                                            <Font size={11}></Font>
                                          </Label>
                                        </Strip >
                                  
                                       <Strip  
                                          startValue={systolicMinThreshold}
                                          endValue={systolicMaxThreshold}
                                          color={thresholdColors.goalRange}>
                                          <Label text="" verticalAlignment="center">
                                            <Font size={11}></Font>
                                          </Label>
                                        </Strip >
                                  
                                       <Strip  
                                          startValue={Math.min(diastolicMaxThreshold,systolicMinThreshold)}
                                          endValue={Math.max(diastolicMaxThreshold,systolicMinThreshold)}
                                          color={thresholdColors.midRange}>
                                          <Label text="" verticalAlignment="center">
                                            <Font size={11}></Font>
                                          </Label>
                                        </Strip >                                  


                                       <Strip  
                                          startValue={diastolicMinThreshold}
                                          endValue={diastolicMaxThreshold}
                                          color={thresholdColors.goalRange}>
                                          <Label text="" verticalAlignment="center">
                                            <Font size={11}></Font>
                                          </Label>
                                        </Strip >

                                        <Strip  
                                          startValue={40}
                                          endValue={diastolicMinThreshold}
                                          color={thresholdColors.lowBkg}>
                                          <Label text={"Dia. Low (≤"+diastolicMinThreshold+" mmHg)"} verticalAlignment="center">
                                            <Font size={11}></Font>
                                          </Label>
                                        </Strip >

                                          <ConstantLine 
                                            value={systolicMaxThreshold} 
                                            color={thresholdColors.highLine}
                                            dashStyle={'dash'} 
                                            width={1.2}
                                            displayBehindSeries={true}>
                                            <Label 
                                              text={"Sys. High<br>("+systolicMaxThreshold +" mmHg)"} 
                                              position="outside"
                                              horizontalAlignment="right"
                                              verticalOffset={40}
                                              visible={false}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>

                                          <ConstantLine 
                                            value={systolicMinThreshold} 
                                            color={thresholdColors.lowLine} 
                                            width={1.2}
                                            displayBehindSeries={true} 
                                            dashStyle={'dash'} >
                                            <Label text={"Sys. Low<br>("+systolicMinThreshold+" mmHg)"}
                                              position="outside"
                                              horizontalAlignment={"right"}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>

                                          <ConstantLine 
                                            value={diastolicMaxThreshold} 
                                            color={thresholdColors.highLine}
                                            dashStyle={'dash'} 
                                            width={1.2}
                                            displayBehindSeries={true}>
                                            <Label 
                                              text={"Dia. High <br>("+diastolicMaxThreshold +" mmHg)"} 
                                              alignment="right"
                                              position="outside"
                                              horizontalAlignment={"right"}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>
                                          
                                          {/* not sure if we want to label all thresholds in BP view */}
                                          <ConstantLine 
                                            value={diastolicMinThreshold} 
                                            color={thresholdColors.lowLine} 
                                            width={1.2}
                                            displayBehindSeries={true} 
                                            dashStyle={'dash'} >
                                            <Label text={"Dia. Low<br>("+diastolicMinThreshold+" mmHg)"}
                                              position="outside"
                                              visible={false}
                                              horizontalAlignment={"right"}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>

                                        </ValueAxis>
                                    <Legend position="outside" visible={false}>
                                      <Border visible={true} />
                                    </Legend>
                                    <LoadingIndicator enabled={true} />
                                  </Chart>
                                : null}
                                </React.Fragment>
                                <React.Fragment>
                                  { radioValue === '3' ?
                                    <Chart
                                      id="bpChartPRMonthly" 
                                      customizePoint={customizeBPMPoints}
                                      dataSource={patientVitals.filter((p) => (p.name === "PulseRate") && p.patientMonitor === monitor.internalID && (new Date(p.readingTime)) >= (new Date(new Date().setDate(new Date().getDate() - 30))))}>
                                      <Title text={monitor.deviceModel}>
                                        <Subtitle text="(Pulse Rate)" />
                                      </Title>
                                      <SeriesTemplate
                                        nameField="name">
                                      </SeriesTemplate>
                                      <ArgumentAxis
                                          visible={true}
                                          valueMarginsEnabled={true}
                                          title="">
                                        <Label
                                          wordWrap="none"
                                          overlappingBehavior={'stagger'}
                                        />
                                      </ArgumentAxis>
                                      <CommonSeriesSettings
                                        color={thresholdColors.line}
                                        argumentField="readingTime"
                                        valueField="reading"
                                        type="line"
                                        hoverMode="allArgumentPoints">
                                        <Point size={6} color={thresholdColors.point} />
                                      </CommonSeriesSettings>
                                          <Crosshair enabled={true}>
                                            <Label visible={true} />
                                          </Crosshair>
                                        <ValueAxis maxValueMargin={0.20} valueType="number" >
                                          <Title text="Pulse Rate (bpm)"></Title>
                                          <VisualRange startValue={40} />

                                          <Strip  
                                            startValue={bpmMaxThreshold}
                                            color={thresholdColors.highBkg}>
                                            <Label text={"PulseRate High (>"+bpmMaxThreshold +" bpm)"} verticalAlignment="top">
                                              <Font size={11}></Font>
                                            </Label>
                                          </Strip>
                                          
                                          <Strip  
                                            startValue={0}
                                            endValue={bpmMinThreshold}
                                            color={thresholdColors.lowBkg}
                                            paddingTopBottom={20}>
                                            <Label
                                             text={"PulseRate Low (≤"+bpmMinThreshold +" bpm)"}
                                             verticalAlignment="top">
                                              <Font size={11}></Font>
                                            </Label>
                                          </Strip >

                                          <ConstantLine 
                                            value={bpmMaxThreshold} 
                                            color={thresholdColors.highLine}
                                            dashStyle={'dash'} 
                                            width={1.2}
                                            displayBehindSeries={true}>
                                            <Label 
                                              text={"Pulse High<br>("+bpmMaxThreshold +" bpm)"} 
                                              position="outside"
                                              horizontalAlignment="right"
                                              visible={true}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>
                                          <ConstantLine 
                                            value={bpmMinThreshold} 
                                            color={thresholdColors.lowLine}
                                            dashStyle={'dash'} 
                                            width={1.2}
                                            displayBehindSeries={true}>
                                            <Label 
                                              text={"Pulse Low<br>("+bpmMinThreshold +" bpm)"} 
                                              position="outside"
                                              horizontalAlignment="right"
                                              visible={true}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>                                          
                                        </ValueAxis>
                                    <Legend position="outside" visible={false}>
                                      <Border visible={true} />
                                    </Legend>
                                    <LoadingIndicator enabled={true} />
                                  </Chart>
                                : null}
                                { radioValue === '2' ?
                                    <Chart
                                      id="bpChartPR2Weeks" 
                                      customizePoint={customizeBPMPoints}
                                      dataSource={patientVitals.filter((p) => (p.name === "PulseRate") && (p.patientMonitor === monitor.internalID) && (new Date(p.readingTime)) >= (new Date(new Date().setDate(new Date().getDate() - 14))))}>
                                      <Title text={monitor.deviceModel}>
                                        <Subtitle text="(Pulse Rate)" />
                                      </Title>  
                                      <SeriesTemplate
                                        nameField="name">
                                      </SeriesTemplate>
                                      <ArgumentAxis
                                          visible={true}
                                          valueMarginsEnabled={true}
                                          title="">
                                        <Label
                                          wordWrap="none"
                                          overlappingBehavior={'stagger'}
                                        />
                                      </ArgumentAxis>
                                      <CommonSeriesSettings
                                        color={thresholdColors.line}
                                        argumentField="readingTime"
                                        valueField="reading"
                                        type="line"
                                        hoverMode="allArgumentPoints">
                                        <Point size={6} color={thresholdColors.point} />
                                      </CommonSeriesSettings>
                                          <Crosshair enabled={true}>
                                            <Label visible={true} />
                                          </Crosshair>
                                        <ValueAxis maxValueMargin={0.20} valueType="number" >
                                          <Title text="Pulse Rate (bpm)"></Title>
                                          <VisualRange startValue={40} />

                                          <Strip  
                                            startValue={bpmMaxThreshold}
                                            color={thresholdColors.highBkg}>
                                            <Label text={"PulseRate High (>"+bpmMaxThreshold +" bpm)"} verticalAlignment="top">
                                              <Font size={11}></Font>
                                            </Label>
                                          </Strip>
                                          
                                          <Strip  
                                            startValue={0}
                                            endValue={bpmMinThreshold}
                                            color={thresholdColors.lowBkg}
                                            paddingTopBottom={20}>
                                            <Label
                                             text={"PulseRate Low (≤"+bpmMinThreshold +" bpm)"}
                                             verticalAlignment="top">
                                              <Font size={11}></Font>
                                            </Label>
                                          </Strip >

                                          <ConstantLine 
                                            value={bpmMaxThreshold} 
                                            color={thresholdColors.highLine}
                                            dashStyle={'dash'} 
                                            width={1.2}
                                            displayBehindSeries={true}>
                                            <Label 
                                              text={"Pulse High<br>("+bpmMaxThreshold +" bpm)"} 
                                              position="outside"
                                              horizontalAlignment="right"
                                              visible={true}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>
                                          <ConstantLine 
                                            value={bpmMinThreshold} 
                                            color={thresholdColors.lowLine}
                                            dashStyle={'dash'} 
                                            width={1.2}
                                            displayBehindSeries={true}>
                                            <Label 
                                              text={"Pulse Low<br>("+bpmMinThreshold +" bpm)"} 
                                              position="outside"
                                              horizontalAlignment="right"
                                              visible={true}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>                                          
                                        </ValueAxis>
                                    <Legend position="outside" visible={false}>
                                      <Border visible={true} />
                                    </Legend>
                                    <LoadingIndicator enabled={true} />
                                  </Chart>
                                : null}
                                { radioValue === '1' ?
                                    <Chart
                                      id="bpChartPRWeek" 
                                      customizePoint={customizeBPMPoints}
                                      dataSource={patientVitals.filter((p) => (p.name === "PulseRate") && (p.patientMonitor === monitor.internalID) && (new Date(p.readingTime)) >= (new Date(new Date().setDate(new Date().getDate() - 7))))}>
                                      <Title text={monitor.deviceModel}>
                                        <Subtitle text="(Pulse Rate)" />
                                      </Title>  
                                      <SeriesTemplate
                                        nameField="name">
                                      </SeriesTemplate>
                                      <ArgumentAxis
                                          visible={true}
                                          valueMarginsEnabled={true}
                                          title="">
                                        <Label
                                          wordWrap="none"
                                          overlappingBehavior={'stagger'}
                                        />
                                      </ArgumentAxis>
                                      <CommonSeriesSettings
                                        color={thresholdColors.line}
                                        argumentField="readingTime"
                                        valueField="reading"
                                        type="line"
                                        hoverMode="allArgumentPoints">
                                        <Point size={6} color={thresholdColors.point} />
                                      </CommonSeriesSettings>
                                          <Crosshair enabled={true}>
                                            <Label visible={true} />
                                          </Crosshair>
                                        <ValueAxis maxValueMargin={0.20} valueType="number" >
                                          <Title text="Pulse Rate (bpm)"></Title>
                                          <VisualRange startValue={40} />

                                          <Strip  
                                            startValue={bpmMaxThreshold}
                                            color={thresholdColors.highBkg}>
                                            <Label text={"PulseRate High (>"+bpmMaxThreshold +" bpm)"} verticalAlignment="top">
                                              <Font size={11}></Font>
                                            </Label>
                                          </Strip>
                                          
                                          <Strip  
                                            startValue={0}
                                            endValue={bpmMinThreshold}
                                            color={thresholdColors.lowBkg}
                                            paddingTopBottom={20}>
                                            <Label
                                             text={"PulseRate Low (≤"+bpmMinThreshold +" bpm)"}
                                             verticalAlignment="top">
                                              <Font size={11}></Font>
                                            </Label>
                                          </Strip >

                                          <ConstantLine 
                                            value={bpmMaxThreshold} 
                                            color={thresholdColors.highLine}
                                            dashStyle={'dash'} 
                                            width={1.2}
                                            displayBehindSeries={true}>
                                            <Label 
                                              text={"Pulse High<br>("+bpmMaxThreshold +" bpm)"} 
                                              position="outside"
                                              horizontalAlignment="right"
                                              visible={true}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>
                                          <ConstantLine 
                                            value={bpmMinThreshold} 
                                            color={thresholdColors.lowLine}
                                            dashStyle={'dash'} 
                                            width={1.2}
                                            displayBehindSeries={true}>
                                            <Label 
                                              text={"Pulse Low<br>("+bpmMinThreshold +" bpm)"} 
                                              position="outside"
                                              horizontalAlignment="right"
                                              visible={true}>
                                              <Font size={11}></Font>
                                            </Label>
                                          </ConstantLine>                                          
                                        </ValueAxis>
                                    <Legend position="outside" visible={false}>
                                      <Border visible={true} />
                                    </Legend>
                                    <LoadingIndicator enabled={true} />
                                  </Chart>
                                : null}
                                </React.Fragment>
                              </>
                            : null }
                            { monitor.deviceName === 'Blood Glucose Monitor' ?
                            <>
                            <ButtonGroup className="float-end" size={'sm'} >
                              {glucoseRadiosButtons.map((r, idx) => (
                                <ToggleButton key={idx}  id={`r-${idx}`}
                                  type="radio"
                                  name="r"
                                  value={r.value}
                                  variant={glucoseRadioValue === r.value ? "primary" : "outline-primary"}
                                  checked={glucoseRadioValue === r.value}
                                  onChange={onWeekMonthChangeGC}>
                                  {r.name}
                                </ToggleButton>
                              ))}
                            </ButtonGroup>
                            <React.Fragment>
                            { glucoseRadioValue === '6' ?
                              <Chart 
                                  dataSource={patientVitals.filter((p) => p.patientMonitor === monitor.internalID && (new Date(p.readingTime)) >= (new Date(new Date().setDate(new Date().getDate() - 30))))}
                                  customizePoint={customizeGlucosePoints}
                                  id="gcChart">
                                <Title text={monitor.deviceModel}>
                                  <Subtitle text="(Blood Glucose)" />
                                </Title>
                                  
                                <ArgumentAxis
                                    visible={true}
                                    valueMarginsEnabled={true}
                                    title=""
                                    argumentType={'date'}>
                                  <Label wordWrap="none" overlappingBehavior={'stagger'} />
                                </ArgumentAxis>
                                <SeriesTemplate nameField="name" />
                                <CommonSeriesSettings
                                        color={thresholdColors.line}
                                        argumentField="readingTime"
                                        valueField="reading"
                                        type="line"
                                        hoverMode="allArgumentPoints">
                                        <Point size={6} color={thresholdColors.point} />
                                </CommonSeriesSettings>
                                <Crosshair enabled={true}>
                                  <Label visible={true} />
                                </Crosshair>
                                <ValueAxis maxValueMargin={0.1} valueType="number">
                                  <Title text="Blood Glucose (mg/dl)"></Title>
                                  <VisualRange startValue={0} />

                                  <Strip  
                                    startValue={glucoseMaxThreshold}
                                    color={thresholdColors.highBkg}>
                                    <Label text={"Blood Glucose High (>"+glucoseMaxThreshold+" mg/dl)"}
                                      verticalAlignment="top">
                                      <Font size={11}></Font>
                                    </Label>
                                  </Strip>

                                  <Strip  
                                    endValue={glucoseMinThreshold}
                                    color={thresholdColors.lowBkg}>
                                    <Label text={"Blood Glucose Low (≤"+glucoseMinThreshold+" mg/dl)"} verticalAlignment="bottom">
                                      <Font size={11}></Font>
                                    </Label>
                                  </Strip>
                                  
                                  <ConstantLine
                                    width={1.2}
                                    value={glucoseMaxThreshold}
                                    color={thresholdColors.highLine}
                                    dashStyle="dash">
                                     <Label text={"High <br>("+glucoseMaxThreshold+" mg/dl)"} horizontalAlignment={"right"} position={"outside"} >
                                      <Font size={11}></Font>
                                    </Label>
                                  </ConstantLine>
                                  
                                  <ConstantLine
                                    width={1.2}
                                    value={glucoseMinThreshold}
                                    color={thresholdColors.lowLine}
                                    dashStyle="dash">
                                     <Label text={"Low <br>("+glucoseMinThreshold+" mg/dl)"} horizontalAlignment={"right"} position={"outside"} >
                                      <Font size={11}></Font>
                                    </Label>
                                  </ConstantLine>
                                </ValueAxis>
                                <Legend visible={false}
                                  verticalAlignment="bottom"
                                  horizontalAlignment="center"
                                  itemTextPosition="bottom"/>
                                <Tooltip
                                  enabled={true}
                                  customizeTooltip={customizeTooltip}
                                ></Tooltip>
                              </Chart>
                            : null}
                            { glucoseRadioValue === '5' ?
                              <Chart 
                                  dataSource={patientVitals.filter((p) => (p.patientMonitor === monitor.internalID) && (new Date(p.readingTime)) >= (new Date(new Date().setDate(new Date().getDate() - 14))))}
                                  customizePoint={customizeGlucosePoints}
                                  id="gcChart2Weeks">
                                <Title text={monitor.deviceModel}>
                                  <Subtitle text="(Blood Glucose)" />
                                </Title>
                                  
                                <ArgumentAxis
                                    visible={true}
                                    valueMarginsEnabled={true}
                                    title=""
                                    argumentType={'date'}>
                                  <Label wordWrap="none" overlappingBehavior={'stagger'} />
                                </ArgumentAxis>
                                <SeriesTemplate nameField="name" />
                                <CommonSeriesSettings
                                        color={thresholdColors.line}
                                        argumentField="readingTime"
                                        valueField="reading"
                                        type="line"
                                        hoverMode="allArgumentPoints">
                                        <Point size={6} color={thresholdColors.point} />
                                </CommonSeriesSettings>
                                <Crosshair enabled={true}>
                                  <Label visible={true} />
                                </Crosshair>
                                <ValueAxis maxValueMargin={0.1} valueType="number">
                                  <Title text="Blood Glucose (mg/dl)"></Title>
                                  <VisualRange startValue={0} />

                                  <Strip  
                                    startValue={glucoseMaxThreshold}
                                    color={thresholdColors.highBkg}>
                                    <Label text={"Blood Glucose High (>"+glucoseMaxThreshold+" mg/dl)"}
                                      verticalAlignment="top">
                                      <Font size={11}></Font>
                                    </Label>
                                  </Strip>

                                  <Strip  
                                    endValue={glucoseMinThreshold}
                                    color={thresholdColors.lowBkg}>
                                    <Label text={"Blood Glucose Low (≤"+glucoseMinThreshold+" mg/dl)"} verticalAlignment="bottom">
                                      <Font size={11}></Font>
                                    </Label>
                                  </Strip>
                                  
                                  <ConstantLine
                                    width={1.2}
                                    value={glucoseMaxThreshold}
                                    color={thresholdColors.highLine}
                                    dashStyle="dash">
                                     <Label text={"High <br>("+glucoseMaxThreshold+" mg/dl)"} horizontalAlignment={"right"} position={"outside"} >
                                      <Font size={11}></Font>
                                    </Label>
                                  </ConstantLine>
                                  
                                  <ConstantLine
                                    width={1.2}
                                    value={glucoseMinThreshold}
                                    color={thresholdColors.lowLine}
                                    dashStyle="dash">
                                     <Label text={"Low <br>("+glucoseMinThreshold+" mg/dl)"} horizontalAlignment={"right"} position={"outside"} >
                                      <Font size={11}></Font>
                                    </Label>
                                  </ConstantLine>
                                </ValueAxis>
                                <Legend visible={false}
                                  verticalAlignment="bottom"
                                  horizontalAlignment="center"
                                  itemTextPosition="bottom"/>
                                <Tooltip
                                  enabled={true}
                                  customizeTooltip={customizeTooltip}
                                ></Tooltip>
                              </Chart>
                            : null}
                            { glucoseRadioValue === '4' ?
                              <Chart 
                                  dataSource={patientVitals.filter((p) => p.patientMonitor === monitor.internalID && (new Date(p.readingTime)) >= (new Date(new Date().setDate(new Date().getDate() - 7))))}
                                  customizePoint={customizeGlucosePoints}
                                  id="gcChart">
                                <Title text={monitor.deviceModel}>
                                  <Subtitle text="(Blood Glucose)" />
                                </Title>
                                  
                                <ArgumentAxis
                                    visible={true}
                                    valueMarginsEnabled={true}
                                    title=""
                                    argumentType={'date'}>
                                  <Label wordWrap="none" overlappingBehavior={'stagger'} />
                                </ArgumentAxis>
                                <SeriesTemplate nameField="name" />
                                <CommonSeriesSettings
                                        color={thresholdColors.line}
                                        argumentField="readingTime"
                                        valueField="reading"
                                        type="line"
                                        hoverMode="allArgumentPoints">
                                        <Point size={6} color={thresholdColors.point} />
                                </CommonSeriesSettings>
                                <Crosshair enabled={true}>
                                  <Label visible={true} />
                                </Crosshair>
                                <ValueAxis maxValueMargin={0.1} valueType="number">
                                  <Title text="Blood Glucose (mg/dl)"></Title>
                                  <VisualRange startValue={0} />

                                  <Strip  
                                    startValue={glucoseMaxThreshold}
                                    color={thresholdColors.highBkg}>
                                    <Label text={"Blood Glucose High (>"+glucoseMaxThreshold+" mg/dl)"}
                                      verticalAlignment="top">
                                      <Font size={11}></Font>
                                    </Label>
                                  </Strip>

                                  <Strip  
                                    endValue={glucoseMinThreshold}
                                    color={thresholdColors.lowBkg}>
                                    <Label text={"Blood Glucose Low (≤"+glucoseMinThreshold+" mg/dl)"} verticalAlignment="bottom">
                                      <Font size={11}></Font>
                                    </Label>
                                  </Strip>
                                  
                                  <ConstantLine
                                    width={1.2}
                                    value={glucoseMaxThreshold}
                                    color={thresholdColors.highLine}
                                    dashStyle="dash">
                                     <Label text={"High <br>("+glucoseMaxThreshold+" mg/dl)"} horizontalAlignment={"right"} position={"outside"} >
                                      <Font size={11}></Font>
                                    </Label>
                                  </ConstantLine>
                                  
                                  <ConstantLine
                                    width={1.2}
                                    value={glucoseMinThreshold}
                                    color={thresholdColors.lowLine}
                                    dashStyle="dash">
                                     <Label text={"Low <br>("+glucoseMinThreshold+" mg/dl)"} horizontalAlignment={"right"} position={"outside"} >
                                      <Font size={11}></Font>
                                    </Label>
                                  </ConstantLine>
                                </ValueAxis>
                                <Legend visible={false}
                                  verticalAlignment="bottom"
                                  horizontalAlignment="center"
                                  itemTextPosition="bottom"/>
                                <Tooltip
                                  enabled={true}
                                  customizeTooltip={customizeTooltip}
                                ></Tooltip>
                              </Chart>
                            : null}
                            </React.Fragment>
                            </>
                           : null 
                          }
                          { monitor.deviceName === 'Weight Scale' ?
                            <>
                            <ButtonGroup className="float-end" size={'sm'} >
                              {glucoseRadiosButtons.map((r, idx) => (
                                <ToggleButton key={idx}  id={`r-${idx}`}
                                  type="radio"
                                  name="r"
                                  value={r.value}
                                  variant={glucoseRadioValue === r.value ? "primary" : "outline-primary"}
                                  checked={glucoseRadioValue === r.value}
                                  onChange={onWeekMonthChangeGC}>
                                  {r.name}
                                </ToggleButton>
                              ))}
                            </ButtonGroup>
                            <React.Fragment>
                            { glucoseRadioValue === '6' ?
                              <Chart 
                                  dataSource={patientVitals.filter((p) => p.patientMonitor === monitor.internalID && (new Date(p.readingTime)) >= (new Date(new Date().setDate(new Date().getDate() - 30))))}
                                  customizePoint={customizeGlucosePoints}
                                  id="gcChart">
                                <Title text={monitor.deviceModel}>
                                  <Subtitle text="(Weight)" />
                                </Title>
                                  
                                <ArgumentAxis
                                    visible={true}
                                    valueMarginsEnabled={true}
                                    title=""
                                    argumentType={'date'}>
                                  <Label wordWrap="none" overlappingBehavior={'stagger'} />
                                </ArgumentAxis>
                                <SeriesTemplate nameField="name" />
                                <CommonSeriesSettings
                                        color={thresholdColors.line}
                                        argumentField="readingTime"
                                        valueField="reading"
                                        type="line"
                                        hoverMode="allArgumentPoints">
                                        <Point size={6} color={thresholdColors.point} />
                                </CommonSeriesSettings>
                                <Crosshair enabled={true}>
                                  <Label visible={true} />
                                </Crosshair>
                                <ValueAxis maxValueMargin={0.1} valueType="number">
                                  <Title text="Weight (kgs)"></Title>
                                  <VisualRange startValue={0} />

                                  <Strip  
                                    startValue={glucoseMaxThreshold}
                                    color={thresholdColors.highBkg}>
                                    <Label text={"Weight High (>"+glucoseMaxThreshold+" mg/dl)"}
                                      verticalAlignment="top">
                                      <Font size={11}></Font>
                                    </Label>
                                  </Strip>

                                  <Strip  
                                    endValue={glucoseMinThreshold}
                                    color={thresholdColors.lowBkg}>
                                    <Label text={"Weight Low (≤"+glucoseMinThreshold+" mg/dl)"} verticalAlignment="bottom">
                                      <Font size={11}></Font>
                                    </Label>
                                  </Strip>
                                  
                                  <ConstantLine
                                    width={1.2}
                                    value={glucoseMaxThreshold}
                                    color={thresholdColors.highLine}
                                    dashStyle="dash">
                                     <Label text={"High <br>("+glucoseMaxThreshold+" mg/dl)"} horizontalAlignment={"right"} position={"outside"} >
                                      <Font size={11}></Font>
                                    </Label>
                                  </ConstantLine>
                                  
                                  <ConstantLine
                                    width={1.2}
                                    value={glucoseMinThreshold}
                                    color={thresholdColors.lowLine}
                                    dashStyle="dash">
                                     <Label text={"Low <br>("+glucoseMinThreshold+" mg/dl)"} horizontalAlignment={"right"} position={"outside"} >
                                      <Font size={11}></Font>
                                    </Label>
                                  </ConstantLine>
                                </ValueAxis>
                                <Legend visible={false}
                                  verticalAlignment="bottom"
                                  horizontalAlignment="center"
                                  itemTextPosition="bottom"/>
                                <Tooltip
                                  enabled={true}
                                  customizeTooltip={customizeTooltip}
                                ></Tooltip>
                              </Chart>
                            : null}
                            { glucoseRadioValue === '5' ?
                              <Chart 
                                  dataSource={patientVitals.filter((p) => (p.patientMonitor === monitor.internalID) && (new Date(p.readingTime)) >= (new Date(new Date().setDate(new Date().getDate() - 14))))}
                                  customizePoint={customizeGlucosePoints}
                                  id="gcChart2Weeks">
                                <Title text={monitor.deviceModel}>
                                  <Subtitle text="(Weight)" />
                                </Title>
                                  
                                <ArgumentAxis
                                    visible={true}
                                    valueMarginsEnabled={true}
                                    title=""
                                    argumentType={'date'}>
                                  <Label wordWrap="none" overlappingBehavior={'stagger'} />
                                </ArgumentAxis>
                                <SeriesTemplate nameField="name" />
                                <CommonSeriesSettings
                                        color={thresholdColors.line}
                                        argumentField="readingTime"
                                        valueField="reading"
                                        type="line"
                                        hoverMode="allArgumentPoints">
                                        <Point size={6} color={thresholdColors.point} />
                                </CommonSeriesSettings>
                                <Crosshair enabled={true}>
                                  <Label visible={true} />
                                </Crosshair>
                                <ValueAxis maxValueMargin={0.1} valueType="number">
                                  <Title text="Weight (kgs)"></Title>
                                  <VisualRange startValue={0} />

                                  <Strip  
                                    startValue={glucoseMaxThreshold}
                                    color={thresholdColors.highBkg}>
                                    <Label text={"Weight High (>"+glucoseMaxThreshold+" mg/dl)"}
                                      verticalAlignment="top">
                                      <Font size={11}></Font>
                                    </Label>
                                  </Strip>

                                  <Strip  
                                    endValue={glucoseMinThreshold}
                                    color={thresholdColors.lowBkg}>
                                    <Label text={"Weight Low (≤"+glucoseMinThreshold+" mg/dl)"} verticalAlignment="bottom">
                                      <Font size={11}></Font>
                                    </Label>
                                  </Strip>
                                  
                                  <ConstantLine
                                    width={1.2}
                                    value={glucoseMaxThreshold}
                                    color={thresholdColors.highLine}
                                    dashStyle="dash">
                                     <Label text={"High <br>("+glucoseMaxThreshold+" mg/dl)"} horizontalAlignment={"right"} position={"outside"} >
                                      <Font size={11}></Font>
                                    </Label>
                                  </ConstantLine>
                                  
                                  <ConstantLine
                                    width={1.2}
                                    value={glucoseMinThreshold}
                                    color={thresholdColors.lowLine}
                                    dashStyle="dash">
                                     <Label text={"Low <br>("+glucoseMinThreshold+" mg/dl)"} horizontalAlignment={"right"} position={"outside"} >
                                      <Font size={11}></Font>
                                    </Label>
                                  </ConstantLine>
                                </ValueAxis>
                                <Legend visible={false}
                                  verticalAlignment="bottom"
                                  horizontalAlignment="center"
                                  itemTextPosition="bottom"/>
                                <Tooltip
                                  enabled={true}
                                  customizeTooltip={customizeTooltip}
                                ></Tooltip>
                              </Chart>
                            : null}
                            { glucoseRadioValue === '4' ?
                              <Chart 
                                  dataSource={patientVitals.filter((p) => p.patientMonitor === monitor.internalID && (new Date(p.readingTime)) >= (new Date(new Date().setDate(new Date().getDate() - 7))))}
                                  customizePoint={customizeGlucosePoints}
                                  id="gcChart">
                                <Title text={monitor.deviceModel}>
                                  <Subtitle text="(Weight)" />
                                </Title>
                                  
                                <ArgumentAxis
                                    visible={true}
                                    valueMarginsEnabled={true}
                                    title=""
                                    argumentType={'date'}>
                                  <Label wordWrap="none" overlappingBehavior={'stagger'} />
                                </ArgumentAxis>
                                <SeriesTemplate nameField="name" />
                                <CommonSeriesSettings
                                        color={thresholdColors.line}
                                        argumentField="readingTime"
                                        valueField="reading"
                                        type="line"
                                        hoverMode="allArgumentPoints">
                                        <Point size={6} color={thresholdColors.point} />
                                </CommonSeriesSettings>
                                <Crosshair enabled={true}>
                                  <Label visible={true} />
                                </Crosshair>
                                <ValueAxis maxValueMargin={0.1} valueType="number">
                                  <Title text="Weight (kgs)"></Title>
                                  <VisualRange startValue={0} />

                                  <Strip  
                                    startValue={glucoseMaxThreshold}
                                    color={thresholdColors.highBkg}>
                                    <Label text={"Weight High (>"+glucoseMaxThreshold+" mg/dl)"}
                                      verticalAlignment="top">
                                      <Font size={11}></Font>
                                    </Label>
                                  </Strip>

                                  <Strip  
                                    endValue={glucoseMinThreshold}
                                    color={thresholdColors.lowBkg}>
                                    <Label text={"Weight Low (≤"+glucoseMinThreshold+" mg/dl)"} verticalAlignment="bottom">
                                      <Font size={11}></Font>
                                    </Label>
                                  </Strip>
                                  
                                  <ConstantLine
                                    width={1.2}
                                    value={glucoseMaxThreshold}
                                    color={thresholdColors.highLine}
                                    dashStyle="dash">
                                     <Label text={"High <br>("+glucoseMaxThreshold+" mg/dl)"} horizontalAlignment={"right"} position={"outside"} >
                                      <Font size={11}></Font>
                                    </Label>
                                  </ConstantLine>
                                  
                                  <ConstantLine
                                    width={1.2}
                                    value={glucoseMinThreshold}
                                    color={thresholdColors.lowLine}
                                    dashStyle="dash">
                                     <Label text={"Low <br>("+glucoseMinThreshold+" mg/dl)"} horizontalAlignment={"right"} position={"outside"} >
                                      <Font size={11}></Font>
                                    </Label>
                                  </ConstantLine>
                                </ValueAxis>
                                <Legend visible={false}
                                  verticalAlignment="bottom"
                                  horizontalAlignment="center"
                                  itemTextPosition="bottom"/>
                                <Tooltip
                                  enabled={true}
                                  customizeTooltip={customizeTooltip}
                                ></Tooltip>
                              </Chart>
                            : null}
                            </React.Fragment>
                            </>
                           : null 
                        }  
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Details</Accordion.Header>
                            <Accordion.Body>
                              <DataGrid
                                id="grid-container"
                                width={"100%"}
                                showColumnLines={false}
                                showRowLines={false}
                                showBorders={false}
                                dataSource={patientVitals.filter((p) => p.patientMonitor == monitor.internalID)}
                                focusStateEnabled={true}
                                allowColumnResizing={true}>
                                <Column dataField="name" caption="Name" width={"40%"}/>
                                <Column dataField="dataType" caption="Type" width={"40%"}/>
                                <Column dataField="dataUnits" caption="Units" width={"40%"}/>
                                <Column dataField="reading" caption="Reading" width={"30%"}/>
                                <Column dataField="readingTime" dataType={'date'} caption="Reading Time" width={"30%"} defaultSortOrder="desc"/>
                              </DataGrid>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>


                      </div>
                    );
                  })}
                  </div>{/* end id="VitalsData" */}
                </Col>
              </Row>
                </Col>

                {/* Second page column */}
                <Col md={12} lg={5}>

                    <div className="page-panel">
                      <section>
                        <h2>ACTIVATE Team</h2>
                          {activateTeamList(teamSummary)}
                          {/* IF user has permission to add team members, then show the Link
                              <a href="#">Add ACTIVATE Team Member</a>
                            */}
                      </section>
                    </div>
                    
                    <div className="page-panel">
                      <div className="panel-header">
                        <h2>Most Recent Vitals</h2>
                        {isEnrolled ? 
                          <Anchor className="" onClick={() => props.switchView("patientvitalshistory") }>
                            View History
                          </Anchor>
                        : null }
                      </div>
                      <Form.Label className="font-face-activate-caption">
                        Individual Vitals Readings
                      </Form.Label>
                            <DataGrid
                              id="grid-container"
                              width={"100%"}
                              showColumnLines={false}
                              showRowLines={false}
                              showBorders={false}
                              focusStateEnabled={true}
                              dataSource={recentPatientVitals}
                              allowColumnResizing={true}
                              showColumnHeaders={true}
                              onCellPrepared={formatMostRecentVitals}
                              onCellClick={gridCellClicked}
                              noDataText="No Vitals collected for this patient">
                              <Paging defaultPageSize={6} defaultPageIndex={0} />
                              <Selection mode="single" />
                              <Column dataField="date" dataType={"date"} width={"20%"} caption="Date" />
                              <Column dataField="time" width={"20%"} caption="Time" defaultSortOrder="asc"/>
                              <Column dataField="heartrate" caption="heartrate" width={"20%"} headerCellRender={renderHeartRateBPM} alignment={"center"}/>
                              <Column dataField="bloodpressure" caption="bloodpressure" width={"20%"} headerCellRender={renderBloodPressure} alignment={"center"}/>
                              <Column dataField="glucose" caption="glucose" width={"20%"} headerCellRender={renderBloodGlucose} alignment={"center"}/>
                            </DataGrid>
                    </div>
                    <div className="page-panel">
                      <div className="panel-header">
                        <h2>Vital Data Thresholds</h2>
                          {props.permissions.checkFunctionPermission("PATIENT_PANEL", "THRESHOLDS", "READ") && isEnrolled ?
                          <Anchor onClick={() => props.switchView("patientvitalthresholds") }>
                            Customize
                          </Anchor>
                          : 
                          <></>
                          }   
                            </div>
                              <Form.Label className="font-face-activate-caption">
                                {thresholdType}
                              </Form.Label>
                            <DataGrid
                              id="grid-container"
                              showColumnLines={false}
                              showRowLines={false}
                              dataSource={monitorReadingThresholds}
                              showBorders={false}
                              focusStateEnabled={true}
                              allowColumnResizing={true}
                              showColumnHeaders={true}
                              noDataText="No Vital Data Thresholds stored">
                              <Paging defaultPageSize={4} defaultPageIndex={0} />
                              <Selection mode="single" />
                              <Column dataField="readingName" cellRender={renderVitalGridReadings} caption="Vital Reading" width={"70%"} defaultSortOrder="asc" />
                              <Column dataField="dataType" width={"15%"} caption="Low" cellRender={renderLowValues}/> 
                              <Column dataField="dataUnits" width={"15%"} caption="High" cellRender={renderHighValues} />
                            </DataGrid>
                    </div>
                    <div className="page-panel">
                      <PatientHuddleSchedule
                         permissions={props.permissions}
                         switchView={props.switchView}
                         patientID={props.patientID}
                         setHuddleID={props.setHuddleID}
                         setMeetingID={props.setMeetingID}
                         setSelectedTeamletID={props.setSelectedTeamletID}
                         teamletSummary={teamSummary}
                         isMemberOfTeamLet={isMemberOfTeamLet}
                      />
                    </div>  
                </Col>
            </Row>
      </Form>
      <Modal show={showAddDeviceDialog}>
        <Modal.Header className="rpm">
          <h2>Assign Device to Patient</h2>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <Form noValidate validated={deviceInfovalidated} onSubmit={handleDeviceSubmit} id="device-details">

                  <h2>
                  {deviceAssignedEdit
                    ? "Update Device for " + firstName + " " + lastName + "?"
                    : "Assign Device to " + firstName + " " + lastName + "?"}
                  </h2>

                  <Form.Group className="form-group" controlId="validateDeviceModelName">
                    <Form.Label className="required">
                      Device Model Name
                    </Form.Label>
                    <Form.Select
                      className="font-face-activate-secondary-text"
                      aria-label="deviceToAssign"
                      name="deviceToAssign"
                      value={deviceToAssignName}
                      required
                      onChange={handleDeviceOnDeviceModelChange}>
                      {deviceList}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Device Model Name is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="form-group" controlId="validateDeviceSerialNumber">
                    <Form.Label className="required">
                      Device Serial Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={deviceSerialNumber}
                      required
                      style={{width: "250px" }}
                      onChange={handleDeviceOnSerialNumberChange}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Device Serial Number is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="form-group">
                  <Form.Label>
                    Date Assigned
                  </Form.Label>
                    <DateBox
                      acceptCustomValue={false}
                      openOnFieldClick={true}
                      defaultValue={deviceDateAssigned}
                      inputAttr={inputAttr}
                      onValueChanged={DeviceAssignedDate}
                      style={{width: "200px"}}
                      type="date"
                    />
                    </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
              variant="primary"
              className='rpm submit'
              id="cmdSaveDeviceToPatient"
              form="device-details"
              type="submit">
              {deviceAssignedEdit ? "Update" : "Submit"}
          </Button>
          <Button
              variant="secondary"
              className='rpm cancel'
              id="cmdCloseAddDeviceModal"
              onClick={() => closeAddDeviceModal(false)}>
              Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteDeviceDialog}>
        <Modal.Header className="rpm">
          <h2>Delete Device?</h2>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this device from the Patient? This
          action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button className="rpm submit" variant="primary" onClick={() => deleteDevice(deleteDeviceID)}>Delete</Button>
          <Button className="rpm cancel" variant="secondary" onClick={() => showDeleteModal("", false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showErrorModal}>
          <Modal.Header className="error">
              <h2>{errorMessageTitle}</h2>
          </Modal.Header>
          <Modal.Body>
          {errorMessage}<br/>
          <br/><b>{errorServerMessage}</b><br/>
          <br/>{errorUserMessage}<br/>
          </Modal.Body>
          <Modal.Footer>
          <Button className='error submit' onClick={() => setShowErrorModal(false)}>Ok</Button>
          </Modal.Footer>
      </Modal>
    </Container>
    </div>
  );
}

export default PatientInformation;
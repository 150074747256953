/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { Anchor, Container, Row, Col, Form, Card, Breadcrumb } from 'react-bootstrap';
import { DataGrid, Selection, Column, Scrolling, Paging} from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';
import { faUserGroup, faCalendar} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@ailibs/feather-react-ts';
import OrgHuddleSchedule from '../Shared/Huddle/OrgHuddleSchedule';
import PermissionService from '../../Services/PermissionService';

import './AdminDashboard.css';

const config = require('../../config.json');
let isTopLevelOrg = false;

function AdminDashboard(props : 
    { 
        switchView : (newView : string) => void,
        permissions: PermissionService; 
        selectedOrganization : string,
        selectedOrganizationID : string,
        setSelectedTeamletID: (Id: string) => void,
        setHuddleID:  (newID: string) => void,
        setMeetingID:  (Id: string) => void,
        teamletName: string
    }) {
        
    const [healthCenterName, setHealthCenterName] = useState("");
    const [healthCenterStreet, setHealthCenterStreet] = useState("");
    const [healthCenterCity, setHealthCenterCity] = useState("");
    const [healthCenterState, setHealthCenterState] = useState("");
    const [healthCenterZip, setHealthCenterZip] = useState("");
    const [healthCenterPhoneNumber, setHealthCenterPhoneNumber] = useState("");
    const [healthCenterAddress, setHealthCenterAddress] = useState("");
    const [healthCenterPointOfContact, setHealthCenterPointOfContact] = useState("");
    const [healthCenterPointOfContactEmailAddress, setHealthCenterPointOfContactEmailAddress] = useState("");
    const [healthCenterPointOfContactPhone, setHealthCenterPointOfContactPhone] = useState("");
    const [healthCenterParent, setHealthCenterParent] = useState("");
    const [healthCenterDescription, setHealthCenterDescription] = useState("");
    const [healthStaffSummary, setHealthStaffSummary] = useState([]);
    const [healthCenterSummary, setHealthCenterSummary] = useState([]);
    const [healthcareTeamLetSummary, setHealthcareTeamLetSummary] = useState([]);
    const [myOrgId, setMyOrgId] = useState(props.selectedOrganizationID != null ? props.selectedOrganizationID : ""); //props.selectedOrganizationID;
    const [monitorReadingThresholds, setMonitorReadingThresholds] = useState<any[]>([]);
  
    const [digitalHealthPathwaySummary, setDigitalHealthPathwaySummary] = useState([]);
    const [organizationList, setOrganizations] = useState([]);
    const [organizationIDToView, setOrganizationIDToView] = useState("");
    const [huddleSummary, setHuddleSummary] = useState([]);
    const [assignedPatients, setAssignedPatients] = useState("");
    const [patientsAdded, setPatientsAdded] = useState("");
    const [reportedVitalsToday, setReportedVitalsToday] = useState("");
    
    const token = sessionStorage.getItem('ACTIVATEtoken');

    //  Spinner Icon location
    const position = { of: '#profileInfo' };

    //  User Status
    const [showProgress, setShowProgress] = useState(false);

    //  Load User Profile
    useEffect(() => {
        //  Load UI
        loadUserProfile();
    }, [props.selectedOrganization, props.selectedOrganizationID])
 
    //  Load User Profile Info
    async function loadUserProfile(){
        let orgIdToQuery = ''

        await fetch(`${config.activateServer}:${config.activatePORT}/getUserProfile`, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(_response =>{
            if (!_response.ok){
                throw Error('Could not fetch the data for the resource')
            }
            return _response.json();
        }).then(data =>{
            try {
                if (data != null){
                    //  Check for assigned ID
                    if (props.selectedOrganizationID === ''){
                        setMyOrgId(() => {return data.organizations[0].internalID});
                        orgIdToQuery = data.organizations[0].internalID
                    } else {
                        setMyOrgId(() => {return props.selectedOrganizationID});
                        orgIdToQuery = props.selectedOrganizationID
                    }

                    if (orgIdToQuery != ''){

                         //  Load Summaries Here
                        loadHealthCenterSummary(orgIdToQuery);
                        
                        //  Load Health Staff Grid
                        loadHealthStaffSummary(orgIdToQuery);

                        //  Load Teamlets Grid
                        loadTeamletSummary(orgIdToQuery);
                    }
                }
            } catch(err){
                console.log("Error in loadUserProfile: " + err);
            }
        }).catch(err =>{
            console.log("Error in loadUserProfile: " + err);
        })
    }

    //  Load Huddle Summary
    async function loadHuddleSummary(orgId : string){
        await fetch(`${config.activateServer}:${config.activatePORT}/getHuddleSummary?orgId=` + orgId, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(_response =>{
            if (!_response.ok){
                throw Error('Could not fetch the data for the resource')
            }
            return _response.json();
        }).then(data =>{
            try {
                if (data != null){
                    //  Load Huddle Summary List
                    setHuddleSummary(data);
                }
            } catch(err){
                console.log("Error in loadHuddleSummary: " + err);
            }
        }).catch(err =>{
            console.log("Error in loadHuddleSummary: " + err);
        })
    }

    //  Load HealthStaff Summary
    async function loadHealthStaffSummary(orgId : string){
        await fetch(`${config.activateServer}:${config.activatePORT}/getHealthStaffSummary?orgId=` + orgId, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(_response =>{
            if (!_response.ok){
                throw Error('Could not fetch the data for the resource')
            }
            return _response.json();
        }).then(data =>{
            try {
                if (data != null){
                     //  Load Health Staff Summary List
                    setHealthStaffSummary(data);
                }
            } catch(err){
                console.log("Error in loadHealthStaffSummary: " + err);
            }
        }).catch(err =>{
            console.log("Error in loadHealthStaffSummary: " + err);
        })
        
    }

    //  Load HealthCenter Summary
    async function loadHealthCenterSummary(orgId : string) {
        await fetch(`${config.activateServer}:${config.activatePORT}/getHealthCenterSummary?orgId=` + orgId, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(_response =>{
            if (!_response.ok){
              throw Error('Could not fetch the data for the resource')
            }
            return _response.json();
        }).then(data =>{
            try {
                //  Load Health Center Summary
                setHealthCenterSummary(() => data);
                setHealthCenterName(() => data.name);
                setHealthCenterDescription(() => data.description);
                setHealthCenterCity(() => data.streetAddress.cityName);
                setHealthCenterPhoneNumber(() => data.phoneNumber.number);
                setHealthCenterState(() => data.streetAddress.stateName);
                setHealthCenterStreet(() => data.streetAddress.street);
                setHealthCenterZip(() => data.streetAddress.zipcode);
                
                //  Check for Primary POC
                if (data.primaryPointOfContact != null) {
                    setHealthCenterPointOfContact(() => data.primaryPointOfContact?.firstName + ' ' + data.primaryPointOfContact?.lastName);
                    setHealthCenterPointOfContactEmailAddress(() => data.primaryPointOfContact.emailAddresses[0] == null ? 'NYA' : data.primaryPointOfContact.emailAddresses[0].address);
                    setHealthCenterPointOfContactPhone(() => data.primaryPointOfContact.phoneNumbers[0] == null ? 'NYA' : data.primaryPointOfContact.phoneNumbers[0].number);
                } else {
                    setHealthCenterPointOfContact(() => '');
                    setHealthCenterPointOfContactEmailAddress(() => '');
                    setHealthCenterPointOfContactPhone(() => '');
                }

                if (data.parentOrganization != null) {
                    isTopLevelOrg = false;
                    setHealthCenterParent(() => data.parentOrganization.name);
                } else {
                    setHealthCenterParent(() => data.name);
                    isTopLevelOrg = true;
                }
                setAssignedPatients(() => data.assignedPatientCount === -1 ? 0 : data.assignedPatientCount)
                setPatientsAdded(() => data.patientsAddedThisWeekCount === -1 ? 0 : data.patientsAddedThisWeekCount)
                setReportedVitalsToday(() => data.patientsReportingVitalsTodayCount === -1 ? 0 : data.patientsReportingVitalsTodayCount)

                if (data.monitorReadingThresholds != null) {
                    setMonitorReadingThresholds(() => {return data.monitorReadingThresholds})
                }
            } catch(err){
                console.log("Error in loadHealthCenterSummary: " + err);
            }
        })
        .catch(err =>{
            console.log("Error in loadHealthCenterSummary: " + err);
        })
    }

    //  Load Healthcare Team Summary
    async function loadTeamletSummary(orgId : string){
        setShowProgress(true);

        await fetch(`${config.activateServer}:${config.activatePORT}/getHealthcareTeamSummary?orgId=` + orgId, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(_response =>{
            if (!_response.ok){
                setShowProgress(false);

                throw Error('Could not fetch the data for the resource')
            }
            return _response.json();
        }).then(data =>{
            try {
                if (data != null){
                    //  Load Healthcare Team Summary
                    setHealthcareTeamLetSummary(data);
                    setShowProgress(false);

                }
            } catch(err){
                console.log("Error in loadTeamletSummary: " + err);
                setShowProgress(false);
            }
        }).catch(err =>{
            console.log("Error in loadTeamletSummary: " + err);
            setShowProgress(false);
        })
    }

    //  Load Digital Health Pathway Summary
    async function loadDigitalHealthPathwaySummary(orgId : string){
        await fetch(`${config.activateServer}:${config.activatePORT}/getDigitalHealthPathwaySummary?orgId=` + orgId, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(_response =>{
            if (!_response.ok){
                throw Error('Could not fetch the data for the resource')
            }
            return _response.json();
        }).then(data =>{
            try {
                if (data != null){
                    //  Load DigitalHealth Pathway Summary
                    setDigitalHealthPathwaySummary(data);
                }
            } catch(err){
                console.log("Error in loadDigitalHealthPathwaySummary: " + err);
            }
        }).catch(err =>{
            console.log("Error in loadDigitalHealthPathwaySummary: " + err);
        })
    }

    //  Concats TeamLet Names
    function getTeamlets(e:any){
        let teamNames = ''
        for (let iLp = 0; iLp < e.data.healthCareTeams.length; iLp++){
            if (iLp === 0){
                teamNames = e.data.healthCareTeams[iLp].name
            } else {
                teamNames = teamNames + ', ' + e.data.healthCareTeams[iLp].name
            }
        }
        //  Check if assigned a Teamlet
        if (teamNames != ''){
            return (
                <>{teamNames}</>
            )
        } else {
            return (
                <><label className='titlesDisabled'>na</label></>
            )
        }
    }

    //  Concatenates Name for Health Staff Grid
    function getName(e:any){
        return (
            <div>
                {e.data.firstName + ' ' + e.data.lastName}
            </div>
        )
    }

    //  Assigned Patients
    function getAssignedPatientCount(e:any){
        if (viewHuddleSchedule()){
            return (
                <div>
                    <br/>
                    <p><a href="#" title={e.data.description} id={e.data.internalID} onClick={e => goToTeamletDetails(e)}>{e.data.assignedPatientCount + ' Assigned Patients'}</a></p>
                </div>
            )
        } else {
            return (
                <div>
                    <br/>
                    <p>{e.data.assignedPatientCount + ' Assigned Patients'}</p>
                </div>
            )
        }
        
    }

    //  Child Node connect
    function nestedIcon(){
        return (
            <>
                <FontAwesomeIcon className='nestLine' icon={faUserGroup} style={{color: "navy"}}></FontAwesomeIcon>
            </>
        );
    }

    //  Calendar Icon
    function nestedCalendarIcon(){
        return (
            <>
                <FontAwesomeIcon className='nestLine' icon={faCalendar} style={{color: "darkorange"}}></FontAwesomeIcon>
            </>
        );
    }

    //  Format Huddle Date
    function nextHuddleDateFormated(e:any){
        return (
            <>
                {new Date(e.data.nextHuddleTime).toDateString()}
            </>
        )
    }

    //  Render Vital Readings
    function renderVitalGridReadings(e: any) {
      let _readName = e.data.readingName;
      let _readingTitle = e.data.readingName;

      switch(_readName) {
        case 'BloodGlucose':
          _readingTitle = "Blood Glucose";
          break;
        case 'PulseRate':
          _readingTitle = "Pulse Rate";
          break;
        case 'Systolic':
          _readingTitle = "Systolic Blood Pressure";
          break;
        case 'Diastolic':
          _readingTitle = "Diastolic Blood Pressure";
          break;
        default:
          _readingTitle = _readName;
      }

      return (
        <>
          <p>{_readingTitle}<br />
          <small>{e.data.dataUnits}</small></p>
        </>
      );
    }

    //  Render Low Vital Thresholds
    function renderLowValues(e:any){
        const low = e.data.dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')

        return (
            <>&#8804; {low[0].thresholdValue}</>
        )
    }

    //  Render High Vital Thresholds
    function renderHighValues(e:any){
        const high = e.data.dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')
        return (
            <>&gt;{high[0].thresholdValue}</>
        )
    }
    
    /**
     * @name renderIcon
     * @description Shows the feather icon
     * @param
     * @returns
     */
    function renderIcon(iconName : any, category:string) {
      return (
        <div className={`ui-icon ${category}`}>
          <Icon name={iconName} size="24" />
        </div>
      );
    }

    /**
     * @name goToTeamletDetails
     * @description Goes to Teamlet Details
     * @param
     * @returns
     */
    function goToTeamletDetails(e:any){
        props.setSelectedTeamletID(e.target.id);
        props.switchView("TeamletDetails");
        e.preventDefault()
    }

    /**
     * @name huddledetails
     * @description Goes to Huddle Details
     * @param
     * @returns
     */
    function goToHuddledetails(todaysHuddleID: string, todaysMeetingID: string){
        props.setHuddleID(todaysHuddleID); 
        props.setMeetingID(todaysMeetingID);
        props.switchView("huddledetails");
    }

    /**
     * @name teamletList
     * @description Renders user's assigned Teamlets
     * @param healthcareTeamletSummary
     * @returns Team list HTML
     */
    function teamletList(e: any){
        let pathways = e.data.digitalHealthPathways?.map((pathway: any) => {
            return <><small key={pathway.uniqueId}>{pathway.name}</small><br/></>
        })
        return (
            <ul className="team-list">
                    <li>
                    {renderIcon("users","people")}
                    <p>
                    <a href="#" title={e.data.description} id={e.data.internalID} onClick={e => goToTeamletDetails(e)}>{e.data.name}</a><br/>
                    {e.data.digitalHealthPathways != null ?
                        <>{pathways}</>    
                    : null
                    }
                    </p>
                </li>
            </ul>
        )
    }
    

    //  Gets Today's Huddles
    function getTodaysHuddles(e:any){
        //  Set-up link to Huddle Details
        if (e.data != null && e.data.todaysMeetings != null && e.data.todaysMeetings.length > 0){
            const todaysMeeting = new Date(e.data.todaysMeetings[e.data.todaysMeetings.length-1].time).toLocaleTimeString();
            const todaysMeetingID = e.data.todaysMeetings[e.data.todaysMeetings.length-1].internalID;
            const todaysHuddleID = e.data.todaysMeetings[e.data.todaysMeetings.length-1].huddle.internalID;;
            return (
                <ul className="team-list">
                    <p>{renderIcon("calendar","todo")} <Anchor onClick={(e) => { goToHuddledetails(todaysHuddleID, todaysMeetingID); e.preventDefault(); }} title="Edit Meeting">{todaysMeeting}</Anchor></p>
                </ul>
            )
        } else
        {
            return (
                <ul className="team-list">
                    <br/><p>No huddle scheduled today</p>
                </ul>
            )
        }
    }
//  PERMISSION HOOKS
//  Check if User can Invite Health Staff
function canInviteStaff(){
    return props.permissions.checkFunctionPermission("DASHBOARD", "HEALTH_STAFF", "CREATE");
}
  
//  Check if User can edit Invite Health Staff
function canEditHealthStaff(){
    return props.permissions.checkFunctionPermission("DASHBOARD", "HEALTH_STAFF", "UPDATE");
}

//  Check if User can edit Teamlets
function canEditTeamlets(){
    return props.permissions.checkFunctionPermission("DASHBOARD", "TEAMLET", "UPDATE");
}

//  Check if User can view Huddle Schedule
function viewHuddleSchedule(){
    return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "HUDDLE_SCHEDULE", "READ");
}

    return(
        <Container fluid>
              <Breadcrumb>
                   <Breadcrumb.Item active>{props.selectedOrganization + " Overview "}</Breadcrumb.Item>
              </Breadcrumb>
              <Row>
                <Col lg={7}>
                  <div className="page-panel" id="profileInfo">
                    <LoadPanel visible={showProgress} message={"Loading Teamlets"} showPane={true} position={position} showIndicator={true}></LoadPanel>
        
                        <div className="panel-header">
                            <h2>Teamlets</h2>
                            <Anchor href="#" onClick={(e) => {props.switchView("teamlet");e.preventDefault();}} hidden={!canEditTeamlets()} >
                                Edit
                            </Anchor>
                        </div>
                        <section>
                            <DataGrid id="grid-container"
                                showColumnLines={false} 
                                dataSource={healthcareTeamLetSummary}
                                showRowLines={false}
                                keyExpr="internalID"
                                showBorders={false}
                                allowColumnResizing={true}
                                wordWrapEnabled={false}
                                showColumnHeaders={true}
                                noDataText='No Teamlets found'>
                                    <Scrolling rowRenderingMode='virtual'></Scrolling>
                                    <Paging defaultPageSize={5} defaultPageIndex={0} />
                                    <Column dataField="internalID" caption="Id" visible={false} />
                                    <Column dataField="name" caption="Teamlet" cellRender={e => teamletList(e)} />
                                    <Column width={'25%'} visible={viewHuddleSchedule()} dataField="assignedPatientCount" alignment={'center'} caption="Today's Huddles" cellRender={getTodaysHuddles} />
                                    <Column width={'25%'} dataField="assignedPatientCount2" caption="Total Patients" cellRender={e => getAssignedPatientCount(e)} />
                            </DataGrid>
                            <Anchor href="#" onClick={(e) => {props.switchView("addTeamlet");e.preventDefault();}} >Add ACTIVATE Teamlet</Anchor>
                        </section>

                  </div>
                  <div className="page-panel">
                    <div className="panel-header">
                            <h2>Health Staff</h2>
                            <Anchor href="#" onClick={(e) => {props.switchView("users");e.preventDefault();}} hidden={!canEditHealthStaff()} >
                                Edit
                            </Anchor>
                        </div>
                        <DataGrid id="grid-container" showColumnLines={false} showRowLines={false}
                            keyExpr="internalID"
                            showBorders={false}
                            focusStateEnabled={true}
                            allowColumnResizing={true}
                            wordWrapEnabled={true}
                            dataSource={healthStaffSummary}
                            noDataText='No Health Staff found'>
                                    <Scrolling rowRenderingMode='virtual'></Scrolling>
                                    <Paging defaultPageSize={5} defaultPageIndex={0} />
                                    <Selection mode="single" />
                                    <Column dataField="lastName" caption="Name" cellRender={getName} defaultSortOrder="asc"/>
                                    <Column dataField="userRole.name" caption="Role" />
                                    <Column dataField="teamlet" width={"30%"} caption="Teamlets" cellRender={getTeamlets} />
                        </DataGrid>
                    <br/>
                    <Anchor href="#" onClick={(e) => {props.switchView("enrollmentInvitations");e.preventDefault();}} hidden={!canInviteStaff()} >Invite Health Staff</Anchor>
                </div>
                </Col>
                <Col lg={5}>
                  <div className="page-panel">
                    <div className="panel-header">
                        <h2 className="panel-section">Site Address</h2>
                        <Anchor href="#" onClick={(e) => {props.switchView("adminOrganization");e.preventDefault();} }>
                            Edit
                        </Anchor>
                    </div>
                    <section>
                      
                      <p>
                        <span className="site-name">{healthCenterName}</span><br/>
                        {healthCenterStreet}<br/>
                        {healthCenterCity}, {healthCenterState} {healthCenterZip}<br/>
                        {healthCenterPhoneNumber}
                      </p>
                    </section>
                    <section>
                      <h2 className="panel-section">Point of Contact</h2>
                      <div className="contacts">
                        <p>
                          {healthCenterPointOfContact}<br/>
                          <a href={`mailto:${healthCenterPointOfContactEmailAddress}`}>{healthCenterPointOfContactEmailAddress}</a><br/>
                          {healthCenterPointOfContactPhone}
                        </p>
                      </div>
                    </section>
                    {!isTopLevelOrg ?
                    <section>
                      <h2 className="panel-section">Parent Organization</h2>
                      <div className="site-name">
                       <Anchor href="#" onClick={(e) => {props.switchView("adminOrganization");e.preventDefault();} }>
                            {healthCenterParent}
                        </Anchor>
                       </div>
                      <p>{healthCenterDescription}</p>
                    </section>
                    : <></>}
                    
                    </div>

                    <div className="page-panel">
                        <div className="panel-header">
                            <h2>Vital Data Thresholds</h2>
                            <Anchor href="#" onClick={(e) => {props.switchView("vitalData");e.preventDefault();} }>
                                Edit
                            </Anchor>
                        </div>
                      <Form.Label className="font-face-activate-caption">
                        Organization Defaults
                      </Form.Label>
                      <DataGrid id="grid-container" 
                          showColumnLines={false} showRowLines={false}
                          dataSource={monitorReadingThresholds}
                          showBorders={false}
                          focusStateEnabled={true}
                          allowColumnResizing={true} showColumnHeaders={true}
                          noDataText='No Vital Data Thresholds for this organization'>
                              <Paging defaultPageSize={4} defaultPageIndex={0} />
                              <Selection mode="single" />
                              <Column dataField="readingName" cellRender={renderVitalGridReadings} caption="Vital Reading" width={'70%'} defaultSortOrder="asc"/>
                              <Column dataField="dataType" width={'15%'} caption="Low" cellRender={renderLowValues} />
                              <Column dataField="dataUnits" width={'15%'} caption="High" cellRender={renderHighValues} />
                      </DataGrid>
                    </div>

                    {viewHuddleSchedule() == true ?
                    <div className="page-panel">
                        <OrgHuddleSchedule 
                            selectedOrganizationID={props.selectedOrganizationID}
                            permissions={props.permissions}
                            switchView={props.switchView}
                            teamletID={''}
                            teamletName={props.teamletName}
                            setHuddleID={props.setHuddleID}
                            setMeetingID={props.setMeetingID}
                            setSelectedTeamletID={props.setSelectedTeamletID}/>
                    </div>
                    : null
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default AdminDashboard;

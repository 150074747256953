/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Form, Col, Breadcrumb, Table, Spinner } from 'react-bootstrap';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { Button as DevButton } from 'devextreme-react/data-grid';
import "./Users.css"
import { GroupPanel, Column, Paging, Pager } from 'devextreme-react/data-grid';
import DataGrid from '../Shared/DataGridTable';
import { ACTIVATEADMINISTRATOR, ROLE_ACTIVATEADMINISTRATOR, UNSPECIFIED } from '../Shared/constants'
import PermissionService from '../../Services/PermissionService';
const config = require('../../config.json');


function Users(props: {
    switchView: (newView: string) => void,
    loginUsername: string,
    permissions: PermissionService,
    editUser: (tempLoginInfo: any, tempuserUsername: any, tempuserStatus: any, tempuserRoleTemplate: any,
        tempuserRoleName: any, tempuserOrganizations: any, tempAssignedOrganizations: any, tempuserFirstName: any, tempuserMiddleName: any, tempuserLastName: any,
        tempuserPrefix: any, tempuserSuffix: any, tempuserPreferredName: any,
        tempuserEmail: any, tempuserCellphone: any, tempuserLandlinePhone: any, tempuserInternalID: string, tempuserAssignedOrgIDs: any, properties: Map<string, string>) => void
}) {
    const dgUsers = useRef<any>(null);
    const [userDisplayList, setUserDisplayList] = useState<any[]>([]);
    const [unfilteredList, setUnfilteredList] = useState<any[]>([]);
    const [tableCaptions, setTableCaptions] = useState<any[]>([]);
    const [lookupValue, setLookupValue] = useState('');
    const [roleList, setRoleList] = useState<any[]>([]);
    const [organizationList, setOrganizationList] = useState<any[]>([]);
    const [statusList, setStatusList] = useState<any[]>([]);
    const [statusValue, setStatusValue] = useState('');
    const [roleValue, setRoleValue] = useState('');
    const [organizationValue, setOrganizationValue] = useState('');
    const [healthCenterList, setHealthCenterList] = useState([]);
    const [loginUserInfo, setLoginUserInfo] = useState({});

    useEffect(() => {
        loadUsers();

        setTableCaptions([
            { dataField: "username", caption: "Username" },
            { dataField: "emailAddress", caption: "Email" },
            { dataField: "rolename", caption: "Role" },
            { dataField: "assignedOrganizations", caption: "Organizations" },
            { dataField: "accountStatus", caption: "Status" },
            { dataField: "Last Login", caption: "Last Data" },
            { dataField: "", caption: "Action" }
        ])
    }, [])


    /**
     * @name loadUsers
     * @description Pulls all the currently enrolled users from the server and stores it in an html array
     */
    async function loadUsers() {
        // Start Spinner
        dgUsers.current?.instance.beginCustomLoading();

        const token = sessionStorage.getItem('ACTIVATEtoken');
        try {
            //  Get all HealthCenters
            const hcresponse = await fetch(`${config.activateServer}:${config.activatePORT}/getHealthCenters`, {
                method: 'POST',
                body: JSON.stringify({ token: token }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode: 'cors'
            })
            
            //  Load all Health Centers
            const allHealthcenters = await hcresponse.json();

            //  Get all Users
            const response = await fetch(`${config.activateServer}:${config.activatePORT}/getUsers`, {
                method: 'POST',
                body: JSON.stringify({ token: token }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode: 'cors'
            })

            //  Load Users
            const users = await response.json();

            //console.log("GOT BACK USERS " + JSON.stringify(data));
            //TODO Make sure the edit user has all needed fields filled out and calls function
            //Load the table here
            const userList: any = [];

            for (let i = 0; i < users.length; i++) {
                if (users[i].username === props.loginUsername) {
                    setLoginUserInfo(users[i]);
                }

                const assignedOrganizationsList: any = [];
                const assignedOrganizationIds: any = [];

                users[i].assignedOrganizations.forEach((o: any) => {

                    allHealthcenters.forEach((a: any) => {

                        if (a.internalID === o) {
                            assignedOrganizationIds.push(a.internalID);
                            assignedOrganizationsList.push(a.name);
                        } else {
                            a.sites.forEach((s: any) => {
                                if (s.internalID === o) {
                                    assignedOrganizationIds.push(s.internalID);
                                    assignedOrganizationsList.push(s.name);
                                }
                            })
                        }
                    })
                })

                //  Get the ACTIVATE Admin Parent
                if (users[i]?.role?.value === ROLE_ACTIVATEADMINISTRATOR){
                    const activateAdminResponse = await fetch(`${config.activateServer}:${config.activatePORT}/getActivateAdminHealthCenters`, {
                        method: 'POST',
                        body: JSON.stringify({ token: token }),
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        },
                        mode: 'cors'
                    })
                    
                    //  Check for success response
                    if (activateAdminResponse.ok){

                        //  Load all Health Centers
                        const activateAdminHealthcenters = await activateAdminResponse.json();

                        //  Add Activate Admin Healthcneters to HealthCenter List
                        if (activateAdminHealthcenters != null ){
                            //  Add the ActivateAdmin HC to the List
                            assignedOrganizationIds.push(activateAdminHealthcenters.internalID);
                            assignedOrganizationsList.push(activateAdminHealthcenters.name);
                        }
                    }
                }
      
                let suffix = '';
                if (users[i].suffix !== UNSPECIFIED) suffix = users[i].suffix
                //  Load User List
                userList.push({
                    "id": users[i].internalID,
                    "username": users[i].username,
                    "emailAddress": users[i].emailAddress !== undefined ? users[i].emailAddress.address : null,
                    "rolename": users[i].role.name,
                    "assignedOrganizationList": assignedOrganizationsList,
                    "accountStatus": users[i].accountStatus,
                    "lastLoginTime": users[i].lastLoginTime != null ? new Date(users[i].lastLoginTime).toLocaleString() : '',
                    "name": users[i].firstName + " " + users[i].middleName + " " + users[i].lastName + " " + suffix,
                    "firstName": users[i].firstName,
                    "middleName": users[i].middleName,
                    "lastName": users[i].lastName,
                    "prefix": users[i].prefix,
                    "suffix": users[i].suffix,
                    "preferredName": users[i].preferredName,
                    "cellphone": users[i].cellPhoneNumber,
                    "landline": users[i].landLineNumber,
                    "roleTemplate": users[i].roleTemplate,
                    "emailAddressObj": users[i].emailAddress,
                    "assignedOrgIds": assignedOrganizationIds,
                    "properties": users[i].properties,
                })
            }
            setUserDisplayList(userList);
            setUnfilteredList(userList);
            setHealthCenterList(allHealthcenters);

            const roles = userList.map((r: any, index: number) => { return r.rolename })
            const roleFilter = [...new Set(roles)]
            const organizations = userList.map((r: any, index: number) => {
                if (r.assignedOrganizationList[0] !== undefined) {
                    return r.assignedOrganizationList.toString()
                }
            });
            const orgFilter = [...new Set(organizations.filter((q: any) => { return (q !== undefined) }))];
            const statuses = userList.map((r: any, index: number) => { return r.accountStatus });
            const statusFilter = [...new Set(statuses)];
            setRoleList(roleFilter.map((r: any, index: number) => { return (<option key={index + 1} value={r}>{r}</option>) }));
            //setUnfilteredRoleList(roleFilter.map((r:any, index:number)=>{ return (<option key={index + 1} value={r}>{r}</option>) }));
            setOrganizationList(orgFilter.map((o: any, index: number) => { return (<option key={index + 1} value={o}>{o}</option>) }));
            //setUnfilteredOrganizationList(orgFilter.map((o:any, index:number)=>{return(<option key={index + 1} value={o}>{o}</option>)}));
            setStatusList(statusFilter.map((s: any, index: number) => { return (<option key={index + 1} value={s}>{toCamelCase(s)}</option>) }))
            //setUnfilteredStatusList(statusFilter.map((s:any, index:number)=>{return(<option key={index + 1} value={s}>{s}</option>)}))
        } catch (err) {
            console.log("ERROR " + err);
            // Stop Spinner
            dgUsers.current?.instance.endCustomLoading();
        }

        // Stop Spinner
        dgUsers.current?.instance.endCustomLoading();
    }

    const onValueChanged = (e: any) => {
        if (e.value === "Name or email" || e.value === undefined || e.value === null || e.value === '') return;
        setUserDisplayList(userDisplayList.filter((y: any) => {
            return (y.name === e.value)
        }));
        setLookupValue(e.value);
    }

    const resetFilters = () => {
        setLookupValue('');
        setStatusValue('');
        setRoleValue('');
        setOrganizationValue('');
        setUserDisplayList(unfilteredList);
        getDisplayExpr(null);
    }

    const getDisplayExpr = (item: any) => {
        if (item === undefined || item === null) return "Name or Email";
        return item ? `${item.name} / ${item.emailAddress}` : '';
    }

    const editClick = (e: any) => {
        const user = e.row.data;
        const orgs = [user.assignedOrganizationList]
        props.editUser(loginUserInfo, user.username, user.accountStatus, user.roleTemplate,
            user.rolename, healthCenterList, orgs, user.firstName, user.middleName, user.lastName,
            user.prefix, user.suffix, user.preferredName,
            user.emailAddressObj, user.cellphone, user.landline, user.id, user.assignedOrgIds, user.properties)
    }

    const onRoleChanged = (e: any) => {
        const selectedvalue = e.target.value;
        setRoleValue(selectedvalue);
        let newList = unfilteredList.filter((i) => { return (i.rolename.indexOf(selectedvalue) > -1) });
        if (statusValue !== "") {
            const statusList = newList.filter(((j) => { return (j.accountStatus === statusValue) }));
            newList = statusList;
        }

        if (organizationValue !== "") {
            const organizationList = newList.filter((j) => {
                const stringOrgs = j.assignedOrganizationList.toString();
                if (stringOrgs.includes(organizationValue)) {
                    return (j)
                }
            });
            newList = organizationList;
        }
        setUserDisplayList(newList);
    }

    const onStatusChanged = (e: any) => {
        const selectedvalue = e.target.value;
        setStatusValue(selectedvalue);
        let newList = unfilteredList.filter((i) => { return (i.accountStatus.indexOf(selectedvalue) > -1) });
        if (roleValue !== "") {
            const roleList = newList.filter(((j) => { return (j.rolename === roleValue) }));
            newList = roleList;
        }

        if (organizationValue !== "") {
            const organizationList = newList.filter((j:any) => {
                const stringOrgs = j.assignedOrganizationList.toString();
                if (stringOrgs.includes(organizationValue)) {
                    return (j)
                }
            })
            newList = organizationList;
        }

        setUserDisplayList(newList);
    }

    const onOrganizationChanged = (e: any) => {        
        const selectedvalue = e.target.value;
        setOrganizationValue(selectedvalue);
        let newList: any=[];
        if (selectedvalue === "" || selectedvalue === undefined) {
            newList = unfilteredList;
        } else {
            //let newList = unfilteredList.filter((i) => { return (i.assignedOrganizationList.indexOf(selectedvalue) > -1) });   
            newList = unfilteredList.filter((i:any) => {
                const stringOrgs = i.assignedOrganizationList.toString();
                const stringValue = selectedvalue.toString();
                if (stringOrgs.includes(stringValue)) {
                    return (i)
                }
            })
        }
        if (roleValue !== "") {
            const roleList = newList.filter(((j:any) => { return (j.rolename === roleValue) }));
            newList = roleList;
        }
        if (statusValue !== "") {
            const statusList = newList.filter(((j:any) => { return (j.accountStatus === statusValue) }));
            newList = statusList;
        }
        setUserDisplayList(newList);
    }

    function editOrgLinkClick(e: any) {
        console.log(e.currentTarget.id)
    }

    //  Create HyperLink on Org Name
    function OrgHyperLink(org: any) {
        let orgLinks: any = [];
        if (org.data.assignedOrgIds.length > 0) {
            orgLinks = org.data.assignedOrgIds.map((m: any, index: number) => {
                return (
                    <div key={index} >
                        <a className='plusButton' onClick={editOrgLinkClick} id={m}>{org.data.assignedOrganizationList[index]}</a>
                    </div>
                )
            })
        } else return null
        return orgLinks;
    }

    function editEmailLinkClick(e: any) {
        console.log(e.currentTarget.id)
    }

    //  Create HyperLink on Org Name
    function EmailHyperLink(row: any) {
        return (
            <div>
                <a className='plusButton' onClick={editUsernameLinkClick} id={row.data.id}>{row.data.emailAddress}</a>
            </div>
        )
    }

    function editUsernameLinkClick(e: any) {
        const user = userDisplayList.filter((x: any) => { return (x.id === e.currentTarget.id) });
        const userObj = {
            row: {
                "data": user[0]
            }
        }
        editClick(userObj)
    }

    //  Create HyperLink on Org Name
    function UsernameHyperLink(row: any) {
        return (
            <div><a className='plusButton' id={row.data.id} onClick={editUsernameLinkClick} >{row.data.username}</a></div>
        )
    }

    function toCamelCase(str: string) {
        const formatted = str.toLowerCase()
            // Replaces any - or _ characters with a space 
            .replace(/[-_]+/g, ' ')
            // Removes any non alphanumeric characters 
            .replace(/[^\w\s]/g, '')
            // Uppercases the first character in each group immediately following a space 
            // (delimited by spaces) 
            .replace(/ (.)/g, function ($1) { return $1.toUpperCase(); })
            // Removes spaces 
            .replace(/ /g, '');
        return formatted.substring(0, 1).toUpperCase() + formatted.substring(1);
    }

    function formatStatusText(user: any) {
        const formattedStatus = toCamelCase(user.data.accountStatus);
        return <p style={{ width: "80px" }}>{formattedStatus}</p>
    }

    const renderHeader = (data: any) => {
        return <>{data.column.caption}</>;
    }

    //  Render's Last name and suffix for the Grid
    const renderLastName = (e: any) => {
        if (e.data.suffix != "UNSPECIFIED" &&
                e.data.suffix != '') {
            return <>{e.data.lastName}, {e.data.suffix}</>
  
        } else {
            return <>{e.data.lastName}</>
        }
    }

    return (

        <Container fluid>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => props.switchView("configuration")}>Configuration</Breadcrumb.Item>
                <Breadcrumb.Item active>Users</Breadcrumb.Item>
            </Breadcrumb>
            <div className="page-panel">
                <div className="panel-function-header">
                    <div className="d-flex">

                        <Form.Group className="form-group" controlId="name" style={{ minWidth: "16em" }}>
                            <Form.Label>Name or Email</Form.Label>

                            <Lookup
                                aria-label="Name or Email"
                                items={userDisplayList}
                                valueExpr="name"
                                placeholder="Name or Email"
                                value={lookupValue}
                                displayExpr={getDisplayExpr}
                                onValueChanged={(e) => onValueChanged(e)}
                            >
                                <DropDownOptions showTitle={false} />
                            </Lookup>
                        </Form.Group>

                        <Form.Group className="form-group" controlId="status">
                            <Form.Label>Status</Form.Label>
                            <Form.Select
                                aria-label="Status"
                                value={statusValue}
                                onChange={(e) => { onStatusChanged(e) }}>
                                <option key={0} value={""}>- Any -</option>
                                {statusList}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="form-group" controlId="role">
                            <Form.Label>Role</Form.Label>
                            <Form.Select aria-label="Role"
                                value={roleValue}
                                onChange={(e) => { onRoleChanged(e) }}>
                                <option key={0} value={""}>- Any -</option>
                                {roleList}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="form-group" controlId="organization" style={{ flex: "0 0 33%" }}>
                            <Form.Label>Organization</Form.Label>
                            <Form.Select aria-label="Organization"
                                value={organizationValue}
                                onChange={(e) => { onOrganizationChanged(e) }}>
                                <option key={0} value={""}>- Any -</option>
                                {organizationList}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="form-group reset" controlId="reset">
                            <Button
                                onClick={() => { resetFilters() }}
                                variant="function"
                                className="btn-function-button">
                                Reset
                            </Button>
                        </Form.Group>
                    </div>
                    <Button
                        className="btn-function-button"
                        variant="function"
                        onClick={() => props.switchView("addUsers")} hidden={props.permissions.isEhrOrganization}>
                        Add User
                        
                    </Button>
                </div>

                <DataGrid id="grid-container"
                    ref={dgUsers}
                    keyExpr="username"
                    focusStateEnabled={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    showBorders={false}
                    dataSource={userDisplayList}
                    noDataText='No users have been loaded'>
                    <Column dataField="username" caption="Username" cellRender={UsernameHyperLink} width={"18%"} headerCellRender={renderHeader} />
                    <Column dataField="firstName" caption="First Name" width={"18%"} headerCellRender={renderHeader}/>
                    <Column dataField="lastName" caption="Last Name" width={"18%"} cellRender={e => renderLastName(e)} headerCellRender={renderHeader}/>
                    <Column dataField="emailAddress" caption="Email" width={"20%"} cellRender={EmailHyperLink} headerCellRender={renderHeader} />
                    <Column dataField="rolename" caption="Role" width={"15%"} headerCellRender={renderHeader} />
                    <Column dataField="assignedOrganizationList" caption="Organizations" width={"20%"} headerCellRender={renderHeader} cellRender={OrgHyperLink} defaultSortOrder="asc" />
                    <Column dataField="accountStatus" caption="Status" width={"10%"} cellRender={formatStatusText} headerCellRender={renderHeader} />
                    <Column dataField="lastLoginTime" caption="Last Login" width={"10%"} dataType="date" headerCellRender={renderHeader} />
                    <Column type="buttons" caption="Action" headerCellRender={renderHeader} width={"5%"} >
                        <DevButton hint="edit" icon="edit" onClick={editClick} />
                        <DevButton hint="delete" visible={false} icon="trash" onClick={() => { console.log('trash clicked') }} />
                    </Column>
                    <GroupPanel visible={false} />
                    <Paging
                        defaultPageSize={10}
                        defaultPageIndex={1} />
                    <Pager showNavigationButtons={true} />
                </DataGrid>
            </div>
        </Container>

    )
}

export default Users
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Modal, Form, Card, Breadcrumb } from 'react-bootstrap';
import { BLOODGLUCOSE, DIASTOLIC, PULSERATE, SYSTOLIC } from '../Shared/constants';
import PermissionService from '../../Services/PermissionService';

const config = require('../../config.json');

function PatientVitalThresholds(props: 
    {
        switchView: Function,
        patientID: string,
        permissions: PermissionService
    }){

    const [parentOrgId, setParentOrdId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");

    const [validated, setValidated] = useState(false);
    const [badSubmissionError, setBadSubmissionError] = useState("");

    //  Use to track RPM Device Readings
    const [orgHeartRateID, setOrgHeartRateID] = useState("");
    const [orgHeartRateLow, setOrgHeartRateLow] = useState("");
    const [orgHeartRateHigh, setOrgHeartRateHigh] = useState("");
    const [orgHeartRateLowID, setOrgHeartRateLowID] = useState("");
    const [orgHeartRateHighID, setOrgHeartRateHighID] = useState("");
    const [orgHeartRateMonitorLowID, setOrgHeartRateMonitorLowID] = useState("");
    const [orgHeartRateMonitorHighID, setOrgHeartRateMonitorHighID] = useState("");

    const [orgSystolicID, setOrgSystolicID] = useState("");
    const [orgSystolicBloodPressureLow, setOrgSystolicBloodPressureLow] = useState("");
    const [orgSystolicBloodPressureHigh, setOrgSystolicBloodPressureHigh] = useState("");
    const [orgSystolicLowID, setOrgSystolicLowID] = useState("");
    const [orgSystolicHighID, setOrgSystolicHighID] = useState("");
    const [orgSystolicMonitorLowID, setOrgSystolicMonitorLowID] = useState("");
    const [orgSystolicMonitorHighID, setOrgSystolicMonitorHighID] = useState("");

    const [orgDiastolicID, setOrgDiastolicID] = useState("");
    const [orgDiastolicBloodPressureLow, setOrgDiastolicBloodPressureLow] = useState("");
    const [orgDiastolicBloodPressureHigh, setOrgDiastolicBloodPressureHigh] = useState("");
    const [orgDiastolicLowID, setOrgDiastolicLowID] = useState("");
    const [orgDiastolicHighID, setOrgDiastolicHighID] = useState("");
    const [orgDiastolicMonitorLowID, setOrgDiastolicMonitorLowID] = useState("");
    const [orgDiastolicMonitorHighID, setOrgDiastolicMonitorHighID] = useState("");

    const [orgGlucoseID, setOrgGlucoseID] = useState("");
    const [orgGlucoseLow, setOrgGlucoseLow] = useState("");
    const [orgGlucoseHigh, setOrgGlucoseHigh] = useState("");
    const [orgGlucoseLowID, setOrgGlucoseLowID] = useState("");
    const [orgGlucoseHighID, setOrgGlucoseHighID] = useState("");
    const [orgGlucoseMonitorLowID, setOrgGlucoseMonitorLowID] = useState("");
    const [orgGlucoseMonitorHighID, setOrgGlucoseMonitorHighID] = useState("");

    const [heartRateID, setHeartRateID] = useState("");
    const [heartRateLow, setHeartRateLow] = useState("");
    const [heartRateHigh, setHeartRateHigh] = useState("");
    const [heartRateLowID, setHeartRateLowID] = useState("");
    const [heartRateHighID, setHeartRateHighID] = useState("");
    const [heartRateMonitorIDlow, setHeartRateMonitorIDlow] = useState("");
    const [heartRateMonitorIDhigh, setHeartRateMonitorIDhigh] = useState("");
    const [systolicID, setSystolicID] = useState("");
    const [systolicBloodPressureLow, setSystolicBloodPressureLow] = useState("");
    const [systolicBloodPressureHigh, setSystolicBloodPressureHigh] = useState("");
    const [systolicLowID, setSystolicLowID] = useState("");
    const [systolicHighID, setSystolicHighID] = useState("");
    const [systolicMonitorIDlow, setSystolicMonitorIDlow] = useState("");
    const [systolicMonitorIDhigh, setSystolicMonitorIDhigh] = useState("");
    const [diastolicID, setDiastolicID] = useState("");
    const [diastolicBloodPressureLow, setDiastolicBloodPressureLow] = useState("");
    const [diastolicBloodPressureHigh, setDiastolicBloodPressureHigh] = useState("");
    const [diastolicLowID, setDiastolicLowID] = useState("");
    const [diastolicHighID, setDiastolicHighID] = useState("");
    const [diastolicMonitorIDlow, setDiastolicMonitorIDlow] = useState("");
    const [diastolicMonitorIDhigh, setDiastolicMonitorIDhigh] = useState("");

    const [glucoseID, setGlucoseID] = useState("");
    const [glucoseLow, setGlucoseLow] = useState("");
    const [glucoseHigh, setGlucoseHigh] = useState("");
    const [glucoseLowID, setGlucoseLowID] = useState("");
    const [glucoseHighID, setGlucoseHighID] = useState("");
    const [glucoseMonitorLowID, setGlucoseMonitorLowID] = useState("");
    const [glucoseMonitorHighID, setGlucoseMonitorHighID] = useState("");

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showErrorMessage, setErrorMessage] = useState("");
    const [action, setAction] = useState("");
    const [defaultDataLoaded, setDefaultDataLoaded] = useState(false);

    //  Load Patient information
    useEffect(() => {
        if (props.patientID != '') {
            loadPatientAccount(props.patientID);
        }
    }, [])

    //  Load default thresholds for Parent Org
    useEffect(() => {
        if (parentOrgId != '') {
            loadVitalDefaultData(parentOrgId)
        }
    }, [parentOrgId])

    //  Load default thresholds for Parent Org
    useEffect(() => {
        if (defaultDataLoaded){
            loadPatientVitalData(props.patientID)
        }
        
    }, [defaultDataLoaded])


    /**
     * @name loadPatientAccount
     * @description Loads the selected Patient's account info
     * @param
     * @returns {json}
    */
    async function loadPatientAccount(patientID: string) {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getPatientSummaryById`,
        {
            method: "POST",
            body: JSON.stringify({ token: token, patientID: patientID }),
            headers: { "Content-Type": "application/json",
            Accept: "application/json",
            },
            mode: "cors",
        });
    
        try {
            const data = await response.json();

            //  Fields that can be updated
            setFirstName(data.firstName);
            setMiddleName(data.middleName);
            setLastName(data.lastName);
            setParentOrdId(data.organization.internalID)
        } catch (err) {
            console.log("ERROR " + err);
        }
    }

    /**
     * @name loadPatientVitalData
     * @description Pulls in the Vital Data for the Patient
     * @param patientID
     * @returns {}
    */
    async function loadPatientVitalData(patientIDToLoad: string) {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        await fetch(`${config.activateServer}:${config.activatePORT}/getVitalData`, {
            method: 'POST',
            body: JSON.stringify({ token : token, orgID : patientIDToLoad }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(async (_response: { ok: any; json: () => any; status: { toString: () => string; }; }) => {
            //  Check for Success
            if (_response.ok)
            {
                try {
                    const data = await _response.json();
                    //  Check for Patient specific thresholds
                    if ( data != null && data.length > 0){
                        for(let i = 0; i < data.length; i++){
                            if(data[i].readingName === PULSERATE){
                                setHeartRateID(data[i].internalID);
                                setHeartRateHigh(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue);
                                setHeartRateLow(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue);
                                setHeartRateLowID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].internalID);
                                setHeartRateHighID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].internalID);
                                setHeartRateMonitorIDlow(data[i].dataValues[0].monitorReadingThreshold);
                                setHeartRateMonitorIDhigh(data[i].dataValues[0].monitorReadingThreshold);
            
                            } else if(data[i].readingName === SYSTOLIC){
                                setSystolicID(data[i].internalID);
                                setSystolicBloodPressureHigh(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue);
                                setSystolicBloodPressureLow(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue);
                                setSystolicLowID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].internalID);
                                setSystolicHighID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].internalID);
                                setSystolicMonitorIDlow(data[i].dataValues[0].monitorReadingThreshold);
                                setSystolicMonitorIDhigh(data[i].dataValues[0].monitorReadingThreshold);
            
                            } else if(data[i].readingName === DIASTOLIC){
                                setDiastolicID(data[i].internalID);
                                setDiastolicBloodPressureHigh(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue);
                                setDiastolicBloodPressureLow(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue);
                                setDiastolicLowID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].internalID);
                                setDiastolicHighID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].internalID);
                                setDiastolicMonitorIDlow(data[i].dataValues[0].monitorReadingThreshold);
                                setDiastolicMonitorIDhigh(data[i].dataValues[0].monitorReadingThreshold);
            
                            } else if(data[i].readingName === BLOODGLUCOSE){
                                setGlucoseID(data[i].internalID);
                                setGlucoseHigh(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue);
                                setGlucoseLow(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue);
                                setGlucoseLowID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].internalID);
                                setGlucoseHighID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].internalID);
                                setGlucoseMonitorLowID(data[i].dataValues[0].monitorReadingThreshold);
                                setGlucoseMonitorHighID(data[i].dataValues[0].monitorReadingThreshold);
 
                            }

                            setAction("UPDATE")
                        }
                    } else {
                        setHeartRateHigh(orgHeartRateHigh);
                        setHeartRateLow(orgHeartRateLow);
                        setDiastolicBloodPressureHigh(orgDiastolicBloodPressureHigh);
                        setDiastolicBloodPressureLow(orgDiastolicBloodPressureLow);
                        setSystolicBloodPressureHigh(orgSystolicBloodPressureHigh);
                        setSystolicBloodPressureLow(orgSystolicBloodPressureLow);
                        setGlucoseHigh(orgGlucoseHigh);
                        setGlucoseLow(orgGlucoseLow);
                        setAction("ADD")
                    }
                    
                } catch(err){
                    console.log("Error in loadStatusOptions: " + err);
                }
            }
            else {
                //Handle non-200s
                console.log("Received HTTP status " + _response.status.toString())
            }
        })
    }

    /**
     * @name loadVitalData
     * @description Pulls in the Vital Data values
     * @param OrdId
     * @returns {}
    */
    async function loadVitalDefaultData(OrdId : string) {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getVitalData`, {
            method: 'POST',
            body: JSON.stringify({ token : token, orgID : OrdId }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(async (r: { ok: any; json: () => any; status: { toString: () => string; }; }) => {
            //  Check for Success
            if (r.ok)
            {
                try {
                    const data = await r.json();
        
                    for(let i = 0; i < data.length; i++){
                        if(data[i].readingName === PULSERATE){
                            setOrgHeartRateID(data[i].internalID);
                            setOrgHeartRateHigh(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue);
                            setOrgHeartRateLow(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue);
                            setOrgHeartRateLowID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].internalID);
                            setOrgHeartRateHighID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].internalID);
                            setOrgHeartRateMonitorLowID(data[i].dataValues[0].monitorReadingThreshold);
                            setOrgHeartRateMonitorHighID(data[i].dataValues[0].monitorReadingThreshold);
                        } else if(data[i].readingName === SYSTOLIC){
                            setOrgSystolicID(data[i].internalID);
                            setOrgSystolicBloodPressureHigh(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue);
                            setOrgSystolicBloodPressureLow(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue);
                            setOrgSystolicLowID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].internalID);
                            setOrgSystolicHighID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].internalID);
                            setOrgSystolicMonitorLowID(data[i].dataValues[0].monitorReadingThreshold);
                            setOrgSystolicMonitorHighID(data[i].dataValues[0].monitorReadingThreshold);
                        } else if(data[i].readingName === DIASTOLIC){
                            setOrgDiastolicID(data[i].internalID);
                            setOrgDiastolicBloodPressureHigh(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue);
                            setOrgDiastolicBloodPressureLow(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue);
                            setOrgDiastolicLowID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].internalID);
                            setOrgDiastolicHighID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].internalID);
                            setOrgDiastolicMonitorLowID(data[i].dataValues[0].monitorReadingThreshold);
                            setOrgDiastolicMonitorHighID(data[i].dataValues[0].monitorReadingThreshold);
                        } else if(data[i].readingName === BLOODGLUCOSE){
                            setOrgGlucoseID(data[i].internalID);
                            setOrgGlucoseHigh(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue);
                            setOrgGlucoseLow(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue);
                            setOrgGlucoseLowID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].internalID);
                            setOrgGlucoseHighID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].internalID);
                            setOrgGlucoseMonitorLowID(data[i].dataValues[0].monitorReadingThreshold);
                            setOrgGlucoseMonitorHighID(data[i].dataValues[0].monitorReadingThreshold);
                        }
                    }
                    
                    setDefaultDataLoaded(true);

                } catch(err){
                    console.log("Error in loadStatusOptions: " + err);
                }
            }
            else {
                // Handle non-200s
                console.log("Received HTTP status " + r.status.toString())
            }
        })
    }
   
    /**
     * @name addEachVitalData
     * @description Used to send each individual vital data
     * @param OrdId
     * @returns {}
    */
    function addEachVitalData(){
        if (action === "ADD") {
            
            addSpecificVitalData(heartRateID, PULSERATE, "bpm", heartRateHigh, heartRateLow, heartRateHighID, heartRateLowID, heartRateMonitorIDhigh, heartRateMonitorIDlow).catch((error) => {
                // Handle the error
                console.log(error);
            });
            addSpecificVitalData(systolicID, SYSTOLIC, "mmHg", systolicBloodPressureHigh, systolicBloodPressureLow, systolicHighID, systolicLowID, systolicMonitorIDhigh, systolicMonitorIDlow).catch((error) => {
                // Handle the error
                console.log(error);
            });
            addSpecificVitalData(diastolicID, DIASTOLIC, "mmHg", diastolicBloodPressureHigh, diastolicBloodPressureLow, diastolicHighID, diastolicLowID, diastolicMonitorIDhigh, diastolicMonitorIDlow).catch((error) => {
                // Handle the error
                console.log(error);
            });
            addSpecificVitalData(glucoseID, BLOODGLUCOSE, "mg/dl", glucoseHigh, glucoseLow, glucoseHighID, glucoseLowID, glucoseMonitorHighID, glucoseMonitorLowID).catch((error) => {
                // Handle the error
                console.log(error);
            });

        } else if (action === "UPDATE") {
            updateSpecificVitalData(heartRateID, PULSERATE, "bpm", heartRateHigh, heartRateLow, heartRateHighID, heartRateLowID, heartRateMonitorIDhigh, heartRateMonitorIDlow).catch((error) => {
                // Handle the error
                console.log(error);
            });
            updateSpecificVitalData(systolicID, SYSTOLIC, "mmHg", systolicBloodPressureHigh, systolicBloodPressureLow, systolicHighID, systolicLowID, systolicMonitorIDhigh, systolicMonitorIDlow).catch((error) => {
                // Handle the error
                console.log(error);
            });
            updateSpecificVitalData(diastolicID, DIASTOLIC, "mmHg", diastolicBloodPressureHigh, diastolicBloodPressureLow, diastolicHighID, diastolicLowID, diastolicMonitorIDhigh, diastolicMonitorIDlow).catch((error) => {
                // Handle the error
                console.log(error);
            });
            updateSpecificVitalData(glucoseID, BLOODGLUCOSE, "mg/dl", glucoseHigh, glucoseLow, glucoseHighID, glucoseLowID, glucoseMonitorHighID, glucoseMonitorLowID).catch((error) => {
                // Handle the error
                console.log(error);
            });
        }
    }

    /**
    * @name validateFormValues
    * @description Checks for correct High and Low Values
    * @param
    * @returns {boolean}
    */
    function validateFormValues(){

        //  Pulse Rate Low is greater than the High
        if (parseInt(heartRateLow) > parseInt(heartRateHigh)){
            setErrorMessage(`The Low Pulse Rate value (${parseInt(heartRateLow)}) cannot be greater than the High value (${parseInt(heartRateHigh)})`);
            setShowErrorModal(true)
            return false;
        }

        //  Systolic Low is greater than the High
        if (parseInt(systolicBloodPressureLow) > parseInt(systolicBloodPressureHigh)){
            setErrorMessage(`The Low Systolic Blood Pressure value (${parseInt(systolicBloodPressureLow)}) cannot be greater than the High value (${parseInt(systolicBloodPressureHigh)})`);
            setShowErrorModal(true)
            return false;
        }

        //  Diastolic Low is greater than the High
        if (parseInt(diastolicBloodPressureLow) > parseInt(diastolicBloodPressureHigh)){
            setErrorMessage(`The Low Diastolic Blood Pressure value (${parseInt(diastolicBloodPressureLow)}) cannot be greater than the High value (${parseInt(diastolicBloodPressureHigh)})`);
            setShowErrorModal(true)
            return false;
        }

        //  Glucose Low is greater than the High
        if (parseInt(glucoseLow) > parseInt(glucoseHigh)){
            setErrorMessage(`The Low Blood Glucose value (${parseInt(glucoseLow)}) cannot be greater then the High value (${parseInt(glucoseHigh)})`);
            setShowErrorModal(true)
            return false;
        }

        return true;
    }
    
    /**
     * @name updateSpecificVitalData
     * @description Used for updating specific thresholds
     * @param OrdId
     * @returns {}
    */
    async function updateSpecificVitalData(vitalID : string, vitalName : string, vitalMeasure : string, vitalsHigh : string, vitalsLow : string,
        vitalsHighID : string, vitalsLowID : string, monitorReadingHigh : string, monitorReadingLow : string){
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/putVitalData`, {
            method: 'POST',
            body: JSON.stringify({ 
                token : token,
                parentOrgID : props.patientID,
                vitalID,
                vitalName,
                vitalMeasure,
                vitalsHigh,
                vitalsLow,
                vitalsHighID,
                vitalsLowID,
                monitorReadingHigh,
                monitorReadingLow
            }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(response => {
            //  Check for valid response
            if (response.ok){
                try {
                    console.log("GOT RESPONSE " + JSON.stringify(response));
                } catch (err) {
                    setBadSubmissionError("Error: " + err);
                }
            } else {
                setBadSubmissionError("Error: An unknown error occurred.");
            }
        }).then(data => {
            props.switchView("patientInformation");
        }).catch((error) => {
            // Handle the error
            console.log(error);
        });
    }

     /**
     * @name addSpecificVitalData
     * @description Used to add Patient specific thresholds
     * @param 
     * @returns {}
    */
    async function addSpecificVitalData(vitalID : string, vitalName : string, vitalMeasure : string, vitalsHigh : string, vitalsLow : string,
        vitalsHighID : string, vitalsLowID : string, monitorReadingHigh : string, monitorReadingLow : string){
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/postpatientVitalData`, {
            method: 'POST',
            body: JSON.stringify({ 
                token : token,
                parentOrgID : props.patientID,
                vitalID,
                vitalName,
                vitalMeasure,
                vitalsHigh,
                vitalsLow,
                vitalsHighID,
                vitalsLowID,
                monitorReadingHigh,
                monitorReadingLow
            }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(response => {
            //  Check for valid response
            if (response.ok){
                try {
                    console.log("GOT RESPONSE " + JSON.stringify(response));
                } catch (err) {
                    setBadSubmissionError("Error: " + err);
                }
            } else {
                setBadSubmissionError("Error: An unknown error occurred.");
            }
        }).then(data => {
            props.switchView("patientInformation");
        }).catch((error) => {
            // Handle the error
            console.log(error);
        });
    }
   
    /**
    * @name showWarningModal
    * @description Shows/Hides warning pop-up
    * @param  
    * @returns 
    */
    function showWarningModal(toggle : boolean) {
        setShowErrorModal(toggle);
    }

    /**
    * @name handleSubmit
    * @description Submits the form for validation and then POSTS the Vital Data
    * @param  
    * @returns 
    */
    const handleSubmit = async (event : any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
          event.stopPropagation();
          setValidated(true);
        } else {
            //  Validate High and Lows
            if (validateFormValues()){
                addEachVitalData();
            } else {
                event.stopPropagation();
                setValidated(true);
                return false;
            }
        }
        //  Reset validate
        setValidated(true);
    };

    /**
    * @name returnToPatient
    * @description Return to Patient Panel
    * @param  
    * @returns 
    */
    function returnToPatient() {
        props.switchView("patientInformation")
    }

    /**
    * @name resetHeartRateToDefault
    * @description Resets to Default
    * @param  
    * @returns 
    */
    function resetHeartRateToDefault() {
        setHeartRateHigh(orgHeartRateHigh);
        setHeartRateLow(orgHeartRateLow);
    }

    /**
    * @name resetSystolicBPToDefault
    * @description Resets to Default
    * @param  
    * @returns 
    */
    function resetSystolicBPToDefault() {
        setSystolicBloodPressureHigh(orgSystolicBloodPressureHigh);
        setSystolicBloodPressureLow(orgSystolicBloodPressureLow);
    }

    /**
    * @name resetDiastolicBPToDefault
    * @description Resets to Default
    * @param  
    * @returns 
    */
    function resetDiastolicBPToDefault() {
        setDiastolicBloodPressureHigh(orgDiastolicBloodPressureHigh);
        setDiastolicBloodPressureLow(orgDiastolicBloodPressureLow);
    }

    /**
    * @name resetBloodGlucoseToDefault
    * @description Resets to Default
    * @param  
    * @returns 
    */
    function resetBloodGlucoseToDefault() {
        setGlucoseHigh(orgGlucoseHigh);
        setGlucoseLow(orgGlucoseLow);    
    }

    /**
    * @name render
    * @description Render the page here
    * @param  
    * @returns 
    */
    return(

      <div>
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => props.switchView("patientpanel")}>Patients</Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => returnToPatient()}>{firstName} {lastName}</Breadcrumb.Item>
                    <Breadcrumb.Item active>Vitals Data Thresholds</Breadcrumb.Item>
                </Breadcrumb>

                <div className="page-panel">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                      <div className="threshold-box">
                        <a className="reset" role="button" onClick={() => resetHeartRateToDefault()  }>Reset to Default</a>
                        <h2>Pulse Rate</h2>
                        <div className="threshold-group">
                          <div className="threshold-title">
                            <div><Form.Label>Threshold</Form.Label></div>
                            <div><span className="visually-hidden">Comparison</span></div>
                            <div><Form.Label className="required">Value</Form.Label></div>
                          </div>

                          <div className="threshold-setting">
                            <div className="setting-label"><Form.Label>Low</Form.Label></div>
                            <div>Less than or equal to</div>
                            <Form.Group controlId="lowPulseThresholdValue">
                                <Form.Control
                                    type="number"
                                    maxLength={3}
                                    htmlSize={3}
                                    min={0}
                                    max={999}
                                    as="input"
                                    required
                                    value={heartRateLow}
                                    onChange={(e) => setHeartRateLow(e.target.value)}/> bpm
                                <Form.Control.Feedback type="invalid">
                                    Low threshold must be between 0 and 999.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="threshold-setting">
                            <div className="setting-label"><Form.Label>High</Form.Label></div>
                            <div>Greater than</div>
                            <Form.Group controlId="highPulseThresholdValue">
                                <Form.Control
                                    type="number"
                                    maxLength={3}
                                    htmlSize={3}
                                    min={0}
                                    max={999}
                                    as="input"
                                    required
                                    value={heartRateHigh}
                                    onChange={(e) => setHeartRateHigh(e.target.value)}/> bpm
                                <Form.Control.Feedback type="invalid">
                                    High threshold must be between 0 and 999.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <br/>
                      <div className="threshold-box">
                        <a className="reset" role="button" onClick={() => resetSystolicBPToDefault()  }>Reset to Default</a>
                        <h2>Systolic Blood Pressure</h2>
                        <div className="threshold-group">
                          <div className="threshold-title">
                            <div><Form.Label>Threshold</Form.Label></div>
                            <div><span className="visually-hidden">Comparison</span></div>
                            <div><Form.Label className="required">Value</Form.Label></div>
                          </div>

                          <div className="threshold-setting">
                            <div className="setting-label"><Form.Label>Low</Form.Label></div>
                            <div>Less than or equal to</div>
                            <Form.Group controlId="lowSystolicThresholdValue">
                                <Form.Control
                                    type="number"
                                    maxLength={3}
                                    htmlSize={3}
                                    min={1}
                                    max={999}
                                    as="input"
                                    required
                                    value={systolicBloodPressureLow}
                                    onChange={(e) => setSystolicBloodPressureLow(e.target.value)}/> mmHg
                                <Form.Control.Feedback type="invalid">
                                    Low threshold must be between 1 and 999.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </div>

                          <div className="threshold-setting">
                            <div className="setting-label"><Form.Label>High</Form.Label></div>
                            <div>Greater than</div>
                            <Form.Group controlId="highSystolicThresholdValue">
                                <Form.Control
                                    type="number"
                                    maxLength={3}
                                    htmlSize={3}
                                    min={1}
                                    max={999}
                                    as="input"
                                    required
                                    value={systolicBloodPressureHigh}
                                    onChange={(e) => setSystolicBloodPressureHigh(e.target.value)}/> mmHg
                                <Form.Control.Feedback type="invalid">
                                    High threshold must be between 1 and 999.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <br/>
                      <div className="threshold-box">
                        <a className="reset" role="button" onClick={() => resetDiastolicBPToDefault()  }>Reset to Default</a>
                        <h2>Diastolic Blood Pressure</h2>
                        <div className="threshold-group">
                          <div className="threshold-title">
                            <div><Form.Label>Threshold</Form.Label></div>
                            <div><span className="visually-hidden">Comparison</span></div>
                            <div><Form.Label className="required">Value</Form.Label></div>
                          </div>

                          <div className="threshold-setting">
                            <div className="setting-label"><Form.Label>Low</Form.Label></div>
                            <div>Less than or equal to</div>
                            <Form.Group controlId="lowDiastolicThresholdValue">
                                <Form.Control
                                    type="number"
                                    maxLength={3}
                                    htmlSize={3}
                                    min={1}
                                    max={999}
                                    as="input"
                                    required
                                    value={diastolicBloodPressureLow}
                                    onChange={(e) => setDiastolicBloodPressureLow(e.target.value)}/> mmHg
                                <Form.Control.Feedback type="invalid">
                                    Low threshold must be between 1 and 999.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </div>

                          <div className="threshold-setting">
                            <div className="setting-label"><Form.Label>High</Form.Label></div>
                            <div>Greater than</div>
                            <Form.Group controlId="highDiastolicThresholdValue">
                                <Form.Control
                                    type="number"
                                    maxLength={3}
                                    htmlSize={3}
                                    min={1}
                                    max={999}
                                    as="input"
                                    required
                                    value={diastolicBloodPressureHigh}
                                    onChange={(e) => setDiastolicBloodPressureHigh(e.target.value)}/> mmHg
                                <Form.Control.Feedback type="invalid">
                                    High threshold must be between 1 and 999.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <br/>
                      <div className="threshold-box">
                        <a className="reset" role="button" onClick={() => resetBloodGlucoseToDefault()  }>Reset to Default</a>
                        <h2>Blood Glucose</h2>
                        <div className="threshold-group">
                          <div className="threshold-title">
                            <div><Form.Label>Threshold</Form.Label></div>
                            <div><span className="visually-hidden">Comparison</span></div>
                            <div><Form.Label className="required">Value</Form.Label></div>
                          </div>

                          <div className="threshold-setting">
                            <div className="setting-label"><Form.Label>Low</Form.Label></div>
                            <div>Less than or equal to</div>
                            <Form.Group controlId="lowGlucoseThresholdValue">
                                <Form.Control
                                    type="number"
                                    maxLength={3}
                                    htmlSize={3}
                                    min={1}
                                    max={999}
                                    as="input"
                                    required
                                    value={glucoseLow}
                                    onChange={(e) => setGlucoseLow(e.target.value)}/> mg/dl
                                <Form.Control.Feedback type="invalid">
                                    Low threshold must be between 1 and 999.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </div>

                          <div className="threshold-setting">
                            <div className="setting-label"><Form.Label>High</Form.Label></div>
                            <div>Greater than</div>
                            <Form.Group controlId="highGlucoseThresholdValue">
                                <Form.Control
                                    type="number"
                                    maxLength={3}
                                    htmlSize={3}
                                    min={1}
                                    max={999}
                                    as="input"
                                    required
                                    value={glucoseHigh}
                                    onChange={(e) => setGlucoseHigh(e.target.value)}/> mg/dl
                                <Form.Control.Feedback type="invalid">
                                    High threshold must be between 1 and 999.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    <br/>
                    {badSubmissionError.length > 0 ? 
                      <p className="validation-error">{badSubmissionError}</p>
                      : <></> 
                    }

                    <div className="form-actions">
                        <Button className='people submit'
                            variant="primary"
                            type="submit">
                            Save
                        </Button>
                        <Button className='people cancel'
                            variant="secondary"
                            id="cancelButton"
                            type="button"
                            onClick={() => returnToPatient()}>
                            Cancel
                        </Button>
                    </div>
                                          
                    </Form>
                  </div>
            </Container>
            <Modal show={showErrorModal}>
                <Modal.Header className="error">
                    <h2>Invalid Low and High Values</h2>
                </Modal.Header>
                <Modal.Body>
                    {showErrorMessage}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="submit error" onClick={() => showWarningModal(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}


export default PatientVitalThresholds;
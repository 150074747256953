/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from 'react';
import { Button, Form, Card, Navbar, Nav, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ModalErrorMessage } from '../Shared/MessageBox/ModalErrorMessage';
const config = require('../../config.json');
const ACTIVATElogo = require('../../Resources/ACTIVATE_logo_small.png');

function ForgotUsername() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [retreiveStatus, setRetreiveStatus] = useState(false);
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorServerMessage, setErrorServerMessage] = useState("");
    const [errorUserMessage, setErrorUserMessage] = useState("");
    const [messageType, setMessageType] = useState("error");

    function changeValue(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    function returnToSignIn() {
        navigate('/login', {state: {}});
    }

    async function retreiveUsername() {
        //console.log(`FETCHING ${config.activateServer}:${config.activatePORT}/retreiveUsername`);
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/retreiveUsername`, {
            method: 'POST',
            body: JSON.stringify({ email : email }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        });

        try {
            const data = await response.json();
            if (response.status !== 200) {
                setErrorMessageTitle("Error Retreiving Username");
                setErrorMessage("An error occurred while locating your username.");
                setErrorServerMessage(data.message);
                setErrorUserMessage("");
                setShowErrorModal(true);
            } else {
                setRetreiveStatus(true);
            }
        } catch (err) {
            console.log("ERROR " + err);
        }
    }

    return (
        <div className="activate-background">
            <Navbar id="navContainer">
                <Container>
                    <Nav>
                        <img id="ACTIVATElogo" src={ACTIVATElogo} alt="ACTIVATElogo"></img>
                    </Nav>
                </Container>
            </Navbar>
            <Container className="d-flex justify-content-center">
                    {retreiveStatus ? 
                    <Card className="login-card">
                    <Card.Body>
                        <Card.Title>
                            <h1>Username sent!</h1>
                            <p>We've sent you a message with your username.</p>
                            <p>Look for a message from ACTIVATE - it contains your username and a link to take you to sign in to ACTIVATE.</p>
                        </Card.Title>
                        <Form>
                            <Form.Group className="login-actions">
                                <Button className="submit-button" onClick={() => returnToSignIn()}>Back to Sign In</Button>
                            </Form.Group>
                        </Form>
                        </Card.Body>
                    </Card>
                    :
                    <Card className="login-card">
                    <Card.Body>
                        <Card.Title>
                            <h1>Forgot your username?</h1>
                            <p>Enter the email address associated with your account.  We'll send
                            you a reminder with your username.</p>
                        </Card.Title>
                        <Form>
                            <Form.Group className="form-group">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    id="email"
                                    name="email"
                                    onChange={changeValue}
                                    value={email}
                                    placeholder="Enter your email" />
                            </Form.Group>
                            <Form.Group className="login-actions">
                                <Button
                                    className="submit-button"
                                    disabled={email.length <= 0}
                                    onClick={() => retreiveUsername()}>
                                        Send Username
                                </Button>
                            </Form.Group>
                            <Form.Group className="login-footer">
                                <Button
                                    onClick={() => returnToSignIn()}
                                    variant="link">
                                        Back to Sign In
                                </Button>
                            </Form.Group>
                        </Form>
                        </Card.Body>
                    </Card>
                    }
            </Container>

            <ModalErrorMessage
                showErrorModal={showErrorModal}
                errorMessageTitle={errorMessageTitle}
                errorMessage={errorMessage}
                errorServerMessage={errorServerMessage}
                errorUserMessage={errorUserMessage}
                messageType={messageType}
                setShowErrorModal={setShowErrorModal}/>
        </div>
    );
}

export default ForgotUsername;
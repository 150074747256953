import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Table, Card, Breadcrumb } from 'react-bootstrap';
const config = require('../../config.json');

function PatientDashboard(){
    return(
        <Container className="d-flex justify-content-center">
            <Card className="login-card">
                <Card.Body>
                    <Card.Title>
                        <h1>ACTIVATE Patient</h1><hr/>
                    </Card.Title>
                    <p>To view your data, please use the MyACTIVATE mobile application.</p>
                </Card.Body>
            </Card>
        </Container>

    )
}


export default PatientDashboard;
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Container, Button, Form, Breadcrumb, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataGrid, { Column, GroupPanel, Pager, Paging, Grouping } from 'devextreme-react/data-grid';
import { Autocomplete } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
const config = require('../../../config.json');

function PatientRegistrationSelect(props: {
  switchView: (newView: string) => void,
  setSelectedPatientID: (ParentId: string) => void
}) {
  const [lookupSearchValue, setLookupSearchValue] = useState('');
  const [patientLookup, setPatientLookUp] = useState<any>([]);
  const [patientList, setPatientList] = useState<CustomStore>();
  //  Paging variables
  const [pageSize, setPageSize] = useState(20);
  const [pageIndex, setPageIndex] = useState(0);
  const [patientId, setPatientId] = useState('');
  //  Store internalIDs for paging
  const [mapState, setMapState] = useState(new Map());
  const dgPatients = useRef<any>(null);
  const token = sessionStorage.getItem('ACTIVATEtoken');

  useEffect(() => {
    loadUnregisteredPatients();
  }, []);

  useEffect(() => {
      loadUnregisteredPatients();
  }, [patientId]);

  useEffect(() => {
    loadUnregisteredPatientsByValue();
  }, [lookupSearchValue]);

  /**
   * loadUnregisteredPatients
  */
  async function loadUnregisteredPatients() {
    await fetch(`${config.activateServer}:${config.activatePORT}/getUnregisteredPatients`, {
        method: 'POST',
        body: JSON.stringify({ token : token, pageIndex, pageSize, patientid : patientId }),
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        mode : 'cors'
    }).then(async response => {
        //  Check for Success
        if (response.ok)
        {
            try {
                const _json = await response.json();
                setPatientList(_json.content);
            } catch (err) {
                console.log("ERROR " + err);
            }
        } else {
            // ToDo:  Handle non-200s
            console.log("Received HTTP status" + response.status.toString());
        }
    }).catch(err =>{
        console.log("Error in getting unregistered patients: " + err);
    });
  }

  /**
   * loadUnregisteredPatientsByValue
   * @returns 
   */
  async function loadUnregisteredPatientsByValue() {
    //  Abort controller to close long fetch calls
    const controller = new AbortController();
    const token = sessionStorage.getItem('ACTIVATEtoken');
   
    //  Call when 3 or more characters are entered
    if (((lookupSearchValue !== null) && (lookupSearchValue.length >= 3))) {

        //  Fetch values
        const fetchData = async () => {
            await fetch(`${config.activateServer}:${config.activatePORT}/getUnregisteredPatientsByValue`, {signal: controller.signal, 
                method: 'POST',
                body: JSON.stringify({ token: token, pattern: lookupSearchValue}),
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode : 'cors'
            }).then(response => {
                //  Check for Success
                if (!response.ok) {
                    throw Error('Could not fetch the data for the resource');
                }
                return response.json();
            }).then(data =>{
                //  Copy data to store state?
                const tempData = data;
                setPatientLookUp(() => tempData);

                //  Clear map
                mapState.clear();

                //  Loop through each Patient capture internalID
                for (let i = 0; i < data.length; i++) {
                    //  Load the map here
                    mapState.set(data[i].value, data[i].internalID);
                }
            }).catch(err =>{
                controller.abort();
            });
        };

        // call the function
        fetchData().then(() => {
            //console.log("Loaded....");
        }).catch(console.error);
    } 

    return () => {
        controller.abort();
    };
  }

/**
 * @name onSearchValueChanged
 * @description  Ignore backspaces, otherwise search gets call repeatedly
 * @param  
 * @returns 
*/
function onSearchValueChanged(e: any) {
  //  Check if Search value was cleared
  if (e.value === null) {
      setLookupSearchValue('');
      setPatientId('');
      setPageIndex(0);
  } else {
      setLookupSearchValue(() => (e.value));
  }
}

/**
 * @name onSelectionChanged
 * @description  AutoComplete Selection Change
 * @param  
 * @returns 
*/
const onSelectionChanged = useCallback((e:any) => {
  if (e.selectedItem != null) {  
      if (mapState.has(e.selectedItem.value)) {
          const internalID = mapState.get(e.selectedItem.value);
          setPatientId(() => internalID);
      }  
  }
}, []);

/**
 * @name patientInfo
 * @description  Displays Patient Info for AutoComplete
 * @param  
 * @returns 
*/
function patientInfo(item:any) {
  return (
      <div>
          <div>{item.value}</div>
      </div>
  );
}

/**
 * @name pageIndexChange
 * @description  Grid PageIndex change event
 * @param  
 * @returns 
*/
function pageIndexChange(e:any) {
  setPageIndex(e);
}

/**
 * @name renderPatientLink
 * @description  Patient Panel Name column render
 * @param  
 * @returns 
*/
function renderPatientLink(e: any) {
  return (
    <div>
        <a
          onClick={() => {props.setSelectedPatientID(e.data.internalID); props.switchView('patientRegistrationAdd');}}
          className='plusButton' id={e.data.internalID}>{e.data.firstName + ' ' + e.data.middleName + ' ' + e.data.lastName}
        </a>
    </div>
  );
}

  return (
    <div>
      <Container fluid>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => props.switchView("configuration")}>Configuration</Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => props.switchView('patientRegistration')}>Patient Registration</Breadcrumb.Item>
          <Breadcrumb.Item active>Register Patient</Breadcrumb.Item>
        </Breadcrumb>

        <div className="page-panel">
            <div className="panel-function-header">
                <div className="d-flex">
                    <Form.Group className="form-group" controlId="lookup">
                        <Form.Label>Name</Form.Label>
                        <Autocomplete className="form-group"
                            id="autocomplete"
                            width={350}
                            value={lookupSearchValue}
                            dataSource={patientLookup}
                            showClearButton={true}
                            maxItemCount={1000}
                            valueExpr="value"
                            searchMode = "startswith"
                            searchExpr = "lastName"
                            minSearchLength={3}
                            searchTimeout={600}
                            maxLength={20}
                            itemRender={patientInfo}
                            placeholder="Enter Patient's Last Name..."
                            onSelectionChanged={(e) => { onSelectionChanged(e); }}
                            onValueChanged = {(e) => { onSearchValueChanged(e); }}>
                        </Autocomplete>
                    </Form.Group>
                </div>
            </div>

            <DataGrid id="grid-container"
              keyExpr="internalID"
              ref={dgPatients}
              focusStateEnabled={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={false}
              dataSource={patientList}
              remoteOperations={true}
              wordWrapEnabled={true}
              noDataText='No patients are available to enroll in ACTIVATE'>
                <Paging defaultPageSize={10} 
                  onPageIndexChange={pageIndexChange} 
                  pageIndex={pageIndex} />
                <GroupPanel visible={false} />
                <Grouping autoExpandAll={false} />
                <Column dataField="name" caption="Name" width={"25%"} cellRender={renderPatientLink} defaultSortOrder={'asc'} />
                <Column dataField="dateOfBirth" caption="DOB" width={"10%"} />
                <Pager showNavigationButtons={true} displayMode='full' allowedPageSizes='all' showPageSizeSelector={false}/>
            </DataGrid>
        </div>

      </Container>
    </div>
  );
}

export default PatientRegistrationSelect;
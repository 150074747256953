/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { getTeamletByID } from "../../../Services/TeamletServices";
const config = require("../../../config.json");


function ModalRemovePatientFromMeeting(props: {
  showRemovePatientModal: boolean,
  setShowRemovePatientModal: Function,
  internalID: string,
  teamletID: string,
  name: string,
  description: string,
  teamletTime: string,
  teamletName: string,
  patientName: string,
  patientID: string,
  criteria: string,
  meetingID: string,
  toggleRemoveModal: () => void,
  huddleDeleted: (meetingID: string) => void
}) {

  const [errorText, setErrorText] = useState("");
  
  async function removePatientFromMeeting() {
    const token = sessionStorage.getItem('ACTIVATEtoken');

    await fetch(`${config.activateServer}:${config.activatePORT}/removePatientFromHuddle`, {
      method: 'POST',
      body: JSON.stringify({
        token: token,
        internalID: props.internalID
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      mode: 'cors'
    }).then(response => {
      //console.log("GOT REMOVE PATIENT FROM MEETING REPLY " + JSON.stringify(response));
      if (response.ok) {
        try {
          // 
          props.setShowRemovePatientModal(false);
          props.toggleRemoveModal();
          props.huddleDeleted(props.meetingID);
        } catch (err) {
          setErrorText("Error: " + err);
        }
      } else {
        //  Error returned from data service, display to user
        response.json().then((data: any) => {
          //  Show Modal
          setErrorText('Error deleting huddle');
        });
      }
    });

  }

  return (
    <Modal show={props.showRemovePatientModal}>
      <Modal.Header className="error">
        <h2>Remove Patient From Huddle</h2>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col><p>Patient Name<br /></p></Col>
            <Col>{props.patientName}</Col>
          </Row>
          <Row>
            <Col><p>Reason</p></Col>
            <Col>{props.criteria}</Col>
          </Row>
          <Row>
            <Col><p>Date and Time</p></Col>
            <Col>{props.teamletTime}</Col>
          </Row>
          <Row>
            <Col><p>Teamlet</p></Col>
            <Col>{props.teamletName}</Col>
          </Row>
          <Row>
            <Col><p>Name</p></Col>
            <Col>{props.name}</Col>
          </Row>
          <Row>
            <Col><p>Description</p></Col>
            <Col>{props.description}</Col>
          </Row>
          <Row><br/></Row>
          <Row>
            <Col><label>Are you sure you want to remove {props.patientName} from this huddle?</label></Col>
          </Row>
          {errorText.length > 0 ?
            <Row>
              <Col>{errorText}</Col>
            </Row>
            : <></>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="error submit" onClick={() => removePatientFromMeeting()}>Remove</Button>
        <Button
          className='system cancel'
          id="cancelButton"
          variant="secondary"
          type="button"
          onClick={() => { props.setShowRemovePatientModal(false); props.toggleRemoveModal() }}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalRemovePatientFromMeeting;

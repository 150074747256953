import { ModalErrorMessage } from '../Shared/MessageBox/ModalErrorMessage';
/* eslint-disable @typescript-eslint/no-var-requires */
import { TextBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Form, Breadcrumb, Modal } from 'react-bootstrap';
import './AddOrganization.css';
import { UNSPECIFIED } from '../Shared/constants';
const config = require('../../config.json');
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance() 
                , PNF = require('google-libphonenumber').PhoneNumberFormat
                , PNT = require('google-libphonenumber').PhoneNumberType

function AddOrganization(props: 
    {
        switchView: (newView : string) => void,
        cancelEdit : () => void;
        editOrgID : string;
        editOrg : boolean;
        editOrgName : string;
        editOrgShortName : string;
        editOrgDescription : string;
        editOrgParentOrganization : string;
        editOrgPrimaryPointOfContact : string;
        editOrgSecondaryPointOfContact : string;
        editOrgAddress : string;
        editOrgTownCity : string;
        editOrgZip : string;
        editOrgState : string;
        editOrgPhone : string;
        editpatientsAssigned : boolean;
        editdevicesAssigned : boolean;
        editisParent : boolean;
        editStreetAddressID : string;
        editPhoneNumberID : string;
        validate : boolean;
        editType: string;
        editOrgStatus: string;
        ehrassociated: boolean;
    }) 
{
    const [validated, setValidated] = useState(false);
    const [editOrg, setEditOrg] = useState(props.editOrg ? props.editOrg : false); 
    const [editOrgID, setEditOrgID] = useState(props.editOrg ? props.editOrgID : "");
    const [editOrgName, setEditOrgName] = useState(props.editOrg ? props.editOrgName : "");
    const [editOrgShortName, setEditOrgShortName] = useState(props.editOrg ? props.editOrgShortName : "");
    const [editOrgDescription, setEditOrgDescription] = useState(props.editOrg ? props.editOrgDescription : "");
    const [editOrgParentOrganizationID, setEditOrgParentOrganizationID] = useState(props.editOrgParentOrganization);   // Set always? ParentOrg Id
    const [editOrgParentOrganizationName, setEditOrgParentOrganizationName] = useState(""); // ParentOrg Name to set the DropDownList
    const [editOrgPrimaryPointOfContact, setEditOrgPrimaryPointOfContact] = useState(props.editOrg ? props.editOrgPrimaryPointOfContact : "");
    const [editOrgSecondaryPointOfContact, setEditOrgSecondaryPointOfContact] = useState(props.editOrg ? props.editOrgSecondaryPointOfContact : "");
    const [editOrgAddress, setEditOrgAddress] = useState(props.editOrg ? props.editOrgAddress : "");
    const [editOrgTownCity, setEditOrgTownCity] = useState(props.editOrg ? props.editOrgTownCity : "");
    const [editOrgZip, setEditOrgZip] = useState(props.editOrg ? props.editOrgZip : "");
    const [editOrgState, setEditOrgState] = useState(props.editOrg ? props.editOrgState : UNSPECIFIED);
    const [editOrgPhone, setEditOrgPhone] = useState(props.editOrg ? props.editOrgPhone : "");
    const [editpatientsAssigned, setEditPatientsAssigned] = useState(props.editOrg ? props.editpatientsAssigned : false);
    const [devicesAssigned, setEditDevicesAssigned] = useState(props.editOrg ? props.editdevicesAssigned : false);
    const [editisParent, setEditIsParent] = useState(props.editisParent);
    const [editStreetAddressID, setEditStreetAddressID] = useState(props.editOrg ? props.editStreetAddressID : "");
    const [editPhoneNumberID, setEditPhoneNumberID] = useState(props.editOrg ? props.editPhoneNumberID : "");
    const [stateDisplayList, setstateDisplayList] = useState<any[]>([]);
    const [statusDisplayList, setstatusDisplayList] = useState<any[]>([]);
    const [editType, setEditType] = useState(props.editType);
    const [editOrgStatus, setEditOrgStatus] = useState(props.editOrgStatus);
    const [showInactiveModalPrompt, setShowInactiveModalPrompt] = useState(false);
    const [showInactiveModalReasonForChangePrompt, setShowInactiveModalReasonForChangePrompt] = useState(false);
    const [primaryandSecondaryPOCList, setPrimaryandSecondaryPOCList] = useState<any[]>([]);
    const [orgPrimaryPointOfContactName, setOrgPrimaryPointOfContactName] = useState("");
    const [orgSecondaryPointOfContactName, setOrgSecondaryPointOfContactName] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorServerMessage, setErrorServerMessage] = useState("");
    const [errorUserMessage, setErrorUserMessage] = useState("");
  
    //  Load Look-ups
    useEffect(() => {
        //  Load State Options
        loadLookup('USState');
       
        //  Load the proper POCs options
        if (editType === 'Organization') {
            loadPrimaryandSeconsdaryPOCs();
        } else {
            loadSuperUsers();
        }

        //  Load the Org/Site Status options
        loadStatusOptions();
    }, [])

    /**
    * @name handleSubmit
    * @description Handle Submit with extra Phone Checks
    * @param  
    * @returns 
    */
    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();

        //   Phone Validation test
        const elements = form.elements;
        let isPhoneValid = true;
        
        //  Loop through form elements
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i]
            //  Get the phone number input
            if (element.type === "text" && element.id ==='validationOrgPhone') {
                //  Let blanks display normal error message
                if (element.value.trim().length === 0) {
                    //  Let normal form validation handle message
                    element.nextElementSibling.innerHTML = (editType === "Organization" ? "Please enter the Organization Phone (xxx) xxx-xxxx." : "Please enter the Site Phone (xxx) xxx-xxxx.")
                } else {
                    //  Check for valid phone
                    if (!validPhoneNumber(element.value)) 
                    {
                        element.nextElementSibling.innerHTML = (editType === "Organization" ? "INVALID Format.  Please enter the Organization Phone (xxx) xxx-xxxx." : "INVALID Format.  Please enter the Site Phone (xxx) xxx-xxxx.")
                        element.classList.add('is-invalid');
                        isPhoneValid = false
                    } else {
                        element.classList.remove('is-invalid');
                        element.nextElementSibling.innerHTML = (editType === "Organization" ? "Please enter the Organization Phone (xxx) xxx-xxxx." : "Please enter the Site Phone (xxx) xxx-xxxx.")
                        isPhoneValid = true
                    }
                }

                break;
            }
        }

        //  Check if Phone valid
        if (!isPhoneValid){
            //  Set form validation
            setValidated(true);
            return;
        }
       
        //  Check for valid form
        if (form.checkValidity() === false) {
            event.stopPropagation();

            //  Set form validation
            setValidated(true);
        }
        else {
            //  Check Form mode edit vs. add
            if (props.editOrg){
                saveEditOrganization();
            } else {
                addOrganization();
            }
        }
    };

    /**
    * @name validPhoneNumber
    * @description Standard Phone Validation Check
    * @param  
    * @returns 
    */
    function validPhoneNumber(e:any){
        // https://www.ronaldjamesgroup.com/blog/phone-numbers-in-javascript-using-e164-libphonenumber-and-microdata
        // https://www.npmjs.com/package/google-libphonenumber
        let isValid = true;
      
        try {
            const tel = phoneUtil.parse(e, 'US');
            //console.log(phoneUtil.format(tel, PNF.INTERNATIONAL));
            //console.log(phoneUtil.format(tel, PNF.NATIONAL));
            //console.log(phoneUtil.format(tel, PNF.E164));
            isValid = phoneUtil.isValidNumber(phoneUtil.parse(e, 'US'));
        } catch (e) {
            isValid = false
        }

        return isValid;
    }
    
    /**
    * @name closeAddOrganizationForm
    * @description Closes Form
    * @param  
    * @returns 
    */
    function closeAddOrganizationForm(){
        //  Reset Default Org Type
        setEditType("Organization")
        props.switchView("adminOrganization");
    }

    /**
    * @name cancelAddOrganizationForm
    * @description Handle User Selects Cancel
    * @param  
    * @returns 
    */
    function cancelAddOrganizationForm(){
        props.cancelEdit();
        //  Close Form
        closeAddOrganizationForm();
    }

    /**
    * @name orgStateSelectChange
    * @description Organization Changes States
    * @param  
    * @returns 
    */
    function orgStateSelectChange(event : React.ChangeEvent<HTMLSelectElement>){
        setEditOrgState(event.target.value);
    }

    /**
    * @name orgStateSelectChange
    * @description Organization Changes from Active, Inactive, etc.
    * @param  
    * @returns 
    */
    function orgStatusSelectChange(event : React.ChangeEvent<HTMLSelectElement>){
        const status = event.target.value;

        //  Check if Status has changed
        if (status !== editOrgStatus){

            //  Prompt for INACTIVE Status
            if (status === "INACTIVE"){
                setShowInactiveModalPrompt(true)
            } else {
                setEditOrgStatus(status);
            }
        }
    }

    /**
    * @name orgPrimaryPOCSelectChange
    * @description Organization Changes from Active, Inactive, etc.
    * @param  
    * @returns 
    */
    function orgPrimaryPOCSelectChange(event : React.ChangeEvent<HTMLSelectElement>){
        const primaryInternalID = event.target.options[event.target.selectedIndex].getAttribute('data-value') ? event.target.options[event.target.selectedIndex].getAttribute('data-value') : "";
        const primaryPOCName = event.target.options[event.target.selectedIndex].getAttribute('data-name') ? event.target.options[event.target.selectedIndex].getAttribute('data-name') : "";
        setEditOrgPrimaryPointOfContact(primaryInternalID as string);
        setOrgPrimaryPointOfContactName(primaryPOCName as string);
    }

    /**
    * @name orgSecondaryPOCSelectChange
    * @description Handle Secondary POC change event
    * @param  
    * @returns 
    */
    function orgSecondaryPOCSelectChange(event : React.ChangeEvent<HTMLSelectElement>){
        const secondaryInternalID = event.target.options[event.target.selectedIndex].getAttribute('data-value') ? event.target.options[event.target.selectedIndex].getAttribute('data-value') : "";
        const secondaryPOCName = event.target.options[event.target.selectedIndex].getAttribute('data-name') ? event.target.options[event.target.selectedIndex].getAttribute('data-name') : "";
        setEditOrgSecondaryPointOfContact(secondaryInternalID as string);
        setOrgSecondaryPointOfContactName(secondaryPOCName as string);
    }

    /**
    * @name hideInactiveModalPrompt
    * @description Hide Modal UI
    * @param  
    * @returns 
    */
    function hideInactiveModalPrompt(e:any){
        setShowInactiveModalPrompt(e);
    }

    /**
    * @name setInactiveStatusConfirmed
    * @description Confirm the Inactive Selection
    * @param  
    * @returns 
    */
    function setInactiveStatusConfirmed(){
        setEditOrgStatus("INACTIVE");
        setShowInactiveModalPrompt(false);
        setShowInactiveModalReasonForChangePrompt(true);
    }

    /**
    * @name hideInactiveModalReasonForChangePrompt
    * @description Hide Modal UI
    * @param  
    * @returns 
    */
    function hideInactiveModalReasonForChangePrompt(){
        setShowInactiveModalReasonForChangePrompt(false);
    }


    /**
    * @name this.loadLookup()
    * @description Loads the lookups for Dropdowns
    * @param  
    * @returns {json} 
    */
    async function loadLookup(lookup: string) {
        const token = sessionStorage.getItem('ACTIVATEtoken');

        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getValues`, {
            method: 'POST',
            body: JSON.stringify({ token: token, type: lookup }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        })

        try {
            const data = await response.json();
            const _stateList = [];
            switch (lookup) {
                case 'USState':
                    for (let i = 0; i < data.length; i++) {
                        _stateList.push(
                            <option
                                key={data[i].value}
                                value={data[i].value}
                                data-name={data[i].value}
                                data-value={data[i].value}>
                                {data[i].name}
                            </option>
                        )
                    }
     
                    //  Load State List
                    setstateDisplayList(_stateList);
                    break;
                default:
                    break;

            }

        } catch (err) {
            console.log("ERROR " + err);
        }
    }
    
    
    /**
    * @name LoadPOCsByRole
    * @description Load POCs By Role
    * @param  
    * @returns 
    */
    async function LoadPOCsByRole(roleName: string){
        const token = sessionStorage.getItem('ACTIVATEtoken');
        await fetch(`${config.activateServer}:${config.activatePORT}/getPrimaryandSecondaryPOCs?role=` + roleName + `&orgId=` + editOrgID, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(async (response: { ok: any; json: () => any; status: { toString: () => string; }; }) => {
            //  Check for Success
            if (response.ok)
            {
                try {
                    const data = await response.json();
                    const primaryandSeconsdaryPOCList = [];

                    for (let i = 0; i < data.length; i++) {
                        //  Set Primary POC
                        if (data[i].internalID === editOrgPrimaryPointOfContact){
                            setOrgPrimaryPointOfContactName(data[i].firstName + ' ' + data[i].lastName)
                        }

                        //  Set Secondary POC
                        if (data[i].internalID === editOrgSecondaryPointOfContact){
                            setOrgSecondaryPointOfContactName(data[i].firstName + ' ' + data[i].lastName)
                        }
                      
                        primaryandSeconsdaryPOCList.push(
                            <option
                                key={i}
                                data-name={data[i].firstName + ' ' + data[i].lastName}
                                data-value={data[i].internalID}>
                                    {data[i].firstName + ' ' + data[i].lastName}
                            </option>
                        )
                    }
    
                    //  Load Primary and Seconsdary POC List
                    setPrimaryandSecondaryPOCList(primaryandSeconsdaryPOCList);
                } catch(err){
                    console.log("Error in loadStatusOptions: " + err);
                }
            }
            else {
                // ToDo:  Handle non-200s
                console.log("Received HTTP status" + response.status.toString())
            }
        })
    }
 
    /**
    * @name loadSuperUsers
    * @description Load Organization Super User POCs
    * @param  
    * @returns 
    */
    async function loadSuperUsers() {
        const pocID = props.editOrgPrimaryPointOfContact
        LoadPOCsByRole('ROLE_ORGANIZATION_SUPER_USER')
    }

    /**
    * @name loadPrimaryandSeconsdaryPOCs
    * @description Load Site Primary and Secondary POCs
    * @param  
    * @returns 
    */
    async function loadPrimaryandSeconsdaryPOCs(){
        const pocID = props.editOrgSecondaryPointOfContact
        LoadPOCsByRole('ROLE_ORGANIZATION_ADMINISTRATOR')
    }

    /**
     * @name validateZipCode
     * @description Update Pin method to store state
     * @param HTMLInputElement element 
     * @returns {string} 
     */
     function validateZipCode(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
        const re = /^[0-9\b]+$/;

        //  Check for numbers only
        if (e.target.value === '' || re.test(e.target.value)) {
            setEditOrgZip(e.target.value)
        } else {
            return false
        }
    }

    /**
    * @name loadStatusOptions
    * @description Load Status List
    * @param  
    * @returns 
    */
    async function loadStatusOptions () {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        await fetch(`${config.activateServer}:${config.activatePORT}/getEntityStatus`, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(async (response: { ok: any; json: () => any; status: { toString: () => string; }; }) => {
            //  Check for Success
            if (response.ok)
            {
                try {
                    const data = await response.json();
                    const statusList = [];
                    for (let i = 0; i < data.length; i++) {
                        statusList.push(
                            <option
                                key={i}
                                data-name={data[i].value}
                                data-value={data[i].value}>
                                    {data[i].value}
                            </option>
                        )
                    }
    
                    //  Load State List
                    setstatusDisplayList(statusList);
                } catch(err){
                    console.log("Error in loadStatusOptions: " + err);
                }
            }
            else {
                // Handle non-200s
                setErrorMessageTitle("Error Loading Options")
                setErrorMessage("An error occurred loading status options [" + response.status.toString() + "].");
                setErrorServerMessage("");
                setErrorUserMessage("Please check your network connection.");
                showErrorMessage(true);
            }
        })
    }

    /**
    * @name saveEditOrganization
    * @description  Save Organization
    * @param  
    * @returns 
    */
    async function saveEditOrganization(){
        const token = sessionStorage.getItem('ACTIVATEtoken');
        try {
            await fetch(`${config.activateServer}:${config.activatePORT}/updateHealthCenter`, {
                method: 'POST',
                body: JSON.stringify({ 
                    token : token,
                    orgInternalID : editOrgID,
                    orgName : editOrgName,
                    orgShortName : editOrgShortName,
                    orgDescription : editOrgDescription,
                    orgParentOrganization : editOrgParentOrganizationID,
                    orgPrimaryPointOfContact : editOrgPrimaryPointOfContact,
                    orgSecondaryPointOfContact : editOrgSecondaryPointOfContact,
                    orgAddress : editOrgAddress,
                    orgTownCity : editOrgTownCity,
                    orgZip : editOrgZip,
                    orgState : editOrgState,
                    orgPhone : editOrgPhone,
                    orgAcceptsPatients : editpatientsAssigned,
                    orgAcceptsDevices : devicesAssigned,
                    orgRequiresParent : editisParent,
                    orgStreetAddressID : editStreetAddressID,
                    orgPhoneNumberID : editPhoneNumberID,
                }),
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode : 'cors'
            }).then(res => {
                try {
                    //  Check for Success
                    if (res.ok) {
                       //  Switching the view in this then block was required to fix UI refresh/timing issues
                        setEditType("Organization")
                        props.switchView("adminOrganization")
                    } else {
                        //  Error returned from data service, display to user
                        res.json().then(data => {
                            //  Create Message
                            const _statusCode = res.status.toString();
                            const _statusmessage = data.message
                            setErrorMessageTitle("Error Saving Organization")
                            setErrorMessage("An error occurred saving the organization [" + editOrgName + "]")
                            setErrorServerMessage(_statusmessage + " (Code: " + _statusCode + ")");
                            setErrorUserMessage("Please check the data you entered, and re-try.");
                            
                            //  Show Modal
                            showErrorMessage(true);
                        })
                    }
                }
                catch (err) {
                    console.log("Error in saveEditOrganization: Saving Organization " + JSON.stringify(err));
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    /**
    * @name addOrganization
    * @description  Add Organization
    * @param  
    * @returns 
    */
    async function addOrganization() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        try {
            await fetch(`${config.activateServer}:${config.activatePORT}/postNewHealthCenter`, {
                method: 'POST',
                body: JSON.stringify({ 
                    token : token,
                    orgName : editOrgName,
                    orgShortName : editOrgShortName,
                    orgDescription : editOrgDescription,
                    orgParentOrganization : editOrgParentOrganizationID,
                    orgPrimaryPointOfContact : editOrgPrimaryPointOfContact,
                    orgSecondaryPointOfContact : editOrgSecondaryPointOfContact,
                    orgAddress : editOrgAddress,
                    orgTownCity : editOrgTownCity,
                    orgZip : editOrgZip,
                    orgState : editOrgState,
                    orgPhone : editOrgPhone,
                    orgAcceptsPatients : editpatientsAssigned,
                    orgAcceptsDevices : devicesAssigned,
                    orgRequiresParent : editisParent
                }),
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode : 'cors'
            }).then(res => {
                try {
                    //  Check for Success
                    if (res.ok) {
                        //  Save succeess, switch views
                        setEditType("Organization")
                        props.switchView("adminOrganization")
                    } else {
                        //  Error returned from data service, display to user
                        res.json().then(data => {
                            //  Create Message
                            const _statusCode = res.status.toString();
                            const _statusmessage = data.message

                            setErrorMessageTitle("Error Adding Organization")
                            setErrorMessage("An error occurred adding the organization [" + editOrgName + "]");
                            setErrorServerMessage(_statusmessage + " (Code: " + _statusCode + ")");
                            setErrorUserMessage("Please check the data you entered, and re-try.");
                                        
                            //  Show Modal
                            showErrorMessage(true);
                        })
                    }
                }
                catch (err) {
                    console.log("Error in saveEditOrganization: Saving Organization " + JSON.stringify(err));
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    /**
    * @name showErrorMessage
    * @description  Error Pop-up
    * @param  
    * @returns 
    */
    function showErrorMessage(toggle : boolean) {
        setShowErrorModal(toggle);
    }

    return(
        <div>
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => props.switchView("configuration")}>Configuration</Breadcrumb.Item>
                    <Breadcrumb.Item active>{props.editOrg ? "Edit " : "Add "} {props.editType}</Breadcrumb.Item>
                </Breadcrumb>
                <div className="page-panel">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6}>
                                <Form.Group className="form-group" controlId="validationOrgName">
                                    <Form.Label className="required">{props.editType === "Organization"? "Organization Name" : "Site Name"}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={props.editType === "Organization"? "Enter Organization name": "Enter Site name"}
                                        required
                                        name="orgName"
                                        value={editOrgName}
                                        maxLength={255}
                                        onChange={e => setEditOrgName(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        {props.editType === "Organization"? "Please enter the Organization name." : "Please enter the Site name."}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="form-group" controlId="validationOrgShortName">
                                    <Form.Label className="required">{props.editType === "Organization"? "Organization Short Name" : "Site Short Name"}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={props.editType === "Organization"? "Enter Organization short name": "Enter Site Short Name"}
                                        required
                                        name="orgShortName"
                                        maxLength={20}
                                        value={editOrgShortName}
                                        onChange={e => setEditOrgShortName(e.target.value)}/>
                                    <Form.Control.Feedback type="invalid">
                                        {props.editType === "Organization"? "Please enter the Organization Short name." : "Please enter the Site Short Name."}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="form-group" controlId="validationOrgDescription">
                                    <Form.Label className="required">Description</Form.Label>
                                    <Form.Control
                                        required
                                        as="textarea"
                                        rows={3}
                                        maxLength={1024}
                                        name="orgDescription"
                                        value={editOrgDescription}
                                        onChange={e => setEditOrgDescription(e.target.value)}/>
                                        <Form.Control.Feedback type="invalid">
                                            {props.editType === "Organization"? "Please enter the Organization Description." : "Please enter the Site Description."}
                                        </Form.Control.Feedback>
                                </Form.Group>
                                {props.editOrg ?
                                    <Form.Group className="form-group">
                                        <Form.Label className="required">{props.editType === "Organization"? "Primary Organization Administrator" : "Primary Super User" }</Form.Label>
                                        <Form.Select 
                                            required
                                            aria-label="Point of Contact"
                                            placeholder="Enter Primary POC"
                                            name="orgPrimaryPOC"
                                            value={orgPrimaryPointOfContactName}
                                            onChange={orgPrimaryPOCSelectChange}>
                                            <option></option>
                                            {primaryandSecondaryPOCList}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a Primary Point of Contact.
                                        </Form.Control.Feedback>
                                    </Form.Group> : 
                                    <label>&nbsp;</label>
                                    }
                                {props.editOrg ?    
                                    <Form.Group className="form-group">
                                        <Form.Label>{props.editType === "Organization"? "Secondary Organization Administrator" : "Secondary Super User"}</Form.Label>
                                        <Form.Select
                                            aria-label="Point of Contact"
                                            placeholder="Enter Secondary POC"
                                            name="orgSecondaryPOC"
                                            value={orgSecondaryPointOfContactName}
                                            onChange={orgSecondaryPOCSelectChange}>
                                            <option></option>
                                            {primaryandSecondaryPOCList}
                                        </Form.Select>
                                    </Form.Group> :
                                    <label>&nbsp;</label>
                                }

                            </Col>
                            <Col md={6}>
                                <Form.Group className="form-group"  controlId="validationOrgAddress">
                                    <Form.Label className="required">Address</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter address"
                                        maxLength={255}
                                        name="orgAddress"
                                        value={editOrgAddress}
                                        onChange={e => setEditOrgAddress(e.target.value)}/>
                                    <Form.Control.Feedback type="invalid">
                                        {props.editType === "Organization"? "Please enter the Organization address." : "Please enter the Site address."}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="form-group" controlId="validationOrgTownAndCity">
                                    <Form.Label className="required">Town/City</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter town/city"
                                        maxLength={30}
                                        name="orgTownCity"
                                        value={editOrgTownCity}
                                        onChange={e => setEditOrgTownCity(e.target.value)}/>
                                    <Form.Control.Feedback type="invalid">
                                        {props.editType === "Organization"? "Please enter the Organization Town/City." : "Please enter the Site Town/City."}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="form-group" controlId="validationOrgZip">
                                    <Form.Label className="required">Zip Code</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter zip code"
                                        maxLength={30}
                                        name="orgZip"
                                        value={editOrgZip}
                                        onChange={e => validateZipCode(e)}/>
                                        <Form.Control.Feedback type="invalid">
                                            {props.editType === "Organization"? "Please enter the Organization Zip Code." : "Please enter the Site Zip Code."}
                                        </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="form-group" controlId="validationOrgState">
                                    <Form.Label className="required">State</Form.Label>
                                    <Form.Select
                                        required
                                        placeholder="Enter state"
                                        name="orgState"
                                        value={editOrgState}
                                        onChange={orgStateSelectChange}> 
                                        <option></option>
                                        {stateDisplayList}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.editType === "Organization"? "Please enter the Organization State." : "Please enter the Site State."}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="form-group" controlId="validationOrgPhone">
                                    <Form.Label className="required">Phone</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter phone number (xxx) xxx-xxxx"
                                        name="orgPhone"
                                        maxLength={255}
                                        value={editOrgPhone}
                                        onChange={e => setEditOrgPhone(e.target.value)}/>
                                        <Form.Control.Feedback type="invalid">
                                            {props.editType === "Organization"? "Please enter the Organization Phone (xxx) xxx-xxxx." : "Please enter the Site Phone (xxx) xxx-xxxx."}
                                        </Form.Control.Feedback>
                                </Form.Group>

                                {props.editOrg ?
                                    <Form.Group className="form-group" >
                                        <Form.Label className="required">{props.editType === "Organization"? "Status" : "Site Status"}</Form.Label>
                                        <Form.Select 
                                            aria-label="Status"
                                            required
                                            disabled={true}
                                            placeholder="Enter status"
                                            name="orgStatus"
                                            value={editOrgStatus}
                                            onChange={orgStatusSelectChange}> 
                                            <option></option>
                                            {statusDisplayList}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid Status
                                        </Form.Control.Feedback>
                                    </Form.Group> :
                                    <label>&nbsp;</label>
                                }
                                {props.ehrassociated ?
                                    <Form.Group className="form-group" >
                                        <Form.Label>EHR-Integrated Organization</Form.Label>
                                    </Form.Group>
                                    :<></>
                                }
                            </Col>
                        </Row>
                        <div className="form-actions">
                            <Button className='system submit'
                                variant="primary"
                                type="submit">
                                Save
                            </Button>
                            <Button className='system cancel'
                                variant="secondary"
                                type="button"
                                onClick={() => cancelAddOrganizationForm()}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                </div>

            </Container>

            <Modal show={showInactiveModalPrompt}>
                <Modal.Header className="font-face-activate-heading3" style={{backgroundColor:"#4e4e4e", color: "white"}}>
                    Confirm Status Change
                </Modal.Header>
                <Modal.Body style={{marginLeft: "80px"}}>
                    Selecting INACTIVE will change all users assigned to the {editOrgName} to a status of INACTIVE.  They will not be able to log in or make updates in ACTIVATE.
                    <br/><br/>
                    Do you still want to change the status to `&#34;`<b>INACTIVE</b>`&#34;`?
                </Modal.Body>
                <Modal.Footer>
                    <Button className='system submit' onClick={() => setInactiveStatusConfirmed()}>
                        Confirm
                    </Button>
                    <Button className='system cancel' onClick={() => hideInactiveModalPrompt(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showInactiveModalReasonForChangePrompt}>
                <Modal.Header className="font-face-activate-heading3" style={{backgroundColor:"#4e4e4e", color: "white"}}>
                    Confirm Status Change
                </Modal.Header>
                <Modal.Body style={{marginLeft: "80px"}}>
                    Please note the reason for changing the status to `&#34;`INACTIVE`&#34;`.
                    <br/><br/>
                    <label style={{marginBottom:"30px"}}>Your password is required to make this change.</label><br/>
                    <label style={{marginBottom:"10px"}}><b>Reason for change<span style={{ color : "red"}}> *</span></b></label>
                    <br/>
                    <textarea style={{width: "90%", marginRight:"30px"}} placeholder='Enter reason here'></textarea>
                    <label style={{marginBottom:"10px"}}><b>Password<span style={{ color : "red"}}> *</span></b></label>
                    <br/>
                    <TextBox></TextBox>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='system submit' onClick={() => alert('Now what?? :-)')}>
                        Confirm
                    </Button>
                    <Button className='system cancel' onClick={() => hideInactiveModalReasonForChangePrompt()}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <ModalErrorMessage   
                showErrorModal={showErrorModal} 
                errorMessageTitle={errorMessageTitle} 
                errorMessage={errorMessage} 
                errorServerMessage={errorServerMessage} 
                errorUserMessage={errorUserMessage}
                messageType="error" 
                setShowErrorModal={setShowErrorModal}  
            />
        </div>
    );
}

export default AddOrganization
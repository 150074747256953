/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Row, Col, Breadcrumb, Form, Table, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import 'devextreme/dist/css/dx.light.css';
import './AddUsers.css';
import { ModalErrorMessage } from '../Shared/MessageBox/ModalErrorMessage';

const config = require('../../config.json');

function AddTeamlet(props: 
    { switchView: (newView: string) => void }) 
    {
    const [validated, setValidated] = useState(false);
    const [name, setName] = useState("");
    const [organization, setOrganization] = useState("");
    const [organizationID, setOrganizationID] = useState("");
    const [organizationInfo, setOrganizationInfo] = useState("");
    const [site, setSite] = useState("");
    const [siteID, setSiteID] = useState("");
    const [siteList, setSiteList] = useState<any[]>([]);
    const [organizationList, setOrganizationList] = useState<any[]>([]);
    //Used to track and lookup sites based on the organization selected on the dropdown
    const [orgData, setOrgData] = useState<any[]>([]);
    const [pathwayList, setPathwayList] = useState<any[]>([]);
    const [displayPathwayList, setDisplayPathwayList] = useState<any[]>([]);
    const [displayPathwayCheckBoxList, setDisplayPathwayCheckBoxList] = useState<any[]>([]);
    const [memberList, setMemberList] = useState<any>([]);
    const [selectableMembers, setSelectableMembers] = useState<any[]>([]);
    const [displayMemberList, setDisplayMemberList] = useState<any[]>([]);
    const [noPathwaySelected, setNoPathwaySelected] = useState("");
    const [badUserSubmissionError, setBadUserSubmissionError] = useState("");
    const [healthCoachCount, setHasHealthCoachCount] = useState(0);
    const [providerCount, setProviderCount] = useState(0);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const lookupReference = useRef();
    const [testValue, setTestValue] = useState("test");
    const [selectedPathways, setSelectedPathways] = useState<any[]>([]);

    //  Error message props
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorServerMessage, setErrorServerMessage] = useState("");
    const [errorUserMessage, setErrorUserMessage] = useState("");
    
    //  Load Organizations
    useEffect(() => {
        loadOrganizations();
    }, []);

    //  Load Pathways after the Organization ID is set
    useEffect(() => {
        //  Check for Org Id
        if (organizationID != '') {
            loadPathways();
        }
    }, [organizationID]);

    /**
     * @name dropdownSelectChange
     * @description This tracks the dropdown for the organization.  Then we will the autocomplete with people based on the site
     * @param  
     * @returns
    */
    function dropdownSelectChange(event : React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();
        //Check if we are dropping down the site or the organization dropdown
        if (event.target.name === "primaryOrganization") {
            const orgValue = event.target.options[event.target.selectedIndex].getAttribute('data-value');
            const orgName = event.target.options[event.target.selectedIndex].getAttribute('data-name');
            setOrganization(event.target.value);
            if (orgValue) {
                setOrganizationID(orgValue);
            } else {
                setOrganizationID('');
            }
            
            //  Reset the selected when Orgs change
            const cleanIt: React.SetStateAction<any[]> = [];
     
            //  Check for non-blank
            if (orgName != null) {
                loadSites(orgName);
            } else {
                setSiteList(cleanIt);
            }
          
            setDisplayPathwayList(cleanIt);
            setPathwayList(cleanIt);
 
        } else {
            const siteValue = event.target.options[event.target.selectedIndex].getAttribute('data-value');
            setSite(event.target.value);
            setSiteID(siteValue || '');
            populateAutocomplate(siteValue, (event.target.value).replace(/\s/g, ''));
        }
    }

     /**
     * @name populateAutocomplate
     * @description Once we have the organization select in the dropdown we then want to fill the autocomplete input with users who are under 
     *   the selected organization
     * @param  
     * @returns
    */
    async function populateAutocomplate(healthCenterID : string | null, sitename : string) {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getUsersByHealthCenterWithStaffAndParent`, {
            method: 'POST',
            body: JSON.stringify({ token : token, healthCenterID : healthCenterID }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        });

        try {
            const data = await response.json();
            //console.log("GOT BACK AUTOCOMPLETE DATA " + JSON.stringify(data));
            //TODO Filter loaded team members by only these roles: Health Coach, Health provider, Delegated Health Staff, and Super User
            //Load the table here
            const userList : any = [];
            for (let i = 0; i < data.length; i++) {
                //  Filter loaded team members by only these roles: Health Coach, Health provider, Delegated Health Staff, and Super User
                if (data[i].userRole != null && 
                    (data[i].userRole.value == 'ROLE_HEALTH_COACH' || 
                        data[i].userRole.value == 'ROLE_PROVIDER' || 
                            data[i].userRole.value == 'ROLE_DELEGATED_HEALTH_STAFF' || 
                                data[i].userRole.value == 'ROLE_ORGANIZATION_SUPER_USER')) {
                    userList.push({
                        internalID : data[i].internalID,
                        firstName : data[i].firstName,
                        lastName : data[i].lastName,
                        title : data[i].title,
                        ordinal : data[i].ordinal,
                        preferredName : data[i].preferredName,
                        userRole : data[i].userRole,
                        accountStatus : data[i].accountStatus,
                        streetAddresses : data[i].streetAddresses,
                        phoneNumbers : data[i].phoneNumbers,
                        emailAddresses : data[i].emailAddresses,
                        clinicalSpecialties : data[i].clinicalSpecialties,
                        //healthCenters : [data[i].healthCenters[0] + sitename]
                        healthCenters : [data[i].healthCenters[0]]
                    });
                }
            }
            const uniqueUserList = [...new Set(userList)];
            setSelectableMembers(uniqueUserList);

        } catch (err) {
            console.log("ERROR " + err);
        }
    }

    /**
    * @name loadOrganizations
    * @description This function will load the Organizations
    * @param  orgName
    * @returns
    */
    async function loadOrganizations()  {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getHealthCenters`, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        });

        try {
            const data = await response.json();
      
            setOrgData(data);
            //Load the table here
            const organizationList = [];
            for (let i = 0; i < data.length; i++) {
                organizationList.push(
                    <option key={i}
                        data-name={data[i].name}
                        data-value={data[i].internalID}>
                            {data[i].name}
                    </option>
                );
            }

            setOrganizationList(organizationList);
        } catch (err) {
            console.log("ERROR " + err);
        }
    }

    /**
    * @name loadSites
    * @description This function will populate the Sites under an organization after a user selects a specific primary org
    * @param  orgName
    * @returns
    */
    function loadSites(orgName : string | null) {
        const tempSiteList : any = [];
        let matchSite;
        for (let i = 0; i < orgData.length; i++) {
            //Check through the data for the right org
            if (orgData[i].name === orgName) {
                matchSite = orgData[i].sites;
            }
        }
        for (let j = 0; j < matchSite.length; j++) {
            tempSiteList.push(
                <option key={j}
                    data-name={matchSite[j].name}
                    data-value={matchSite[j].internalID}
                    >
                    {matchSite[j].name}
                </option>
            );
        }
        setSiteList(tempSiteList);
    }

    /**
    * @name loadPathways
    * @description Loads the pathways for the particular user.
    * @param  
    * @returns
    * Note:  when organizationID is blank Pathways are loaded based on the User 
    */
    async function loadPathways() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getPathways`, {
            method: 'POST',
            body: JSON.stringify({ token : token, organizationID: organizationID }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        });

        try {
            const data = await response.json();
            setDisplayPathwayCheckBoxList(data);

            //console.log("GOT BACK PATHWAY " + JSON.stringify(data));
            //Load the table here
            const _pathwayList = [];
            for (let i = 0; i < data.length; i++) {
                _pathwayList.push(
                    <Form.Check
                        key={i}
                        id={"pathway_"+i}
                        required={false}
                        label={data[i].name}
                        name={data[i].name}
                        type="checkbox"
                        onChange={event => addOrRemovePathway(data[i].internalID, event.target.checked)}
                        feedback="You must select at least one Pathway for this user"
                        feedbackType="invalid"
                    />
                );
            }

            setDisplayPathwayList(_pathwayList);
        } catch (err) {
            console.log("ERROR " + err);
        }
    }

    /**
    * @name addOrRemovePathway
    * @description When Pathway Checkboxes are selected and deselected
    * @param  
    * @returns
    */
    function addOrRemovePathway(pathwayID : any, event : any) {
        setNoPathwaySelected("");

        //First check if box was check or unchecked
        if (event) {
            const tempAssignedPathway = pathwayList;
            tempAssignedPathway.push(
                {   
                    "internalID" : pathwayID , 
                    "name" : ""
                }
            );
            setPathwayList(tempAssignedPathway);
        } else {
            const tempAssignedPathway = pathwayList;
            for (let i = 0; i < tempAssignedPathway.length; i++) {
                if (tempAssignedPathway[i].internalID === pathwayID) {
                    tempAssignedPathway.splice(i, 1);
                }
            }
            setPathwayList(tempAssignedPathway);
        }

        //  Check for selected Pathways
        if (pathwayList.length < 1) {
            setNoPathwaySelected("You must select at least one Pathway to create a Teamlet.");
        }
    }

    /**
    * @name loadTeamletMemberHeaders
    * @description Load Teamlet Members Headers
    * @param  
    * @returns
    */
    function loadTeamletMemberHeaders() {
        const headerList = ["Name", "Role", "Email", "Action"];
        return headerList.map((key, index) => {
            return <th key={index}>{key}</th>;
        });
    }

    /**
    * @name loadTeamletMembers
    * @description If we are editing we need to pull from the server but otherwise we store info locally
    * @param  
    * @returns
    */
    async function loadTeamletMembers(editMode : boolean) {
        if (!editMode) {
            const tempMemberList : any = [];
            for (let i = 0; i < memberList.length; i++) {
                tempMemberList.push(
                    <tr key={i}>
                        <td>{memberList[i].firstName + " " + memberList[i].lastName}</td>
                        <td>{memberList[i].userRole.name}</td>
                        <td>{memberList[i].emailAddresses[0] != null ? memberList[i].emailAddresses[0].address : ''}</td>
                        <td>
                            <Button
                                id="removeMember"
                                className="action-icon"
                                variant="action"
                                onClick={() => removeTeamletMember(i)}>
                                    <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
                            </Button>
                        </td>
                    </tr>
                );
            }

            //  Load Table Here
            setDisplayMemberList(tempMemberList);
            console.log(tempMemberList);
        }
    }

    //This function occurs from clicking the trash can icon to remove a Teamlet member in the list
    function removeTeamletMember(index : any) {
        setBadUserSubmissionError("");
        const tempMemberList = memberList;
        if (tempMemberList[index].userRole.name === "Health Coach") {
            setHasHealthCoachCount(healthCoachCount-1);
        } else if (tempMemberList[index].userRole.name === "Provider") {
            setProviderCount(providerCount-1);
        }
        tempMemberList.splice(index, 1);
        setMemberList(tempMemberList);
        loadTeamletMembers(false);
    }

    /**
    * @name handleSubmit
    * @description Submits form to the server
    * @param  
    * @returns
    */
    const handleSubmit = async (event : any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
          // Add this to the invalid page
          if (pathwayList.length === 0) {
            setNoPathwaySelected("You must select at least one Pathway to create a Teamlet.");
          }
          event.stopPropagation();
          setValidated(true);
        } else if (pathwayList.length === 0) {
            setNoPathwaySelected("You must select at least one Pathway to create a Teamlet.");
        } else if (healthCoachCount === 0 || providerCount === 0) {
            //Give the user a warning that their Teamlet team is missing one of these profiles.  They can still
            //continue through the modal if they choose
            setShowWarningModal(true);
        } else {
            addNewTeamlet();
        }
    
        setValidated(true);

    };

    /**
    * @name addNewTeamlet
    * @description Adds Teamlet
    * @param  
    * @returns
    */
    async function addNewTeamlet() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        
        await fetch(`${config.activateServer}:${config.activatePORT}/addTeamlet`, {
            method: 'POST',
            body: JSON.stringify({ 
                token : token,
                name,
                organization,
                siteID,
                pathwayList,
                memberList
            }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(res =>{
            if (!res.ok) {
              setBadUserSubmissionError("Error: An unknown error occurred.");
              setShowWarningModal(false);
             
              //  Error returned from data service, display to user
              res.json().then(data => {
                //  Create Message
                const _statusCode = res.status.toString();
                const _statusmessage = JSON.stringify(data.message);
 
                //  Show Modal
                setShowErrorModal(true);
                setErrorMessageTitle("Error Updating Teamlet");
                setErrorMessage("An error occurred updating the Teamlet [" + name + "]");
                setErrorServerMessage(_statusmessage + " (Code: " + _statusCode + ")");
                setErrorUserMessage("Please check the data you entered, and re-try.");
            });


              throw Error('Could not fetch the data for the resource');
            }
            return res.json();
          }).then(data =>{
                props.switchView("teamlet");
          })
          .catch(err =>{
            if (err.name === 'AbortError') {
              //console.log('fetch aborted');
            }
          });
    }

    //This function fires when a user selects an option in the lookup field
    function lookUpValueChanged( e : any) {
        const val = e.value;
        //First we find the index in selectable members for who we are adding
        const index = selectableMembers.findIndex(function(item, i) {
            return item.internalID === val;
        });
        //Now we remove that user form the selectable members to prevent duplicates
        //setSelectableMembers(selectableMembers.splice(index, 1));

        setTestValue("");
        const tempMemberList = memberList;
        //console.log("ADDING " + JSON.stringify(selectableMembers[index]));
        for (let i = 0; i < tempMemberList.length; i++) {
            if (memberList[i] && selectableMembers[index]) {
                if (memberList[i].internalID === selectableMembers[index].internalID) {
                    setBadUserSubmissionError("This user is already assigned to this Teamlet");
                    return;
                } else {
                    setBadUserSubmissionError("");
                }
            }
        }

        if (selectableMembers[index]) {
            setBadUserSubmissionError("");
            //Check for either health provider or health coach
            if (selectableMembers[index].userRole.name === "Health Coach") {
                setHasHealthCoachCount(healthCoachCount+1);
            } else if (selectableMembers[index].userRole.name === "Provider") {
                setProviderCount(providerCount+1);
            }
            tempMemberList.push(selectableMembers[index]);
            setMemberList(tempMemberList);
            loadTeamletMembers(false);
        }
    }

    function getDisplayName(item: any) {
        return item ? `${item.firstName} ${item.lastName} ${item.userRole.name}` : '';
    }
    
    return (
            <div>
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => props.switchView("configuration")}>Configuration</Breadcrumb.Item>
                    <Breadcrumb.Item active>Add Teamlet</Breadcrumb.Item>
                </Breadcrumb>
                <div className="page-panel">
                

                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                <Row>
                    <Col md={6} lg={4}>
                        <Form.Group className="form-group" controlId="Name">
                            <Form.Label className="required">Name</Form.Label>
                            <Form.Control className="font-face-activate-secondary-text"
                                type="text"
                                placeholder="Enter a Teamlet name"
                                required 
                                onChange={e => setName(e.target.value)}
                                value={name}/>
                            <Form.Control.Feedback type="invalid">
                                Please enter a Teamlet name.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group" controlId='organization'>
                            <Form.Label className="required">Organizations</Form.Label>
                            <Form.Select className="font-face-activate-secondary-text"
                                aria-label="Organization"
                                name="primaryOrganization"
                                value={organization}
                                disabled={memberList.length > 0}
                                required
                                onChange={dropdownSelectChange}>
                                    <option></option>
                                    {organizationList}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                   Please select an Organization
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-group" controlId='organization'>
                            <Form.Label className="required">Site</Form.Label>
                            <Form.Select className="font-face-activate-secondary-text"
                                aria-label="Site"
                                name="primarySite"
                                value={site}
                                disabled={memberList.length > 0}
                                required
                                onChange={dropdownSelectChange}>
                                    <option></option>
                                    {siteList}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                   Please select a Site
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="form-group" controlId='organization'>
                            <Form.Label className="required">Digital Health Pathway</Form.Label>
                            {displayPathwayList.length > 0 ?   
                                <>{displayPathwayList}</>
                            : <>
                                <br/>
                                    <Form.Label className="font-face-activate-secondary-text">Please select an Organization for Digital Health Pathway options</Form.Label>
                                </> 
                            }
                            {noPathwaySelected.length > 0 ? 
                                <p className="validation-error">{noPathwaySelected}</p>
                                : <></> 
                            }
                        </Form.Group>
                    </Col>
                </Row>
                        <Table>
                            <thead>
                                <tr>
                                    {loadTeamletMemberHeaders()}
                                </tr>
                            </thead>
                            <tbody>
                                {displayMemberList}
                            </tbody>
                        </Table>

                        <Lookup
                            disabled={site === ""}
                            label="Add User"
                            items={selectableMembers}
                            displayExpr={getDisplayName}
                            valueExpr="internalID"
                            value={testValue}
                            onValueChanged={(e) => lookUpValueChanged(e)}
                            >
                            <DropDownOptions showTitle={false} />
                        </Lookup>

                    {badUserSubmissionError.length > 0 ? 
                      <p className="validation-error">{badUserSubmissionError}</p>
                      : <></> 
                    }
                    <div className="form-actions">
                        <Button
                            className="system submit"
                            type="submit"
                            variant="primary"
                            id="submitButton">
                                Save
                        </Button>
                        <Button className='system cancel'
                            variant="secondary"
                            type="button"
                            onClick={() => props.switchView("teamlet")}>
                            Cancel
                        </Button>
                    </div>
                </Form>
                </div>
            </Container>
            
            <Modal show={showWarningModal}>
                <Modal.Header className="system">
                    <h2>Confirm Teamlet Submission</h2>
                </Modal.Header>
                <Modal.Body>
                    Teamlets must have at least one member with the role of Health Coach and one member with the role of Provider
                    <br/><br/>
                    Do you still want to save this Teamlet?
                </Modal.Body>
                <Modal.Footer>
                    <Button className="system submit" variant="primary" onClick={() => addNewTeamlet()}>Confirm</Button>
                    <Button className="system cancel" variant="secondary" onClick={() => setShowWarningModal(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>

            <ModalErrorMessage   
                showErrorModal={showErrorModal} 
                errorMessageTitle={errorMessageTitle} 
                errorMessage={errorMessage} 
                errorServerMessage={errorServerMessage} 
                errorUserMessage={errorUserMessage}
                messageType="error" 
                setShowErrorModal={setShowErrorModal}  
            />
        </div>
    );
} 

export default AddTeamlet;

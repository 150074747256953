const config = require("../config.json");
/**
     * @name fetchHuddleDetails
     * @description Loads the details for a huddle
     * @param
     * @returns {json}
     */
export async function fetchHuddleDetails(huddleID: string) {

    const token = sessionStorage.getItem('ACTIVATEtoken');

    const response = await fetch(
        `${config.activateServer}:${config.activatePORT}/getHuddleDetails/`,
        {
            method: "POST",
            body: JSON.stringify({ token: token, internalID: huddleID }),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            mode: "cors",
        }
    );
    try {

      //  Check for correct response
      if (response.ok){
        const data = await response.json();
        return (data);
      } else {
        throw Error(response.statusText);
      }
    } catch (err) {
        console.log("ERROR " + err);
    }
}

/**
     * @name fetchHuddleDetails
     * @description Loads the details for a huddle
     * @param
     * @returns {json}
     */
export async function fetchHuddleMeetings(huddleID: string) {

  const token = sessionStorage.getItem('ACTIVATEtoken');
  const response = await fetch(
      `${config.activateServer}:${config.activatePORT}/getHuddleMeetings`,
      {
          method: "POST",
          body: JSON.stringify({ token: token, internalID: huddleID }),
          headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
          },
          mode: "cors",
      }
  );
  try {
      const data = await response.json();
      return (data);
  } catch (err) {
      console.log("ERROR " + err);
  }
}

export async function getHuddleInfo(huddleid: string) {

    const token = sessionStorage.getItem('ACTIVATEtoken');
    try {
      const response = await fetch(
        `${config.activateServer}:${config.activatePORT}/getHuddleDetails/`,
        {
          method: "POST",
          body: JSON.stringify({ token: token, internalID: huddleid }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          mode: "cors",
        }
      )
      try {
        const data = await response.json();
        return(data);
      } catch (err) {
        console.log("ERROR " + err);
      }
    } catch (err) {
      console.log("ERROR " + err);
    }
  }

  export async function fetchNextMeeting(meetingID: string, historyCount: number, inclusive: boolean) {
    // console.log(meetingID)
    const token = sessionStorage.getItem('ACTIVATEtoken');
    try {
      const response = await fetch(
        `${config.activateServer}:${config.activatePORT}/getNextMeeting/`,
        {
          method: "POST",
          body: JSON.stringify({ token: token, meetingInternalID: meetingID, meetingCount: historyCount, inclusive: inclusive }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          mode: "cors",
        }
      )
      try {
        const data = await response.json();
        return(data);
      } catch (err) {
        console.log("ERROR " + err);
      }
    } catch (err) {
      console.log("ERROR " + err);
    }
  }


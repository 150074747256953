import React, { useEffect, useState } from 'react';
import { ListGroup, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PermissionService from '../../Services/PermissionService';
import './Sidebar.css';
import {
    faUser, faPeopleGroup, faClipboardList, faCalendar, faMessage, faVideo, faHeartPulse,
    faMicrochip, faSliders, faGear, faWrench, faChartPie
} from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@ailibs/feather-react-ts';

function AdminSidebar(props: {expanded : boolean, switchView: (newView : string) => void, permissions: PermissionService, defaultView: string}) {
    
    const [activeView, setActiveView] = useState(props.defaultView);
    
    useEffect(() => {
        setActiveView(props.defaultView);
    }, []);

    function newActiveView(newPage: string) {
        setActiveView(newPage);
        props.switchView(newPage);
    }

    return (
        <div className={props.expanded ? "sidebar-menu expanded" : "sidebar-menu "}>
            <ListGroup>
                {props.permissions.checkPermissionSetting("MY_PROFILE", "READ") ?
                    <ListGroup.Item>
                        <Button
                            onClick={() => newActiveView("MyProfile")}
                            className={activeView === "MyProfile" ? 'sidebar-icon peopleButtonActive' : 'sidebar-icon peopleButton'}>
                            <Icon name="user" size="24"/></Button>
                            <Button hidden={!props.expanded} variant="link" onClick={() => newActiveView("MyProfile")}>My Profile</Button>
                    </ListGroup.Item>
                : <></>}

                { props.permissions.checkPermissionSetting("PATIENT_PANEL", "READ") ?
                <ListGroup.Item
                    className="sidebarList">
                    <Button
                        //hidden={this.state.enablePatientPanel===true ? false : true}
                        onClick={() => newActiveView("patientpanel")}
                        className={activeView === "patientpanel" ? 'sidebar-icon peopleButtonActive' : 'sidebar-icon peopleButton'}>
                        <Icon name="users" size="24"/></Button>
                    {props.expanded ? <Button variant="link" onClick={() => newActiveView("patientpanel")}>Patient Panel</Button> : ""}
                </ListGroup.Item>
                : <></>}
                
                {/* Unused icons - do not emit these into the HTML */}
                {/* ----------------------------------------------
                <ListGroup.Item
                    hidden={true}>
                    <Button
                        className='sidebar-icon eventButton'>
                        <FontAwesomeIcon icon={faClipboardList} /></Button>
                    {this.props.expanded ? "To Do Lists" : ""}
                </ListGroup.Item>
                <ListGroup.Item
                    hidden={true}>
                    <Button
                        className='sidebar-icon eventButton'>
                        <FontAwesomeIcon icon={faCalendar} /></Button>
                    {this.props.expanded ? "Appointments" : ""}
                </ListGroup.Item>
                <ListGroup.Item
                    hidden={true}>
                    <Button
                        className='sidebar-icon messageButton'>
                        <FontAwesomeIcon icon={faMessage} /></Button>
                    {this.props.expanded ? "Messages" : ""}
                </ListGroup.Item>
                <ListGroup.Item
                    hidden={true}>
                    <Button
                        className='sidebar-icon messageButton'>
                        <FontAwesomeIcon icon={faVideo} /></Button>
                    {this.props.expanded ? "Video Calls" : ""}
                </ListGroup.Item>
                <ListGroup.Item
                    hidden={true}>
                    <Button
                        className='sidebar-icon dataButton'
                        disabled={true}>
                        <FontAwesomeIcon icon={faHeartPulse} /></Button>
                    {this.props.expanded ? "Manage RPM Data" : ""}
                </ListGroup.Item>
                <ListGroup.Item
                    hidden={true}>
                    <Button
                        className='sidebar-icon dataButton'>
                        <FontAwesomeIcon icon={faMicrochip} /></Button>
                    {this.props.expanded ? "Manage Devices" : ""}
                </ListGroup.Item>
                <ListGroup.Item
                    hidden={true}>
                    <Button
                        className='sidebar-icon systemButton'>
                        <FontAwesomeIcon icon={faSliders} /></Button>
                    {this.props.expanded ? "Preferences" : ""}
                </ListGroup.Item>
                <ListGroup.Item
                    hidden={true}>
                    <Button
                        className='sidebar-icon systemButton'>
                        <FontAwesomeIcon icon={faGear} /></Button>
                    {this.props.expanded ? "Settings" : ""}
                </ListGroup.Item>
                */}
                
                {props.permissions.checkPermissionSetting("DATA_READINGS", "READ") ?
                <ListGroup.Item>
                    <Button                    
                        onClick={() => newActiveView("datareadings")}
                        className={activeView === "datareadings" ? 'sidebar-icon dataButtonActive' : 'sidebar-icon dataButton'}>
                        <Icon name="activity" size="24"  />
                    </Button>
                    <Button hidden={!props.expanded} variant="link" onClick={() => newActiveView("datareadings")}>Patient Data Readings Manager</Button>
                </ListGroup.Item>
                : ""}
                {props.permissions.checkPermissionSetting("CONFIGURATION", "READ") ?
                <ListGroup.Item>
                    <Button
                        onClick={() => newActiveView("configuration")}
                        className={activeView === "configuration" ? 'sidebar-icon systemButtonActive' : 'sidebar-icon systemButton'}>
                        <Icon name="tool" size="24" />
                    </Button>
                    <Button hidden={!props.expanded} variant="link" onClick={() => newActiveView("configuration")}>Configuration</Button>
                </ListGroup.Item>
                : ""}
                {/* 
                <ListGroup.Item
                    hidden={true}>
                    <Button
                        className='sidebar-icon systemButton'>
                        <FontAwesomeIcon icon={faChartPie} /></Button>
                    {this.props.expanded ? "Analytics" : ""}
                </ListGroup.Item>
                */}
            </ListGroup>
        </div>
    );
}

export default AdminSidebar;
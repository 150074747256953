import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Modal, Form, Card, Breadcrumb } from 'react-bootstrap';
import { PULSERATE, SYSTOLIC, DIASTOLIC, BLOODGLUCOSE } from '../Shared/constants';
const config = require('../../config.json');

function AddVitalData(props: 
    {
        switchView: Function,
        orgID : string,
        orgName : string,
        parentOrgID : string,
        parentOrgName : string
    }){
    const [validated, setValidated] = useState(false);
    const [badSubmissionError, setBadSubmissionError] = useState("");

    //  Use to track RPM Device Readings
    const [heartRateID, setHeartRateID] = useState("");
    const [heartRateLow, setHeartRateLow] = useState("");
    const [heartRateHigh, setHeartRateHigh] = useState("");
    const [heartRateLowID, setHeartRateLowID] = useState("");
    const [heartRateHighID, setHeartRateHighID] = useState("");
    const [heartRateMonitorIDlow, setHeartRateMonitorIDlow] = useState("");
    const [heartRateMonitorIDhigh, setHeartRateMonitorIDhigh] = useState("");
    const [systolicID, setSystolicID] = useState("");
    const [systolicBloodPressureLow, setSystolicBloodPressureLow] = useState("");
    const [systolicBloodPressureHigh, setSystolicBloodPressureHigh] = useState("");
    const [systolicLowID, setSystolicLowID] = useState("");
    const [systolicHighID, setSystolicHighID] = useState("");
    const [systolicMonitorIDlow, setSystolicMonitorIDlow] = useState("");
    const [systolicMonitorIDhigh, setSystolicMonitorIDhigh] = useState("");
    const [diastolicID, setDiastolicID] = useState("");
    const [diastolicBloodPressureLow, setDiastolicBloodPressureLow] = useState("");
    const [diastolicBloodPressureHigh, setDiastolicBloodPressureHigh] = useState("");
    const [diastolicLowID, setDiastolicLowID] = useState("");
    const [diastolicHighID, setDiastolicHighID] = useState("");
    const [diastolicMonitorIDlow, setDiastolicMonitorIDlow] = useState("");
    const [diastolicMonitorIDhigh, setDiastolicMonitorIDhigh] = useState("");
    const [glucoseID, setGlucoseID] = useState("");
    const [glucoseLow, setGlucoseLow] = useState("");
    const [glucoseHigh, setGlucoseHigh] = useState("");
    const [glucoseLowID, setGlucoseLowID] = useState("");
    const [glucoseHighID, setGlucoseHighID] = useState("");
    const [glucoseMonitorIDlow, setGlucoseMonitorIDlow] = useState("");
    const [glucoseMonitorIDhigh, setGlucoseMonitorIDhigh] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showErrorMessage, setErrorMessage] = useState("");
    
    const token = sessionStorage.getItem('ACTIVATEtoken');
      
    //  Load Vitals Data
    useEffect(() => {
        loadVitalData();
    }, [])

    /**
    * @name loadVitalData
    * @description Pulls in the Vital Data values
    */
    async function loadVitalData() {
        await fetch(`${config.activateServer}:${config.activatePORT}/getVitalData`, {
            method: 'POST',
            body: JSON.stringify({ token : token, orgID : props.parentOrgID }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(async (response: { ok: any; json: () => any; status: { toString: () => string; }; }) => {
            //  Check for Success
            if (response.ok)
            {
                try {
                    const data = await response.json();
                    for(let i = 0; i < data.length; i++){
                        if(data[i].readingName === PULSERATE){
                            setHeartRateID(data[i].internalID);
                            setHeartRateHigh(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue);
                            setHeartRateLow(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue);
                            setHeartRateLowID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].internalID);
                            setHeartRateHighID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].internalID);
                            setHeartRateMonitorIDlow(data[i].dataValues[0].monitorReadingThreshold);
                            setHeartRateMonitorIDhigh(data[i].dataValues[0].monitorReadingThreshold);
                        } else if(data[i].readingName === SYSTOLIC){
                            setSystolicID(data[i].internalID);
                            setSystolicBloodPressureHigh(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue);
                            setSystolicBloodPressureLow(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue);
                            setSystolicLowID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].internalID);
                            setSystolicHighID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].internalID);
                            setSystolicMonitorIDlow(data[i].dataValues[0].monitorReadingThreshold);
                            setSystolicMonitorIDhigh(data[i].dataValues[0].monitorReadingThreshold);
                        } else if(data[i].readingName === DIASTOLIC){
                            setDiastolicID(data[i].internalID);
                            setDiastolicBloodPressureHigh(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue);
                            setDiastolicBloodPressureLow(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue);
                            setDiastolicLowID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].internalID);
                            setDiastolicHighID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].internalID);
                            setDiastolicMonitorIDlow(data[i].dataValues[0].monitorReadingThreshold);
                            setDiastolicMonitorIDhigh(data[i].dataValues[0].monitorReadingThreshold);
                        } else if(data[i].readingName === BLOODGLUCOSE){
                            setGlucoseID(data[i].internalID);
                            setGlucoseHigh(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].thresholdValue);
                            setGlucoseLow(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].thresholdValue);
                            setGlucoseLowID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN')[0].internalID);
                            setGlucoseHighID(data[i].dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN')[0].internalID);
                            setGlucoseMonitorIDlow(data[i].dataValues[0].monitorReadingThreshold);
                            setGlucoseMonitorIDhigh(data[i].dataValues[0].monitorReadingThreshold);
                        }
                    }
                    
                } catch(err){
                    console.log("Error in loadStatusOptions: " + err);
                }
            }
            else {
                // ToDo:  Handle non-200s
                console.log("Received HTTP status " + response.status.toString())
            }
        })
    }

    /**
    * @name addEachVitalData
    * @description Used to send each individual vital data
    */
    async function addEachVitalData(){
        await addSpecificVitalData(heartRateID, PULSERATE, "bpm", heartRateHigh, heartRateLow, heartRateHighID, heartRateLowID, heartRateMonitorIDhigh, heartRateMonitorIDlow);
        await addSpecificVitalData(systolicID, SYSTOLIC, "mmHg", systolicBloodPressureHigh, systolicBloodPressureLow, systolicHighID, systolicLowID, systolicMonitorIDhigh, systolicMonitorIDlow);
        await addSpecificVitalData(diastolicID, DIASTOLIC, "mmHg", diastolicBloodPressureHigh, diastolicBloodPressureLow, diastolicHighID, diastolicLowID, diastolicMonitorIDhigh, diastolicMonitorIDlow);
        await addSpecificVitalData(glucoseID, BLOODGLUCOSE, "mg/dl", glucoseHigh, glucoseLow, glucoseHighID, glucoseLowID, glucoseMonitorIDhigh, glucoseMonitorIDlow);
    }

    /**
    * @name validateFormValues
    * @description Checks for correct High and Low Values
    * @param
    * @returns {boolean}
    */
    function validateFormValues(){

        //  Pulse Rate Low is greater than the High
        if (parseInt(heartRateLow) > parseInt(heartRateHigh)){
            setErrorMessage(`The Low Pulse Rate value (${parseInt(heartRateLow)}) cannot be greater than the High value (${parseInt(heartRateHigh)})`);
            setShowErrorModal(true)
            return false;
        }

        //  Systolic Low is greater than the High
        if (parseInt(systolicBloodPressureLow) > parseInt(systolicBloodPressureHigh)){
            setErrorMessage(`The Low Systolic Blood Pressure value (${parseInt(systolicBloodPressureLow)}) cannot be greater than the High value (${parseInt(systolicBloodPressureHigh)})`);
            setShowErrorModal(true)
            return false;
        }

        //  Diastolic Low is greater than the High
        if (parseInt(diastolicBloodPressureLow) > parseInt(diastolicBloodPressureHigh)){
            setErrorMessage(`The Low Diastolic Blood Pressure value (${parseInt(diastolicBloodPressureLow)}) cannot be greater than the High value (${parseInt(diastolicBloodPressureHigh)})`);
            setShowErrorModal(true)
            return false;
        }

        //  Glucose Low is greater than the High
        if (parseInt(glucoseLow) > parseInt(glucoseHigh)){
            setErrorMessage(`The Low Blood Glucose value (${parseInt(glucoseLow)}) cannot be greater then the High value (${parseInt(glucoseHigh)})`);
            setShowErrorModal(true)
            return false;
        }

        return true;
    }    
    /**
    * @name addSpecificVitalData
    * @description Used for specific vitals being sent over
    */
    async function addSpecificVitalData(vitalID : string, vitalName : string, vitalMeasure : string, vitalsHigh : string, vitalsLow : string,
        vitalsHighID : string, vitalsLowID : string, monitorReadingHigh : string, monitorReadingLow : string){
        await fetch(`${config.activateServer}:${config.activatePORT}/putVitalData`, {
            method: 'POST',
            body: JSON.stringify({ 
                token : token,
                parentOrgID : props.parentOrgID,
                vitalID,
                vitalName,
                vitalMeasure,
                vitalsHigh,
                vitalsLow,
                vitalsHighID,
                vitalsLowID,
                monitorReadingHigh,
                monitorReadingLow
            }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(response => {
            if(response.ok){
                try {
                    //  Success
                    setBadSubmissionError("");
                } catch (err) {
                    setBadSubmissionError("Error: " + err);
                }
            } else {
                setBadSubmissionError("Error: An unknown error occurred.");
            }
        }).catch(err =>{
            setBadSubmissionError("Error: An unknown error occurred." + err);
        })
    }

    //  Delete Pop-up
    function showWarningModal(toggle : boolean) {
        setShowErrorModal(toggle);
    }

    /**
    * @name handleSubmit
    * @description Submits the form for validation and then POSTS the Vital Data
    */
    const handleSubmit = async (event : any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
          event.stopPropagation();
          setValidated(true);
        } else {
            //  Validate High and Lows
            if (validateFormValues()){
                //  Timing issue, need to await here 
                await addEachVitalData();
                //  return to dashboard
                props.switchView("dashboard");
            } else {
                event.stopPropagation();
                setValidated(true);
                return false;
            }
        }
        //  Reset validate
        setValidated(true);
    };

    return(
      <div>
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => props.switchView("dashboard")}>Overview</Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Vitals Data Threshold</Breadcrumb.Item>
                </Breadcrumb>

                <div className="page-panel">
                
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                    <h2>Pulse Rate</h2>
                        <div className="threshold-group">
                          <div className="threshold-title">
                            <div><Form.Label>Threshold</Form.Label></div>
                            <div><span className="visually-hidden">Comparison</span></div>
                            <div><Form.Label className="required">Value</Form.Label></div>
                          </div>
                          <div className="threshold-setting">
                            <div className="setting-label"><Form.Label>Low</Form.Label></div>
                            <div>Less than or equal to</div>
                            <Form.Group controlId="lowPulseThresholdValue">
                                <Form.Control
                                    type="number"
                                    maxLength={3}
                                    htmlSize={3}
                                    min={0}
                                    max={999}
                                    as="input"
                                    required
                                    value={heartRateLow}
                                    onChange={(e) => setHeartRateLow(e.target.value)}/> bpm
                                <Form.Control.Feedback type="invalid">
                                    Low threshold must be between 0 and 999.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </div>

                          <div className="threshold-setting">
                            <div className="setting-label"><Form.Label>High</Form.Label></div>
                            <div>Greater than</div>
                            <Form.Group controlId="highPulseThresholdValue">
                                <Form.Control
                                    type="number"
                                    maxLength={3}
                                    htmlSize={3}
                                    min={0}
                                    max={999}
                                    as="input"
                                    required
                                    value={heartRateHigh}
                                    onChange={(e) => setHeartRateHigh(e.target.value)}/> bpm
                                <Form.Control.Feedback type="invalid">
                                    High threshold must be between 0 and 999.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>
                        
                        <h2>Systolic Blood Pressure</h2>
                        <div className="threshold-group">
                          <div className="threshold-title">
                            <div className="setting-label"><Form.Label>Threshold</Form.Label></div>
                            <div><span className="visually-hidden">Comparison</span></div>
                            <div><Form.Label className="required">Value</Form.Label></div>
                          </div>
                          <div className="threshold-setting">
                            <div className="setting-label"><Form.Label>Low</Form.Label></div>
                            <div>Less than or equal to</div>
                            <Form.Group controlId="lowSystolicThresholdValue">
                                <Form.Control
                                    type="number"
                                    maxLength={3}
                                    htmlSize={3}
                                    min={1}
                                    max={999}
                                    as="input"
                                    required
                                    value={systolicBloodPressureLow}
                                    onChange={(e) => setSystolicBloodPressureLow(e.target.value)}/> mmHg
                                <Form.Control.Feedback type="invalid">
                                    Low threshold must be between 1 and 999.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </div>

                          <div className="threshold-setting">
                            <div className="setting-label"><Form.Label>High</Form.Label></div>
                            <div>Greater than</div>
                            <Form.Group controlId="highSystolicThresholdValue">
                                <Form.Control
                                    type="number"
                                    maxLength={3}
                                    htmlSize={3}
                                    min={1}
                                    max={999}
                                    as="input"
                                    required
                                    value={systolicBloodPressureHigh}
                                    onChange={(e) => setSystolicBloodPressureHigh(e.target.value)}/> mmHg
                                <Form.Control.Feedback type="invalid">
                                    High threshold must be between 1 and 999.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>
 
                        <h2>Diastolic Blood Pressure</h2>
                        <div className="threshold-group">
                          <div className="threshold-title">
                            <div className="setting-label"><Form.Label>Threshold</Form.Label></div>
                            <div><span className="visually-hidden">Comparison</span></div>
                            <div><Form.Label className="required">Value</Form.Label></div>
                          </div>
                          <div className="threshold-setting">
                            <div className="setting-label"><Form.Label>Low</Form.Label></div>
                            <div>Less than or equal to</div>
                            <Form.Group controlId="lowDiastolicThresholdValue">
                                <Form.Control
                                    type="number"
                                    maxLength={3}
                                    htmlSize={3}
                                    min={1}
                                    max={999}
                                    as="input"
                                    required
                                    value={diastolicBloodPressureLow}
                                    onChange={(e) => setDiastolicBloodPressureLow(e.target.value)}/> mmHg
                                <Form.Control.Feedback type="invalid">
                                    Low threshold must be between 1 and 999.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </div>

                          <div className="threshold-setting">
                            <div className="setting-label"><Form.Label>High</Form.Label></div>
                            <div>Greater than</div>
                            <Form.Group controlId="highDiastolicThresholdValue">
                                <Form.Control
                                    type="number"
                                    maxLength={3}
                                    htmlSize={3}
                                    min={1}
                                    max={999}
                                    as="input"
                                    required
                                    value={diastolicBloodPressureHigh}
                                    onChange={(e) => setDiastolicBloodPressureHigh(e.target.value)}/> mmHg
                                <Form.Control.Feedback type="invalid">
                                    High threshold must be between 1 and 999.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>

                        <h2>Blood Glucose</h2>
                        <div className="threshold-group">
                          <div className="threshold-title">
                            <div className="setting-label"><Form.Label>Threshold</Form.Label></div>
                            <div><span className="visually-hidden">Comparison</span></div>
                            <div><Form.Label className="required">Value</Form.Label></div>
                          </div>
                          <div className="threshold-setting">
                            <div className="setting-label"><Form.Label>Low</Form.Label></div>
                            <div>Less than or equal to</div>
                            <Form.Group controlId="lowGlucoseThresholdValue">
                                <Form.Control
                                    type="number"
                                    maxLength={3}
                                    htmlSize={3}
                                    min={1}
                                    max={999}
                                    as="input"
                                    required
                                    value={glucoseLow}
                                    onChange={(e) => setGlucoseLow(e.target.value)}/> mg/dl
                                <Form.Control.Feedback type="invalid">
                                    Low threshold must be between 1 and 999.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </div>

                          <div className="threshold-setting">
                            <div className="setting-label"><Form.Label>High</Form.Label></div>
                            <div>Greater than</div>
                            <Form.Group controlId="highGlucoseThresholdValue">
                                <Form.Control
                                    type="number"
                                    maxLength={3}
                                    htmlSize={3}
                                    min={1}
                                    max={999}
                                    as="input"
                                    required
                                    value={glucoseHigh}
                                    onChange={(e) => setGlucoseHigh(e.target.value)}/> mg/dl
                                <Form.Control.Feedback type="invalid">
                                    High threshold must be between 1 and 999.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>

                    {badSubmissionError.length > 0 ? 
                      <p className="validation-error">{badSubmissionError}</p>
                      : <></> 
                    }

                    <div className="form-actions">
                        <Button className='people submit'
                            variant="primary"
                            type="submit">
                            Save
                        </Button>
                        <Button className='people cancel'
                            variant="secondary"
                            id="cancelButton"
                            type="button"
                            onClick={() => props.switchView("dashboard")}>
                            Cancel
                        </Button>
                    </div>
                    </Form>
                </div>
        </Container>
            <Modal show={showErrorModal}>
                <Modal.Header className="error">
                    <h2>Invalid Low and High Values</h2>
                </Modal.Header>
                <Modal.Body>
                    {showErrorMessage}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="submit error" onClick={() => showWarningModal(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddVitalData;
import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Container, Card, Navbar, Nav, NavItem, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import PermissionService from '../Services/PermissionService';
import './LoginPage.css';
const config = require('../config.json');
const ACTIVATElogo = require('../Resources/ACTIVATE_logo_small.png');


function HelpPage(props:
    {
        username: string;
        switchView: Function;
    }) {

    const navigate = useNavigate();
    const [logout, setLogout] = useState(false);
    
    /**
    * @name Logout
    * @description Logouts out the User
    * @param
    * @returns {json}
    */
    async function Logout() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        if (!token) {
            setLogout(true);
            return;
        }
        try {
            await fetch(`${config.activateServer}:${config.activatePORT}/logout`, {
                method: 'POST',
                body: JSON.stringify({ token: token }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode: 'cors'
            })
        } catch (err) {
            console.log("ERROR " + err);
        }

        //  Clear storage
        sessionStorage.removeItem('authenticated');
        sessionStorage.removeItem('ACTIVATEtoken');
        //sessionStorage.removeItem('restorePage');
        // Set Logout   
        setLogout(true);
    }

    if (logout) {
        return <Navigate to="/login" />
    }
    return (
        <div>
            {props.username === undefined ?
                <Navbar id="navContainer">
                    <Container>
                        <Nav>
                            <img
                                id="ACTIVATElogo"
                                style={{ cursor: "pointer" }}
                                src={ACTIVATElogo}
                                onClick={() => navigate(-1)}
                                alt="ACTIVATElogo">
                            </img>
                        </Nav>
                        <Navbar.Collapse className="nav-help-links">
                            <Nav.Link onClick={() => navigate("/about")}>About</Nav.Link>
                        </Navbar.Collapse>
                        <Nav>
                            <NavItem>
                                <Button id="logoutButton" onClick={Logout}><FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon></Button>
                            </NavItem>
                        </Nav>
                    </Container>
                </Navbar> : null}
            <Container className="d-flex justify-content-center">
                <Card className="login-card">
                    <Card.Body>
                        <Card.Title>
                            <h1>Help</h1><hr/>
                        </Card.Title>
                        <p>If you are experiencing technical difficulties,
                            please contact <a href="mailto:activatehelpdesk@groups.mitre.org">activatehelpdesk@groups.mitre.org</a>.</p>
                        <p>An ACTIVATE Administrator will respond within 24-48 hours.</p>

                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}

export default HelpPage
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import PermissionService from '../../../Services/PermissionService';
import { Anchor } from 'react-bootstrap';
import { DataGrid, Selection, Column, Sorting, Paging} from 'devextreme-react/data-grid';
import { ModalErrorMessage } from '../MessageBox/ModalErrorMessage';
import { Icon } from '@ailibs/feather-react-ts';
import { LoadPanel } from 'devextreme-react/load-panel';

import HuddleTable from './HuddleTable';
import DeleteHuddleModal from '../../Huddles/DeleteHuddleModal';
const config = require("../../../config.json");

function HuddleSchedule(props:
{
  permissions: PermissionService,
  switchView: (newView: string) => void,
  teamletID: string,
  teamletName: string,
  setHuddleID: (huddleID: string) => void,
  setMeetingID: (meetingID: string) => void,
  setSelectedTeamletID: (teamletID: string) => void,
  isMemberOfTeamLet: boolean
}) {

//  Error Modal
const [showErrorModal, setShowErrorModal] = useState(false);
const [huddleSchedule, setHuddleSchedule] = useState("");
const [fromDate, setFromDate] = useState("");
const [refreshData, setRefreshData] = useState(true);

const [toDate, setToDate] = useState("");
const [dayCount, setDayCount] = useState(7);

// For Delete Modal
const [deleteModal, setDeleteModal] = useState(false);
const [deleteHuddleID, setDeleteHuddleID] = useState('');
const [deleteHuddleName, setDeleteHuddleName] = useState('');
const [deleteHuddleDescription, setDeleteHuddleDescription] = useState('');
const [deleteHuddleDate, setDeleteHuddleDate] = useState('');
const [deleteHuddleMeeting, setDeleteHuddleMeeting] = useState('');

//  Spinner Icon location
const position = { of: '#huddleScheduleGrid' };
const [showProgress, setShowProgress] = useState(false);

//  Init
useEffect(() => {
  const _currentDate = new Date();
  const _nextDate = new Date();
  _nextDate.setDate(_currentDate.getDate() + 6);

  setFromDate(() => {return _currentDate.toLocaleDateString().toLowerCase(); });
  setToDate(() => {return _nextDate.toLocaleDateString().toLowerCase(); });
}, []);

//  Init
useEffect(() => {
  //  Abort controller to close long fetch calls
  const huddleScheduleController = new AbortController();
  const token = sessionStorage.getItem('ACTIVATEtoken');

  //  Get Patients
  const fetchHuddleSchedule = async () => {
      if (props.teamletID === '' || fromDate === '') {
        return;
      }

      try {
        setShowProgress(false);

        await fetch(`${config.activateServer}:${config.activatePORT}/getHuddlesByTeamletID`, {signal: huddleScheduleController.signal,
            method: 'POST',
            body: JSON.stringify({ token: token, teamID: props.teamletID, daycount: dayCount, fromdate: formatDate(fromDate)}),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(res =>{
          if (!res.ok) {
            throw Error('Could not fetch the data for the resource');
          }
          return res.json();
        }).then(huddles =>{
          setHuddleSchedule(() => {return huddles; });
        }).catch(err =>{
          if (err.name === 'AbortError') {
            //console.log('fetch aborted');
          }
        });

        setShowProgress(false);
      } catch (err) {
          setShowErrorModal(true);
          huddleScheduleController.abort();
          setShowProgress(false);
      }
  };

  // call the function
  fetchHuddleSchedule().catch(console.error);

  //  Clean-up useEffect
  return () => {
    huddleScheduleController.abort();
  };
}, [fromDate, refreshData]);

function getHuddleName(e:any) {
  return (
    <p>{e.data.huddle.name}  </p>
  );
}

/**
* @name getMeetingTime
* @description Properly extract the time from the huddle datetime
* @param e:any
* @returns 
*/
function getMeetingTime(e:any) {
  let meetingLocalTime = <>(none)</>;
  
  //  Check for the proper data
  if (e.data != null && e.data.meetingTime != null) {
    const huddleId = e.data.huddle.internalID;
    const meetingId = e.data.internalID;
    meetingLocalTime = <>{new Date(e.data.meetingTime).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}).toLowerCase()}</>;
  
    return (
        <p className="actions">
          <Anchor onClick={(e) => {props.setHuddleID(huddleId);props.setMeetingID(meetingId); props.switchView("huddledetails"); }} title="Edit Meeting">{meetingLocalTime}</Anchor>
        </p>
    );
  } else {
    return (
      <p className="actions">
        {meetingLocalTime}
      </p>
    );
  }
}

/**
* @name getMeetingDate
* @description Properly extract the date from the huddle datetime
* @param e:any
* @returns 
*/
function getMeetingDate(e:any) {
  let meetingLocalDate = <>(none)</>;
   
  //  Check for the proper data
  if (e.data != null && e.data.meetingTime != null) {
    const huddleId = e.data.huddle.internalID;
    const meetingId = e.data.internalID;
    meetingLocalDate = <>{new Date(e.data.meetingTime).toLocaleDateString().toLowerCase()}</>;
  
    return (
        <p className="actions">
          <Anchor onClick={(e) => { props.setHuddleID(huddleId); props.setMeetingID(meetingId); props.switchView("huddledetails"); }} title="Edit Meeting">{meetingLocalDate}</Anchor>
        </p>
    );
  } else {
    return (
      <p className="actions">
        {meetingLocalDate}
      </p>
    );
  }
}

/**
* @name deleteHuddleScheduleClick
* @description Prompts to Delete Huddle Schedule
* @param id : string, name: string, description: string, date: string, meetingID: string
* @returns 
*/
function deleteHuddleScheduleClick(id : string, name: string, description: string, date: string, meetingID: string) {
  setDeleteHuddleID(id);
  setDeleteHuddleName(name);
  setDeleteHuddleDescription(description);
  setDeleteHuddleDate(date);
  setDeleteHuddleMeeting(meetingID);
  setDeleteModal(true);  
}

/**
* @name toggleDeleteModal
* @description Toggles the delete modal
* @param none
* @returns 
*/
function toggleDeleteModal() {
  setDeleteModal(false);
  setDeleteHuddleDescription('');
  setRefreshData((e) => {return !e})
}

/**
 * @name editHuddleScheduleClick
 * @description Prompts to Edit Huddle
 * @param grid row
 * @returns 
 */
function editHuddleScheduleClick(meetingID : string, name: string, description: string, date: string) {
  props.setMeetingID(meetingID);
  props.switchView('editHuddle');
}

/**
 * @name getActionButtons
 * @description Gets the delete Icon in assigned Patients
 * @param grid row
 * @returns 
 */
function getActionButtons(e:any) {
  let _huddleId = '';
  let _meetingId = '';
  let _name = '';
  let _description = '';
  let _meetingTime = '';

  //  Check for data
  if (e.data != null) {
    _meetingId = e.data.huddle.internalID
    _huddleId = e.data.internalID; 
    _name = e.data.huddle.name;
    _description = e.data.huddle.description ? e.data.huddle.description : '';
    _meetingTime = e.data.meetingTime;
   
    return (
          <p className="actions">
              <Anchor hidden={!canEditHuddles()} disabled={isDateInThePast(_meetingTime)} onClick={(e) => {editHuddleScheduleClick(_huddleId, _name, _description, _meetingTime);e.preventDefault();}} title="Edit"><Icon name="edit" size="18" className="action-icon" data-internalid={_huddleId} /></Anchor>
              <Anchor hidden={!canDeleteHuddles()} disabled={isDateInThePast(_meetingTime)} onClick={(e) => {deleteHuddleScheduleClick(_huddleId, _name, _description, _meetingTime, _meetingId);e.preventDefault();}} title="Remove"><Icon name="trash-2" size="18" className="action-icon" data-internalid={_huddleId} /></Anchor>
          </p>
      );
    } else {
      return (
        <></>
      );
    }
  }

/**
 * @name formatDate
 * @description Formats date in mm/dd/yyyy format
 * @param string 
 * @returns 
*/
function formatDate(dateStringToFormat:string) {
  let date, month;
  const inputDate = new Date(dateStringToFormat);
  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  const year = inputDate.getFullYear();
  
  date = date.toString().padStart(2, '0');
  month = month.toString().padStart(2, '0');
  
  return `${month}/${date}/${year}`;
}

/**
 * @name isDateInThePast
 * @description Check if Date is in the past
 * @param string 
 * @returns 
*/
function isDateInThePast(dateToCheck:string) {
  const date1 = new Date(dateToCheck).getTime();
  const date2 = new Date().getTime();

  if (date1 < date2) {
    return true;
  } else if (date1 > date2) {
    return false;
  } else if (date1 === date2) {
    return true;
  } else {
    return false;
  }
}

/**
 * @name goPreviousDate
 * @description Moves date to previous week
 * @param string
 * @returns 
*/
function goPreviousDate() {
  const _fromDate = new Date(fromDate);
  const _toDate = new Date(fromDate);
  _fromDate.setDate(_fromDate.getDate() - 7);
  _toDate.setDate(_toDate.getDate() - 1);

  setDayCount(() =>{return 7; });
  setFromDate(() => {return _fromDate.toLocaleDateString().toLowerCase(); });
  setToDate(() => {return _toDate.toLocaleDateString().toLowerCase(); });
}

/**
 * @name goNextDate
 * @description Moves date to next week
 * @param string
 * @returns 
*/
function goNextDate() {
  const _fromDate = new Date(toDate);
  const _toDate = new Date(toDate);
  _toDate.setDate(_toDate.getDate() + 7);
  _fromDate.setDate(_fromDate.getDate() + 1);

  setDayCount(() =>{return 7; });
  setFromDate(() => {return _fromDate.toLocaleDateString().toLowerCase(); });
  setToDate(() => {return _toDate.toLocaleDateString().toLowerCase(); });
}

/**
 * @name scheduleNewHuddle
 * @description Gets the delete Icon in assigned Patients
 * @param grid row
 * @returns 
*/
function scheduleNewHuddle() {
  props.switchView('addHuddle');
}

//  Check if User can edit a huddle
function canEditHuddles(){
  if (props.permissions.checkPermissionSetting("REQUIRES_MEMBERSHIP", "READ") === true){
    if (props.isMemberOfTeamLet){
      return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "HUDDLE_SCHEDULE", "UPDATE")
    } else {
      return false;
    } 
  } else {
    return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "HUDDLE_SCHEDULE", "UPDATE")
  }
}

//  Check if User can delete a huddle
function canDeleteHuddles(){
  if (props.permissions.checkPermissionSetting("REQUIRES_MEMBERSHIP", "READ") === true){
    if (props.isMemberOfTeamLet){
      return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "HUDDLE_SCHEDULE", "DELETE")
    } else {
      return false;
    } 
  } else {
    return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "HUDDLE_SCHEDULE", "DELETE")
  }
}

//  Check if User can schedule new huddle
function canScheduleNewHuddle(){
  if (props.permissions.checkPermissionSetting("REQUIRES_MEMBERSHIP", "READ") === true){
    if (props.isMemberOfTeamLet){
      return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "HUDDLE_SCHEDULE", "CREATE")
    } else {
      return false;
    } 
  } else {
    return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "HUDDLE_SCHEDULE", "CREATE")
  }
}

//  Show/Hide Action Column
function showActionColumn(){
  //  Check if Edit or Delete is allowed
  if (canEditHuddles() || canDeleteHuddles()){
    return true;
  } else {
    return false;
  }
}

  return (
      <div>
          <div>
              <section>
                <h2>Huddle Schedule</h2>
              </section>
              <div className="d-flex">
                  <p><label>{fromDate}</label>&nbsp;to&nbsp;<label>{toDate}</label></p>
                  <div className="form-group reset alignRight">
                    <Anchor onClick={() => goPreviousDate()}><Icon size="14" name="arrow-left" className="btn-link"></Icon>Prev&nbsp;</Anchor>
                    <Anchor onClick={() => goNextDate()}>Next<Icon size="14" name="arrow-right" className="btn-link"></Icon></Anchor>
                  </div>
              </div>
              <div id="huddleScheduleGrid">
                <LoadPanel visible={showProgress} message={"Loading Huddle Schedule"} showPane={true} position={position} showIndicator={true}></LoadPanel>
                <DataGrid id="grid-container" 
                    dataSource={huddleSchedule}
                    showColumnLines={false}
                    showRowLines={false}
                    showBorders={false}
                    focusStateEnabled={true}
                    allowColumnResizing={true} 
                    showColumnHeaders={true}
                    width={'100%'}
                    noDataText='No Huddles scheduled for this Teamlet'>
                    <Selection mode="none" />
                    <Sorting mode="single" />    
                    <Paging defaultPageSize={7} />
                      <Column dataField="internalID" caption="Meeting Id" visible={false} />
                      <Column dataField="huddle.internalID" caption="huddle Id" visible={false} />
                      <Column width={'80'} dataField="meetingTime" caption="Date" cellRender={e => getMeetingDate(e)} allowSorting={false} />
                      <Column width={'80'} dataField="meetingTime1" caption="Time" cellRender={e => getMeetingTime(e)} allowSorting={false} />
                      <Column width={'220'} dataField="huddle.name" caption="Name" allowSorting={false} />
                      <Column dataField="huddle.description" caption="Description" allowSorting={false} />
                      <Column width={'80'} dataField="assignedPatientCount" alignment={'center'} caption="Patients" allowSorting={false} />
                      <Column width={'80'} visible={showActionColumn()} caption="Action" alignment="center" cellRender={e => getActionButtons(e)} />
                </DataGrid>
              </div>
       </div>
       <div>
          <Anchor onClick={scheduleNewHuddle} id={props.teamletID} hidden={!canScheduleNewHuddle()}>Schedule New Huddle</Anchor>
       </div>
      
       <ModalErrorMessage
            showErrorModal={showErrorModal} 
            errorMessageTitle={"Assigned Patients"} 
            errorMessage={"An error occurred retrieving Assigned Patients"} 
            errorServerMessage={"See console for error message."} 
            errorUserMessage={""}
            messageType="error" 
            setShowErrorModal={setShowErrorModal}  
       />

      <DeleteHuddleModal
          showModal={deleteModal}
          internalID={deleteHuddleID}
          teamlet={props.teamletName}
          name={deleteHuddleName}
          description={deleteHuddleDescription}
          dateTime={deleteHuddleDate}
          toggleDeleteModal={toggleDeleteModal}/>
      </div>
    );
  }

  export default HuddleSchedule;

const config = require("../config.json");
 /**
 * @name getTeamletByID
 * @description fetches teamlet data from database
 * @param
 * @returns
 */

  export async function getTeamletByID(teamletid: string) {
    const token = sessionStorage.getItem('ACTIVATEtoken');
    try {
      const response = await fetch(
        `${config.activateServer}:${config.activatePORT}/getTeamletByID/`,
        {
          method: "POST",
          body: JSON.stringify({ token: token, internalID: teamletid }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          mode: "cors",
        }
      )
      try {
        const data = await response.json();
        return(data);
      } catch (err) {
        console.log("ERROR " + err);
      }
    } catch (err) {
      console.log("ERROR " + err);
    }
  }
import React, { useEffect, useState } from 'react';
import { Chart, Series, CommonSeriesSettings, Legend, ValueAxis, Title, Export, Tooltip, ArgumentAxis, CommonAxisSettingsLabel, Aggregation, Point} from 'devextreme-react/chart';
import PermissionService from '../../../Services/PermissionService';
import { LoadPanel } from 'devextreme-react/load-panel';

const config = require("../../../config.json");

/* 
 * DataReadingsGraph is called from DataReadingsManager
 * props include noreadings and timeRange (week/twoweeks/month)
*/
function DataReadingsGraph(props:
    {
        permissions: PermissionService,
        switchView: (view: string) => void
        noReadings: boolean
        timeRange: string
        dataReadingsSummary: any
        showSpinner: boolean
    }) {

    const [noReadings, setNoReadings] = useState(false);
    const [patients, setPatients] = useState('allpatients');
    const [dailyStatus, setDailyStatus] = useState<any[]>([]);
   
    //  Location
    const position = { of: '#graphInfo' };

    // get the grid data.  this will be called if there's a change in the "noreadings" status or the time range.
    // for now, the option for 'patients' is always 'allpatients'.  The graph is intended to be separate from the 
    // grid, so the "allpatients/my patients" button only affects the grid, but the option is also there should that
    // change.
    useEffect(() => {
        //  Check for proper props
        if (props.noReadings === undefined) {
            setDailyStatus([]);
            return;
        }

        if (props.dataReadingsSummary === undefined){
            setDailyStatus([]);
            return;
        }

        const thresholds = props.noReadings === true ? 'noreadings' : 'exceedthresholds';


        let newArray: any[] = [];
        Object.values(props.dataReadingsSummary.dailyStatus).forEach((i: any) => {
            let newEntry = {
                "date": i.date,
                "outlierPatientCount": i.outlierPatientCount,
                "totalPatientCount": i.totalPatientCount - i.outlierPatientCount,
                "total": i.totalPatientCount
            }
            newArray.push(newEntry);
        });

        setDailyStatus(newArray);
        
        // if props.noReadings is true, the user has selected the "no readings" tab
        setNoReadings(props.noReadings);


    }, [props.noReadings, props.timeRange, patients, props.dataReadingsSummary]);

    /**
    * @name customizeTooltip
    * @description set the tooltips for the top or bottom of the graph,  and for exceedsthresholds vs. for noreadings
    * @param arg
    * @returns tooltip text
    */
    function customizeTooltip(arg: any) {
        let tooltip = '';
        let plural = arg.point.value == 1 ? "patient" : "patients";
        
        if (noReadings) { 
            
            tooltip = plural + " had no readings"
            if (arg.point.value===arg.point.data.outlierPatientCount) {
                return {
                text: `${arg.point.data.outlierPatientCount} ${tooltip} on ${arg.argument}`
                }
            } else {
                return {
                    text: `${arg.point.value} ${plural} were eligible to report readings on ${arg.argument}`
                    }
            }
        } else {
            //  Support custom toolTips based on the Series
            if (arg.seriesName.startsWith("Patients Who Reported a Data Reading")){
                tooltip = plural + " outside thresholds"
                    if (arg.point.data.outlierPatientCount === arg.point.data.total){
                        return {
                            text: `${arg.point.data.outlierPatientCount} ${tooltip} on ${arg.argument}` + `\r\n\r\n${arg.point.value} ${plural} reported readings on ${arg.argument}`
                            }
                    } else {
                        return {
                        text: `${arg.point.data.outlierPatientCount} ${tooltip} on ${arg.argument}`
                        }
                    }
            } else {
                return {
                    text: `${arg.point.value} ${plural} reported readings on ${arg.argument}`
                }
            }
        }
        
    }

    function sortLegendItems(items:any) {
        return items.sort((a:any, b:any) => {
            let itemA = a.text.toLowerCase();
            let itemB = b.text.toLowerCase();
            if(itemA < itemB) return -1;
            if(itemA > itemB) return 1;
            return 0;
        });
    }

    return (
        <div id="graphInfo">
            <LoadPanel visible={props.showSpinner} message={"Loading..."} showPane={true} position={position} showIndicator={true}></LoadPanel>
            <Chart
                id="chart"
                title={noReadings === true ? "Patients with No Data Readings" : "Patients Reporting Any Data Readings Outside Thresholds"}
                dataSource={dailyStatus}>

                <CommonSeriesSettings argumentField="date" type="stackedBar">
                </CommonSeriesSettings>
                
                <Series color={noReadings === true ? "#644EB8" : "#C46A10"}
                    valueField="outlierPatientCount"
                    name={noReadings === true ? "Patients Who Reported No Data Readings" : "Patients Who Reported a Data Reading<br/>Outside Thresholds (High OR Low)"}
                >
                </Series>
                
                <Series color={"#BEDFFD"}
                    valueField="totalPatientCount"
                    name={noReadings === true ? "Patients Eligible to Report Data Readings" : "Patients Reporting Data Readings"}>
                        
                </Series>

                <ValueAxis position="left" allowDecimals={false}>
                    <Title text="Patient Count" />
                </ValueAxis>
                <Legend
                    margin={{ bottom: 0, top: 120 }}
                    verticalAlignment="middle"
                    horizontalAlignment="right"
                    itemTextPosition="right"
                    customizeItems={sortLegendItems}
                />
                <Export enabled={false} />
                <Tooltip
                    enabled={true}
                    location="edge"
                    customizeTooltip={customizeTooltip}
                />
            </Chart>
        </div>
    )
}
export default DataReadingsGraph;
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Navbar, Button, Container, Nav, NavItem, Dropdown, ButtonGroup } from 'react-bootstrap/';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';
const navbarLogo = require('./../Resources/ACTIVATE_logo_small.png');

interface CustomLocation {
    userOrganizations : any;
}

function AdminNavbar( props : 
    {
        expandCollapseSidebar : Function;
        roleName : string;
        username : string;
        switchView : (newView : string) => void;
        organizationList : any[],
        selectedOrganization : string,
        selectedOrganizationID : string,
        setSelectedOrganization : Function
    }) {
    const location = useLocation().state as CustomLocation;
    const navigate = useNavigate();
    const [logout, setLogout] = useState(false);
    const [displayOrganizationList, setDisplayOrganizationList] = useState<any[]>([]);
    const [currentOrgID, setCurrentOrgID] = useState("");
    const [currentOrgName, setCurrentOrgName] = useState("");
    const config = require('../config.json');

    useEffect(() => {
        if (location) {
            loadOrganizationDropdown(location.userOrganizations);
        } else {
            setLogout(true);
        }
    }, [location]);

    /**
    * @name Logout
    * @description Logouts out the User
    * @param
    * @returns {json}
    */
    async function Logout() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        try {
            await fetch(`${config.activateServer}:${config.activatePORT}/logout`, {
                method: 'POST',
                body: JSON.stringify({ token: token }),
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode : 'cors'
            });
        } catch (err) {
            console.log("ERROR " + err);
        }

        //  Clear storage
        sessionStorage.removeItem('authenticated');
        sessionStorage.removeItem('ACTIVATEtoken');
        //sessionStorage.removeItem('restorePage');
        // Set Logout   
        setLogout(true);
    }

    /**
    * @name organizationDropdown
    * @description Loads Organization Dropdown
    * @param
    * @returns {json}
    */
    function organizationDropdown() {
        if (displayOrganizationList.length > 1) {
            return (
                <>
                <Button
                    style={{ "color" : "black", "backgroundColor" : "white", borderColor : "white"}}
                    onClick={() => goToOrgDashBoard()} >
                    <FontAwesomeIcon icon={faHouse} />
                </Button>
                <Dropdown as={ButtonGroup}>
                    <Button 
                        className="navButton"
                        style={{ "color" : "black", "backgroundColor" : "white", borderColor : "white"}}
                        onClick={() => {return;}}>
                        {currentOrgName}
                    </Button>
                    <Dropdown.Toggle className="navButton" split variant="success" id="dropdown-split-basic" />

                    <Dropdown.Menu>
                        {displayOrganizationList}
                    </Dropdown.Menu>
                </Dropdown>
                </>
            );
        } else {
            return (
                <span>
                    <Button
                        style={{ "color" : "black", "backgroundColor" : "white", borderColor : "white"}}
                        onClick={() => goToOrgDashBoard()} >
                        <FontAwesomeIcon icon={faHouse} />
                    </Button>
                    <Button
                        className='navButton'>
                            {displayOrganizationList[0]}
                    </Button>
                </span>
            );
        }
    }

    function goToOrgDashBoard() {
        props.switchView("dashboard");
    }
    
    function loadOrganizationDropdown(newOrgList : any[]) {
        const tempOrganizationList : any = [];
        //console.log("NEWORGLIST " + JSON.stringify(newOrgList));
        for (let i = 0; i < newOrgList.length; i++) {
            tempOrganizationList.push(
                <Dropdown.Item
                    onClick={() => healthCenterChange(newOrgList[i].name, newOrgList[i].value)}
                    key={i}
                    eventKey={i}
                    data-name={newOrgList[i].name}
                    value={newOrgList[i].value}>
                        {newOrgList[i].name}
                </Dropdown.Item>
            );
        }
        setDisplayOrganizationList(tempOrganizationList);
        setCurrentOrgName(() => {return newOrgList[0].name; });
        setCurrentOrgID(() => {return newOrgList[0].value; });
    }

    function healthCenterChange(orgName : string, orgID : string) {
        setCurrentOrgID(orgID);
        setCurrentOrgName(orgName);
        props.setSelectedOrganization(orgID, orgName);
    }

    function showAboutPage() {
        props.switchView("about");
    }

    function showHelpPage() {
        props.switchView("help");
    }

    if (logout) {
        return <Navigate to="/login"/>;
    }
    return (
        <Navbar id="navContainer">
            <Container fluid>
                <Nav>
                    <Button id="expandButton" className="menu-expand-button" onClick={() => props.expandCollapseSidebar()}>
                        <FontAwesomeIcon icon={faBars}/>
                    </Button>
                    <img 
                        id="ACTIVATElogo"
                        style={{cursor : "pointer"}}
                        src={navbarLogo}
                        onClick={() => props.switchView("dashboard")}
                        alt="ACTIVATElogo">
                    </img>
                    {/* TODO: Better utilize Bootstrap split button: https://getbootstrap.com/docs/5.2/components/dropdowns/#split-button */}
                    {props.roleName !== 'ROLE_ACTIVATE_ADMINISTRATOR' ?
                        organizationDropdown()
                    :
                    <></>
                    }
                </Nav>
                <Navbar.Collapse className="nav-help-links">
                    <Nav.Link onClick={() => props.switchView("about")}>About</Nav.Link>
                    <Nav.Link onClick={() => props.switchView("help")}>Help</Nav.Link>
                </Navbar.Collapse>
                <Nav>
                    <NavItem className="nav-user-info">
                        <p className="nav-user-name">{props.username}</p>
                        <p className="nav-user-role">{props.roleName}</p>
                    </NavItem>
                    <NavItem>
                        <Button id="logoutButton" onClick={Logout}><FontAwesomeIcon icon={ faSignOutAlt }></FontAwesomeIcon></Button>
                    </NavItem>
                </Nav>
            </Container>
        </Navbar>
    );
}

export default AdminNavbar;
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Container, Row, Col, Breadcrumb, Form, Table } from 'react-bootstrap';
import DataGrid, { Column, GroupPanel, Pager, Paging } from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react';
import "./DataGridTable.css"

function DataGridTable(props: {switchView: Function, dataSource:Array<any>, keyExpr:any, columnHeadings:Array<any>, columns: Array<any>}) {
    const [validated, setValidated] = useState(false);
    const [columns, setColumns] = useState([props.columns]);
    

    const cellRender = (data:any) => {
        return <>{data.value}</> ;
      }

    return(
        <div>
            <DataGrid id="grid-container"
                        keyExpr={props.keyExpr}
                        focusStateEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        showBorders={false}
                        dataSource={props.dataSource}
                        defaultColumns={columns}
                        noDataText='No users have been loaded'>
                        <Template name="col" render={cellRender} />

                        <GroupPanel visible={false} />
                        <Paging
                            defaultPageSize={9}
                            defaultPageIndex={1} />
                        <Pager showNavigationButtons={true} />

                    </DataGrid>

        </div>
    )
} 

export default DataGrid
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import PermissionService from '../../../Services/PermissionService';
import { Button, Anchor, Modal } from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import ModalRemovePatientFromMeeting from './ModalRemovePatientFromMeeting';
import DataGrid, { Column, Paging } from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react';
import PatientHuddleQuickAdd from './PatientHuddleQuickAdd';

const config = require("../../../config.json");

function PatientHuddleSchedule(props:
  {
    permissions: PermissionService;
    switchView: (newView: string) => void;
    patientID: string;
    setHuddleID: (huddleID: string) => void;
    setMeetingID: (meetingID: string) => void;
    setSelectedTeamletID: (teamletID: string) => void;
    teamletSummary: any;
    isMemberOfTeamLet: boolean;
  }) {

  //  Form State

  //  Spinner Icon location
  const position = { of: '#huddleScheduleGrid' };
  const [showProgress, setShowProgress] = useState(false);
  const [huddleDetails, setHuddleDetails] = useState<any[]>([]);
  const [meetingTime, setMeetingTime] = useState("");
  const [huddleName, setHuddleName] = useState("");
  const [huddleID, setHuddleID] = useState("");
  const [meetingID, setMeetingID] = useState("");
  const [reason, setReason] = useState("");
  const [patientName, setPatientName] = useState("");
  const [teamletID, setTeamletID] = useState("");
  const [teamletName, setTeamletName] = useState("");
  const [teamletDescription, setTeamletDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [notesAuthor, setNotesAuthor] = useState("");
  const [taggedBy, setTaggedBy] = useState("");
  const [showPatient, setShowPatient] = useState(false);
  const [modalEditType, setModalEditType] = useState("");
  const [meetingPatientID, setMeetingPatientID] = useState("");
  const [showRemovePatientModal, setShowRemovePatientModal] = useState(false);
  const [noData, setNoData] = useState(false);
  const [refreshData, setRefreshData] = useState(true);

  //  Error Model
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessageTitle, setErrorMessageTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorServerMessage, setErrorServerMessage] = useState("");
  const [errorUserMessage, setErrorUserMessage] = useState("");

  // Determine if we are quick adding or editing
  const [editMode, setEditMode] = useState('add');
  // Display the quick add modal
  const [showQuick, setShowQuick] = useState(false);
  // Used for the quick modal to know what to delete
  const [editPatientMeetingID, setEditPatientMeetingID] = useState('');
  // Used to let the quick add modal know a delete occurred
  const [deleteOccurred, setDeleteOccured] = useState(false);
  // Use sparingly only for needed updates
  const [updatePage, setUpdatePage] = useState(false);
  //  API token
  const token = sessionStorage.getItem('ACTIVATEtoken');
    //  Fetch controllers
  const patientInfoController = new AbortController();
  const nextHuddleByPatientController = new AbortController();
  const nextMeetingController = new AbortController();
  
  //  Get Patient 
  const fetchPatientInfo = async() => {
    await fetch(`${config.activateServer}:${config.activatePORT}/getPatientById`, {signal: patientInfoController.signal, 
      method: 'POST',
      body: JSON.stringify({ token: token, patientID: props.patientID }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      mode: 'cors'
    }).then(res => {
      if (!res.ok) {
        throw Error('Could not fetch the data for the resource');
      }
      return res.json();
    }).then(patientData => {
      //  Set Patient Name globally
      //@ts-ignore
      setPatientName(() => {return patientData.firstName + ' ' + patientData.lastName})
    }).catch(err => {
      if (err.name === 'AbortError') {
        //console.log('fetch aborted');
      } else {
        patientInfoController.abort();
      }
    });
  }

  //  Get Huddle Info
  const fetchNextHuddleByPatientId = async () => {
    await fetch(`${config.activateServer}:${config.activatePORT}/getNextHuddleByPatientId`, {signal: nextHuddleByPatientController.signal,
      method: 'POST',
      body: JSON.stringify({ token: token, patientID: props.patientID }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      mode: 'cors'
    }).then(res => {
      if (!res.ok) {
        throw Error('Could not fetch the data for the resource');
      }
      return res.json();
    }).then(huddleinfo => {
      if (huddleinfo.internalID==="") {
        setNoData(true);
        return;
      }

      //  Set Patient Name globally
      //@ts-ignore
      setHuddleDetails(huddleinfo);
    }).catch(err => {
      if (err.name === 'AbortError') {
        //console.log('fetch aborted');
      } else {
        nextHuddleByPatientController.abort();
      }
    });

    
    try {
      setShowProgress(false);
    } catch (err) {
      setShowProgress(false);
    }
  };


  //  Init
  useEffect(() => {
    // Call the functions
    fetchNextHuddleByPatientId().catch(console.error);
    fetchPatientInfo().catch(console.error);
  }, [props.patientID, refreshData]);

   //  Init
   useEffect(() => {
    if (meetingID !== undefined && meetingID !== '' && noData===false) {
      getCurrentMeeting(meetingID) ;
    }
  }, [meetingID]);

  async function getCurrentMeeting(meetingID: any) {
      const response = await fetch(`${config.activateServer}:${config.activatePORT}/getMeeting`, {signal: nextMeetingController.signal,
          method: "POST",
          body: JSON.stringify({ token: token, huddleID: meetingID }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          mode: "cors",
        }
      );
      try {
        const data = await response.json();
        const patientindex = data.patients.findIndex((i: any) => i.patient === props.patientID);
        if (patientindex > -1) {
          //setPatientData(data.patients);
          setMeetingPatientID(data.patients[patientindex].internalID);
          setNotes(data.patients[patientindex].notes);
          setNotesAuthor(data.patients[patientindex].notesAuthor);
          setTaggedBy(data.patients[patientindex].taggedBy);
        }
      } catch (err) {
        console.log("ERROR " + err);
      }
      fetchNextHuddleByPatientId().catch(console.error);
  }

  /**
   * @name editClick
   * @description sets up the edit patient modal
   * @param
   * @returns 
   */
  function editClick(meetTime: string, internalID : string, meetingID : string, 
    huddlename : string, huddlePatientName : string, meetingCriteria : string, 
    healthcareTeamInternalID : string, healthcareTeamName : string, 
    healthcareTeamDescription : string, patientInternalID : string) {
  
    //  Set State for pop-up
    setMeetingTime(getMeetingDate(meetTime));
    setHuddleID(internalID);
    setMeetingID(meetingID);
    setHuddleName(huddlename);
    setPatientName(huddlePatientName);
    setReason(meetingCriteria);
    setTeamletID(healthcareTeamInternalID);
    setTeamletName(healthcareTeamName);
    setTeamletDescription(healthcareTeamDescription);
    setEditPatientMeetingID(patientInternalID);

    //  Set Edit Type
    setEditMode('edit');
    setModalEditType("edit");
    setShowQuickModal(true, "edit");
  }

  /**
  * @name trashClick
  * @description on clicking the trash icon, sets up the delete modal
  * @param
  * @returns 
  */
  function trashClick(meetTime: string, internalID : string, meetingID : string, 
      huddlename : string, huddlePatientName : string, meetingCriteria : string, 
      healthcareTeamInternalID : string, healthcareTeamName : string, 
      healthcareTeamDescription : string, patientInternalID : string) {
    
    //  Set State for pop-up
    setMeetingTime(getMeetingDate(meetTime));
    setHuddleID(internalID);
    setMeetingID(meetingID);
    setHuddleName(huddlename);
    setPatientName(huddlePatientName);
    setReason(meetingCriteria);
    setTeamletID(healthcareTeamInternalID);
    setTeamletName(healthcareTeamName);
    setTeamletDescription(healthcareTeamDescription);
    setEditPatientMeetingID(patientInternalID);

    //  Show Delete Modal
    setShowRemovePatientModal(true);
    setModalEditType("");
    getCurrentMeeting(meetingID);
  }

  /**
   * @name formatDate
   * @description Formats date in mm/dd/yyy format
   * @param string 
   * @returns 
  */
  function formatDate(dateStringToFormat: string) {
    let date, month;
    const inputDate = new Date(dateStringToFormat);
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    const year = inputDate.getFullYear();

    date = date.toString().padStart(2, '0');
    month = month.toString().padStart(2, '0');

    return (`${month}/${date}/${year}`);
  }

  /**
  * @name getMeetingDate
  * @description Properly extract the date from the huddle datetime
  * @param grid row
  * @returns 
  */
  function getMeetingDate(MeetingTime : string) {

    const meetingDT = new Date(MeetingTime);
    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const occurrenceDate = formatDate(MeetingTime);
    const occurrenceDay = weekday[meetingDT.getDay()];
    const occurrenceTime = new Date(MeetingTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toLowerCase();

    const dateandtime = `${occurrenceDate} ${occurrenceDay} @ ${occurrenceTime}`;
    return dateandtime;
  }

/**
* @name setupAddPatient
* @description Shows the feather icon
* @param
* @returns
*/
async function setupAddPatient() {
  await getCurrentMeeting(meetingID);
  setShowPatient(true);
}

/**
* @name renderIcon
* @description Shows the feather icon
* @param
* @returns
*/
function renderIcon(iconName: any, category: string) {
  return (
      <div className={`ui-icon ${category}`}>
        <Icon name={iconName} size="24" />
      </div>
    );
}

/**
* @name setShowQuickModal
* @description Shows QuickModal
* @param
* @returns
*/
function setShowQuickModal(toggle: boolean, editType: string) {
  setEditMode(editType);
  setShowQuick(toggle);
  if (toggle === false) {
    setUpdatePage(!updatePage);
  }
}

/**
* @name updateHuddleSchedule
* @description Call back for updates
* @param grid row
* @returns 
*/
function updateHuddleSchedule() {
  setRefreshData((e) => { return !e; });
}

/**
* @name renderHuddle
* @description Builds Huddle Info
* @param grid row
* @returns 
*/
function renderHuddle(e:any) {
  return (
    <table width={'100%'}>
      <tr>
        <td>{renderIcon("calendar", "todo")}</td>
        <td colSpan={2}>
          <Anchor onClick={() => {props.setHuddleID(e.data.internalID); props.setMeetingID(e.data.meeting); props.switchView("huddledetails"); }}>{getMeetingDate(e.data.meetingTime)} - {e.data.name}</Anchor>
        </td>
      </tr>
    <tr>
      <td style={{width:"30px"}}></td>
      <td style={{width:"40px"}} rowSpan={2}>{renderIcon("users", "people")}&nbsp;&nbsp;</td>
      <td style={{wordBreak:'break-word', whiteSpace:'normal'}}><label>Reason:</label>&nbsp;{e.data.meetingCriteria}</td>
    </tr>
    <tr>
      <td></td>
      <td colSpan={2}><Anchor onClick={() => {props.setSelectedTeamletID(e.data.healthcareTeam.internalID); props.switchView("TeamletDetails"); }}>{e.data.healthcareTeam.name}</Anchor></td>
      <td></td>
    </tr>
    <tr>
      <td style={{width:"30px"}}></td>
      <td style={{width:"40px"}} colSpan={2}>{teamletDescription}</td>
    </tr>
  </table>
  );
}

function huddleDeleted(meetingID : string) {
  setDeleteOccured(!deleteOccurred);
  getCurrentMeeting(meetingID);
}

/**
* @name renderEditIcons
* @description Builds the Edit and Delete Icons
* @param grid row
* @returns 
*/
function renderEditIcons(e:any) {
  const meetTime = e.data.meetingTime;
  const meetingId = e.data.meeting;
  const internalId = e.data.internalID;
  const huddlename = e.data.name;
  const huddlePatientName = e.data.patient.name;
  const meetingCriteria = e.data.meetingCriteria;
  const healthcareTeamInternalID = e.data.healthcareTeam.internalID;
  const healthcareTeamName = e.data.healthcareTeam.name;
  const healthcareTeamDescription = e.data.healthcareTeam.description;
  const patientInternalID = e.data.patient.internalID;

  let isMember = false;

  //   Check if membership is required
  if (props.permissions.checkPermissionSetting("REQUIRES_MEMBERSHIP", "READ") === true){
    isMember = e.data.healthcareTeamMembers.indexOf(props.permissions.myInternalID) >= 0 ? true : false;
  } else {
    isMember = true;
  }
 
  return (
    <>
    <table>
      <tbody>
        <tr>
          <td><Anchor hidden={!isMember} onClick={() => editClick(meetTime, internalId, meetingId, huddlename, huddlePatientName, meetingCriteria, healthcareTeamInternalID, healthcareTeamName, healthcareTeamDescription, patientInternalID)}><Icon name="edit" size="18" className="action-icon " /></Anchor></td>
          <td><Anchor hidden={!isMember} onClick={() => trashClick(meetTime, internalId, meetingId, huddlename, huddlePatientName, meetingCriteria, healthcareTeamInternalID, healthcareTeamName, healthcareTeamDescription, patientInternalID)}><Icon name="trash-2" size="18" className="action-icon " /></Anchor></td>
        </tr>
      </tbody>
    </table>
    </>
  );
}

  return (
    <div>
      <section>
          <div className="panel-header">
            <h2>{huddleDetails.length===1 ? 'Scheduled Huddle' : 'Scheduled Huddles'}</h2>
            <Anchor onClick={() => props.switchView("huddleHistory")}>
              View History
            </Anchor>
          </div>
      </section>
      <div className="d-flex" id="huddleScheduleGrid">
        <LoadPanel visible={showProgress} message={"Loading Scheduled Huddles"} showPane={true} position={position} showIndicator={true}></LoadPanel>
        <div id="scheduledHuddles">
          <DataGrid id="grid-container" 
              dataSource={huddleDetails}
              showColumnLines={false}
              showRowLines={false}
              showBorders={false}
              showColumnHeaders={false}
              width={'100%'}
              noDataText='Not scheduled for upcoming huddles'>
              <Paging defaultPageSize={7} />
                <Column width={'1%'} dataField="meeting" caption="Meeting Id" visible={false} />
                <Column width={'1%'} dataField="internalID" caption="huddle Id" visible={false} />
                <Column width={'80%'} cellRender={e => renderHuddle(e)} />
                <Column width={'20%'} cellRender={e => renderEditIcons(e)} />
                {/*<Column dataField="assignedPatientCount" alignment={'center'} cellRender={e => getPatientCount(e)} />*/}
          </DataGrid>
     </div>
     
      <PatientHuddleQuickAdd
        showing={showQuick}
        setShowQuickModal={setShowQuickModal}
        patientName={patientName}
        patientID={props.patientID}
        editMode={editMode}
        huddleDetails={huddleDetails}
        teamletSummary={props.teamletSummary}
        originalMeetingID={meetingPatientID}
        teamletID={teamletID}
        teamletName={teamletName}
        meetingID={meetingID}
        meetingTime={formatDate(meetingTime)}
        reason={reason}
        notes={notes}
        taggedBy={taggedBy}
        notesAuthor={notesAuthor}
        callAddComplete={updateHuddleSchedule}
        deleteOccured={deleteOccurred}
        permissions={props.permissions}
      />

      <ModalRemovePatientFromMeeting
        showRemovePatientModal={showRemovePatientModal}
        setShowRemovePatientModal={setShowRemovePatientModal}
        patientName={patientName}
        patientID={props.patientID}
        criteria={reason}
        teamletID={teamletID}
        internalID={meetingPatientID}
        name={huddleName}
        teamletName={teamletName}
        description={teamletDescription}
        teamletTime={meetingTime}
        meetingID={meetingID}
        toggleRemoveModal={() => { setShowRemovePatientModal(false); }}
        huddleDeleted={huddleDeleted}
      />

      <Modal show={showErrorModal}>
        <Modal.Header className="error">
          <h2>{errorMessageTitle}</h2>
        </Modal.Header>
        <Modal.Body>
          {errorMessage}<br />
          <br /><b>{errorServerMessage}</b><br />
          <br />{errorUserMessage}<br />
        </Modal.Body>
        <Modal.Footer>
          <Button className='error submit' onClick={() => setShowErrorModal(false)}>Ok</Button>
        </Modal.Footer>
      </Modal>

    </div>
      <br/>
      { props.isMemberOfTeamLet === true ?
      <Anchor onClick={(e) => { setModalEditType("add"); setEditMode('add'); setShowQuickModal(true, "add"); }} id={props.patientID}>Add Patient to Huddle</Anchor>
      : null}
    </div>

  );
}

export default PatientHuddleSchedule;

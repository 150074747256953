import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import PermissionService from '../../../Services/PermissionService';

const config = require("../../../config.json");

/* 
 * DataReadingsPatientCount is called from DataReadingsManager
 * props include noreadings and timeRange (week/twoweeks/month)
*/
function DataReadingsPatientCount(props:
    {
        permissions: PermissionService,
        switchView: (view: string) => void
        noReadings: boolean
        timeRange: string
        dataReadingsSummary: any
    }) {

    const [patients, setPatients] = useState('allpatients');
    const [totalPatientCount, setTotalPatientCount] = useState(0);
    const [outlierPatientCount, setOutlierPatientCount] = useState(0);
    const [outlierPatientCountToday, setOutlierPatientCountToday] = useState(0);
    const [percentage, setPercentage] = useState(0.0);
    const token = sessionStorage.getItem('ACTIVATEtoken');
    
    // get the counts data.  this will be called if there's a change in the "noreadings" status or the time range.
    // for now, the option for 'patients' is always 'allpatients'.  The counts and graph are intended to be separate from the 
    // grid, so the "allpatients/my patients" button only affects the grid, but the option is also there should that
    // change.
    useEffect(() => {
        //  Check for proper props
        if (props.noReadings === undefined) {
            return;
        }

        if (props.dataReadingsSummary === undefined){
            return;
        }

        //  Set Patient Counts
        setTotalPatientCount(props.dataReadingsSummary.totalPatientCount); 
        setOutlierPatientCount(props.dataReadingsSummary.outlierPatientCount);
        setOutlierPatientCountToday(props.dataReadingsSummary.outlierPatientCountToday);

        //  Check for divide by 0
        if (props.dataReadingsSummary.totalPatientCount > 0) {
            const newPercentage: any = props.dataReadingsSummary.outlierPatientCount/props.dataReadingsSummary.totalPatientCount * 100;
            setPercentage(newPercentage.toFixed(1));                   
        } else {
            //  Default to 0
            setPercentage(0);
        }
    }, [props.timeRange, props.noReadings, props.dataReadingsSummary]);
    
    return (
        <div className="pdrm-metrics-tray">
            <Card>
                <Card.Body>
                    {props.noReadings === true ?
                        <>
                            <Card.Title>{totalPatientCount}</Card.Title>
                            <p>Patients eligible to report data readings in the past {props.timeRange==='twoweeks' ? 'two weeks' : props.timeRange}</p>
                        </> :
                        <>
                            <Card.Title>{totalPatientCount}</Card.Title>
                            <p>Unique patients reported data readings in the past {props.timeRange==='twoweeks' ? 'two weeks' : props.timeRange}</p></>}
                </Card.Body>
            </Card>

            <Card>
                <Card.Body>
                    {props.noReadings === true ?
                        <>
                            <Card.Title>{outlierPatientCount} of {totalPatientCount} ({percentage}%)</Card.Title>
                            <p>Patients did not report data readings in the past {props.timeRange==='twoweeks' ? "two weeks" : props.timeRange}</p>
                        </> :
                        <>
                            <Card.Title>{outlierPatientCount} of {totalPatientCount} ({percentage}%)</Card.Title>
                            <p>Patients reported data readings outside thresholds in the past {props.timeRange==='twoweeks' ? "two weeks" : props.timeRange}</p>
                        </>
                    }
                </Card.Body>
            </Card>

            <Card>
                <Card.Body>
                    {props.noReadings === true ?
                        <>
                            <Card.Title>{outlierPatientCountToday}</Card.Title>
                            <p>Patients did not report data readings in the past 24 hours</p>
                        </> :
                        <>
                            <Card.Title>{outlierPatientCountToday}</Card.Title>
                            <p>Patients reported data readings outside of thresholds today</p>
                        </>
                    }
                </Card.Body>
            </Card>
            </div>
        );
}
export default DataReadingsPatientCount;
import React, { useEffect, useState } from 'react';
import { Anchor, Breadcrumb, Container, Card, Form, Row, Col } from 'react-bootstrap';
import { DataGrid, Column, Paging, Selection} from 'devextreme-react/data-grid';
import PermissionService from '../../Services/PermissionService';

import './PatientInformation.css';
const config = require('../../config.json');

function PatientInformationVitalsHistory(props: 
    { 
        switchView: Function, 
        permissions: PermissionService, 
        patientID: string }) {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [patientVitals, setPatientVitals] = useState<any[]>([]);
    const [recentPatientVitals, setRecentPatientVitals] = useState<any[]>([]);
    const [monitorReadingThresholds, setMonitorReadingThresholds] = useState([]);
    const [isReadOnlyMode, setIsReadOnlyMode] = useState(true);
    const [thresholdType, setThresholdType]= useState("Individual")

    // Thresholds
    const [bpmMinThreshold, setBpmMinThreshold] = useState(70);
    const [bpmMaxThreshold, setBpmMaxThreshold] = useState(120);
    const [glucoseMinThreshold, setGlucoseMinThreshold] = useState(60);
    const [glucoseMaxThreshold, setGlucoseMaxThreshold] = useState(90);
    const [systolicMinThreshold, setSystolicMinThreshold] = useState(60);
    const [systolicMaxThreshold, setSystolicMaxThreshold] = useState(90);
    const [diastolicMinThreshold, setDiastolicMinThreshold] = useState(60);
    const [diastolicMaxThreshold, setDiastolicMaxThreshold] = useState(90);

    //  Init
    useEffect(() => {
        //  Load Accout Details
        loadPatientAccount(props.patientID);
        loadPatientVitals(props.patientID);
    }, [props.patientID])

    /**
    * @name loadUsers
    * @description Pulls all the currently enrolled users from the server
    * @param  
    * @returns {json} 
    */
     async function loadPatientAccount(patientID: string) {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getPatientSummaryById`, {
            method: 'POST',
            body: JSON.stringify({ token: token, patientID: patientID }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        })
        try {
            const data = await response.json();

            //  Fields that can be updated
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setMonitorReadingThresholds(data.monitorThresholds);

        } catch (err) {
            console.log("ERROR " + err);
        }
    }

    
    //  Most Recent Conditional Formating
    function formatMostRecentVitals(e: any) {
        return;
        try {
            //  Highlight Highs/Lows Blood Pressue
            if (e.rowType == "data" && e.column.dataField === "bloodpressure") {
                // Check for a value
                if (e.data.bloodpressure.trim().length > 0) {
                    let bpReadings = e.data.bloodpressure.split('/')
                    let bpSystolic = bpReadings[0];
                    let bpDiastolic = bpReadings[1];
                    //  Check Highs and Lows
                    if (parseInt(bpSystolic) > systolicMaxThreshold ||
                            parseInt(bpDiastolic) > diastolicMaxThreshold) {
                        e.cellElement.style.backgroundColor = "red";
                    } else if (parseInt(bpDiastolic) < systolicMinThreshold ||
                                parseInt(bpDiastolic) < diastolicMinThreshold) {
                        e.cellElement.style.backgroundColor = "yellow";
                    } else {
                        e.cellElement.style.backgroundColor = "";
                    }
                }
            }
            
            //  Highlight Highs/Lows Glucose
            if (e.rowType == "data" && e.column.dataField === "glucose") {
                // Check for a value
                if (e.data.glucose.trim().length > 0) {
                    if (parseInt(e.data.glucose) >= glucoseMaxThreshold) {
                        e.cellElement.style.backgroundColor = "red";
                        e.cellElement.style.color = "white";
                    } else if (parseInt(e.data.glucose) <= glucoseMinThreshold){
                        e.cellElement.style.backgroundColor = "yellow";
                    } else {
                        e.cellElement.style.backgroundColor = "";
                        e.cellElement.style.color = "black";
                    }
                }
            }
        
            //  Highlight Highs/Lows Heart Rate
            if (e.rowType == "data" && e.column.dataField === "heartrate") {
                // Check for a value
                if (e.data.heartrate.trim().length > 0) {
                if (parseInt(e.data.heartrate) >= bpmMaxThreshold) {
                    e.cellElement.style.backgroundColor = "red";
                } else if (parseInt(e.data.heartrate) <= bpmMinThreshold) {
                    e.cellElement.style.backgroundColor = "yellow";
                } else {
                    e.cellElement.style.backgroundColor = "";
                    e.cellElement.style.color = "black";
                }
                }
            }
        } catch (err) {
            console.log(err);
        }
        
    }

    //  Grid Header for BPM
    function renderHeartRateBPM() {
        return (
        <>
            <label>Heart Rate</label>
            <br />
            <label>bpm</label>
        </>
        );
    }
    
    //  Grid Header for Blood Pressure
    function renderBloodPressure() {
        return (
        <>
            <label>Blood Pressure</label>
            <br />
            <label>mmHg</label>
        </>
        );
    }
    
    //  Grid Header for Blood Glucose
    function renderBloodGlucose() {
        return (
        <>
            <label>Blood Glucose</label>
            <br />
            <label>mg/dl</label>
        </>
        );
    }

    function gridCellClicked(e: any) {
        e.event.stopImmediatePropagation();
    }

    /**
    * @name loadPatientVitals
    * @description Loads the selected Patient's Vitals
    * @param  
    * @returns {json} 
    */
     async function loadPatientVitals(patientID: string) {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        let _convertedVitalsList: React.SetStateAction<any[]> = [];

        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getPatientMonitorSpecsByPatientId`, {
                method: 'POST',
                body: JSON.stringify({ token: token, patientID: patientID }),
                headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                },
                mode: 'cors'
        })
     
        try {
            const data = await response.json();
            
            // Create Most Recent Vitals List
            // @ts-ignore
            var _recentVitals = [];

            data.sort(sortFunctionDesc);

            let mostRecentDate = data[0].readingTime;
            let i = 0;
            let continueDateProcessing = true;
            let foundPulseRate = false;
            let foundSystolic = false;
            let foundDiastolicRate = false;
            let pulseRate = 0;
            let systolicRate = 0;
            let diastolicRate = 0;
            
            do {
                //  Start Day Processing
                let dateOfReading = new Date(data[i].readingTime).toLocaleDateString();
                let timeOfRreading = new Date(data[i].readingTime).toLocaleTimeString('en-US');
                let readingName = data[i].name;
                let deviceReading = data[i].reading;

                //  Handle Reading
                if (readingName === 'BloodGlucose'){
                    //  Add to recent Vitals List
                    // @ts-ignore
                    var vitalReading = {
                    // @ts-ignore
                    "date" : dateOfReading,
                    // @ts-ignore
                    "time" : timeOfRreading,
                    "reading" : deviceReading,
                    "heartrate" : '',
                    "bloodpressure" : '',
                    "glucose" : deviceReading
                    }

                    //  Add to review
                    _recentVitals.push(vitalReading)

                } else if (readingName === 'Systolic'){
                    foundSystolic = true;
                    systolicRate = deviceReading
                } else if (readingName === 'Diastolic'){
                    foundDiastolicRate = true;
                    diastolicRate = deviceReading
                } else if (readingName === 'PulseRate'){
                    foundPulseRate = true;
                    pulseRate = deviceReading
                }

                //  Found all the BP values, save and move on
                if (foundPulseRate === true && 
                        foundSystolic === true && 
                        foundDiastolicRate === true){
                    // @ts-ignore
                    var vitalReading = {
                        // @ts-ignore
                        "date" : dateOfReading,
                        // @ts-ignore
                        "time" : timeOfRreading,
                        "reading" : deviceReading,
                        "heartrate" : pulseRate,
                        "bloodpressure" : systolicRate + '/' + diastolicRate,
                        "glucose" : ''
                    }

                    //  Add to review
                    _recentVitals.push(vitalReading)

                    foundSystolic = false;
                    foundDiastolicRate = false;
                    foundPulseRate = false;
                }
                
                //  Update counter  
                i = i + 1;
            
                //  Check for the end of the specs
                if (i === data.length ){
                    continueDateProcessing = false;
                }
            } while (continueDateProcessing);
            
            //  Loop through monitor reading
            for (let iLp = 0; iLp < data.length; iLp++) {
                // @ts-ignore
                let monitor = data[iLp];
                monitor.readingTime = new Date(monitor.readingTime).toLocaleDateString();
                monitor.reading = parseInt(monitor.reading)
                // @ts-ignore
                _convertedVitalsList.push(monitor);
            }

            // Set Vitals
            // @ts-ignore
            setPatientVitals(_convertedVitalsList.sort(sortFunctionAsc));

            setRecentPatientVitals(_recentVitals);

            if (data != null) {
                for (let iLp=0; iLp < data.length; iLp++){
                    let monitor = data[iLp];
                    monitor.readingTime = convertUTCDateToLocalDate(new Date(monitor.readingTime)).toLocaleString()
                   
                    // @ts-ignore
                    _convertedVitalsList.push(monitor)
                }
            }

            setPatientVitals(_convertedVitalsList);
        } catch (err) {
            console.log("ERROR " + err);
        }
    }

    // @ts-ignore
    function sortFunctionDesc(a,b){  
        var dateA = new Date(b.readingTime).getTime();
        var dateB = new Date(a.readingTime).getTime();
        return dateA > dateB ? 1 : -1;  
    }; 
  
    // @ts-ignore
    function sortFunctionAsc(a,b){  
        var dateA = new Date(a.readingTime).getTime();
        var dateB = new Date(b.readingTime).getTime();
        return dateA > dateB ? 1 : -1;  
    }; 

    /**
    * @name convertUTCDateToLocalDate
    * @description Converts Date for the back-end
    * @param  
    * @returns {json} 
    */
    function convertUTCDateToLocalDate(date: Date) {
        var newDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
        return newDate;   
    }

    /**
    * @name renderVitalGridReadings
    * @description Until back-end names are updated
    * @param  
    * @returns {json} 
    */
    function renderVitalGridReadings(e: any) {
        let _readName = e.data.readingName;
        let _readingTitle = e.data.readingName;
        
        switch(_readName) {
            case 'BloodGlucose':
            _readingTitle = "Blood Glucose";
            break;
            case 'PulseRate':
            _readingTitle = "Pulse Rate";
            break;
            case 'Systolic':
            _readingTitle = "Systolic Blood Pressure";
            break;
            case 'Diastolic':
            _readingTitle = "Diastolic Blood Pressure";
            break;
            default:
            _readingTitle = _readName;
        }
        
        return (
            <>
            <label className="titlesVitals">{_readingTitle}</label> <br />{" "}
            <label className="subTitles">{e.data.dataUnits}</label>
            </>
        );
    }

      
    //  Render Low Vital Thresholds
    function renderLowValues(e: any) {
        let low = e.data.dataValues.filter(
        (a: { thresholdValueType: string }) =>
            a.thresholdValueType === "LESS_THAN"
        );
    
        return <>&#8804; {low[0].thresholdValue}</>;
    }
    
    //  Render High Vital Thresholds
    function renderHighValues(e: any) {
        let high = e.data.dataValues.filter(
        (a: { thresholdValueType: string }) =>
            a.thresholdValueType === "GREATER_THAN"
        );
        return <>&gt;{high[0].thresholdValue}</>;
    }

    return (
        <Container fluid>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => props.switchView("patientpanel")}>Patients</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => props.switchView("patientInformation")}>{firstName} {lastName}</Breadcrumb.Item>
                <Breadcrumb.Item active>Vitals History</Breadcrumb.Item>
            </Breadcrumb>
                <Row>
                    <Col lg={7}>
                        <div className="page-panel">

                                <h2>
                                    Individual Vitals Readings
                                </h2>
                                <DataGrid
                                    id="grid-container"
                                    width={"100%"}
                                    showColumnLines={false}
                                    showRowLines={false}
                                    showBorders={false}
                                    focusStateEnabled={true}
                                    dataSource={recentPatientVitals}
                                    allowColumnResizing={true}
                                    showColumnHeaders={true}
                                    onCellPrepared={formatMostRecentVitals}
                                    onCellClick={gridCellClicked}
                                    noDataText="No Vitals collected for this patient">
                                <Paging defaultPageSize={20} defaultPageIndex={0} />
                                <Selection mode="single" />
                                <Column dataField="date" dataType={"date"} width={"20%"} caption="Date" />
                                <Column dataField="time" width={"20%"} caption="Time"/>
                                <Column dataField="heartrate" caption="heartrate" width={"20%"} headerCellRender={renderHeartRateBPM} alignment={"center"}/>
                                <Column dataField="bloodpressure" caption="bloodpressure" width={"20%"} headerCellRender={renderBloodPressure} alignment={"center"}/>
                                <Column dataField="glucose" caption="glucose" width={"20%"} headerCellRender={renderBloodGlucose} alignment={"center"}/>
                                </DataGrid>
                            </div>
                    </Col>
                    <Col lg={5}>
                        <div className="page-panel">
                            <div className="panel-header">
                              <h2>Vital Data Thresholds</h2>
                              {props.permissions.checkFunctionPermission("PATIENT_PANEL", "THRESHOLDS", "READ")  ?
                              <Anchor onClick={() => props.switchView("patientvitalthresholds") }>
                                Customize
                              </Anchor>
                              : 
                              <></>
                              }   
                            </div>
                                <Form.Label className="font-face-activate-caption">
                                    {thresholdType}
                                </Form.Label>
                                <DataGrid
                                id="grid-container"
                                showColumnLines={false}
                                showRowLines={false}
                                dataSource={monitorReadingThresholds}
                                showBorders={false}
                                focusStateEnabled={true}
                                allowColumnResizing={true}
                                showColumnHeaders={true}
                                noDataText="No Vital Data Thresholds stored">
                                <Paging defaultPageSize={4} defaultPageIndex={0} />
                                <Selection mode="single" />
                                <Column dataField="readingName" cellRender={renderVitalGridReadings} caption="Vital Reading" width={"55%"} defaultSortOrder="asc" />
                                <Column dataField="dataType" width={"15%"} caption="Low" cellRender={renderLowValues}/> 
                                <Column dataField="dataUnits" width={"15%"} caption="High" cellRender={renderHighValues} />
                                </DataGrid>
                      </div>
                    </Col>
                  </Row>
        </Container>
    )
}

export default PatientInformationVitalsHistory
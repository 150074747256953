import { isVisible } from '@testing-library/user-event/dist/utils';
import React from 'react';
import { Container, Row, Col, Card, ListGroup, Breadcrumb } from 'react-bootstrap';
import PermissionService from '../../Services/PermissionService';


function AdminConfiguration(props: {switchView : (newView : string) => void, permissions : PermissionService}) {

    return (

            <Container fluid>
                <Breadcrumb>
                        <Breadcrumb.Item active>Configuration</Breadcrumb.Item>
                </Breadcrumb>

                <Row>
                    {props.permissions.checkFunctionPermission("CONFIGURATION", "STRUCTURE", "READ") ?
                    <Col md={6} lg={4}>
                        <Card className="h-100">
                            <Card.Body>
                                <Card.Title>Structure</Card.Title>
                                <ListGroup className="list-group-flush align-top">
                                    <ListGroup.Item>
                                        <Card.Link
                                            onClick={() => props.switchView("adminOrganization")}>
                                                Organizations
                                        </Card.Link>
                                        <p>Add and modify organizations and sub-sites.</p>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Card.Link 
                                            onClick={() => props.switchView("teamlet")}>ACTIVATE Teamlets</Card.Link>
                                        <p>Add and modify groups of health care workers.</p>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                    :
                    <></>
                    }
                    {props.permissions.checkFunctionPermission("CONFIGURATION","PEOPLE", "READ") ?
                    <Col md={6} lg={4}>
                        <Card className="h-100">
                            <Card.Body>
                                <Card.Title>People</Card.Title>
                                <ListGroup className="list-group-flush">
                                    {props.permissions.checkSubfunctionPermission("CONFIGURATION", "PEOPLE", "ENROLLMENT_INVITATIONS", "READ") ?
                                    <ListGroup.Item>
                                        <Card.Link
                                            onClick={() => props.switchView("enrollmentInvitations")}>
                                                Enrollment Invitation
                                        </Card.Link>
                                        <p>Send enrollment invitations to register users.</p>
                                    </ListGroup.Item>
                                    : <></>}
                                    {props.permissions.checkSubfunctionPermission("CONFIGURATION", "PEOPLE", "PERMISSIONS", "READ") ?
                                        <ListGroup.Item>
                                            <Card.Link    
                                                className="font-face-activate-label"
                                                onClick={() => props.switchView("adminPermissions")}>
                                                    Permissions
                                            </Card.Link>
                                            <p>Configure permissions for roles.</p>
                                        </ListGroup.Item>
                                    : <></>}
                                    {props.permissions.checkSubfunctionPermission("CONFIGURATION", "PEOPLE", "USERS", "READ") ?
                                    <ListGroup.Item>
                                        <Card.Link 
                                            onClick={() => props.switchView("users")}>Users</Card.Link>
                                        <p>Manage registered user accounts.</p>
                                    </ListGroup.Item>
                                    : <></>}
                                    {props.permissions.checkSubfunctionPermission("CONFIGURATION", "PEOPLE", "REGISTRATION", "READ") ?
                                    <ListGroup.Item>
                                        <Card.Link
                                            onClick={() => props.switchView('patientRegistration')}>
                                                Patient Registration
                                        </Card.Link>
                                        <p>Send mobile app registration invitation to patient users.</p>
                                    </ListGroup.Item>
                                    : <></>}
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                        :
                        <></>
                    }
                    <Col md={6} lg={4}>
                        {props.permissions.checkFunctionPermission("CONFIGURATION","CONTENT", "READ") ?
                            <Card className="h-100">
                                <Card.Body>
                                    <Card.Title>Content</Card.Title>
                                    <ListGroup className="list-group-flush">
                                    {props.permissions.checkFunctionPermission("CONTENT","ASSESSSMENTS", "READ") ?
                                        <ListGroup.Item>
                                            <Card.Link style={{ textDecoration:"none"}}>Assessments</Card.Link>
                                            <p>Add and manage assessment templates.</p>
                                        </ListGroup.Item>
                                    :
                                    <></>
                                    }
                                    {props.permissions.checkSubfunctionPermission("CONFIGURATION", "CONTENT", "DIGITAL_HEALTH_PATHWAYS", "READ") ?
                                        <ListGroup.Item>
                                            <Card.Link style={{ textDecoration:"none"}}>Digital Health Pathways</Card.Link>
                                            <p>Add and manage digital health pathway templates.</p>
                                        </ListGroup.Item>
                                    :
                                    <></>
                                    }
                                    {props.permissions.checkSubfunctionPermission("CONFIGURATION", "CONTENT", "RPM_DATA", "READ") ?
                                        <ListGroup.Item>
                                            <Card.Link style={{ textDecoration:"none"}}>RPM Data</Card.Link>
                                            <p>Configure how RPM data is managed.</p>
                                        </ListGroup.Item>
                                    :
                                    <></>
                                    }
                                    {props.permissions.checkSubfunctionPermission("CONFIGURATION", "CONTENT", "RPM_DEVICES", "READ") ?
                                        <ListGroup.Item>
                                            <Card.Link 

                                                onClick={() => props.switchView("rpmdevices")}>RPM Device Models</Card.Link>
                                            <p>Manage RPM device types and settings.</p>
                                        </ListGroup.Item>
                                    :
                                    <></>}
                                    {props.permissions.checkSubfunctionPermission("CONFIGURATION", "CONTENT", "TO_DO_ITEMS", "READ") ?
                                        <ListGroup.Item>
                                            <Card.Link style={{ textDecoration:"none"}}>To Do Items</Card.Link>
                                            <p>Configure to do item types and settings.</p>
                                        </ListGroup.Item>
                                    : 
                                    <></>
                                    }
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                                : 
                                <></>
                            }  
                    </Col>
                </Row>
                <Row>
                    {props.permissions.checkFunctionPermission("CONFIGURATION", "DEVELOPMENT", "READ") ?
                    <Col>
                        <Card className="h-100">
                            <Card.Body>
                                <Card.Title className="font-face-activate-main-text-bold">Development</Card.Title>
                                <ListGroup className="list-group-flush">
                                {props.permissions.checkSubfunctionPermission("CONFIGURATION", "DEVELOPMENT", "ACCOUNT_SETTINGS", "READ") ?
                                    <ListGroup.Item>
                                        <Card.Link 
                                            style={{ textDecoration:"none"}}>Account Settings</Card.Link>
                                        <p>Configure default user accounts and notifications.</p>
                                    </ListGroup.Item>
                                : <></>}
                                {props.permissions.checkSubfunctionPermission("CONFIGURATION", "DEVELOPMENT", "LOGGING_AND_ERRORS", "READ") ?
                                    <ListGroup.Item>
                                        <Card.Link style={{ textDecoration:"none"}}>Logging and Errors</Card.Link>
                                        <p>Configure the display of activity logging and error messages.</p>
                                    </ListGroup.Item>
                                : <></>}
                                {props.permissions.checkSubfunctionPermission("CONFIGURATION", "DEVELOPMENT", "MAINTENANCE_MODE", "READ") ?
                                    <ListGroup.Item>
                                        <Card.Link style={{ textDecoration:"none"}}>Maintenance Mode</Card.Link>
                                        <p>Take ACTIVATE offline for updates and maintenance.</p>
                                    </ListGroup.Item>
                                : <></>}
                                {props.permissions.checkSubfunctionPermission("CONFIGURATION", "DEVELOPMENT", "NOTICES", "READ") ?
                                    <ListGroup.Item>
                                        <Card.Link style={{ textDecoration:"none"}}>Notices</Card.Link>
                                        <br/>
                                        <p>Sitewide notices to users.</p>
                                    </ListGroup.Item>
                                : <></>}
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                    :
                        <></>
                    }
                    {props.permissions.checkFunctionPermission("CONFIGURATION", "COMMUNICATIONS", "READ") ?
                    <Col>
                        <Card className="h-100">
                            <Card.Body>
                                <Card.Title className="font-face-activate-main-text-bold">Communications</Card.Title>
                                <ListGroup className="list-group-flush">
                                {props.permissions.checkSubfunctionPermission("CONFIGURATION", "COMMUNICATIONS", "EMAIL_TEMPLATES", "READ") ?
                                    <ListGroup.Item>
                                        <Card.Link 
                                            style={{ textDecoration:"none"}}>Email Templates</Card.Link>
                                        <p>Add and configure system email templates.</p>
                                    </ListGroup.Item>
                                : <></>}
                                {props.permissions.checkSubfunctionPermission("CONFIGURATION", "COMMUNICATIONS", "MESSAGING", "READ") ?
                                    <ListGroup.Item>
                                        <Card.Link  
                                            style={{ textDecoration:"none"}}>Messaging</Card.Link>
                                        <p>Configure messaging settings.</p>
                                    </ListGroup.Item>
                                : <></>}
                                {props.permissions.checkSubfunctionPermission("CONFIGURATION", "COMMUNICATIONS", "SCHEDULING", "READ") ?
                                    <ListGroup.Item>
                                        <Card.Link 
                                            style={{ textDecoration:"none"}}>Scheduling</Card.Link>
                                        <p>Manage settings for events and appointments.</p>
                                    </ListGroup.Item>
                                : <></>}
                                {props.permissions.checkSubfunctionPermission("CONFIGURATION", "COMMUNICATIONS", "VIDEO_MEETINGS", "READ") ?
                                    <ListGroup.Item>
                                        <Card.Link 
                                            style={{ textDecoration:"none"}}>Video Meetings</Card.Link>
                                        <p>Configure video chat settings.</p>
                                    </ListGroup.Item>
                                : <></>}
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                    :
                        <></>
                    }
                </Row>
            </Container>
    );
}

export default AdminConfiguration;
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import './variables.css';
import './App.css';

import 'devextreme/dist/css/dx.light.css';
import LoginHook from './Components/LoginPageHook';
import Patient from './Components/Patient/PatientContainer.tsx';
import Admin from './Components/Administrator/AdminContainer';
import HealthProvider from './Components/HealthProvider/HealthProviderContainer';
import Register from './Components/Registration/Registration';
import LockedAccount from './Components/Registration/LockedAccount';
import ResetPassword from './Components/Registration/ResetPassword';
import ForgotUsername from './Components/Registration/ForgotUsername';
import AboutPage from './Components/AboutPage';
import HelpPage from './Components/HelpPage';
import 'devextreme/dist/css/dx.light.css';

class App extends Component {
  state = {
    isAuth: false,
    userRole: 'unknown'
  };

  componentDidMount() {
    window.addEventListener('popstate', e => {
      // Nope, go back to your page
      sessionStorage.removeItem('authenticated');
      sessionStorage.removeItem('ACTIVATEtoken');
     //this.props.history.go(1);
    });
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<LoginHook/>}/>
          <Route
            path='/Administrator'
            element={
              <PrivateRoute>
                <Admin/>
              </PrivateRoute>
            }>
          </Route>
          <Route
            path="/Patient"
            element={
            <PrivateRoute>
              <Patient/>
            </PrivateRoute>
            }/>
          <Route
            path="/HealthCareProvider"
            element={
            <PrivateRoute>
              <HealthProvider/>
            </PrivateRoute>
            }/>
          <Route
            path="/HealthCoach"
            element={
             <PrivateRoute>
              <HealthProvider/>
             </PrivateRoute> 
            }/>
          <Route
            path="/Provider"
            element={
             <PrivateRoute>
              <HealthProvider/>
             </PrivateRoute> 
            }/>
          <Route
            path="/HealthStaff"
            element={
             <PrivateRoute>
              <HealthProvider/>
             </PrivateRoute> 
            }/>
          <Route
            path="/DigitalHealthNavigator"
            element={
             <PrivateRoute>
              <HealthProvider/>
             </PrivateRoute> 
            }/>
          <Route
            path="/OrganizationAdministrator"
            element={
             <PrivateRoute>
              <Admin/>
             </PrivateRoute> 
            }/>
          <Route
            path="/SuperUser"
            element={
             <PrivateRoute>
              <Admin/>
             </PrivateRoute> 
            }/>
          <Route
            path="/PatientDelegate"
            element={
             <PrivateRoute>
              <HealthProvider/>
             </PrivateRoute> 
            }/>
          <Route path="/staffregistration" element={<Register/>}/>
          <Route path="/FailedLogin" element={<LockedAccount/>}/>
          <Route path="/passwordreset" element={<ResetPassword/>}/>
          <Route path="/forgotusername" element={<ForgotUsername/>}/>
          <Route
            path="*"
            element={<Navigate to="/login" replace />}
          />
          <Route path="/about" element={<AboutPage/>}/>
          <Route path="/help" element={<HelpPage/>}/>
        </Routes>
      </BrowserRouter>
    );
  }

}

export default App;


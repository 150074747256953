import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Table, Row, Col, Form, Breadcrumb, Card } from 'react-bootstrap';
import { DataGrid, MasterDetail, GroupPanel, SearchPanel, Selection, Column, Scrolling, Paging} from 'devextreme-react/data-grid';
import './MyProfile.css';
const config = require('../../config.json');

function MyProfile(props: {switchView: Function}) {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [prefix, setPrefix] = useState("");
    const [suffix, setSuffix] = useState("");
    const [preferredName, setPreferredName] = useState("");
    const [roleDisplayList, setRoleDisplayList] = useState<any[]>([]);
    const [roleName, setRoleName] = useState<string | null>("");
    const [roleValue, setRoleValue] = useState<string | null>("");
    const [roleID, setRoleID] = useState<string | null>("");
    const [primaryOrgDisplayList, setPrimaryOrgDisplayList] = useState<any[]>([]);
    const [primaryOrganization, setPrimaryOrganization] = useState("");
    const [primaryOrganizationID, setPrimaryOrganizationID] = useState<string | null>("");
    const [emailAddress, setEmailAddress] = useState("");
    const [cellPhone, setCellPhone] = useState("");
    const [landlinePhone, setLandlinePhone] = useState("");
    const [expireDate, setExpireDate] = useState("");
    const [badSubmissionError, setBadSubmissionError] = useState("");
    const [validated, setValidate] = useState(false);
    const [prefixList, setPrefixList] = useState<any[]>([]);
    const [suffixList, setSuffixList] = useState<any[]>([]);
    const [DOB, setDOB] = useState('');
    const [MRN, setMRN] = useState('');
    const [birthGender, setBirthGender]  = useState('');
    const [genderIdentity, setGenderIdentity]  = useState('');
    const [preferredLanguage, setPreferredLanguage]  = useState('');
    const [secondaryLanguage, setSecondaryLanguage]  = useState('');
    const [maritalStatus, setMaritalStatus]  = useState('');
    const [street, setStreet]  = useState('');
    const [cityName, setCityName]  = useState('');
    const [stateName, setStateName]  = useState('');
    const [zipcode, setZipcode]  = useState('');
    const [preferredContact, setPreferredContact] = useState('');

    useEffect(() => {
       loadProfileInfo();
    }, [])

    function loadProfileInfo(){

        setFirstName("Jim");
        setLastName("Patient");
    }

    return(
    
      <div>
        <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => props.switchView("MyProfile")}>My Profile</Breadcrumb.Item>
                    <Breadcrumb.Item active>{firstName} {lastName}</Breadcrumb.Item>
                </Breadcrumb>
            <Form>
            <Row>
                <Col lg={7}>
                    <div className="page-panel">
                        <div className="panel-header">
                            <h2>Patient Information</h2>
                        </div>
                        <Row style={{marginBottom: "0px"}}>
                            <Col md={6} style={{backgroundColor:"white", marginLeft: "20px"}}>
                                <Card style={{border: "0"}}>
                                    <Card.Header style={{backgroundColor:"white"}}>
                                        <Form.Label className="font-face-activate-heading3">About</Form.Label>
                                    </Card.Header>
                                    <Form.Group as={Row}>
                                        <Form.Label className="font-face-activate-caption" column sm={6}>
                                            First Name
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" column sm={6}>
                                                {firstName}
                                            </Form.Label>
                                        </Col>
                                        <Form.Label className="font-face-activate-caption" column sm={6}>
                                            Last Name
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" column sm={6}>
                                                {lastName}
                                            </Form.Label>
                                        </Col>
                                        <Form.Label className="font-face-activate-caption" column sm={6}>
                                            Preferred Name
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" column sm={6}>
                                                {preferredName}
                                            </Form.Label>
                                        </Col>
                                        <Form.Label className="font-face-activate-caption" column sm={6}>
                                            Prefix
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" column sm={6}>
                                                {prefix}
                                            </Form.Label>
                                        </Col>
                                        <Form.Label className="font-face-activate-caption" column sm={6}>
                                            Suffix
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" column sm={6}>
                                                {suffix}
                                            </Form.Label>
                                        </Col>
                                    </Form.Group>
                                </Card>
                            </Col>
                            <Col md={5}>
                                <Card style={{ border: "0" }}>
                                    <Card.Header style={{backgroundColor:"white"}}><Form.Label className="font-face-activate-heading3">Contact</Form.Label></Card.Header>
                                    <Form.Group as={Row} className="mb-3" style={{ marginLeft : "5px"}}>
                                        <Form.Label className="font-face-activate-caption" column sm={6}>
                                            Email Address
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" style={{marginTop:"5px"}}>
                                                {emailAddress}
                                            </Form.Label>
                                        </Col>
                                        <Form.Label className="font-face-activate-caption" column sm={6}>
                                            Cell Phone
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" style={{marginTop:"5px"}}>
                                                {cellPhone}
                                            </Form.Label>
                                        </Col>
                                        <Form.Label className="font-face-activate-caption" column sm={6}>
                                            Landline Phone
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" style={{marginTop:"5px"}}>
                                                {landlinePhone}
                                            </Form.Label>
                                        </Col>
                                        <Form.Label className="font-face-activate-caption" column sm={5}>
                                            Address
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" style={{marginTop:"5px"}}>
                                                {street}<br/>{cityName}, {stateName} {zipcode}
                                            </Form.Label>
                                        </Col>
                                        <Form.Label className="font-face-activate-caption" column sm={5}>
                                            Preferred Contact
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" style={{marginTop:"5px"}}>
                                                {preferredContact}
                                            </Form.Label>
                                        </Col>
                                    </Form.Group>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} style={{backgroundColor:"white", marginLeft: "20px"}}>
                                <Card style={{border: "0"}}>
                                    <Card.Header style={{backgroundColor:"white"}}>
                                        <Form.Label className="font-face-activate-heading3">Identifiers</Form.Label>
                                    </Card.Header>
                                    <Form.Group as={Row}>
                                        <Form.Label className="font-face-activate-caption" column sm={6}>
                                            MRN
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" column sm={6}>
                                                {MRN}
                                            </Form.Label>
                                        </Col>
                                        <Form.Label className="font-face-activate-caption" column sm={6}>
                                            ACTIVATE Username
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" column sm={6}>
                                                NYA
                                            </Form.Label>
                                        </Col>
                                        <Form.Label className="font-face-activate-caption" column sm={6}>
                                            ACTIVATE Patient ID
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" column sm={6}>
                                                NYA
                                            </Form.Label>
                                        </Col>
                                    </Form.Group>
                                </Card>
                            </Col>
                            <Col md={5}>
                                <Card style={{ border: "0" }}>
                                    <Card.Header style={{backgroundColor:"white"}}><Form.Label className="font-face-activate-heading3"><br/></Form.Label></Card.Header>
                                    <Form.Group as={Row} className="mb-3" style={{ marginLeft : "5px"}}>
                                        <Form.Label className="font-face-activate-caption" column sm={5}>
                                            Date Of Birth
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" style={{marginTop:"5px"}}>
                                                {emailAddress}
                                            </Form.Label>
                                        </Col>
                                        <Form.Label className="font-face-activate-caption" column sm={5}>
                                            Sex at Birth
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" style={{marginTop:"5px"}}>
                                                {emailAddress}
                                            </Form.Label>
                                        </Col>
                                        <Form.Label className="font-face-activate-caption" column sm={5}>
                                            Preferred Pronouns
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" style={{marginTop:"5px"}}>
                                                {emailAddress}
                                            </Form.Label>
                                        </Col>
                                        <Form.Label className="font-face-activate-caption" column sm={5}>
                                            Gender Identity
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" style={{marginTop:"5px"}}>
                                                {emailAddress}
                                            </Form.Label>
                                        </Col>
                                    </Form.Group>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: "30px"}}>
                            <Col md={6} style={{backgroundColor:"white", marginLeft: "20px"}}>
                                <Card style={{border: "0"}}>
                                    <Card.Header style={{backgroundColor:"white"}}>
                                        <Form.Label className="font-face-activate-heading3">Additional information</Form.Label>
                                    </Card.Header>
                                    <Form.Group as={Row}>
                                        <Form.Label className="font-face-activate-caption" column sm={6}>
                                            Primary Language
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" column sm={6}>
                                                {preferredLanguage.length ===0 ? 'Not Provided' : preferredLanguage}
                                            </Form.Label>
                                        </Col>
                                        <Form.Label className="font-face-activate-caption" column sm={6}>
                                            Secondary Language
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" column sm={6}>
                                                {secondaryLanguage.length ===0 ? 'Not Provided' : secondaryLanguage}
                                            </Form.Label>
                                        </Col>
                                        <Form.Label className="font-face-activate-caption" column sm={6}>
                                            Marital Status
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" column sm={6}>
                                                {maritalStatus.length === 0 ? 'Not Provided': maritalStatus}
                                            </Form.Label>
                                        </Col>
                                    </Form.Group>
                                </Card>
                            </Col>
                            <Col md={5}>
                                <Card style={{ border: "0" }}>
                                    <Card.Header style={{backgroundColor:"white"}}><Form.Label className="font-face-activate-heading3"><br/></Form.Label></Card.Header>
                                    <Form.Group as={Row} className="mb-3" style={{ marginLeft : "5px"}}>
                                        <Form.Label className="font-face-activate-caption" column sm={5}>
                                            Race
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" style={{marginTop:"5px"}}>
                                                {emailAddress}
                                            </Form.Label>
                                        </Col>
                                        <Form.Label className="font-face-activate-caption" column sm={5}>
                                            Ethnicity
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label className="font-face-activate-secondary-text" style={{marginTop:"5px"}}>
                                                {cellPhone}
                                            </Form.Label>
                                        </Col>
                                    </Form.Group>
                                </Card>
                            </Col>
                        </Row>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <Form.Label className="font-face-activate-heading2" style={{marginLeft: "20px", marginTop: "30px", marginBottom: "30px"}}>ACTIVATE Team</Form.Label>
                        <Card id="SiteAddressCard" style={{ textAlign: "left"}}>
                            <DataGrid id="grid-container" width={"100%"}
                                showColumnLines={false} showRowLines={false}
                                showBorders={false}
                                focusStateEnabled={true}
                                allowColumnResizing={true}
                                noDataText='No ACTIVATE Teams have been loaded'>
                                    <Paging defaultPageSize={2} defaultPageIndex={0} />
                                    <Selection mode="single" />
                                    <Column dataField="name" caption="Organization Name" width={'70%'} defaultSortOrder="asc"/>
                                    <Column dataField="sitecount" width={'15%'} caption="Sites" />
                                    <Column dataField="usercount" width={'15%'} caption="Users" />
                            </DataGrid>
                        </Card>                     
                    </Col>    
                </Row>
            </Form>
        </Container>
    </div>
    )
}

export default MyProfile
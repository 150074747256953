import React, { useEffect, useState, useRef } from 'react';
import PermissionService from '../../../Services/PermissionService';
import { Anchor } from 'react-bootstrap';
import { DataGrid, Column } from 'devextreme-react/data-grid';
import { ModalErrorMessage } from '../MessageBox/ModalErrorMessage';
const config = require("../../../config.json");

//router.post('/getMembersByTeamletID', use(adminController.getMembersByTeamletID));
function TeamletMembers(props:
{
    switchView: (view: string) => void,
    setSelectedTeamletID: (teamletId: string) => void,
    permissions: PermissionService,
    teamletID: string,
    setReturnToView: (view: string) => void,
    isMemberOfTeamLet: boolean
})
{
  const dgMembers = useRef<any>(null);
  const token = sessionStorage.getItem('ACTIVATEtoken');
  const [members, setMembers] = useState<any[]>([]);

  //  Error Modal
  const [showErrorModal, setShowErrorModal] = useState(false);

//  Init
useEffect(() => {
  //  Abort controller to close long fetch calls
  const membersController = new AbortController();
  
  //  Get Members
  const fetchTeamletMembers = async () => {
      try {
        dgMembers.current?.instance.beginCustomLoading();

        await fetch(`${config.activateServer}:${config.activatePORT}/getMembersByTeamletID`, {signal: membersController.signal,
            method: 'POST',
            body: JSON.stringify({ token: token, teamID: props.teamletID}),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(res =>{
          if (!res.ok){
            throw Error('Could not fetch the data for the resource')
          }
          return res.json();
        }).then(teamletMembers =>{
          //@ts-ignore
          setMembers(teamletMembers);
        }).catch(err =>{
          if (err.name === 'AbortError'){
            //console.log('fetch aborted')
          }
        })

        dgMembers.current?.instance.endCustomLoading();
      } catch (err) {
          //  Show error
          <ModalErrorMessage   
              showErrorModal={showErrorModal} 
              errorMessageTitle={"Teamlet Members"} 
              errorMessage={"An error occurred retrieving Teamlet Members"} 
              errorServerMessage={"See console for error message."} 
              errorUserMessage={""}
              messageType="error" 
              setShowErrorModal={setShowErrorModal}  
          />

          //  Stop spinner
          dgMembers.current?.instance.endCustomLoading();
      }
  }

  // call the function
  fetchTeamletMembers().catch(console.error);

  //  Clean-up useEffect
  return () => {
    membersController.abort();
  }
}, [])

/**
* @name renderPatientName
* @description Properly renders the Patient's name for the grid
* @param grid row
* @returns 
*/
function renderPatientName(e:any){
  return (
      <>{e.data.firstName} {e.data.middleName} {e.data.lastName} {e.data.suffix}</>
  )
}

/**
* @name renderRoleName
* @description Properly renders the Patient's role for the grid
* @param grid row
* @returns 
*/
function renderRoleName(e:any){
  return (
      <>{e.data.userRole.name}</>
  )
}

function editTeamMember(){
  props.setReturnToView("dashboard");
  props.setSelectedTeamletID(props.teamletID);
  props.switchView("editTeamlet");
}

function canEditTeamMember(){
  //  Check first, if membership is required
  if (props.permissions.checkPermissionSetting("REQUIRES_MEMBERSHIP", "READ") === true){
    //  Check if member
    if (props.isMemberOfTeamLet){
      return props.permissions.checkFunctionPermission("DASHBOARD", "TEAMLET", "UPDATE");
    } else {
      return false;
    } 
  } else {
    //  No membership required, normal permissions
    return props.permissions.checkFunctionPermission("DASHBOARD", "TEAMLET", "UPDATE");
  }
}

    return (
      <>
        <div className="panel-header">
          <h2>Teamlet Members</h2>
            <Anchor href="#" onClick={() => editTeamMember()} hidden={!canEditTeamMember()} >
                Edit
            </Anchor>
        </div>
        <section>
          <DataGrid id="grid-container"
              ref={dgMembers}
              dataSource={members} 
              showColumnLines={false} 
              showRowLines={true}
              showBorders={false}
              focusStateEnabled={true}
              allowColumnResizing={true} showColumnHeaders={true}
              noDataText='No Members assigned to this Teamlet'>
                <Column dataField="internalID" caption="Id" visible={false} />
                <Column width={'40%'} dataField="lastName" caption="Name" cellRender={renderPatientName} />
                <Column width={'40%'} dataField="userRole.name" caption="Role" cellRender={renderRoleName} />
          </DataGrid>
        </section>
      </>
    )
  }

  export default TeamletMembers
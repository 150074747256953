/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { getTeamletByID } from "../../../Services/TeamletServices";
import { ModalErrorMessage } from '../MessageBox/ModalErrorMessage';
import { LoadPanel } from 'devextreme-react/load-panel';
  
const config = require("../../../config.json");

export default function ModalAddPatientToHuddle(props:
  {
    showPatient: boolean,
    patientName: string,
    patientID: string,
    setShowPatient: Function,
    modalEditType: string,
    teamletID: string,
    teamletTime: string,
    huddleID: string,
    meetingID: string,
    meetingPatientID: string,
    patientCriteria: string,
    notes: string,
    notesAuthor: string,
    taggedBy: string,
    getCurrentMeeting: (meetingID: any) => void,
    currentPatients: any
  }
) {

  const [patientList, setPatientList] = useState([]);
  // Get our current patient list from props
  const [currentPatients, setCurrentPatients] = useState(props.currentPatients);
  const [patientID, setPatientID] = useState('');
  const [patientName, setPatientName] = useState(props.patientName !== undefined && props.patientName !== '' ? props.patientName : "");
  const [teamletName, setTeamletName] = useState('');
  const [reason, setReason] = useState(props.patientCriteria !== undefined && props.patientCriteria !== '' ? props.patientCriteria : "");
  const [patients, setPatients] = useState<any[]>([]);
  const [newMeeting, setNewMeeting] = useState("");

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessageTitle, setErrorMessageTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorServerMessage, setErrorServerMessage] = useState("");
  const [errorUserMessage, setErrorUserMessage] = useState("");
  const [validated, setValidate] = useState(false);

  //  Spinner Icon location
  const position = { of: '#modalForm' }; 

  //  Show/Hide Spinner
  const [showProgress, setShowProgress] = useState(false);
  
  // Get the patients and time from the meetingID
  useEffect(() => {
    if (props.teamletID !== '' && props.currentPatients) {
      loadPatientList(props.currentPatients);
    }
  }, [props.teamletID, props.currentPatients]);

  async function loadPatientList(tempCurrentPatients: any[]) {
    setShowProgress(true);

    const token = sessionStorage.getItem('ACTIVATEtoken');

    const response = await fetch(`${config.activateServer}:${config.activatePORT}/getPatientsByTeamletID`,
      {
        method: "POST",
        body: JSON.stringify({ token: token, teamletID: props.teamletID }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
      }
    );
    try {

      const data = await response.json();
      // console.log('PATIENT DATA ' + JSON.stringify(data));
      setPatients(data);
      const currentDropdownPatients = data;
      
      // Throw an error if there is no one else to add

      if (tempCurrentPatients.length === currentDropdownPatients.length && props.modalEditType === "add") {
        setErrorServerMessage('All assigned patients are currently in the huddle');
        setShowErrorModal(true);
        setErrorMessage(`All available patients are currently assigned to this meeting`);
        props.setShowPatient(false);
      }

      // Filter patients
      for (let j = 0; j < tempCurrentPatients.length; j++) {
        for (let k = 0; k < currentDropdownPatients.length; k++) {
          if (tempCurrentPatients[j].patient === currentDropdownPatients[k].internalID) {
            currentDropdownPatients.splice(k,1);
          }
        }
      }

      // Set the default value
      if (data.length > 0) {
        setPatientID(data[0].internalID);
        setPatientName(`${data[0].firstName} ${data[0].middleName} ${data[0].lastName}`);
      }
      const tempPatientList : any = [];

      for (let j = 0; j < currentDropdownPatients.length; j++) {
        tempPatientList.push(
          <option
            key={j}
            value={currentDropdownPatients[j].internalID}
            data-name={`${currentDropdownPatients[j].firstName} ${currentDropdownPatients[j].middleName} ${currentDropdownPatients[j].lastName}`}
          >
            {`${currentDropdownPatients[j].firstName}${currentDropdownPatients[j].middleName} ${currentDropdownPatients[j].lastName}`}
          </option>
        );
      }
      setCurrentPatients(tempPatientList);
      setPatientList(tempPatientList);
      setShowProgress(false);

    } catch (err) {
      console.log("ERROR " + err);
      setShowProgress(false);
    }

    setShowProgress(false);
  }

  useEffect(() => {
    // Get the teamlet name and details
    const fetchTeamlet: any = async () => await getTeamletByID(props.teamletID).then(data => {
      setTeamletName(data.name);
    });
    fetchTeamlet();

  }, [props.teamletID]);

  /* useEffect(() => {
    if (props.teamletID !== "" && props.teamletID !== undefined) {
      fetchPatients(props.teamletID);

      const fetchTeamlet: any = async () => await getTeamletByID(props.teamletID).then(data => {
        setTeamletName(data.name);
      });
      fetchTeamlet();
    }

    setReason(props.patientCriteria);
    setPatientID(props.patientID);
    // if this is an edit, all the meetings have to be fetched because the health coach can move a patient between meetings
    if ((props.huddleID !== null && props.huddleID !== undefined) && (props.meetingID !== null && props.meetingID !== undefined)) {
      const fetchHuddle: any = async () => await fetchHuddleDetails(props.huddleID).then(data => {
        setMeetingData(data.meetings);
        buildFormattedDateArray(data.meetings);
      }).catch((error: any) => {
        setErrorMessageTitle("Error Loading Huddle Data");
        setErrorMessage(`An error occurred loading huddle data ${JSON.stringify(error)}.`);
        setErrorServerMessage("");
        setErrorUserMessage("Please check your network connection.");
        setShowErrorModal(true);
      });
      fetchHuddle();
    }
  }, [props.teamletID, props.patientID, props.meetingID],); */

  function setPatientData(e: any) {
    e.preventDefault();
    setPatientID(e.target.value);
    const ptIndex = patients.findIndex((i: any) => i.internalID === e.target.value);
    setPatientName(`${patients[ptIndex].firstName} ${patients[ptIndex].middleName} ${patients[ptIndex].lastName}`);
  }

  function addPatientToHuddle() {
    return (<>
      <Form.Label>Patient To Add<span className="error-text"> *</span></Form.Label>
      <Form.Select aria-label="patient dropdown" onChange={(e) => { setPatientData(e); }}>
        {patientList}
      </Form.Select></>);
  }

  function editPatient() {
    return (<h2>Update the scheduled huddle for {props.patientName}.</h2>);
  }
  async function dbAddPatient() {
    const token = sessionStorage.getItem('ACTIVATEtoken');
    let updateMeeting = '';
    if (newMeeting !== undefined && newMeeting !== "" && newMeeting !== props.meetingID) {
      updateMeeting = newMeeting;
    } else { updateMeeting = props.meetingID; }
    await fetch(`${config.activateServer}:${config.activatePORT}/addPatientToMeeting`, {
      method: 'POST',
      body: JSON.stringify({
        token: token,
        "patientName": patientName,
        "criteria": reason,
        "discussed": false,
        "notes": "",
        "patientID": patientID,
        "meetingID": updateMeeting,
        "taggedBy": "",
        "notesAuthor": ""
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      mode: 'cors'
    }).then(async (r: Response) => {

      //  Check for Success
      if (r.ok) {
        try {
          props.getCurrentMeeting(props.meetingID);
          props.setShowPatient(false);
          setValidate(false);
        } catch (err) {
          handleException(r);
        }
      }
      else {
        // Handle non-200s
        console.log("Received HTTP status" + r.status.toString());
        handleException(r);
      }
    });
  }

  async function dbUpdatePatient() {
    // console.log('inside dbUpdatePatient');
    let updateMeeting = '';
    if (newMeeting !== undefined && newMeeting !== "" && newMeeting !== props.meetingID) {
      dbRemovePatient();
      dbAddPatient();
      updateMeeting = newMeeting;
    } else { updateMeeting = props.meetingID; }

    /* console.log(JSON.stringify({
      "internalID": props.meetingPatientID,
      "patientName": patientName,
      "criteria": reason,
      "discussed": false,
      "notes": props.notes,
      "patient": patientID,
      "meeting": updateMeeting,
      "taggedBy": props.taggedBy,
      "notesAuthor": props.notesAuthor
    })); */
    const token = sessionStorage.getItem('ACTIVATEtoken');
    await fetch(`${config.activateServer}:${config.activatePORT}/updateMeetingPatient`, {
      method: 'POST',
      body: JSON.stringify({
        token: token,
        "internalID": props.meetingPatientID,
        "patientName": patientName,
        "criteria": reason,
        "discussed": false,
        "notes": props.notes,
        "patient": props.patientID,
        "meeting": updateMeeting,
        "taggedBy": props.taggedBy,
        "notesAuthor": props.notesAuthor
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      mode: 'cors'
    }).then(async (r: Response) => {

      //  Check for Success
      if (r.ok) {
        try {
          props.setShowPatient(false);
          setValidate(false);
          props.getCurrentMeeting(props.meetingID);
        } catch (err) {
          handleException(r);
        }
      }
      else {
        // Handle non-200s
        console.log("Received HTTP status" + r.status.toString());
        handleException(r);
      }
    });

  }

  async function dbRemovePatient() {
    const token = sessionStorage.getItem('ACTIVATEtoken');

    await fetch(`${config.activateServer}:${config.activatePORT}/removePatientFromHuddle`, {
      method: 'POST',
      body: JSON.stringify({
        token: token,
        internalID: props.meetingPatientID
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      mode: 'cors'
    }).then(response => {
      // console.log("GOT REMOVE PATIENT FROM MEETING REPLY " + JSON.stringify(response));
      if (response.ok) {
        try {
          //
          const tempCurrentPatients = currentPatients;
          tempCurrentPatients.filter((patientInfo : any) => patientInfo.internalID === props.meetingPatientID);
          setCurrentPatients(tempCurrentPatients);
          props.setShowPatient(false);
        } catch (err) {
          console.log("Error: " + err);
        }
      } else {
        //  Error returned from data service, display to user
        response.json().then((data: any) => {
          //  Show Modal
          console.log('Error deleting huddle');
        });
      }
    });

  }

  /**
  * @name handleException
  * @description  Handle non-200 errors
  * @param  
  * @returns 
  */
  async function handleException(_resp: Response) {
    const error = await _resp.text();
    const parsedError = JSON.parse(error);

    const msg = parsedError.message;

    //  Exception
    setShowErrorModal(true);
    setErrorMessageTitle("Error Updating Patient Meeting Data");
    setErrorMessage(`Error: ${error}`);

  }

  const handleSubmit = (event : any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidate(true);
      return;
    } else {
      setValidate(true);
      props.modalEditType === "add" ? dbAddPatient() : dbUpdatePatient();
    }
  };

  return (
    <div>
      <LoadPanel visible={showProgress} message={"Loading..."} showPane={true} position={position} showIndicator={true}></LoadPanel>
      <Modal size="lg" dialogClassName="modal-20w" show={props.showPatient} aria-labelledby="modal-header" id="modalForm">

      <Modal.Header className="people" id="modal-header">
        {props.modalEditType === "add" ? <h2 >Add To Huddle</h2> :
          props.modalEditType === "edit" ? <h2>Edit Patient Huddle</h2> : <span>Error</span>}
      </Modal.Header>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>

      <Modal.Body className="modal-body">
          <Row>
            <Col md={12}>
                <p>{props.modalEditType === "add" ? addPatientToHuddle() : editPatient()}</p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Label>Teamlet</Form.Label>
                <p>{teamletName}</p>
            </Col>
            <Col md={6}>
                <><Form.Label>Scheduled Huddle</Form.Label>
                  <p>{props.teamletTime}</p>
                </>
            </Col>
          </Row>
          <Row>
          <Col md={12}>
            <Form.Group
              className="mb-3"
              controlId="editGroup"
            >
              <Form.Label>{props.modalEditType === "add" ?
                "What is the reason the patient is being added" :
                "What is the reason the patient is being updated"}?
                <span className="error-text"> *</span></Form.Label>
              <Form.Control as="textarea" rows={4}
                required maxLength={255}
                defaultValue={props.patientCriteria}
                onChange={((e) => { setReason(e.target.value); })}
              />
              <Form.Control.Feedback type="invalid">
                Reason is required
              </Form.Control.Feedback>
            </Form.Group>
            </Col>
          </Row>


      </Modal.Body>
      <Modal.Footer>
        <Button
          className='people submit'
          type="submit"
          variant="primary"
          id="submitButton">
          Save
        </Button>
        <Button
          className='people cancel'
          id="cancelButton"
          variant="secondary"
          type="button"
          onClick={() => { props.setShowPatient(false); setValidate(false); }}>
          Cancel
        </Button>

      </Modal.Footer>
    </Form>
    </Modal>
      <ModalErrorMessage
        showErrorModal={showErrorModal}
        errorMessageTitle={"Assigned Patients"}
        errorMessage={errorMessage}
        errorServerMessage={errorServerMessage}
        errorUserMessage={""}
        messageType="error"
        setShowErrorModal={setShowErrorModal}
      />
    </div>);
}
import React, { useEffect, useState } from "react";
import { Button, Modal, Col, Form, Container, Anchor } from "react-bootstrap";
import { Icon } from '@ailibs/feather-react-ts';
import PermissionService from '../../../Services/PermissionService';
import { getHuddleInfo } from '../../../Services/HuddleServices';
import { getTeamletByID } from "../../../Services/TeamletServices";

function HuddleInfo(props: {
  switchView: Function;
  permissions: PermissionService;
  huddleID: string;
  goToEditHuddle: () => void,
  isMemberOfTeamLet: boolean
}) {

  const [huddleName, setHuddleName] = useState("");
  const [huddleDescription, setHuddleDescription] = useState("");
  const [teamletID, setTeamletID] =   useState("");
  const [teamletName, setTeamletName] = useState("");
  const [teamletDescription, setTeamletDescription] = useState("");

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessageTitle, setErrorMessageTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorServerMessage, setErrorServerMessage] = useState("");
  const [errorUserMessage, setErrorUserMessage] = useState("");
  const [huddleAttendees, setHuddleAttendees] = useState("");
  const [isMeetingInThePast, setIsMeetingInThePast] = useState(false);

  //  Init
  useEffect(() => {
    if (props.huddleID !== "" && props.huddleID !== undefined) {
     
      const fetchData: any = async () => await getHuddleInfo(props.huddleID).then(data => {
        setHuddleName(data.name);
        setHuddleDescription(data.description);
        setTeamletID(data.healthcareTeam);
        setIsMeetingInThePast(isDateInThePast(data.meetings[0].meetingTime));
      });
      fetchData();
    }
  }, [props.huddleID]);

  useEffect(()=>{
    if (teamletID !== "" && teamletID !== undefined) {
    const fetchTeamlet: any = async () => await getTeamletByID(teamletID).then(data => {
      setTeamletName(data.name);
      setTeamletDescription(data.description);
      setHuddleAttendees(data.name);
      });
      fetchTeamlet();
    }
  },[teamletID]);

  
function editHuddle(){
  props.goToEditHuddle();
}

/**
 * @name renderIcon
 * @description Shows the feather icon
 * @param
 * @returns
 */
  function renderIcon(iconName: any, category: string) {
    return (
      <div className={`ui-icon ${category}`}>
        <Icon name={iconName} size="24" />
      </div>
    );
  }

  /**
   * @name activateTeamList
   * @description Renders ACTIVATE Team list members
   * @param Patient pathways array
   * @returns Team list HTML
   */
  function activateTeamList(healthcareTeam: any) {
    if (healthcareTeam === null && healthcareTeam === undefined) { return (<>No ACTIVATE Teams found<br /></>) };
    return (<>
      <ul className="team-list">
        <li>
          {renderIcon("users", "people")}
          <p><a className="btn-link" onClick={() => props.switchView("TeamletDetails")}>{healthcareTeam}</a><br />
            {teamletDescription}</p>
        </li>
      </ul>

    </>
    )
  }

/**
 * @name isDateInThePast
 * @description Check if Date is in the past
 * @param string 
 * @returns 
*/
function isDateInThePast(dateToCheck:string) {
  const date1 = new Date(dateToCheck).getTime();
  const date2 = new Date().getTime();

  if (date1 < date2) {
    return true;
  } else if (date1 > date2) {
    return false;
  } else if (date1 === date2) {
    return true;
  } else {
    return false;
  }
}

//  Check if User can edit a huddle
function canEditHuddles(){
  if (isMeetingInThePast){
    return false;
  } else {
    //  Check first, if membership is required
    if (props.permissions.checkPermissionSetting("REQUIRES_MEMBERSHIP", "READ") === true){
      //  Check if member
      if (props.isMemberOfTeamLet){
        return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "HUDDLE_SCHEDULE", "UPDATE")
      } else {
        return false;
      } 
    } else {
      //  No membership required, normal permissions
      return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "HUDDLE_SCHEDULE", "UPDATE")
    }
  }
}
  return (
    <div className="d-flex">
      <Container fluid>
        <Form>
          <Col>
            <div className="panel-header">
              <h2 className="font-face-activate-heading2">{huddleName}</h2>
              <Anchor onClick={() => editHuddle()} hidden={!canEditHuddles()}>Edit</Anchor>
            </div>
            <hr></hr>
            <p>{huddleDescription}</p>
            <h4>Huddle Attendees</h4>
            {activateTeamList(huddleAttendees)}

          </Col>
        </Form>

        <Modal show={showErrorModal}>
          <Modal.Header className="error">
            <h2>{errorMessageTitle}</h2>
          </Modal.Header>
          <Modal.Body>
            {errorMessage}<br />
            <br /><b>{errorServerMessage}</b><br />
            <br />{errorUserMessage}<br />
          </Modal.Body>
          <Modal.Footer>
            <Button className='error submit' onClick={() => setShowErrorModal(false)}>Ok</Button>
          </Modal.Footer>
        </Modal>

      </Container>
    </div>

  )
}

export default HuddleInfo;
import React from 'react';
import { Button, Breadcrumb, Container, Row, Col } from 'react-bootstrap';
import { DataGrid, MasterDetail, GroupPanel, SearchPanel, Selection, Column, Scrolling, Paging} from 'devextreme-react/data-grid';
import {Button as DevButton} from 'devextreme-react/data-grid';

type AdminPermissionsProps = {
    switchView : Function;
}

type AdminPermissionsState = {

}

export default class AdminPermissions extends React.Component<AdminPermissionsProps, AdminPermissionsState> {
    constructor(props: AdminPermissionsProps) {
        super(props);
        this.state = {

        }
    }

    componentDidMount(){
        //TODO Make call to obtain permissions.  Then load them.
    }

    render() {
        return(
            <div container-fluid="true">
                <Row>
                    <Col sm={12} >
                        <Breadcrumb style={{marginTop: "30px", marginLeft: "30px", marginBottom: "30px"}}>
                            <Breadcrumb.Item className="font-face-activate-heading3" style={{color: "#3764D4", cursor: "pointer", textDecoration:"none"}} onClick={() => this.props.switchView("configuration")} active>Configuration</Breadcrumb.Item>
                            <Breadcrumb.Item className="font-face-activate-heading3" style={{color: "black"}} active>Permissions</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Container className="bg-white" fluid>
                    <Row>
                        <br/>
                    </Row>
                    <Row>
                        <Col sm={12} >
                            <DataGrid id="grid-container" showColumnLines={false} showRowLines={false} width={"100%"}
                                keyExpr="internalID"
                                showBorders={true}
                                focusStateEnabled={true}
                                allowColumnResizing={true}
                                noDataText='No ACTIVATE Permissions have been loaded'>
                                    <Scrolling rowRenderingMode='virtual'></Scrolling>
                                    <Paging defaultPageSize={2} defaultPageIndex={0} />
                                    <SearchPanel visible={true} />
                                    <Selection mode="single" />
                                    <Column dataField="Permission" caption="Permission" width={320} defaultSortOrder="asc"/>
                                    <Column dataField="Role" width={250} caption="Role" />
                                    <Column dataField="Organization" width={250} caption="Organization" />
                                    <Column dataField="userCount" width={50} alignment={"center"} caption="Users" />
                                    <Column type="buttons" caption="Action" width={70}>
                                        <DevButton hint="edit" icon="edit" />
                                        <DevButton hint="delete" visible={false} icon="trash" />
                                    </Column>
                            </DataGrid>
                        </Col>
                    </Row>
                    <Row>
                        <br/>
                    </Row>
                </Container>
            </div>
        )
    }
}
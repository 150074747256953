import React from "react";
import { Button, Modal } from 'react-bootstrap';

/**
  * @name ModalMessage
  * @description  First attempt at a Component for Modal messages
  *               Single Ok button to close 
  * @param  
  *         showErrorModal : boolean,     // Shows UI
            errorMessageTitle: string,    //  Title
            errorMessage: string,         //  Message
            errorServerMessage: string,   //  Server error
            errorUserMessage: string,     //  User message
            messageType: string,          //  Type 'people' (blue), 'rpm' (green), 'system' (gray) and 'error' (red) 
            setShowErrorModal: Function   //  callback to close pop-up
    * @returns 
*/
export function ModalErrorMessage({ showErrorModal, errorMessageTitle, errorMessage, errorServerMessage, errorUserMessage, messageType, setShowErrorModal}:
    { showErrorModal : boolean, 
      errorMessageTitle: string, 
      errorMessage: string, 
      errorServerMessage: string,
      errorUserMessage: string,
      messageType: string, 
      setShowErrorModal: Function}
  ): JSX.Element {
  return <Modal show={showErrorModal}>
    <Modal.Header className={messageType}>
        <h2>{errorMessageTitle}</h2>
    </Modal.Header>
    <Modal.Body>
        {errorMessage}<br />
        <br /><b>{errorServerMessage}</b><br />
        <br />{errorUserMessage}<br />
    </Modal.Body>
    <Modal.Footer>
        <Button className={messageType + " submit"}  onClick={() => setShowErrorModal(false)}>Ok</Button>
    </Modal.Footer>
  </Modal>;
}
  
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import './PatientPanel.css';
import PermissionsService from '../../Services/PermissionService';
import { Button, Container, Form, Breadcrumb, Anchor } from 'react-bootstrap';
import DataGrid, { Column, GroupPanel, Pager, Paging, Grouping } from 'devextreme-react/data-grid';
import { ALLPATIENTS, MYPATIENTS, UNENROLLED, UNSPECIFIED, NONEASSIGNED } from '../Shared/constants';
import { Icon } from '@ailibs/feather-react-ts';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CustomStore from 'devextreme/data/custom_store';
import { ModalErrorMessage } from '../Shared/MessageBox/ModalErrorMessage';
import { Autocomplete } from 'devextreme-react';
import DateBox from 'devextreme-react/date-box';

const config = require('../../config.json');

function PatientPanel(props:
    {
        switchView: (newView: string) => void,
        permissions: PermissionsService,
        roleName: string,
        setHuddleID: (huddleID: string) => void;
        setMeetingID: (meetingID: string) => void;
        setSelectedPatientID: (newId: string) => void,
    }) {

    //  Local variables    
    const [patientList, setPatientList] = useState<CustomStore>();
    const [patientLookUp, setPatientLookUp] = useState<any[]>([]);
    const [selectedPathway, setSelectedPathway] = useState<any[]>([]);
    
    const [pathwayOptions, setPathwayOptions] = useState<any[]>([]);
    const [lookupSearchValue, setLookupSearchValue] = useState('');
    
    const [toggleAssignedPatients, setToggleAssignedPatients] = useState(false);
    const [toggleAllPatients, setToggleAllPatients] = useState(true);
    const [toggleUnenrolledPatients, setToggleUnenrolledPatients] = useState(false);
    
    const [pathway, setAssignedPathway] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);

    //  Reference Grid for spinner
    const dgPatients = useRef<any>(null);
    const dbHuddleDate = useRef<any>(DateBox);
    const token = sessionStorage.getItem('ACTIVATEtoken');

    //  Paging variables
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [viewMode, setViewMode] = useState(ALLPATIENTS);
    const [patientSelected, setPatientSelected] = useState('');
    const [sortBy, setSortBy] = useState('lastName');
    const [sortByDirection, setSortByDirection] = useState('');
    const [validationStatus, setValidationStatus] = useState('valid');
    const [huddleDate, setHuddleDate] = useState<string | undefined>('');
    
    //  Store internalIDs for paging
    const [mapState, setMapState] = useState(new Map());
    
    //  Spinner Icon location
    const position = { of: '#autocomplete' };
    const inputAttr = { readOnly: true };
  
    //  Handle Sort change for the Patient grid
    const handlePropertyChange = (e:any) => {
        //  Check for column event
        if (e.name === "columns") {
            const propertyPath = e.fullName.split(".");
            const propertyShortName = propertyPath[0];
            
            // Set Direction
            setSortByDirection(() => e.value);

            // Check column
            switch (propertyShortName) {
                // Name
                case 'columns[0]':
                    setSortBy("lastname");  // confirmed with back-end
                    setPageIndex(0);
                    break;
                //  DOB
                case 'columns[1]':
                    setSortBy("dateofbirth");  // confirmed with back-end
                    setPageIndex(0);
                    break;
                //  MRN
                case 'columns[2]':
                    setSortBy("medicalrecordnumber"); // confirmed with back-end
                    setPageIndex(0);
                    break;
                //  Pathway
                case 'columns[3]':
                    setSortBy("pathway");
                    setPageIndex(0);
                    break;
                //  Next Huddle
                case 'columns[4]':
                    setSortBy("nexthuddle");
                    setPageIndex(0);
                    break;
                //  Status
                case 'columns[5]':
                    setSortBy("status");
                    setPageIndex(0);
                    break;
                //  Last Data
                case 'columns[6]':
                    setSortBy("lastdata");
                    setPageIndex(0);
                    break;
                default:
                    setSortBy("");
                    break;
              } 
        }
    };
    
    //  Get Patient filtered data
    useEffect(() => {
        //  Abort controller to close long fetch calls
        const patientController = new AbortController();

        // call the function
        fetchPatientData(patientController).catch(console.error);
        
        //  Clean-up useEffect
        return () => {
            patientController.abort();
        };
    }, [pageIndex, viewMode, pathway, patientSelected, sortBy, sortByDirection, huddleDate]);


    //  Get Patients
    const fetchPatientData = async (abortSignal: AbortController) => {
        try {
            dgPatients.current?.instance.beginCustomLoading();
        
            const _huddleDate = huddleDate === undefined ? '': huddleDate ;

            const customDataSource = new CustomStore({
                key: 'internalID',
                load(loadOptions) {
                    return fetch(`${config.activateServer}:${config.activatePORT}/getPanelPatientsPaged`, {signal: abortSignal.signal, 
                        method: 'POST',
                        body: JSON.stringify({ token: token, pageIndex: pageIndex, pageSize: pageSize, view: viewMode, pathwayname: pathway, name: patientSelected, sortby: sortBy, sortbydirection: sortByDirection, huddledate: _huddleDate}),
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        },
                        mode: 'cors'
                    })
                    .then((response) => {
                        if (!response.ok) {
                            abortSignal.abort();
                            dgPatients.current?.instance.endCustomLoading();
                            throw Error('Could not fetch the patient');
                        }
                        return response.json();
                    })
                    .then((data) => (
                        {
                            data: data.patients,
                            totalCount: data.totalElements,
                            summary: [],
                            groupCount: []
                        }
                    ))
                    .catch(() => { 
                        abortSignal.abort();
                        dgPatients.current?.instance.endCustomLoading();
                        throw new Error('Data Loading Patients'); 
                    });
                },
            });

            setPatientList(customDataSource);

            dgPatients.current?.instance.endCustomLoading();
        } catch (err) {
            abortSignal.abort();
            dgPatients.current?.instance.endCustomLoading();
            setShowErrorModal(true);
        }
    };

    //  Load unique Pathways
    useEffect(() => {
        getPathways();
    }, []);

    //  Load Patient Lookup
    useEffect(() => {
        //  Abort controller to close long fetch calls
        const controller = new AbortController();
       
        //  Call when 3 or more characters are entered
        if (((lookupSearchValue !== null) && (lookupSearchValue.length >= 3))) {
            setValidationStatus("pending");

            //  Fetch values
            const fetchData = async () => {
                await fetch(`${config.activateServer}:${config.activatePORT}/getPatientListBySearch`, {signal: controller.signal, 
                    method: 'POST',
                    body: JSON.stringify({ token: token, view: viewMode, pathwayname: pathway, pattern: lookupSearchValue}),
                    headers: { 
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    mode : 'cors'
                }).then(response => {
                    //  Check for Success
                    if (!response.ok) {
                        controller.abort();
                        throw Error('Could not fetch the data for the resource');
                    }
                    return response.json();
                }).then(data =>{
                    //  Copy data to store state?
                    const tempData = data;
                    setPatientLookUp(() => tempData);

                    //  Clear map
                    mapState.clear();

                    //  Loop through each Patient capture internalID
                    for (let i = 0; i < data.length; i++) {
                        //  Load the map here
                        mapState.set(data[i].value, data[i].internalID);
                    }

                    setValidationStatus("valid");
                }).catch(err =>{
                    controller.abort();
                });
            };

            // call the function
            fetchData().then(() => {
                //console.log("Loaded....");
            }).catch(console.error);
        } 

        return () => {
            controller.abort();
        };
    }, [lookupSearchValue]);

    /**
     * @name getPathways
     * @description  Load the Pathways for the Pathway dropdown  
     * @param  
     * @returns 
    */
    async function getPathways() {
        try {
            await fetch(`${config.activateServer}:${config.activatePORT}/getPathways`, {
                method: 'POST',
                body: JSON.stringify({ token : token, organizationID: ''  }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode: 'cors'
            })
            .then((response) => response.json())
            .then(data =>{
                // Pathway Options
                const pathwayOptions = data.map((o: any, index: number) => {
                    return (<option key={o.name} value={o.name}>{o.name}</option>);
                });
      
                //  Load Final Pathways
                const finalPathwayOptions = [getBlankPathway()].concat([getDefaultSelectPathway()].concat(pathwayOptions));
                setPathwayOptions(finalPathwayOptions);
                setSelectedPathway(finalPathwayOptions);
            }).catch(err => { 
                throw new Error('Data Loading Pathways'); 
            });
        } catch (err) {
            console.log("ERROR " + err);
        }
    }
    
    /**
     * @name getAssignedPatients
     * @description  My Patients toggle button click event  
     * @param  
     * @returns 
    */
    function getAssignedPatients() {
        //  Set button state
        setToggleAssignedPatients(true);
        setToggleAllPatients(false);
        setToggleUnenrolledPatients(false);
        
        //  Reset other filters
        setLookupSearchValue('');
        setAssignedPathway('');
        setSelectedPathway(pathwayOptions);

        //  Set the view mode - My Patients
        setViewMode(MYPATIENTS);
        setPageIndex(0);
        setPatientSelected('');
        setHuddleDate('');
    }

    /**
     * @name getAllPatients
     * @description  All Patients toggle button click event  
     * @param  
     * @returns 
    */
    function getAllPatients() {
        //  Set button state
        setToggleAllPatients(true);
        setToggleAssignedPatients(false);
        setToggleUnenrolledPatients(false);

        //  Reset other filters
        setLookupSearchValue('');
        setAssignedPathway('');
        setSelectedPathway(pathwayOptions);

        //  Set the view mode - All Patients
        setViewMode(ALLPATIENTS);
        setPageIndex(0);
        setPatientSelected('');
        setHuddleDate('');
    }

    /**
     * @name getUnenrolledPatients
     * @description  Unenrolled toggle button click event 
     * @param  
     * @returns 
    */
    function getUnenrolledPatients() {
        //  Set button state
        setToggleUnenrolledPatients(true);
        setToggleAssignedPatients(false);
        setToggleAllPatients(false);

        //  Reset other filters
        setLookupSearchValue('');
        setAssignedPathway('');
        setSelectedPathway(pathwayOptions);
        setHuddleDate('');

        // Set the view mode - unenrolled
        setViewMode(UNENROLLED);
        setPageIndex(0);
        setPatientSelected('');
        setHuddleDate('');
    }

    /**
     * @name getDefaultSelectPathway
     * @description  Selected Option for Pathway
     * @param  
     * @returns 
    */
    function getDefaultSelectPathway() {
        return (<option key={NONEASSIGNED} value={NONEASSIGNED}>{NONEASSIGNED}</option>);
    }

    /**
     * @name getBlankPathway
     * @description  Selected Blank Option for Pathway
     * @param  
     * @returns 
    */
    function getBlankPathway() {
        return (<option key={''} value={''}></option>);
    }

    /**
     * @name onPathwayChanged
     * @description  Pathway onChange event
     * @param  
     * @returns 
    */
    function onPathwayChanged(e: any) {
        const selectedvalue = e.target.value;
        setAssignedPathway(selectedvalue);
        setPageIndex(0);
    }

    /**
     * @name renderPatientLink
     * @description  Patient Panel Name column render
     * @param  
     * @returns 
    */
    function renderPatientLink(e: any) {
        let enrolled = true;
        let suffix = '';
        if (e.data.suffix !== UNSPECIFIED) suffix = e.data.suffix;

        //  Check if EHR Organization, if so blank birthdate = not enrolled
        if (props.permissions.isEhrOrganization){
            if ((e.data.dateOfBirth === "" || e.data.dateOfBirth === undefined)) {
                enrolled = false;
            }
        } else {
            if (e.data.medicalRecordNumber === "" || (e.data.dateOfBirth === "" || e.data.dateOfBirth === undefined)) {
                enrolled = false;
            }
        }

        //  Check if enrolled
        if (enrolled) {
            return (<a className='plusButton' onClick={viewPatientDetails} id={e.data.internalID}>{e.data.firstName + ' ' + e.data.middleName + ' ' + e.data.lastName + ' ' + suffix}</a>);
        }
        return (
            props.permissions.checkFunctionPermission("PATIENT_PANEL", "ENROLLMENT_INVITATION", "CREATE") === true ?
                <>
                    <OverlayTrigger overlay={(<Tooltip id="notEnrolled">This patient account has not yet been enrolled by the Organization Super User.</Tooltip>)} placement="bottom">
                        <div>
                            <Icon name="alert-octagon" className="feather-red-12" />
                            <a className='plusButton' onClick={(e) => { viewPatientDetails(e); }} id={e.data.internalID}>{e.data.firstName + ' ' + e.data.middleName + ' ' + e.data.lastName + ' ' + suffix}</a>
                        </div>
                    </OverlayTrigger>
                </> :
                <>
                    <OverlayTrigger overlay={(<Tooltip id="notEnrolled">This patient account has not yet been enrolled by the Organization Super User.</Tooltip>)} placement="bottom">
                        <div>
                            <Icon name="alert-octagon" className="feather-red-12 nolink" />{e.data.firstName + ' ' + e.data.middleName + ' ' + e.data.lastName + ' ' + suffix}
                        </div>
                    </OverlayTrigger>
                </>
        );
    }

    /**
     * @name viewPatientDetails
     * @description  View Patient from hyperlink onClick handler
     * @param  
     * @returns 
    */
    function viewPatientDetails(e: any) {
        const patientID = e.currentTarget.id;

        //  Check for found Organization
        if (e != null) {
            props.setSelectedPatientID(patientID);
            props.switchView("patientInformation");
        }
    }

    /**
     * @name pageIndexChange
     * @description  Grid PageIndex change event
     * @param  
     * @returns 
    */
    function pageIndexChange(e:any) {
        setPageIndex(e);
    }

    //  Reset Button event
    const resetFilters = async (event: any) => {
        setAssignedPathway('');
        setSelectedPathway(pathwayOptions);
        setLookupSearchValue('');
        
        setPatientLookUp([]);
        setPatientSelected('');
        setHuddleDate(undefined);

        //  Reset toggle buttons
        setToggleAllPatients(true);
        setToggleAssignedPatients(false);
        setToggleUnenrolledPatients(false);

        //  Reset paging filters
        setViewMode(ALLPATIENTS);
        setSortBy('');
        setPageIndex(0);
    };

    /**
     * @name formatStatusText
     * @description  Formats the status column in the Grid
     * @param  
     * @returns 
    */
    function formatStatusText(user: any) {
        const formatted = user.data.accountStatus.toLowerCase()
            // Replaces any - or _ characters with a space 
            .replace(/[-_]+/g, ' ')
            // Removes any non alphanumeric characters 
            .replace(/[^\w\s]/g, '')
            .replace(/ /g, '');
        const formattedStatus = formatted.substring(0, 1).toUpperCase() + formatted.substring(1);
        return <p style={{ width: "80px" }}>{formattedStatus}</p>;
    }

    /**
     * @name formatLastDataDate
     * @description  Formats the last data date in the Grid
     * @param  
     * @returns 
    */
    function formatLastDataDate(e: any) {
        //  Check for blank date
        if (e.data.latestMonitorReadingTime != null &&
            e.data.latestMonitorReadingTime.length > 0) {
            return <>{new Date(e.data.latestMonitorReadingTime.toString()).toLocaleDateString() + ' ' + new Date(e.data.latestMonitorReadingTime.toString()).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</>;
        } else {
            return <></>;
        }
    }

    //  Display Pathways in Grid
    const displayPathways = (e: any) => {
        return (
            <>
                {e.data.pathwayInstances === null || e.data.pathwayInstances.length === 0  ?
                    <>{NONEASSIGNED}</>
                :
                <>
                    {e.data.pathwayInstances.map((item: any, index: number) => <p key={item.name}>{item.name}</p>)}
                </>}
            </>
        );
    };

    /**
     * @name patientInfo
     * @description  Displays Patient Info for AutoComplete
     * @param  
     * @returns 
    */
    function patientInfo(item:any) {
        return (
            <div>
                <div>{item.value}</div>
            </div>
        );
    }

    /**
     * @name onSelectionChanged
     * @description  AutoComplete Selection Change
     * @param  
     * @returns 
    */
    const onSelectionChanged = useCallback((e:any) => {
        if (e.selectedItem != null) {  
            if (mapState.has(e.selectedItem.value)) {
                const internalID = mapState.get(e.selectedItem.value);
                setPatientSelected(() => internalID);
            }  
        }
    }, []);

    //  Search should be ready/done
    const onContentReady = useCallback((e:any) => {
        setValidationStatus("valid");
    }, []);

    /**
     * @name onSearchValueChanged
     * @description  Ignore backspaces, otherwise search gets call repeatedly
     * @param  
     * @returns 
    */
    const onSearchValueChanged = useCallback((e:any) => {
        //  Check if Search value was cleared
        if (e.value === null) {
            setLookupSearchValue('');
            setPatientSelected('');
            setHuddleDate('');
            setPageIndex(0);
        } else {
            setLookupSearchValue(() => (e.value));
        }
    }, []);

    //  Default event handler to avoid run-time warnings
    const onChangeCheckHandler = (e: any) => {
        // TODO
    };

    /**
     * @name displayNextHuddle
     * @description  Hyperlink to next Huddle
     * @param  
     * @returns 
    */
    function displayNextHuddle(e:any) {
        let nextHuddle = '(none)';

        if (e.data != null) {
            if (e.data.nextHuddleMeetingTime != null && e.data.nextHuddleMeetingTime != '') {
                const nextHuddleID = e.data.nextHuddle;
                const nextHuddleMeetingID = e.data.nextHuddleMeeting ;
                nextHuddle = new Date(e.data.nextHuddleMeetingTime).toDateString() + ' ' + new Date(e.data.nextHuddleMeetingTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toLowerCase();
                return (
                    <><Anchor onClick={(e) => { props.setHuddleID(nextHuddleID); props.setMeetingID(nextHuddleMeetingID); props.switchView("huddledetails"); }} title="Edit Meeting">{nextHuddle}</Anchor></>
                );
            }
            else {
                return (
                    <p>{nextHuddle}</p>
                );
            }
        } else {
            return (
                <p>{nextHuddle}</p>
            );
        }
    }

/**
 * @name onValueDateChanged
 * @description  Date change event
 * @param  
 * @returns 
*/
function onValueDateChanged(e:any) {
    //  Check for Date Value    
    if (e.value != null && e.value !== '') {
        setHuddleDate(() => {return e.value; });
    } 
}

return (
    <Container fluid>
        <Breadcrumb>
            <Breadcrumb.Item active>Patient Panel</Breadcrumb.Item>
        </Breadcrumb>
        <div className="page-panel">
            <div className="panel-function-header">
                <div className="d-flex">
                    <Form.Group className="form-group" controlId="lookup">
                        <Form.Label>Search {lookupSearchValue}</Form.Label>
                        <Autocomplete className="form-group"
                            id="autocomplete"
                            width={350}
                            value={lookupSearchValue}
                            showClearButton={true}
                            dataSource={patientLookUp}
                            //@ts-ignore
                            validationStatus = {validationStatus} 
                            maxItemCount={1000}
                            valueExpr="value"
                            searchMode = "startswith"
                            searchExpr = "lastName"
                            minSearchLength={3}
                            searchTimeout={600}
                            maxLength={20}
                            itemRender={patientInfo}
                            placeholder="Enter Patient's Last Name..."
                            onContentReady={(e) => {onContentReady(e); }}
                            onSelectionChanged={(e) => { onSelectionChanged(e); }}
                            onValueChanged = {(e) => { onSearchValueChanged(e); }}>
                        </Autocomplete>
                    </Form.Group>
                    
                    <Form.Group className="form-group" controlId="status">
                        <Form.Label>Pathway</Form.Label>
                        <Form.Select
                            aria-label="Pathway"
                            name="selectedpathway"
                            value={pathway}
                            onChange={(e) => { onPathwayChanged(e); }}>
                            {selectedPathway}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="form-group" controlId="huddledate">
                        <Form.Label className="nowrap">Huddle Date</Form.Label>
                        <DateBox
                            id="date-box"
                            ref={dbHuddleDate}
                            acceptCustomValue={true}
                            value={huddleDate}
                            inputAttr={inputAttr}
                            onValueChanged={onValueDateChanged}
                            style={{width: "150px"}}
                            type="date"
                        />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>View</Form.Label><br />
                        <div className="btn-group view-toggle" role="group">
                            <input type="radio" className="btn-check" name="btnradio" id="btnAssignedPatients" onClick={() => { getAssignedPatients(); }} checked={toggleAssignedPatients} onChange={onChangeCheckHandler} />
                            <label className="btn btn-outline-primary" htmlFor="btnAssignedPatients">My Patients</label>
                            <input type="radio" className="btn-check" name="btnradio" id="btnPatients" onClick={() => { getAllPatients(); }} checked={toggleAllPatients} onChange={onChangeCheckHandler} />
                            <label className="btn btn-outline-primary" htmlFor="btnPatients">All Patients</label>
                            <input type="radio" className="btn-check" name="btnradio" id="btnUnenrolledPatients" onClick={() => { getUnenrolledPatients(); }} checked={toggleUnenrolledPatients} onChange={onChangeCheckHandler} />
                            <label className="btn btn-outline-primary" htmlFor="btnUnenrolledPatients">Unenrolled</label>
                        </div>
                    </Form.Group>
                    <Form.Group className="reset-control" controlId="reset">
                        <Button
                            onClick={(e) => { resetFilters(e); }}
                            variant="function"
                            className="btn-function-button">
                            Reset
                        </Button>
                    </Form.Group>
                </div>
            </div>

            <DataGrid id="grid-container"
                    ref={dgPatients}
                    focusStateEnabled={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    showBorders={false}
                    onOptionChanged={handlePropertyChange}
                    dataSource={patientList}
                    remoteOperations={true}
                    wordWrapEnabled={true}
                    noDataText='No patients have been loaded'>
                <Paging defaultPageSize={10} onPageIndexChange={pageIndexChange} pageIndex={pageIndex} />
                <GroupPanel visible={false} />
                <Grouping autoExpandAll={false} />
                <Column dataField="name" caption="Name" width={"25%"} cellRender={renderPatientLink} defaultSortOrder={'asc'} />
                <Column dataField="dateOfBirth" caption="DOB" width={"10%"} />
                <Column dataField="medicalRecordNumber" width={"10%"} caption="MRN" />
                <Column dataField="pathways" allowSorting={false} caption="Pathway" width={"30%"} cellRender={displayPathways} />
                <Column width={"10%"} caption="Next Huddle" alignment={'center'} allowSorting={true} cellRender={displayNextHuddle}/>
                <Column dataField="status" allowSorting={false} caption="Status" width={"10%"} cellRender={formatStatusText} />
                <Column dataField="lastData" allowSorting={true} caption="Last Data" dataType={"date"} width={"15%"} cellRender={formatLastDataDate} />
                <Column dataField="internalID" caption="internalID" visible={false} />
                <Pager showNavigationButtons={true} displayMode='full' allowedPageSizes='all' showPageSizeSelector={false}/>
            </DataGrid>
        </div>

        <ModalErrorMessage   
            showErrorModal={showErrorModal} 
            errorMessageTitle={"Filtering Patients"} 
            errorMessage={"An error occurred retrieving Patients"} 
            errorServerMessage={"See console for error message."} 
            errorUserMessage={"Please check the filter criteria you entered, and re-try."}
            messageType="error" 
            setShowErrorModal={setShowErrorModal}  
        />
    </Container>
);
    }
export default PatientPanel;
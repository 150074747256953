import React, { useState, useEffect } from 'react';
import { Container, ButtonGroup, ToggleButton, Breadcrumb } from 'react-bootstrap';
import PermissionService from '../../../Services/PermissionService';
import Tabs from 'devextreme-react/tabs';
import DataReadingsPatientCount from './DataReadingsPatientCount';
import DataReadingsGraph from './DataReadingsGraph';
import DataReadingsGrid from './DataReadingsGrid';

const config = require("../../../config.json");

/* 
 * DataReadingsManager is called from Sidebar and acts as the parent component
 * for DataReadingsGrid and DataReadingsGraph
*/
function DataReadingsManager(props:
    {
        permissions: PermissionService,
        switchView: (view: string) => void
        setSelectedPatientId: (patientID: string) => void;
    }) {

    const [noReadings, setNoReadings] = useState(false);
    const [patients, setPatients] = useState('allpatients');
    const [timeRange, setTimeRange] = useState('week');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const vitalsCountButtons = [
        { name: 'Week', value: 'week' },
        { name: 'Two Weeks', value: 'twoweeks' },
        { name: 'Month', value: 'month' },
    ];
    const [patientReadings, setPatientReadings] = useState();
    //  User Status
    const [showProgress, setShowProgress] = useState(false);

    // this is called initially to get the data and called if the timerange, patients ("allpatients/mypatients"),
    // or the selected tab changes
    useEffect(() => {
        //  Abort controller to close long fetch calls
        const dataReadingsController = new AbortController();
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const thresholds= selectedIndex===0 ? "exceedthresholds" : "noreadings";
        
        setShowProgress(true);
        setPatientReadings(undefined);

        const fetchCounts = async () => {
            try {
                await fetch(`${config.activateServer}:${config.activatePORT}/getDataReadingsSummary`, {signal: dataReadingsController.signal,
                    method: 'POST',
                    body: JSON.stringify({ token: token, patients: patients, thresholds: thresholds, timerange: timeRange }),
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    mode: 'cors'
                }).then(res => {
                    if (!res.ok) {
                        throw Error('Could not fetch the data for the resource');
                    }
                    return res.json();
                }).then(summary => {
                    setFromDate(summary.fromDate);
                    setToDate(summary.toDate);
                    setPatientReadings(() => {return summary});
                }).catch(err => {
                    setShowProgress(false);
                    if (err.name === 'AbortError') {
                        //console.log('fetch aborted');
                    } else {
                        console.log(err);
                    }
                });
            } catch (err) {
                setShowProgress(false);
                dataReadingsController.abort();
            }

            setShowProgress(false);
        };

        // call the function
        fetchCounts().catch(console.error);

        //  Clean-up useEffect
        return () => {
            dataReadingsController.abort();
        };
    }, [timeRange, noReadings, selectedIndex, patients]);

    const setUpTabs = () => {        
        const longtabs = [
            { text: 'Data Readings Outside Thresholds' },
            { text: 'No Data Readings' },
        ];
        return (
            <React.Fragment>
                <div id="longtabs">                    
                    <Tabs
                        dataSource={longtabs}
                        selectedIndex={selectedIndex}
                        onOptionChanged={onTabsSelectionChanged}
                    />
                </div>                
            </React.Fragment>
        );
    }
    
    // If the user selects a different tab, changes the selected tab and the noreadings value (sent through props)
    const onTabsSelectionChanged= React.useCallback((args:any) => {
        if (args.name==='selectedIndex' && args.value >= 0) {
            setSelectedIndex(args.value);
            args.value===0 ? setNoReadings(() => {return false}) : setNoReadings(() => {return true})
        }
      }, [setSelectedIndex, setNoReadings]);
      
    return (

        <Container fluid>
            <Breadcrumb>
                <Breadcrumb.Item active>Patient Data Readings Manager</Breadcrumb.Item>
            </Breadcrumb>
            {setUpTabs()}
            <div className="page-panel">
                <div className="d-flex panel-function-header">
                {noReadings === false ?
                            <h1>Data Readings Reported - {fromDate} to {toDate}</h1>
                            : <h1>No Reported Data Readings - {fromDate} to {toDate}</h1>}
                    <div className="time-period-selector">
                        <ButtonGroup className="time-period-group" size={'sm'} >
                            {vitalsCountButtons.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio-${idx}`}
                                    type="radio"
                                    name="radio"
                                    value={radio.value}
                                    variant={timeRange === radio.value ? "primary" : "outline-primary"}
                                    checked={timeRange === radio.value}
                                    onChange={(e) => setTimeRange(radio.value)}>
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </div>
                </div>
                <DataReadingsPatientCount switchView={props.switchView} permissions={props.permissions} noReadings={noReadings} timeRange={timeRange} dataReadingsSummary={patientReadings}  />
                <DataReadingsGraph switchView={props.switchView} permissions={props.permissions} noReadings={noReadings} timeRange={timeRange} dataReadingsSummary={patientReadings} showSpinner={showProgress} />
                <DataReadingsGrid switchView={props.switchView} permissions={props.permissions} noReadings={noReadings} timeRange={timeRange} setSelectedPatientId={props.setSelectedPatientId} showSpinner={showProgress} />
            </div>

        </Container>)
}
export default DataReadingsManager;
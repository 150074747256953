/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Navbar from '../Navbar';
import AboutPage from '../AboutPage';
import HelpPage from '../HelpPage';
import AdminDashboard from './AdminDashboard';
import AdminOrganizations from './AdminOrganizationsHook';  //working on this....grrr
import AdminConfiguration from './AdminConfiguration';
import AddOrganization from './AddOrganizationHook';
import EnrollmentInvitations from './EnrollmentInvitations';
import NewEnrollmentInvitationHook from './NewEnrollmentInvitationHook';
import Users from './Users';
import AddUsers from './AddUsers';
import EditUsers from './EditUsers';
import Teamlet from './Teamlet';
import AddTeamlet from './AddTeamlet';
import EditTeamlet from '../Shared/Teamlets/EditTeamlet';
import AdminRoles from './AdminRoles';
import EnrollPatient from '../HealthProvider/EnrollPatient';
import DataReadingsManager from '../Shared/DataReadings/DataReadingsManager';
import Patient from '../Patient/PatientContainer';
import PatientPanel from '../HealthProvider/PatientPanel';
import PermissionService from '../../Services/PermissionService';
import AdminPermissions from './AdminPermissions';
import RPMdeviceModals from './RPMdeviceModels';
import { Container, Row, Col } from 'react-bootstrap/';
import MyProfile from './MyProfile';
import PatientInformation from '../HealthProvider/PatientInformation';
import PatientInformationEdit from '../HealthProvider/PatientInformationEdit';
import PatientInformationVitalsHistory from '../HealthProvider/PatientInformationVitalsHistory';
import AddVitalData from '../HealthProvider/AddVitalData';
import PatientVitalThresholds from '../HealthProvider/PatientVitalThresholds';
import Sidebar from '../Shared/Sidebar';
import Timeout from '../Shared/Timeout';
import TeamletDetails from '../Shared/Teamlets/TeamletDetails';
import HuddleDetails from '../Shared/Huddle/HuddleDetails';
import AddHuddle from '../Huddles/Huddle';
import PatientRegistrationView from '../Shared/PatientRegistration/PatientRegistrationView';
import PatientRegistrationSelect from '../Shared/PatientRegistration/PatientRegistrationSelect';
import PatientRegistrationAdd from '../Shared/PatientRegistration/PatientRegistrationAdd';

import './AdministratorContainer.css';
const config = require('../../config.json');
interface CustomLocation {
    username: string,
    role: string,
    userOrganizations: any,
    displayRoleName: string,
    parentOrgID: string,
    parentOrgName: string,
    permissions: any,
    myInternalID: string,
    isEHROrganization: boolean,
}


function AdminContainer(this: any) {
    //const [profileservice, setProfileService] = useState(new ProfileService());
    const location = useLocation().state as CustomLocation;
    const [roleName, setRoleName] = useState("");
    const [displayRoleName, setDisplayRoleName] = useState("");
    const [username, setUsername] = useState("");
    const [logout, setLogout] = useState(false);
    //const [viewMode, setViewMode] = useState(sessionStorage.getItem('restorePage') ? sessionStorage.getItem('restorePage') : "dashboard");
    const [viewMode, setViewMode] = useState("");
    const [sidebarExpanded, setSidebarExpanded] = useState(true);
    const [editOrg, setEditOrg] = useState(false);
    const [editOrgID, setEditOrgID] = useState("");
    const [editOrgName, setEditOrgName] = useState("");
    const [editOrgShortName, setEditOrgShortName] = useState("");
    const [editOrgDescription, setEditOrgDescription] = useState("");
    const [editOrgParentOrganization, setEditOrgParentOrganization] = useState("");
    const [editOrgPrimaryPointOfContact, setEditOrgPrimaryPointOfContact] = useState("");
    const [editOrgSecondaryPointOfContact, setEditOrgSecondaryPointOfContact] = useState("");
    const [editOrgAddress, setEditOrgAddress] = useState("");
    const [editOrgTownCity, setEditOrgTownCity] = useState("");
    const [editOrgZip, setEditOrgZip] = useState("");
    const [editOrgState, setEditOrgState] = useState("");
    const [editOrgPhone, setEditOrgPhone] = useState("");
    const [editpatientsAssigned, setEditpatientsAssigned] = useState(false);
    const [editdevicesAssigned, setEditdevicesAssigned] = useState(false);
    const [editisParent, setEditisParent] = useState(false);
    const [editPhoneNumberID, seteditPhoneNumberID] = useState("");
    const [editStreetAddressID, seteditStreetAddressID] = useState("");
    const [validate, setValidate] = useState(false);
    const [editType, setEditType] = useState("Organization");
    const [editOrgStatus, setEditOrgStatus] = useState("");
    const [loginUserInfo, setLoginUserInfo] = useState({});
    const [permissionService, setPermissionService] = useState(new PermissionService());
    const [properties, setProperties] = useState(new Map());

    //Fields for editing the a user
    const [userUsername, setUserUsername] = useState("");
    const [userStatus, setUserStatus] = useState("");
    const [userRoleTemplate, setUserRoleTemplate] = useState("");
    const [userRoleName, setUserRoleName] = useState("");
    const [userHealthcenters, setUserHealthCenters] = useState<any[]>([]);
    const [userOrganizations, setUserOrganizations] = useState<any[]>([]);
    const [userFirstName, setUserFirstName] = useState("");
    const [userMiddleName, setUserMiddleName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userPrefix, setUserPrefix] = useState("");
    const [userSuffix, setUserSuffix] = useState("");
    const [userPreferredName, setUserPreferredName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userCellphone, setUserCellphone] = useState("");
    const [userLandlinePhone, setUserLandlinePhone] = useState("");
    const [userInternalID, setUserInternalID] = useState("");
    const [userAssignedOrgIds, setUserAssignedOrgIDs] = useState<any[]>([]);
    //const [permissions, setPermissions] = useState(new PermissionService());

    //Fields for editing a teamlet
    const [teamletName, setTeamletName] = useState("");
    const [parentID, setParentID] = useState("");
    const [parentName, setParentName] = useState("");
    const [organization, setOrganization] = useState("");
    const [organizationID, setOrganizationID] = useState("");
    const [site, setSite] = useState("");
    const [pathwayList, setPathwayList] = useState<any[]>([]);
    const [memberList, setMemberList] = useState<any[]>([]);
    const [healthCoachCount, setHealthCoachCount] = useState(0);
    const [providerCount, setProviderCount] = useState(0);
    const [teamletID, setTeamletID] = useState("");

    const [patientID, setPatientID] = useState("");
    const [huddleID, setHuddleID] = useState('');
    const [meetingID, setMeetingID] = useState('');
    const [returnTo, setReturnTo] = useState('dashboard');

    //These fields track the organization info dropdown on the navbar that effects the page
    const [organizationList, setOrganizationList] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState("");
    const [selectedOrganizationID, setSelectedOrganizationID] = useState("");
    const [parentOrgName, setParentOrgName] = useState("");
    const [parentOrgID, setParentOrgID] = useState("");
    const [isEHROrganization, setIsEHROrganization] = useState(false);
    const [ehrassociated, setEhrassociated] = useState(false);

    useEffect(() => {
        // Check if we have location.  If we dont have location from a new tab or manually entered URL we redirect to login.
        if (location) {
            setUsername(location.username);
            setRoleName(location.role);
            setDisplayRoleName(location.displayRoleName);
            setParentOrgID(location.parentOrgID);
            setParentOrgName(location.parentOrgName);
            setViewMode(location.role === "ROLE_ACTIVATE_ADMINISTRATOR" ? "configuration" : "dashboard");
            permissionService.setMyInternalID(location.myInternalID);
            permissionService.loadPermissions(location.permissions);
            permissionService.setIsEHROrganization(location.isEHROrganization);
        } else {
            Logout();
        }
        // Check if the user has change the browser to a new page and left.

    }, [location]);


    useEffect(() => {
        if (location) {
            setSelectOrganization(location.userOrganizations[0].value, location.userOrganizations[0].name);
        }
    }, []);

    /**
    * @name setParentOrgId
    * @param ParentId 
    */
    function setSelectedPatientID(ParentId: string) {
        setPatientID(ParentId);
    }

    /**
    * @name setSelectedHuddleID
    * @param huddleID 
    */
    function setSelectedHuddleID(huddleID: string) {
        setHuddleID(huddleID);
    }

    /**
    * @name setSelectedMeetingID
    * @param meetingID 
    */
    function setSelectedMeetingID(meetingID: string) {
        setMeetingID(meetingID);
    }

    /**
    * @name setSelectedTeamletID
    * @param ParentId 
    */
    function setSelectedTeamletID(TeamLetID: string) {
        setTeamletID(TeamLetID);
    }
    /**
     * @name switchView
     * @description This function is passed down to all the components and controls which component
     * appears in the main view.  See currentView variable below for details
     */
    function switchView(newView: string) {
        setViewMode(newView);
    }

    /**
     * @name setParentOrgId
     * @param ParentId 
     */
    function setParentOrgId(ParentId: string) {
        setEditOrgParentOrganization(ParentId);
    }

    function expandCollapseSidebar() {
        setSidebarExpanded(!sidebarExpanded);
    }

    function cancelEdit() {
        setEditOrg(false);
    }

    //Used in navbar props
    function setSelectOrganization(orgID: string, orgName: string) {
        //console.log("SETTING ID " + orgID + " name " + orgName + " parentID " + parentOrgID);
        setSelectedOrganization(orgName);
        setSelectedOrganizationID(orgID);
    }

    /**
     * @name setParentIndentification
     * @description This function is called to set parent ID for Add Templates
     */
    function setParentIndentification(tempParentID: string) {
        setParentID(tempParentID);
    }

    /**
     * @name editOrganization
     * @description This function is passed down through props so the AddOrganization.tsx can be used for both adding
     * and editing organizations
     */
    function editOrganization(editOrg: boolean, editOrgID: string, editOrgName: string, editOrgShortName: string, editOrgDescription: string,
        editOrgParentOrganization: string, editOrgPrimaryPointOfContact: string, editOrgSecondaryPointOfContact: string, editOrgAddress: string,
        editOrgTownCity: string, editOrgZip: string, editOrgState: string, editOrgPhone: string,
        editpatientsAssigned: boolean, editdevicesAssigned: boolean, editisParent: boolean, editStreetAddressID: string,
        editPhoneNumberID: string, validate: boolean, editType: string, editOrgStatus: string, ehrassociated: boolean) {
        setEditOrg(editOrg);
        setEditOrgID(editOrgID);
        setEditOrgName(editOrgName);
        setEditOrgShortName(editOrgShortName);
        setEditOrgDescription(editOrgDescription);
        setEditOrgParentOrganization(editOrgParentOrganization);
        setEditOrgPrimaryPointOfContact(editOrgPrimaryPointOfContact);
        setEditOrgSecondaryPointOfContact(editOrgSecondaryPointOfContact);
        setEditOrgAddress(editOrgAddress);
        setEditOrgTownCity(editOrgTownCity);
        setEditOrgZip(editOrgZip);
        setEditOrgState(editOrgState);
        setEditOrgPhone(editOrgPhone);
        setEditpatientsAssigned(editpatientsAssigned);
        setEditdevicesAssigned(editdevicesAssigned);
        setEditisParent(editisParent);
        seteditPhoneNumberID(editPhoneNumberID);
        seteditStreetAddressID(editStreetAddressID);
        setValidate(validate);
        setEditType(editType);
        setEditOrgStatus(editOrgStatus);
        setEhrassociated(ehrassociated);
    }

    /**
     * @name editUser
     * @description This function is passed down through props so the EditUser.  Used for tracking the
     * fields to be passed down
     */
    function editUser(tempLoginInfo: any, tempuserUsername: any, tempuserStatus: any, tempuserRoleTemplate: any,
        tempuserRoleName: any, tempuserOrganizations: any, tempAssignedOrganizations: any, tempuserFirstName: any, tempuserMiddleName: any, tempuserLastName: any,
        tempuserPrefix: any, tempuserSuffix: any, tempuserPreferredName: any,
        tempuserEmail: any, tempuserCellphone: any, tempuserLandlinePhone: any, tempuserInternalID: string, tempuserAssignedOrgIDs: any, _properties: Map<string, string>) {
        //console.log(tempuserFirstName)
        setLoginUserInfo(tempLoginInfo);
        setUserUsername(tempuserUsername);
        setUserStatus(tempuserStatus);
        setUserRoleTemplate(tempuserRoleTemplate);
        setUserRoleName(tempuserRoleName);
        setUserHealthCenters(tempuserOrganizations);
        setUserOrganizations(tempAssignedOrganizations);
        setUserFirstName(tempuserFirstName);
        setUserMiddleName(tempuserMiddleName);
        setUserLastName(tempuserLastName);
        setUserPrefix(tempuserPrefix);
        setUserSuffix(tempuserSuffix);
        setUserPreferredName(tempuserPreferredName);
        setUserEmail(tempuserEmail);
        setUserCellphone(tempuserCellphone);
        setUserLandlinePhone(tempuserLandlinePhone);
        setUserInternalID(tempuserInternalID);
        setUserAssignedOrgIDs(tempuserAssignedOrgIDs);
        setProperties(_properties);
        setViewMode("editUser");
    }

    /**
     * @name editTeamlet
     * @description This function is passed down through props so the EditTeamlet.  Used for tracking the
     * fields to be passed down
     */
    function editTeamlet(tempTeamletName: string, tempParentName: string, tempParentID: string,
        tempOrganization: string, tempOrganizationID: string, tempSite: string, tempPathwayList: any[],
        tempMemberList: any[], tempHealthCoachCount: number, tempProviderCount: number, tempTeamletID: string) {
        setTeamletName(tempTeamletName);
        setParentID(tempParentID);
        setParentName(tempParentName);
        setOrganization(tempOrganization);
        setOrganizationID(tempOrganizationID);
        setSite(tempSite);
        setPathwayList(tempPathwayList);
        setMemberList(tempMemberList);
        setHealthCoachCount(tempHealthCoachCount);
        setProviderCount(tempProviderCount);
        setTeamletID(tempTeamletID);
    }

    /**
     * @name setReturnToView
     * @description This function is used to set the return to view from Edit Teamlet
     * 
    */
    function setReturnToView(viewame: string) {
        setReturnTo(viewame);
    }

    /**
    * @name Logout
    * @description Logouts out the User
    * @param
    * @returns {json}
    */
    async function Logout() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        try {
            await fetch(`${config.activateServer}:${config.activatePORT}/logout`, {
                method: 'POST',
                body: JSON.stringify({ token: token }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode: 'cors'
            });
        } catch (err) {
            console.log("ERROR " + err);
        }

        //  Clear storage
        sessionStorage.removeItem('authenticated');
        sessionStorage.removeItem('ACTIVATEtoken');
        //sessionStorage.removeItem('restorePage');
        // Set Logout   
        setLogout(true);
    }

    if (logout) {
        return <Navigate to="/login" />;
    }
    let currentView;

    switch (viewMode) {
        case "dashboard":
            if (roleName === "ROLE_ACTIVATE_ADMINISTRATOR") {
                currentView = <AdminConfiguration switchView={switchView} permissions={permissionService} />;
            } else {
                currentView = <AdminDashboard switchView={switchView} permissions={permissionService}
                    selectedOrganization={selectedOrganization}
                    selectedOrganizationID={selectedOrganizationID}
                    setSelectedTeamletID={setSelectedTeamletID}
                    setHuddleID={setSelectedHuddleID}
                    setMeetingID={setMeetingID}
                    teamletName={teamletName} />;
            }

            break;
        case "configuration":
            currentView = <AdminConfiguration switchView={switchView} permissions={permissionService} />;
            break;
        case "about":
            currentView = <AboutPage switchView={switchView} username={username} />;
            break;
        case "help":
            currentView = <HelpPage switchView={switchView} username={username} />;
            break;
        case "datareadings":
            currentView = <DataReadingsManager permissions={permissionService} switchView={switchView} setSelectedPatientId={setSelectedPatientID} />;
            break;
        case "adminOrganization":
            currentView = <AdminOrganizations
                switchView={switchView}
                editOrganization={editOrganization}
                setParentOrgId={setParentOrgId}
                roleName={roleName}
                permissions={permissionService} />;
            break;
        case "addOrganization":
            currentView = <AddOrganization
                switchView={switchView}
                cancelEdit={cancelEdit}
                editOrgID={editOrgID}
                editOrg={editOrg}
                editOrgName={editOrgName}
                editOrgShortName={editOrgShortName}
                editOrgDescription={editOrgDescription}
                editOrgParentOrganization={editOrgParentOrganization}
                editOrgPrimaryPointOfContact={editOrgPrimaryPointOfContact}
                editOrgSecondaryPointOfContact={editOrgSecondaryPointOfContact}
                editOrgAddress={editOrgAddress}
                editOrgTownCity={editOrgTownCity}
                editOrgZip={editOrgZip}
                editOrgState={editOrgState}
                editOrgPhone={editOrgPhone}
                editpatientsAssigned={editpatientsAssigned}
                editdevicesAssigned={editdevicesAssigned}
                editisParent={editisParent}
                editPhoneNumberID={editPhoneNumberID}
                editStreetAddressID={editStreetAddressID}
                validate={validate}
                editType={editType}
                editOrgStatus={editOrgStatus} 
                ehrassociated={ehrassociated}/>;
            break;
        case "addNewOrganization":
            //  Reset State for Org add
            currentView = <AddOrganization
                switchView={switchView}
                cancelEdit={cancelEdit}
                editOrgID=""
                editOrg={false}
                editOrgName=""
                editOrgShortName=""
                editOrgDescription=""
                editOrgParentOrganization=""
                editOrgPrimaryPointOfContact=""
                editOrgSecondaryPointOfContact=""
                editOrgAddress=""
                editOrgTownCity=""
                editOrgZip=""
                editOrgState=""
                editOrgPhone=""
                editpatientsAssigned={true}
                editdevicesAssigned={true}
                editisParent={true}
                editPhoneNumberID=""
                editStreetAddressID=""
                validate={validate}
                editType="Organization"
                editOrgStatus={editOrgStatus}
                ehrassociated={ehrassociated} />;
            break;
        case "addNewSite":
            //  Reset State for Org add
            currentView = <AddOrganization
                switchView={switchView}
                cancelEdit={cancelEdit}
                editOrgID=""
                editOrg={false}
                editOrgName=""
                editOrgShortName=""
                editOrgDescription=""
                editOrgParentOrganization={editOrgParentOrganization}
                editOrgPrimaryPointOfContact=""
                editOrgSecondaryPointOfContact=""
                editOrgAddress=""
                editOrgTownCity=""
                editOrgZip=""
                editOrgState=""
                editOrgPhone=""
                editpatientsAssigned={true}
                editdevicesAssigned={true}
                editisParent={false}
                editPhoneNumberID=""
                editStreetAddressID=""
                validate={validate}
                editType="Site"
                editOrgStatus={editOrgStatus}
                ehrassociated={ehrassociated} />;

            break;
        case "enrollmentInvitations":
            currentView = <EnrollmentInvitations switchView={switchView} permissions={permissionService} />;
            break;
        case "newEnrollmentInvitation":
            //Was non hook version
            currentView = <NewEnrollmentInvitationHook switchView={switchView} />;
            break;
        case "adminRoles":
            currentView = <AdminRoles switchView={switchView} />;
            break;
        case "adminPermissions":
            currentView = <AdminPermissions switchView={switchView} />;
            break;
        case "patientpanel":
            currentView = <PatientPanel setMeetingID={setMeetingID} setHuddleID={setHuddleID} permissions={permissionService} switchView={switchView} roleName={roleName} setSelectedPatientID={setSelectedPatientID} />;
            break;
        case "users":
            currentView = <Users switchView={switchView} loginUsername={username} permissions={permissionService} editUser={editUser} />;
            break;
        case "addUsers":
            currentView = <AddUsers switchView={switchView} />;
            break;
        case "editUser":
            currentView = <EditUsers permissions={permissionService} switchView={switchView} loginUserInfo={loginUserInfo} usernameProp={userUsername} statusProp={userStatus}
                roleTemplateProp={userRoleTemplate} roleNameProp={userRoleName} healthcenterProp={userHealthcenters} assignedOrgProp={userOrganizations} firstNameProp={userFirstName}
                middleNameProp={userMiddleName} lastNameProp={userLastName} prefixProp={userPrefix} suffixProp={userSuffix}
                preferredNameProp={userPreferredName} emailProp={userEmail} cellPhoneProp={userCellphone} landlineProp={userLandlinePhone} 
                idProp={userInternalID} 
                assignedOrgIdProp={userAssignedOrgIds}
                properties={properties} />;
            break;
        case "teamlet":
            currentView = <Teamlet switchView={switchView} setReturnToView={setReturnToView} setSelectedTeamletID={setTeamletID} permissions={permissionService} />;
            break;
        case "addTeamlet":
            currentView = <AddTeamlet switchView={switchView} />;
            break;
        case "editTeamlet":
            currentView = <EditTeamlet returnTo={returnTo} switchView={switchView} teamletID={teamletID} />;
            break;
        case "rpmdevices":
            currentView = <RPMdeviceModals switchView={switchView} />;
            break;
        case "MyProfile":
            currentView = <MyProfile 
                            permissions={permissionService} 
                            switchView={switchView} 
                            setHuddleID={setSelectedHuddleID} 
                            setMeetingID={setSelectedMeetingID} 
                            setSelectedTeamletID={setTeamletID} 
                            editTeamlet={editTeamlet} 
                            setReturnToView={setReturnToView}/>;
            break;
        case "patientInformation":
            currentView = <PatientInformation permissions={permissionService} switchView={switchView} patientID={patientID} parentOrgID={parentOrgID} setMeetingID={setSelectedMeetingID} setHuddleID={setSelectedHuddleID} setSelectedTeamletID={setTeamletID} />;
            break;
        case "patientinformationEdit":
            currentView = <PatientInformationEdit permissions={permissionService} switchView={switchView} patientID={patientID} />;
            break;
        case 'vitalData':
            currentView = <AddVitalData
                switchView={switchView}
                orgID={organizationID}
                orgName={organization}
                parentOrgID={parentOrgID}
                parentOrgName={parentOrgName}
            />;
            break;
        case "patientvitalshistory":
            currentView = <PatientInformationVitalsHistory permissions={permissionService} switchView={switchView} patientID={patientID} />;
            break;
        case "patientvitalthresholds":
            currentView = <PatientVitalThresholds switchView={switchView} patientID={patientID} permissions={permissionService} />;
            break;
        case "TeamletDetails":
            currentView = <TeamletDetails 
                            setSelectedTeamletID={setSelectedTeamletID} 
                            setSelectedPatientID={setPatientID} 
                            switchView={switchView} 
                            permissions={permissionService} 
                            setHuddleID={setSelectedHuddleID} 
                            setMeetingID={setMeetingID} 
                            teamletID={teamletID} 
                            setReturnToView={setReturnToView}/>;
            break;
        case "huddledetails":
            currentView = <HuddleDetails
                switchView={switchView}
                huddleID={huddleID}
                meetingID={meetingID}
                permissions={permissionService}
                setMeetingID={setMeetingID}
                teamletID={teamletID}
                setHuddleID={setHuddleID}
                setSelectedPatientID={setSelectedPatientID}
                setSelectedTeamletID={setTeamletID} />;
            break;
        case "addHuddle":
            currentView = <AddHuddle
                            switchView={switchView}
                            teamletID={teamletID}
                            editMode='add'/>;
                break;
        case "editHuddle":
            currentView = <AddHuddle
                            switchView={switchView}
                            teamletID={teamletID}
                            editMode='edit'
                            meetingID={meetingID}/>;
                break;
        case "huddleDetails":
            currentView = <HuddleDetails
                switchView={switchView}
                huddleID={huddleID}
                meetingID={meetingID}
                permissions={permissionService}
                setMeetingID={setMeetingID}
                teamletID={teamletID}
                setHuddleID={setHuddleID}
                setSelectedPatientID={setSelectedPatientID}
                setSelectedTeamletID={setTeamletID} />;
            break;
        case 'patientRegistration':
            currentView = <PatientRegistrationView
                switchView={switchView} />;
            break;
        case 'patientRegistrationSelect':
            currentView = <PatientRegistrationSelect
                switchView={switchView}
                setSelectedPatientID={setSelectedPatientID}/>;
            break;
        case 'patientRegistrationAdd':
            currentView = <PatientRegistrationAdd
                switchView={switchView}
                patientID={patientID} />;
            break;
    }

    return (
        <div data-testid="adminContainer">
            <Navbar
                expandCollapseSidebar={expandCollapseSidebar}
                roleName={displayRoleName}
                username={username}
                switchView={switchView}
                organizationList={organizationList}
                selectedOrganization={selectedOrganization}
                selectedOrganizationID={selectedOrganizationID}
                setSelectedOrganization={setSelectOrganization} />
            <div className="app-body-content">
                <Sidebar
                    expanded={sidebarExpanded}
                    switchView={switchView}
                    permissions={permissionService}
                    defaultView={"configuration"} />
                <div className="page-content">
                    {currentView}
                </div>
            </div>

            <Timeout logout={Logout} />
        </div>
    );
}

export default AdminContainer;
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { ModalTimeoutWarning } from '../Shared/MessageBox/ModalTimeoutWarning';
import { Navigate, useLocation } from 'react-router-dom';

const config = require('../../config.json');

function Timeout(props : { logout : () => void}) {
    const [timeout, setTimeout] = useState(config.inactivityTimeInSeconds !== undefined ? 1000 * config.inactivityTimeInSeconds + 1 : 120001); // This is the timer to determine when to check the page inactivity
    const [showTimeoutModal, setShowTimeoutModal] = useState(false);
    const [logout, setLogout] = useState(false);

    /**
     * Timeout functions
     */

    const { reset } = useIdleTimer({
        onAction,
        onActive,
        onIdle,
        onPrompt,
        timeout : timeout,
        promptTimeout : (timeout * 0.2),
        events : [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange'
        ]
    });

    /**
     * @name onAction
     * @description Check if a user made an action on the page so that they are not timed out
     */
    function onAction() {
        reset();
    }

    /**
     * @name onActive
     * @description Another function to check if the user is activly making actions
     */
    function onActive() {
        reset();
    }

    /**
     * @name onIdle
     * @description Fires an action based on the user not having any activity after some set time
     * We check first if they have been timedout before to send a modal and then if they are inactive
     * after checking again, then we formally log them out
     */
    function onIdle() {
        props.logout();
    }

    function onPrompt() {
        setShowTimeoutModal(true);
    }

    /**
    * @name Logout
    * @description Logouts out the User
    * @param
    * @returns {json}
    */
    async function Logout() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        try {
            await fetch(`${config.activateServer}:${config.activatePORT}/logout`, {
                method: 'POST',
                body: JSON.stringify({ token: token }),
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode : 'cors'
            });
        } catch (err) {
            console.log("ERROR " + err);
        }

        //  Clear storage
        sessionStorage.removeItem('authenticated');
        sessionStorage.removeItem('ACTIVATEtoken');
        //sessionStorage.removeItem('restorePage');
        // Set Logout   
        setLogout(true);
    }

    if (logout) {
        return <Navigate to="/login" />;
    }

    return (
        <ModalTimeoutWarning
            showErrorModal={showTimeoutModal}
            messageType={"system"}
            Logout={Logout}
            setShowModalWarning={setShowTimeoutModal}/>
    );

}

export default Timeout;
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Navbar, Button, Container, Nav, NavItem, Dropdown, ButtonGroup } from 'react-bootstrap/';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import '../Navbar.css';
const navbarLogo = require('./../../Resources/ACTIVATE_logo_small.png');
const config = require('../../config.json');

function PatientNavbar( props : 
    { 
        roleName : string,
        username : string,
        expandCollapseSidebar : Function,
        switchView : Function,
        displayRoleName : string;
    }){
    const location = useLocation();
    const [logout, setLogout] = useState(false);
    const [displayOrganizationList, setDisplayOrganizationList] = useState<any[]>([]);
    const [currentOrgID, setCurrentOrgID] = useState("");
    const [currentOrgName, setCurrentOrgName] = useState("");
    const navigate=useNavigate();


    /**
    * @name Logout
    * @description Logouts out the User
    * @param
    * @returns {json}
    */
     async function Logout(){
        const token = sessionStorage.getItem('ACTIVATEtoken');
        try{
            await fetch(`${config.activateServer}:${config.activatePORT}/logout`, {
                method: 'POST',
                body: JSON.stringify({ token: token }),
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode : 'cors'
            })
        } catch(err){
            console.log("ERROR " + err);
        }

        //  Clear storage
        sessionStorage.removeItem('authenticated');
        sessionStorage.removeItem('ACTIVATEtoken');
        //sessionStorage.removeItem('restorePage');
        // Set Logout   
        setLogout(true);
    }

    if(logout){
        return <Navigate to="/login"/>
    }
    return(
        <Navbar id="navContainer">
            <Container fluid>
                <Nav>
                    
                    <img 
                        id="ACTIVATElogo"
                        src={navbarLogo}
                        style={{cursor : "pointer"}}
                        onClick={() => props.switchView("dashboard")}
                        alt="ACTIVATElogo">
                    </img>
                    <Button
                        style={{ "color" : "black", "backgroundColor" : "white", borderColor : "white"}}
                        onClick={() => props.switchView("dashboard")}>
                        <FontAwesomeIcon icon={faHouse} />
                    </Button>
                    {displayOrganizationList.length > 1 ?
                        <Dropdown as={ButtonGroup}>
                            <Button 
                            className="navButton"
                            style={{ "color" : "black", "backgroundColor" : "white", borderColor : "white"}}
                            onClick={() => props.switchView("dashboard")}>
                                {currentOrgName}
                            </Button>
                            <Dropdown.Toggle className="navButton" split variant="success" id="dropdown-split-basic" />

                            <Dropdown.Menu>
                                {displayOrganizationList}
                            </Dropdown.Menu>
                        </Dropdown>
                    :
                    <Button
                        className='navButton'>
                            {displayOrganizationList[0]}
                    </Button>
                    }
                </Nav>
                <Navbar.Collapse className="nav-help-links">
                    <Nav.Link onClick={() => props.switchView("about")}>About</Nav.Link>
                    <Nav.Link onClick={() => props.switchView("help")}>Help</Nav.Link>
                </Navbar.Collapse>
                <Nav>
                    <NavItem className="nav-user-info">
                        <p className="nav-user-name">{props.username}</p>
                        <p className="nav-user-role">{props.displayRoleName}</p>
                    </NavItem>
                    <NavItem>
                        <Button id="logoutButton" onClick={Logout}><FontAwesomeIcon icon={ faSignOutAlt }></FontAwesomeIcon></Button>
                    </NavItem>
                </Nav>
            </Container>
        </Navbar>
    )
}

export default PatientNavbar;
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Anchor, Button, Row, Col, Form, Breadcrumb, Card, Modal, Container } from 'react-bootstrap';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Icon } from '@ailibs/feather-react-ts';
import { EmailValidator } from 'commons-validator-js';
import { ROLE_PROVIDER, ROLE_DIGITAL_HEALTH_NAVIGATOR, SUFFIXLOOKUP, STAFFPCPID } from '../Shared/constants';
import PermissionService from '../../Services/PermissionService';

import './MyProfile.css';
import MyPatients from '../Shared/MyProfile/MyPatients';
import MyNextHuddle from '../Shared/Huddle/MyNextHuddles';

const config = require('../../config.json');

function MyProfile(props: 
    { 
        permissions: PermissionService, 
        switchView: (view: string) => void, 
        setSelectedPatientID: (newId: string) => void,
        setSelectedTeamletID: (Id: string) => void,
        setReturnToView: (view: string) => void,
        setHuddleID: (huddleId: string) => void,
        setMeetingID: (meetingId: string) => void,
    }) {
    const location = useLocation();
    const [internalID, setInternalID] = useState("");
    const [status, setStatus] = useState("");
    const [emailAddressObj, setEmailAddressObj] = useState({});
    const [cellPhone, setCellPhone] = useState({});
    const [cellPhoneNumber, setCellPhoneNumber] = useState("");
    const [landLineObj, setLandLineObj] = useState({});
    const [landLineNumber, setLandLineNumber] = useState("");
    const [requireChangePassword, setRequireChangePassword] = useState(false);
    const [finalOrganizations, setFinalOrganizations] = useState<any>([]);
    const [validated, setValidated] = useState(false);
    const [prefixList, setPrefixList] = useState<any[]>([]);
    const [prefixNames, setPrefixNames] = useState<any[]>([]);
    const [suffixList, setSuffixList] = useState<any[]>([]);
    const [suffixNames, setSuffixNames] = useState<any[]>([]);
    const [healthCenterParent, setHealthCenterParent] = useState("");
    const [healthCenterDescription, setHealthCenterDescription] = useState("");
    const [pcpnpiIdentifier, setPcpnpiIdentifier] = useState("");

    const [username, setUserName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [preferredName, setPreferredName] = useState('');
    const [prefix, setPrefix] = useState('');
    const [suffix, setSuffix] = useState('');

    const [emailAddress, setEmailAddress] = useState('');
    const [roleID, setRoleID] = useState("");
    const [deleteID, setDeleteID] = useState("");
    const [showDeleteTeamletModal, setShowDeleteTeamletModal] = useState(false);
    const [roleName, setRoleName] = useState(location.state !== undefined && location.state !== null ? location.state.role : '');

    const [isReadOnlyMode, setIsReadOnlyMode] = useState(true);
    const [showSaveModalPrompt, setShowSaveModalPromptl] = useState(false);
    const [healthcareTeamLetSummary, setHealthcareTeamLetSummary] = useState([]);
    const [mySites, setMySites] = useState<any[]>([]);
    const [properties, setProperties] = useState(new Map());

    //  User Status
    const [showProgress, setShowProgress] = useState(false);

    // Error handling
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState("");

    //  Spinner Icon location
    const position = { of: '#profileInfo' };

    const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()
        , PNF = require('google-libphonenumber').PhoneNumberFormat
        , PNT = require('google-libphonenumber').PhoneNumberType;

    const validPhoneNumber = (e: any) => {
        // https://www.ronaldjamesgroup.com/blog/phone-numbers-in-javascript-using-e164-libphonenumber-and-microdata
        // https://www.npmjs.com/package/google-libphonenumber
        let isValid = true;

        try {
            isValid = phoneUtil.isValidNumber(phoneUtil.parse(e, 'US'));
        } catch (e) {
            isValid = false;
        }

        return isValid;
    };


    useEffect(() => {
        loadProfileInfo();
        loadPrefixes();
        loadSuffixes();
    }, []);

    /**
    * @name loadProfileInfo
    * @description Loads the User Profile from userprofile/detailed
    * @param  
    * @returns {json} 
    */
    async function loadProfileInfo() {
        // Start Spinner
        setShowProgress(true);

        const token = sessionStorage.getItem('ACTIVATEtoken');

        await fetch(`${config.activateServer}:${config.activatePORT}/getUserProfileDetailed`, {
            method: 'POST',
            body: JSON.stringify({ token: token }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(async _response => {
            //  Check for Success
            if (_response.ok) {
                try {
                    const _json = await _response.json();
                    loadUI(_json);

                } catch (err) {
                    console.log("ERROR " + err);
                }
            } else {
                // ToDo:  Handle non-200s
                console.log("Received HTTP status" + _response.status.toString());
            }
        }).catch(err =>{
            // Stop Spinner
            setShowProgress(false);
            console.log("Error in loadProfileInfo: " + err);
        });
        
        // Stop Spinner
        setShowProgress(false);
    }

    /**
    * @name loadUI
    * @description Loads the UI
    * @param  
    * @returns {json} 
    */
    async function loadUI(data: any) {
        //  Fields that can be updated
        setInternalID(data.internalID);
        setUserName(data.username);
        setStatus(data.accountStatus);
        setMiddleName(data.middleName);
        setRequireChangePassword(data.mustChangePasswordNextLogin);
        setRoleID(data.roleTemplate.internalID);

        //  Fields that can be updated
        setFirstName(data.firstName);
        setMiddleName(data.middleName);
        setLastName(data.lastName);
        setPreferredName(data.preferredName);
        setPrefix(data.prefix);
        setSuffix(data.suffix);
        setEmailAddressObj(data.emailAddress);
        setEmailAddress(data.emailAddress.name);
        setCellPhone(data.cellPhone);
        setCellPhoneNumber(data.cellPhone.name);
        setLandLineObj(data.landlinePhone);
        setLandLineNumber(data.landlinePhone.name);
        setFinalOrganizations(data.organizations.map((o: any) => { return (o.internalID); }));
        setMySites(data.organizations);

        if (roleName === ROLE_PROVIDER){
            //  Grab PCP Identifier out of a map
            if (data.properties != null &&
                    data.properties !== undefined){
                
                //  Set the PCP ID
                //  Convert properties to Map    
                const map = new Map(Object.entries(data.properties));
                setProperties(() => map);

                //  Set the PCP ID
                let _id = '';

                //  Check for StaffPCPID
                if (map.has(STAFFPCPID)){
                    _id = map.get(STAFFPCPID) as string;
                }
            
                //  Set the PCP Id, if found
                setPcpnpiIdentifier(_id!);
            } else {
                setPcpnpiIdentifier('');
            }
        }
    
        //  Load Health Center Summary
        setHealthCenterParent(data.parentOrganization.name);
        setHealthCenterDescription(data.parentOrganization.description);

        //  Load Health Center Summary
        setHealthcareTeamLetSummary(data.teams);
    }

    /**
      * @name loadPrefixes
      * @description Loads the Prefix List for the Dropdown values
      * @param  
      * @returns {json} 
    */
    async function loadPrefixes() {
        const token = sessionStorage.getItem('ACTIVATEtoken');

        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getValues`, {
            method: 'POST',
            body: JSON.stringify({ token: token, type: "Prefix" }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        });

        try {
            const data = await response.json();
            //  Load the Prefix List
            setPrefixList(data.map((i: any) => {
                return (<option
                    key={i.value}
                    value={i.value}>
                    {i.name}
                </option>);
            }));
            setPrefixNames(data);
        } catch (err) {
            console.log("ERROR " + err);
        }
    }

    /**
    * @name loadSuffixes
    * @description Loads the Suffix List for the Dropdown values
    * @param  
    * @returns {json} 
    */
    async function loadSuffixes() {
        const token = sessionStorage.getItem('ACTIVATEtoken');

        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getValues`, {
            method: 'POST',
            body: JSON.stringify({ token: token, type: SUFFIXLOOKUP }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        });

        try {
            const data = await response.json();
            //  Load Suffix List
            setSuffixList(data.map((i: any) => {
                return (<option
                    key={i.value}
                    value={i.value}>
                    {i.name}
                </option>);
            }));
            setSuffixNames(data);
        } catch (err) {
            console.log("ERROR " + err);
        }
    }

    /**
    * @name setReadOnlyMode
    * @description Turns Edit Mode On/Off
    * @param  boolean On/Off
    * @returns  
    */
    async function setReadOnlyMode(editModeOn: boolean) {
        setIsReadOnlyMode(editModeOn);
        const forms = document.querySelectorAll('form');
        const form = forms[0];
        Array.from(form.elements).forEach((element: any) => {
            if (editModeOn) {
                if (element.name === 'emailAddress' || element.name === 'cellPhoneNumber' || element.name === 'landLineNumber') {
                    element.classList.remove('is-invalid');
                    element.required = false;
                    element.nextElementSibling.innerHTML = '   ';
                }
            } else {
                if (element.name === "emailAddress") {
                    element.required = true;
                    if (element.value === "") {
                        element.classList.add('is-invalid');
                        element.nextElementSibling.innerHTML = 'Please enter a valid email address';
                    }

                }
            }
        });
        //  Reset if the user cancels
        if (editModeOn) {
            loadProfileInfo();
            setValidated(false);
        }
    }

    /**
    * @name prefixSelectChange
    * @description Sets Prefix selection
    * @param  
    * @returns 
    */
    async function prefixSelectChange(e: { target: { value: React.SetStateAction<string>; }; }) {
        setPrefix(e.target.value);
    }

    /**
    * @name suffixSelectChange
    * @description Sets Suffix selection
    * @param  
    * @returns 
    */
    async function suffixSelectChange(e: { target: { value: React.SetStateAction<string>; }; }) {
        setSuffix(e.target.value);
    }

    /**
    * @name showUpdateConfirmModal
    * @description Sets Suffix selection
    * @param  
    * @returns 
    */
    function showUpdateConfirmModal(toggle: boolean) {
        setShowSaveModalPromptl(toggle);
    }

    /**
    * @name saveProfileConfirmed
    * @description Saves the profile
    * @param  
    * @returns 
    */
    function saveProfileConfirmed() {
        showUpdateConfirmModal(false);
        //setIsReadOnlyMode(true);
        updateUser();

    }
    const handleSubmit = (event: any) => {

        const form = event.currentTarget;
        event.preventDefault();

        const validator = new EmailValidator();
        const elements = form.elements;
        const cellphoneElement = elements.namedItem('cellPhone');
        const emailElement = elements.namedItem('emailAddress');

        let isCellphoneValid = true;
        let isEmailValid = true;
        let isLandlineValid = true;

        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element.type === "text" && element.id === 'emailAddress') {
                //  Let blanks display normal error message                

                if (element.value === '') {
                    isEmailValid = false;
                    element.classList.add('is-invalid');
                    element.required = true;
                    element.nextElementSibling.innerHTML = 'Please enter a valid email address';
                } else {
                    if (validator.isValid(emailAddress) === false) {
                        isEmailValid = false;
                        element.classList.add('is-invalid');
                        element.required = true;
                        element.nextElementSibling.innerHTML = 'Please enter a valid email address';
                    } else {
                        isEmailValid = true;
                        element.required = false;
                        element.classList.remove('is-invalid');
                    }
                }
            }

            if (element.type === "text" && element.id === 'cellPhoneNumber') {

                if (element.value !== '') {
                    if (validPhoneNumber(cellPhoneNumber) === false) {
                        isCellphoneValid = false;
                        element.classList.add('is-invalid');
                        element.required = true;
                        element.nextElementSibling.innerHTML = "Please enter the cell Phone Number (xxx) xxx-xxxx.";
                    } else {
                        element.classList.remove('is-invalid');
                        element.required = false;
                        element.nextElementSibling.innerHTML = '   ';
                    }
                } else {
                    element.classList.remove('is-invalid');
                    element.required = false;
                    element.nextElementSibling.innerHTML = '   ';
                }

            }

            if (element.type === "text" && element.id === 'landLineNumber') {
                if (element.value !== '') {
                    if (validPhoneNumber(landLineNumber) === false) {
                        isLandlineValid = false;
                        element.classList.add('is-invalid');
                        element.required = true;
                        element.nextElementSibling.innerHTML = "Please enter the landline Number (xxx) xxx-xxxx.";
                    } else {
                        element.classList.remove('is-invalid');
                        element.required = false;
                        element.nextElementSibling.innerHTML = '   ';
                    }
                } else {
                    element.classList.remove('is-invalid');
                    element.required = false;
                    element.nextElementSibling.innerHTML = '   ';
                }
            }
        }

        if (!isEmailValid || !isCellphoneValid || !isLandlineValid) {
            event.stopPropagation();
            setValidated(true);
            return;
        }
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        //  Save MyProfile here
        saveProfileInfo();
    };

    /**
    * @name saveProfileInfo
    * @description Starts the Profile Save
    * @param  
    * @returns
    */
    async function saveProfileInfo() {
        showUpdateConfirmModal(true);
    }

    /**
     * @name updateUser
     * @description Updates the user
     * @param
     * @returns {json}
    */
    async function updateUser() {
        let propertyMap = '{}'
        let aProperties = JSON.parse(propertyMap);
        let _temp = '';
        
        for (let key of properties.keys()) {
            _temp = '{"' + key + '" : "' + properties.get(key) + '"}';
        }
  
        //  Create Object
        if (_temp != ''){
            aProperties = JSON.parse(_temp);
        }

        const token = sessionStorage.getItem('ACTIVATEtoken');

        await fetch(`${config.activateServer}:${config.activatePORT}/updateUser`, {
            method: 'POST',
            body: JSON.stringify({
                token: token,
                internalID,
                username,
                status,
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                preferredName,
                emailAddress,
                emailAddressObj,
                cellPhoneNumber,
                cellPhone,
                landLineNumber,
                landLineObj,
                requireChangePassword,
                roleID,
                finalOrganizations, 
                properties: aProperties
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(async _response => {
            try {
                //  Check for Success
                if (_response.ok) {
                    //  Save success, switch views
                    setIsReadOnlyMode(() => { return true; });
                    loadProfileInfo();
                } else {
                    //  Error returned from data service, display to user
                    _response.json().then(data => {
                        //  Create Message
                        const _statusCode = _response.status.toString();
                        const _statusmessage = JSON.stringify(data);

                        setShowErrorMsg(_statusmessage + " (Code: " + _statusCode + ")");

                        //  Show Modal
                        setShowErrorModal(true);
                    });
                }
            }
            catch (err) {
                console.log("Error in updateUser: " + JSON.stringify(err));
            }
        }).catch(err => {
            console.log(err);
        });
    }
    
    /**
     * @name renderIcon
     * @description Shows the feather icon
     * @param
     * @returns
     */
    function renderIcon(iconName : any, category:string) {
      return (
        <div className={`ui-icon ${category}`}>
          <Icon name={iconName} size="24" />
        </div>
      );
    }
    
    /**
     * @name teamletList
     * @description Renders user's assigned Teamlets
     * @param healthcareTeamletSummary
     * @returns Team list HTML
     */
    function teamletList(e: any) {
      // Check for row  
      if (e != null && e.length > 0) {
       return (
        <ul className="team-list"> 
          {e.map((data: any, index: number) => {
            if (data != null) {
                const pathways = data.digitalHealthPathways?.map((pathway: any, index: number) => {
                return <span key={index}><small>{pathway.name}</small><br/></span>;
            });
            return (
              <li key={index}>
                {renderIcon("users","people")}
                <p title={data.description}><Anchor onClick={(e) => { props.setSelectedTeamletID(data.internalID); props.switchView("TeamletDetails");}} title="View Teamlets">{data.name}</Anchor><br/>
                  {pathways}
                </p>
                {/* if user has permission to delete team members then show the icon  */}
                <p className="actions">
                  {canDelete() ? <a onClick={(e) => deleteTeamletClick(data)} title={`Remove ${data.name}`}><Icon name="trash-2" size="18" className="action-icon" data-internalid={data.internalID}/></a> : null}
                  {canEdit() ? <a onClick={(e) => editTeamletClick(data)} title={`Edit ${data.name}`}><Icon name="edit" size="18" className="action-icon" data-internalid={data.internalID} /></a> : null}
                </p>
              </li>
            );
            }
            }
          )}
      </ul>
      );
     } else {
       return (<>No assigned teamlets<br/></>);
     }
    }

    //  Delete Teamlet called from the DataGrid
    function deleteTeamletClick(e: any) {
        promptDeleteTeamlet(e.internalID);
    }

    //  Delete Pop-up
    function promptDeleteTeamlet(id: string) {
        showDeleteModal(id, true);
    }

    //  Delete Pop-up Toggle
    function showDeleteModal(id: string, toggle: boolean) {
        setDeleteID(id);
        setShowDeleteTeamletModal(toggle);
    }

    /**
    * @name deleteTeamlet
    * @description Delete Teamlets
    * @param
    * @returns {json}
    */
    async function deleteTeamlet() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        await fetch(`${config.activateServer}:${config.activatePORT}/deleteTeamlet`, {
            method: 'POST',
            body: JSON.stringify({
                token: token,
                deleteID: deleteID
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(async _response => {
            //  Check for Success
            if (_response.ok) {
                try {
                    const data = await _response.json();
                    setDeleteID('');
                    setShowDeleteTeamletModal(false);
                    //  Reload page
                    loadProfileInfo();
                } catch (e) {
                    console.log("ERROR deleting Teamlet " + JSON.stringify(e));
                }
            } else {
                //  Error returned from data service, display to user
                _response.json().then(data => {

                    //  Create Message
                    const _statusCode = _response.status.toString();
                    const _statusmessage = JSON.stringify(data);

                    setShowErrorMsg(_statusmessage + " (Code: " + _statusCode + ")");

                    //  Show Modal
                    setShowErrorModal(true);
                });
            }
        });
    }

    //  Edit TeamLet onClick handler
    function editTeamletClick(e: any) {
       //  Check for row Object
       if (e != null) {
        props.setReturnToView("MyProfile");
        props.setSelectedTeamletID(e.internalID);
        props.switchView("editTeamlet");
    }
    }

    /**
    * @name goToDashBoard
    * @description Goes to the dashboard
    * @param
    * @returns
    */
     function goToDashBoard(e:any) {
        props.switchView("dashboard");
        e.preventDefault();
    }


    //  Edit Teamlet Button
    function canEdit() {
        //return (roleName === 'ROLE_PROVIDER')
        //return props.permissions.checkSubfunctionPermission("CONFIGURATION", "STRUCTURE", "ACTIVATE_TEAMLET", "CREATE")
        return false;
    }

    //  Delete Button
    function canDelete() {
        //return (roleName === 'ROLE_PROVIDER')
        //return props.permissions.checkSubfunctionPermission("CONFIGURATION", "STRUCTURE", "ACTIVATE_TEAMLET", "CREATE")
        return false;
    }

    function loadSites() {
        const siteList: any[] = [];
        for (let i = 0; i < mySites.length; i++) {
            siteList.push(
                <section key={i}>
                    <p key={mySites[i].internalID}>
                    <span className="site-name">{mySites[i].name}</span><br/>
                    {mySites[i].streetAddress.street}<br/>
                    {mySites[i].streetAddress.cityName}, {mySites[i].streetAddress.stateName} {mySites[i].streetAddress.zipcode}
                    </p>
                    <div className="points-of-contact">
                    <h3>Point of Contact</h3>
                    <div className="contacts">
                        {mySites[i].primaryPointOfContact !== null ?
                        <p>
                        {mySites[i].primaryPointOfContact.firstName} {mySites[i].primaryPointOfContact.lastName}<br/>
                        {mySites[i].primaryPointOfContact !== null && mySites[i].primaryPointOfContact.emailAddresses !== null && mySites[i].primaryPointOfContact.emailAddresses[0] !== undefined ? <a href={`mailto:${mySites[i].primaryPointOfContact.emailAddresses[0].address}`}>{mySites[i].primaryPointOfContact.emailAddresses[0].address}</a> : <span className="text-na">No Email Provided</span>}<br/>
                        {mySites[i].primaryPointOfContact !== null && mySites[i].primaryPointOfContact.phoneNumbers !== null && mySites[i].primaryPointOfContact.phoneNumbers[0] !== undefined ? mySites[i].primaryPointOfContact.phoneNumbers[0].number : <span className="text-na">No Phone Provided</span>}
                        </p>
                        : <></> }
                        {mySites[i].secondaryPointOfContact != null ?
                        <p>
                            {mySites[i].secondaryPointOfContact.firstName} {mySites[i].secondaryPointOfContact.lastName}<br/>
                            {mySites[i].secondaryPointOfContact.emailAddresses !== null && mySites[i].secondaryPointOfContact.emailAddresses[0] !== undefined ? <a href={`mailto:${mySites[i].secondaryPointOfContact.emailAddresses[0].address}`}>{mySites[i].secondaryPointOfContact.emailAddresses[0].address}</a> : mySites[i].secondaryPointOfContact != null ? <span className="text-na">No Email Provided</span> : ""}<br/>
                            {mySites[i].secondaryPointOfContact.phoneNumbers !== null && mySites[i].secondaryPointOfContact.phoneNumbers[0] !== undefined  ? mySites[i].secondaryPointOfContact.phoneNumbers[0].number : mySites[i].secondaryPointOfContact != null ? <span className="text-na">No Phone Provided</span> : ""}
                        </p> 
                        : <></> }
                    </div>
                    </div>
            </section>
            );
        }
        return siteList;
    }

    //  updatePcpnpiIdentifier
    function updatePcpnpiIdentifier(e:any){
        setPcpnpiIdentifier(e);

        //  Check for PCP Properties
        if (properties != null &&
                properties !== undefined){
            //  Update Properties Map
            properties.set(STAFFPCPID, e);
        }

    }

    return (
        <div>
            <LoadPanel visible={showProgress} message={"Loading Profile Information"} showPane={true} position={position} showIndicator={true}></LoadPanel>
            <Container fluid>
                <Form validated={validated} noValidate onSubmit={handleSubmit}>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => props.switchView("MyProfile")}>My Profile</Breadcrumb.Item>
                        <Breadcrumb.Item active>{firstName} {lastName}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col lg={7} >
                            <div className="page-panel" id="profileInfo">
                                <div className="panel-header">
                                    <h2>Profile Information</h2>
                                    {isReadOnlyMode ? <Card.Link onClick={() => setReadOnlyMode(false)}>Edit</Card.Link> : <Card.Link onClick={() => setReadOnlyMode(true)}>Cancel</Card.Link>}
                                </div>
                                <Row>
                                    <Col>
                                        <h3>About</h3>
                                        <Row>
                                            <Form.Label className={isReadOnlyMode ? "" : "required"} column>
                                                First Name
                                            </Form.Label>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    required
                                                    name="firstName"
                                                    value={firstName}
                                                    onChange={e => setFirstName(e.target.value)} readOnly={isReadOnlyMode} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a first name
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column>
                                                Middle Name
                                            </Form.Label>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    name="middleName"
                                                    value={middleName}
                                                    onChange={e => setMiddleName(e.target.value)} readOnly={isReadOnlyMode} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label className={isReadOnlyMode ? "" : "required"} column>
                                                Last Name
                                            </Form.Label>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    required
                                                    name="lastName"
                                                    value={lastName}
                                                    onChange={e => setLastName(e.target.value)} readOnly={isReadOnlyMode} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a last name
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column>
                                                Preferred Name
                                            </Form.Label>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    name="preferredName"
                                                    value={preferredName}
                                                    onChange={e => setPreferredName(e.target.value)} readOnly={isReadOnlyMode} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column>
                                                Prefix
                                            </Form.Label>
                                            {isReadOnlyMode == true ?
                                                <Col>
                                                    <Form.Control
                                                        type="text"
                                                        name="prefix"
                                                        value={prefixNames.filter((i: any) => { return (i.value === prefix); }).length > 0 ?
                                                            prefixNames.filter((i: any) => { return (i.value === prefix); })[0].name : prefix}
                                                        readOnly={isReadOnlyMode} />
                                                </Col>
                                                :
                                                <Col>
                                                    <Form.Select
                                                        aria-label="Prefix"
                                                        name="prefix"
                                                        value={prefix}
                                                        onChange={prefixSelectChange}>
                                                        {prefixList}
                                                    </Form.Select>
                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            <Form.Label column>
                                                Suffix
                                            </Form.Label>
                                            {isReadOnlyMode == true ?
                                                <Col>
                                                    <Form.Control
                                                        type="text"
                                                        name="suffix"
                                                        value={suffixNames.filter((i: any) => { return (i.value === suffix); }).length > 0 ?
                                                            suffixNames.filter((i: any) => { return (i.value === suffix); })[0].name : suffix}
                                                        readOnly={isReadOnlyMode} />
                                                </Col>
                                                :
                                                <Col>
                                                    <Form.Select
                                                        aria-label="suffix"
                                                        name="suffix"
                                                        value={suffix}
                                                        onChange={suffixSelectChange}>
                                                        {suffixList}
                                                    </Form.Select>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                    <Col>
                                        <h3>Contact</h3>
                                        <Row>
                                            <Col lg={4}>
                                                <Form.Label className={isReadOnlyMode ? "" : "required"} column>
                                                    Email Address
                                                </Form.Label>
                                            </Col>  
                                            <Col>
                                                <Form.Control
                                                    id="emailAddress"
                                                    type="text"
                                                    required
                                                    name="emailAddress"
                                                    value={emailAddress}
                                                    onChange={e => setEmailAddress(e.target.value)} readOnly={isReadOnlyMode} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a valid email address
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4}>
                                                <Form.Label column>
                                                    Cell Phone
                                                </Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    id="cellPhoneNumber"
                                                    name="cellPhoneNumber"
                                                    value={cellPhoneNumber}
                                                    onChange={e => setCellPhoneNumber(e.target.value)} readOnly={isReadOnlyMode} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter the cell Phone Number in the format (xxx) xxx-xxxx.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4}>
                                                <Form.Label column>
                                                    Landline Phone
                                                </Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    id="landLineNumber"
                                                    name="landLineNumber"
                                                    value={landLineNumber}
                                                    onChange={e => setLandLineNumber(e.target.value)} readOnly={isReadOnlyMode} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter the Landline Number in the format (xxx) xxx-xxxx.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        { roleName === ROLE_PROVIDER ?
                                        <Row>
                                            <Col lg={4}>
                                                <Form.Label column>
                                                    PCP NPI Identifier
                                                </Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    id="pcpnpiIdentifier"
                                                    name="pcpnpiIdentifier"
                                                    value={pcpnpiIdentifier}
                                                    onChange={e => updatePcpnpiIdentifier(e.target.value)}
                                                    readOnly={isReadOnlyMode} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a valid PCP NPI Identifier.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        :
                                        <></>
                                        }
                                    </Col>
                                </Row>
                                {isReadOnlyMode == false ?
                                    <div className="form-actions">
                                        <Button className="people submit" type="submit" variant="primary">Save</Button>
                                        <Button className='people cancel' variant="secondary" type="button" onClick={() => setReadOnlyMode(true)}>Cancel</Button>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            <div className="page-panel">
                               <MyPatients
                                    switchView={props.switchView} 
                                    permissions={props.permissions}
                                    setSelectedPatientID={props.setSelectedPatientID}
                                />
                            </div>
                        </Col>
                      {/* Second page column */}
                      <Col lg={5}>
                          <div className="page-panel">
                            <section>
                              <h2 className="panel-section">My Sites</h2>
                              {loadSites()}
                            </section>
                            <section>
                              <h2 className="panel-section">Parent Organization</h2>
                              <div className="site-name"><Anchor onClick={(e) => {goToDashBoard(e); }} title="View Org">{healthCenterParent}</Anchor></div>
                              <p>{healthCenterDescription}</p>
                            </section>
                          </div>
                          {healthcareTeamLetSummary.length > 0 && roleName != ROLE_DIGITAL_HEALTH_NAVIGATOR  ?
                          <div className="page-panel">
                              <h2>My Assigned Teamlets</h2>
                              {healthcareTeamLetSummary.length > 0 ?
                              <section>
                                  {teamletList(healthcareTeamLetSummary)}
                              </section>
                              : <p>Not assigned to any teamlets</p>}
                          </div>
                         : null }
                         <div className="page-panel">
                            <MyNextHuddle
                                switchView={props.switchView} 
                                permissions={props.permissions}
                                setHuddleID={props.setHuddleID}
                                setMeetingID={props.setMeetingID}
                                setSelectedTeamletID={props.setSelectedTeamletID}
                            />
                         </div>
                      </Col>
                  </Row>
              </Form>
            </Container>
      
            <Modal show={showSaveModalPrompt}>
                <Modal.Header className="people">
                    <h2>Save Profile?</h2>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to save this Profile?
                </Modal.Body>
                <Modal.Footer>
                    <Button className='people submit' variant="primary" onClick={() => saveProfileConfirmed()}>Save</Button>
                    <Button className='people cancel' variant="secondary" onClick={() => showUpdateConfirmModal(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showErrorModal}>
                <Modal.Header className="error">
                    <h2>Database error</h2>
                </Modal.Header>
                <Modal.Body>
                    {showErrorMsg}
                </Modal.Body>
                <Modal.Footer>
                    <Button className='error submit' variant="primary" onClick={() => setShowErrorModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteTeamletModal}>
                <Modal.Header className="people">
                    <h2>Delete Teamlet?</h2>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this Teamlet?  This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button className='people submit' variant="primary" onClick={() => deleteTeamlet()}>Delete</Button>
                    <Button className='people cancel' variant="secondary" onClick={() => showDeleteModal("", false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>

    );
}

export default MyProfile;
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
const config = require("../../config.json");


function DeleteHuddleModal(props: { showModal: boolean, internalID: string, teamlet: string, name: string, description: string, dateTime: string, toggleDeleteModal: () => void}) {
  const [errorText, setErrorText] = useState("");
  
  /**
  * @name closeModal
  * @description Closes and resets error message
  * @param none
  * @returns 
  */
  function closeModal(){
    setErrorText('');
    props.toggleDeleteModal()
  }

  /**
  * @name deleteHuddle
  * @description Deletes the Huddle
  * @param none
  * @returns 
  */
  async function deleteHuddle() {
    const token = sessionStorage.getItem('ACTIVATEtoken');
      await fetch(`${config.activateServer}:${config.activatePORT}/deleteHuddle`, {
          method: 'POST',
          body: JSON.stringify({ 
            token : token,
            meetingID: props.internalID,
      }),
      headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
      },
      mode : 'cors'
      }).then(response => {
          console.log("GOT HUDDLE DELETE REPLY " + JSON.stringify(response));
          if (response.ok) {
              try {
                  // Call back to refresh
                  props.toggleDeleteModal();
              } catch (err) {
                  setErrorText("Error: " + err);
              }
          } else {
              //  Error returned from data service, display to user
              response.json().then((data : any) => {            
                  //  Show Modal
                  setErrorText('Error deleting huddle');
              });
          }
      });
  }

  return (
    <Modal show={props.showModal}>
      <Modal.Header className="error">
          <h2>Delete Huddle</h2>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this huddle?</p>
        <Form>
          <Row>
            <Col><p>Teamlet</p></Col>
            <Col>{props.teamlet}</Col>
          </Row>
          <Row>
            <Col><p>Name</p></Col>
            <Col>{props.name}</Col>
          </Row>
          <Row>
            <Col><p>Description</p></Col>
            <Col>{props.description}</Col>
          </Row>
          <Row>
            <Col><p>Date and Time</p></Col>
            <Col>{new Date(props.dateTime).toLocaleString()}</Col>
          </Row>
          {errorText.length > 0 ?
          <Row>
            <Col>{errorText}</Col>
          </Row>
          : <></>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="error submit" onClick={() => deleteHuddle()}>Delete</Button>
        <Button
          className='system cancel'
          id="cancelButton"
          variant="secondary"
          type="button"
          onClick={() => closeModal()}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteHuddleModal;

/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState, useRef } from "react";
import PermissionService from '../../../Services/PermissionService';
import { Row, Col, Breadcrumb, Form, Anchor } from "react-bootstrap";
import AssignedTeamletPatients from "./AssignedTeamletPatients";
import TeamletMembers from "./TeamletMembers";
import HuddleSchedule from "./HuddleSchedule";

const config = require("../../../config.json");
function TeamletDetails(props: {
    switchView: (view: string) => void;
    setHuddleID: (huddleID: string) => void;
    setMeetingID: (meetingID: string) => void;
    permissions: PermissionService;
    teamletID: string;
    setSelectedPatientID: (Id: string) => void;
    setSelectedTeamletID: (teamletId: string) => void;
    setReturnToView: (view: string) => void;
  }) 
{

//  Reference Grid for spinner
const dgPatients = useRef<any>(null);
const token = sessionStorage.getItem('ACTIVATEtoken');
const [teamletName, setTeamletName] = useState('');
const [organizationName, setOrganizationName] = useState<any[]>([]);
const [teamletDetails, setTeamletDetails] = useState<any>([]);
const [nextHuddleName, setNextHuddleName] = useState('');
const [nextHuddleDate, setNextHuddleDate] = useState('');
const [nextHuddleID, setNextHuddleID] = useState('');
const [nextmeetingID, setNextMeetingID] = useState('');
const [isMemberOfTeamlet, setIsMemberOfTeamlet] = useState(false);

//  Init
useEffect(() => {
  //  Abort controller to close long fetch calls
  const patientController = new AbortController();

  //  Get Patients
  const fetchTeamletDetails = async () => {
      try {
        dgPatients.current?.instance.beginCustomLoading();

        await fetch(`${config.activateServer}:${config.activatePORT}/getTeamletDetailsByID`, {signal: patientController.signal,
            method: 'POST',
            body: JSON.stringify({ token: token, teamID: props.teamletID}),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(res =>{
          if (!res.ok) {
            throw Error('Could not fetch the data for the resource');
          }
          return res.json();
        }).then(teamLetDetails =>{
          setIsMemberOfTeamlet(() => {return isMember(teamLetDetails)});

          setTeamletDetails(teamLetDetails);
          setOrganizationName(teamLetDetails.parentHealthCenter.name);
          setTeamletName(teamLetDetails.name);
          setNextHuddleName(teamLetDetails.nextHuddle.name);
          setNextHuddleID(teamLetDetails.nextHuddle.internalID)
          setNextMeetingID(teamLetDetails.nextHuddle.nextMeeting)
          setNextHuddleDate(new Date(teamLetDetails.nextHuddle.nextHuddleTime).toLocaleDateString() + ' @ ' + new Date(teamLetDetails.nextHuddle.nextHuddleTime).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}));

        }).catch(err =>{
          if (err.name === 'AbortError') {
            //console.log('fetch aborted');
          }
        });

        //  Stop spinner
        dgPatients.current?.instance.endCustomLoading();
      } catch (err) {
          //  Stop spinner
          dgPatients.current?.instance.endCustomLoading();
      }
  };

  // call the function
  fetchTeamletDetails().catch(console.error);

  //  Clean-up useEffect
  return () => {
      patientController.abort();
  };
}, []);

/**
* @name isMember
* @description Checks if user is a member of the Teamlet
* @param none
* @returns 
*/
function isMember(teamLet: any){

  //  Check for membership
  if (teamLet != null && teamLet.members){
    let indexOf = teamLet.members.indexOf(props.permissions.myInternalID)

    //  User found, enable adds
    if (indexOf >= 0){
      setIsMemberOfTeamlet(() => {return true});
      return true;
    } else {
      setIsMemberOfTeamlet(() => {return false});
      return false;
    }
  } else {
    setIsMemberOfTeamlet(() => {return false});
    return false;
  }
}

/**
* @name getPathwayLabel
* @description Get the Pathway Label for the About
* @param none
* @returns 
*/
function getPathwayLabel() {
  let pathwayCount = 0;
  
  if (teamletDetails.digitalHealthPathways != null) {
    pathwayCount = teamletDetails.digitalHealthPathways.length;
  }
 
  return (
    <>
      {pathwayCount > 1 ? 'Pathways' : 'Pathway'}
    </>
  );
}

/**
* @name getPathways
* @description Get the list of Pathways for the About
* @param none
* @returns 
*/
function getPathways() {
  const pathways = teamletDetails.digitalHealthPathways;
  
  if (pathways != null && pathways.length > 0) {
    return (
      <>{pathways.map((pw:any) => (<>{pw.name}<br/></>))}</>
    );
  } else {
    return (
      <><label className='titlesDisabled'>None Assigned</label></>
    );
  }
}

/**
* @name returnToDashBoard
* @description Returns to Dashboard
* @param none
* @returns 
*/
function returnToDashBoard() {
  props.switchView("dashboard");
}

/**
* @name goToHuddleDetails
* @description Go to Huddle Details
* @param none
* @returns 
*/
function goToHuddleDetails() {
  props.setHuddleID(nextHuddleID); 
  props.setMeetingID(nextmeetingID)
  props.switchView("huddledetails")
}

/**
* @name editTeamlet
* @description Edits the Teamlet
* @param none
* @returns 
*/
function editTeamlet(){
  props.setReturnToView("dashboard");
  props.setSelectedTeamletID(teamletDetails.internalID);
  props.switchView("editTeamlet");
}

//  Check if User can edit a huddle
function canEditTeamlets(){
  if (props.permissions.checkPermissionSetting("REQUIRES_MEMBERSHIP", "READ") === true){
    if (isMemberOfTeamlet){
      return props.permissions.checkFunctionPermission("DASHBOARD", "TEAMLET", "UPDATE");
    } else {
      return false;
    } 
  } else {
    return props.permissions.checkFunctionPermission("DASHBOARD", "TEAMLET", "UPDATE");
  }
}

//  Check if User can edit a huddle
function canEditHuddles(){
  return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "HUDDLE_SCHEDULE", "UPDATE");
}

//  Check if User can view Huddles
function canViewHuddles(){
  return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "HUDDLE_SCHEDULE", "READ");
}

//  Check if User can view Huddle Schedule
function viewHuddleSchedule(){
  return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "HUDDLE_SCHEDULE", "READ");
}

return (
      <Form>
        <Breadcrumb>
            <Breadcrumb.Item onClick={() => returnToDashBoard()}>{organizationName}</Breadcrumb.Item>
            <Breadcrumb.Item active>{teamletName}</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          {viewHuddleSchedule() === true || canViewHuddles() === true ?
          <Col lg={7}>
            {viewHuddleSchedule() === true ?
            <div className="page-panel">
              <AssignedTeamletPatients
                setMeetingID={props.setMeetingID}
                setHuddleID={props.setHuddleID}
                setSelectedPatientID={props.setSelectedPatientID}
                switchView={props.switchView} 
                permissions={props.permissions}
                teamletID={props.teamletID}
                isMemberOfTeamLet={isMemberOfTeamlet}/>
            </div>
            : null }
            {canViewHuddles() === true ?
            <div className="page-panel">
              <HuddleSchedule 
                permissions={props.permissions}
                switchView={props.switchView}
                teamletID={props.teamletID}
                teamletName={teamletName}
                setHuddleID={props.setHuddleID}
                setMeetingID={props.setMeetingID}
                setSelectedTeamletID={props.setSelectedTeamletID}
                isMemberOfTeamLet={isMemberOfTeamlet}/>
            </div>
            : null}
          </Col>
          : null}
          {/* Second page column */}
          <Col lg={5}>
            <div className="page-panel">
              <div className="panel-header">
                <h2>About</h2>
                  <Anchor href="#" onClick={() => editTeamlet()} hidden={!canEditTeamlets()} >
                      Edit
                  </Anchor>
              </div>
              <section>
                <Row>
                    <Col md={6}>
                      <Form.Label>Teamlet Name</Form.Label>
                      <p className="label.form-label">{teamletName}</p>
                    </Col>
                    <Col md={6}>
                      <Form.Label>Site</Form.Label>
                      <p className="label.form-label">{organizationName}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                      <Form.Label>{getPathwayLabel()}</Form.Label>
                      <p className="label.form-label">{getPathways()}</p>
                    </Col>
                    {canEditHuddles() ?
                    <Col md={6}>
                        <Form.Label>Next Huddle</Form.Label><br/>
                        <Anchor onClick={() => goToHuddleDetails()}><p>{nextHuddleName}</p></Anchor>
                        <Anchor onClick={() => goToHuddleDetails()}><p>{nextHuddleDate}</p></Anchor>
                    </Col>
                    : null}
                  </Row>
                </section>
              </div>
              <div className="page-panel">
                <TeamletMembers 
                  switchView={props.switchView}
                  setSelectedTeamletID={props.setSelectedTeamletID}
                  permissions={props.permissions}
                  teamletID={props.teamletID}
                  setReturnToView={props.setReturnToView}
                  isMemberOfTeamLet={isMemberOfTeamlet}/>
              </div>
            </Col>
        </Row>
      </Form>
  );
}
export default TeamletDetails;
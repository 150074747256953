/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { Container, Breadcrumb, Form, Row, Col, Button } from 'react-bootstrap';
const config = require('../../../config.json');

function PatientRegistrationAdd(props: {
    switchView: (newView: string) => void,
    patientID: string
  }) {
  
  const [patientFirstName, setPatientFirstName] = useState('');
  const [patientMiddleName, setPatientMiddleName] = useState('');
  const [patientLastName, setPatientLastName] = useState('');
  const [patientDOB, setPatientDOB] = useState('');
  const [patientUsername, setPatientUsername] = useState('');
  const [badSubmissionError, setBadSubmissionError] = useState("");

  useEffect(() => {
    // Make call for the patient details based on their id
    getPatientInfo();
  }, []);

  async function getPatientInfo() {
    const token = sessionStorage.getItem('ACTIVATEtoken');
    
    await fetch(`${config.activateServer}:${config.activatePORT}/getPatientSummaryById`, {
        method: 'POST',
        body: JSON.stringify({ token : token, patientID: props.patientID }),
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        mode : 'cors'
    }).then(async response => {
        //  Check for Success
        if (response.ok)
        {
            try {
                const _json = await response.json();
                setPatientFirstName(_json.firstName);
                setPatientMiddleName(_json.middleName);
                setPatientLastName(_json.lastName);
                setPatientDOB(_json.dateOfBirth);
            } catch (err) {
                console.log("ERROR " + err);
            }
        } else {
            // ToDo:  Handle non-200s
            console.log("Received HTTP status" + response.status.toString());
        }
    }).catch(err =>{
        console.log("Error in getting unregistered patients: " + err);
    });
  }

  async function registerPatient() {
    // First check if an email was added
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(patientUsername) === false) {
      setBadSubmissionError('Error: Invalid email format entered.');
    } else {

      const token = sessionStorage.getItem('ACTIVATEtoken');

      await fetch(`${config.activateServer}:${config.activatePORT}/registerPatient`, {
          method: 'POST',
          body: JSON.stringify({ token : token, patientID: props.patientID, patientUsername }),
          headers: { 
              'Content-Type': 'application/json',
              'Accept': 'application/json',
          },
          mode : 'cors'
      }).then(async response => {
          //  Check for Success
          // console.log('OK? ' + response.ok);
          if (response.ok)
          {
              try {
                  const _json = await response.json();
                  props.switchView('patientRegistration');
              } catch (err) {
                  console.log("ERROR " + err);
              }
          } else {
              // ToDo:  Handle non-200s
              console.log("Received HTTP status" + response.status.toString());
              const _json = await response.json();
              setBadSubmissionError(`Error registering patient: ${_json.message}`);
          }
      }).catch(err =>{
          console.log("Error in getting unregistered patients: " + err);
      });
    }
  }

  return (
    <Container fluid>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => props.switchView("configuration")}>Configuration</Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => props.switchView('patientRegistration')}>Patient Registration</Breadcrumb.Item>
          <Breadcrumb.Item active>{`${patientFirstName} ${patientLastName}`}</Breadcrumb.Item>
        </Breadcrumb>

        <Row>
        <Col md={12} lg={7} >
          <div className="page-panel profile-information">
            <Row>
              <Col md={6}>
                <section>
                  <Row>
                    <Form.Label column>First Name</Form.Label>
                    <Col><p className="form-control-plaintext">{patientFirstName}</p></Col>
                  </Row>
                  <Row>
                      <Form.Label column>Middle Name</Form.Label>
                      <Col><p className="form-control-plaintext">{patientMiddleName}</p></Col>
                  </Row>
                  <Row>
                      <Form.Label column>Last Name</Form.Label>
                      <Col><p className="form-control-plaintext">{patientLastName}</p></Col>
                  </Row>
                  <Row>
                      <Form.Label column>Date of Birth</Form.Label>
                      <Col><p className="form-control-plaintext">{patientDOB}</p></Col>
                  </Row>
                  <hr />
                  <Form.Group className="form-group" controlId="firstName">
                    <Form.Label className="required">ACTIVATE Username</Form.Label>
                      <Form.Control
                          type="email"
                          placeholder="Enter patient's email address for mobile app"
                          required
                          onChange={e => setPatientUsername(e.target.value)}
                          value={patientUsername} />
                      <Form.Control.Feedback type="invalid">
                          Please enter the patient&#39;s email.
                      </Form.Control.Feedback>
                  </Form.Group>
                </section>
              </Col>
              {badSubmissionError.length > 0 ? 
                <p className="validation-error">{badSubmissionError}</p>
                : <></>
              }
              <div className="form-actions">
                <Button
                    className="system submit"
                    variant="primary"
                    id="submitButton"
                    onClick={() => registerPatient()}>
                        Save
                </Button>
                <Button className='system cancel'
                    id="cancelButton"
                    variant="secondary"
                    type="button"
                    onClick={() => props.switchView('patientRegistrationSelect')}>
                    Cancel
                </Button>
              </div>
            </Row>
          </div>
        </Col>
      </Row>

      </Container>
  );
}

export default PatientRegistrationAdd;

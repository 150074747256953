export const UNSPECIFIED = "UNSPECIFIED";
export const SUFFIXLOOKUP = "Ordinal";
export const PREFIXLOOKUP = "Prefix";
export const ACTIVATEADMINISTRATOR = "ACTIVATE Administrator";
export const ROLE_ACTIVATEADMINISTRATOR = "ROLE_ACTIVATE_ADMINISTRATOR";
export const SYSTOLIC = "Systolic";
export const PULSERATE = "PulseRate";
export const DIASTOLIC = "Diastolic";
export const BLOODGLUCOSE = "BloodGlucose";
export const BLOODPRESSURE = "BloodPressure";
export const ROLE_HEALTH_COACH = "ROLE_HEALTH_COACH";
export const ROLE_PROVIDER = "ROLE_PROVIDER";
export const ROLE_DELEGATED_HEALTH_STAFF = "ROLE_DELEGATED_HEALTH_STAFF";
export const ROLE_ORGANIZATION_SUPER_USER = "ROLE_ORGANIZATION_SUPER_USER";
export const ROLE_DIGITAL_HEALTH_NAVIGATOR = "ROLE_DIGITAL_HEALTH_NAVIGATOR";
export const MYPATIENTS = "MyPatients";
export const ALLPATIENTS = "AllPatients";
export const UNENROLLED = "Unenrolled";
export const NONEASSIGNED = "None Assigned";
export const ABOVE="Above";
export const BELOW="Below";
export const WITHIN="Within";
// Used for patient source
export const HEALTH_CENTER = "HEALTH_CENTER";
export const ELECTRONIC_HEALTH_RECORD = "ELECTRONIC_HEALTH_RECORD";
export const STAFFPCPID = "StaffPCPID";

/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Nav} from 'react-bootstrap/';
import Navbar from '../Patient/PatientNavbar';
import Sidebar from '../Shared/Sidebar';
import '../Administrator/AdministratorContainer.css';
import PermissionService from '../../Services/PermissionService';
import PatientDashboard from './PatientDashboard';
import AboutPage from '../AboutPage';
import HelpPage from '../HelpPage';
import MyProfile from './MyProfile';
import Timeout from '../Shared/Timeout';
const config = require('../../config.json');

interface CustomLocation {
    username : string,
    role : string,
    permissions : any,
    displayRoleName: string,
}

function PatientContainer(props: {switchView: (newView : string) => void;}) {
    const location = useLocation().state as CustomLocation;
    const [roleName, setRoleName] = useState("");
    const [username, setUsername] = useState("");
    const [logout, setLogout] = useState(false);
    const [viewMode, setViewMode] = useState("dashboard");
    const [sidebarExpanded, setSidebarExpanded] = useState(true);
    const [permissions, setPermissions] = useState(new PermissionService());
    const [displayRoleName, setDisplayRoleName] = useState("");
    
    useEffect(() => {
        //Load the correct page upon refresh
        /*if (sessionStorage.getItem('restorePage')) {
            setViewMode(sessionStorage.getItem('restorePage') || "");
        }*/

        // Check if we have location.  If we dont have location from a new tab or manually entered URL we redirect to login.
        if (location) {
            setUsername(location.username);
            setRoleName(location.role);
            setDisplayRoleName(location.displayRoleName);
            permissions.loadPermissions(location.permissions);
        } else {
            //setLogout(true);
        }

    }, [location]);

    /**
     * @name switchView
     * @description This function is passed down to all the components and controls which component
     * appears in the main view.  See currentView variable below for details
     */
    function switchView(newView: string) {
        setViewMode(newView);
        //sessionStorage.setItem('restorePage', newView);
    }

    function expandCollapseSidebar() {
        setSidebarExpanded(!sidebarExpanded);
    }

    /**
    * @name Logout
    * @description Logouts out the User
    * @param
    * @returns {json}
    */
    async function Logout() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        try {
            await fetch(`${config.activateServer}:${config.activatePORT}/logout`, {
                method: 'POST',
                body: JSON.stringify({ token: token }),
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode : 'cors'
            });
        } catch (err) {
            console.log("ERROR " + err);
        }

        //  Clear storage
        sessionStorage.removeItem('authenticated');
        sessionStorage.removeItem('ACTIVATEtoken');
        //sessionStorage.removeItem('restorePage');
        // Set Logout   
        setLogout(true);
    }

    if (logout) {
        sessionStorage.removeItem('authenticated');
        sessionStorage.removeItem('ACTIVATEtoken');
        //sessionStorage.removeItem('restorePage');
        return <Navigate to="/login" />
    }
    let currentView;
    //console.dir(permissions)
    switch (viewMode) {
        case "dashboard":
            currentView = <PatientDashboard />
            break;
        case "MyProfile":
            currentView = <MyProfile switchView={switchView} />
            break;
        case "about":
            currentView = <AboutPage switchView={switchView} username={username} />
            break;
        case "help":
            currentView = <HelpPage switchView={switchView} username={username} />
            break;
    }
  
    return (
        <div>
            <Navbar
                expandCollapseSidebar={expandCollapseSidebar}
                roleName={roleName}
                username={username}
                switchView={switchView}
                displayRoleName={displayRoleName}
            />
            <div className="app-body-content">
                <div className="page-content">
                    {currentView}
                </div>
            </div>
            <Timeout logout={Logout}/>
        </div>
    )
}

export default PatientContainer
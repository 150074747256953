import React, { useEffect, useState, useRef } from 'react';
import PermissionService from '../../../Services/PermissionService';
import { DataGrid, Column, Selection, Sorting, Paging} from 'devextreme-react/data-grid';
import { Button, Form, Container, Row, Col, Modal, Anchor } from 'react-bootstrap';
import { ModalErrorMessage } from '../MessageBox/ModalErrorMessage';
import {Icon} from '@ailibs/feather-react-ts';

const config = require("../../../config.json");

function AssignedTeamletPatients(props:
{
    switchView: (newView: string) => void,
    permissions: PermissionService,
    teamletID: string,
    setSelectedPatientID: (newId: string) => void,
    setHuddleID: (huddleId: string) => void,
    setMeetingID: (meetingId: string) => void,
    isMemberOfTeamLet: boolean
}){

//  Reference Grid for spinner
const dgAssignedPatients = useRef<any>(null);
const token = sessionStorage.getItem('ACTIVATEtoken');
const [assignedPatientsToTeamlet, setAssignedPatients] = useState<any[]>([]);
const [assignedPatientToDeleteID, setAssignedPatientToDeleteID] = useState("");
const [teamIdToDeleteID, setTeamIdToDeleteID] = useState("");

//  Error Modal
const [showErrorModal, setShowErrorModal] = useState(false);
const [errorMessageTitle, setErrorMessageTitle] = useState("");
const [errorMessage, setErrorMessage] = useState("");
const [errorServerMessage, setErrorServerMessage] = useState("");
const [errorUserMessage, setErrorUserMessage] = useState("");

//  Unassign Modal
const [patientName, setPatientName] = useState("");
const [pathway, setPathway] = useState("Unknown");
const [mrn, setMRN] = useState("Unknown");
const [dateOfBirth, setDateOfBirth] = useState("Unknown");

const [showUnassignModal, setShowUnassignModal] = useState(false);
const [refreshData, setRefreshData] = useState(true);


//  Init
useEffect(() => {
  //  Abort controller to close long fetch calls
  const assignedPatientController = new AbortController();

  //  Get Patients
  const fetchTeamletDetails = async () => {
      try {
        dgAssignedPatients.current?.instance.beginCustomLoading();

        await fetch(`${config.activateServer}:${config.activatePORT}/getPatientsByTeamletID`, {signal: assignedPatientController.signal,
            method: 'POST',
            body: JSON.stringify({ token: token, teamletID: props.teamletID}),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(res =>{
          if (!res.ok){
            throw Error('Could not fetch the data for the resource')
          }
          return res.json();
        }).then(assignedPatients =>{
          //@ts-ignore
          setAssignedPatients(assignedPatients);
        }).catch(err =>{
          if (err.name === 'AbortError'){
            //console.log('fetch aborted')
          } else {
            assignedPatientController.abort();
          }
        })
    
        //  Stop spinner
        dgAssignedPatients.current?.instance.endCustomLoading();
      } catch (err) {
          //  Stop spinner
          dgAssignedPatients.current?.instance.endCustomLoading();
      }
  }

  // call the function
  fetchTeamletDetails().catch(console.error);

  //  Clean-up useEffect
  return () => {
    assignedPatientController.abort();
  }
}, [refreshData])


/**
* @name renderPatientName
* @description Properly renders the Patient's name for the grid
* @param e:any
* @returns 
*/
function renderPatientName(e:any){
  let patientName = <></>

  if (e.data != null){
    if (canViewPatient()){
      patientName = <><Anchor className='plusButton' title="Edit Patient" onClick={(e) => { viewPatientDetails(e) }} id={e.data.internalID}>{e.data.firstName + ' ' + e.data.middleName + ' ' + e.data.lastName}</Anchor></>
    } else {
      patientName = <>{e.data.firstName + ' ' + e.data.middleName + ' ' + e.data.lastName}</>
    }

  }
  return (
      <p className="actions">{patientName}</p>
  )
}

/**
* @name renderNextHuddle
* @description Hyperlink to next huddle
* @param e:any
* @returns 
*/
function renderNextHuddle(e:any){
  let nextHuddle = '(none)';

  if (e.data != null){
      let nextHuddleMeetingId = e.data.nextHuddleMeeting;
      let meetingId = e.data.nextHuddle;  

      if (e.data.nextHuddleMeetingTime != null && e.data.nextHuddleMeetingTime != ''){
          nextHuddle = new Date(e.data.nextHuddleMeetingTime).toLocaleDateString() + ' ' + new Date(e.data.nextHuddleMeetingTime).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}).toLowerCase();
          
          //  Check if allowed to Edit
          if (!canEditAssignedPatients() == true){
            return (
                <p>{nextHuddle}</p>
            )
          } else {
            return (
                <><Anchor onClick={(e) => { props.setHuddleID(meetingId); props.setMeetingID(nextHuddleMeetingId); props.switchView("huddledetails"); }} title="Edit Huddle" >{nextHuddle}</Anchor></>
            )
          }
      } else {
        return (
            <p>{nextHuddle}</p>
        )
    }
  } else {
      return (
          <p>{nextHuddle}</p>
      )
  }
}

/**
* @name formatLastVitalsDate
* @description Format Last Vitals Date
* @param e:any
* @returns 
*/
function formatLastVitalsDate(e:any){
  let vitalsDate = <>(none)</>

  //  Check for data and a last vitals time
  if (e.data != null && e.data.lastVitalsTime != null){
    var assignedDate = new Date(e.data.lastVitalsTime).toLocaleDateString() + ' ' + new Date(e.data.lastVitalsTime).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}).toLowerCase();
    vitalsDate = <>{assignedDate}</>
  }
  return (
      <>{vitalsDate}</>
  )
}

/**
* @name deleteClick
* @description Prompts to Delete assigned Patient
* @param id : string, name : string, mrn : string, dob: string, pathway: string, meetingID :string
* @returns 
*/
function deleteClick(id : string, name : string, mrn : string, dob: string, pathway: string, meetingID :string) {
  setPatientName(() => {return name});
  setMRN(() => {return mrn});
  setDateOfBirth(() => {return dob});
  setPathway(() => {return pathway});
  setTeamIdToDeleteID(() => {return props.teamletID});
  showDeleteModal(id, true)
}

/**
* @name deleteClick
* @description Shows Delete Prompt Pop-up
* @param id : string, toggle : boolean
* @returns 
*/
function showDeleteModal(id : string, toggle : boolean) {
  setAssignedPatientToDeleteID(id);
  setShowUnassignModal(toggle);
}

/**
* @name deleteAssignedPatient
* @description Deletes the assigned Patient
* @param none
* @returns 
*/
async function deleteAssignedPatient(){
      const token = sessionStorage.getItem('ACTIVATEtoken');
      try {
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/removeAssignedPatientFromTeamlet`, {
            method: 'POST',
            body: JSON.stringify({ 
                token : token,
                assignedpatientid : assignedPatientToDeleteID,
                teamid : teamIdToDeleteID
            }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        })

        //  Check for succes
        if (response.status === 200){
          setAssignedPatientToDeleteID("");
          setRefreshData((e) => {return !e})
        } else {
          response.json().then(data => {
            //  Create Message
            const _statusCode = response.status.toString();
            const _statusmessage = data.message;
            setErrorMessageTitle("Error Unassigning Patient")
            setErrorMessage("An error occurred unassigning the Patient.")
            setErrorServerMessage(_statusmessage + " (Code: " + _statusCode + ")");
            setErrorUserMessage("");
            
            //  Show Modal
            setShowErrorModal(true);
          });
          
        }

      } catch(err) {
          console.log("ERROR deleting enrollment " + JSON.stringify(err));
      }

      //  Hide Pop-up
      showDeleteModal('', false);
}

/**
* @name viewPatientDetails
* @description View Patient from hyperlink onClick handler
* @param e: any
* @returns 
*/
function viewPatientDetails(e: any) {
  var patientID = e.currentTarget.id

  //  Check for found Organization
  if (e != null) {
      props.setSelectedPatientID(patientID);
      props.switchView("patientInformation");
  }
}

//  PERMISSION HOOKS
//  Check if User can delete assigned Patients
function canViewPatient(){
  return props.permissions.checkPermissionSetting("PATIENT_PANEL", "READ")
}

//  Check if User can delete assigned Patients
function canDeleteAssignedPatients(){
  if (props.permissions.checkPermissionSetting("REQUIRES_MEMBERSHIP", "READ") === true){
    if (props.isMemberOfTeamLet){
      return props.permissions.checkSubfunctionPermission("DASHBOARD", "TEAMLET", "ASSIGN_PATIENTS", "DELETE")
    } else {
      return false;
    }
  } else {
    return props.permissions.checkSubfunctionPermission("DASHBOARD", "TEAMLET", "ASSIGN_PATIENTS", "DELETE")
  }
}

//  Check if User can update assigned Patients
function canEditAssignedPatients(){
  return props.permissions.checkSubfunctionPermission("DASHBOARD", "TEAMLET", "ASSIGN_PATIENTS", "CREATE")
}

//  Delete Icon in assigned Patients
function getDeleteIcon(e:any){
  let _id = e.data.internalID;
  let _teamId = e.data.huddleMeeting;
  let _name = e.data.firstName + ' ' + e.data.lastName;
  let _medicalRecordNumber = e.data.medicalRecordNumber;
  let _dob = '';
  if (e.data.dateOfBirth != null){
    _dob = new Date(e.data.dateOfBirth).toLocaleDateString();
  }
  
  let _pathways = 'TBD';
  if (e.data.digitalHealthPathwayNames != null){
    _pathways = ''
    for (let i = 0; i < e.data.digitalHealthPathwayNames.length; i++) {
      _pathways += e.data.digitalHealthPathwayNames[i] + ' ';
    }  
  }

    return (
      <p className="actions">
          <Anchor onClick={(e) => {deleteClick(_id, _name, _medicalRecordNumber, _dob, _pathways, _teamId);e.preventDefault();}} title="Remove" hidden={!canDeleteAssignedPatients()}><Icon name="trash-2" size="18" className="action-icon" data-internalid={_id} /></Anchor>
      </p>
    )
  }

return (
      <Container fluid>
        <div>
              <section>
                <h2>Assigned Patients</h2>
              </section>
              <DataGrid id="grid-container"
                  ref={dgAssignedPatients}
                  dataSource={assignedPatientsToTeamlet}
                  showColumnLines={false} 
                  showRowLines={false}
                  showBorders={false}
                  focusStateEnabled={false}
                  focusedRowEnabled={false}
                  autoNavigateToFocusedRow={false}
                  allowColumnResizing={true} 
                  showColumnHeaders={true}
                  width={'100%'}
                  noDataText='No Assigned Patients to this Teamlet'>
                    <Selection mode="none" />
                    <Sorting mode="single" />    
                    <Paging defaultPageSize={5} />
                    <Column dataField="internalID" caption="Id" visible={false} />
                    <Column width={'25%'} dataField="firstName" caption="Name" cellRender={renderPatientName} />
                    <Column width={'15%'} dataField="medicalRecordNumber" caption="MRN" />
                    <Column width={'25%'} dataField="MRN" alignment={'center'} caption="Next Huddle" cellRender={renderNextHuddle} />
                    <Column width={'25%'} dataField="lastVitalsTime" caption="Last Vitals Date" alignment="center" cellRender={formatLastVitalsDate} />
                    <Column width={'75'} caption="Action" alignment="center" visible={canDeleteAssignedPatients()} cellRender={e => getDeleteIcon(e)} />
              </DataGrid>
        </div>

        <Modal show={showUnassignModal}>
            <Modal.Header className="error">
                <h2>Unassign Patient From Teamlet</h2>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-body">
                <Form>
                  <Row>
                    <Col>
                        <p>Patient Name</p>
                    </Col>
                    <Col>
                      {patientName}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                        <p>Digital Health Pathway</p>
                    </Col>
                    <Col>
                      {pathway}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>MRN</p>
                    </Col>
                    <Col>
                      {mrn}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>Date of Birth</p>
                    </Col>
                    <Col>
                      {dateOfBirth}
                    </Col>
                  </Row>
                </Form>
                <br/>
                Are you sure you want to unasssign this Patient from this Teamlet? They will be removed from any future scheduled huddles for this Teamlet, but will remain in their current Digital Health Pathways.
              </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="error submit" variant="primary" onClick={() => deleteAssignedPatient()}>Remove</Button>
                <Button className="system cancel" variant="secondary" onClick={() => showDeleteModal('', false)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
        <ModalErrorMessage   
                showErrorModal={showErrorModal} 
                errorMessageTitle={errorMessageTitle} 
                errorMessage={errorMessage} 
                errorServerMessage={errorServerMessage} 
                errorUserMessage={errorUserMessage}
                messageType="error" 
                setShowErrorModal={setShowErrorModal}  
        />
      </Container>
  )
}

  export default AssignedTeamletPatients
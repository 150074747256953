import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Breadcrumb, Form, Container } from "react-bootstrap";

import PermissionService from '../../../Services/PermissionService';
import AddedPatients from './AddedPatients';
import HuddleInfo from './HuddleInfo';
import TeamletHuddleHistory from './TeamletHuddleHistory';
import { fetchHuddleDetails } from '../../../Services/HuddleServices';
import { getTeamletByID } from "../../../Services/TeamletServices";
//const config = require("../../../config.json");

function HuddleDetails(props: {
  switchView: (newView: string) => void;
  permissions: PermissionService;
  huddleID: string;
  meetingID: string;
  teamletID: string;
  setHuddleID: (huddleID: string) => void;
  setMeetingID: (meetingID: string) => void;
  setSelectedPatientID: (patientID: string) => void;
  setSelectedTeamletID: (teamletID: string) => void;
}) {

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessageTitle, setErrorMessageTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorServerMessage, setErrorServerMessage] = useState("");
  const [errorUserMessage, setErrorUserMessage] = useState("");
  const [huddleName, setHuddleName] = useState('');
  const [teamletName, setTeamletName] = useState('');
  const [huddleDetails, setHuddleDetails]: any = useState(null);
  const [isMemberOfTeamlet, setIsMemberOfTeamlet] = useState(false);


  //  Init
  useEffect(() => {

    if ((props.huddleID !== null && props.huddleID !== undefined) && (props.meetingID !== null && props.meetingID !== undefined)) {
      //  Call to service
      const fetchHuddle: any = async () => await fetchHuddleDetails(props.huddleID).then(data => {
        //  Check for null, if call fails, data will be null
        if (data != null) {
          setHuddleName(data.name);
          setHuddleDetails(data);
          props.setSelectedTeamletID(data.healthcareTeam);


          fetchTeamlet(data.healthcareTeam);
        } 
      }).catch((error: any) => {
        setErrorMessageTitle("Error Loading Huddle Data");
        setErrorMessage("An error occurred loading huddle data .");
        setErrorServerMessage("");
        setErrorUserMessage("");
        showErrorMessage(true);
      });

      //  Fetch Huddle for UI
      fetchHuddle();
    }

  }, [props.huddleID, props.meetingID]);

  //  Fetch Teamlet Details
  const fetchTeamlet: any = async (teamletID: string) => await getTeamletByID(teamletID).then(teamLetDetails => {
    setTeamletName(teamLetDetails.name);
    setIsMemberOfTeamlet(() => { return isMember(teamLetDetails); });
  });

/**
* @name isMember
* @description Checks if user is a member of the Teamlet
* @param none
* @returns 
*/
function isMember(teamLet: any) {
  //  Check for membership
  if (teamLet != null && teamLet.members) {
    if (teamLet.members.some((member: any) => member.internalID === props.permissions.myInternalID)) {
      setIsMemberOfTeamlet(() => {return true; });
      return true;
    } else {
      setIsMemberOfTeamlet(() => {return false; });
      return false;
    }
  } else {
    setIsMemberOfTeamlet(() => {return false; });
    return false;
  }
}

/**
* @name formatHuddleMeetingData
* @description  Formats the Huddle meeting date time
* @param  
* @returns 
*/
function formatHuddleMeetingData(huddleDetails: any) {
  const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  if (huddleDetails === undefined && huddleDetails === null) { return; }
  if (huddleDetails.meetings === undefined && huddleDetails.meetings === null) { return; }

  const headingTime = new Date(huddleDetails.meetings[0].meetingTime).toLocaleTimeString().toLowerCase();
  const huddleHeading = `${huddleName} (${new Date(huddleDetails.meetings[0].meetingTime).toLocaleDateString().toLowerCase()} ${weekday[new Date(huddleDetails.meetings[0].meetingTime).getDay()]} @ ${headingTime.slice(0, headingTime.length - 6)} ${headingTime.substring(headingTime.length - 2, headingTime.length)})`;
  return (<>{huddleHeading}</>);
}

/**
* @name showErrorMessage
* @description  Error Pop-up
* @param  
* @returns 
*/
function showErrorMessage(toggle: boolean) {
  setShowErrorModal(toggle);
}

function editHuddle() {
  props.setHuddleID(props.huddleID);
  props.setMeetingID(props.meetingID);
  props.switchView('editHuddle');
}

  return (

    <div>
      <Container fluid>
        <Form>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => props.switchView("TeamletDetails")}>{teamletName}</Breadcrumb.Item>
            <Breadcrumb.Item active>{huddleDetails !== undefined && huddleDetails !== null ? formatHuddleMeetingData(huddleDetails) : null}
            </Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col lg={7} >
              <div className="page-panel">
                <AddedPatients
                  permissions={props.permissions}
                  huddleID={props.huddleID}
                  meetingID={props.meetingID}
                  setMeetingID={props.setMeetingID}
                  switchView={props.switchView}
                  teamletID={props.teamletID}
                  setHuddleID={props.setHuddleID}
                  setSelectedPatientId={props.setSelectedPatientID}
                  isMemberOfTeamLet={isMemberOfTeamlet}
                />
              </div>
            </Col>
            <Col lg={5}>
              <div className="page-panel">
                <HuddleInfo
                  permissions={props.permissions}
                  huddleID={props.huddleID}
                  switchView={props.switchView}
                  goToEditHuddle={editHuddle}
                  isMemberOfTeamLet={isMemberOfTeamlet}
                />
              </div>
              <div className="page-panel">
                <TeamletHuddleHistory
                  permissions={props.permissions}
                  switchView={props.switchView}
                  teamletID={props.teamletID}
                  teamletName={teamletName}
                  huddleID={props.huddleID}
                  setHuddleID={props.setHuddleID}
                  setMeetingID={props.setMeetingID}
                  isMemberOfTeamLet={isMemberOfTeamlet}
              />               
              </div>
            </Col>
          </Row>
        </Form>

        <Modal show={showErrorModal}>
          <Modal.Header className="error">
            <h2>{errorMessageTitle}</h2>
          </Modal.Header>
          <Modal.Body>
            {errorMessage}<br />
            <br /><b>{errorServerMessage}</b><br />
            <br />{errorUserMessage}<br />
          </Modal.Body>
          <Modal.Footer>
            <Button className='error submit' onClick={() => setShowErrorModal(false)}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default HuddleDetails;
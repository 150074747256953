import React from "react";
import { Button, Modal } from 'react-bootstrap';

/**
  * @name ModalTimeoutWarning
  * @description  This modal will appear when a user begins to show signs of being idle
  * @param  
  *         showErrorModal : boolean,     // Shows UI
            errorMessageTitle: string,    //  Title
            errorMessage: string,         //  Message
            errorServerMessage: string,   //  Server error
            errorUserMessage: string,     //  User message
            messageType: string,          //  Type 'people' (blue), 'rpm' (green), 'system' (gray) and 'error' (red) 
            setShowErrorModal: Function   //  callback to close pop-up
    * @returns 
*/
export function ModalTimeoutWarning({ showErrorModal, messageType, Logout, setShowModalWarning}:
    { showErrorModal : boolean, 
      messageType: string,
      Logout: () => void,
      setShowModalWarning: (show : boolean) => void}
  ): JSX.Element {
  return <Modal show={showErrorModal}>
    <Modal.Header className={messageType}>
        <h2>Timeout Notification</h2>
    </Modal.Header>
    <Modal.Body>
      <br /><b>Your ACTIVATE session has been idle and you will be automatically logged out for your security.</b><br />
      <br />Do you want to continue using ACTIVATE?<br />
    </Modal.Body>
    <Modal.Footer>
        <Button className='system submit' variant="secondary"  onClick={() => Logout()}>Logout Now</Button>
        <Button className='system cancel' variant="primary" onClick={() => setShowModalWarning(false)}>Stay Logged In</Button>
    </Modal.Footer>
  </Modal>;
}
  
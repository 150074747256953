/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Breadcrumb, Button } from 'react-bootstrap';
import 'devextreme/dist/css/dx.light.css';
import { DateBox } from 'devextreme-react/date-box';
import { ModalErrorMessage } from '../Shared/MessageBox/ModalErrorMessage';
const config = require("../../config.json");

//editMode: 'add', 'edit;
function AddHuddle(props: {
    editMode: string,
    switchView: (newView: string) => void,
    teamletID: string,
    meetingID?: string
  }) {
  const inputAttr = { readOnly: true };
  const [huddleName, setHuddleName] = useState('');
  const [huddleDescription, setHuddleDescription] = useState('');
  const [huddleDate, setHuddleDate] = useState<Date>();
  const [huddleHour, setHuddleHour] = useState(0);
  const [huddleMinute, setHuddleMinute] = useState(0);
  const [validated, setValidate] = useState(false);
  const [badSubmissionError, setBadSubmissionError] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageTitle, setErrorMessageTitle] = useState('');
  const [validDate, setValidDate] = useState(true);

  // Used for edit mode
  const [submitID, setSubmitID] = useState('');
  const [meetingID, setMeetingID] = useState('');

  const hourArray = [
    { text: '12 am', value: 0},
    { text: '1 am', value: 1 }, 
    { text: '2 am', value: 2 },
    { text: '3 am', value: 3},
    { text: '4 am', value: 4 },
    { text: '5 am', value: 5 },
    { text: '6 am', value: 6 },
    { text: '7 am', value: 7 },
    { text: '8 am', value: 8 },
    { text: '9 am', value: 9 },
    { text: '10 am', value: 10 },
    { text: '11 am', value: 11 },
    { text: '12 pm', value: 12 },
    { text: '1 pm', value: 13 },
    { text: '2 pm', value: 14 },
    { text: '3 pm', value: 15 },
    { text: '4 pm', value: 16 },
    { text: '5 pm', value: 17 },
    { text: '6 pm', value: 18 },
    { text: '7 pm', value: 19 },
    { text: '8 pm', value: 20 },
    { text: '9 pm', value: 21 },
    { text: '10 pm', value: 22 },
    { text: '11 pm', value: 23 }];

  useEffect(() => {
    if (props.editMode === "edit") {
      getHuddleDetails();
    }
  }, []);

  async function getHuddleDetails() {
    let tempHuddleID;
    const token = sessionStorage.getItem('ACTIVATEtoken');
    await fetch(`${config.activateServer}:${config.activatePORT}/getMeeting`, {
        method: 'POST',
        body: JSON.stringify({ 
          token : token,
          huddleID: props.meetingID
    }),
    headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    mode : 'cors'
    }).then(async response => {
        if (response.ok) {
            try {
              const data = await response.json();
              //console.log("DATA " + JSON.stringify(data));
              tempHuddleID = data.huddle;
              setSubmitID(data.huddle);
              setMeetingID(data.internalID);
              const date = new Date(data.meetingTime);
              const huddleDateString = new Date(`${determineDay(date.getDay())} ${date.getMonth() + 1} ${date.getDate()} ${date.getFullYear()} 00:00:00 GMT-0500 (Eastern Standard Time)`);
              setHuddleDate(huddleDateString);
              setHuddleHour(date.getHours());
              setHuddleMinute(date.getMinutes());
            } catch (err) {
                setBadSubmissionError("Error: " + err);
            }
        } else {
            //  Error returned from data service, display to user
            response.json().then((data : any) => {            
                //  Show Modal
                setErrorMessage(data.message);
                setErrorMessageTitle(props.editMode === 'add' ? 'Add Huddle' : 'Edit Huddle');
                setShowErrorModal(true);
            });
        }
    });

    await fetch(`${config.activateServer}:${config.activatePORT}/getHuddleEdit`, {
      method: 'POST',
      body: JSON.stringify({ 
        token : token,
        huddleID: tempHuddleID
    }),
    headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    mode : 'cors'
    }).then(async response => {
        if (response.ok) {
            try {
              const data = await response.json();
              setHuddleName(data.name);
              setHuddleDescription(data.description);
            } catch (err) {
                setBadSubmissionError("Error: " + err);
            }
        } else {
            //  Error returned from data service, display to user
            response.json().then((data : any) => {
                //  Show Modal
                setErrorMessage(data.message);
                setErrorMessageTitle(props.editMode === 'add' ? 'Add Huddle' : 'Edit Huddle');
                setShowErrorModal(true);
            });
        }
    });


  }

  function determineDay(day: number) {
    switch (day) {
      case (0):
        return 'Sun';
      case (1):
        return 'Mon';
      case (2):
        return 'Tue';
      case (3):
        return 'Wed';
      case (4):
        return 'Thu';
      case (5):
        return 'Fri';
      case (6):
        return 'Sat';
    }
  }

  /**
     * @name handleSubmit
    */
  const handleSubmit = async (event : any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidate(true);
      return;
    } else {
      const stringDate = new Date(huddleDate ? huddleDate?.getFullYear() : 0, huddleDate ? huddleDate?.getMonth() : 0,
      huddleDate?.getDate(), huddleHour, huddleMinute, 0);

      if (huddleName === '' || huddleDescription === '' || !huddleDate) {
        setBadSubmissionError('Please fill out all fields before saving a huddle.');
      } else {
        setValidate(true);
        const token = sessionStorage.getItem('ACTIVATEtoken');
        // Check if ADD or EDIT
        if (props.editMode === 'add') {
          await fetch(`${config.activateServer}:${config.activatePORT}/addHuddle`, {
              method: 'POST',
              body: JSON.stringify({ 
                token : token,
                name: huddleName,
                description: huddleDescription,
                meetingTime: stringDate.toISOString(),
                organizationID: props.teamletID
          }),
          headers: { 
              'Content-Type': 'application/json',
              'Accept': 'application/json',
          },
          mode : 'cors'
          }).then(response => {
              //console.log("GOT HUDDLE POST REPLY " + JSON.stringify(response));
              if (response.ok) {
                  try {
                      // 
                      props.switchView("TeamletDetails");
                  } catch (err : any) {
                      setBadSubmissionError("Error: " + err);
                      setErrorMessage(err);
                  }
              } else {
                  //  Error returned from data service, display to user
                  response.json().then((data : any) => {            
                      //  Show Modal
                      setErrorMessage(data);
                      setErrorMessageTitle(props.editMode === 'add' ? 'Add Huddle' : 'Edit Huddle');
                      setShowErrorModal(true);
                  });
              }
          });
        } else {
          console.log("USING " + submitID);
          await fetch(`${config.activateServer}:${config.activatePORT}/editMeeting`, {
            method: 'POST',
            body: JSON.stringify({ 
              token : token,
              internalID: submitID,
              name: huddleName,
              description: huddleDescription,
              meetingID: meetingID,
              meetingTime: stringDate.toISOString(),
              organizationID: props.teamletID
          }),
          headers: { 
              'Content-Type': 'application/json',
              'Accept': 'application/json',
          },
          mode : 'cors'
          }).then(response => {
              console.log("GOT HUDDLE PUT REPLY " + JSON.stringify(response));
              if (response.ok) {
                  try {
                      // 
                      props.switchView("TeamletDetails");
                  } catch (err: any) {
                      setBadSubmissionError("Error: " + err);
                      setErrorMessage(err);
                  }
              } else {
                  //  Error returned from data service, display to user
                  response.json().then((data : any) => {  
                      //  Show Modal
                      setErrorMessage(data.message);
                      setErrorMessageTitle(props.editMode === 'add' ? 'Add Huddle' : 'Edit Huddle');
                      setShowErrorModal(true);
                  });
              }
          });
        }

      }
    }
  };

  return (
    <div>
      <Container fluid>
        <Breadcrumb>
            <Breadcrumb.Item onClick={() => props.switchView("configuration")}>Configuration</Breadcrumb.Item>
            <Breadcrumb.Item active>
              {props.editMode === 'add' ? 'Schedule New Huddle' : `Edit ${huddleName}`}
            </Breadcrumb.Item>
        </Breadcrumb>

          <div className="page-panel">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group className="form-group" controlId="huddleName">
                      <Form.Label className="required">Name</Form.Label>
                      <Form.Control
                          type="text"
                          required 
                          onChange={e => setHuddleName(e.target.value)}
                          value={huddleName}/>
                      <Form.Control.Feedback type="invalid">
                          Please enter the huddle name.
                      </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group" controlId="lastName">
                      <Form.Label className="required">Description</Form.Label>
                      <Form.Control
                          type="text"
                          required 
                          onChange={e => setHuddleDescription(e.target.value)}
                          value={huddleDescription}/>
                      <Form.Control.Feedback type="invalid">
                          Please enter the huddle description.
                      </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Huddle Date <span style={{ color: 'red' }}>*</span></Form.Label>
                    <DateBox
                      acceptCustomValue={false}
                      openOnFieldClick={true}
                      inputAttr={inputAttr}
                      placeholder="mm/dd/yyyy"
                      onValueChanged={(e : any) => setHuddleDate(e.value)}
                      style={{width: "200px"}}
                      type="date"
                      value={huddleDate}
                      invalidDateMessage='Date must be after todays date'
                      min={new Date()}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group">
                    <Form.Label className="required">Hour</Form.Label>
                    <Form.Select 
                        required
                        aria-label="Meeting Hour"
                        name="huddleHour"
                        value={huddleHour}
                        onChange={(e) => setHuddleHour(parseInt(e.target.value))}>
                        {hourArray.map((element: any, index: number) => {
                          return (
                            <option key={index} value={element.value}>{element.text}</option>
                          );
                        })}
                        
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        Please choose an Hour for this meeting.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group">
                    <Form.Label className="required">Minute</Form.Label>
                    <Form.Select 
                        required
                        aria-label="Meeting Minute"
                        name="huddleMinute"
                        value={huddleMinute}
                        onChange={(e) => setHuddleMinute(parseInt(e.target.value))}>
                        <option value={0}>00 min</option>
                        <option value={15}>15 min</option>
                        <option value={30}>30 min</option>
                        <option value={45}>45 min</option>
                        
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        Please choose an Hour for this meeting.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
          
              {badSubmissionError.length > 0 ? 
                <p className="validation-error">{badSubmissionError}</p>
                : <></> 
              }
              <div className="form-actions">
                  <Button
                      className="system submit"
                      type="submit"
                      variant="primary"
                      id="submitButton">
                          Save
                  </Button>
                  <Button className='system cancel'
                      id="cancelButton"
                      variant="secondary"
                      type="button"
                      onClick={() => props.switchView("TeamletDetails")}>
                      Cancel
                  </Button>
              </div>
          </Form>
          </div>
      </Container>

      <ModalErrorMessage   
        showErrorModal={showErrorModal} 
        errorMessageTitle={errorMessageTitle} 
        errorMessage={errorMessage} 
        errorServerMessage={""} 
        errorUserMessage={""}
        messageType="error" 
        setShowErrorModal={setShowErrorModal}
      />
  </div>
  );
}

export default AddHuddle;

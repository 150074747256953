/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState, useRef } from 'react';
import PermissionService from '../../../Services/PermissionService';
import { Button, Col, Container, Anchor, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { DataGrid, Selection, Column, Sorting, Paging, GroupPanel, SearchPanel, Grouping} from 'devextreme-react/data-grid';
import { Icon } from '@ailibs/feather-react-ts';
import { LoadPanel } from 'devextreme-react/load-panel';

const config = require("../../../config.json");

function OrgHuddleSchedule(props:
{
  permissions: PermissionService;
  switchView: (newView: string) => void;
  teamletID: string;
  teamletName: string;
  setHuddleID: (huddleID: string) => void;
  setMeetingID: (meetingID: string) => void;
  setSelectedTeamletID: (teamletID: string) => void;
  selectedOrganizationID: string;
}) {

//  Error Modal
const [huddleSchedule, setHuddleSchedule] = useState("");
const [fromDate, setFromDate] = useState("");
const [refreshData, setRefreshData] = useState(true);
const [toDate, setToDate] = useState("");
const [dayCount, setDayCount] = useState(7);
const [groupByIndex, setGroupByIndex] = useState(-1);
//  Spinner Icon location
const position = { of: '#huddleScheduleGrid' };
const [showProgress, setShowProgress] = useState(false);
const [huddleViewBy, setHuddleViewBy] = useState('1');
  
const huddleViewByButtons = [
  { name: 'View By Date', value: '1' },
  { name: 'View By Teamlet', value: '2' }
];

//  Init
useEffect(() => {
  const _currentDate = new Date();
  const _nextDate = new Date();
  _nextDate.setDate(_currentDate.getDate() + 6);

  setFromDate(() => {return _currentDate.toLocaleDateString().toLowerCase(); });
  setToDate(() => {return _nextDate.toLocaleDateString().toLowerCase(); });
}, []);

//  Init
useEffect(() => {
  //  Abort controller to close long fetch calls
  const huddleScheduleController = new AbortController();
  const token = sessionStorage.getItem('ACTIVATEtoken');

  //  Get Patients
  const fetchHuddleSchedule = async () => {
      if (props.selectedOrganizationID === '' || fromDate === '') {
        return;
      }
  
      try {
        setShowProgress(true);

        await fetch(`${config.activateServer}:${config.activatePORT}/getHuddlesByOrganizationID`, {
            method: 'POST',
            body: JSON.stringify({ token: token, internalID: props.selectedOrganizationID, daycount: dayCount, fromdate: formatDate(fromDate)}),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(res =>{
          if (!res.ok) {
            throw Error('Could not fetch the data for the resource');
          }
          return res.json();
        }).then(huddles =>{
          setHuddleSchedule(() => {return huddles });
        }).catch(err =>{
          if (err.name === 'AbortError') {
            //console.log('fetch aborted');
          } else {
            // TODO fill in
            setShowProgress(false);
          }
        });

        setShowProgress(false);
      } catch (err) {
          huddleScheduleController.abort();
          setShowProgress(false);
      }
  };

  // call the function
  fetchHuddleSchedule().catch(console.error);

  //  Clean-up useEffect
  return () => {
    huddleScheduleController.abort();
  };
}, [fromDate, refreshData, props.selectedOrganizationID]);

function getHuddleName(e:any) {
  return (
    <p>{e.data.huddle.name}  </p>
  );
}

/**
* @name getPatientCount
* @description Gets the Huddle Schedule Patient Count
* @param grid row
* @returns 
*/
function getPatientCount(e:any) {
  return (
    <>{e.data.meetingSummaries[0].assignedPatientCount}</>
  )
}

/**
* @name getMeetingTime
* @description Properly extract the time from the huddle datetime
* @param grid row
* @returns 
*/
function getMeetingTime(e:any) {
  let meetingLocalTime = <>(none)</>;
  
  //  Check for the proper data
  if (e.data != null && e.data.meetingSummaries != '') {
    const huddleId = e.data.meetingSummaries[0].huddle.internalID;
    const meetingId = e.data.meetingSummaries[0].internalID;
    meetingLocalTime = <>{new Date(e.data.meetingSummaries[0].meetingTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toLowerCase()}</>;
  
    return (
        <p className="actions">
          <Anchor onClick={(e) => { props.setHuddleID(huddleId); props.setMeetingID(meetingId); props.switchView("huddledetails"); }} title="Edit Meeting">{meetingLocalTime}</Anchor>
        </p>
    );
  } else {
    return (
      <p className="actions">
        {meetingLocalTime}
      </p>
    );
  }
}

/**
 * @name getMeetingDate
 * @description Properly extract the date from the huddle datetime
 * @param grid row
 * @returns 
 */
function getMeetingDate(e:any) {
  let meetingLocalDate = <>(none)</>;

  //  Check for the proper data
  if (e.data != null && e.data.meetingSummaries != '') {
    const huddleId = e.data.meetingSummaries[0].huddle.internalID;
    const meetingId = e.data.meetingSummaries[0].internalID;
    meetingLocalDate = <>{new Date(e.data.meetingSummaries[0].meetingTime).toLocaleDateString().toLowerCase()}</>;
  
    return (
      <p className="actions">
        <Anchor onClick={(e) => { props.setHuddleID(huddleId); props.setMeetingID(meetingId); props.switchView("huddledetails"); }} title="Edit Meeting">{meetingLocalDate}</Anchor>
      </p>
    );
  } else {
    return (
      <p className="actions">
        {meetingLocalDate}
      </p>
    );
  } 
}

//  Call back?  
function toggleDeleteModal() {
  setRefreshData((e) => {return !e})
}

/**
 * @name formatDate
 * @description Formats date in mm/dd/yyyy format
 * @param string 
 * @returns 
*/
function formatDate(dateStringToFormat:string) {
  let date, month;
  const inputDate = new Date(dateStringToFormat);
  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  const year = inputDate.getFullYear();
  
  date = date.toString().padStart(2, '0');
  month = month.toString().padStart(2, '0');
  
  return `${month}/${date}/${year}`;
}

/**
 * @name isDateInThePast
 * @description Check if Date is in the past
 * @param string 
 * @returns 
*/
function isDateInThePast(dateToCheck:string) {
  const date1 = new Date(dateToCheck).getTime();
  const date2 = new Date().getTime();

  if (date1 < date2) {
    return true;
  } else if (date1 > date2) {
    return false;
  } else if (date1 == date2) {
    return true;
  } else {
    return false;
  }
}

/**
 * @name goPreviousDate
 * @description Moves date to previous week
 * @param string
 * @returns 
*/
function goPreviousDate() {
  const _fromDate = new Date(fromDate);
  const _toDate = new Date(fromDate);
  _fromDate.setDate(_fromDate.getDate() - 7);
  _toDate.setDate(_toDate.getDate() - 1);

  setDayCount(() =>{return 7; });
  setFromDate(() => {return _fromDate.toLocaleDateString().toLowerCase(); });
  setToDate(() => {return _toDate.toLocaleDateString().toLowerCase(); });
}

/**
 * @name goNextDate
 * @description Moves date to next week
 * @param string
 * @returns 
*/
function goNextDate() {
  const _fromDate = new Date(toDate);
  const _toDate = new Date(toDate);
  _toDate.setDate(_toDate.getDate() + 7);
  _fromDate.setDate(_fromDate.getDate() + 1);

  setDayCount(() =>{return 7; });
  setFromDate(() => {return _fromDate.toLocaleDateString().toLowerCase(); });
  setToDate(() => {return _toDate.toLocaleDateString().toLowerCase(); });
}

/**
 * @name scheduleNewHuddle
 * @description Gets the delete Icon in assigned Patients
 * @param grid row
 * @returns 
 */
function scheduleNewHuddle() {
  props.switchView('addHuddle');
}

function viewHuddleDetails() {
  props.switchView('addHuddle');
}

/**
 * @name onHuddleViewByChange
 * @description Change Huddle View By
 * @param grid row
 * @returns 
*/
function onHuddleViewByChange(e:any){
  if (e.currentTarget.value === '1'){
    setGroupByIndex(-1)
  } else {
    setGroupByIndex(1)
  }
  setHuddleViewBy(e.currentTarget.value)
}

/**
 * @name renderTeamletGroup
 * @description Renders the Group By Teamlet anchor
 * @param grid row
 * @returns 
*/
function renderTeamletGroup(e: any) {
  //  Check for proper data
  if (e.data != null && e.data.items != null && e.data.items.length > 0) {
    return (
      <p>
        <Anchor onClick={() => { props.setHuddleID(e.data.items[e.rowIndex].meetingSummaries[0].internalID); props.setMeetingID(e.data.items[e.rowIndex].meetingSummaries[0].huddle.internalID); props.setSelectedTeamletID(e.data.items[e.rowIndex].healthcareTeam.internalID); props.switchView("TeamletDetails"); }}>{e.text}</Anchor>
      </p>
    );
  } else {
    return (
      <>{e.data.key}</>
    );
  }
}

  return (
          <div>
              <section>
              <div className="d-flex">
                  <h2>Huddle Schedule</h2><br/>
                <div className="form-group reset alignRight">
                  <ButtonGroup className="float-end" size={'sm'} >
                    {huddleViewByButtons.map((r, idx) => (
                      <ToggleButton key={idx}  id={`r-${idx}`}
                        type="radio"
                        name="r"
                        value={r.value}
                        variant={huddleViewBy === r.value ? "primary" : "outline-primary"}
                        checked={huddleViewBy === r.value}
                        onChange={onHuddleViewByChange}>
                        {r.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </div>
                </div>
              </section>
              <div className="d-flex">
                <p><label>{fromDate}</label>&nbsp;to&nbsp;<label>{toDate}</label></p>
                <div className="form-group reset alignRight">
                  <Anchor onClick={() => goPreviousDate()}><Icon size="14" name="arrow-left" className="btn-link"></Icon>Prev&nbsp;</Anchor>
                  <Anchor onClick={() => goNextDate()}>Next<Icon size="14" name="arrow-right" className="btn-link"></Icon></Anchor>
                </div>
              </div>
              <div id="huddleScheduleGrid">
                <LoadPanel visible={showProgress} message={"Loading Huddle Schedule"} showPane={true} position={position} showIndicator={true}></LoadPanel>
                <DataGrid id="grid-container" 
                    dataSource={huddleSchedule}
                    showColumnLines={false}
                    showRowLines={false}
                    showBorders={false}
                    focusStateEnabled={true}
                    allowColumnResizing={true} 
                    showColumnHeaders={true}
                    width={'100%'}
                    noDataText='No Huddles are scheduled'>
                      <GroupPanel visible={false} />
                      <SearchPanel visible={false} />
                      <Grouping autoExpandAll={true} />
                    <Selection mode="none" />
                    <Sorting mode="single" />    
                    <Paging defaultPageSize={7} />
                      <Column dataField="internalID" caption="Meeting Id" visible={false} />
                      <Column dataField="huddle.internalID" caption="huddle Id" visible={false} />
                      <Column width={'80'} dataField="meetingTime" caption="Date" cellRender={e => getMeetingDate(e)} allowSorting={false} />
                      <Column width={'80'} dataField="meetingTime1" caption="Time" cellRender={e => getMeetingTime(e)} allowSorting={false} />
                      <Column dataField="healthcareTeam.name" caption="Teamlet" groupIndex={groupByIndex} groupCellRender={e => renderTeamletGroup(e)} allowSorting={false} />
                      <Column dataField="name" caption="Name" allowSorting={false} />
                      <Column width={'80'} dataField="meetingSummaries.assignedPatientCount" alignment={'center'} caption="Patients" allowSorting={false} cellRender={e => getPatientCount(e)} />
                </DataGrid>
              </div>
       </div>
    );
  }

  export default OrgHuddleSchedule;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col, Form, Breadcrumb, InputGroup, Modal } from 'react-bootstrap';
import { ACTIVATEADMINISTRATOR, UNSPECIFIED } from '../Shared/constants';
const config = require('../../config.json');

function NewEnrollmentInvitation(props: {switchView: Function}) {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [prefix, setPrefix] = useState(UNSPECIFIED);
    const [suffix, setSuffix] = useState(UNSPECIFIED);
    const [preferredName, setPreferredName] = useState("");
    const [roleDisplayList, setRoleDisplayList] = useState<any[]>([]);
    const [roleName, setRoleName] = useState<string | null>("");
    const [roleValue, setRoleValue] = useState<string | null>("");
    const [roleID, setRoleID] = useState<string | null>("");
    const [primaryOrgDisplayList, setPrimaryOrgDisplayList] = useState<any[]>([]);
    const [activateAdminOrgDisplayList, setACTIVATEAdminsPrimaryOrgDisplayList] = useState<any[]>([]);
    const [nonActivateAdminOrgDisplayList, setNonActivateAdminOrgDisplayList] = useState<any[]>([]);
    const [primaryOrganization, setPrimaryOrganization] = useState("");
    const [primaryOrganizationID, setPrimaryOrganizationID] = useState<string | null>("");
    const [emailAddress, setEmailAddress] = useState("");
    const [cellPhone, setCellPhone] = useState("");
    const [landlinePhone, setLandlinePhone] = useState("");
    const [expireDate, setExpireDate] = useState("");
    const [badSubmissionError, setBadSubmissionError] = useState("");
    const [validated, setValidate] = useState(false);
    const [prefixList, setPrefixList] = useState<string[]>([]);
    const [suffixList, setSuffixList] = useState<string[]>([]);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorServerMessage, setErrorServerMessage] = useState("");
    const [errorUserMessage, setErrorUserMessage] = useState("");

    useEffect(() => {
        loadRoleTemplates();
        loadPrimaryOrg();
        loadPrefixes();
        loadSuffixes();
    }, [])

    function dropdownSelectChange(event : React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();
        const name = event.target.name;
        if (name === "prefix") {
            setPrefix(event.target.value);
        } else if (name === "role") {
            let roleName = event.target.options[event.target.selectedIndex].getAttribute('data-name') ?
                event.target.options[event.target.selectedIndex].getAttribute('data-name') : "";
            let roleValue = event.target.options[event.target.selectedIndex].getAttribute('data-value') ?
                event.target.options[event.target.selectedIndex].getAttribute('data-value') : "";
            let roleID = event.target.options[event.target.selectedIndex].getAttribute('data-id') ?
                event.target.options[event.target.selectedIndex].getAttribute('data-id') : "";
            setRoleName(roleName);
            setRoleValue(roleValue);
            setRoleID(roleID);

            //  Load ACTIVATE specific Org
            if (roleName === ACTIVATEADMINISTRATOR) {
                setPrimaryOrgDisplayList(activateAdminOrgDisplayList);
            } else {
                setPrimaryOrgDisplayList(nonActivateAdminOrgDisplayList);
            }
        } else if (name === "primaryOrganization") {
            let orgValue = event.target.options[event.target.selectedIndex].getAttribute('data-value') ?
            event.target.options[event.target.selectedIndex].getAttribute('data-value') : "";
            setPrimaryOrganization(event.target.value);
            setPrimaryOrganizationID(orgValue);
        } else if (name === "suffix") {
            setSuffix(event.target.value);
        }
    }

    async function loadRoleTemplates() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getRoleTemplates`, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        })

        try {
            const data = await response.json();
            //console.log("Permission " + JSON.stringify(data[0]));
            let roleList : any[] = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].name !== 'Patient' && data[i].name !== 'Patient Delegate') {
                    roleList.push(
                        <option
                            key={i}
                            data-name={data[i].userrole.name}
                            data-value={data[i].userrole.value}
                            data-id={data[i].internalID}>
                                {data[i].name}
                        </option>
                    )
                }
            }
            setRoleDisplayList(roleList);

        } catch(err){
            console.log("ERROR " + err);
        }
    }

    async function loadPrimaryOrg() {
        console.log("Loading organization");
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getAllOrganizations`, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        })

        try {
            const data = await response.json();
            //console.log("GOT BACK " + JSON.stringify(data));
            //Load the table here
            let organizationList = [];
            let activateAdminsOrganizationList = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].activate){
                    activateAdminsOrganizationList.push(
                        <option key={i} data-value={data[i].internalID}>{data[i].name}</option>
                    )
                } else {
                    organizationList.push(
                        <option key={i} data-value={data[i].internalID}>{data[i].name}</option>
                    )
                }
            }
            
            //  Set the Primary Display List, non ACTIVATE Admins
            setNonActivateAdminOrgDisplayList(organizationList);

            //  Set the Primary Display List, non ACTIVATE Admins
            setACTIVATEAdminsPrimaryOrgDisplayList(activateAdminsOrganizationList);

            //  Set Default
            setPrimaryOrgDisplayList(organizationList);


        } catch(err){
            console.log("ERROR " + err);
        }
    }

    /**
     * @name handleSubmit
     * @description Runs the for validation and if passing will send the enrollment and change the page
    */
    const handleSubmit = async (event : any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
          event.stopPropagation();
          setValidate(true);
        } else if((/\S+@\S+\.\S+/.test(emailAddress)) !== true){
            event.stopPropagation();
            setBadSubmissionError("Invalid email.  Please enter a valid email");
        } else {
            saveEnrollment();
        }
    
        setValidate(true);

    };

    /**
     * @name loadPrefixes
     * @description Loads the available Prefixes for Enrollees
    */
    async function loadPrefixes() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
    
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getValues`, {
            method: 'POST',
            body: JSON.stringify({ token: token, type:'Prefix' }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        })

        try {
            const data = await response.json();
            
            //  Load the table here  
            setPrefixList(data.map((i:any)=>{
                return(<option
                key={i.value}
                value={i.value}>
                    {i.name}
            </option>)
            }));
        } catch (err) {
            console.log("ERROR " + err);
        }
    }
    
    /**
     * @name loadSuffixes
     * @description Loads the available Prefixes for Enrollees
    */
    async function loadSuffixes() {
        const token = sessionStorage.getItem('ACTIVATEtoken');

        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getValues`, {
            method: 'POST',
            body: JSON.stringify({ token: token, type:'Ordinal' }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        })

        try {
            const data = await response.json();

            //  Load the table here
            setSuffixList(data.map((i:any)=>{
                return(<option
                key={i.value}
                value={i.value}>
                    {i.name}
            </option>)
            }));
        } catch (err) {
            console.log("ERROR " + err);
        }
    }
    
    async function saveEnrollment() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        await fetch(`${config.activateServer}:${config.activatePORT}/postNewEnrollment`, {
            method: 'POST',
            body: JSON.stringify({ 
                token : token,
                firstName : firstName,
                lastName : lastName,
                prefix : prefix,
                suffix : suffix,
                preferredName : preferredName,
                roleName : roleName,
                roleValue : roleValue,
                roleID : roleID,
                primaryOrganization : primaryOrganizationID,
                email : emailAddress,
                cellPhone : cellPhone,
                landlinePhone : landlinePhone,
                autoExpire : expireDate,
                expiration : 10080        // Default for now
                //invitationDate : Date.now()
        }),
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        mode : 'cors'
    }).then(response => {
        //console.log("GOT ENROLLMENT POST REPLY " + JSON.stringify(response));
        if (response.ok) {
            try {
                props.switchView("enrollmentInvitations");
            } catch (err) {
                setBadSubmissionError("Error: " + err);
            }
        } else {
            //  Error returned from data service, display to user
            response.json().then((data : any) => {            
                //  Create Message
                const _statusCode = response.status.toString();
                const _statusmessage = data !== null ? data.message : "Error Inviting New User";
    
                //  Create Error Message
                setErrorMessageTitle("Error Updating User")
                setErrorMessage("An error occurred while creating an enrollment for: " + firstName + " " + lastName);
                setErrorServerMessage(_statusmessage + " (Code: " + _statusCode + ")");
                setErrorUserMessage("Please check the data you entered, and re-try.");
                            
                //  Show Modal
                setShowErrorModal(true);
            })
        }
    })
    /*.then(data => {
        //Make sure we dont go back to the enrollment if an error occurs
        if (data === "Duplicate") {
            setBadSubmissionError("Error: This email is already associated with a user.");
        } else {
            props.switchView("enrollmentInvitations");
        }
    })*/
    }

    return (
    
        <div>
            <Container fluid>
            
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => props.switchView("configuration")}>Configuration</Breadcrumb.Item>
                    <Breadcrumb.Item active>New Enrollment Invitation</Breadcrumb.Item>
                </Breadcrumb>
                
                <div className="page-panel">
                  <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="form-group" controlId="firstName">
                            <Form.Label className="required">First Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter first name"
                                required 
                                onChange={e => setFirstName(e.target.value)}
                                value={firstName}/>
                            <Form.Control.Feedback type="invalid">
                                Please enter your first name.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group" controlId="lastName">
                            <Form.Label className="required">Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter last name"
                                required 
                                onChange={e => setLastName(e.target.value)}
                                value={lastName}/>
                            <Form.Control.Feedback type="invalid">
                                Please enter your last name.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group" controlId="prefix">
                            <Form.Label>Prefix</Form.Label>
                            <Form.Select
                                aria-label="Prefix"
                                name="prefix"                                       
                                value={prefix}
                                onChange={dropdownSelectChange}>
                                {prefixList}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="form-group" controlId="suffix">
                            <Form.Label>Suffix</Form.Label>
                            <Form.Select
                                aria-label="Suffix"
                                name="suffix"                                       
                                value={suffix}
                                onChange={dropdownSelectChange}>
                                <option>Select</option>
                                {suffixList}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="form-group" controlId="preferredName">
                            <Form.Label>Preferred Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter preferred name"
                                value={preferredName}
                                onChange={e => setPreferredName(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="form-group" controlId="role">
                            <Form.Label className="required">Role</Form.Label>
                            <Form.Select
                                aria-label="Role"
                                name="role"
                                required
                                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => dropdownSelectChange(event)}>
                                <option value="">Select</option>
                                {roleDisplayList}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Please enter role.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group" controlId="primaryOrganization">
                            <Form.Label className="required">Primary Organization</Form.Label>
                            <Form.Select
                                aria-label="Primary Organization"
                                name="primaryOrganization"
                                value={primaryOrganization}
                                required
                                onChange={dropdownSelectChange}>
                                <option value="">Select</option>
                                {primaryOrgDisplayList}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Please choose Primary Organization.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="form-group" controlId="emailAddress">
                            <Form.Label className="required">Email Address</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter email address"
                                value={emailAddress}
                                required
                                onChange={e => setEmailAddress(e.target.value)}/>
                            <Form.Control.Feedback type="invalid">
                                Please enter your email address.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group" controlId="cellPhone">
                            <Form.Label>Cell Phone</Form.Label>
                            <Form.Control
                                type="text" 
                                placeholder="Enter cell phone"
                                value={cellPhone}
                                onChange={e => setCellPhone(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="form-group" controlId="landlinePhone">
                            <Form.Label>Landline Phone</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter landline phone"
                                value={landlinePhone}
                                onChange={e => setLandlinePhone(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="form-group">
                            <h3 className="sub-section">Options</h3>
                            <Form.Label>Invitation Automatically Expires</Form.Label>
                                <Form.Select aria-label="Role">
                                    <option>In one week</option>
                                </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                
                    {badSubmissionError.length > 0 ? 
                      <p className="validation-error">{badSubmissionError}</p>
                      : <></> 
                    }
                    <div className="form-actions">
                        <Button
                            className="system submit"
                            type="submit"
                            variant="primary"
                            id="submitButton">
                                Save
                        </Button>
                        <Button className='system cancel'
                            id="cancelButton"
                            variant="secondary"
                            type="button"
                            onClick={() => props.switchView("enrollmentInvitations")}>
                            Cancel
                        </Button>
                    </div>
                </Form>
                </div>
            </Container>

            <Modal show={showErrorModal}>
                <Modal.Header className="error">
                    <h2>{errorMessageTitle}</h2>
                </Modal.Header>
                <Modal.Body>
                {errorMessage}<br/>
                <br/><b>{errorServerMessage}</b><br/>
                <br/>{errorUserMessage}<br/>
                </Modal.Body>
                <Modal.Footer>
                <Button className='error submit' onClick={() => setShowErrorModal(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
} 

export default NewEnrollmentInvitation
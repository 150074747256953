import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Anchor, Container, Button, Row, Col, Form, Breadcrumb, Card, Modal } from 'react-bootstrap';
import { DataGrid, Selection, Column, Paging } from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { Button as DevButton } from 'devextreme-react/data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmailValidator } from 'commons-validator-js';
import { Icon } from '@ailibs/feather-react-ts';
import './MyProfile.css';
import { SUFFIXLOOKUP } from '../Shared/constants';
import PermissionService from '../../Services/PermissionService';
import MyNextHuddle from '../Shared/Huddle/MyNextHuddles';

const config = require('../../config.json');

function MyProfile(props: { 
    permissions: PermissionService, 
    switchView: (view: string) => void,
    setHuddleID: (huddleId: string) => void,
    setSelectedTeamletID: (Id: string) => void,
    setReturnToView: (view: string) => void,
    editTeamlet: Function,
    setMeetingID: (meetingId: string) => void,}) {
    const navigate = useNavigate();
    const dgOrganizations = useRef(null);

    const [validated, setValidated] = useState(false);
    const [internalID, setInternalID] = useState("");
    const [username, setUsername] = useState("");
    const [status, setStatus] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [prefix, setPrefix] = useState("");
    const [suffix, setSuffix] = useState("");
    const [preferredName, setPreferredName] = useState("");
    const [emailAddress, setEmailAddress] = useState('');
    const [emailAddressObj, setEmailAddressObj] = useState({});
    const [cellPhone, setCellPhone] = useState({});
    const [cellPhoneNumber, setCellPhoneNumber] = useState("");
    const [landLineObj, setLandLineObj] = useState({});
    const [landLineNumber, setLandLineNumber] = useState("");

    const [requireChangePassword, setRequireChangePassword] = useState(false);
    const [roleID, setRoleID] = useState("");
    const [finalOrganizations, setFinalOrganizations] = useState<any>([])
    const [healthcareTeamLetSummary, setHealthcareTeamLetSummary] = useState([]);

    //  Handle State for Cancels
    const [_username, _setUsername] = useState("");
    const [_status, _setStatus] = useState("");
    const [_firstName, _setFirstName] = useState("");
    const [_middleName, _setMiddleName] = useState("");
    const [_lastName, _setLastName] = useState("");
    const [_prefix, _setPrefix] = useState("");
    const [_suffix, _setSuffix] = useState("");
    const [_preferredName, _setPreferredName] = useState("");
    const [_emailAddress, _setEmailAddress] = useState("");
    const [_cellPhoneNumber, _setCellPhoneNumber] = useState("");
    const [_landLineNumber, _setLandLineNumber] = useState("");
    const [_requireChangePassword, _setRequireChangePassword] = useState(false);
    const [_roleID, _setRoleID] = useState("");

    const [isReadOnlyMode, setIsReadOnlyMode] = useState(true);
    const [prefixList, setPrefixList] = useState<any[]>([]);
    const [prefixNames, setPrefixNames] = useState<any[]>([]);
    const [suffixList, setSuffixList] = useState<any[]>([]);
    const [suffixNames, setSuffixNames] = useState<any[]>([]);
    const [showSaveModalPrompt, setShowSaveModalPromptl] = useState(false);
    const location = useLocation();
    //@ts-ignore
    const [roleName, setRoleName] = useState(location.state !== undefined && location.state !== null ? location.state.role : '');

    // Organization Data
    const [orgData, setOrgData] = useState<any[]>([]);
    const [mySites, setMySites] = useState<any[]>([]);
    const [healthCenterParent, setHealthCenterParent] = useState("");
    const [healthCenterDescription, setHealthCenterDescription] = useState("");

    // Error handling
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState("");
    
    //  User Status
    const [showProgress, setShowProgress] = useState(false);
    
    //  Delete Model Management
    const [deleteID, setDeleteID] = useState("");
    const [showDeleteTeamletModal, setShowDeleteTeamletModal] = useState(false);

    //  Spinner Icon location
    const position = { of: '#profileInfo' };
    const positionForTeamlets = { of: '#positionForTeamlets' };
    const positionForSites = { of: '#positionForSites' };

    const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()
        , PNF = require('google-libphonenumber').PhoneNumberFormat
        , PNT = require('google-libphonenumber').PhoneNumberType

    const validPhoneNumber = (e: any) => {
        // https://www.ronaldjamesgroup.com/blog/phone-numbers-in-javascript-using-e164-libphonenumber-and-microdata
        // https://www.npmjs.com/package/google-libphonenumber
        let isValid = true;

        try {
            var tel = phoneUtil.parse(e, 'US');
            isValid = phoneUtil.isValidNumber(phoneUtil.parse(e, 'US'));
        } catch (e) {
            isValid = false
        }

        return isValid;
    }

    //  Initial Effect
    useEffect(() => {
        //  Set Role name
        loadProfileInfo();
        loadPrefixes();
        loadSuffixes();

    }, [])


    /**
    * @name loadProfileInfo
    * @description Loads the User Profile from userprofile/detailed
    * @param  
    * @returns {json} 
    */
    async function loadProfileInfo() {
        // Start Spinner
        //@ts-ignore
        dgOrganizations.current?.instance.beginCustomLoading();
        setShowProgress(true);

        const token = sessionStorage.getItem('ACTIVATEtoken');

        await fetch(`${config.activateServer}:${config.activatePORT}/getUserProfileDetailed`, {
            method: 'POST',
            body: JSON.stringify({ token: token }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(async _response => {
            //  Check for Success
            if (_response.ok) {
                try {
                    let _json = await _response.json();
                    loadUI(_json);

                } catch (err) {
                    console.log("ERROR " + err);
                }
            }
            else {
                // Handle non-200s
                console.log("Received HTTP status " + _response.status.toString() + '   ' + _response.statusText)
                handleException(_response)
                // Stop Spinner
                //@ts-ignore
                dgOrganizations.current?.instance.endCustomLoading();
                setShowProgress(false);
            }
        }).catch(err =>{
            // Stop Spinner
            //@ts-ignore
            dgOrganizations.current?.instance.endCustomLoading();
            setShowProgress(false);
            console.log("Error in loadProfileInfo: " + err);
        })

        //@ts-ignore
        dgOrganizations.current?.instance.endCustomLoading();
        // Stop Spinner
        setShowProgress(false);
    }

    /**
    * @name loadUI
    * @description Loads the UI
    * @param  
    * @returns {json} 
    */
    async function loadUI(data: any) {
        setInternalID(data.internalID);
        setUsername(data.username);
        setStatus(data.accountStatus);
        setMiddleName(data.middleName);
        setRequireChangePassword(data.mustChangePasswordNextLogin);
        setRoleID(data.roleTemplate.internalID);

        //  Fields that can be updated
        setFirstName(data.firstName);
        setMiddleName(data.middleName);
        setLastName(data.lastName);
        setPreferredName(data.preferredName);
        setPrefix(data.prefix);
        setSuffix(data.suffix);
        setEmailAddressObj(data.emailAddress);
        setEmailAddress(data.emailAddress.name);
        setCellPhone(data.cellPhone);
        setCellPhoneNumber(data.cellPhone.name);
        setLandLineObj(data.landlinePhone);
        setLandLineNumber(data.landlinePhone.name);


        //  State for cancelling edit
        _setFirstName(data.firstName);
        _setLastName(data.lastName);
        _setPreferredName(data.preferredName);

        _setPrefix(data.prefix);
        _setSuffix(data.suffix);
        _setEmailAddress(data.emailAddress.name);
        _setCellPhoneNumber(data.cellPhone.name);
        _setLandLineNumber(data.landlinePhone.name);

        // @ts-ignore
        var orgAdminSummary = [];
        if (roleName === "ROLE_ACTIVATE_ADMINISTRATOR") {

            for (let i = 0; i < data.parentHealthCenters.length; i++) {
                // @ts-ignore
                var orgDetails = {
                    "name": data.parentHealthCenters[i].name,
                    "staffCount": data.parentHealthCenters[i].staffCount,
                    "patientCount": data.parentHealthCenters[i].patientCount,
                    "siteCount": data.parentHealthCenters[i].siteCount

                }
                orgAdminSummary.push(orgDetails)
            }
            setOrgData(orgAdminSummary)
            const tempArray = [];
            tempArray.push(data.parentOrganization.internalID);
            setFinalOrganizations(tempArray);

        } else {
            setOrgData(data.organizations);
            setFinalOrganizations(data.organizations.map((o: any) => { return (o.internalID) }))
            //  Load Team Summary
            setHealthcareTeamLetSummary(data.teams);
        }
        setMySites(data.organizations);
        setHealthCenterParent(data.parentOrganization.name);
        setHealthCenterDescription(data.parentOrganization.description);
        
    }

    /**
    * @name loadPrefixes
    * @description Loads the Prefix List for the Dropdown values
    * @param  
    * @returns {json} 
    */
    async function loadPrefixes() {
        const token = sessionStorage.getItem('ACTIVATEtoken');

        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getValues`, {
            method: 'POST',
            body: JSON.stringify({ token: token, type: "Prefix" }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        })
        if (response.ok) {
            try {
                const data = await response.json();
                //  Load the Prefix List
                setPrefixList(data.map((i: any) => {
                    return (<option
                        key={i.value}
                        value={i.value}>
                        {i.name}
                    </option>)
                }));
                setPrefixNames(data)
            } catch (err) {
                console.log("ERROR " + err);
            }
        }
        else {
            // Handle non-200s
            console.log("Received HTTP status " + response.status.toString() + '   ' + response.statusText)
            handleException(response)
        }
    }

    /**
    * @name loadSuffixes
    * @description Loads the Suffix List for the Dropdown values
    * @param  
    * @returns {json} 
    */
    async function loadSuffixes() {
        const token = sessionStorage.getItem('ACTIVATEtoken');

        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getValues`, {
            method: 'POST',
            body: JSON.stringify({ token: token, type: SUFFIXLOOKUP }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        })
        if (response.ok) {
            try {
                const data = await response.json();
                //  Load Suffix List
                setSuffixList(data.map((i: any) => {
                    return (<option
                        key={i.value}
                        value={i.value}>
                        {i.name}
                    </option>)
                }));
                setSuffixNames(data);
            } catch (err) {
                console.log("ERROR " + err);
            }
        }
        else {
            // Handle non-200s
            console.log("Received HTTP status " + response.status.toString() + '   ' + response.statusText)
            handleException(response)
        }
    }

    /**
    * @name setReadOnlyMode
    * @description Turns Edit Mode On/Off
    * @param  boolean On/Off
    * @returns  
    */
    async function setReadOnlyMode(editModeOn: boolean) {
        setIsReadOnlyMode(editModeOn);
        const forms = document.querySelectorAll('form');
        const form = forms[0];
        Array.from(form.elements).forEach((element) => {
            if (editModeOn) {
                //@ts-ignore
                if (element.name === 'emailAddress' || element.name === 'cellPhoneNumber' || element.name === 'landLineNumber') {
                    //@ts-ignore
                    element.classList.remove('is-invalid');
                    //@ts-ignore
                    element.required = false;
                    //@ts-ignore
                    element.nextElementSibling.innerHTML = '   ';
                }
            } else {
                // @ts-ignore
                if (element.name === "emailAddress") {
                    //@ts-ignore
                    element.required = true;
                    //@ts-ignore
                    if (element.value === "") {
                        element.classList.add('is-invalid');
                        //@ts-ignore
                        element.nextElementSibling.innerHTML = 'Please enter a valid email address'
                    }
                }
            }
        });
        //  Reset if the user cancels
        if (editModeOn) {
            loadProfileInfo();
            //cancelProfileInfo();
            setValidated(false);
        }

    }


    /**
    * @name cancelProfileInfo
    * @description Cancels Profile Info Edit
    * @param  
    * @returns 
    */
    async function cancelProfileInfo() {
        //  Restore State for Edits
        setFirstName(_firstName);
        setLastName(_lastName);
        setPreferredName(_preferredName);
        setPrefix(_prefix);
        setSuffix(_suffix);
        setEmailAddress(_emailAddress);
        setCellPhoneNumber(_cellPhoneNumber);
        setLandLineNumber(_landLineNumber);
    }

    /**
    * @name prefixSelectChange
    * @description Sets Prefix selection
    * @param  
    * @returns 
    */
    async function prefixSelectChange(e: { target: { value: React.SetStateAction<string>; }; }) {
        setPrefix(e.target.value);
    }

    /**
    * @name suffixSelectChange
    * @description Sets Suffix selection
    * @param  
    * @returns 
    */
    async function suffixSelectChange(e: { target: { value: React.SetStateAction<string>; }; }) {
        setSuffix(e.target.value)
    }

    /**
    * @name showUpdateConfirmModal
    * @description Sets Suffix selection
    * @param  
    * @returns 
    */
    function showUpdateConfirmModal(toggle: boolean) {
        setShowSaveModalPromptl(() => {return toggle});
    }

    /**
    * @name saveProfileConfirmed
    * @description Saves the profile
    * @param  
    * @returns 
    */
    function saveProfileConfirmed() {
        showUpdateConfirmModal(false);
        updateUser();
    }

    /**
    * @name goToDashBoard
    * @description Goes to the dashboard
    * @param
    * @returns
    */
    function goToDashBoard(e:any){
        props.switchView("dashboard");
        e.preventDefault()
    }

    /**
     * @name renderIcon
     * @description Shows the feather icon
     * @param
     * @returns
     */
    function renderIcon(iconName : any, category:string) {
        return (
          <div className={`ui-icon ${category}`}>
            <Icon name={iconName} size="24" />
          </div>
        );
    }

    /**
     * @name teamletList
     * @description Renders user's assigned Teamlets
     * @param healthcareTeamletSummary
     * @returns Team list HTML
     */
    function teamletList(e: any) {
        // Check for row  
        if (e != null && e.length > 0) {
         return (
          <ul className="team-list"> 
            {e.map((data: any) => {
              if (data != null) {
                  let pathways = data.digitalHealthPathways?.map((pathway: any) => {
                  return <><small>{pathway.name}</small><br/></>
              })
              return (
                <li>
                  {renderIcon("users","people")}
                  <p title={data.description}><Anchor onClick={(e) => { props.setSelectedTeamletID(data.internalID); props.switchView("TeamletDetails");}} title="View Teamlets">{data.name}</Anchor><br/>
                    {pathways}
                  </p>
                  {/* if user has permission to delete team members then show the icon  */}
                  <p className="actions">
                    {canDelete() ? <a onClick={(e) => deleteTeamletClick(data)} title={`Remove ${data.name}`}><Icon name="trash-2" size="18" className="action-icon" data-internalid={data.internalID}/></a> : null}
                    {canEdit() ? <a onClick={(e) => editTeamletClick(data)} title={`Edit ${data.name}`}><Icon name="edit" size="18" className="action-icon" data-internalid={data.internalID} /></a> : null}
                  </p>
                </li>
              )
              } 
              }
            )}
        </ul>
        )
       } else {
         return(<>No assigned teamlets<br/></>)
       }
    }

    //  Delete Teamlet called from the DataGrid
    // @ts-ignore
    function deleteTeamletClick(e) {
        promptDeleteTeamlet(e.internalID);
    }

    //  Delete Pop-up
    function promptDeleteTeamlet(id: string) {
        showDeleteModal(id, true);
    }

    //  Delete Pop-up Toggle
    function showDeleteModal(id: string, toggle: boolean) {
        setDeleteID(id);
        setShowDeleteTeamletModal(toggle);
    }

    //  Edit TeamLet onClick handler
    // @ts-ignore
    function editTeamletClick(e) {
        //  Check for row Object
        if (e != null) {
            props.setReturnToView("MyProfile");
            props.setSelectedTeamletID(e.internalID);
            props.switchView("editTeamlet");
        }
    }
    
    //  Can Edit Teamlet?
    function canEdit() {
        return props.permissions.checkSubfunctionPermission("CONFIGURATION", "STRUCTURE", "ACTIVATE_TEAMLET", "CREATE")
    }

    //  Can Delete Teamlet ?
    function canDelete() {
        return props.permissions.checkSubfunctionPermission("CONFIGURATION", "STRUCTURE", "ACTIVATE_TEAMLET", "CREATE")
    }

    /**
    * @name deleteTeamlet
    * @description Delete Teamlets
    * @param
    * @returns {json}
    */
    async function deleteTeamlet() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        await fetch(`${config.activateServer}:${config.activatePORT}/deleteTeamlet`, {
            method: 'POST',
            body: JSON.stringify({
                token: token,
                deleteID: deleteID
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(async _response => {
            //  Check for Success
            if (_response.ok) {
                try {
                    const data = await _response.json();
                    setDeleteID('');
                    setShowDeleteTeamletModal(false);
                    //  Reload page
                    loadProfileInfo();
                } catch (e) {
                    console.log("ERROR deleting Teamlet " + JSON.stringify(e));
                }
            } else {
                //  Error returned from data service, display to user
                _response.json().then(data => {

                    //  Create Message
                    const _statusCode = _response.status.toString();
                    const _statusmessage = JSON.stringify(data)

                    setShowErrorMsg(_statusmessage + " (Code: " + _statusCode + ")")

                    //  Show Modal
                    setShowErrorModal(true);
                })
            }
        })
    }

    //  Handle page submit
    const handleSubmit = (event: any) => {

        const form = event.currentTarget;
        event.preventDefault();

        const validator = new EmailValidator();
        var elements = form.elements;
        const cellphoneElement = elements.namedItem('cellPhone');
        const emailElement = elements.namedItem('emailAddress');

        let isCellphoneValid = true;
        let isEmailValid = true;
        let isLandlineValid = true;

        for (let i = 0; i < elements.length; i++) {
            let element = elements[i]
            if (element.type === "text" && element.id === 'emailAddress') {
                //  Let blanks display normal error message                
                if (element.value === '') {
                    isEmailValid = false;
                    element.classList.add('is-invalid');
                    element.required = true;
                    element.nextElementSibling.innerHTML = 'Please enter a valid email address'
                } else {
                    if (validator.isValid(emailAddress) === false) {
                        isEmailValid = false;
                        element.classList.add('is-invalid');
                        element.required = true;
                        //@ts-ignore
                        element.nextElementSibling.innerHTML = 'Please enter a valid email address'
                    } else {
                        isEmailValid = true;
                        element.required = false;
                        element.classList.remove('is-invalid');
                    }
                }
            }

            if (element.type === "text" && element.id === 'cellPhoneNumber') {

                if (element.value !== '') {
                    if (validPhoneNumber(cellPhoneNumber) === false) {
                        isCellphoneValid = false;
                        element.classList.add('is-invalid');
                        element.required = true;
                        element.nextElementSibling.innerHTML = "Please enter the cell Phone Number (xxx) xxx-xxxx."
                    } else {
                        element.classList.remove('is-invalid');
                        element.required = false;
                        element.nextElementSibling.innerHTML = '   ';
                    }
                } else {
                    element.classList.remove('is-invalid');
                    element.required = false;
                    element.nextElementSibling.innerHTML = '   ';
                }

            }

            if (element.type === "text" && element.id === 'landLineNumber') {
                if (element.value !== '') {
                    if (validPhoneNumber(landLineNumber) === false) {
                        isLandlineValid = false;
                        element.classList.add('is-invalid');
                        element.required = true;
                        element.nextElementSibling.innerHTML = "Please enter the landline Number (xxx) xxx-xxxx."
                    } else {
                        element.classList.remove('is-invalid');
                        element.required = false;
                        element.nextElementSibling.innerHTML = '   ';
                    }
                } else {
                    element.classList.remove('is-invalid');
                    element.required = false;
                    element.nextElementSibling.innerHTML = '   ';
                }
            }
        }

        if (!isEmailValid || !isCellphoneValid || !isLandlineValid) {
            event.stopPropagation();
            setValidated(true);
            return;
        }
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }
        saveProfileInfo();
    }

    /**
    * @name saveProfileInfo
    * @description Starts the Profile Save
    * @param  
    * @returns
    */
    async function saveProfileInfo() {
        showUpdateConfirmModal(true);

    };

    /**
         * @name updateUser
         * @description Updates the user
         * @param
         * @returns {json}
         */
    async function updateUser() {
        const token = sessionStorage.getItem('ACTIVATEtoken');

        await fetch(`${config.activateServer}:${config.activatePORT}/updateUser`, {
            method: 'POST',
            body: JSON.stringify({
                token: token,
                internalID,
                username,
                status,
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                preferredName,
                emailAddress,
                emailAddressObj,
                cellPhoneNumber,
                cellPhone,
                landLineNumber,
                landLineObj,
                requireChangePassword,
                roleID,
                finalOrganizations
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(_response => {
            //  Check for Success
            if (_response.ok) {
                try { 
                    setIsReadOnlyMode(() => {return true});
                    loadProfileInfo();              
                } catch (err) {
                    console.log("Error: " + err);
                }
            }
            else {

                //  Error returned from data service, display to user
                _response.json().then(data => {
                    //  Create Message
                    const _statusCode = _response.status.toString();
                    const _statusmessage = data.message
                    
                    //  Set message
                    setShowErrorMsg(_statusmessage + " (Code: " + _statusCode + ")")

                    //  Show Modal
                    setShowErrorModal(true);
                })
            }
        }).catch(err =>{
            //  Handle Exception
            //  Set message
            setShowErrorMsg(err.message)
            //  Show Dialog
            setShowErrorModal(true);
        })
    }
  
     /**
        * @name handleException
        * @description  Handle non-200 errors
        * @param  
        * @returns 
        */
      async function handleException(_resp: Response) {
        const error = await _resp.text();
        const parsedError = JSON.parse(error)

        let msg = "";
        msg += parsedError.message;

        //  Exception
        setShowErrorMsg(msg)
        setShowErrorModal(true);
    }

    /**
    * @name nestedIcon
    * @description Shows the Teamlet Icon
    * @param  
    * @returns 
    */
    function nestedIcon() {
        return (
            <>
                <FontAwesomeIcon className='nestLine' icon={faUserGroup} style={{ color: "navy" }}></FontAwesomeIcon>
            </>
        );
    }

    /**
    * @name huddleInfo
    * @description Shows the Huddle details
    * @param  
    * @returns 
    */
    function huddleInfo(e: any) {
        if (e.data.huddleSummary == null) {
            return (
                <>
                    <label className='titlesTeamlet'>{e.data.name}</label><br />
                    <label className='subTitles'>{e.data.description}</label>
                </>
            )
        } else {
            return (
                <>
                    <label className='titlesTeamlet'>{e.data.huddleSummary.name}</label><br />
                    <label className='subTitles'>{e.data.huddleSummary.description}</label>
                </>
            )
        }
    }

    //  @ts-ignore
    function trashClick(e) {
        //console.log(e.row.data)
    }

    //  Edit TeamLet onClick handler
    // @ts-ignore
    function editClick(e) {
        //  Check for row Object
        if (e.row != null) {
            //console.log(e.row.data)
        }
    }

    //  Edit Click from Organizations
    function editOrganization(){
        props.switchView("adminOrganization")
    }

    //@ts-ignore
    let siteList: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | JSX.Element[] | null | undefined = [];
    if (roleName === "ROLE_ORGANIZATION_ADMINISTRATOR" || roleName === "ROLE_ORGANIZATION_SUPER_USER") {
    mySites.forEach((item: any, index: number) => {

        //@ts-ignore
        siteList.push(React.Children.toArray(
          <section key={index + 1}>
            <p key={item.internalID}>
              <span className="site-name">{item.name}</span><br/>
              {item.streetAddress.street}<br/>
              {item.streetAddress.cityName}, {item.streetAddress.stateName} {item.streetAddress.zipcode}
            </p>
            <div className="points-of-contact">
              <h3>Point of Contact</h3>
              <div className="contacts">
                {item.primaryPointOfContact != null ?
                <p>
                  {item.primaryPointOfContact.firstName} {item.primaryPointOfContact.lastName}<br/>
                  {item.primaryPointOfContact != null && item.primaryPointOfContact.emailAddresses != null && item.primaryPointOfContact.emailAddresses[0] !== undefined ? <a href={`mailto:${item.primaryPointOfContact.emailAddresses[0].address}`}>{item.primaryPointOfContact.emailAddresses[0].address}</a> : <span className="text-na">No Email Provided</span>}<br/>
                  {item.primaryPointOfContact != null && item.primaryPointOfContact.phoneNumbers != null && item.primaryPointOfContact.phoneNumbers[0] !== undefined ? item.primaryPointOfContact.phoneNumbers[0].number : <span className="text-na">No Phone Provided</span>}
                </p>
                : <></> }
                {item.secondaryPointOfContact != null ?
                <p>
                    {item.secondaryPointOfContact.firstName} {item.secondaryPointOfContact.lastName}<br/>
                    {item.secondaryPointOfContact.emailAddresses != null && item.secondaryPointOfContact.emailAddresses[0] !== undefined ? <a href={`mailto:${item.secondaryPointOfContact.emailAddresses[0].address}`}>{item.secondaryPointOfContact.emailAddresses[0].address}</a> : item.secondaryPointOfContact != null ? <span className="text-na">No Email Provided</span> : ""}<br/>
                    {item.secondaryPointOfContact.phoneNumbers != null && item.secondaryPointOfContact.phoneNumbers[0] !== undefined  ? item.secondaryPointOfContact.phoneNumbers[0].number : item.secondaryPointOfContact != null ? <span className="text-na">No Phone Provided</span> : ""}
                </p> 
                : <></> }
              </div>
            </div>
       </section>))
    })
    }

    return (

        <div>
            <LoadPanel visible={showProgress} message={"Loading My Profile Information"} showPane={true} position={position} showIndicator={true}></LoadPanel>
            <Container fluid>
                <Form validated={validated} noValidate onSubmit={handleSubmit}>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => props.switchView("MyProfile")}>My Profile</Breadcrumb.Item>
                        <Breadcrumb.Item active>{firstName} {lastName}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col lg={7} >
                            <div className="page-panel" id="profileInfo">
                                    <div className="panel-header profile-information">
                                        <h2>Profile Information</h2>
                                        {isReadOnlyMode ?
                                            <Card.Link onClick={() => setReadOnlyMode(false)}>Edit</Card.Link>
                                            :
                                            <Card.Link onClick={() => setReadOnlyMode(true)}>Cancel</Card.Link>
                                        }
                                    </div>
                                <Row>
                                    <Col>
                                        <h3>About</h3>
                                        <Row>

                                            <Form.Label className={isReadOnlyMode ? "" : "required"} column>
                                                First Name
                                            </Form.Label>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    required
                                                    name="firstName"
                                                    value={firstName}
                                                    onChange={e => setFirstName(e.target.value)} readOnly={isReadOnlyMode} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a first name
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label className={isReadOnlyMode ? "" : "required"} column>
                                                Last Name
                                            </Form.Label>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    required
                                                    name="lastName"
                                                    value={lastName}
                                                    onChange={e => setLastName(e.target.value)} readOnly={isReadOnlyMode} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a last name
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column>
                                                Preferred Name
                                            </Form.Label>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    name="preferredName"
                                                    value={preferredName}
                                                    onChange={e => setPreferredName(e.target.value)} readOnly={isReadOnlyMode} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column>
                                                Prefix
                                            </Form.Label>
                                            {isReadOnlyMode === true ?
                                                <Col>
                                                    <Form.Control
                                                        type="text"
                                                        name="prefix"
                                                        value={prefixNames !== undefined && prefixNames !== null && prefixNames.filter((i: any) => { return (i.value === prefix) }).length > 0 ?
                                                            prefixNames.filter((i: any) => { return (i.value === prefix) })[0].name : null}
                                                        readOnly={isReadOnlyMode} />
                                                </Col>
                                                :
                                                <Col>
                                                    <Form.Select
                                                        aria-label="Prefix"
                                                        name="prefix"
                                                        value={prefix}
                                                        onChange={prefixSelectChange}>
                                                        {prefixList}
                                                    </Form.Select>
                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            <Form.Label column>
                                                Suffix
                                            </Form.Label>
                                            {isReadOnlyMode === true ?
                                                <Col>
                                                    <Form.Control
                                                        type="text"
                                                        name="suffix"
                                                        value={suffixNames.filter((i: any) => { return (i.value === suffix) }).length > 0 ?
                                                            suffixNames.filter((i: any) => { return (i.value === suffix) })[0].name : null}
                                                        readOnly={isReadOnlyMode} />
                                                </Col>
                                                :
                                                <Col>
                                                    <Form.Select
                                                        aria-label="suffix"
                                                        name="suffix"
                                                        value={suffix}
                                                        onChange={suffixSelectChange}>
                                                        {suffixList}
                                                    </Form.Select>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                    <Col>
                                        <h3>Contact</h3>
                                        <Row>

                                            <Form.Label className={isReadOnlyMode ? "" : "required"} column>
                                                Email Address
                                            </Form.Label>
                                            <Col>
                                                <Form.Control
                                                    id="emailAddress"
                                                    type="text"
                                                    required
                                                    name="emailAddress"
                                                    value={emailAddress}
                                                    onChange={e => setEmailAddress(e.target.value)} readOnly={isReadOnlyMode} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a valid email address
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column>
                                                Cell Phone
                                            </Form.Label>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    id="cellPhoneNumber"
                                                    name="cellPhoneNumber"
                                                    value={cellPhoneNumber}
                                                    onChange={e => setCellPhoneNumber(e.target.value)} readOnly={isReadOnlyMode} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter the cell Phone Number in the format (xxx) xxx-xxxx.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column>
                                                Landline Phone
                                            </Form.Label>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    id="landLineNumber"
                                                    name="landLineNumber"
                                                    value={landLineNumber}
                                                    onChange={e => setLandLineNumber(e.target.value)} readOnly={isReadOnlyMode} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter the Landline Number in the format (xxx) xxx-xxxx.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {isReadOnlyMode === false ?
                                    <div className="form-actions">
                                        <Button className="people submit" type="submit" variant="primary">Save</Button>
                                        <Button className='people cancel' variant="secondary" type="button" onClick={() => setReadOnlyMode(true)}>Cancel</Button>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </Col>

                        {/* Second page column */}
                        <Col lg={5}>
                            <div className="page-panel" id="positionForSites">
                                <LoadPanel visible={showProgress} message={"Loading My Sites"} showPane={true} position={positionForSites} showIndicator={true}></LoadPanel>
                         
                                {roleName === "ROLE_ACTIVATE_ADMINISTRATOR" ?
                                    <div>
                                        <div className="panel-header">
                                            <h2>Organizations</h2>
                                            <Card.Link onClick={() => editOrganization()}>Edit</Card.Link>
                                        </div>

                                        <DataGrid id="grid-container" width={"100%"}
                                            ref={dgOrganizations}
                                            dataSource={orgData}
                                            showColumnLines={false}
                                            showRowLines={false}
                                            showBorders={false}
                                            focusStateEnabled={true}
                                            allowColumnResizing={true}
                                            noDataText='No ACTIVATE Organizations have been loaded'>
                                            <Paging defaultPageSize={2} defaultPageIndex={0} />
                                            <Selection mode="single" />
                                            <Column dataField="name" caption="Organization Name" width={'70%'} defaultSortOrder="asc" />
                                            <Column dataField="siteCount" width={'15%'} caption="Sites" />
                                            <Column dataField="staffCount" width={'15%'} caption="Users" />
                                        </DataGrid>
                                    </div>
                                    :
                                    <div>
                                        <div className="panel-header">
                                            {roleName === "ROLE_ORGANIZATION_SUPER_USER" ?
                                                <h2>My Site</h2>
                                            : 
                                                <h2>Organizations</h2>
                                            }
                                            <Card.Link onClick={() => editOrganization()}>Edit</Card.Link>
                                        </div>
                                        {siteList}
                                        <h2>Parent Organization</h2>
                                        <Anchor onClick={(e) => {goToDashBoard(e) }} title="View Org">{healthCenterParent}</Anchor><br/>
                                        <br/>
                                        {roleName !== "ROLE_ORGANIZATION_SUPER_USER" ?
                                            <>
                                            <div className="panel-header">
                                                <h2>Sites</h2>
                                            </div>
                                            <DataGrid id="grid-container" width={"100%"}
                                                dataSource={orgData}
                                                showColumnLines={false}
                                                showRowLines={false}
                                                showBorders={false}
                                                focusStateEnabled={true}
                                                allowColumnResizing={true}
                                                noDataText='No Site Information Loaded'>
                                                <Paging defaultPageSize={2} defaultPageIndex={0} />
                                                <Selection mode="single" />
                                                <Column dataField="name" caption="Site Name" width={'70%'} defaultSortOrder="asc" />
                                                <Column dataField="staffCount" width={'15%'} caption="Health Staff" />
                                                <Column dataField="patientCount" width={'15%'} caption="Patients" />
                                            </DataGrid>
                                            </>
                                        : null}
                                    </div>
                                }
                            </div>

                            {roleName === "ROLE_ORGANIZATION_SUPER_USER" ?
                                <div className="page-panel" id="positionForTeamlets">
                                    <LoadPanel visible={showProgress} message={"Loading My Assigned Teamlets"} showPane={true} position={positionForTeamlets} showIndicator={true}></LoadPanel>
                                    <h2>My Assigned Teamlets</h2>
                                    {healthcareTeamLetSummary.length > 0 ?
                                    <section>
                                        {teamletList(healthcareTeamLetSummary)}
                                    </section>
                                    : <p>Not assigned to any teamlets</p>}
                                    </div>
                                    : null }
                            {roleName === "ROLE_ORGANIZATION_SUPER_USER" ?
                            <div className="page-panel">
                                <MyNextHuddle
                                    switchView={props.switchView} 
                                    permissions={props.permissions}
                                    setHuddleID={props.setHuddleID}
                                    setMeetingID={props.setMeetingID}
                                    setSelectedTeamletID={props.setSelectedTeamletID}
                                />
                            </div>
                            : null }

                        </Col>
                    </Row>

                </Form>
            </Container>
            <Modal show={showSaveModalPrompt}>
                <Modal.Header className="people">
                    <h2>Save Profile?</h2>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to save this Profile?
                </Modal.Body>
                <Modal.Footer>
                    <Button className="people submit" variant="primary" onClick={() => saveProfileConfirmed()}>Save</Button>
                    <Button className="people cancel" variant="secondary" onClick={() => showUpdateConfirmModal(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showErrorModal}>
                <Modal.Header className="error">
                    <h2>Database error</h2>
                </Modal.Header>
                <Modal.Body>
                    {showErrorMsg}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="error submit" onClick={() => setShowErrorModal(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteTeamletModal}>
                <Modal.Header className="people">
                    <h2>Delete Teamlet?</h2>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this Teamlet?  This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button className='people submit' variant="primary" onClick={() => deleteTeamlet()}>Delete</Button>
                    <Button className='people cancel' variant="secondary" onClick={() => setShowDeleteTeamletModal(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default MyProfile
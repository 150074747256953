/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState, useRef } from 'react';
import { Button, Breadcrumb, Container, Row, Col, Modal, Stack } from 'react-bootstrap';
import { DataGrid, MasterDetail, GroupPanel, SearchPanel, Selection, Column, Scrolling, Paging} from 'devextreme-react/data-grid';
import {Button as DevButton} from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { faPlusCircle, faL } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PermissionService from '../../Services/PermissionService';
import './AdminOrganizations.css';
const config = require('../../config.json');

function AdminOrganizations(props: 
    {
        switchView: (newView : string) => void,
        editOrganization: (editOrg : boolean, editOrgID : string, editOrgName : string, editOrgShortName : string, editOrgDescription : string, 
            editOrgParentOrganization : string, editOrgPrimaryPointOfContact : string, editOrgSecondaryPointOfContact: string, editOrgAddress : string, 
            editOrgTownCity : string, editOrgZip : string, editOrgState : string, editOrgPhone : string,
            editpatientsAssigned : boolean, editdevicesAssigned : boolean, editisParent : boolean, editStreetAddressID: string, 
            editPhoneNumberID: string, validate : boolean, editType: string, editOrgStatus: string, ehrassociated: boolean) => void,
        setParentOrgId: (ParentId: string) => void,
        roleName: string,
        permissions: PermissionService
    })
{
    // Constants
    const dgOrganizations = useRef<any>(null);
    const [orgData, setOrgData] = useState("");
    const [orgJSONData, setOrgJSONData] = useState<any[]>([]);
    const [showDeleteOrganizationModal, setShowDeleteOrganizationModal] = useState(false);
    const [deleteID, setDeleteID] = useState("");
    const [orgJSONSiteData, setOrgJSONSiteData] = useState<any[]>([]);
    const [selectedOrgs, setSelectedOrgs] =  useState<any[]>([]);
    const [selectedPageIndex, setSelectedPageIndex] = useState(0);
    
    //  Load Organizations and Look-ups
    useEffect(() => {
        loadOrganizations();
    }, [])
     
    //  Gets the Organizations
    async function loadOrganizations(){
        // Start Spinner
        dgOrganizations.current?.instance.beginCustomLoading();
        const token = sessionStorage.getItem('ACTIVATEtoken');
        
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getHealthCenterParentEntries`, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(async response => {
            //  Check for Success
            if (response.ok)
            {
                try {
                    const _json = await response.json();
                    setOrgData(_json);
        
                    const _data = JSON.parse(JSON.stringify(_json))
                    processOrgs(_data)
        
                } catch(err){
                    console.log("ERROR " + err);
                }
            } else {
                // ToDo:  Handle non-200s
                console.log("Received HTTP status" + response.status.toString())
            }
        }).catch(err =>{
            // Stop Spinner
            dgOrganizations.current?.instance.endCustomLoading();
            console.log("Error in loadOrganizations: " + err);
        })

        // Stop Spinner
        dgOrganizations.current?.instance.endCustomLoading();
    }

    //  Delete Organization
    async function deleteOrganization(orgId : string){
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/deleteHealthCenter`, {
            method: 'POST',
            body: JSON.stringify({ token : token, id : orgId }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(async response => {
            //  Check for Success
            if (response.ok)
            {
                try {
                    const data = await response.json();
                    showDeleteModal(orgId, false)
                    loadOrganizations();
                } catch (e) {
                    console.log("ERROR DELETING ORGANIZATION " + JSON.stringify(e));
                }
            } else {
                // ToDo:  Handle non-200s
                console.log("Received HTTP status" + response.status.toString())
            }
        })
    }

    //  Display add Org View
    function showAddOrganizationView(){
        props.switchView("addNewOrganization")
    }

    //  Display add new Site  View
    function showAddSiteView(e:any){
        // Before we display a Add Site ui, the parent ord id should be set
        props.setParentOrgId(e.key)
        props.switchView("addNewSite")
    }
    
    //  Delete Pop-up
    function showDeleteModal(id : string, toggle : boolean) {
        setDeleteID(id);
        setShowDeleteOrganizationModal(toggle);
    }

    //  Edit Organization onClick handler
    function editClick(e : any) {
        //  Check for row Object
        if (e.row != null)  {
            const org = e.row.data
            props.editOrganization(
                true, org.internalID, org.name, org.shortName, org.description, 
                org.parentOrganization == null ? org.ParentId : null , org.primaryPointOfContact, org.secondaryPointOfContact, org.streetAddress.street, 
                org.streetAddress.cityName, org.streetAddress.zipcode, org.streetAddress.stateName, org.phoneNumber.number,
                org.acceptsPatients, org.acceptsDevices, org.canBeParent, org.streetAddress.internalID,
                org.phoneNumber.internalID, true, org.canBeParent ? "Organization" : "Site", org.organizationStatus, org.ehrassociated
            );
            
            props.switchView("addOrganization");
        }
    }

    //  Edit Organization from hyperlink onClick handler
    function editOrgLinkClick(e:any) {
        //@ts-ignore
        e = orgData.filter((a : any) => a.internalID === e.currentTarget.id)

        //  Check for found Organization
        if (e != null){
            const org = e[0]
            props.editOrganization(
                true, org.internalID, org.name, org.shortName, org.description, 
                org.parentOrganization == null ? org.ParentId : null , org.primaryPointOfContact, org.secondaryPointOfContact, org.streetAddress.street, 
                org.streetAddress.cityName, org.streetAddress.zipcode, org.streetAddress.stateName, org.phoneNumber.number,
                org.acceptsPatients, org.acceptsDevices, org.canBeParent, org.streetAddress.internalID,
                org.phoneNumber.internalID, true, org.canBeParent ? "Organization" : "Site", org.organizationStatus, org.ehrassociated
            );
            
            props.switchView("addOrganization");
        }
    }

    //  Edit Site from hyperlink onClick handler
    function editSiteLinkClick(e:any) {
        // @ts-ignore
        e = orgJSONSiteData.filter(a => a.internalID === e.currentTarget.id)

        //  Check for found Organization
        if (e != null){
            const org = e[0]
            props.editOrganization(
                true, org.internalID, org.name, org.shortName, org.description, 
                org.parentOrganization == null ? org.ParentId : null , org.primaryPointOfContact, org.secondaryPointOfContact, org.streetAddress.street, 
                org.streetAddress.cityName, org.streetAddress.zipcode, org.streetAddress.stateName, org.phoneNumber.number,
                org.acceptsPatients, org.acceptsDevices, org.canBeParent, org.streetAddress.internalID,
                org.phoneNumber.internalID, true, org.canBeParent ? "Organization" : "Site", org.organizationStatus, org.ehrassociated
            );
            
            props.switchView("addOrganization");
        }
    }

    function trashClick(e : any) {
        showDeleteModal(e.row.data.internalID, true)
        e.event.preventDefault();
    }
    
    //  Filters Sites By Parent
    function getTasks(key:any) {
        return new DataSource({
            store: new ArrayStore({
            data: orgJSONSiteData,
            key: 'internalID',
            }),
            filter: ['ParentId', '=', key],
        });
    }

    //  Return the Sites detail view
    function MasterDetailViewUI(e:any){
        const ds = getTasks(e.key)
        return (
            <>
                <DataGrid id="grid-container" 
                        keyExpr="internalID"
                        width={"100%"}
                        showBorders={false}
                        dataSource={ds}
                        showColumnHeaders={false} showColumnLines={false} showRowLines={false}
                        noDataText='No ACTIVATE Sites have been added to this Organization.'>
                            <GroupPanel visible={false} />
                            <Selection mode="none" />
                            <Column caption="" width={30} cellRender={nestedIcon}/>
                            <Column dataField="name" caption="Site Name" width={300} cellRender={SiteHyperLink} defaultSortOrder="asc" />
                            <Column dataField="streetAddress.street" width={250} caption="Address" />
                            <Column dataField="streetAddress.cityName" width={150} caption="Town/City" />
                            <Column dataField="streetAddress.stateName" width={150} caption="State" />
                            <Column dataField="primaryPointOfContactName" caption="Primary Contact" width={195}/>
                            <Column dataField="userCount" alignment={"center"} width={50} caption="Users" />
                            <Column dataField="ehrassociated" width={50} alignment={"center"} caption="EHR Integrated" />
                            <Column type="buttons" caption="Action" width={70}>
                                <DevButton hint="edit" icon="edit" onClick={editClick} />
                                <DevButton hint="delete" visible={false} icon="trash" onClick={trashClick} />
                            </Column>
                            <Column dataField="ParentId" width={175} visible={false} caption="Number" />
                            <Column dataField="phoneNumber.number" width={175} visible={false} caption="Number" />
                            <Column dataField="organizationStatus" width={50} visible={false} caption="organizationStatus" />
                            <Column dataField="acceptsPatients" width={50} visible={false} caption="acceptsPatients" />
                            <Column dataField="acceptsDevices" width={50} visible={false} caption="acceptsDevices" />
                            <Column dataField="canBeParent" width={50} visible={false} caption="canBeParent" />
                            <Column dataField="primaryPointOfContact" width={1} visible={false} caption="primaryPointOfContact" />
                            <Column dataField="secondaryPointOfContact" width={1} visible={false} caption="secondaryPointOfContact" />
                    </DataGrid>
                    <FontAwesomeIcon className='plusButton' icon={faPlusCircle} onClick={() => showAddSiteView(e)}></FontAwesomeIcon>
                    <Button variant="link" onClick={() => showAddSiteView(e)}>Add Site</Button>
          </>
        );
    }
    
    //  Create HyperLink on Org Name
    function OrgHyperLink(org: any){
        return (
        <>
            <div>
                <a className='plusButton' onClick={editOrgLinkClick} id={org.data.internalID}>{org.data.name}</a>
            </div>
        </>
        );
    }

    //  Create HyperLink on Site Name
    function SiteHyperLink(org: any){
        return (
            <>
                <div>
                    <a className='plusButton' onClick={editSiteLinkClick} id={org.data.internalID}>{org.data.name}</a>
                </div>
            </>
        );
    }

    //  Child Node connect
    function nestedIcon(){
        return (
            <>
                <FontAwesomeIcon className='nestLine' icon={faL}></FontAwesomeIcon>
            </>
        );
    }

    //  Handle Grid Page change
    function handlePageIndexChange(e: any){
        setSelectedPageIndex(e);
    }

    //  Process Orgs 
    //  The Grid's Master-view controller required a flat parent-child dataset
    //  This function sets up the data to properly render in the UI
    function processOrgs(_orgData : any) {
        const orgs = [];
        const sites = [];
   
        for (let iLp = 0; iLp < _orgData.length; iLp++) {
            const temp_org = _orgData[iLp];
            const temp_sites = _orgData[iLp].sites

            const streetaddress = [];
            
            const orgStreetAddress = {
                "internalID": temp_org.streetAddress.internalID,
                "street": temp_org.streetAddress.street,
                "cityName": temp_org.streetAddress.cityName,
                "stateName": temp_org.streetAddress.stateName,
                "zipcode": temp_org.streetAddress.zipcode,
                "addressType": temp_org.streetAddress.addressType,
                "description": temp_org.streetAddress.description,
                "preference": temp_org.streetAddress.preference,
            }
   
            streetaddress.push(orgStreetAddress)

            const phoneNumber = [];
        
            const orgPhoneNumber = {
                "internalID" : temp_org.phoneNumber.internalID,
                "number" : temp_org.phoneNumber.number,
                "numberType" : temp_org.phoneNumber.numberType,
                "description" :	temp_org.phoneNumber.description,
                "preference" : temp_org.phoneNumber.preference,
            }
            phoneNumber.push(orgPhoneNumber)
    
            orgs.push({
                "internalID" : temp_org.internalID,
                "name" : temp_org.name,
                "description" : temp_org.description,
                "streetAddress" : streetaddress,
                "phoneNumber" : phoneNumber,
                "organizationStatus" : temp_org.organizationStatus,
                "shortName"	:	temp_org.shortName,
                "acceptsPatients"	:	temp_org.acceptsPatients,
                "acceptsDevices"	:	temp_org.acceptsDevices,
                "canBeParent"	:	temp_org.canBeParent,
                "userCount"	: temp_org.userCount,
                "sites" : temp_sites,
                "primaryPointOfContact" : temp_org.primaryPointOfContact,
                "secondaryPointOfContact" : temp_org.secondaryPointOfContact,
                "primaryPointOfContactName": temp_org.primaryPointOfContactName,
                "secondaryPointOfContactName": temp_org.secondaryPointOfContactName,
                "ehrassociated": temp_org.ehrassociated
            });

            //  Store Sites
            for (let iLp1 = 0; iLp1 < temp_sites.length; iLp1++) {
                const temp_site = temp_sites[iLp1];

                const siteStreetAddress = {
                    "internalID": temp_site.streetAddress.internalID,
                    "street": temp_site.streetAddress.street,
                    "cityName": temp_site.streetAddress.cityName,
                    "stateName": temp_site.streetAddress.stateName,
                    "zipcode": temp_site.streetAddress.zipcode,
                    "addressType": temp_site.streetAddress.addressType,
                    "description": temp_site.streetAddress.description,
                    "preference": temp_site.streetAddress.preference,
                }

                const sitePhoneNumber = {
                    "internalID" : temp_site.phoneNumber.internalID,
                    "number" : temp_site.phoneNumber.number,
                    "numberType" : temp_site.phoneNumber.numberType,
                    "description" :	temp_site.phoneNumber.description,
                    "preference" : temp_site.phoneNumber.preference,
                }
               
                sites.push({
                    "internalID" : temp_site.internalID,
                    "name" : temp_site.name,
                    "description" : temp_site.description,
                    "streetAddress" : siteStreetAddress,
                    "phoneNumber" : sitePhoneNumber,
                    "organizationStatus" : temp_site.organizationStatus,
                    "shortName"	:	temp_site.shortName,
                    "acceptsPatients"	:	temp_site.acceptsPatients,
                    "acceptsDevices"	:	temp_site.acceptsDevices,
                    "canBeParent"	:	temp_site.canBeParent,
                    "userCount"	: temp_site.userCount,
                    "sites" : null,
                    "ParentId" : temp_org.internalID,
                    "primaryPointOfContact" : temp_site.primaryPointOfContact,
                    "secondaryPointOfContact" : temp_site.secondaryPointOfContact,
                    "primaryPointOfContactName": temp_site.primaryPointOfContactName,
                    "secondaryPointOfContactName": temp_site.secondaryPointOfContactName,
                    "ehrassociated": temp_site.ehrassociated
                });
            }
        }

        //  Set State
        setOrgJSONData(orgs)
        setOrgJSONSiteData(sites)
    }

    //  Screen Display
    return(
            <div>
                <Container fluid>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => props.switchView("configuration")}>Configuration</Breadcrumb.Item>
                        <Breadcrumb.Item active>Organization</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="page-panel">
                        <div className="panel-function-header">
                            {props.permissions.checkSubfunctionPermission("CONFIGURATION", "STRUCTURE", "ORGANIZATION_INFORMATION", "CREATE") ?
                            <Button className="btn-function-button" onClick={() => showAddOrganizationView()} id="addOrgButton">Add Organization</Button>
                            : null 
                            }
                        </div>
                            <DataGrid id="grid-container" 
                                ref={dgOrganizations}
                                showColumnLines={false} 
                                showRowLines={false} 
                                keyExpr="internalID"
                                showBorders={true}
                                focusStateEnabled={true}
                                dataSource={orgData}
                                selectedRowKeys={selectedOrgs} 
                                allowColumnResizing={true}
                                width={"100%"}
                                noDataText='No ACTIVATE Organizations have been loaded'>
                                    <Paging enabled={true} defaultPageSize={2} defaultPageIndex={0} pageIndex={selectedPageIndex} onPageIndexChange={handlePageIndexChange} />
                                    <GroupPanel visible={false} />
                                    <SearchPanel visible={false} />
                                    <Selection mode="single" />
                                    <Column dataField="name" caption="Name" width={320} cellRender={OrgHyperLink} defaultSortOrder="asc"/>
                                    <Column dataField="streetAddress.street" width={250} caption="Address" />
                                    <Column dataField="streetAddress.cityName" width={150} caption="Town/City" />
                                    <Column dataField="streetAddress.stateName" width={150} caption="State" />
                                    <Column dataField="primaryPointOfContactName" caption="Primary Contact" width={185}/>
                                    <Column dataField="userCount" width={50} alignment={"center"} caption="Users" />
                                    <Column dataField="ehrassociated" width={50} alignment={"center"} caption="EHR Integrated" />
                                    <Column type="buttons" caption="Action" width={70}>
                                        <DevButton hint="Edit" icon="edit" onClick={editClick} />
                                        <DevButton hint="Delete" visible={false} icon="trash" onClick={trashClick} />
                                    </Column>
                                    <Column dataField="organizationStatus" width={50} visible={false} caption="organizationStatus" />
                                    <Column dataField="acceptsPatients" width={50} visible={false} caption="acceptsPatients" />
                                    <Column dataField="acceptsDevices" width={50} visible={false} caption="acceptsDevices" />
                                    <Column dataField="canBeParent" width={50} visible={false} caption="canBeParent" />
                                    <Column dataField="phoneNumber.number" width={150} visible={false} caption="Number" />
                                    <Column dataField="primaryPointOfContact" width={1} visible={false} caption="primaryPointOfContact" />
                                    <Column dataField="secondaryPointOfContact" width={1} visible={false} caption="secondaryPointOfContact" />
                                    <MasterDetail
                                        enabled={true}
                                        render={MasterDetailViewUI}
                                        autoExpandAll={true}
                                    />
                            </DataGrid>
                        </div>
                </Container>
            <Modal show={showDeleteOrganizationModal}>
                <Modal.Header className="system">
                    <h2>Delete Organization?</h2>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this Organization?  This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button className="system cancel" onClick={() => showDeleteModal("", false)}>Cancel</Button>
                    <Button className="system submit" onClick={() => deleteOrganization(deleteID)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default AdminOrganizations




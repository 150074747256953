// import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const RegistrationFooter=() => {
  const navigate = useNavigate();
    function showAboutPage() {
        navigate('/about', { state: {} });
    }

    function showHelpPage() {
        navigate('/help', { state: {} });
    }
  return (
    <Form.Group className="login-footer">
        <Button variant="link" onClick={() => showAboutPage()}>About</Button>| 
        <Button variant="link" onClick={() => showHelpPage()}>Help</Button>
    </Form.Group>
  )
}

export default RegistrationFooter;

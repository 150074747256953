import React from 'react';
import { Button, Container, Row, Col, Breadcrumb, Form, FormGroup, Modal } from 'react-bootstrap';
import DateBox from 'devextreme-react/date-box';
import List from 'devextreme-react/list';
import moment from 'moment';
import PermissionService from '../../Services/PermissionService';
import './PatientInformation.css';
const config = require('../../config.json');

type PatientInformationEditProps = {
    switchView: (newView: string) => void,
    permissions: PermissionService,
    patientID: string
}

type PatientInformationEditState = {
    username: string,
    firstName: string,
    middleName: string,
    lastName: string,
    preferredName: string,
    prefix: string,
    suffix: string,
    emailAddress: string,
    cellPhone: string,
    landlinePhone: string,
    street: string,
    street2: string,
    cityName: string,
    stateName: string,
    zipcode: string,
    country: string,
    organization: any;
    monitors: any,
    monitorThresholds: any,

    //  Temp variables for cancelling edit
    _username: string,
    _firstName: string,
    _middleName: string,
    _lastName: string,
    _preferredName: string,
    _prefix: string,
    _suffix: string,
    _emailAddress: string,
    _cellPhone: string,
    _landlinePhone: string,
    _street: string,
    _street2: string,
    _cityName: string,
    _stateName: string,
    _zipcode: string,
    _country: string,
    _organization: any,
    _monitors: any,
    _monitorThresholds: any,

    DOB: string,
    dateOfBirth: string,
    MRN: string,
    birthGender: string,
    primaryLanguage: string,
    secondaryLanguage: string,
    maritalStatus: string,
    genderIdentity: string,
    preferredContact: string,
    patientID: string,
    pronouns: any,
    race: string,
    ethnicity: string,

    //  Temp variables for cancelling edit
    _MRN: string,
    _birthGender: string,
    _primaryLanguage: string,
    _secondaryLanguage: string,
    _maritalStatus: string,
    _genderIdentity: string,
    _preferredContact: string,
    _patientID: string,
    _pronouns: any,
    _race: string,
    _ethnicity: string,
    _DOB: string,

    //  Look-Up Lists
    prefixList: any,
    suffixList: any,
    stateDisplayList: any,
    genderIdentityList: any,
    languageList: any,
    raceList: any,
    ethnicityList: any,
    countryList: any,
    maritalStatusList: any,
    genderAssignmentList: any,
    preferredContactList: any,
    pathwayList: any,
    pathwayData: any,
    badSubmissionError: string,

    //  Page Level state vars
    patientInfovalidated: boolean;
    pronounList: any,
    finalPathways: any,

    isReadOnlyMode: boolean,
    validationStatus: any,
    validationErrors: any,
    cellPhoneID: string,
    emailAddressID: string,
    landlinePhoneID: string,
    streetAddressID: string,
    _streetAddressID: string,
    selectedTeamlet: any,
    teamletRefsArray: any,
    pathwayRefsArray: any,
    teamletArray: any,
    showErrorModal: boolean,
    showErrorMessage: string,
    errorMessageTitle: string,
    errorUserMessage: string,
    errorServerMessage: string,

    // Used for EHR Patient
    patientSource: string,
    ehrPatientID: string,
    PCPNPIidentifier: string,
    PCPname: string,
    EHRemail: string,
}

export default class PatientInformationEdit
    extends React.Component<PatientInformationEditProps, PatientInformationEditState, HTMLSelectElement> {
        
    constructor(props: PatientInformationEditProps) {
        super(props);
        
        this.state = {
            username: '',
            firstName: '',
            middleName: '',
            lastName: '',
            preferredName: '',
            prefix: '',
            suffix: '',
            emailAddress: '',
            cellPhone: '',
            landlinePhone: '',
            street: '',
            street2: '',
            cityName: '',
            stateName: '',
            zipcode: '',
            country: '',
            organization: {},
            monitors: [],
            monitorThresholds: [],

            //  Temp variables for cancelling edit
            _username: '',
            _firstName: '',
            _middleName: '',
            _lastName: '',
            _preferredName: '',
            _prefix: '',
            _suffix: '',
            _emailAddress: '',
            _cellPhone: '',
            _landlinePhone: '',
            _street: '',
            _street2: '',
            _cityName: '',
            _stateName: '',
            _zipcode: '',
            _country: '',
            _organization: [],
            _monitors: [],
            _monitorThresholds: [],

            DOB: '',
            dateOfBirth: '',
            MRN: '',
            birthGender: '',
            primaryLanguage: '',
            secondaryLanguage: '',
            maritalStatus: '',
            genderIdentity: '',
            preferredContact: '',
            patientID: this.props.patientID,
            pronouns: [],
            race: '',
            ethnicity: '',

            //  Temp variables for cancelling edit
            _MRN: '',
            _birthGender: '',
            _primaryLanguage: '',
            _secondaryLanguage: '',
            _maritalStatus: '',
            _genderIdentity: '',
            _preferredContact: '',
            _patientID: '',
            _pronouns: [],
            _race: '',
            _ethnicity: '',
            _DOB: '',

            //  Look-Up Lists
            prefixList: [],
            suffixList: [],
            stateDisplayList: [],
            genderIdentityList: [],
            languageList: [],
            raceList: [],
            ethnicityList: [],
            countryList: [],
            maritalStatusList: [],
            genderAssignmentList: [],
            preferredContactList: [],
            pathwayList: [],
            pathwayData: [],
            badSubmissionError: '',

            //  Page Level state vars
            patientInfovalidated: false,
            pronounList: [],
            finalPathways: [],

            isReadOnlyMode: false,
            validationStatus: 'valid',
            validationErrors: [],
            cellPhoneID: '',
            emailAddressID: '',
            landlinePhoneID: '',
            streetAddressID: '',
            _streetAddressID: '',
            selectedTeamlet: [],
            teamletRefsArray: [],
            pathwayRefsArray: [],
            teamletArray: [],
            showErrorModal: false,
            showErrorMessage:'',
            errorMessageTitle: '',
            errorUserMessage: '',
            errorServerMessage: '',
            patientSource: '',
            ehrPatientID: '',
            PCPNPIidentifier: '',
            PCPname: '',
            EHRemail: '',
        };

        this.loadPatientAccount = this.loadPatientAccount.bind(this);
        this.loadLookup = this.loadLookup.bind(this);
        this.setReadOnlyMode = this.setReadOnlyMode.bind(this);
        this.cancelPatientEdit = this.cancelPatientEdit.bind(this);
        this.prefixSelectChange = this.prefixSelectChange.bind(this);
        this.suffixSelectChange = this.suffixSelectChange.bind(this);
        this.stateSelectChange = this.stateSelectChange.bind(this);
        this.countrySelectChange = this.countrySelectChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.returnToPatient = this.returnToPatient.bind(this);
        this.validateDOB = this.validateDOB.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addOrRemovePathway = this.addOrRemovePathway.bind(this);
        this.setInitialTeamletValues = this.setInitialTeamletValues.bind(this);
        this.handleException = this.handleException.bind(this);
        this.onSelectedPronounChange = this.onSelectedPronounChange.bind(this);
        this.createPathwayUIArray = this.createPathwayUIArray.bind(this);
    }

    
    // first steps, load the patient data and the assignments
    componentDidMount() {

        //  Load Patient Account
        this.loadPatientAccount(this.props.patientID);
        //  Load Prefix
        this.loadLookup('Prefix');
        //  Load Suffix
        this.loadLookup('Ordinal');
        //  Load GenderIdentity
        this.loadLookup('GenderIdentity');
        //  Load Race
        this.loadLookup('Race');
        //  Load Ethnicity
        this.loadLookup('Ethnicity');
        //  Load Country
        this.loadLookup('Country');
        //  Marital Status
        this.loadLookup('MaritalStatus');
        //  Sex at Birth
        this.loadLookup('GenderAssignment');
        //  Load State Options
        this.loadLookup('USState');
        //  Load Pronoun
        this.loadLookup('Pronoun');
        //  Load PreferredContactType
        this.loadLookup('PreferredContactType');
        //  Load Language
        this.loadLookup('Language');
    }

    resetError() {
        this.setState({ showErrorModal: false });
    }

    /**
    * @name onSelectedPronounChange
    * @description Updates the Selected Pronoun
    * @param  
    * @returns 
    */
    onSelectedPronounChange(e: any) {
        this.setState({ pronouns: e });
    }

    /**
     * setInitialTeamletValues
     * @param pathways 
     * @param patientPathways 
     */
    setInitialTeamletValues(pathways: any, patientPathways: any) {
        const selectedteamletvalues: any[] = [];
        const temppwayArray: any[] = [];
        const tempArray: any[] = [];

        pathways.forEach((p: any, index: number) => {
            let pathwayRef = React.createRef();
            temppwayArray.push(pathwayRef);
            let teamletRef = React.createRef();
            tempArray.push(teamletRef);

            const match = patientPathways.findIndex((j: any) => {
                return (j.template === p.internalID);
            });
            if (match > -1) {
                //if (patientPathways[match].hasOwnProperty('healthcareTeam')) {
                //    selectedteamletvalues.push(patientPathways[match].healthcareTeam.internalID)}
            }   else selectedteamletvalues.push('');

        });

        this.setState({ selectedTeamlet: selectedteamletvalues });
        this.setState({ teamletRefsArray: tempArray });
        this.setState({ pathwayRefsArray: temppwayArray });
    }

    /**
    * @name loadPatientAccount
    * @description Gets the Patient Account Info
    * @param  
    * @returns {json} 
    */
    async loadPatientAccount(patientID: string) {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getPatientSummaryById`, {
            method: 'POST',
            body: JSON.stringify({ token: token, patientID: patientID }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        });
        try {
            const data = await response.json();

            const mappedPathways = data.pathways.map((pw: any) => {
                if (pw.healthcareTeam===undefined) pw.healthcareTeam=null;
                return {
                    internalID: pw.internalID,
                    template: pw.template,
                    healthcareTeam: pw.healthcareTeam,
                    patient: pw.patient
                }
            });
   
            const pathwayData = await fetch(`${config.activateServer}:${config.activatePORT}/getPathways`, {
                method: 'POST',
                body: JSON.stringify({ token: token, organizationID: data.organization.internalID }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode: 'cors'
                //agent : httpsAgent,
            });

            try {
                const pwayData = await pathwayData.json();
                this.setInitialTeamletValues(pwayData, mappedPathways);
                this.createPathwayUIArray(pwayData, mappedPathways);
                this.createTeamletUIArray(pwayData, mappedPathways);

            } catch (err) {
                console.log("ERROR " + err);
            }

            this.setState({
                patientSource: data.patientSource
            });
            if (this.props.permissions.isEhrOrganization) {
                this.setState({
                    ehrPatientID: data.ehrPatientID,
                    PCPNPIidentifier: data.ehrPCPID,
                    PCPname: data.ehrPCPName,
                });
            }

            //  Fields that can be updated
            this.setState({ finalPathways: mappedPathways });
            this.setState({ firstName: data.firstName });
            this.setState({ middleName: data.middleName });
            this.setState({ lastName: data.lastName });
            this.setState({ preferredName: data.preferredName });
            this.setState({ organization: data.organization });
            this.setState({ prefix: data.prefix == null ? 'Not Provided' : data.prefix });
            this.setState({ suffix: data.suffix == null ? 'Not Provided' : data.suffix });
            this.setState({ emailAddress: data.emailAddress.name });
            this.setState({ cellPhone: data.cellPhone.name });
            this.setState({ landlinePhone: data.landlinePhone.name });
            this.setState({ monitors: data.monitors });
            this.setState({ monitorThresholds: data.monitorThresholds });
            // setting original internal ids for the case where the user changes teamlets on a pathway, 
            // then decides to set back to original, we need to keep the original internal ID


            //  Check for address
            if (data.streetAddress != null) {
                this.setState({ street: data.streetAddress.street });
                this.setState({ street2: data.streetAddress.street2 });
                this.setState({ cityName: data.streetAddress.cityName });
                this.setState({ stateName: data.streetAddress.stateName});
                this.setState({ zipcode: data.streetAddress.zipcode });
                this.setState({ streetAddressID: data.streetAddress.internalID });
                this.setState({ country: data.streetAddress.country});
            }

            //  State for cancelling edit
            this.setState({ _firstName: data.firstName });
            this.setState({ _middleName: data.middleName });
            this.setState({ _lastName: data.lastName });
            this.setState({ _preferredName: data.preferredName });
            this.setState({ _organization: data.organization });
            this.setState({ _prefix: data.prefix == null ? '' : data.prefix });
            this.setState({ _suffix: data.suffix == null ? '' : data.suffix });
            this.setState({ _emailAddress: data.emailAddress.name });
            this.setState({ _cellPhone: data.cellPhone.name });
            this.setState({ _landlinePhone: data.landlinePhone.name });

            //  Check for address
            if (data.streetAddress != null) {
                this.setState({ _street: data.streetAddress.street });
                this.setState({ _street2: data.streetAddress.street2 });
                this.setState({ _cityName: data.streetAddress.cityName });
                this.setState({ _stateName: data.streetAddress.stateName });
                this.setState({ _zipcode: data.streetAddress.zipcode });
                this.setState({ _streetAddressID: data.streetAddress.internalID });
                this.setState({ _country: data.streetAddress.country });
            }
            this.setState({ pronouns: data.pronouns });
            this.setState({ _pronouns: data.pronouns });
            //  Set Default Selections
            this.setState({ preferredContact: data.preferredContact });
            this.setState({ MRN: data.medicalRecordNumber });
            this.setState({ username: data.username });
            this.setState({ patientID: data.internalID });
            this.setState({ DOB: data.dateOfBirth });
            this.setState({ birthGender: data.birthGender });
            this.setState({ genderIdentity: data.genderIdentity });
            this.setState({ primaryLanguage: data.primaryLanguage });
            this.setState({ secondaryLanguage: data.secondaryLanguage });
            this.setState({ maritalStatus: data.maritalStatus });
            this.setState({ race: data.race });
            this.setState({ ethnicity: data.ethnicity });

            this.setState({ _preferredContact: data.preferredContact });
            this.setState({ _MRN: data.medicalRecordNumber });
            this.setState({ _username: data.username });
            this.setState({ _patientID: data.internalID });
            this.setState({ _DOB: data.dateOfBirth });
            this.setState({ _birthGender: data.birthGender });
            this.setState({ _genderIdentity: data.genderIdentity });
            this.setState({ _primaryLanguage: data.primaryLanguage });
            this.setState({ _secondaryLanguage: data.secondaryLanguage });
            this.setState({ _maritalStatus: data.maritalStatus });
            this.setState({ _race: data.race });
            this.setState({ _ethnicity: data.ethnicity });
            //  Save specific IDs for the POST
            this.setState({ cellPhoneID: data.cellPhone.internalID });
            this.setState({ emailAddressID: data.emailAddress.internalID });
            this.setState({ landlinePhoneID: data.landlinePhone.internalID });
        } catch (err) {
            console.log("ERROR " + err);
            this.handleException(response);
        }
    }

    createPathwayUIArray(pwayData: any, mappedpathways: any) {
        const tempPways = pwayData.map((s: any, pwindex: any) => {
            return (
                <Col key={s.internalID}>
                    <Form.Check style={{ paddingTop: "10px", paddingBottom: "10px" }}
                        ref={(ref: any) => { this.state.pathwayRefsArray[pwindex] = ref; return true; }}
                        required={false}
                        label={s.name}
                        name={s.name}
                        id={s.internalID}
                        disabled={this.disableUpdate()}
                        defaultChecked={mappedpathways.findIndex((i: any) => { return (i.template === s.internalID) }) > -1 ? true : false}
                        type="checkbox"
                        onChange={event => this.addOrRemovePathway(event, s.internalID, pwindex)}
                    />
                </Col>
            );
        });
        this.setState({ pathwayList: tempPways });
    }

    createTeamletUIArray(pwayData: any, mappedPathways: any) {
        const temparr = pwayData.map((s: any, pwindex: any) => {
            return (
                <Col key={s.internalID}>
                    <Form.Group className="form-group">
                        <Form.Select
                            aria-label="pathwayTeamlets"
                            placeholder="Enter teamlet"
                            name="teamlets"
                            id={s.internalID}
                            disabled={this.disableUpdate()}
                            ref={(ref: any) => { this.state.teamletRefsArray[pwindex] = ref; return true; }}
                            onChange={(e) => this.dropdownSelectChange(e, s.internalID, pwindex)}>
                            <option>Select</option>
                            {s.healthcareTeams.map((i: any, index: any) => {
                                return (<option
                                    key={i.internalID}
                                    value={i.internalID}
                                    id={i.internalID}
                                    selected={this.teamletCheck(i.internalID, s.internalID, mappedPathways) ? i.internalID  : false}
                                >
                                    {i.name}
                                </option>)
                            })}
                        </Form.Select>
                    </Form.Group>
                </Col>)
        })

        this.setState({ teamletArray: temparr });
    }

    teamletCheck(teamlet: any, template: any, checkPathways: any[]) {
        if (template===null || template===undefined) return
        if (teamlet===null || teamlet===undefined) return
     
        const found = checkPathways.filter((i: any) => { 
            if (i.hasOwnProperty('healthcareTeam') && i.healthcareTeam !== undefined && i.healthcareTeam !== null && i.healthcareTeam.internalID === teamlet && template === i.template) {
            return ( i );
            }
        })[0]

        if (found) return true;
        else return false;
    }

    /**
    * @name this.loadLookup()
    * @description Loads the lookups for Dropdowns
    * @param  
    * @returns {json} 
    */
    async loadLookup(lookup: string) {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getValues`, {
            method: 'POST',
            body: JSON.stringify({ token: token, type: lookup }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        });

        try {
            const data = await response.json();
            const _genderIdentityList = [];
            let _language = [];
            let _race = [];
            let _ethnicity = [];
            let _country = [];
            let _prefix = [];
            let _suffix = [];
            let _maritalStatus = [];
            let _genderAssignment = [];
            let _stateList = [];
            let _preferredContactList = [];

            switch (lookup) {
                case 'Pronoun':
                    this.setState({ pronounList: data.map((i: any) => i.name) });
                    break;
                case 'GenderIdentity':
                    for (let i = 0; i < data.length; i++) {
                        _genderIdentityList.push(
                            <option
                                key={i}
                                data-name={data[i].name}
                                data-value={data[i].value}>
                                {data[i].name}
                            </option>
                        );
                    }

                    //  Load Gender Identity List
                    this.setState({ genderIdentityList: _genderIdentityList });
                    break;
                case 'Language':
                    for (let i = 0; i < data.length; i++) {
                        _language.push(
                            <option
                                key={i}
                                data-name={data[i].name}
                                data-value={data[i].value}>
                                {data[i].name}
                            </option>
                        );
                    }

                    //  Load Language List
                    this.setState({ languageList: _language });
                    break;
                case 'Race':
                    for (let i = 0; i < data.length; i++) {
                        _race.push(
                            <option
                                key={i}
                                data-name={data[i].name}
                                data-value={data[i].value}>
                                {data[i].name}
                            </option>
                        );
                    }

                    //  Load Race List
                    this.setState({ raceList: _race });
                    break;
                case 'Ethnicity':
                    for (let i = 0; i < data.length; i++) {
                        _ethnicity.push(
                            <option
                                key={i}
                                data-name={data[i].name}
                                data-value={data[i].value}>
                                {data[i].name}
                            </option>
                        );
                    }

                    //  Load Ethnicity List
                    this.setState({ ethnicityList: _ethnicity });
                    break;
                case 'Country':
                    for (let i = 0; i < data.length; i++) {
                        _country.push(
                            <option
                                key={i}
                                data-name={data[i].name}
                                data-value={data[i].value}
                                value={data[i].value}>
                                {data[i].name}
                            </option>
                        );
                    }

                    //  Load Ethnicity List
                    this.setState({ countryList: _country });
                    break;

                case 'Prefix':
                    for (let i = 0; i < data.length; i++) {
                        _prefix.push(
                            <option
                                key={i}
                                data-name={data[i].name}
                                data-value={data[i].value}>
                                {data[i].name}
                            </option>
                        );
                    }

                    //  Load Prefix List
                    this.setState({ prefixList: _prefix });
                    break;
                case 'Ordinal':
                    for (let i = 0; i < data.length; i++) {
                        _suffix.push(
                            <option
                                key={i}
                                data-name={data[i].name}
                                data-value={data[i].value}>
                                {data[i].name}
                            </option>
                        );
                    }

                    //  Load Suffix List
                    this.setState({ suffixList: _suffix });
                    break;
                case 'MaritalStatus':
                    for (let i = 0; i < data.length; i++) {
                        _maritalStatus.push(
                            <option
                                key={i}
                                data-name={data[i].name}
                                data-value={data[i].value}>
                                {data[i].name}
                            </option>
                        );
                    }

                    //  Load Marital Status
                    this.setState({ maritalStatusList: _maritalStatus });
                    break;
                case 'GenderAssignment':
                    for (let i = 0; i < data.length; i++) {
                        _genderAssignment.push(
                            <option
                                key={i}
                                data-name={data[i].name}
                                data-value={data[i].value}>
                                {data[i].name}
                            </option>
                        );
                    }

                    //  Load Gender Assignment
                    this.setState({ genderAssignmentList: _genderAssignment });
                    break;

                case 'USState':
                    for (let i = 0; i < data.length; i++) {
                        _stateList.push(
                            <option
                                key={i}
                                value={data[i].value}
                                data-name={data[i].name}
                                data-value={data[i].value}>
                                {data[i].name}
                            </option>
                        );
                    }
     
                    //  Load States
                    this.setState({ stateDisplayList: _stateList });
                    break;
                case "PreferredContactType":
                    for (let i = 0; i < data.length; i++) {
                        _preferredContactList.push(
                            <option
                                key={i}
                                data-name={data[i].name}
                                data-value={data[i].value}>
                                {data[i].name}
                            </option>
                        );
                    }

                    //  Load States
                    this.setState({ preferredContactList: _preferredContactList });
                    break;
                default:
                    break;

            }

        } catch (err) {
            console.log("ERROR " + err);
        }
    }
    
    /**
    * @name setReadOnlyMode
    * @description Turns Edit Mode On/Off
    * @param  boolean On/Off
    * @returns  
    */
    async setReadOnlyMode(editModeOn: boolean) {

        this.setState({ isReadOnlyMode: editModeOn });
        //  Reset if the user cancels
        if (editModeOn) {
            this.cancelPatientEdit();
        }
    }

    /**
    * @name updatePatientSummaryDetails
    * @description Updates the Patient Summary Details
    * @param  
    * @returns
    */
    async updatePatientSummaryDetails(form: any) {
        var elements = form.elements;

        //stateElement.options[stateElement.selectedIndex].text

        const stateListComboBoxElement = elements.namedItem('cboStateList');
        const countryComboBoxElement = elements.namedItem('cboCountryList');
        
        const stateFullName = stateListComboBoxElement.options[stateListComboBoxElement.selectedIndex].text;
        const countryFullName = countryComboBoxElement.options[countryComboBoxElement.selectedIndex].text;

        if (this.validateDOB(this.state.DOB) === false) {
            this.setState({ validationErrors: [{ message: "Date entered is not a valid date" }] });
            this.setState({ validationStatus: 'invalid' });

            return;
        }
        else {
            this.setState({ validationErrors: [{ message: "" }] });
            this.setState({ validationStatus: 'valid' });
        }

        let dateOfBirth = '';
        if (this.state.DOB !== null || this.state.DOB !== '') {
            const dob = new Date(this.state.DOB);
            let bdmonth = (dob.getMonth() + 1);
            let bdmonthStr = bdmonth > 9 ? bdmonth : `0${bdmonth}`;
            let bdday = (dob.getDate());
            let bddayStr = bdday > 9 ? bdday : `0${bdday}`;
            dateOfBirth = bdmonthStr + '/' + bddayStr + '/' + dob.getFullYear();
        }
        
        // changing some names to be consistent with what the service expects
        const internalID = this.state.patientID;
        const pathways = this.state.finalPathways;
        const streetaddressobj =
        {
            "internalID": this.state.streetAddressID !== undefined ? this.state.streetAddressID : '',
            "street": this.state.street,
            "street2": this.state.street2,
            "cityName": this.state.cityName,
            "stateName": stateFullName,
            "zipcode": this.state.zipcode,
            "country": countryFullName,
            "addressType": "HOME",
            "description": "",
            "preference": "PRIMARY"
        };
        const cellphoneobj = {
            internalID: this.state.cellPhoneID,
            name: this.state.cellPhone
        };

        const landlinephoneobj = {
            internalID: this.state.landlinePhoneID,
            name: this.state.landlinePhone
        };

        const emailaddressobj = {
            internalID: this.state.emailAddressID,
            name: this.state.emailAddress
        };
       
        const token = sessionStorage.getItem('ACTIVATEtoken');

        console.log(JSON.stringify({
            token: token,
            internalID: internalID,
            firstName: this.state.firstName,
            middleName: this.state.middleName,
            lastName: this.state.lastName,
            prefix: this.state.prefix,
            suffix: this.state.suffix,
            preferredName: this.state.preferredName,
            organization: this.state.organization,
            dateOfBirth: dateOfBirth,
            birthGender: this.state.birthGender,
            pronouns: this.state.pronouns,
            genderIdentity: this.state.genderIdentity,
            medicalRecordNumber: this.state.MRN,
            username: this.state.username,
            primaryLanguage: this.state.primaryLanguage,
            secondaryLanguage: this.state.secondaryLanguage,
            maritalStatus: this.state.maritalStatus,
            race: this.state.race,
            ethnicity: this.state.ethnicity,
            preferredContact: this.state.preferredContact,
            emailAddress: emailaddressobj,
            cellPhone: cellphoneobj,
            landlinePhone: landlinephoneobj,
            streetAddress: streetaddressobj,
            pathways: pathways,
            monitors: this.state.monitors,
            monitorThresholds: this.state.monitorThresholds
        }))


        await fetch(`${config.activateServer}:${config.activatePORT}/updatePatientSummaryDetails`, {
            method: 'POST',
            body: JSON.stringify({
                token: token,
                internalID: internalID,
                firstName: this.state.firstName,
                middleName: this.state.middleName,
                lastName: this.state.lastName,
                prefix: this.state.prefix,
                suffix: this.state.suffix,
                preferredName: this.state.preferredName,
                organization: this.state.organization,
                dateOfBirth: dateOfBirth,
                birthGender: this.state.birthGender,
                pronouns: this.state.pronouns,
                genderIdentity: this.state.genderIdentity,
                medicalRecordNumber: this.state.MRN,
                username: this.state.username,
                primaryLanguage: this.state.primaryLanguage,
                secondaryLanguage: this.state.secondaryLanguage,
                maritalStatus: this.state.maritalStatus,
                race: this.state.race,
                ethnicity: this.state.ethnicity,
                preferredContact: this.state.preferredContact,
                emailAddress: emailaddressobj,
                cellPhone: cellphoneobj,
                landlinePhone: landlinephoneobj,
                streetAddress: streetaddressobj,
                pathways: pathways,
                monitors: this.state.monitors,
                monitorThresholds: this.state.monitorThresholds
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(async (response: { ok: boolean; json: () => any; status: { toString: () => string; }; }) => {
            try {
                const data = await response.json();
                if (response.status === 200) {
                    this.props.switchView("patientInformation");
                } else {
                    this.setState({ 
                        showErrorModal : true,
                        showErrorMessage : "An error occured saving user: " + this.state.firstName + " " + this.state.lastName,
                        errorServerMessage : data.message,
                        errorMessageTitle : "Error Updating Patient Information",
                        errorUserMessage : "Please check the data you entered, and re-try"
                    });
                }
            } catch (err) {
                console.log("ERROR " + err);
            }
        }).catch(err =>{
                this.setState({ 
                showErrorModal : true,
                showErrorMessage : "An error occured saving user: " + this.state.firstName + " " + this.state.lastName,
                //@ts-ignore
                errorServerMessage : err.message,
                errorMessageTitle : "Error Updating Patient Information",
                errorUserMessage : ""
            });
        });
    }

    /**
    * @name cancelPatientEdit
    * @description Cancels Patient Info Edit
    * @param  
    * @returns 
    */
    async cancelPatientEdit() {
        this.setState({ firstName: this.state._firstName });
        this.setState({ middleName: this.state._middleName });;
        this.setState({ lastName: this.state._lastName });
        this.setState({ preferredName: this.state._preferredName });
        this.setState({ prefix: this.state._prefix });
        this.setState({ suffix: this.state._suffix });
        this.setState({ emailAddress: this.state._emailAddress });
        this.setState({ cellPhone: this.state._cellPhone });
        this.setState({ landlinePhone: this.state._landlinePhone });
        this.setState({ street: this.state._street });
        this.setState({ street2: this.state._street2 });
        this.setState({ cityName: this.state._cityName });
        this.setState({ stateName: this.state._stateName });
        this.setState({ zipcode: this.state._zipcode });
        this.setState({ DOB: this.state._DOB });
        this.setState({ pronouns: this.state._pronouns });
        this.setState({ preferredContact: this.state._preferredContact });
        this.setState({ MRN: this.state._MRN });
        this.setState({ username: this.state._username });
        this.setState({ patientID: this.state._patientID });
        this.setState({ birthGender: this.state._birthGender });
        this.setState({ genderIdentity: this.state._genderIdentity });
        this.setState({ secondaryLanguage: this.state._secondaryLanguage });
        this.setState({ maritalStatus: this.state._maritalStatus });
        this.setState({ race: this.state._race });
        this.setState({ ethnicity: this.state._ethnicity });
        this.returnToPatient();
    }

    /**
    * @name prefixSelectChange
    * @description Sets Prefix selection
    * @param  
    * @returns 
    */
    async prefixSelectChange(e: any) {
        const _prefixName = e.target.options[e.target.selectedIndex].getAttribute('data-name') ? e.target.options[e.target.selectedIndex].getAttribute('data-name') : "";
        this.setState({ prefix: _prefixName });
    }

    /**
    * @name suffixSelectChange
    * @description Sets Suffix selection
    * @param  
    * @returns 
    */
    async suffixSelectChange(e: any) {
        this.setState({ suffix: e.target.value });
    }

    /**
    * @name StateSelectChange
    * @description Patient's State selection
    * @param  
    * @returns 
    */
    stateSelectChange(e: any) {
        const _value = e.target.options[e.target.selectedIndex].getAttribute('data-value') ? e.target.options[e.target.selectedIndex].getAttribute('data-value') : "";
        this.setState({ stateName: _value});
    }

    /**
    * @name countrySelectChange
    * @description Patient's Country selection
    * @param  
    * @returns 
    */
    countrySelectChange(e: any) {
        const _value = e.target.options[e.target.selectedIndex].getAttribute('data-value') ? e.target.options[e.target.selectedIndex].getAttribute('data-value') : "";
        this.setState({ country: _value});
    }

    /**
    * @name handleDateChange
    * @description Stores the new date
    * @param  
    * @returns 
    */
    handleDateChange(dob: any) {
        this.setState({ DOB: dob });
    }

    /**
    * @name returnToPatient
    * @description Return to Patient Panel
    * @param  
    * @returns 
    */
    returnToPatient() {
        this.props.switchView("patientInformation");
    }

    /**
    * @name isReadOnly
    * @description Determines if the user can edit the MRN related fields
    * @param  
    * @returns 
    */
    isReadOnly() {
        return !this.props.permissions.checkFunctionPermission("PATIENT_PANEL", "ENROLLMENT_INVITATION", "UPDATE")
    }
    /**
    * @name validateDOB
    * @description Verifies that the date of birth entered is valid
    */
    validateDOB(dobtest: any) {
        let isValidDate = false;
        // modeled after https://stackoverflow.com/questions/54632337/dob-validation-with-moment-js
        // The birth date 
        let dob = moment(dobtest, "M/D/YYYY");
        if (!dob.isValid()) dob=moment(dobtest,"YYYY/M/D");
        if (!dob.isValid()) return isValidDate;
        // The constant number of years you don't want to exceed
        const maxAge = 100;
        const today = moment(new Date()).format("MM/DD/YYYY");

        //  Clone the dob (to not mutate it), increment by the years, and compare with day precision
        //  Testing if the person is within a valid date range
        const isBeforeToday = dob.clone().add(maxAge, 'years');
        isBeforeToday.isSameOrBefore(today);

        if (!isBeforeToday) return isValidDate;

        // Is this date in the future?  If so, return false
        const isAfterToday = moment(dob).isAfter(today);
        if (isAfterToday) isValidDate = false;

        else isValidDate = true;
        return isValidDate;
    }


    /**
    * @name handleSubmit
    * @description Handles the Form Submit
    * @param  
    * @returns 
    */
    handleSubmit(event: any) {
        const form = event.currentTarget;
        event.preventDefault();
        if (this.validateDOB(this.state.DOB) === false) {
            this.setState({ validationErrors: [{ message: "Date entered is not a valid date" }] });
            this.setState({ validationStatus: 'invalid' });
            return;
        }
        else {
            this.setState({ validationErrors: [{ message: "" }] });
            this.setState({ validationStatus: 'valid' });
        }
        if (this.state.DOB !== null || this.state.DOB !== '') {

            const dob = new Date(this.state.DOB);
            const bdmonth = (dob.getMonth() + 1);
            const bdmonthStr = bdmonth > 9 ? bdmonth : `0${bdmonth}`;
            const bdday = (dob.getDate());
            const bddayStr = bdday > 9 ? bdday : `0${bdday}`;
            this.setState({ dateOfBirth: `${bdmonthStr}/${bddayStr}/${dob.getFullYear()}` });
        }

        //  Check for valid form
        if (form.checkValidity() === false) {
            event.stopPropagation();

            //  Set form validation
            this.setState({ patientInfovalidated: true });
            return;
        } else {
            this.updatePatientSummaryDetails(form);
        }
    }
    /*===========================================================================================*/
    /* assignment processing                                                                     */
    /*===========================================================================================*/

    dropdownSelectChange(e: React.ChangeEvent<HTMLSelectElement>, pathwayID: any, idx: any) {
        e.preventDefault();

        let index;
        // Case 1: teamlet changed from something to nothing; set teamletID to ''
        if (e.target.value === 'Select' || e.target.value === '') {
            index = this.state.finalPathways.findIndex((t: any) => { return (t.template === pathwayID); });
            if (index > -1) {
                const tempTeamlets = this.state.finalPathways;
                const teamletListObj = this.state.finalPathways[index];
                if (teamletListObj.healthcareTeam !== undefined) {
                    // if the pathway doesn't have an internal ID, go ahead and remove the teamlet property
                    // otherwise replace with empty strings
                    teamletListObj.healthcareTeam=null;
                    tempTeamlets[index]= teamletListObj;
                    this.setState({ finalPathways: tempTeamlets });
                }
                return;
            }

        }

        // Case 2: does teamlet aready exist in the array for this pathway?  If so, return
        if (this.state.finalPathways.length > 0) {
            index = this.state.finalPathways.findIndex((t: any) => { if (t.hasOwnProperty('healthcareTeam') && t.healthcareTeam !== undefined && t.healthcareTeam !== null) { return (t.healthcareTeam.internalID === e.currentTarget.value && t.template === pathwayID) } });
            if (index >= 0) {
                return;
            }
        }
       
        // Teamlet checked but not pathway
        // Case 3: pathway not checked; check pathway 
        // first check to be sure pathway really isn't there
       
        let checkForPathway = this.state.finalPathways.findIndex((p: any) => { return (p.template === pathwayID); });
        if (checkForPathway === -1) {
            const tempFinallist = this.state.finalPathways;
            const teamletListObj = { internalID: '', template: pathwayID, patient: this.props.patientID, healthcareTeam: { internalID: e.target.value } };
            tempFinallist.push(teamletListObj);
            this.setState({ finalPathways: tempFinallist });
            const refIndex = this.state.pathwayRefsArray.findIndex((r:any)=>{return(r.id===pathwayID)});
            this.state.pathwayRefsArray[refIndex].checked = true;
            return;
        }
        // Case 4: does the pathway exist in the array with a different teamlet?  If so replace the teamlet value
        index = this.state.finalPathways.findIndex((t: any) => { return (t.template === pathwayID); });
        let tteamletListObj;

        if (index >= 0) {
            const tempInstances = this.state.finalPathways;
            tteamletListObj = this.state.finalPathways[index];

            if (tteamletListObj !== undefined) {
                if (tteamletListObj.hasOwnProperty('healthcareTeam')) {
                    tteamletListObj.healthcareTeam = { internalID: e.currentTarget.value };
                } else {
                    tteamletListObj.healthcareTeam = {};
                    tteamletListObj.healthcareTeam.internalID = e.currentTarget.value;
                }
                this.setState({ finalPathways: tempInstances });
            }
            /*
			// Case 5 new teamlet for pathway - going from nothing to something
			let pwindex = this.state.finalPathways.findIndex((t: any) => { return (t.pathway === pathwayID) });
			console.log('pwindex=' + pwindex)
			if (pwindex > -1) {
				let ttempArray= this.state.finalPathways
				let tempTeamlets = this.state.finalPathways[pwindex]
				console.dir(tempTeamlets)
				tempTeamlets.teamlet = e.target.value;
				
				this.setState({finalPathways:ttempArray})
			}
*/

        }
    }

    /**
    * @name addOrRemovePathway
    * @description adds pathway to selected list
    */
    addOrRemovePathway(event: any, id: any, index: any) {

        const selectedteamlets = this.state.selectedTeamlet;

        //First check if box was check or unchecked  
        const checked = event.target.checked;
        // Case 1: pathway is checked, add id to selected Pathways and create entry in teamletList;
        if (checked === true) {
            const pathwayExists = this.state.finalPathways.filter((i: any) => { return (i.template === id); });
            if (pathwayExists.length > 0) return;
            const tempTeams = this.state.finalPathways;
            const newTeam = null;
            tempTeams.push({ internalID: '',template: id, healthcareTeam: newTeam, patient: this.props.patientID });
            this.setState({ finalPathways: (tempTeams) });
            this.setState({ selectedTeamlet: selectedteamlets });
        }
        // Case 2: pathway is unchecked, remove id from selected Pathways and remove entry in teamletList;
        if (checked === false) {
            const tempPathways = this.state.finalPathways;
            const tempTeamlets = this.state.selectedTeamlet;
            // checks final pathways to see if pathway already exists 
            const pwindex = this.state.finalPathways.findIndex((t: any) => {
                return (t.template === id);
            });
            if (pwindex > -1) {
                tempPathways.splice(pwindex, 1);
                tempTeamlets[pwindex] = null;
                const refIndex=this.state.pathwayRefsArray.findIndex((r:any)=>{return(r.id===id)});
                this.state.teamletRefsArray[refIndex].value = 'Select';
            }

            this.setState({ finalPathways: tempPathways });
            this.setState({ selectedTeamlet: tempTeamlets });
        }
    }

    /**
    * @name handleException
    * @description  Handle non-200 errors
    * @param  
    * @returns 
    */
     async handleException(_resp: Response) {
       
        const error = await _resp.text();
        const parsedError= JSON.parse(error);

        let msg = "";
        msg += parsedError.message;
        //  Exception
        this.setState({
            showErrorModal: true, 
            showErrorMessage: "An error has occured while updating user: " + this.state.firstName + " " + this.state.lastName,
            errorServerMessage: msg,
            errorMessageTitle: "An error has occured",
            errorUserMessage: "Please refer to the error for resolution."
        });
    }

    //  Can Update?
    disableUpdate() {
        return !this.props.permissions.checkFunctionPermission("PATIENT_PANEL", "DIGITAL_HEALTH_PATHWAY", "UPDATE");
    }

    onChangeUserName(_username: string){
        this.setState({ username: _username })
        this.setState({ emailAddress: _username });
    }
    /*===========================================================================================*/
    /* end of assignment processing                                                                     */
    /*===========================================================================================*/
    render() {
        return (
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => this.props.switchView("patientpanel")}>Patients</Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => this.returnToPatient()}>{this.state.firstName} {this.state.middleName} {this.state.lastName} {this.state.suffix==="Not Provided" ? null : this.state.suffix}</Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Patient Information</Breadcrumb.Item>
                </Breadcrumb>
                <div className="page-panel">
                <Form noValidate validated={this.state.patientInfovalidated} onSubmit={(e) => { this.handleSubmit(e)}} id="patient-about-info">
                <section>
                    <h2 className="panel-section">About Patient</h2>
                      <div className="d-flex">
                        <FormGroup className="form-group">
                            <Form.Label className="required">
                                First Name
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                disabled={this.props.permissions.isEhrOrganization}
                                name="firstName"
                                value={this.state.firstName}
                                onChange={e => this.setState({ firstName: e.target.value })} readOnly={this.state.isReadOnlyMode} />
                            <Form.Control.Feedback type="invalid" style={{ marginBottom: "30px" }}>
                                First Name is required.
                            </Form.Control.Feedback>
                        </FormGroup>
                        
                        <FormGroup className="form-group">
                            <Form.Label>
                                Middle Name
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="middleName"
                                disabled={this.props.permissions.isEhrOrganization}
                                value={this.state.middleName}
                                onChange={e => this.setState({ middleName: e.target.value })} readOnly={this.state.isReadOnlyMode} />
                        </FormGroup>
                        
                        <FormGroup className="form-group">
                            <Form.Label>
                                Last Name <span style={{ color: "red" }}> *</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                name="lastName"
                                disabled={this.props.permissions.isEhrOrganization}
                                value={this.state.lastName}
                                onChange={e => this.setState({ lastName: e.target.value })} readOnly={this.state.isReadOnlyMode} />
                            <Form.Control.Feedback type="invalid">
                                Last Name is required.
                            </Form.Control.Feedback>
                        </FormGroup>
                      </div>
                      <div className="d-flex">
                        <FormGroup className="form-group">
                            <Form.Label>
                                Prefix
                            </Form.Label>
                            <Form.Select
                                aria-label="Prefix"
                                name="prefix"
                                value={this.state.prefix}
                                onChange={this.prefixSelectChange}>
                                <option>Select</option>
                                {this.state.prefixList}
                            </Form.Select>
                        </FormGroup>
                        
                        <FormGroup className="form-group">
                            <Form.Label >
                                Suffix
                            </Form.Label>
                            <Form.Select
                                aria-label="suffix"
                                name="suffix"
                                value={this.state.suffix}
                                onChange={this.suffixSelectChange}>
                                <option>Select</option>
                                {this.state.suffixList}
                            </Form.Select>
                        </FormGroup>
                        <FormGroup className="form-group">
                            <Form.Label>
                                Preferred Name
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="preferredName"
                                value={this.state.preferredName}
                                onChange={e => this.setState({ preferredName: e.target.value })} readOnly={this.state.isReadOnlyMode} />
                        </FormGroup>
                        </div>
                    
                      <div className="d-flex">
                        <FormGroup className="form-group">
                            <Form.Label className="required">Date of Birth</Form.Label>
                            <DateBox
                                value={this.state.DOB}
                                text={this.state.DOB}
                                disabled={this.props.permissions.isEhrOrganization}
                                type="date"
                                onValueChange={this.handleDateChange}
                                readOnly={this.isReadOnly()}
                                placeholder="MM/DD/YYYY"
                                displayFormat='MM/dd/yyyy'
                                dateOutOfRangeMessage="Date of birth is out of range"
                                validationStatus={this.state.validationStatus}
                                validationErrors={this.state.validationErrors}
                                width="12em"
                            />
                        </FormGroup>
                        <FormGroup className="form-group">
                            <Form.Label>Sex at Birth</Form.Label>
                            <div style={{minWidth:"11em"}}>
                            {this.props.permissions.isEhrOrganization ?
                            <Form.Control
                                type="text"
                                required
                                name="ehrBirthGender"
                                disabled={this.props.permissions.isEhrOrganization}
                                value={this.state.birthGender}
                                readOnly={this.state.isReadOnlyMode} /> 
                            :                                  
                            <Form.Select
                                aria-label="birthGender"
                                name="birthGender"
                                disabled={this.props.permissions.isEhrOrganization}
                                value={this.state.birthGender}
                                onChange={e => this.setState({ birthGender: e.target.value })}>
                                <option>Select</option>
                                {this.state.genderAssignmentList}
                            </Form.Select>
                            }
                          </div>
                        </FormGroup>
                        <FormGroup className="form-group">
                            <Form.Label>Preferred Pronouns</Form.Label>
                            <List
                                id="listElement"
                                noDataText=''
                                dataSource={this.state.pronounList}
                                selectedItemKeys={this.state.pronouns}
                                showSelectionControls={true}
                                selectionMode={'multiple'}
                                onSelectedItemKeysChange={this.onSelectedPronounChange}
                                width="15em"
                            >
                            </List>
                        </FormGroup>

                        <FormGroup className="form-group">
                            <Form.Label>Gender Identity</Form.Label>
                            <Form.Select
                                aria-label="genderIdentity"
                                name="genderIdentity"
                                value={this.state.genderIdentity}
                                onChange={e => this.setState({ genderIdentity: e.target.value })}>
                                <option>Select</option>
                                {this.state.genderIdentityList}
                            </Form.Select>
                        </FormGroup>
                      </div>
                      {this.props.permissions.isEhrOrganization ?
                      <div className="d-flex">
                        <FormGroup className="form-group">
                            <Form.Label>EHR Patient ID</Form.Label>
                            <Form.Control
                                type="text"
                                name="ehrPatientID"
                                disabled={this.props.permissions.isEhrOrganization}
                                value={this.state.ehrPatientID}
                                onChange={e => this.setState({ ehrPatientID: e.target.value })}>
                            </Form.Control>
                        </FormGroup>
                        </div>
                       : <></>}
                        <div className="d-flex">
                        {this.props.permissions.isEhrOrganization ?
                            <FormGroup className="form-group">
                                <Form.Label>MRN (non-EHR)</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="MRN"
                                    value={this.state.MRN}
                                    onChange={e => this.setState({ MRN: e.target.value })} 
                                    readOnly={this.isReadOnly()} />
                            </FormGroup>
                        :
                        <FormGroup className="form-group">
                            <Form.Label className="required">MRN</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                name="MRN"
                                value={this.state.MRN}
                                onChange={e => this.setState({ MRN: e.target.value })} 
                                readOnly={this.isReadOnly()} />
                            <Form.Control.Feedback type="invalid">
                                MRN is required.
                            </Form.Control.Feedback>
                        </FormGroup>
                        }
                        <FormGroup className="form-group">
                            {this.props.permissions.isEhrOrganization ?
                                <>
                                <Form.Label>ACTIVATE Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    value={this.state.username}
                                    onChange={e => this.onChangeUserName(e.target.value)} 
                                    disabled = {this.isReadOnly()}
                                />
                                </>
                            :
                            <>
                                <Form.Label className="required">ACTIVATE Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    name="username"
                                    value={this.state.username}
                                    onChange={e => this.onChangeUserName(e.target.value)} 
                                    disabled = {this.isReadOnly()}
                                />
                                <Form.Control.Feedback type="invalid">
                                    ACTIVATE Username is required.
                                </Form.Control.Feedback>
                            </>
                            }
                        </FormGroup>
                        </div>
                    <div className="d-flex">
                        <FormGroup className="form-group">
                            <Form.Label>Primary Language</Form.Label>
                            {this.props.permissions.isEhrOrganization ?
                            <Form.Control
                                type="text"
                                required
                                name="ehrPrimaryLanguage"
                                disabled={this.props.permissions.isEhrOrganization}
                                value={this.state.primaryLanguage}
                                readOnly={this.state.isReadOnlyMode} />  
                            :
                            <Form.Select
                                aria-label="primaryLanguage"
                                name="primaryLanguage"
                                disabled={this.props.permissions.isEhrOrganization}
                                value={this.state.primaryLanguage}
                                onChange={e => this.setState({ primaryLanguage: e.target.value })}>
                                <option>Select</option>
                                {this.state.languageList}
                            </Form.Select>
                            }
                        </FormGroup>
                        <FormGroup className="form-group">
                            <Form.Label>Secondary Language</Form.Label>
                            <Form.Select
                                aria-label="secondaryLanguage"
                                name="secondaryLanguage"
                                value={this.state.secondaryLanguage}
                                onChange={e => this.setState({ secondaryLanguage: e.target.value })}>
                                <option>Select</option>
                                {this.state.languageList}
                            </Form.Select>
                        </FormGroup>
                        {this.props.permissions.isEhrOrganization ?
                        <FormGroup className="form-group">
                            <Form.Label>
                                PCP NPI Identifier
                            </Form.Label>
                            <Form.Control
                                type="text"
                                disabled={this.props.permissions.isEhrOrganization}
                                name="PCPNPIidentifier"
                                value={this.state.PCPNPIidentifier}
                                onChange={e => this.setState({ PCPNPIidentifier: e.target.value })} />
                        </FormGroup>
                        : <></>}
                        {this.props.permissions.isEhrOrganization ?
                        <FormGroup className="form-group">
                            <Form.Label>
                                PCP Name
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="PCPname"
                                value={this.state.PCPname}
                                disabled={this.props.permissions.isEhrOrganization}
                                onChange={e => this.setState({ PCPname: e.target.value })} />
                        </FormGroup>
                        : <></>}
                    </div>
                    <div className="d-flex">
                        <FormGroup className="form-group">
                            <Form.Label>Marital Status</Form.Label>
                            {this.props.permissions.isEhrOrganization ?
                            <Form.Control
                                type="text"
                                required
                                name="ehrMaritalStatus"
                                disabled={this.props.permissions.isEhrOrganization}
                                value={this.state.maritalStatus}
                                readOnly={this.state.isReadOnlyMode} />  
                            :
                                <Form.Select
                                    aria-label="maritalStatus"
                                    name="maritalStatus"
                                    disabled={this.props.permissions.isEhrOrganization}
                                    value={this.state.maritalStatus}
                                    onChange={e => this.setState({ maritalStatus: e.target.value })}>
                                    <option>Select</option>
                                    {this.state.maritalStatusList}
                                </Form.Select>
                            }
                        </FormGroup>
                    </div>
                    <div className="d-flex">
                        <FormGroup className="form-group">
                            <Form.Label>Race</Form.Label>
                            {this.props.permissions.isEhrOrganization ?
                            <Form.Control
                                type="text"
                                required
                                name="ehrRace"
                                disabled={this.props.permissions.isEhrOrganization}
                                value={this.state.race}
                                readOnly={this.state.isReadOnlyMode} />  
                            :
                            <Form.Select
                                aria-label="race"
                                name="race"
                                disabled={this.props.permissions.isEhrOrganization}
                                value={this.state.race}
                                onChange={e => this.setState({ race: e.target.value })}>
                                <option>Select</option>
                                {this.state.raceList}
                            </Form.Select>
                        }
                        </FormGroup>
                        <FormGroup className="form-group">
                            <Form.Label>Ethnicity</Form.Label>
                            {this.props.permissions.isEhrOrganization ?
                            <Form.Control
                                type="text"
                                required
                                name="ehrEthnicity"
                                disabled={this.props.permissions.isEhrOrganization}
                                value={this.state.ethnicity}
                                readOnly={this.state.isReadOnlyMode} />  
                            :
                            <Form.Select
                                aria-label="ethnicity"
                                name="ethnicity"
                                disabled={this.props.permissions.isEhrOrganization}
                                value={this.state.ethnicity}
                                onChange={e => this.setState({ ethnicity: e.target.value })}>
                                <option>Select</option>
                                {this.state.ethnicityList}
                            </Form.Select>
                            }
                        </FormGroup>
                    </div>
                </section>
                <section>
                    <h2 className="panel-section">Patient Contact Information</h2>
                    <div className="d-flex">
                        <FormGroup className="form-group">
                            <Form.Label className="required">Preferred Contact</Form.Label>
                            <div style={{width:"10em"}}>
                            <Form.Select
                                required
                                placeholder="Enter state"
                                name="preferredContact"
                                value={this.state.preferredContact}
                                onChange={e => this.setState({ preferredContact: e.target.value })}>
                                {this.state.preferredContactList}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Preferred Contact is required.
                            </Form.Control.Feedback>
                            </div>
                        </FormGroup>
                        <FormGroup className="form-group">
                            <Form.Label>ACTIVATE Email Address</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                name="emailAddress"
                                disabled
                                value={this.state.emailAddress}
                                onChange={e => this.setState({ emailAddress: e.target.value })} />
                            <Form.Control.Feedback type="invalid">
                                Email Address is required.
                            </Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup className="form-group">
                            <Form.Label>Cell Phone</Form.Label>
                            <Form.Control
                                type="text"
                                name="cellPhone"
                                value={this.state.cellPhone}
                                disabled={this.props.permissions.isEhrOrganization}
                                onChange={e => this.setState({ cellPhone: e.target.value })} readOnly={this.state.isReadOnlyMode} />
                        </FormGroup>
                        <FormGroup className="form-group">
                            <Form.Label>Home Phone</Form.Label>
                            <Form.Control
                                type="text"
                                name="landlinePhone"
                                value={this.state.landlinePhone}
                                disabled={this.props.permissions.isEhrOrganization}
                                onChange={e => this.setState({ landlinePhone: e.target.value })} readOnly={this.state.isReadOnlyMode} />
                        </FormGroup>
                    </div>
                    {(this.props.permissions.isEhrOrganization) ?
                        <div className="d-flex">
                            <FormGroup className="form-group">
                                <Form.Label column>EHR Email Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="EHRemail"
                                    value={this.state.EHRemail}
                                    disabled={this.props.permissions.isEhrOrganization}/>
                            </FormGroup>
                      </div>
                    : <></>}
                    <div className="d-flex">
                        <FormGroup className="form-group">
                            <Form.Label>Address 1</Form.Label>
                            <Form.Control
                                type="text"
                                name="street"
                                value={this.state.street}
                                onChange={e => this.setState({ street: e.target.value })} readOnly={this.state.isReadOnlyMode} />
                        </FormGroup>
                        <FormGroup className="form-group">
                            <Form.Label>Address 2</Form.Label>
                            <Form.Control
                                type="text"
                                name="street2"
                                value={this.state.street2}
                                onChange={e => this.setState({ street2: e.target.value })} readOnly={this.state.isReadOnlyMode} />
                        </FormGroup>
                    </div>
                    <div className="d-flex">
                        <FormGroup className="form-group">
                            <Form.Label>Town/City</Form.Label>
                            <Form.Control
                                type="text"
                                name="cityName"
                                value={this.state.cityName}
                                onChange={e => this.setState({ cityName: e.target.value })} readOnly={this.state.isReadOnlyMode} />
                        </FormGroup>
                        <FormGroup className="form-group">
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control
                                type="text"
                                name="zipCode"
                                value={this.state.zipcode}
                                onChange={e => this.setState({ zipcode: e.target.value })} readOnly={this.state.isReadOnlyMode} />
                        </FormGroup>
                    </div>
                    <div className="d-flex">
                        <FormGroup className="form-group">
                            <Form.Label>State</Form.Label>
                            <Form.Select
                                aria-label="state"
                                name="cboStateList"
                                value={this.state.stateName}
                                onChange={e => this.stateSelectChange(e)}>
                                {this.state.stateDisplayList}
                            </Form.Select>
                        </FormGroup>
                        <FormGroup className="form-group">
                            <Form.Label>Country</Form.Label>
                            <Form.Select
                                aria-label="country"
                                name="cboCountryList"
                                value={this.state.country}
                                onChange={e => this.countrySelectChange(e)}>
                                {this.state.countryList}
                            </Form.Select>
                        </FormGroup>
                    </div>
                  </section>
                  <section>
                    <h2 className="panel-section">ACTIVATE Assignments</h2>
                    <Row>
                        <Col sm={6}>
                            <Form.Label>Digital Health Pathway</Form.Label>
                        </Col>
                        <Col sm={6}>
                            <Form.Label>Assigned Teamlet</Form.Label>
                        </Col>
                    </Row>

                    {this.state.pathwayList.map((o: any, index: any) => {
                        return (
                            <Row key={index}>
                                {o}
                                {this.state.teamletArray[index]}
                            </Row>)
                    })}
                  </section>
                    <div className="form-actions">
                        <Button className="people submit" type="submit" id="submitButton">Save</Button>
                        <Button className="people cancel" id="cancelButton" variant="secondary" type="button" onClick={() => this.props.switchView("patientInformation")}>Cancel</Button>
                    </div>

                 <Modal show={this.state.showErrorModal}>
                    <Modal.Header className="error">
                        <h2>{this.state.errorMessageTitle}</h2>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.showErrorMessage}<br/>
                        <br/><b>{this.state.errorServerMessage}</b><br/>
                        <br/>{this.state.errorUserMessage}<br/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='system error' onClick={() => this.resetError()}>Ok</Button>
                    </Modal.Footer>
                </Modal>
                    
                    <span style={{ color: "red" }}>{this.state.badSubmissionError.length > 0 ? this.state.badSubmissionError : ""}</span>
                    {/*this.state.isReadOnlyMode === false ?
                        <Row style={{ marginTop: "60px" }}>
                            <Button type="submit" id="submitButton" className="rounded-pill" style={{ width: "200px", marginLeft: "40px", backgroundColor: "lightskyblue !important" }} >Save</Button>
                            <Button className='UserButtons' style={{ width: "200px", marginLeft: "40px" }}
                                id="cancelButton"
                                type="button"
                                onClick={() => this.props.switchView("patientpanel")}>
                                Cancel
                            </Button><br />
                        </Row>


                        :
                        <Row>&nbsp;</Row>
                */}
                </Form>
                  </div>
            </Container>
        );
    }
}


import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Navbar, Button, Container, Nav, NavItem, Dropdown, ButtonGroup } from 'react-bootstrap/';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import '../Navbar.css';
import './HealthProviderNavbar.css';
import { PropertiesPanel } from 'devextreme-react/diagram';
const navbarLogo = require('./../../Resources/ACTIVATE_logo_small.png');
const config = require('../../config.json');

function HealthProviderNavbar( props : 
    { 
        roleName : string,
        displayRoleName : string;
        username : string,
        expandCollapseSidebar : Function,
        switchView : Function,
        organizationList : any[],
        selectedOrganization : string,
        selectedOrganizationID : string,
        setSelectedOrganization : Function
    }){
    const location = useLocation();
    const [logout, setLogout] = useState(false);
    const [displayOrganizationList, setDisplayOrganizationList] = useState<any[]>([]);
    const [currentOrgID, setCurrentOrgID] = useState("");
    const [currentOrgName, setCurrentOrgName] = useState("");
    const navigate=useNavigate();

    useEffect(() => {
        //@ts-ignore
        if (location.state){
            //@ts-ignore
            loadOrganizationDropdown(location.state.userOrganizations);
        }
    }, [])

    /**
    * @name Logout
    * @description Logouts out the User
    * @param
    * @returns {json}
    */
     async function Logout(){
        const token = sessionStorage.getItem('ACTIVATEtoken');
        try{
            await fetch(`${config.activateServer}:${config.activatePORT}/logout`, {
                method: 'POST',
                body: JSON.stringify({ token: token }),
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode : 'cors'
            })
        } catch(err){
            console.log("ERROR " + err);
        }

        //  Clear storage
        sessionStorage.removeItem('authenticated');
        sessionStorage.removeItem('ACTIVATEtoken');
        //sessionStorage.removeItem('restorePage');
        // Set Logout   
        setLogout(true);
    }

    function loadOrganizationDropdown(newOrgList : any[]){
        let tempOrganizationList : any = [];
        //console.log("NEWORGLIST " + JSON.stringify(newOrgList));
        for(let i = 0; i < newOrgList.length; i++){
            tempOrganizationList.push(
                <Dropdown.Item
                    onClick={() => healthCenterChange(newOrgList[i].name, newOrgList[i].value)}
                    key={i}
                    eventKey={i}
                    data-name={newOrgList[i].name}
                    value={newOrgList[i].value}>
                        {newOrgList[i].name}
                </Dropdown.Item>
            )
        }
        setDisplayOrganizationList(tempOrganizationList);

        //  Check if the Org exists
        if (newOrgList[0]!=null){
            setCurrentOrgName(newOrgList[0].name);
            setCurrentOrgID(newOrgList[0].value);
        }
    }

    function healthCenterChange(orgName : string, orgID : string){
        setCurrentOrgID(orgID);
        setCurrentOrgName(orgName);
        props.setSelectedOrganization(JSON.stringify(orgID), orgName);
    }

    function showAboutPage(){
        navigate('/about', {state: {}});
    }

    function showHelpPage(){
        navigate('/help', {state: {}});
    }

    if(logout){
        return <Navigate to="/login"/>
    }
    return(
        <Navbar id="navContainer">
            <Container fluid>
                <Nav>
                    <Button id="expandButton" className="menu-expand-button" onClick={() => props.expandCollapseSidebar()}>
                        <FontAwesomeIcon icon={faBars}/>
                    </Button>
                    <img 
                        id="ACTIVATElogo"
                        src={navbarLogo}
                        style={{cursor : "pointer"}}
                        onClick={() => props.switchView("dashboard")}
                        alt="ACTIVATElogo">
                    </img>
                    <Button
                        style={{ "color" : "black", "backgroundColor" : "white", borderColor : "white"}}
                        onClick={() => props.switchView("dashboard")}>
                        <FontAwesomeIcon icon={faHouse} />
                    </Button>
                    {displayOrganizationList.length > 1 ?
                        <Dropdown as={ButtonGroup}>
                            <Button 
                            className="navButton"
                            style={{ "color" : "black", "backgroundColor" : "white", borderColor : "white"}}
                            onClick={() => props.switchView("dashboard")}>
                                {currentOrgName}
                            </Button>
                            <Dropdown.Toggle className="navButton" split variant="success" id="dropdown-split-basic" />

                            <Dropdown.Menu>
                                {displayOrganizationList}
                            </Dropdown.Menu>
                        </Dropdown>
                    :
                    <Button
                        className='navButton'>
                            {displayOrganizationList[0]}
                    </Button>
                    }
                </Nav>
                <Navbar.Collapse className="nav-help-links">
                    <Nav.Link onClick={() => props.switchView("about")}>About</Nav.Link>
                    <Nav.Link onClick={() => props.switchView("help")}>Help</Nav.Link>
                </Navbar.Collapse>
                <Nav>
                    <NavItem className="nav-user-info">
                        <p className="nav-user-name">{props.username}</p>
                        <p className="nav-user-role">{props.displayRoleName}</p>
                    </NavItem>
                    <NavItem>
                        <Button id="logoutButton" onClick={Logout}><FontAwesomeIcon icon={ faSignOutAlt }></FontAwesomeIcon></Button>
                    </NavItem>
                </Nav>
            </Container>
        </Navbar>
    )
}

export default HealthProviderNavbar;
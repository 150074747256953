/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { Button, Table, Modal, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import './AdminRoles.css';
const config = require('../../config.json');

function AdminRoles( props : { switchView : (newView : string) => void }) {

    const [showAddRole, setShowAddRole] = useState(false);
    const [showEditRole, setShowEditRole] = useState(false);
    const [roleName, setRoleName] = useState("");
    const [roleCode, setRoleCode] = useState("");
    const [roleID, setRoleID] = useState("");
    const [roleList, setRoleList] = useState<JSX.Element[]>([]);
    const [newRoleError, setNewRoleError] = useState("");

    useEffect(() => {
        loadRoles();
    })

    function loadRoleHeader(){
        const headerList = ["Role Name", "Role Code", "User Count", "Action"];
        return headerList.map((key, index) => {
            return <th key={index}>{key}</th>
        })
    }

    /**
     * @name loadRoles
     * @description Loads the roles and then puts it into a HTML array to display.
     * @returns {HTMLElement[]} 
     */
    async function loadRoles(){
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getRoles`, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        })

        try {
            const data = await response.json();
            //console.log("GOT BACK " + JSON.stringify(data));
            //Load the table here
            const roleList : JSX.Element[] = [];
            let actionButtons;
            for (let i = 0; i < data.length; i++) {
                if (data[i].value === "ROLE_ADMINISTRATOR") {
                    actionButtons = <span>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                            <Tooltip>
                                                Edit is disabled for the administrator role.
                                            </Tooltip>
                                        }>
                                            <span>
                                                <Button
                                                id="editRole"
                                                className="actionButtonsDisabled"
                                                disabled={true}>
                                                    <FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon>
                                                </Button>
                                            </span>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                            <Tooltip>
                                                Delete is disabled for the administrator role.
                                            </Tooltip>
                                        }>
                                            <span>
                                                <Button
                                                    id="deleteRole"
                                                    className="actionButtonsDisabled"
                                                    disabled={true}>
                                                        <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
                                                </Button>
                                            </span>
                                        </OverlayTrigger>
                                    </span>
                } else {
                    //TODO Add if statement if the total members in a role is greater than zero
                    actionButtons = <span>
                                        <Button
                                            id="editRole"
                                            className="actionButtons"
                                            onClick={() => toggleEditRoleModal(true, data[i].name, data[i].value, data[i].internalID)}>
                                                <FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon>
                                        </Button>
                                        <Button
                                            id="deleteRole"
                                            className="actionButtons">
                                                <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
                                        </Button>
                                    </span>
                }
                roleList.push(
                    <tr key={i}>
                        <td>{data[i].name}</td>
                        <td>{data[i].value}</td>
                        <td>0</td>
                        <td>
                            {actionButtons}
                        </td>
                    </tr>
                )
            }

            setRoleList(roleList);
        } catch(err){
            console.log("ERROR " + err);
        }
    }

    /**
     * @name postNewRole
     * @description Sends a completed new role to the server and closes the modal
     * @returns {string} 
     */
    async function postNewRole(){
        //Check for valid input
        if(roleName === "" || roleCode === "") {
            const badRoleName = roleName === "" ? " Role Name" : "";
            const badRoleCode = roleCode === "" ? " Role Code" : "";
            setNewRoleError(`The following fields are required: ${badRoleName}${badRoleCode}`);
        } else {
            const token = sessionStorage.getItem('ACTIVATEtoken');
            const response = await fetch(`${config.activateServer}:${config.activatePORT}/postRole`, {
                method: 'POST',
                body: JSON.stringify({  
                    token : token,
                    internalID : "",
                    name : roleName,
                    value : roleCode 
                }),
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode : 'cors'
            })

            try {
                //const data = await response.json();
                //console.log("GOT BACK " + JSON.stringify(data));
                toggleNewRoleModal(false);
                loadRoles();
            } catch(err) {
                console.log("ERROR ADDING NEW ROLE " + JSON.stringify(err));
            }
        }
    }

    async function editRole(){
        //Check for valid input
        if(roleName === "" || roleCode === "") {
            const badRoleName = roleName === "" ? " Role Name" : "";
            const badRoleCode = roleCode === "" ? " Role Code" : "";
            setNewRoleError(`The following fields are required: ${badRoleName}${badRoleCode}`);
        }
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/putRole`, {
            method: 'POST',
            body: JSON.stringify({  
                token : token,
                internalID : roleID,
                name : roleName,
                value : roleCode
            }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        })

        try {
            const data = await response.json();
            //console.log("GOT BACK " + JSON.stringify(data));
            toggleEditRoleModal(false, "", "", "");
            loadRoles();
        } catch(err) {
            console.log("ERROR EDITING ROLE " + JSON.stringify(err));
        }
    }

    function changeValue(event : React.ChangeEvent<HTMLInputElement>){
        const name = event.target.name;
        if (name === "roleName") {
            setRoleName(event.target.value);
        } else {
            setRoleCode(event.target.value);
        }
    }

    function toggleNewRoleModal(toggle : boolean){
        if(toggle === false){
            setRoleName("");
            setRoleID("");
        }
        setShowAddRole(toggle);
    }

    //TODO Make sure that when table loads, the edit button calls this function with the roles needed info filled out
    function toggleEditRoleModal(toggle : boolean, editRoleName : string, editRoleCode : string, editRoleID : string){
        setShowEditRole(toggle);
        setRoleName(editRoleName);
        setRoleCode(editRoleCode);
        setRoleID(editRoleID);
    }

    return(
        <div>
            <Button
                className="linkButton"
                onClick={() => props.switchView("configuration")}>
                    Configuration
            </Button>
            &gt; Roles
            <br/>
            <Button
                id="addRolesButton"
                onClick={() => toggleNewRoleModal(true)}>
                    Add Roles
            </Button>
            <Table>
                <thead style={{ backgroundColor : "lightgray" }}>
                    <tr>
                        {loadRoleHeader()}
                    </tr>
                </thead>
                <tbody>
                    {roleList}
                </tbody>
            </Table>

            {/* Modal for a new role */}
            <Modal show={showAddRole}>
                <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Role Name<span style={{ color : "red"}}>*</span></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter role name"
                            id="roleName"
                            name="roleName"
                            value={roleName}
                            onChange={changeValue}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Role Code<span style={{ color : "red"}}>*</span></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter role code"
                            id="roleCode"
                            name="roleCode"
                            value={roleCode}
                            onChange={changeValue}/>
                    </Form.Group>

                </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="primary"
                        id="saveButton"
                        onClick={() => postNewRole()}>
                        Save
                    </Button>
                    <Button
                        variant="secondary"
                        id="cancelButton"
                        onClick={() => toggleNewRoleModal(false)}>
                            Close
                    </Button>
                {newRoleError !== "" ? 
                <div>{newRoleError}</div>
                : ""}
                </Modal.Footer>
            </Modal>

            <Modal show={showEditRole}>
                <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Role Name<span style={{ color : "red"}}>*</span></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter role name"
                            id="roleName"
                            name="roleName"
                            value={roleName}
                            onChange={changeValue}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Role Code<span style={{ color : "red"}}>*</span></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter role code"
                            id="roleCode"
                            name="roleCode"
                            value={roleCode}
                            onChange={changeValue}/>
                    </Form.Group>
                </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="secondary"
                        id="saveButton"
                        onClick={() => toggleNewRoleModal(false)}>
                            Close
                    </Button>
                    <Button
                        variant="primary"
                        id="cancelButton"
                        onClick={() => editRole()}>
                            Save changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AdminRoles
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState, useRef } from 'react';
import PermissionService from '../../../Services/PermissionService';
import { Container, Anchor, Modal, Breadcrumb, Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { DataGrid, Selection, Column, Sorting, Paging, Pager, Grouping, GroupPanel, } from 'devextreme-react/data-grid';
import { ModalErrorMessage } from '../MessageBox/ModalErrorMessage';
import { Icon } from '@ailibs/feather-react-ts';
import { LoadPanel } from 'devextreme-react/load-panel';

const config = require("../../../config.json");

function HuddleHistory(props:
  {
    permissions: PermissionService;
    switchView: (newView: string) => void;
    patientID: string;
    setHuddleID: (huddleID: string) => void;
    setMeetingID: (meetingID: string) => void;
    setSelectedTeamletID: (teamletID: string) => void;
  }) {

  //  Error Modal
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [patientHistory, setPatientHistory] = useState<any[]>([]);
  const [teamletPatientHistory, setTeamletPatientHistory] = useState<any[]>([]);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [suffix, setSuffix] = useState('');
  const [rangeStart, setRangeStart] = useState('');
  const [rangeEnd, setRangeEnd] = useState('');
  const [huddleViewBy, setHuddleViewBy] = useState('1');
  const huddleViewByButtons = [
    { name: 'View By Date', value: '1' },
    { name: 'View By Teamlet', value: '2' }
  ];
  //  Spinner Icon location
  const position = { of: '#huddleScheduleGrid' };
  const [showProgress, setShowProgress] = useState(false);
  const huddleScheduleController = new AbortController();
  const token = sessionStorage.getItem('ACTIVATEtoken');

  const fetchPatientName = async () => {
    try {
      setShowProgress(false);

      await fetch(`${config.activateServer}:${config.activatePORT}/getPatientSummaryById`, {
        method: 'POST',
        body: JSON.stringify({ token: token, patientID: props.patientID }),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        mode: 'cors'
      }).then(res => {
        if (!res.ok) {
          throw Error('Could not fetch the data for the resource');
        }
        return res.json();
      }).then(patient => {
        setFirstName(patient.firstName);
        setMiddleName(patient.middleName);
        setLastName(patient.lastName);
        setSuffix(patient.suffix);
      }).catch(err => {
        if (err.name === 'AbortError') {
          //console.log('fetch aborted');
        } else {
          // TODO fill in
        }
      });

      setShowProgress(false);
    } catch (err) {
      setShowErrorModal(true);
      huddleScheduleController.abort();
      setShowProgress(false);
    }
  };

  //  Get Patients
  const fetchHuddlesByPatient = async () => {
    if (props.patientID === '' || props.patientID === undefined) {
      return;
    }

    try {
      setShowProgress(false);

      await fetch(`${config.activateServer}:${config.activatePORT}/getHuddlesByPatientID`, {
        method: 'POST',
        body: JSON.stringify({ token: token, patientID: props.patientID }),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        mode: 'cors'
      }).then(res => {
        if (!res.ok) {
          throw Error('Could not fetch the data for the resource');
        }
        return res.json();
      }).then(meetings => {

        setTeamletPatientHistory(meetings.sort((a:any,b:any) => (a.healthcareTeam.name > b.healthcareTeam.name) ? 1 : ((b.healthcareTeam.name > a.healthcareTeam.name) ? -1 : 0)));
        
        const newArray = meetings.sort(function (a: any, b: any) {
          // @ts-ignore
          return (new Date(a.meetingTime) - new Date(b.meetingTime));
        });

        setPatientHistory(() => { return newArray});
        setRangeStart(formatDate(meetings[0].meetingTime));
        setRangeEnd(formatDate(meetings[meetings.length - 1].meetingTime));
      }).catch(err => {
        if (err.name === 'AbortError') {
          //console.log('fetch aborted');
        } else {
          // TODO fill in
        }
      });

      setShowProgress(false);
    } catch (err) {
      setShowErrorModal(true);
      huddleScheduleController.abort();
      setShowProgress(false);
    }
  };

  //  Init
  useEffect(() => {
    if (props.patientID === '' || props.patientID === undefined) {
      return;
    }

    fetchPatientName().catch(console.error);
    // call the function
    fetchHuddlesByPatient().catch(console.error);
    //  Clean-up useEffect
    return () => {
      huddleScheduleController.abort();
    };
  }, [props.patientID]);

  /**
  * @name getMeetingDate
  * @description Properly extract the date from the huddle datetime
  * @param grid row
  * @returns 
  */
  function getMeetingDate(e: any) {

    const meetingDT = new Date(e.data.meetingTime)

    const huddleID = e.data.huddle.internalID;
    const meetingID = e.data.internalID;
    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let occurrenceDate = formatDate(e.data.meetingTime);
    let occurrenceDay = weekday[meetingDT.getDay()];
    let occurrenceTime = new Date(e.data.meetingTime).toLocaleTimeString().toLowerCase()

    let dateandtime = `${occurrenceDate} ${occurrenceDay} @ ${occurrenceTime}`;

    return (
      <p className="actions">
        <Anchor onClick={(e) => { props.setHuddleID(huddleID); props.setMeetingID(meetingID); props.switchView("huddledetails"); }}>{dateandtime}</Anchor>
      </p>
    );
  }

  /**
   * @name formatDate
   * @description Formats date in mm/dd/yyy format
   * @param string 
   * @returns 
  */
  function formatDate(dateStringToFormat: string) {
    let date, month, year;
    const inputDate = new Date(dateStringToFormat);
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date.toString().padStart(2, '0');
    month = month.toString().padStart(2, '0');

    return (`${month}/${date}/${year}`);
  }

  function renderTeamlet(e: any) {
    return (
      <p className="actions">
        <Anchor href="#" onClick={() => { props.setHuddleID(e.data.huddle.internalID); props.setMeetingID(e.data.meeting); props.setSelectedTeamletID(e.data.healthcareTeam.internalID); props.switchView("TeamletDetails"); }}>{e.data.healthcareTeam.name}</Anchor>
      </p>
    );
  }

  function renderTeamletGroup(e: any) {
    return (
      <p >
        <Anchor onClick={() => { props.setHuddleID(e.data.items[0].huddle.internalID); props.setMeetingID(e.data.items[0].meeting); props.setSelectedTeamletID(e.data.items[0].healthcareTeam.internalID); props.switchView("TeamletDetails"); }}>{e.value}</Anchor>
      </p>
    );
  }

  function onHuddleViewByChange(e: any) {
    setHuddleViewBy(e.currentTarget.value)
  }

  /**
  * @name renderColumnHeader
  * @description provides column header format to DataGrid
  * @param string
  * @returns 
 */
  const renderColumnHeader = (data: any) => {
    return <p>{data.column.caption}</p>;
  };

  /**
    * @name renderReasonNotes
    * @description provides format for patient reason they were added to the huddle and notes from the discussion to DataGrid
    * @param string
    * @returns 
   */
  const renderReasonNotes = (data: any) => {

    return (data.row.data.notes > '' ?
      <p>{data.row.data.criteria}<br /><span style={{ fontWeight: 'bold' }}> Notes: </span> {data.row.data.notes}</p> : <p>{data.row.data.criteria}</p>
    );
  };

  /**
   * @name renderCheck
   * @description provides check format to DataGrid
   * @param string
   * @returns 
  */
  const renderCheck = (data: any) => {
    return (data.data.discussed === true ?
      <Icon size="18" name="check" className="rpm"></Icon> : null
    );
  };

  return (
    <Container fluid>

      <Breadcrumb>
        <Breadcrumb.Item onClick={() => props.switchView("patientpanel")}>Patients</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => props.switchView("patientInformation")}>{firstName} {middleName} {lastName} {suffix === 'Not Provided' ? null : suffix}</Breadcrumb.Item>
        <Breadcrumb.Item active >HuddleHistory</Breadcrumb.Item>
      </Breadcrumb>
      <div className="page-panel">
        <section>
          <h2>Huddle History</h2>
        </section>
        <div className="d-flex">
          <p>
            {rangeStart !== undefined && rangeStart !== '' ? <label>{formatDate(rangeStart)}</label> : null}
            {rangeEnd !== undefined && rangeEnd !== '' ? <>&nbsp;to&nbsp;<label>{formatDate(rangeEnd)}</label></> : null}
          </p>
          <div className="form-group reset alignRight">
            <ButtonGroup className="float-end" size={'sm'} >
              {huddleViewByButtons.map((r, idx) => (
                <ToggleButton key={idx} id={`r-${idx}`}
                  type="radio"
                  name="r"
                  value={r.value}
                  variant={huddleViewBy === r.value ? "primary" : "outline-primary"}
                  checked={huddleViewBy === r.value}
                  onChange={onHuddleViewByChange}>
                  {r.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </div>
        </div>
        <div>
          <div id="huddleScheduleGrid">
            <LoadPanel visible={showProgress} message={"Loading Huddle History"} showPane={true} position={position} showIndicator={true}></LoadPanel>
            {huddleViewBy === "1" ? <DataGrid id="grid-container"
              dataSource={patientHistory}
              keyExpr="meeting"
              focusStateEnabled={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={false}
              width={'100%'}
              noDataText='No Huddles scheduled for this Patient'>
              <Selection mode="none" />
              <Sorting mode="single" />
              <Paging defaultPageSize={7} />
              <Column dataField="meeting" caption="Meeting Id" visible={false} />
              <Column dataField="huddle.internalID" caption="huddle Id" visible={false} />
              <Column dataField="meetingTime" caption="Date" width={"15%"} cellRender={e => getMeetingDate(e)} allowSorting={false} />
              <Column width={"20%"} dataField="healthcareTeam.name" caption="Teamlet" cellRender={renderTeamlet} allowSorting={false} />
              <Column dataField="criteria" width={"50%"} caption={"Reason and Notes"} cellRender={renderReasonNotes} headerCellRender={renderColumnHeader} />
              <Column key={Math.random()} caption="Discussed" cellRender={renderCheck}></Column>
              <Paging
                defaultPageSize={9}
                defaultPageIndex={1} />
              <Pager showNavigationButtons={true} />
            </DataGrid> : <DataGrid id="grid-container"
              dataSource={teamletPatientHistory}
              keyExpr="meeting"
              focusStateEnabled={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={false}
              width={'100%'}
              noDataText='No Huddles scheduled for this Patient'>
              <GroupPanel visible={false} />
              <Selection mode="none" />
              <Sorting mode="single" />
              <Paging defaultPageSize={7} />
              <Column dataField="meeting" caption="Meeting Id" visible={false} />
              <Column dataField="huddle.internalID" caption="huddle Id" visible={false} />
              <Column dataField="meetingTime" caption="Date" width={"30%"} cellRender={e => getMeetingDate(e)} allowSorting={false} />
              <Column width={'220'} dataField="healthcareTeam.name" caption="Teamlet" groupCellRender={renderTeamletGroup} groupIndex={0} allowSorting={false} />
              <Column dataField="criteria" width={"50%"} caption={"Reason and Notes"} cellRender={renderReasonNotes} headerCellRender={renderColumnHeader} />
              <Column key={Math.random()} caption="Discussed" cellRender={renderCheck}></Column>
              <Paging
                defaultPageSize={9}
                defaultPageIndex={1} />
              <Pager showNavigationButtons={true} />
            </DataGrid>}
          </div>
        </div>

        <ModalErrorMessage
          showErrorModal={showErrorModal}
          errorMessageTitle={"Assigned Patients"}
          errorMessage={"An error occurred retrieving Assigned Patients"}
          errorServerMessage={"See console for error message."}
          errorUserMessage={""}
          messageType="error"
          setShowErrorModal={setShowErrorModal}
        />
      </div>
    </Container>
  );
}

export default HuddleHistory;

import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Container, Form, Button, InputGroup, Navbar, Nav, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ModalErrorMessage } from '../Shared/MessageBox/ModalErrorMessage';
import RegistrationFooter from './RegistrationFooter';
const ACTIVATElogo = require('../../Resources/ACTIVATE_logo_small.png');
const config = require('../../config.json');

function Registration() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [registeredToken, setToken] = useState("");
    const [enrollmentToken, setEnrollmentToken] = useState("");
    const [registered, setRegistered] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [strPword, setStrPword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [validUsername, setValidUsername] = useState(false);
    const [usernameCheck, setUsernameCheck] = useState("");
    const [validPassword, setValidPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordCheck, setPasswordCheck] = useState("");
    const [matchingPassword, setMatchingPassword] = useState(false);
    const [internalID, setInternalID] = useState("");
    const [organizationName, setOrganizatonName] = useState("");
    const [userRoleTemplateName, setUserRoleTemplateName] = useState("");
    const [enrollment, setEnrollment] = useState("");

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorServerMessage, setErrorServerMessage] = useState("");
    const [errorUserMessage, setErrorUserMessage] = useState("");
    const [messageType, setMessageType] = useState("error");

    useEffect(() => {
        let registeredToken = searchParams.get("registration") ? searchParams.get("registration") : "";
        //If a user goes directly to the route then we reroute them to the login pge
        if (registeredToken === "") {
            returnToLogin();
        }
        checkTokenValidation(registeredToken);
        //@ts-ignore
        setToken(registeredToken);
        getEnrollmentToken(registeredToken);
    }, [searchParams, registeredToken])

    async function checkTokenValidation(token : any) {
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/validateToken`, {
            method: 'POST',
            body: JSON.stringify({ registeredToken : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        });

        try {
            const data = await response.json();
            //console.log("GOT BACK " + JSON.stringify(data));
            if (data.valid === false) {
                returnToLogin();
            }
        } catch (err) {
            console.log("ERROR " + err);
        }
    }

    async function getEnrollmentToken(token : string | null) {
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getEnrollmentToken`, {
            method: 'POST',
            body: JSON.stringify({ registeredToken : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        });

        try {
            const data = await response.json();
            // A already registered user may get a 404 error for expire email.  Need to make
            // sure a user is redirected if so.
            if (response.status === 404) {
                returnToLogin();
            }
            setInternalID(data.internalID);
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setOrganizatonName(data.organizationName);
            setUserRoleTemplateName(data.userRoleTemplateName);
            setEnrollment(data.enrollment);

        } catch (err) {
            console.log("ERROR " + err);
        }
    }

    function changeValue(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.name === "username") {
            setUsername(event.target.value);
        } else if (event.target.name === "password") {
            setStrPword(event.target.value);
        } else {
            setConfirmPassword(event.target.value);
        }
    }

    function togglePassword(toggle : boolean) {
        setShowPassword(toggle);
    }

    function toggleConfirmPassword(toggle : boolean) {
        setShowConfirmPassword(toggle);
    }

    function validateUsername() {
        const LEGAL_USERNAME = /^[a-zA-Z0-9]([._](?![._])|[a-zA-Z0-9]){6,20}[a-zA-Z0-9]$/;
        const isFormatCorrect = LEGAL_USERNAME.test(username);
        if (!isFormatCorrect) {
            setUsernameCheck("Invalid Username Format");
            setValidUsername(false);
        } else {
            setUsernameCheck("");
            setValidUsername(true);
        }
    }

    function validatePassword() {
        const LEGAL_PASSWORD = /(?=.*?[0-9])(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[#?!@$%()^&*-])(?=\S+$).{8,20}/;
        const isFormatCorrect = LEGAL_PASSWORD.test(strPword);
        if (!isFormatCorrect) {
            setPasswordCheck("Invalid Password Format");
            setValidPassword(false);
        } else {
            setPasswordCheck("");
            setValidPassword(true);
        }
        if (confirmPassword.length > 0){
            checkMatching();
        }
    }

    function checkMatching() {
        if (validPassword === false) {
            setPasswordCheck("Password is invalid");
        }
        else if (strPword !== confirmPassword){
            setPasswordCheck("Passwords do not match");
            setMatchingPassword(false);
        } else {
            setPasswordCheck("Passwords match");
            setMatchingPassword(true);
        }
    }

    async function register() {
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/Registration`, {
            method: 'POST',
            body: JSON.stringify({ 
                "internalID": internalID,
                "firstName": firstName,
                "lastName": lastName,
                "organizationName": organizationName,
                "userRoleTemplateName": userRoleTemplateName,
                "username": username,
                "password": strPword,
                "enrollment": enrollment
            }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        })

        try {
            const data = await response.json();
            //  Check for non-200s
            if (response.status !== 200) {
                setRegistered(false);
                setErrorMessageTitle("Error with your Username");
                setErrorMessage("An error occurred while selecting your username.");
                setErrorServerMessage(data.message);
                setErrorUserMessage("");
                setShowErrorModal(true);
            } else {
                setRegistered(true);
            }
        } catch(err){
            console.log("ERROR " + err);
        }
    }

    function returnToLogin() {
        navigate('/login', {state: {}});
    }

    return(
        <div className="activate-background">
            <Navbar id="navContainer">
                <Container>
                    <Nav>
                        <img id="ACTIVATElogo" src={ACTIVATElogo} alt="ACTIVATElogo"></img>
                    </Nav>
                </Container>
            </Navbar>
            <Container className="d-flex justify-content-center">
                {registered === false ?
                        <Card className="login-card" style={{width:"620px"}}>
                            <Card.Body>
                                <Card.Title>
                                    <h1>{firstName} {lastName}, welcome to ACTIVATE!</h1>
                                    <p>You've been invited!  Please complete your Registration
                                    and we can get you using ACTIVATE right away.
                                    </p>
                                </Card.Title>
                                <Form.Group className="form-group">
                                    <h2>Choose a username</h2>
                                    <Form.Label className="required">Username</Form.Label>
                                    <Form.Control
                                        id="username"
                                        name="username"
                                        onChange={changeValue}
                                        value={username}
                                        onBlur={validateUsername}
                                        placeholder="Username" />
                                    <Form.Text>
                                        This will be your unique username to use whenever you sign in to ACTIVATE.
                                    </Form.Text>
                                </Form.Group>
                                        {usernameCheck.length > 0 ?
                                        <div>
                                            <p className="error-text">{usernameCheck}</p>
                                            <ul>
                                                <li>It contains at least 8 characters and at most 20 characters.</li>
                                                <li>It contains no spaces.</li>
                                                <li>No non-alphanumeric characters except _.</li>
                                                <li>The _ character cannot be used consecutively.</li>
                                            </ul>
                                        </div>: <></>}

                                <Form.Group className="form-group" controlId="formBasicPassword">
                                    <h2>Choose a password</h2>
                                    <Form.Text>Your password should have the following.
                                        <ul>
                                            <li>It contains at least 8 characters and at most 20 characters.</li>
                                            <li>It contains at least one digit.</li>
                                            <li>It contains at least one upper case alphabet.</li>
                                            <li>It contains at least one lower case alphabet.</li>
                                            <li>It contains at least one special character which includes !@#$%()&*-+=^.</li>
                                            <li>It does not contain any white space.</li>
                                        </ul>
                                    </Form.Text>

                                    <Form.Label className="required">Password</Form.Label>
                                    <InputGroup className="form-group">
                                        <Form.Control
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Password"
                                            name="password"
                                            value={strPword}
                                            onChange={e => setStrPword(e.target.value)}
                                            onBlur={validatePassword}/>
                                        <Button variant="outline-secondary" onClick={() => togglePassword(!showPassword)}>
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
                                        </Button>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className="form-group">
                                    <Form.Label className="required">Confirm Password</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type={showConfirmPassword ? "text" : "password"}
                                            placeholder="Password"
                                            name="confirmPassword"
                                            value={confirmPassword}
                                            onBlur={checkMatching}
                                            onChange={e => setConfirmPassword(e.target.value)} />
                                        <Button variant="outline-secondary" onClick={() => toggleConfirmPassword(!showConfirmPassword)}>
                                            <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye}/>
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                                <p style={matchingPassword ? { color : 'green' } : { color : 'red'}}>
                                    {passwordCheck}
                                </p>
                                <Form.Group className="login-actions">
                                <Button
                                    variant="primary"
                                    className="submit-button"
                                    disabled={username === "" || !validPassword || !matchingPassword || !validUsername}
                                    onClick={() => register()}>
                                    Register
                                </Button>
                                </Form.Group>
                                <RegistrationFooter/>
                            </Card.Body>
                        </Card>
                :
                        <Card className="login-card">
                            <Card.Body>
                                <Card.Title>
                                    <h1>Successfully Registered!</h1>
                                    <p>You have successfully registered with ACTIVATE.</p>
                                    <p>You may sign in to begin using ACTIVATE.</p>
                                </Card.Title>
                                <Form.Group className="login-actions">
                                    <FontAwesomeIcon icon={faCheckCircle}/>
                                    <Button className='submit-button' onClick={() => returnToLogin()}>Go to Sign In</Button>
                                </Form.Group>
                                <RegistrationFooter/>
                            </Card.Body>
                        </Card>
                }
               
            </Container>

            <ModalErrorMessage
                showErrorModal={showErrorModal}
                errorMessageTitle={errorMessageTitle}
                errorMessage={errorMessage}
                errorServerMessage={errorServerMessage}
                errorUserMessage={errorUserMessage}
                messageType={messageType}
                setShowErrorModal={setShowErrorModal}/>
        </div>
    )
}

export default Registration;
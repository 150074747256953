/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Breadcrumb, Button, Modal } from 'react-bootstrap';
import { DataGrid, MasterDetail, GroupPanel, SearchPanel, Selection, Column, Scrolling, Paging} from 'devextreme-react/data-grid';
import {Button as DevButton} from 'devextreme-react/data-grid';
import PermissionService from '../../Services/PermissionService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import './EnrollmentInvitations.css';
import { UNSPECIFIED } from '../Shared/constants';
const config = require('../../config.json');

function EnrollmentInvitations(props : { switchView : (newView : string) => void, permissions : PermissionService}) {
    
    const [mapState, setMapState] = useState(new Map());
    const [enrollmentList, setEnrollmentList] = useState([]);
    const [showDeleteEnrollmentModal, setShowDeleteEnrollmentModal] = useState(false);
    const [deleteID, setDeleteID] = useState("");

    useEffect(() => {
        loadEnrollment();
        loadRegistrationMap();
    }, [])

    /**
     * @name loadEnrollment
     * @description Pulls all current enrollments from the server and stores it in an html array
     * @returns {HTMLElement[]} 
     */
    async function loadEnrollment(){
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getEnrollmentEmails`, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        })

        try {
            const data = await response.json();
   
            //  Load the table here
            setEnrollmentList(data);
        } catch(err){
            console.log("ERROR " + err);
        }
    }

    /**
     * @name loadRegistrationMap
     * @description Loads registration map
     * @returns {HTMLElement[]} 
    */
    async function loadRegistrationMap(){
       
        const token = sessionStorage.getItem('ACTIVATEtoken');
        mapState.clear();
        
        const response = await fetch(
            `${config.activateServer}:${config.activatePORT}/getValues`,
            {
              method: "POST",
              body: JSON.stringify({ token: token, type: 'RegistrationStatusType' }),
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              mode: "cors",
            }
          );
        
          try {
            const data = await response.json();

            //  Loop through each registration status and load
            for (let i = 0; i < data.length; i++) {
                //  Load the table here
                setMapState(map => new Map(map.set(data[i].value, data[i].name)));
            }
        } catch(err){
            console.log("ERROR " + err);
        }
    }


    /**
     * @name deleteEnrollmentEmail
     * @description Searches the enrollment email by ID and deletes that specific enrollment.  Then loads the page again  
     */
    async function deleteEnrollmentEmail(id : string){
        const token = sessionStorage.getItem('ACTIVATEtoken');
            const response = await fetch(`${config.activateServer}:${config.activatePORT}/deleteEnrollmentEmail`, {
                method: 'POST',
                body: JSON.stringify({ 
                    token : token,
                    enrollmentID : id
                }),
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode : 'cors'
            })

            try {
                //const data = await response.json();
                //console.log("GOT BACK " + JSON.stringify(data));
                loadEnrollment();
            } catch(err) {
                console.log("ERROR deleting enrollment " + JSON.stringify(err));
            }

            //  Hide Pop-up
            showDeleteModal("", false);
    }

    //  Edit Organization onClick handler
    function editClick(e : any) {
        //  Check for row Object
        if (e.row != null)  {
            //
        }
    }

    //  Prompt to Delete Enrollment
    function trashClick(e : any) {
        showDeleteModal(e.row.data.internalID, true)
        e.event.preventDefault();
    }

    //  Delete Pop-up
    function showDeleteModal(id : string, toggle : boolean) {
      setDeleteID(id);
      setShowDeleteEnrollmentModal(toggle);
    }

    //  Call Delete Enrollment 
    function deleteEnrollment(EnrollmentID : string){
        deleteEnrollmentEmail(EnrollmentID)
    }

    //  Format Huddle Date
    function getExpirationDateTimeFormated(e:any){
        return (
            <>
                {new Date(e.data.expirationTime).toDateString()} <br/> {new Date(e.data.expirationTime).toLocaleTimeString()}
            </>
        )
    }

    //  Concatenates Name for Health Staff Grid
    function getName(e:any){
        return (
            <>
                {e.data.title == UNSPECIFIED ? '' : e.data.title} {e.data.firstName + ' ' + e.data.lastName} {e.data.ordinal == UNSPECIFIED ? '' : e.data.ordinal}
            </>
        )
    }

    //  Gets the proper display value from the Service's enumeration
    function getStatus(e:any){
        return (
            <>
                {mapState.get(e.data.status)}
            </>
        )
    }

    return(
        <div>
        <Container fluid>
        
            <Breadcrumb>
            <Breadcrumb.Item onClick={() => props.switchView("configuration")}>Configuration</Breadcrumb.Item>
            <Breadcrumb.Item active>Enrollment Invitations</Breadcrumb.Item>
            </Breadcrumb>

            <div className="page-panel">
                {props.permissions.checkSubfunctionPermission("CONFIGURATION", "PEOPLE", "ENROLLMENT_INVITATIONS", "CREATE") ?
                <div className="panel-function-header">
                    <Button className="btn-function-button"
                        variant="function"
                        onClick={() => props.switchView("newEnrollmentInvitation")}>Invite User</Button>
                </div>
                : <></>}
                
                <DataGrid id="grid-container" showColumnLines={false} showRowLines={false} width={"100%"}
                    showBorders={true}
                    focusStateEnabled={true}
                    dataSource={enrollmentList}
                    allowColumnResizing={true}
                    noDataText='No ACTIVATE Enrollment Invitations have been loaded'>
                        <Scrolling rowRenderingMode='virtual'></Scrolling>
                        <Paging defaultPageSize={10} defaultPageIndex={0} />
                        <Selection mode="none" />
                        <Column dataField="firstName" minWidth={100} width={125} caption="Name" cellRender={getName} />
                        <Column dataField="emailAddresses[0].address" minWidth={100} width={150} caption="Email Address" />
                        <Column dataField="userRoleTemplateName" minWidth={150} width={250} caption="Role" />
                        <Column dataField="organizations[0].name" minWidth={300} width={325} caption="Organization" />
                        <Column dataField="status" minWidth={100} width={100} caption="Status" cellRender={getStatus} />
                        <Column dataField="expirationTime" minWidth={50} width={50} caption="Expires" cellRender={getExpirationDateTimeFormated} />
                        <Column type="buttons" caption="Action" minWidth={70} width={70}>
                                <DevButton hint="edit" icon="edit" visible={false} onClick={editClick} />
                                <DevButton hint="delete" visible={true} icon="trash" onClick={trashClick} />
                        </Column>
                    <Column dataField="internalID" caption="Id" visible={false} />
                </DataGrid>

            </div>
            </Container>

            <Modal show={showDeleteEnrollmentModal}>
                <Modal.Header className="system">
                    <h2>Delete Enrollment?</h2>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this Enrollment?  This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button className="system cancel" onClick={() => showDeleteModal("", false)}>Cancel</Button>
                    <Button className="system submit" onClick={() => deleteEnrollment(deleteID)}>Delete</Button>
                </Modal.Footer>
            </Modal>
    </div>
    )
}

export default EnrollmentInvitations
export default class PermissionService {
    myInternalID : string;
    myInformation : any;
    myOrgs : any;
    isEhrOrganization: boolean;

    constructor() {
        this.myInternalID = '';
        this.myInformation = [];
        this.myOrgs = [];
        this.isEhrOrganization = false;

        this.loadUserOrgs = this.loadUserOrgs.bind(this);
        this.getUserOrgs = this.getUserOrgs.bind(this);
        this.loadPermissions = this.loadPermissions.bind(this);
        this.getMyInformation = this.getMyInformation.bind(this);
        this.getReadConfiguration = this.getReadConfiguration.bind(this);
    }

    setMyInternalID(newID: string) {
        this.myInternalID = newID;
    }

    getMyInternalID() {
        return this.myInternalID;
    }

    setIsEHROrganization(isEHROrg: boolean){
        this.isEhrOrganization = isEHROrg;
    }

    getIsEHROrganization(){
        return this.isEhrOrganization;
    }

    loadPermissions(permissions : any) {
        this.myInformation=permissions;
    }

    loadUserOrgs(orgs : any) {
        this.myOrgs = orgs;
    }

    getUserOrgs() {
        return this.myOrgs;
    }

    getMyInformation() {
        return this.myInformation;
    }

    getReadConfiguration() {
        //console.log("CHECKING SERVICE FOR CONFIG " + JSON.stringify(this.myInformation));
        for (let i = 0; i < this.myInformation.length; i++) {
            if (this.myInformation[i].value === "CONFIGURATION") {
                return true;
            }
        }
        return false;
    }

    checkPermissionExists(permission : string) {
        for (let i = 0; i < this.myInformation.length; i++) {
            if (this.myInformation[i].value === permission) {
                return true;
            }
        }
        return false;
    }

    //Example call: checkPermissionSetting("CONFIGURATION", "READ");
    checkPermissionSetting(permission : string, crudtype : string) {
        for (let i = 0; i < this.myInformation.length; i++) {
            if (this.myInformation[i].value === permission) {
                return (this.myInformation[i].permissions).find((t : any) => t.value === crudtype).enabled;
            }
        }
        return false;
    }

    //Example call: checkFunctionPermission("CONFIGURATION", "STRUCTURE", "CREATE");
    checkFunctionPermission( permission : string, permissionFunction : string, crudtype : string ) {
        let functionList = [];
        //console.log("CHECKING PERMISSION " + permission);
        for (let i = 0; i < this.myInformation.length; i++) {
            if (this.myInformation[i].value === permission) {
                functionList = this.myInformation[i].subfunctions;
                break;
            }
        }
        for (let j = 0; j < functionList.length; j++) {
            if (functionList[j].value === permissionFunction) {
                return (functionList[j].permissions).find((t : any) => t.value === crudtype).enabled;
            }
        }
        return false;
    }

    checkSubfunctionPermission( permission : string, permissionFunction : string, permissionSubfunction : string, crudtype : string) {
        let functionList = [];
        let subFunctionList = [];
        //console.log("CHECKING SUB FUNCTION " + permissionSubfunction);
        for (let i = 0; i < this.myInformation.length; i++) {
            if (this.myInformation[i].value === permission) {
                functionList = this.myInformation[i].subfunctions;
                break;
            }
        }
        //console.log("FOUND FUNCTIONS " + JSON.stringify(functionList));
        for (let j = 0; j < functionList.length; j++) {
            if (functionList[j].value === permissionFunction) {
                subFunctionList = functionList[j].subSubfunctions;
                break;
            }
        }
        for (let k = 0; k < subFunctionList.length; k++) {
            if (subFunctionList[k].value === permissionSubfunction) {
                return (subFunctionList[k].permissions).find((t : any) => t.value === crudtype).enabled;
            }
        }
        return false;
    }
}
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Table, Card, Breadcrumb } from 'react-bootstrap';
const config = require('../../config.json');

function PathwaySettings(props: 
    {
        switchView: Function,
        parentOrgID : string,
        orgName : string
    }){
    const [displayVitalData, setDisplayVitalData] = useState<any[]>([]);

    useEffect(() => {
        loadDataVitals();
    }, [])

    function loadPathwayDetails(){

    }

    /**
    * @name loadVitalsHeaders
    * @description Loads the default headers for the Vital Data table
    * @returns HTMLElement[]
    */
    function loadVitalsHeaders(){
        let headerList = ["Vitals Reading", "Low", "High"];
        return headerList.map((key, index) => {
            return <th key={index}>{key}</th>
        })
    }

    /**
    * @name loadDataVitals
    * @description Calls the server and loads the currently stored Vital Data by site
    */
    async function loadDataVitals(){
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getVitalData`, {
            method: 'POST',
            body: JSON.stringify({ token : token, orgID : props.parentOrgID }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(async (response: { ok: any; json: () => any; status: { toString: () => string; }; }) => {
            //  Check for Success
            if (response.ok)
            {
                try {
                    const data = await response.json();
                    console.log("RECEIVED VITALS " + JSON.stringify(data));
                    let tempVitalInfo = [];
                    for(let i = 0; i < data.length; i++){
                        tempVitalInfo.push(
                            <tr key={i}>
                                <td>{data[i].readingName}</td>
                                <td>≤{data[i].dataValues[0].thresholdValue}</td>
                                <td>&#62;{data[i].dataValues[1].thresholdValue}</td>
                            </tr>
                        )
                    }
                    
                    setDisplayVitalData(tempVitalInfo);
                } catch(err){
                    console.log("Error in loadStatusOptions: " + err);
                }
            }
            else {
                // ToDo:  Handle non-200s
                console.log("Received HTTP status " + response.status.toString())
            }
        })
    }

    return(
        <Container className="bg-white" fluid style={{marginTop: "50px"}}>
            <Row>
                <Col sm={12} >
                    <Breadcrumb style={{marginTop: "30px", marginLeft: "30px", marginBottom: "30px"}}>
                        <Breadcrumb.Item className="font-face-activate-heading3" style={{color: "#3764D4", cursor: "pointer", textDecoration:"none"}} onClick={() => props.switchView("dashboard")} active>{props.orgName}</Breadcrumb.Item>
                        <Breadcrumb.Item className="font-face-activate-heading3" style={{color: "black"}} active>Pathway Settings</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col sm={5}>
                    <Card id="PathwayHuddleGuidelines" style={{ textAlign: "left", marginTop: "20px"}}>
                        <Card.Body>
                                <Card.Title className="text-left">
                                    <p className='font-face-activate-heading3'>Pathway Huddle Guidelines</p>
                                    <p className='font-face-activate-main-text'></p>
                                    
                                    <br/>
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    <br/>
                </Col>
                <Col sm={5}>
                    <Row>
                        <Card id="PathwayAbout" style={{ textAlign: "left", marginTop: "20px"}}>
                            <Card.Body>
                                <Card.Title className="text-left">
                                    <p className='font-face-activate-heading3'>About</p>
                                </Card.Title>
                            </Card.Body>
                        </Card>
                        <br/>
                    </Row>
                    <Row>
                        <Card id="PathwayVitalData" style={{ textAlign: "left", marginTop: "20px"}}>
                            <Card.Body>
                                <Card.Title className="text-left">
                                    <p className='font-face-activate-heading3'>Pathway Data Vitals</p>
                                </Card.Title>
                                <Table>
                                    <thead className='font-face-activate-caption'>
                                        <tr>
                                            {loadVitalsHeaders()}
                                        </tr>
                                    </thead>
                                    <tbody className='font-face-activate-secondary-text'>
                                        {displayVitalData}
                                    </tbody>
                                </Table>
                            </Card.Body>
                            <Card.Footer>
                                <Button onClick={() => props.switchView("addVitalData")}>Add Vital Data Threshold</Button>
                            </Card.Footer>
                        </Card>
                        <br/>
                    </Row>
                </Col>
            </Row>
            <Row>

                <Col sm={5}>
                    <Card id="PathwayInclusionCriteria" style={{ textAlign: "left", marginTop: "20px"}}>
                        <Card.Body>
                                <Card.Title className="text-left">
                                    <p className='font-face-activate-heading3'>Pathway Inclusion Criteria</p>
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    <br/>
                </Col>
            </Row>

        </Container>
    )
}


export default PathwaySettings;
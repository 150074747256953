/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Form, Card, Navbar, Nav, InputGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './LoginPage.css';
import RegistrationFooter from './Registration/RegistrationFooter';
const config = require('../config.json');
const ACTIVATElogo = require('../Resources/ACTIVATE_logo_small.png');

function Login() {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [strPword, setStrPword] = useState("");
    const [myInternalID, setMyInternalID] = useState('');
    const [isAuth, setAuth] = useState(false);
    const [userRole, setRole] = useState("");
    const [isEHROrganization, setIsEHROrganization] = useState(false);
    const [displayRoleName, setDisplayRoleName] = useState("");
    const [userOrganizations, setUserOrganizations] = useState([]);
    const [parentOrgName, setParentOrgName] = useState("");
    const [parentOrgID, setParentOrgID] = useState("");
    const [userPermissions, setUserPermissions] = useState([]);
    const [loginAttempts, setLoginAttempts] = useState(0);
    const [usernameError, setUsernameError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [otp, setotp] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [pin1, setPin1] = useState("");
    const [pin2, setPin2] = useState("");
    const [pin3, setPin3] = useState("");
    const [pin4, setPin4] = useState("");
    const [pin5, setPin5] = useState("");
    const [pin6, setPin6] = useState("");
    const [showOTPNotification, setShowOTPNotification] = useState(false);
    const [oTPNotificationMessage, setOTPNotificationMessage] = useState("");

    
    useEffect(() => {
        if (isAuth === true) {
            if (userRole === 'ROLE_PATIENT') {
                navigate('/Patient', { state: { username: username, role: userRole, permissions: userPermissions, displayRoleName } });
            } else if (userRole === 'ROLE_ACTIVATE_ADMINISTRATOR') {
                navigate('/Administrator', {
                    state:
                    {
                        username: username, role: userRole, permissions: userPermissions, displayRoleName, userOrganizations,
                        parentOrgID, parentOrgName, myInternalID, isEHROrganization
                    }
                });
            } else if (userRole === 'ROLE_HEALTH_COACH') {
                navigate('/HealthCoach', {
                    state:
                    {
                        role: userRole, username: username, permissions: userPermissions, displayRoleName, userOrganizations,
                        parentOrgID, parentOrgName, myInternalID, isEHROrganization
                    }
                });
            } else if (userRole === 'ROLE_PROVIDER') {
                navigate('/Provider',
                    {
                        state: {
                            role: userRole, username: username, permissions: userPermissions, displayRoleName, userOrganizations,
                            parentOrgID, parentOrgName, myInternalID, isEHROrganization
                        }
                    });
            } else if (userRole === 'ROLE_DELEGATED_HEALTH_STAFF') {
                navigate('/HealthStaff', {
                    state:
                    {
                        role: userRole, username: username, permissions: userPermissions, displayRoleName, userOrganizations,
                        parentOrgID, parentOrgName, myInternalID, isEHROrganization
                    }
                });
            } else if (userRole === 'ROLE_DIGITAL_HEALTH_NAVIGATOR') {
                navigate('/DigitalHealthNavigator', {
                    state:
                    {
                        role: userRole, username: username, permissions: userPermissions, displayRoleName, userOrganizations,
                        parentOrgID, parentOrgName, myInternalID, isEHROrganization
                    }
                });
            } else if (userRole === 'ROLE_ORGANIZATION_ADMINISTRATOR') {
                navigate('/OrganizationAdministrator', {
                    state:
                    {
                        role: userRole, username: username, permissions: userPermissions, displayRoleName, userOrganizations,
                        parentOrgID, parentOrgName, myInternalID, isEHROrganization
                    }
                });
            } else if (userRole === 'ROLE_ORGANIZATION_SUPER_USER') {
                navigate('/SuperUser', {
                    state:
                    {
                        role: userRole, username: username, permissions: userPermissions, displayRoleName, userOrganizations,
                        parentOrgID, parentOrgName, myInternalID, isEHROrganization
                    }
                });
            } else if (userRole === 'ROLE_PATIENT_DELEGATE') {
                navigate('/PatientDelegate', {
                    state:
                    {
                        role: userRole, username: username, permissions: userPermissions, displayRoleName, userOrganizations,
                        parentOrgID, parentOrgName, myInternalID, isEHROrganization
                    }
                });
            } else {
                //TODO Redirect a bad role
            }
        }

        //  Handle the Enter key
        const listener = async (event: { code: string; preventDefault: () => void; }) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                //  Enter selected for regular log-in
                if (otp === false) {
                    //  Call Log-in
                    await sendLogin();
                } else {
                    //  Call OTP Log-in
                    await otpLogin();
                }
            }
        };

        //  Wire-up keydown event
        document.addEventListener("keydown", listener);

        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    function forgotUsername() {
        navigate('/forgotusername', { state: {} });
    }

    function forgotPassword() {
        navigate('/FailedLogin', { state: { status: "EnterEmail" } });
    }

    async function sendLogin() {
        setUsernameError("");
        setErrorMessage("");
        if (loginAttempts >= 5) {
            navigate('/FailedLogin', { state: { status: "ForgotPassword" } });
        }
        if (username.length === 0) {
            setUsernameError("The username field is empty.  Please enter your username to login.");
        } else if (strPword.length === 0) {
            setErrorMessage("The password field is empty.  Please enter your password to login.");
        } else {
            try {
                //console.log(`FETCHING ${config.activateServer}:${config.activatePORT}/loginCheck`);
                const response = await fetch(`${config.activateServer}:${config.activatePORT}/loginCheck`, {
                    method: 'POST',
                    body: JSON.stringify({ username: username, password: strPword }),
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    mode: 'cors'
                });

                const data = await response.json();

                //console.log("GOT BACK " + JSON.stringify(data) + " attempt: " + loginAttempts);

                //Check that we have received a good data object
                if (data) {
                    if (response.status === 200) {
                        sessionStorage.setItem('ACTIVATEtoken', data.jwttoken);
                        sessionStorage.setItem('authenticated', 'true');
                        setMyInternalID(data.userInternalID);
                        setRole(data.role);
                        setIsEHROrganization(data.ehrOrganization);
                        // TEST setIsEHROrganization(true);
                        setUserPermissions(data.functions);
                        setDisplayRoleName(data.roleName);
                        setUserOrganizations(data.organizations);
                        setParentOrgName(data.parentOrganization.name);
                        setParentOrgID(data.parentOrganization.value);
                        setAuth(true);
                    // Branch to check specific bad message.
                    } else if (response.status === 401) {
                        // Once time password needs to be sent to user
                        if (data.errors[0] === 'OTP Required') {
                            setotp(true);
                        //Invalid Credentials
                        } else {
                            setLoginAttempts(loginAttempts + 1);
                            if (loginAttempts <= 2) {
                                setErrorMessage(`Incorrect username or password`);
                            } else {
                                setErrorMessage(`An incorrect username or password has been entered ${loginAttempts} times in a row.  
                                You have ${5 - loginAttempts} more attempts before the account is locked.`);
                            }
                        }
                    // User is in INACTIVE, or DISABLED status.  No user recovery
                    } else if (response.status === 405) {
                        setErrorMessage('This account looks to be inactive or disabled.  Please contact your account administrator.');
                    // Account locked.  Send to failed login page. User can recover
                    } else if (response.status === 410) {
                        setErrorMessage('Your One Time Password has expired.  Please contact your account administrator');
                    // Account has been blocked.  Occurs from too many failed logins
                    } else if (response.status === 423) {
                        navigate('/FailedLogin', { state: { status: "Blocked" } });
                    // Defualt to unknown error for all other cases.
                    } else {
                        setErrorMessage(`An unknown error occurred.`);
                    }
                } else {
                    setErrorMessage(`Please check your connection and refresh your page. If the error continues, please try again later.`);
                }
            } catch (err) {
                // Keep console disabled while outside of testing.  User should not see status codes based on fetch
                //console.log("ERROR " + err);
                setErrorMessage(`Please check your connection and refresh your page. If the error continues, please try again later.`);
            }
        }
    }

    /**
     * @name resendPasscode
     * @description Resends the OTP
     * @param 
     * @returns {} 
    */
    async function resendPasscode() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        await fetch(`${config.activateServer}:${config.activatePORT}/resendPasscode`, {
            method: 'POST',
            body: JSON.stringify({ 
                token : token,
                userName : username
            }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(async (_response) => {
            //  Check for Success
            if (_response.ok)
            {
                setOTPNotificationMessage("A OTP has been sent to " + username + ".  Please allow up to 30 minutes to receive.");
                showOTPModal(true);
            } else {
                const _statusCode = _response.status.toString();
                setOTPNotificationMessage("A OTP request for " + username + " was NOT successful.  Please retry.");
                showOTPModal(true);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    /**
     * @name togglePassword
     * @description Hides and Shows password
     * @param boolean
     * @returns {} 
    */
    function togglePassword(toggle: boolean) {
        setShowPassword(toggle);
    }

    /**
     * @name updatePin
     * @description Update Pin method to store state
     * @param HTMLInputElement element 
     * @returns {string} 
     */
    function updatePin(e: React.ChangeEvent<HTMLInputElement>) {
        const re = /^[0-9\b]+$/;

        //  Check for numbers only
        if (e.target.value === '' || re.test(e.target.value)) {
            //  Store the variables, as needed
            switch (e.target.name) {
                case "pin1":
                    setPin1(e.target.value);
                    break;
                case "pin2":
                    setPin2(e.target.value);
                    break;
                case "pin3":
                    setPin3(e.target.value);
                    break;
                case "pin4":
                    setPin4(e.target.value);
                    break;
                case "pin5":
                    setPin5(e.target.value);
                    break;
                case "pin6":
                    setPin6(e.target.value);
                    break;
            }

            let next = e.target.tabIndex;

            //  Blank value is clearing input
            if (e.target.value === "") {
                console.log("Ignore blanks");
            }
            else {
                next = next + 1;
            }

            //  Check length
            if (next < 6) {
                //  Check for null form and elements
                if (e.target.form != null &&
                    e.target.form.elements != null) {

                    //  Get next selected index        
                    const nextIndex = e.target.form.elements[next].id;
                    if (nextIndex != null) {
                        document.getElementById(nextIndex)?.focus();
                    }
                }
            }
        } else {
            return false;
        }
    }

    async function otpLogin() {
        //  Capture the Pin
        const pin = pin1.trim() + pin2.trim() + pin3.trim() + pin4.trim() + pin5.trim() + pin6.trim();

        const response = await fetch(`${config.activateServer}:${config.activatePORT}/submitOtp`, {
            method: 'POST',
            body: JSON.stringify({ username: username, password: strPword, otp: pin }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        });

        try {
            const data = await response.json();
            if (data.success === true) {
                sessionStorage.setItem('ACTIVATEtoken', data.jwttoken);
                sessionStorage.setItem('authenticated', 'true');
                setMyInternalID(data.userInternalID);
                setRole(data.role);
                setUserPermissions(data.functions);
                setDisplayRoleName(data.roleName);
                setUserOrganizations(data.organizations);
                setAuth(true);
            } else if (data.errors[0] === "User Account LOCKED : " + username) {
                navigate('/FailedLogin', { state: { status: "Blocked" } });
            } else if (response.status === 500) {
                setErrorMessage(data.msg);
            } else {
                setErrorMessage("Username, password, and or your one-time-password is incorrect.");
            }
        } catch (err) {
            console.log("ERROR " + err);
            setErrorMessage(``);
        }
    }

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    function showOTPModal(toggle : boolean) {
        setShowOTPNotification(toggle);
    }

    return (
        <div>
            <Navbar id="navContainer">
                <Container>
                    <Nav>
                        <img id="ACTIVATElogo" src={ACTIVATElogo} alt="ACTIVATE logo"></img>
                    </Nav>
                </Container>
            </Navbar>
            <Container className="d-flex justify-content-center">

                {otp === false ?
                    <Card className="login-card">
                        <Card.Body>
                            <Card.Title>
                                <h1>Welcome!</h1>
                                <p>Please enter your username and password.</p>
                            </Card.Title>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Form.Group className="form-group">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        placeholder="Enter username"
                                        id="username"
                                        required
                                        style={{ marginBottom: "10px" }}
                                        onChange={e => setUsername(e.target.value)}>
                                    </Form.Control>
                                    <Form.Text>
                                        <span className="error-text">{usernameError}</span>
                                        <Button
                                            tabIndex={-1}
                                            onClick={() => forgotUsername()}
                                            className="link-button">Forgot Username?</Button>
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="form-group">
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Enter password"
                                            name="password"
                                            autoComplete='false'
                                            value={strPword}
                                            onChange={e => setStrPword(e.target.value)} />
                                        <Button variant="outline-secondary" id="button-addon2" onClick={() => togglePassword(!showPassword)}>
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                        </Button>
                                    </InputGroup>
                                    <Form.Text>
                                        <span className="error-text">{errorMessage}</span>
                                        <Button
                                            tabIndex={-1}
                                            className="link-button"
                                            onClick={() => forgotPassword()}>Forgot Password?</Button>
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="login-actions">
                                    <Button className="submit-button" data-testid="loginButton" onClick={sendLogin}>Sign In</Button>
                                </Form.Group>
                                <RegistrationFooter/>
                            </Form>
                        </Card.Body>
                    </Card>
                    :
                    <Card className="login-card" >
                        <Card.Body>
                            <Card.Title>
                                <h1>Checking it&#39;s you!</h1>
                                <p>Please enter the code we sent you.</p>
                            </Card.Title>
                            <Form>
                                <Form.Group className="form-group" controlId="formBasicPassword">
                                    <div className="one-time-password-entry">
                                        <input id="pin1"
                                            name="pin1"
                                            type={showPassword ? "text" : "password"}
                                            autoComplete="off"
                                            size={1}
                                            tabIndex={0}
                                            maxLength={1}
                                            onChange={updatePin}
                                            value={pin1}>
                                        </input>

                                        <input name="pin2"
                                            id="pin2"
                                            type={showPassword ? "text" : "password"}
                                            autoComplete="off"
                                            size={1}
                                            tabIndex={1}
                                            maxLength={1}
                                            onChange={updatePin}
                                            value={pin2}>
                                        </input>

                                        <input name="pin3"
                                            id="pin3"
                                            type={showPassword ? "text" : "password"}
                                            autoComplete="off"
                                            size={1}
                                            tabIndex={2}
                                            maxLength={1}
                                            onChange={updatePin}
                                            value={pin3}>
                                        </input>
                                        <input name="pin4"
                                            id="pin4"
                                            type={showPassword ? "text" : "password"}
                                            autoComplete="off"
                                            size={1}
                                            tabIndex={3}
                                            maxLength={1}
                                            onChange={updatePin}
                                            value={pin4}>
                                        </input>

                                        <input name="pin5"
                                            id="pin5"
                                            type={showPassword ? "text" : "password"}
                                            autoComplete="off"
                                            size={1}
                                            tabIndex={4}
                                            maxLength={1}
                                            onChange={updatePin}
                                            value={pin5}>
                                        </input>

                                        <input name="pin6"
                                            id="pin6"
                                            type={showPassword ? "text" : "password"}
                                            autoComplete="off"
                                            size={1}
                                            tabIndex={5}
                                            maxLength={1}
                                            onChange={updatePin}
                                            value={pin6}>
                                        </input>


                                        <Button variant="outline-secondary" id="button-addon2" onClick={() => togglePassword(!showPassword)}>
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                        </Button>

                                    </div>
                                </Form.Group>

                                <Form.Group className="form-group">
                                    <p>Didn&#39;t receive a code? <span>
                                        <Card.Link
                                            onClick={() => resendPasscode()}>
                                            Resend code
                                        </Card.Link>
                                    </span>
                                    </p>
                                    <p className="error-text">{errorMessage}</p>
                                </Form.Group>

                                <Form.Group className="login-actions">
                                    <Button className="submit-button" onClick={() => otpLogin()}>Confirm</Button>
                                </Form.Group>

                                <RegistrationFooter/>

                            </Form>
                        </Card.Body>
                    </Card>
                }
            </Container>
            <Modal show={showOTPNotification}>
                <Modal.Header className="system">
                    <h2>OTP Password Request</h2>
                </Modal.Header>
                <Modal.Body>
                    {oTPNotificationMessage}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => showOTPModal(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Login;
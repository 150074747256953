/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState, useRef } from 'react';
import { Anchor, Container, Row, Col, Form, Card, Breadcrumb } from 'react-bootstrap';
import { DataGrid, Selection, Column, Scrolling, Paging} from 'devextreme-react/data-grid';
import './HealthProviderDashboard.css';
import { Icon } from '@ailibs/feather-react-ts';
import { LoadPanel } from 'devextreme-react/load-panel';
import OrgHuddleSchedule from '../Shared/Huddle/OrgHuddleSchedule';
import PermissionService from '../../Services/PermissionService';

const config = require('../../config.json');
let isTopParentOrg = false;

function HealthProviderDashboard(props : 
{ 
    switchView : (newView: string) => void,
    permissions: PermissionService; 
    selectedOrganization : string,
    selectedOrganizationID : string,
    setSelectedTeamletID: (Id: string) => void,
    setSelectedOrganizationName: (Id: string) => void,
    setHuddleID:  (newID: string) => void,
    setMeetingID:  (Id: string) => void,
    teamletName: string
}) {

    // Constants
    const dgHealthStaff = useRef<any>(null);
    const dgVitals = useRef<any>(null);
    const [healthCenterName, setHealthCenterName] = useState("");
    const [healthCenterStreet, setHealthCenterStreet] = useState("");
    const [healthCenterCity, setHealthCenterCity] = useState("");
    const [healthCenterState, setHealthCenterState] = useState("");
    const [healthCenterZip, setHealthCenterZip] = useState("");
    const [healthCenterPhoneNumber, setHealthCenterPhoneNumber] = useState("");
    const [healthCenterAddress, setHealthCenterAddress] = useState("");
    const [healthCenterPointOfContact, setHealthCenterPointOfContact] = useState("");
    const [healthCenterPointOfContactEmailAddress, setHealthCenterPointOfContactEmailAddress] = useState("");
    const [healthCenterPointOfContactPhone, setHealthCenterPointOfContactPhone] = useState("");
    const [healthCenterParent, setHealthCenterParent] = useState("");
    const [healthCenterDescription, setHealthCenterDescription] = useState("");

    const [huddleSummary, setHuddleSummary] = useState([]);
    const [healthStaffSummary, setHealthStaffSummary] = useState([]);
    const [healthCenterSummary, setHealthCenterSummary] = useState([]);
    const [healthcareTeamLetSummary, setHealthcareTeamLetSummary] = useState([]);
    const [digitalHealthPathwaySummary, setDigitalHealthPathwaySummary] = useState([]);

    const [assignedPatients, setAssignedPatients] = useState("");
    const [patientsAdded, setPatientsAdded] = useState("");
    const [reportedVitalsToday, setReportedVitalsToday] = useState("");
    const [MyOrg, setMyOrg] = useState(props.selectedOrganization != null ? props.selectedOrganization : "");         //props.selectedOrganization;
    const [monitorReadingThresholds, setMonitorReadingThresholds] = useState<any[]>([]);

    //  Spinner Icon location
    const position = { of: '#healthStaffInfo' };

    //  User Status
    const [showProgress, setShowProgress] = useState(false);

    useEffect(() => {
        //  Load UI
        loadUserProfile();
    },[props.selectedOrganization, props.selectedOrganizationID]);


    //  Load User Profile Info
    async function loadUserProfile() {
        setMyOrg(props.selectedOrganization);

        let orgIdToQuery = '';
        
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getUserProfile`, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(async (r: { ok: any; json: () => any; status: { toString: () => string; }; }) => {
            //  Check for Success
            if (r.ok)
            {
                try {
                    const data = await r.json();
                    if (data != null) {
                        
                        //  Check for assigned ID
                        if (props.selectedOrganizationID === '') {
                            orgIdToQuery = data.organizations[0].internalID;
                        } else {
                            orgIdToQuery = props.selectedOrganizationID;
                        }
          
                        if (orgIdToQuery != '') {
                            //  Load Summaries Here
                            loadHealthCenterSummary(orgIdToQuery);

                            //  Load Upcoming Huddles
                            //loadHuddleSummary(data.organizations[0].internalID);
                            
                            //  Load Digital Health Pathways
                            //loadDigitalHealthPathwaySummary(data.organizations[0].internalID);
                            
                            //  Load Health Staff Grid
                            loadHealthStaffSummary(orgIdToQuery);

                            //  Load Teamlets Grid
                            loadTeamletSummary(orgIdToQuery);
                        }
                        else {
                             console.log('Blank ID Selected');
                        }

                    }
                    //console.log(data)
                } catch (err) {
                    console.log("Error in loadUserProfile: " + err);
                }
            }
            else {
                // ToDo:  Handle non-200s
                console.log("Received HTTP status" + r.status.toString());
            }
        });
    }

    //  Load Huddle Summary
    async function loadHuddleSummary(orgId : string) {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getHuddleSummary?orgId=` + orgId, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(async (response: { ok: any; json: () => any; status: { toString: () => string; }; }) => {
            //  Check for Success
            if (response.ok)
            {
                try {
                    const data = await response.json();
                    //console.log(data)

                    if (data != null) {
                        //  Load Huddle Summary List
                        setHuddleSummary(data);
                    }
                } catch (err) {
                    console.log("Error in loadHuddleSummary: " + err);
                }
            }
            else {
                // ToDo:  Handle non-200s
                console.log("Received HTTP status" + response.status.toString());
            }
        });
    }

    //  Load HealthStaff Summary
    async function loadHealthStaffSummary(orgId : string) {
        // Start Spinner
        setShowProgress(true);

        //  Abort controller to close long fetch calls
        const controller = new AbortController();
        const token = sessionStorage.getItem('ACTIVATEtoken');
       
        await fetch(`${config.activateServer}:${config.activatePORT}/getHealthStaffSummary?orgId=` + orgId, {signal: controller.signal, 
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(async (response: { ok: any; json: () => any; status: { toString: () => string; }; }) => {
            //  Check for Success
            if (response.ok)
            {
                try {
                    //  Get the JSON
                    const data = await response.json();
            
                    //  Load Health Staff Summary List
                    setHealthStaffSummary(() => {return data; });
                    setShowProgress(false);
                } catch (err) {
                    console.log("Error in loadHealthStaffSummary: " + err);
                    setShowProgress(false);
                }
            }
            else {
                // ToDo:  Handle non-200s
                console.log("Received HTTP status" + response.status.toString());
                setShowProgress(false);
            }
        }).catch(err =>{
            if (err.name === 'AbortError') {
              //console.log('fetch aborted')
            }
            setShowProgress(false);
          });
          
          setShowProgress(false);
    }

    //  Load HealthCenter Summary
    async function loadHealthCenterSummary(orgId : string) {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        dgVitals.current?.instance.beginCustomLoading();

        await fetch(`${config.activateServer}:${config.activatePORT}/getHealthCenterSummary?orgId=` + orgId, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(res =>{
            if (!res.ok) {
              throw Error('Could not fetch the data for the resource');
              //  Stop Spinner
              dgVitals.current?.instance.endCustomLoading();
            }
            return res.json();
        }).then(data =>{
            try {
     
                //  Load Health Center Summary
                setHealthCenterSummary(data);
                setHealthCenterName(data.name);
                setHealthCenterDescription(data.description);
                setHealthCenterCity(data.streetAddress.cityName);
                setHealthCenterPhoneNumber(data.phoneNumber.number);
                setHealthCenterState(data.streetAddress.stateName);
                setHealthCenterStreet(data.streetAddress.street);
                setHealthCenterZip(data.streetAddress.zipcode);
                //  Check for Primary POC
                if (data.primaryPointOfContact != null) {
                    setHealthCenterPointOfContact(data.primaryPointOfContact.firstName + ' ' + data.primaryPointOfContact.lastName);
                    setHealthCenterPointOfContactEmailAddress(data.primaryPointOfContact.emailAddresses[0] == null ? 'NYA' : data.primaryPointOfContact.emailAddresses[0].address);
                    setHealthCenterPointOfContactPhone(data.primaryPointOfContact.phoneNumbers[0] == null ? 'NYA' : data.primaryPointOfContact.phoneNumbers[0].number);
                }
                // Check if the org is a top organization
                if (data.parentOrganization !== null) {
                    isTopParentOrg = false;
                    setHealthCenterParent(data.parentOrganization.name);
                } else {
                    isTopParentOrg = true;
                }
                setAssignedPatients(data.assignedPatientCount === -1 ? 0 : data.assignedPatientCount);
                setPatientsAdded(data.patientsAddedThisWeekCount === -1 ? 0 : data.patientsAddedThisWeekCount);
                setReportedVitalsToday(data.patientsReportingVitalsTodayCount === -1 ? 0 : data.patientsReportingVitalsTodayCount);

                if (data.monitorReadingThresholds != null) {
                    setMonitorReadingThresholds(() => data.monitorReadingThresholds);
                }

            } catch (err) {
                console.log("Error in loadHealthCenterSummary: " + err);
                //  Stop Spinner
                dgVitals.current?.instance.endCustomLoading();
            }
        })
        .catch(err =>{
            console.log("Error in loadHealthCenterSummary: " + err);
            //  Stop Spinner
            dgVitals.current?.instance.endCustomLoading();
        });

        //  Stop Spinner
        dgVitals.current?.instance.endCustomLoading();
    }

    //  Load Healthcare Team Summary
    async function loadTeamletSummary(orgId : string) {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const _response = await fetch(`${config.activateServer}:${config.activatePORT}/getHealthcareTeamSummary?orgId=` + orgId, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(async (response: { ok: any; json: () => any; status: { toString: () => string; }; }) => {
            //  Check for Success
            if (response.ok)
            {
                try {
                    const data = await response.json();
                    //  Load Healthcare Team Summary
                    setHealthcareTeamLetSummary(() => {return data; });
                } catch (err) {
                    console.log("Error in loadHealthcareTeamSummary: " + err);
                }
            }
            else {
                // ToDo:  Handle non-200s
                console.log("Received HTTP status" + response.status.toString());
            }
        });
    }

    //  Load Digital Health Pathway Summary
    async function loadDigitalHealthPathwaySummary(orgId : string) {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getDigitalHealthPathwaySummary?orgId=` + orgId, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(async (response: { ok: any; json: () => any; status: { toString: () => string; }; }) => {
            //  Check for Success
            if (response.ok)
            {
                try {
                    const data = await response.json();
       
                    //  Load DigitalHealth Pathway Summary
                    setDigitalHealthPathwaySummary(data);
                } catch (err) {
                    console.log("Error in loadDigitalHealthPathwaySummary: " + err);
                }
            }
            else {
                // ToDo:  Handle non-200s
                console.log("Received HTTP status" + response.status.toString());
            }
        });
    }

    //  Build TeamLet Names
    function getTeamletName(e:any) {
        const teams = e.data.healthCareTeams;
    
        if (teams != null && teams.length > 0 ) {
           return (
                teams.map((t:any) => (
                    <span key={t}>
                        <a
                            href="#"
                            title={t.description}
                            id={t.internalID}
                            onClick={e => goToTeamletDetails(e)}>
                                {t.name}
                        </a><br/>
                    </span>
                ))
           );
        } else {
            return (
                <><label className='titlesDisabled'>na</label></>
            );
        }
    }

    //  Concatenates Name for Health Staff Grid
    function getName(e:any) {
        return (
            <div>
                {e.data.firstName + ' ' + e.data.lastName}
            </div>
        );
    }

    //  Assigned Patients
    function getAssignedPatientCount(e:any) {
        return (
            <div>
                <br/>
                <p><a href="#" title={e.data.description} id={e.data.internalID} onClick={e => goToTeamletDetails(e)}>{e.data.assignedPatientCount + ' Patients'}</a></p>
            </div>
        );
    }

    //  Gets Today's Huddles
    function getTodaysHuddles(e:any) {
        //  Set-up link to Huddle Details
        if (e.data != null && e.data.todaysMeetings != null && e.data.todaysMeetings.length > 0) {
            const todaysMeeting = new Date(e.data.todaysMeetings[e.data.todaysMeetings.length-1].time).toLocaleTimeString();
            const todaysMeetingID = e.data.todaysMeetings[e.data.todaysMeetings.length-1].internalID;
            const todaysHuddleID = e.data.todaysMeetings[e.data.todaysMeetings.length-1].huddle.internalID;
            return (
                <ul className="team-list">
                    <p>{renderIcon("calendar","todo")} <Anchor onClick={(e) => { props.setHuddleID(todaysHuddleID); props.setMeetingID(todaysMeetingID); props.switchView("huddledetails"); }} title="Edit Meeting">{todaysMeeting}</Anchor></p>
                </ul>
            );
        } else
        {
            return (
                <ul className="team-list">
                    <br/>
                    <p>No huddle scheduled today</p>
                </ul>
            );
        }
    }
    
    /**
     * @name renderIcon
     * @description Shows the feather icon
     * @param
     * @returns
     */
    function renderIcon(iconName : any, category:string) {
      return (
        <span className={`ui-icon ${category}`}>
          <Icon name={iconName} size="24" />
        </span>
      );
    }

    /**
     * @name goToTeamletDetails
     * @description Goes to Teamlet Details
     * @param
     * @returns
     */
    function goToTeamletDetails(e:any) {
        props.setSelectedTeamletID(e.target.id);
        props.switchView("TeamletDetails");
        e.preventDefault();
    }
    
    /**
     * @name teamletList
     * @description Renders user's assigned Teamlets
     * @param healthcareTeamletSummary
     * @returns Team list HTML
     */
    function teamletList(e: any) {
        const pathways = e.data.digitalHealthPathways?.map((pathway: any, index: number) => {
            return <span key={index}>
                <small>{pathway.name}</small><br/>
            </span>;
        });
        return (
            <ul className="team-list">
                    <li>
                    {renderIcon("users","people")}
                    <p>
                    <a href="#" title={e.data.description} id={e.data.internalID} onClick={e => goToTeamletDetails(e)}>{e.data.name}</a><br/>
                    {e.data.digitalHealthPathways != null ?
                        <span>{pathways}</span>
                    : null
                    }
                    </p>
                </li>
            </ul>
        );
    }
    
    //  Render Vital Readings
    function renderVitalGridReadings(e: any) {
      const _readName = e.data.readingName;
      let _readingTitle = e.data.readingName;

      switch (_readName) {
        case 'BloodGlucose':
          _readingTitle = "Blood Glucose";
          break;
        case 'PulseRate':
          _readingTitle = "Pulse Rate";
          break;
        case 'Systolic':
          _readingTitle = "Systolic Blood Pressure";
          break;
        case 'Diastolic':
          _readingTitle = "Diastolic Blood Pressure";
          break;
        default:
          _readingTitle = _readName;
      }

      return (
        <>
          <p>{_readingTitle}<br />
          <small>{e.data.dataUnits}</small></p>
        </>
      );
    }

    //  Render Low Vital Thresholds
    function renderLowValues(e:any) {
        const low = e.data.dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'LESS_THAN');

        return (
            <>&#8804; {low[0].thresholdValue}</>
        );
    }

    //  Render High Vital Thresholds
    function renderHighValues(e:any) {
        const high = e.data.dataValues.filter((a: { thresholdValueType: string; }) => a.thresholdValueType === 'GREATER_THAN');
        return (
            <>&gt;{high[0].thresholdValue}</>
        );
    }
    
    return (
        <Container fluid>
          <Form>
              <Breadcrumb>
                  <Breadcrumb.Item active>{props.selectedOrganization + " Overview "}</Breadcrumb.Item>
              </Breadcrumb>
              <Row>
                <Col lg={7}>
                  <div className="page-panel">
                        <h2>Teamlets</h2>
                        {healthcareTeamLetSummary.length > 0 ?
                        <>
                        <section>
                            {/* {teamletList(healthcareTeamLetSummary)}*/}
                        </section>
                        <section>
                            <DataGrid id="grid-container"
                                showColumnLines={false} 
                                dataSource={healthcareTeamLetSummary}
                                showRowLines={false}
                                keyExpr="internalID"
                                showBorders={false}
                                allowColumnResizing={true}
                                wordWrapEnabled={false}
                                showColumnHeaders={true}
                                noDataText='No Teamlets found'>
                                    <Scrolling rowRenderingMode='virtual'></Scrolling>
                                    <Paging defaultPageSize={5} defaultPageIndex={0} />
                                    <Selection mode="single" />
                                    <Column dataField="internalID" caption="Id" visible={false} />
                                    <Column width={'50%'} dataField="name" caption="Teamlet" cellRender={e => teamletList(e)} />
                                    <Column width={'25%'} dataField="assignedPatientCount" alignment={'center'} caption="Today's Huddles" cellRender={getTodaysHuddles} />
                                    <Column width={'25%'} dataField="assignedPatientCount2" caption="Total Patients" cellRender={e => getAssignedPatientCount(e)} />
                            </DataGrid>
                        </section>
                        </>
                        : <p>No teamlets for this site</p>} 
                        {/* TODO: Add permission check to see if user can add new teamlets to a site          
                            <Anchor onClick={() => {}} >Add Teamlet</Anchor>
                        */}
                  </div>

                  <div className="page-panel" id="healthStaffInfo">
                     <LoadPanel visible={showProgress} message={"Loading Health Staff"} showPane={true} position={position} showIndicator={true}></LoadPanel>
         
                      <h2>Health Staff</h2>
                      <DataGrid id="grid-container"
                            ref={dgHealthStaff}
                            showColumnLines={false} 
                            showRowLines={false}
                            keyExpr="internalID"
                            showBorders={false}
                            focusStateEnabled={true}
                            allowColumnResizing={true}
                            wordWrapEnabled={true}
                            dataSource={healthStaffSummary}
                            noDataText='No Health Staff found'>
                                <Scrolling rowRenderingMode='virtual'></Scrolling>
                                <Paging defaultPageSize={5} defaultPageIndex={0} />
                                <Selection mode="single" />
                                <Column dataField="lastName" caption="Name" cellRender={getName} defaultSortOrder="asc"/>
                                <Column dataField="userRole.name" caption="Role" />
                                <Column dataField="teamlet" width={"30%"} caption="Teamlets" cellRender={e => getTeamletName(e)} />
                      </DataGrid>
                    </div>
                </Col>
                <Col lg={5}>
                  <div className="page-panel">
                    <section>
                      <h2 className="panel-section">Site Address</h2>
                      <p>
                        <span className="site-name">{healthCenterName}</span><br/>
                        {healthCenterStreet}<br/>
                        {healthCenterCity}, {healthCenterState} {healthCenterZip}<br/>
                        {healthCenterPhoneNumber}
                      </p>
                    </section>
                    <section>
                      <h2 className="panel-section">Point of Contact</h2>
                      <div className="contacts">
                        <p>
                          {healthCenterPointOfContact}<br/>
                          <a href={`mailto:${healthCenterPointOfContactEmailAddress}`}>{healthCenterPointOfContactEmailAddress}</a><br/>
                          {healthCenterPointOfContactPhone}
                        </p>
                      </div>
                    </section>
                    {!isTopParentOrg ?
                    <section>
                      <h2 className="panel-section">Parent Organization</h2>
                      <div className="site-name">{healthCenterParent}</div>
                      <p>{healthCenterDescription}</p>
                    </section>
                    : <></>}
                    
                    </div>
                    
                    <div className="page-panel">
                        <div className="panel-header">
                            <h2>Vital Data Thresholds</h2>
                            {/* TODO: Add permission check to let user edit thresholds
                            <Anchor href="#" onClick={(e) => props.switchView("editvitalthresholds");e.preventDefault(); }>
                            Edit
                            </Anchor>
                            */}
                        </div>
                      <Form.Label className="font-face-activate-caption">
                        Organization Defaults
                      </Form.Label>
                      <DataGrid id="grid-container"
                          ref={dgVitals}   
                          showColumnLines={false} 
                          showRowLines={false}
                          dataSource={monitorReadingThresholds}
                          showBorders={false}
                          focusStateEnabled={true}
                          allowColumnResizing={true} showColumnHeaders={true}
                          noDataText='No Vital Data Thresholds stored'>
                              <Paging defaultPageSize={4} defaultPageIndex={0} />
                              <Selection mode="single" />
                              <Column dataField="readingName" cellRender={renderVitalGridReadings} caption="Vital Reading" width={'70%'} defaultSortOrder="asc"/>
                              <Column dataField="dataType" width={'15%'} caption="Low" cellRender={renderLowValues} />
                              <Column dataField="dataUnits" width={'15%'} caption="High" cellRender={renderHighValues} />
                      </DataGrid>
                    </div>
                    <div className="page-panel">
                        <OrgHuddleSchedule 
                            selectedOrganizationID={props.selectedOrganizationID}
                            permissions={props.permissions}
                            switchView={props.switchView}
                            teamletID={''}
                            teamletName={props.teamletName}
                            setHuddleID={props.setHuddleID}
                            setMeetingID={props.setMeetingID}
                            setSelectedTeamletID={props.setSelectedTeamletID}/>
                    </div>
                </Col>
            </Row>
            </Form>
            </Container>
    );
}

export default HealthProviderDashboard;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars ,faSignOutAlt, faUser, faPeopleGroup, faClipboardList, faCalendar, faMessage, faVideo, faHeartPulse,
  faMicrochip, faSliders, faGear, faWrench, faChartPie, faTrashCan, faPenToSquare, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

library.add( faBars, faSignOutAlt, faUser, faPeopleGroup, faClipboardList, faCalendar, faMessage, faVideo, faHeartPulse,
  faMicrochip, faSliders, faGear, faWrench, faChartPie, faTrashCan, faPenToSquare, faEye, faEyeSlash );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
    
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

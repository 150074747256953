/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import PermissionService from '../../../Services/PermissionService';
const config = require("../../../config.json");


let noMeetings = true;

function PatientHuddleQuickAdd(props: 
  { 
    showing: boolean,
    setShowQuickModal: (toggle: boolean, editType: string) => void,
    patientName: string,
    patientID: string
    editMode: string,
    huddleDetails: any[],
    teamletSummary: any[],
    originalMeetingID?: string,
    teamletID?: string,
    teamletName?: string,
    meetingID?: string,
    meetingTime?: string,
    reason?: string,
    notes?: string,
    taggedBy?: string,
    notesAuthor?: string,
    callAddComplete: () => void,
    deleteOccured: boolean,
    permissions: PermissionService
  }) {
  const [validated, setValidated] = useState(false);
  const [reason, setReason] = useState('');
  const [meetingID, setMeetingID] = useState('');
  const [originalMeetingID, setOriginalMeetingID] = useState('');
  const [huddleData, setHuddleData] = useState<any[]>([]);
  const [notes, setNotes] = useState('');
  const [taggedBy, setTaggedBy] = useState('');
  const [notesAuthor, setNoteAuthor] = useState('');
  const [teamletOrHuddleChange, setTeamletOrHuddleChange] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');

  useEffect(() => {
    // Fetch the default teamlets
    // If in the add then we set the first teamlet as the default value
    if (props.teamletSummary.length > 0 && props.editMode === 'add') {
      if (props.teamletSummary[0].healthcareTeam != undefined){
        selectNewTeamlet(props.teamletSummary[0].healthcareTeam.internalID);
      }
    } else {
      if (props.teamletID && props.meetingID) {
        selectNewTeamlet(props.teamletID);
        setMeetingID(props.meetingID);
      }
    }
  }, [props.teamletSummary]);

  useEffect(() => {
    setReason(props.reason || '');
  }, [props.reason]);

  useEffect(() => {
    if (props.teamletID && props.teamletID?.length > 0) {
      selectNewTeamlet(props.teamletID);
    }
  }, [props.teamletID]);

  useEffect(() => {
    if (props.meetingID && props.meetingID?.length > 0) {
      setMeetingID(props.meetingID);
      setOriginalMeetingID(props.meetingID);
    }
  }, [props.meetingID]);

  // This use effect is set to fire when a meeting is deleted
  useEffect(() => {
    if (props.teamletSummary.length > 0) {
      selectNewTeamlet(props.teamletSummary[0].healthcareTeam.internalID);
    }
  }, [props.deleteOccured]);
  

  function displayTeamlets() {
    let isMemberOnlyList = false;
    //   Check if membership is required
    if (props.permissions.checkPermissionSetting("REQUIRES_MEMBERSHIP", "READ") === true){
      isMemberOnlyList = true;
    } else {
      isMemberOnlyList = false;
    }

    const displayTeamlets = [];
    for (let i = 0; i < props.teamletSummary.length; i++) {

      if (isMemberOnlyList){
        //  Check for null healthcare Team
        if (props.teamletSummary[i].healthcareTeam != null && props.teamletSummary[i].healthcareTeam.members.some((member: any) => member.internalID === props.permissions.myInternalID)) {
          //  Add to Teamlet List
          displayTeamlets.push(
            <option
                key={i}
                value={props.teamletSummary[i].healthcareTeam.internalID}
                selected={(props.editMode === 'edit' && props.teamletSummary[i].healthcareTeam.internalID === props.teamletID)}
              >
                {`${props.teamletSummary[i].healthcareTeam.name}`}
            </option>
          );
        }
      } else {
          //  Check for null healthcare Team
          if (props.teamletSummary[i].healthcareTeam != null) {
            //  Add to Teamlet List
            displayTeamlets.push(
              <option
                key={i}
                value={props.teamletSummary[i].healthcareTeam.internalID}
                selected={(props.editMode === 'edit' && props.teamletSummary[i].healthcareTeam.internalID === props.teamletID)}
              >
                {`${props.teamletSummary[i].healthcareTeam.name}`}
              </option>
            );
          }
      }
    }
    return displayTeamlets;
  }

  async function selectNewTeamlet(teamletID: string) {
    // On dropdown change and load
    setTeamletOrHuddleChange(true);
    const token = sessionStorage.getItem('ACTIVATEtoken');
    const response = await fetch(`${config.activateServer}:${config.activatePORT}/getHuddlesForTeamlet`,
      {
        method: "POST",
        body: JSON.stringify({ token: token, teamletID }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
      }
    );
    try {

      const data = await response.json();
      let setID = false;
      setHuddleData(data);
      // If we dont have a meeting ID we want to set the default one
      if (props.editMode === 'add' && data[0] != undefined && data[0].meetings) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].meetings.length > 0) {
            for (let j = 0; j < data[i].meetings.length; j++) {
              // Only show future huddles
              const meetingDate = new Date(data[i].meetings[j].meetingTime);
              const today = new Date();
              if (today < meetingDate && !data[i].meetings[j].patients.some((patients: any) => patients.patient === props.patientID) && setID === false) {
                setMeetingID(data[i].meetings[j].internalID);
                setID = true;
                // setOriginalMeetingID(data[i].meetings[j].internalID);
                break;
              }
            }
          }
        }
      }
    } catch (err) {
      setModalErrorMessage(`An error occurred: ${err}`);
      console.log("ERROR " + err);
    }
  }

  function displayHuddles() {
    const displayHuddlesArray = [];
    if (props.editMode === 'add') {
      for (let i = 0; i < huddleData.length; i++) {
        // Teamlet could possibly have no meetings
        if (huddleData[i].meetings.length > 0) {
          noMeetings = true;
          // Now we lead to loop through each of the attached meetings
          for (let j = 0; j < huddleData[i].meetings.length; j++) {
            // Only show future huddles
            const newDate = new Date(huddleData[i].meetings[j].meetingTime);
            const today = new Date();
            const displayDate = `${newDate.toLocaleDateString()} ${newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit'}).toLowerCase()}`;
            // console.log('COMPARING ' + JSON.stringify(props.huddleDetails));
            // console.log('EVALUATING ' + (!props.huddleDetails.some((detail: any) => detail.name === huddleData[i].name)) + ' for ' + huddleData[i].name);
            // console.log('EVALUATING ' + !huddleData[i].meetings[j].patients.some((patients: any) => patients.patient === props.patientID) + ' for ' + huddleData[i].name);
            if (!huddleData[i].meetings[j].patients.some((patients: any) => patients.patient === props.patientID) && today < newDate) {
              displayHuddlesArray.push(
                <option
                    key={i}
                    value={huddleData[i].meetings[j].internalID}
                    selected={(huddleData[i].meetings[j].internalID === props.meetingID) ? true : false}
                  >
                    {`${huddleData[i].name} ${displayDate}`}
                </option>
              );
            }
          }
        }
      }
      // Check if no meetings were assigned
      if (displayHuddlesArray.length === 0) {
        noMeetings = true;
        return ([
          <option
              key={Math.random()}
              value="Novalue"
            >
              This Teamlet has no associated meetings.  Please assign a meeting or select another teamlet.
          </option>
        ]);
      } else {
        noMeetings = false;
      }
    } else {
      for (let i = 0; i < huddleData.length; i++) {
        // Check if meetings are empty 
        if (huddleData[i].meetings.length > 0) {
          noMeetings = true;
          for (let j = 0; j < huddleData[i].meetings.length; j++) {
            // Only show future huddles
            const newDate = new Date(huddleData[i].meetings[j].meetingTime);
            const today = new Date();
            const displayDate = `${newDate.toLocaleDateString()} ${newDate.toLocaleTimeString('en-US')}`;

            if (today < newDate) {
              displayHuddlesArray.push(
                <option
                    key={i}
                    value={huddleData[i].meetings[j].internalID}
                    selected={(props.editMode === 'edit' && huddleData[i].meetings[j].internalID === props.meetingID) ? true : false}
                  >
                    {`${huddleData[i].name} ${displayDate}`}
                </option>
              );
            }
          }
        }
      }
      // Check if no meetings were assigned
      if (displayHuddlesArray.length === 0) {
        noMeetings = true;
        return ([
          <option
              key={Math.random()}
              value="Novalue"
            >
              This Teamlet has no associated meetings.  Please assign a meeting or select another teamlet.
          </option>
        ]);
      } else {
        noMeetings = false;
      }
    }
    return displayHuddlesArray;
  }

  function selectNewHuddle(e: any) {
    setMeetingID(e.target.value);
    setTeamletOrHuddleChange(true);
  }

  function handleSubmit(event: any) {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    } else {
      setValidated(true);
      props.editMode === "add" ? addPatient() : updatePatient();
    }
  }

  async function addPatient() {
    const token = sessionStorage.getItem('ACTIVATEtoken');
    await fetch(`${config.activateServer}:${config.activatePORT}/addPatientToMeeting`, {
      method: 'POST',
      body: JSON.stringify({
        token: token,
        "patientName": props.patientName,
        "criteria": reason,
        "discussed": false,
        "notes": "",
        "patientID": props.patientID,
        "meetingID": meetingID,
        "taggedBy": "",
        "notesAuthor": ""
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      mode: 'cors'
    }).then(async (r: Response) => {

      //  Check for Success
      if (r.ok) {
        try {
          props.callAddComplete();
          props.setShowQuickModal(false, 'add');
          setMeetingID('');
          selectNewTeamlet(props.teamletSummary[0].healthcareTeam.internalID);
          setValidated(false);
        } catch (err) {
          setModalErrorMessage(`An error occurred ${err}`);
        }
      }
      else {
        // Handle non-200s
        setModalErrorMessage("Received HTTP status: " + r.status.toString());
      }
    });
  }

  async function updatePatient() {
    const token = sessionStorage.getItem('ACTIVATEtoken');
    // Check if the teamlet or huddle was change
    // If not then we can just update the huddle
    if (originalMeetingID !== meetingID) {
      // First unassign from the from the meeting
      await fetch(`${config.activateServer}:${config.activatePORT}/removePatientFromHuddle`, {
        method: 'POST',
        body: JSON.stringify({
          token: token,
          internalID: props.originalMeetingID
        }),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        mode: 'cors'
      }).then(response => {
        if (response.ok) {
          try {
            setModalErrorMessage('');
          } catch (err) {
            setModalErrorMessage(`An error occurred: ${err}`);
          }
        } else {
          //  Error returned from data service, display to user
          response.json().then((data: any) => {
            console.log('Error status:' + response.status);
            setModalErrorMessage('Error status:' + response.status);
        });
        }
      });
      // Implement assign call

      await fetch(`${config.activateServer}:${config.activatePORT}/addPatientToMeeting`, {
        method: 'POST',
        body: JSON.stringify({
          token: token,
          "patientName": props.patientName,
          "criteria": reason,
          "discussed": false,
          "notes": props.notes ? props.notes : '',
          "patientID": props.patientID,
          "meetingID": meetingID,
          "taggedBy": props.taggedBy ? props.taggedBy : '',
          "notesAuthor": props.notesAuthor ? props.notesAuthor : ''
        }),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        mode: 'cors'
      }).then(async (r: Response) => {

        //  Check for Success
        if (r.ok) {
          try {
            setModalErrorMessage('');
            props.callAddComplete();
            props.setShowQuickModal(false, 'add');
            setValidated(false);
          } catch (err) {
            setModalErrorMessage(`An error has occurred: ${err}`);
          }
        }
        else {
          // Handle non-200s
          console.log(`ERR ${JSON.stringify(r)}`);
          console.log("Received HTTP status" + r.status.toString());
          setModalErrorMessage("Received HTTP status: " + r.status.toString());
        }
      });
    // Run just an update on the huddle
    } else {
      await fetch(`${config.activateServer}:${config.activatePORT}/updatePatientMeeting`, {
        method: 'POST',
        body: JSON.stringify({
          token: token,
          "internalID": props.originalMeetingID,
          "patientName": props.patientName,
          "criteria": reason,
          "discussed": false,
          "notes": props.notes ? props.notes : '',
          "patient": props.patientID,
          "meeting": meetingID,
          "taggedBy": props.taggedBy ? props.taggedBy : '',
          "notesAuthor": props.notesAuthor ? props.notesAuthor : ''
        }),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        mode: 'cors'
      }).then(async (r: Response) => {

        //  Check for Success
        if (r.ok) {
          try {
            setModalErrorMessage('');
            props.callAddComplete();
            props.setShowQuickModal(false, 'edit');
            setValidated(false);
          } catch (err) {
            setModalErrorMessage(`An error occurred: ${err}`);
          }
        }
        else {
          // Handle non-200s
          console.log(`ERR ${JSON.stringify(r)}`);
          setModalErrorMessage("Received HTTP status: " + r.status.toString());
        }
      });
    }
  }

  function determineDefaultTeamlet() {
    if (props.editMode === 'edit') {
      return props.teamletID;
    } else {
      if (props.teamletSummary.length > 0) {
        if (props.teamletSummary[0].healthcareTeam === undefined){
          return '';
        } else {
          return props.teamletSummary[0].healthcareTeam.internalID;
        }
      }
    }
  }

  return (
    <Modal
      size="lg"
      dialogClassName="modal-20w"
      show={props.showing}
      aria-labelledby="modal-header"
    >

      <Modal.Header className="people" id="modal-header">
        {props.editMode === "add" ? <h2 >Add To Huddle</h2> :
          <h2>Edit Patient Huddle</h2>}
      </Modal.Header>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>

      <Modal.Body className="modal-body">
          <Row>
            {props.editMode === "add" ?
              <label>Add {props.patientName} to a Huddle:</label>
              :
              <label>Update the scheduled huddle for {props.patientName}.</label>
            }
          </Row>
          <br/>
          <Row>
            <Col md={6}>
              <Form.Label>Teamlet<span className="error-text"> *</span></Form.Label>
              <Form.Select
                aria-label="patient dropdown"
                defaultValue={determineDefaultTeamlet()}
                onChange={(e: any) => { selectNewTeamlet(e.target.value); }}>
                {displayTeamlets()}
              </Form.Select>
            </Col>
            <Col md={6}>
                <><Form.Label>Scheduled Huddle<span className="error-text"> *</span></Form.Label>
                <Form.Select aria-label="patient dropdown" onChange={(e: any) => selectNewHuddle(e)}>
                  {displayHuddles()}
                </Form.Select>
                </>
            </Col>
          </Row>
          <Row>
          <Col md={12}>
            <br/>
            <Form.Group
              className="mb-3"
              controlId="editGroup">
              <Form.Label>{props.editMode === "add" ?
                "What is the reason the patient is being added" :
                "What is the reason the patient is being updated"}?
                <span className="error-text"> *</span></Form.Label>
              <Form.Control as="textarea" rows={4}
                required maxLength={255}
                defaultValue={props.editMode === 'edit' ? props.reason : ''}
                onChange={((e) => { setReason(e.target.value); })}
              />
              <Form.Control.Feedback type="invalid">
                Reason is required
              </Form.Control.Feedback>
            </Form.Group>
            </Col>
          </Row>

          {modalErrorMessage.length > 0 ?
            <p className="validation-error">{modalErrorMessage}</p>
          :
            <></>
          }
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='people submit'
          type="submit"
          variant="primary"
          disabled={noMeetings}
          id="submitButton">
          Save
        </Button>
        <Button
          className='people cancel'
          id="cancelButton"
          variant="secondary"
          type="button"
          onClick={() => { props.setShowQuickModal(false, 'add'); setValidated(false); setReason(''); setTeamletOrHuddleChange(false); setModalErrorMessage('');}}>
          Cancel
        </Button>

      </Modal.Footer>
    </Form>
    </Modal>
  );
}

export default PatientHuddleQuickAdd;
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import ModalAddPatientToHuddle from './ModalAddPatientToHuddle';
import ModalRemovePatientFromMeeting from './ModalRemovePatientFromMeeting';
import { Icon } from '@ailibs/feather-react-ts';
import PermissionService from '../../../Services/PermissionService';
import { fetchHuddleDetails } from '../../../Services/HuddleServices';
import { Anchor, Button, Container, Form, Modal } from 'react-bootstrap';
import DataGrid, { Column, GroupPanel, Pager, Paging, Selection } from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';

const config = require("../../../config.json");

function AddedPatients(props:
    {
        permissions: PermissionService;
        switchView: (newView: string) => void
        huddleID: string;
        meetingID: string;
        teamletID: string;
        setHuddleID: (huddleID: string) => void;
        setMeetingID: (meetingID: string) => void;
        setSelectedPatientId: (patientID: string) => void;
        isMemberOfTeamLet: boolean
    }) {


    const [huddleName, setHuddleName] = useState("");
    const [huddleDescription, setHuddleDescription] = useState('');
    const [huddleData, setHuddleData] = useState<any>({});
    const [huddleIndex, setHuddleIndex] = useState(-1);

    const [meetingDate, setMeetingDate] = useState("");
    const [meetingDay, setMeetingDay] = useState("");
    const [meetingTime, setMeetingTime] = useState("");
    const [meetingAMPM, setMeetingAMPM] = useState("");

    const [meetingPatientID, setMeetingPatientID] = useState("");

    const [patientCriteria, setPatientCriteria] = useState("");
    const [originalPatientData, setOriginalPatientData] = useState('');
    const [patientData, setPatientData] = useState<any[]>([]);
    const [notes, setNotes] = useState("");
    const [notesAuthor, setNotesAuthor] = useState("");
    const [taggedBy, setTaggedBy] = useState("");

    const [patientInternalID, setPatientInternalID] = useState("");
    const [patientName, setPatientName] = useState("");

    const [showPatient, setShowPatient] = useState(false);
    const [modalEditType, setModalEditType] = useState("");
    const [showRemovePatientModal, setShowRemovePatientModal] = useState(false);

    const [discussType, setDiscussType] = useState("view");
    const [relativeTime, setRelativeTime] = useState('');
    const [healthcareTeam, setHealthcareTeam] = useState('');
    const [teamletHuddleArray, setTeamletHuddleArray] = useState<any[]>([]);

    const [huddleTime, setHuddleTime] = useState('');
    const [enablePrevButton, setEnablePrevButton] = useState(true);
    const [enableNextButton, setEnableNextButton] = useState(true);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorServerMessage, setErrorServerMessage] = useState("");
    const [errorUserMessage, setErrorUserMessage] = useState("");
    const [teamletTime, setTeamletTime] = useState('');

    // Used to let the quick add modal know a delete occurred
    const [deleteOccurred, setDeleteOccured] = useState(false);

    //  Spinner Icon location
    const position = { of: '#huddleScheduleGrid' };
    const [showProgress, setShowProgress] = useState(false);

    const [canAdd, setCanAdd] = useState(true);
    const [availablePatients, setAvailablePatients] = useState<any[]>([]);
    const fetchHuddle: any = async () => await fetchHuddleDetails(props.huddleID).then(data => {
        setHuddleData(data);
        setHuddleDescription(data.description);
        setHealthcareTeam(data.healthcareTeam);
        setHuddleName(data.name);
        setHuddleTime(data.meetings[0].huddle);
        setOriginalPatientData(JSON.stringify(data.meetings[0].patients));
        setPatientData(data.meetings[0].patients);
        const tempRelativeTime = calcRelativeTime(data.meetings[0].meetingTime);
        setRelativeTime(tempRelativeTime);
        setMeetingDate(formatDate(data.meetings[0].meetingTime));
        const meetingTime = new Date(data.meetings[0].meetingTime).toLocaleTimeString().toLowerCase();
        const formattedDate = new Date(data.meetings[0].meetingTime);
        setTeamletTime(`${formattedDate.getMonth() + 1}/${formattedDate.getDate()}/${formattedDate.getFullYear()} @ ${meetingTime.slice(0, meetingTime.length - 6)} ${meetingTime.substring(meetingTime.length - 2, meetingTime.length)}`);
        const displayMeetingTime = `${meetingTime.slice(0, meetingTime.length - 6)} ${meetingTime.substring(meetingTime.length - 2, meetingTime.length)}`;
        setMeetingTime(displayMeetingTime);
        props.setMeetingID(data.meetings[0].internalID);
    }).catch((error: any) => {
        setErrorMessageTitle("Error Loading Patient Data");
        setErrorMessage("An error occurred loading patient data for the meeting.");
        setErrorServerMessage("");
        setErrorUserMessage("");
        showErrorMessage(true);
    });

    const fetchTeamletHuddles = async () => {
        //  Abort controller to close long fetch calls
        const huddleScheduleController = new AbortController();

        const token = sessionStorage.getItem('ACTIVATEtoken');
        try {
            setShowProgress(false);

            await fetch(`${config.activateServer}:${config.activatePORT}/getHuddlesForTeamlet`, {
                method: 'POST',
                body: JSON.stringify({ token: token, teamletID: props.teamletID }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode: 'cors'
            }).then(res => {
                if (!res.ok) {
                    throw Error('Could not fetch the data for the resource');
                }
                return res.json();
            }).then(huddles => {
                const huddlesWithMeetings = huddles.filter((i: any) => { return (i.meetings.length > 0); });
                const newArray = huddlesWithMeetings.sort(function(a:any, b:any) {
                    // @ts-ignore
                    return (new Date(a.meetings[0].meetingTime) - new Date(b.meetings[0].meetingTime));
                });

                setTeamletHuddleArray(newArray);
            }).catch(err => {
                if (err.name === 'AbortError') {
                    //console.log('fetch aborted');
                } else {
                    // TODO fill in
                }
            });

            setShowProgress(false);
        } catch (err) {
            setShowErrorModal(true);
            huddleScheduleController.abort();
            setShowProgress(false);
        }
    };

    useEffect(() => {
        if (props.teamletID !=='') {
            checkPatientsAvailable();
        }
        fetchTeamletHuddles();
    }, [patientData, props.teamletID]);

    useEffect(() => {
        // fetching the entire huddle to get huddle description and teamlet id
        if (props.huddleID !== "" && props.huddleID !== undefined) {
            fetchHuddle();
        }
    }, [props.huddleID, props.meetingID]);

    /**
    * @name formatDate
    * @description Formats date in mm/dd/yyy format
    * @param string 
    * @returns 
    */
    function formatDate(dateStringToFormat: string) {
        let date, month;
        const inputDate = new Date(dateStringToFormat);
        date = inputDate.getDate();
        month = inputDate.getMonth() + 1;
        const year = inputDate.getFullYear();

        date = date.toString().padStart(2, '0');
        month = month.toString().padStart(2, '0');

        return (`${month}/${date}/${year}`);
    }

    async function checkPatientsAvailable() {
        const token = sessionStorage.getItem('ACTIVATEtoken');

        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getPatientsByTeamletID`,
            {
                method: "POST",
                body: JSON.stringify({ token: token, teamletID: props.teamletID }),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                mode: "cors",
            }
        );
        try {
            const data = await response.json();
            const currentAvailablePatients = data;
            if (currentAvailablePatients.length === patientData.length) {
                setCanAdd(false);
            } else {
                setCanAdd(true);
            }

            for (let j = 0; j < patientData.length; j++) {
                for (let k = 0; k < currentAvailablePatients.length; k++) {
                    // console.log('COMPARING ' + patientData[j].patient + ' ' + currentAvailablePatients[k].internalID);
                    if (patientData[j].patient === currentAvailablePatients[k].internalID) {
                        currentAvailablePatients.splice(k, 1);
                    }
                }
            }
            setAvailablePatients(currentAvailablePatients);

        } catch (err) {
            setShowErrorModal(true);
        }
    }

    /**
    * @name setNavigationButtons
    * @description formats meeting date and time and enables appropriate next/prev buttons
    * @param
    * @returns 
    */
    function setNavigationButtons(fwdbck: string) {

        const temphuddleIndex = teamletHuddleArray.findIndex((i: any) => i.internalID === props.huddleID);
        if (temphuddleIndex <= -1) { return; }
        if (fwdbck === "prev") {
            if (temphuddleIndex === 0) {
                setEnablePrevButton(false);
                setEnableNextButton(true);
                return;
            } else {
                props.setHuddleID(teamletHuddleArray[temphuddleIndex - 1].internalID);
                props.setMeetingID(teamletHuddleArray[temphuddleIndex - 1].meetings[0].internalID);
                setHuddleIndex(temphuddleIndex - 1);
                // if temphuddleIndex= 1, set it back to 0 to get the first huddle in the array and turn off "Prev"
                if (temphuddleIndex === 1) {
                    setEnablePrevButton(false);
                    setEnableNextButton(true);
                    return;
                } else {
                    setEnablePrevButton(true);
                    setEnableNextButton(true);
                }
            }
        }
        if (fwdbck === "next") {
            if (teamletHuddleArray === undefined || temphuddleIndex === teamletHuddleArray.length-1) {
                setEnableNextButton(false);
                setEnablePrevButton(true);
                return;
            } else {
                props.setHuddleID(teamletHuddleArray[temphuddleIndex+1].internalID);
                setHuddleIndex(temphuddleIndex+1);
                // if the next entry in the teamlet huddles array is the last one, disable "next"
                if (teamletHuddleArray === undefined || temphuddleIndex+1 === teamletHuddleArray.length-1) {
                    setEnableNextButton(false);
                    setEnablePrevButton(true);
                    return;
                } else {
                    setEnablePrevButton(true);
                    setEnableNextButton(true);
                }
            }
        }

    }

    /**
    * @name viewPatientDetails
    * @description View Patient Information from hyperlink onClick handler
    * @param
    * @returns {json}
    */
    function viewPatientDetails(data: any) {
        const patientID = data.patient;

        //  Check for found Patient
        if (data != null) {
            props.setSelectedPatientId(patientID);
            props.switchView("patientInformation");
        }
    }

    /**
    * @name calcRelativeTime
    * @description check if the meeting time falls within the editable range (midnight on the day of the meeting to 24 hours after the meeting start time)
    * @param   meeting date/time
    * @returns string
    */
    function calcRelativeTime(meetingTime: string) {
        const today = new Date();
        const setToMidnight = new Date(meetingTime);
        // editing the meeting is possible starting at midnight of the day of the meeting
        setToMidnight.setHours(0);
        setToMidnight.setMinutes(0);
        setToMidnight.setSeconds(0);
        const huddleWindowStart = setToMidnight;
        // number of milliseconds in 24 hours is added onto the meeting time to get the end of the window
        // editing the meeting is possible for 24 hours after the meeting starts

        const huddleWindowEnd = new Date(new Date(meetingTime).setDate(new Date(meetingTime).getDate() + 1));

        if (huddleWindowStart > today && huddleWindowEnd > today) {
            setDiscussType('view');
            return ('future');
        }
        if (huddleWindowEnd < today && huddleWindowEnd < today) {
            setDiscussType('view');
            return ('past');
        }
        return ('current');
    }

    /**
    * @name renderPatientLink
    * @description renders a link html element
    * @param
    * @returns {json}
    */
    function renderPatientLink(data: any) {
        return (<Anchor onClick={() => viewPatientDetails(data.data)}>{data.value}</Anchor>);
    }

    const renderNameHeader = (data: any) => {
        return <p>{data.column.caption}</p>;
    };

    const renderColumnHeader = (data: any) => {
        return <p>{data.column.caption}</p>;
    };


    const renderActionButtons = (data: any) => {
        return (<p>
            <Anchor onClick={(e) => editClick(data)} hidden={!canEditAddedPatients()} ><Icon name="edit" size="18" className="action-icon " /></Anchor>
            <Anchor onClick={(e) => trashClick(data)} hidden={!canDeleteAddedPatients()} ><Icon name="trash-2" size="18" className="action-icon" /></Anchor>
        </p>);
    };

    const renderReasonNotes = (data: any) => {

        return (
            relativeTime === "current" && discussType === "discuss" ?
                <div>
                    <p>{data.row.data.criteria}</p>
                    <Form.Control as="textarea" rows={1}
                        defaultValue={data.row.data.notes}
                        maxLength={255}
                        onChange={(e) => { updateNotes(e, data.row.data.patient); }}
                    />
                </div>
                : data.row.data.notes > '' ?
                    <p>{data.row.data.criteria}<br /><span style={{ fontWeight: 'bold' }}> Notes: </span>
                    {data.row.data.notes}</p> : <p>{data.row.data.criteria}</p>
        );
    };

    const renderCheck = (data: any) => {
        return (data.data.discussed === true ?
            <Icon size="18" name="check" className="rpm"></Icon> : null
        );
    };

    const renderDiscussCheckbox = (data: any) => {

        return (<div className="d-flex form-group">
            <br /><p>
                {/*<input type="checkbox" id="discussed" name="discussed" checked={data.data.discussed} onChange={(e) => { updateDiscussed(e, data.data.patient) }} />*/}
                <Form.Check style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    key={data.data.patient}
                    required={false}
                    name={"discussed"}
                    id={data.patient}
                    defaultChecked={data.data.discussed}
                    type="checkbox"
                    onChange={(e) => { updateDiscussed(e, data.data.patient); }}
                />
            </p>
        </div>);
    };

    /**
    * @name editClick
    * @description sets up the edit patient modal
    * @param
    * @returns 
    */
    function editClick(e: any) {
        //e.preventDefault();
        //  Check for row Object

        if (e.row != null) {
            setModalEditType("edit");
            setShowPatient(true);
            setMeetingPatientID(e.row.data.internalID);
            setPatientInternalID(e.row.data.patient);
            setPatientName(e.row.data.patientName);
            setPatientCriteria(e.row.data.criteria);
            setNotes(e.row.data.notes);
            setNotesAuthor(e.row.data.notesAuthor);
            setTaggedBy(e.row.data.taggedBy);
        }
    }

    /**
    * @name trashClick
    * @description on clicking the trash icon, sets up the delete modal
    * @param
    * @returns 
    */
    function trashClick(e: any) {

        if (e.row != null) {
            console.log('USING ID FOR DELETE ' + e.row.data.internalID);
            setShowRemovePatientModal(true);
            setPatientInternalID(e.row.data.patient);
            setMeetingPatientID(e.row.data.internalID);
            setPatientName(e.row.data.patientName);
            props.setMeetingID(e.row.data.meeting);
            setPatientCriteria(e.row.data.criteria);

        }
    }

    function saveNotes(e: any) {
        //console.dir(huddleData.meetings)
        const thismeetingIndex = huddleData.meetings.findIndex((j: any) => props.meetingID === j.internalID);
        if (thismeetingIndex === -1) { console.log('error finding this meeting in the huddle'); return; }
        const thismeeting = huddleData.meetings[thismeetingIndex];

        patientData.forEach((k: any) => {
            if (k.modified === true) {
                const dataToModify = {
                    "internalID": k.internalID,
                    "patientName": k.patientName,
                    "criteria": k.criteria,
                    "discussed": k.discussed,
                    "notes": k.notes,
                    "patient": k.patient,
                    "meeting": k.meeting,
                    "taggedBy": k.taggedBy,
                    "notesAuthor": k.notesAuthor
                };
                // put to /rest/huddle/meeting/patient
                dbUpdateNotesDiscussed(dataToModify);
            }
            setDiscussType('view');
        });
    }

    async function getCurrentMeeting(meetingID: any) {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(
            `${config.activateServer}:${config.activatePORT}/getMeeting`,
            {
                method: "POST",
                body: JSON.stringify({ token: token, huddleID: meetingID }),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                mode: "cors",
            }
        );
        try {
            const data = await response.json();
            setPatientData(data.patients);
        } catch (err) {
            console.log("ERROR " + err);
        }
    }


    function updateNotes(e: any, id: string) {
        const thisPatientNotes = patientData.filter((j: any) => { return (j.patient === id); })[0];
        thisPatientNotes.notes = e.target.value;
        thisPatientNotes.modified = true;
        setPatientData(patientData);
    }

    function updateDiscussed(e: any, id: string) {
        const patient = patientData.filter((j: any) => { return (j.patient === id); })[0];
        patient.discussed = e.target.checked;
        patient.modified = true;
    }

    /**
    * @name showErrorMessage
    * @description  Error Pop-up
    * @param  
    * @returns 
    */
    function showErrorMessage(toggle: boolean) {
        setShowErrorModal(toggle);
    }

    /**
    * @name showWarningModal
    * @description Shows/Hides warning pop-up
    * @param  
    * @returns 
    */
    function showWarningModal(toggle: boolean) {
        setShowErrorModal(toggle);
    }

//  PERMISSION HOOKS
//  Check if User can delete Added Patients
function canDeleteAddedPatients() {
    //  Check if requires membership
    if (props.permissions.checkPermissionSetting("REQUIRES_MEMBERSHIP", "READ") === true){
        //  Check for membership
        if (props.isMemberOfTeamLet === true) {
            return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "ADDED_PATIENTS", "DELETE");
        } else {
        return false;
        }
    } else {
        return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "ADDED_PATIENTS", "DELETE");
    }
}
  
//  Check if User can update Added Patients
function canEditAddedPatients() {
    //  Check if requires membership
    if (props.permissions.checkPermissionSetting("REQUIRES_MEMBERSHIP", "READ") === true){
        //  Check for membership
        if (props.isMemberOfTeamLet === true) {
            return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "ADDED_PATIENTS", "UPDATE");
        } else {
            return false;
        }
    } else {
        return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "ADDED_PATIENTS", "UPDATE");
    }
}

//  Check if User can add Added Patients
function canAddAddedPatients() {
    //  Check if requires membership
    if (props.permissions.checkPermissionSetting("REQUIRES_MEMBERSHIP", "READ") === true){
        //  Check for membership
        if (props.isMemberOfTeamLet === true) {
            return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "ADDED_PATIENTS", "CREATE");
        } else {
            return false;
        }
    } else {
        return props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "ADDED_PATIENTS", "CREATE");
    }
}

//  Check if User modify Added Patients
function showActions() {
    //  Check if requires membership
    if (props.permissions.checkPermissionSetting("REQUIRES_MEMBERSHIP", "READ") === true){
        //  Show Action colum if user can update and delete
        if (props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "ADDED_PATIENTS", "DELETE") &&
                props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "ADDED_PATIENTS", "UPDATE") &&
                props.isMemberOfTeamLet === true)
        {
            return true;     
        } else {
            return false;
        }
    } else {
        if (props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "ADDED_PATIENTS", "DELETE") &&
                props.permissions.checkSubfunctionPermission("DASHBOARD", "HUDDLE", "ADDED_PATIENTS", "UPDATE"))
        {
            return true;     
        } else {
            return false;
        }
    }
}

//  Can Discuss Patient
function canDiscuss(){
     //  Check for membership
     if (props.isMemberOfTeamLet === true) {
        return true;
    } else {
        return false;
    }
}
    async function dbUpdateNotesDiscussed(updatedData: any) {

        const token = sessionStorage.getItem('ACTIVATEtoken');

        const response = await fetch(
            `${config.activateServer}:${config.activatePORT}/updateMeetingPatient`,
            {
                method: "POST",
                body: JSON.stringify({
                    token: token,
                    "internalID": updatedData.internalID,
                    "patientName": updatedData.patientName,
                    "criteria": updatedData.criteria,
                    "discussed": updatedData.discussed,
                    "notes": updatedData.notes,
                    "patient": updatedData.patient,
                    "meeting": updatedData.meeting,
                    "taggedBy": updatedData.taggedBy,
                    "notesAuthor": updatedData.notesAuthor
                }),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                mode: "cors",
            }
        );
        try {
            const data = await response.json();
            getCurrentMeeting(props.meetingID);
            return (data);
        } catch (err) {
            console.log("ERROR " + err);
        }
    }

    function huddleDeleted(meetingID : string) {
        setDeleteOccured(!deleteOccurred);
        getCurrentMeeting(meetingID);
    }

    return (
        <Container fluid>
            <div className="d-flex">
                {discussType === "view" ? <h2>Added Patients</h2> : <h2>Discuss Patients</h2>}
                {relativeTime === "current" && discussType === "view" ?
                    <Form.Group className="form-group reset alignRight">
                        <Button
                            className="btn-function-button btn"
                            disabled={patientData.length===0 ? true : false}
                            variant="function"
                            hidden={!canDiscuss()}
                            onClick={(e) => { setDiscussType("discuss"); }}
                        >
                            Discuss
                        </Button>
                    </Form.Group> : null}
            </div>
            <div className="d-flex">
                {discussType === "view" && meetingDate !== undefined ? <p>{meetingDate} {meetingDay} @ {meetingTime} {meetingAMPM} </p> : <p></p>}
                <div className="form-group reset alignRight">
                    {enablePrevButton && discussType === "view" ?
                        <Anchor onClick={() => setNavigationButtons('prev')}><Icon size="14" name="arrow-left" className="btn-link"></Icon>Prev</Anchor>
                        : discussType === "view" ? <p>Prev&nbsp;&nbsp;</p> : null}&nbsp;
                    {enableNextButton && discussType === "view" ?
                        <Anchor onClick={() => setNavigationButtons('next')}>Next<Icon size="14" name="arrow-right" className="btn-link"></Icon></Anchor>
                        : discussType === "view" ? <p>Next</p> : null}
                </div>
            </div>
            <LoadPanel visible={showProgress} message={"Loading Huddle Schedule"} showPane={true} position={position} showIndicator={true}></LoadPanel>

            <DataGrid id="grid-container"
                keyExpr="internalID"
                focusStateEnabled={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                showBorders={false}
                wordWrapEnabled={true}
                dataSource={patientData}
                noDataText='No patients have been loaded'>
                <Selection mode="none" />
                <GroupPanel visible={false} />
                <Column
                    dataField="patientName"
                    caption="Name"
                    width={"35%"} 
                    cellRender={renderPatientLink}
                    headerCellRender={renderNameHeader}
                />
                <Column dataField="criteria" width={"50%"} caption={relativeTime === "current" && discussType === "view" ? "Reason" : "Reason and Notes"} cellRender={renderReasonNotes} headerCellRender={renderColumnHeader} />
                {(relativeTime === "current" || relativeTime === "future") && discussType === "view"
                    ? [<Column key={Math.random()} type="buttons" caption="Action" visible={showActions()} cellRender={renderActionButtons}></Column>]
                    : relativeTime === "current" && discussType === "discuss" ? [<Column key={Math.random()} caption="Discussed" cellRender={renderDiscussCheckbox}></Column>] : null}
                {relativeTime === "past" ? [<Column key={Math.random()} caption="Discussed" cellRender={renderCheck}></Column>] : null}
                <Paging
                    defaultPageSize={9}
                    defaultPageIndex={1} />
                <Pager showNavigationButtons={true} />
            </DataGrid>
            {relativeTime !== "past" && discussType === "view" && canAdd === true
                ? <Anchor onClick={(e) => { setModalEditType("add"); setShowPatient(true); }} id={patientInternalID} hidden={!canAddAddedPatients()}>Add Patient to this Huddle</Anchor>
                : null
            }
            {discussType === "discuss" ? <>
                <div className="form-actions">
                    <Button
                        className="system submit"
                        type="submit"
                        variant="primary"
                        onClick={(e) => { e.preventDefault(); saveNotes(e); setOriginalPatientData(JSON.stringify(patientData)); }}
                        id="submitButton"
                    >
                        Save
                    </Button>
                    <Button
                        className="system cancel"
                        id="cancelButton"
                        variant="secondary"
                        type="button"
                        onClick={() => { setPatientData(JSON.parse(originalPatientData)); setDiscussType("view"); }}
                    >
                        Cancel
                    </Button></div></>
                : null}

            <ModalAddPatientToHuddle
                showPatient={showPatient}
                patientName={patientName}
                patientID={patientInternalID}
                setShowPatient={setShowPatient}
                modalEditType={modalEditType}
                teamletID={healthcareTeam}
                huddleID={props.huddleID}
                meetingID={props.meetingID}
                meetingPatientID={meetingPatientID}
                teamletTime={teamletTime}
                notes={notes}
                notesAuthor={notesAuthor}
                taggedBy={taggedBy}
                patientCriteria={modalEditType === "add" ? '' : patientCriteria}
                getCurrentMeeting={getCurrentMeeting}
                currentPatients={patientData}
            />
            <ModalRemovePatientFromMeeting
                showRemovePatientModal={showRemovePatientModal}
                setShowRemovePatientModal={setShowRemovePatientModal}
                patientName={patientName}
                patientID={patientInternalID}
                criteria={patientCriteria}
                teamletName={''}
                teamletID={healthcareTeam}
                internalID={meetingPatientID}
                name={huddleName}
                description={huddleDescription}
                teamletTime={teamletTime}
                meetingID={props.meetingID}
                toggleRemoveModal={() => { setShowRemovePatientModal(false); }}
                huddleDeleted={huddleDeleted}
            />
            <Modal show={showErrorModal}>
                <Modal.Header className="error">
                    <h2>{errorMessageTitle}</h2>
                </Modal.Header>
                <Modal.Body>
                    {errorMessage}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="submit error" onClick={() => showWarningModal(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>

        </Container >
    );
}
export default AddedPatients;
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState} from 'react';
import PermissionService from '../../../Services/PermissionService';
import { Anchor } from 'react-bootstrap';
import { DataGrid, Column, Paging} from 'devextreme-react/data-grid';
import { Icon } from '@ailibs/feather-react-ts';
import { LoadPanel } from 'devextreme-react/load-panel';

const config = require("../../../config.json");

function MyNextHuddle(props:
{
  permissions: PermissionService;
  switchView: (newView: string) => void;
  setHuddleID: (huddleID: string) => void;
  setMeetingID: (meetingID: string) => void;
  setSelectedTeamletID: (Id: string) => void,
}) {

//  Form State
const [myNextHuddles, setMyNextHuddles] = useState("");

//  Spinner Icon location
const position = { of: '#myNextHuddles' };
const [showProgress, setShowProgress] = useState(false);

//  Init
useEffect(() => {
  //  Abort controller to close long fetch calls
  const huddleScheduleController = new AbortController();
  const token = sessionStorage.getItem('ACTIVATEtoken');

  //  Get Patients
  const fetchHuddles = async () => {
      
      try {
        setShowProgress(true);

        await fetch(`${config.activateServer}:${config.activatePORT}/getNextHuddles`, {
            method: 'POST',
            body: JSON.stringify({ token: token }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(res =>{
          if (!res.ok) {
            throw Error('Could not fetch the data for the resource');
          }
          return res.json();
        }).then(huddles =>{
          setMyNextHuddles(() => getHuddleList(huddles));
        }).catch(err =>{
          if (err.name === 'AbortError') {
            //console.log('fetch aborted');
          } else {
            console.log(err);
          }
        });

        setShowProgress(false);
      } catch (err) {
          huddleScheduleController.abort();
          setShowProgress(false);
      }
  };

  // call the function
  fetchHuddles().catch(console.error);

  //  Clean-up useEffect
  return () => {
    huddleScheduleController.abort();
  };
}, []);

/**
* @name getHuddleList
* @description Remove Huddles without Next Huddles
* @param grid row
* @returns 
*/
function getHuddleList(data:any){
  return data.filter((i:any) => (i.nextHuddle != null));
}

/**
* @name getHuddleInfo
* @description Builds Huddle Info
* @param grid row
* @returns 
*/
function getHuddleInfo(e:any) {
  let meetingLocalDate = '';
  let meetingLocalTime = '';
  let huddleName = '';
  let huddleDesc = '';
  let huddleId = '';
  let meetingId = '';
  let teamletId = '';

  //  Check for proper data
  if (e.data != null && e.data.nextHuddle.nextMeeting != null) {
    huddleId = e.data.nextHuddle.internalID;
    meetingId = e.data.nextHuddle.nextMeeting;      
    meetingLocalDate = new Date(e.data.nextHuddle.nextHuddleTime).toLocaleDateString().toLowerCase();
    meetingLocalTime = new Date(e.data.nextHuddle.nextHuddleTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toLowerCase();
    huddleName = e.data.name;
    huddleDesc = e.data.digitalHealthPathways[0].name;
    teamletId = e.data.internalID;
  }

  return (
      <table>
        <tbody>
          <tr>
            <td>{renderIcon("calendar","todo")}</td>
            <td colSpan={2}>
              <Anchor onClick={(e) => { props.setHuddleID(huddleId); props.setMeetingID(meetingId); props.switchView("huddledetails"); }} title="View Huddle Details" >{meetingLocalDate} @ {meetingLocalTime}</Anchor>
            </td>
          </tr>
          <tr>
            <td></td>
            <td rowSpan={2} >{renderIcon("users","people")}</td>
            <td><Anchor onClick={(e) => { props.setSelectedTeamletID(teamletId); props.switchView("TeamletDetails"); }} title="View Teamlet Details" >{huddleName}</Anchor></td>
          </tr>
          <tr>
            <td></td>
            <td colSpan={2}>{huddleDesc}</td>
          </tr>
        </tbody>
    </table>
    )
}

/**
* @name getPatientCount
* @description Gets the Next Huddle's Patient Count
* @param grid row
* @returns 
*/
function getPatientCount(e:any){
  //  Check for data and a next huddle
  if (e.data != null && e.data.nextHuddle != null)
  {
    let meetingId = e.data.nextHuddle.internalID;
    let nextHuddleMeetingId = e.data.nextHuddle.nextMeeting; 
     
    return (
      <><Anchor onClick={(e) => { props.setHuddleID(meetingId); props.setMeetingID(nextHuddleMeetingId); props.switchView("huddledetails"); }} title="View Huddle Details" >{e.data.nextHuddle.nextHuddlePatientCount} Patients</Anchor></>
    )
  } else {
    return (
      <>None</>
    )
  }
}

/**
 * @name renderIcon
 * @description Shows the feather icon
 * @param
 * @returns
 */
function renderIcon(iconName : any, category:string) {
  return (
    <div className={`ui-icon ${category}`}>
      <Icon name={iconName} size="20" />
    </div>
  );
}

return (
  <div>
      <section>
        <h2>My Next Huddles</h2>
      </section>
      <LoadPanel visible={showProgress} message={"Loading My Next Huddles"} showPane={true} position={position} showIndicator={true}></LoadPanel>
      <div id="myNextHuddles">
        <DataGrid id="grid-container" 
            dataSource={myNextHuddles}
            showColumnLines={false}
            showRowLines={false}
            showBorders={false}
            focusStateEnabled={true}
            allowColumnResizing={true} 
            showColumnHeaders={false}
            width={'100%'}
            noDataText='You have no scheduled Huddles'>
            <Paging defaultPageSize={7} />
              <Column dataField="internalID" caption="Meeting Id" visible={false} />
              <Column dataField="huddle.internalID" caption="huddle Id" visible={false} />
              <Column width={'80%'} cellRender={e => getHuddleInfo(e)} />
              <Column width={'20%'} dataField="assignedPatientCount" alignment={'center'} cellRender={e => getPatientCount(e)} />
        </DataGrid>
      </div>
    </div>
  );
}

  export default MyNextHuddle;

import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Breadcrumb, Form, Modal } from 'react-bootstrap';
import './AddUsers.css';
import { EmailValidator } from 'commons-validator-js';
import RadioGroup from 'devextreme-react/radio-group';
import { ModalErrorMessage } from '../Shared/MessageBox/ModalErrorMessage';
import { STAFFPCPID } from '../Shared/constants';
import PermissionService from '../../Services/PermissionService';

const config = require('../../config.json');

function EditUsers(props: {
    switchView: Function, loginUserInfo: any, usernameProp: string, statusProp: string, roleTemplateProp: any, roleNameProp: any, healthcenterProp: any,
    assignedOrgProp: any[], firstNameProp: string, middleNameProp: string, lastNameProp: string, prefixProp: string, suffixProp: string,
    preferredNameProp: string, emailProp: any, cellPhoneProp: any, landlineProp: any, idProp: string, assignedOrgIdProp: any[], properties: Map<string, string>,
    permissions: PermissionService
}) {

    const [internalID, setInternalID] = useState(props.idProp);
    const [validated, setValidated] = useState(false);
    const [status, setStatus] = useState(props.statusProp !== "" ? props.statusProp : "DISABLED");
    const [role, setRole] = useState(props.roleNameProp !== "" ? props.roleNameProp : "");
    const [userRoleList, setUserRoleList] = useState<any[]>([]);
    const [prefixList, setPrefixList] = useState<any[]>([]);
    const [suffixList, setSuffixList] = useState<any[]>([]);
    const [roleData, setRoleData] = useState<any[]>([]);
    const [roleName, setRoleName] = useState<string | null>(props.roleNameProp !== "" ? props.roleNameProp : "");

    const [roleID, setRoleID] = useState<string | null>(props.roleTemplateProp);
    const [organizationList, setOrganizationList] = useState<any[]>([]);

    const [organizationListOptions, setOrganizationListOptions] = useState<any[]>([]);
    const [assignedOrganizationsArray, setAssignedOrganizationsArray] = useState(props.assignedOrgIdProp.toString());
    const [origOrganizations, setOrigOrganizations] = useState<any[]>(props.assignedOrgProp);
    const [finalOrganizations, setFinalOrganizations] = useState<any[]>(props.assignedOrgIdProp);
    const [firstName, setFirstName] = useState(props.firstNameProp ? props.firstNameProp : "");
    const [middleName, setMiddleName] = useState(props.middleNameProp ? props.middleNameProp : "");
    const [lastName, setLastName] = useState(props.lastNameProp ? props.lastNameProp : "");
    const [prefix, setPrefix] = useState(props.prefixProp !== "" ? props.prefixProp : "");
    const [suffix, setSuffix] = useState(props.suffixProp !== "" ? props.suffixProp : "");
    const [preferredName, setPreferredName] = useState(props.preferredNameProp !== "" ? props.preferredNameProp : "");
    const [emailAddress, setEmailAddress] = useState(props.emailProp !== undefined ? props.emailProp.address : '')
    const [emailAddressObj, setEmailAddressObj] = useState(props.emailProp !== undefined ? props.emailProp : {})
    const [cellPhone, setCellPhone] = useState(props.cellPhoneProp !== undefined ? props.cellPhoneProp : {});
    const [cellPhoneNumber, setCellPhoneNumber] = useState(props.cellPhoneProp !== undefined ? props.cellPhoneProp.number : "");
    const [landLineNumber, setLandLineNumber] = useState(props.landlineProp !== undefined ? props.landlineProp.number : "");
    const [landLineObj, setLandLineObj] = useState(props.landlineProp !== undefined ? props.landlineProp : {});
    const [requireChangePassword, setRequireChangePassword] = useState(false);
    const [badSubmissionError, setBadSubmissionError] = useState("");
    const [orgIds, setAssignedOrgIds] = useState<any[]>(props.assignedOrgIdProp);
    const [showAccountLockedMessage] = useState(props.statusProp === "LOCKED" ? true : false);
    const [properties, setProperties] = useState(props.properties !== undefined ? new Map(Object.entries(props.properties)) : new Map());
    const [pcpnpiIdentifier, setPcpnpiIdentifier] = useState("");
    const [userName, setUserName] = useState(props.usernameProp ? props.usernameProp : "");

    //  Error message props
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorServerMessage, setErrorServerMessage] = useState("");
    const [errorUserMessage, setErrorUserMessage] = useState("");

    const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()
    //, PNF = require('google-libphonenumber').PhoneNumberFormat
    //, PNT = require('google-libphonenumber').PhoneNumberType

    const validPhoneNumber = (e: any) => {
        // https://www.ronaldjamesgroup.com/blog/phone-numbers-in-javascript-using-e164-libphonenumber-and-microdata
        // https://www.npmjs.com/package/google-libphonenumber
        let isValid = true;

        try {
            //var tel = phoneUtil.parse(e, 'US');
            isValid = phoneUtil.isValidNumber(phoneUtil.parse(e, 'US'));
        } catch (e) {
            isValid = false
        }
        setBadSubmissionError('');
        return isValid;
    }

    const token = sessionStorage.getItem('ACTIVATEtoken');

    useEffect(() => {
        loadPCPIdentifier();
        loadPrefixes();
        loadSuffixes();
        loadRoles();
        loadOrganizations();

    }, []);

    function dropdownSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();

        const name = event.target.name;
        if (name === "prefix") {
            setPrefix(event.target.value);
            return;
        }

        if (name === "suffix") {
            setSuffix(event.target.value);
            return;
        }

        if (name === "role") {
            roleData.forEach((r) => {
                if (r.name === event.target.value) {
                    setRoleName(r.name);
                    // @ts-ignore                    
                    setRoleID(r.internalID);
                    return;
                }
            })
            setRole(event.target.value);
        }
    }

    async function loadRoles() {
        //  Call service
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getRoleTemplates`, {
            method: 'POST',
            body: JSON.stringify({ token: token }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        })

        try {
            const data = await response.json();
            let roleList: any[] = [];
            /* start with the old role  */

            roleList.push(<option
                key={roleID}
                value={roleName || ''}
                id={roleID || ''}>
                {roleName}
            </option>)
            for (let i = 0; i < data.length; i++) {
                //console.log("LOADING TEMPLATES " + i + " " + JSON.stringify(data[i]));
                // displaying the current role as the one on top of the list
                if (data[i].name !== roleName) {
                    roleList.push(<option
                        key={data[i].internalID}
                        value={data[i].name}
                        id={data[i].internalID || ''}>
                        {data[i].name}
                    </option>)
                }
            }
            setRoleData(data);
            setUserRoleList(roleList);

        } catch (err) {
            console.log("ERROR " + err);
        }
    }

    const orgCheck = (orgValue: any) => {
        let returnValue = false;

        origOrganizations[0].forEach((i: any) => {
            if (i.toString() === orgValue) returnValue = true;
            return;
        })
        return returnValue;
    }

    /**
     * @name changeSelectedAssignTo
     * @description  Handles the radio button change event
     * @param  
     * @returns 
    */
    //@ts-ignore
    function changeSelectedAssignTo(e) {
        //  Service requires an array
        let _orgToAssign: any[] = [];
        _orgToAssign.push(e.value)

        //  Set State on Radio Buttons
        setAssignedOrganizationsArray(e.value);
        
        //  Reset Error
        setBadSubmissionError('');
    }

    async function loadOrganizations() {
        // Get HealthCenters
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getHealthCenters`, {
            method: 'POST',
            body: JSON.stringify({ token: token, centerID: assignedOrganizationsArray }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        })

        try {
            const hcdata = await response.json();
            //console.log("GOT BACK HEALTHCENTERS " + JSON.stringify(data));
            //Load the table here
            let organizationList: React.SetStateAction<any[]> = [];
            let data: string | any[] | null = null;

            // Matches Parent
            //@ts-ignore
            if (hcdata.filter((b) => b.internalID === orgIds[0]).length > 0) {
                //@ts-ignore 
                data = hcdata.filter((b) => b.internalID === orgIds[0])
            }
            //@ts-ignore 
            else if (hcdata.filter((b) => b.sites.find((pw) => pw.internalID === orgIds[0])).length > 0) {
                //@ts-ignore 
                data = hcdata.filter((b) => b.sites.find((pw) => pw.internalID === orgIds[0]))
            }

            //@ts-ignore
            setOrganizationListOptions(data);

            if ( data != null){
                for (let i = 0; i < data.length; i++) {
                    // if this org was the org from the Users list, hold on to the id for future reference

                    if (roleName === 'Patient') {
                        organizationList.push(
                            <Form.Check
                                key={data[i].internalID}
                                required={false}
                                label={data[i].name}
                                name={data[i].name}
                                value={data[i].internalID}
                                id={data[i].internalID}
                                className="org-checkbox"
                                type="checkbox"
                                defaultChecked={orgCheck(data[i].name) ? true : false}
                                onChange={(e) => addOrRemoveOrganization(e)}
                                feedback="You must select at least one Organization for this user but only one is permitted if the user is a patient"
                                feedbackType="invalid"
                            />)
                    } else {
                        let siteList = [];

                        organizationList.push(
                            <Form.Check
                                key={data[i].internalID}
                                required={false}
                                label={data[i].name}
                                name={data[i].name}
                                id={data[i].internalID}
                                defaultChecked={orgCheck(data[i].name) ? true : false}
                                type="checkbox"
                                className="org-checkbox"
                                onChange={(e) => addOrRemoveOrganization(e)}
                                feedback="You must select at least one Organization for this user"
                                feedbackType="invalid"
                            />
                        )
                        if (roleName !== 'Patient') {
                            if (data[i].sites !== undefined) {
                                siteList = data[i].sites.map((s: any) => {
                                    return (
                                        <Form.Check
                                            key={s.internalID}
                                            required={false}
                                            label={s.name}
                                            id={s.internalID}
                                            disabled={roleName === "Organization Administrator"}
                                            defaultChecked={orgCheck(s.name) ? true : false}
                                            name={s.name}
                                            type="checkbox"
                                            className="site-checkbox"
                                            onChange={(e) => addOrRemoveOrganization(e)}

                                        />
                                    )
                                })
                                organizationList.push(siteList)
                            }
                        }
                    }
                }
            }

            setOrganizationList(organizationList);

        } catch (err) {
            console.log("ERROR " + err);
        }

        setFinalOrganizations(orgIds);
    }

    /**
     * loadPCPIdentifier
     */
    function loadPCPIdentifier(){
        //  Check for PCP Properties
        if (props.properties != null &&
                props.properties !== undefined){
            //  Convert properties to Map    
            const map = new Map(Object.entries(props.properties));

            //  Set the PCP ID
            let _id = '';

            //  Check for StaffPCPID
            if (map.has(STAFFPCPID)){
                _id = map.get(STAFFPCPID);
            }
        
            //  Set the PCP Id, if found
            setPcpnpiIdentifier(_id!);
        } else {
            setPcpnpiIdentifier('');
        }
    }

    /**
     * loadPrefixes
     */
    async function loadPrefixes() {
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getValues`, {
            method: 'POST',
            body: JSON.stringify({ token: token, type: 'Prefix' }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        })

        try {
            const data = await response.json();
     
            //Load the table here  
            setPrefixList(data.map((i: any) => {
                return (<option
                    key={i.value}
                    value={i.value}>
                    {i.name}
                </option>)
            }));
        } catch (err) {
            console.log("ERROR " + err);
        }
    }

    async function loadSuffixes() {
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getValues`, {
            method: 'POST',
            body: JSON.stringify({ token: token, type: 'Ordinal' }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(async (response: Response) => {
            //  Check for Success
            if (response.ok) {
                try {
                    const data = await response.json();
                    //console.log("GOT BACK SUFFIXES " + JSON.stringify(data));
                    //Load the table here
                    setSuffixList(data.map((i: any) => {
                        return (<option
                            key={i.value}
                            value={i.value}>
                            {i.name}
                        </option>)
                    }));
                } catch (err) {
                    console.log("ERROR " + err);
                }
            }
            else {
                // Handle non-200s
                console.log("Received HTTP status" + response.status.toString())

            }
        })
    };

    function addOrRemoveOrganization(event: any) {
        //First check if box was check or unchecked
        const originalLength = finalOrganizations.length;
        const elements = event.currentTarget.form.elements;

        if (event.target.checked === true) {
            const tempFinalOrgs = finalOrganizations;
            const tempOrgNames = origOrganizations;
            tempFinalOrgs.push(event.target.id);
            tempOrgNames.push(event.target.name);
            setFinalOrganizations(() => { return tempFinalOrgs; });
            setOrigOrganizations(() => { return tempOrgNames; });

            if (roleName === 'Patient') {
                for (var iLp = 0, element; element = elements[iLp++];) {
                    if (element.id === 'organization') {
                        if (finalOrganizations.length === 1) {
                            element.classList.remove('is-invalid');
                            element.required = false;
                        } else {
                            element.classList.add('is-invalid');
                            element.required = true;
                        }
                    }
                }
            }
        }
        if (event.target.checked === false) {

            const tempFinalOrgs = finalOrganizations;
            const tempOrgNames = origOrganizations;
            for (let i = 0; i < tempFinalOrgs.length; i++) {
                if (tempFinalOrgs[i] === event.target.id) {
                    tempFinalOrgs.splice(i, 1);
                }
            }
            for (let i = 0; i < tempOrgNames.length; i++) {
                if (tempOrgNames[i] === event.target.name) {
                    tempOrgNames.splice(i, 1);
                }
            }
            setFinalOrganizations(() => { return tempFinalOrgs; });
            setOrigOrganizations(() => { return tempOrgNames; });


            //  Loop through form elements
            for (let i = 0; i < elements.length; i++) {
                let element = elements[i]
                if (element.id === 'organization') {
                    if (originalLength === 1) {
                        element.classList.add('is-invalid');
                        element.required = true;
                    } else {
                        element.classList.remove('is-invalid');
                        element.required = false;
                    }
                }
            }
        }

    }

    //  updatePcpnpiIdentifier
    function updatePcpnpiIdentifier(e:any){
        setPcpnpiIdentifier(e);

        //  Check for PCP Properties
        if (properties != null &&
                properties !== undefined){
            //  Update Properties Map
            properties.set(STAFFPCPID, e);
        }

    }

    const handleSubmit = (event: any) => {
            const form = event.currentTarget;
            event.preventDefault();
            setBadSubmissionError('');
            const validator = new EmailValidator();
            var elements = form.elements
            let isCellphoneValid = true;
            let isEmailValid = true;
            const cellphoneElement = elements.namedItem('cellPhoneNumber');
            const emailElement = elements.namedItem('emailAddress');
            let isLandlineValid = false;
            //  Loop through form elements  
            for (let i = 0; i < elements.length; i++) {
                let element = elements[i]
                //  Get the organization input
                if (element.type === "checkbox" && element.id === 'organization') {
                    //  Let blanks display normal error message
                    if (element.checked === false) {
                        if (finalOrganizations.length === 0) {
                            //  Let normal form validation handle message
                            element.classList.add('is-invalid');
                            element.required = true;
                        }
                    }
                    if (element.checked === true) {
                        if (roleName === 'Patient' && finalOrganizations.length > 1) {
                            element.classList.add('is-invalid');
                            element.required = true;
                            element.feedback = "Only one organization can be entered for a patient";
                        } else {
                            element.classList.remove('is-invalid');
                            element.required = false;
                        }

                    }
                }

                if (element.type === "text" && element.id === 'emailAddress') {
                    //  Let blanks display normal error message
                    if (element.value === '' && validPhoneNumber(cellphoneElement.value) === true) {
                        element.classList.remove('is-invalid');
                        element.required = false;
                    }
                    if (element.value !== '') {
                        if (validator.isValid(element.value) === false) {
                            isEmailValid = false;
                            element.classList.add('is-invalid');
                            element.required = true;
                        } else {
                            isEmailValid = true;
                            element.required = false;
                            element.classList.remove('is-invalid');
                            isEmailValid = true;
                        }
                    }
                }

                if (element.type === "text" && element.id === 'cellPhoneNumber') {
                    if (validator.isValid(emailElement.value) === true && element.value === '') {
                        element.classList.remove('is-invalid');
                        element.required = false;
                    }

                    if (element.value !== '') {
                        if (validPhoneNumber(cellPhoneNumber) === false) {
                            isCellphoneValid = false;
                            element.classList.add('is-invalid');
                            element.required = true;
                            element.nextElementSibling.innerHTML = "Please enter the user cell Phone Number (xxx) xxx-xxxx."
                        } else {
                            element.classList.remove('is-invalid');
                            element.required = false;
                            element.nextElementSibling.innerHTML = '   ';
                            isCellphoneValid = true;
                        }
                    }
                }

                if (element.type === "text" && element.id === 'landlineNumber') {
                    if (element.value !== '' && validPhoneNumber(landLineNumber) === false) {
                        element.classList.add('is-invalid');
                        element.required = true;
                        element.nextElementSibling.innerHTML = 'Please enter a valid phone number ';
                        isLandlineValid = false;
                    } else {
                        isLandlineValid = true;
                        element.required = false;
                        element.classList.remove('is-invalid');
                        element.nextElementSibling.innerHTML = ' ';
                    }
                }
            }
            if (!isEmailValid || !isCellphoneValid || !isLandlineValid) {
                event.stopPropagation();
                setValidated(true);
                return;
            }

            if (form.checkValidity() === false) {
                event.stopPropagation();
                setValidated(true);
                return;
            }

            //  Final Save point
            updateUser();

        };

    /**
     * @name handleStatusChange
     * @description Sets the Status properly
     * @param
     * @returns {json}
     */
    // @ts-ignore 
    async function handleStatusChange(e) {

        switch (e.target.name) {
            case "Inactive":
                setStatus("INACTIVE");
                break;
            case "Active":
                setStatus("ACTIVE");
                break;
            case "Disabled":
                setStatus("DISABLED");
                break;
            default:
                setStatus("INACTIVE");
                break;
        }
    }

    //  Converts Map to an Array
    function buildProperties(){
        let arr = '';
        
        for (let key of properties.keys()) {
            var _key = key
            //arr = '"properties": {"' + _key + '" : "' + properties.get(key) + '"}';
            arr = '"properties": {"' + _key + '" : "' + properties.get(key) + '"}';
            //arr = '{"' + _key + '" : "' + properties.get(key) + '"}';
           
        }

        //return arr;
    }

    /**
	 * @name updateUser
	 * @description Updates the user
	 * @param
	 * @returns {json}
	 */
    async function updateUser() {
        let propertyMap = '{}'
        let aProperties = JSON.parse(propertyMap);
        let _temp = '';

        //  Convert the Map
        for (let key of properties.keys()) {
            let _key = key
            _temp = '{"' + _key + '" : "' + properties.get(key) + '"}';
        }
        //  Create Object
        if (_temp != ''){
            aProperties = JSON.parse(_temp);
        }
       
        const _response = await fetch(`${config.activateServer}:${config.activatePORT}/updateUser`, {
            method: 'POST',
            body: JSON.stringify({
                token: token,
                internalID,
                userName,
                status,
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                preferredName,
                emailAddress,
                emailAddressObj,
                cellPhoneNumber,
                cellPhone,
                landLineNumber,
                landLineObj,
                requireChangePassword,
                roleID,
                finalOrganizations, 
                properties: aProperties
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(res => {
            try {
                //  Check for Success
                if (res.ok) {
                    
                    props.switchView("users")
                } else {
                    //  Error returned from data service, display to user
                    res.json().then(data => {
                        //  Create Message
                        const _statusCode = res.status.toString();
                        const _statusmessage = JSON.stringify(data)
                        setErrorMessageTitle("Error Updating User")
                        setErrorMessage("An error occurred updating the user [" + emailAddress + "]")
                        setErrorServerMessage(data.message + " (Code: " + _statusCode + ")");
                        setErrorUserMessage("Please check the data you entered, and re-try.");
                        
                        //  Show Modal
                        showErrorMessage(true);
                    })
                }
            }
            catch (err) {
                console.log("Error in saveEditOrganization: Saving Organization " + JSON.stringify(err));
            }
        })
    }

/**
   * @name showErrorMessage
   * @description  Error Pop-up
   * @param  
   * @returns 
*/
function showErrorMessage(toggle : boolean) {
    setShowErrorModal(toggle);
}
        return (
            <div>
                <Container fluid>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => props.switchView("configuration")}>Configuration</Breadcrumb.Item>
                        <Breadcrumb.Item active>Edit User</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="page-panel">
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6}>
                                    {roleName !== 'Patient' ?
                                        <Form.Group className="form-group" controlId='status'>
                                            <Form.Label className="required">Status</Form.Label>
                                            <Form.Check
                                                key='active'
                                                type="radio"
                                                label="Active"
                                                name="Active"
                                                disabled={roleName === 'Patient' ? true : false}
                                                checked={status === "ACTIVE" ? true : false}
                                                id={"active"}
                                                onChange={handleStatusChange} />
                                            <Form.Check
                                                key='inactive'
                                                type="radio"
                                                label="Inactive"
                                                disabled={roleName === 'Patient' ? true : false}
                                                checked={status === "INACTIVE" ? true : false}
                                                onChange={handleStatusChange}
                                                id={"inactive"}
                                                name="Inactive" />
                                            <Form.Check
                                                key='disabled'
                                                type="radio"
                                                label="Disabled"
                                                checked={status === "DISABLED" ? true : false}
                                                onChange={handleStatusChange}
                                                id={"disabled"}
                                                name="Disabled" />
                                        </Form.Group> : null}
                                    {showAccountLockedMessage ?
                                        <p className="locked-account">This User Account has been LOCKED</p>
                                        : <></>}
                                    <Form.Group className="form-group" controlId="role">
                                        <Form.Label className="required">Role</Form.Label>
                                        <Form.Select
                                            //aria-label="Role"
                                            key={role}
                                            name="role"
                                            value={role}
                                            onChange={dropdownSelectChange}
                                            disabled>
                                            {userRoleList}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please select a role for this profile.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    {roleName !== 'Patient' ?
                                        <Form.Group className="form-group" controlId='organization'>
                                            <Form.Label className="required checklist">Assigned To</Form.Label>
                                            <div className="org-selection-list">
                                                {organizationList}
                                            </div>
                                            <Form.Control.Feedback type="invalid">
                                                Please check the user organization.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        :
                                        <Form.Group className="form-group" controlId="organizationOptions">
                                            <Form.Label className="required">Assigned To</Form.Label>
                                            <RadioGroup id="radio-group-with-selection"
                                                items={organizationListOptions}
                                                value={assignedOrganizationsArray}
                                                valueExpr="internalID" displayExpr="name"
                                                onValueChanged={changeSelectedAssignTo}
                                                disabled={true} />
                                        </Form.Group>
                                    }

                                    {badSubmissionError.length > 0 ?
                                        <p className="validation-error">{badSubmissionError}</p>
                                        : <></>
                                    }

                                    {props.roleNameProp === 'Provider' ?
                                        <Form.Group className="form-group" controlId="pcpnpiIdentifier">
                                            <Form.Label>PCP NPI Identifier</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Identifier"
                                                value={pcpnpiIdentifier!}
                                                onChange={e => updatePcpnpiIdentifier(e.target.value)}
                                            />
                                        </Form.Group>
                                    : <></>
                                    }
                                </Col>

                                <Col md={6}>
                                    <Form.Group className="form-group" controlId="firstName">
                                        <Form.Label className="required">First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter first name"
                                            required
                                            onChange={e => setFirstName(e.target.value)}
                                            disabled={roleName == 'Patient' ? props.permissions.isEhrOrganization : false}
                                            value={firstName} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your first name.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="form-group" controlId="firstName">
                                        <Form.Label>Middle Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter middle name"
                                            onChange={e => setMiddleName(e.target.value)}
                                            disabled={roleName == 'Patient' ? props.permissions.isEhrOrganization : false}
                                            value={middleName} />
                                    </Form.Group>

                                    <Form.Group className="form-group" controlId="lastName">
                                        <Form.Label className="required">Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter last name"
                                            required
                                            disabled={roleName == 'Patient' ? props.permissions.isEhrOrganization : false}
                                            onChange={e => setLastName(e.target.value)}
                                            value={lastName} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your last name.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="form-group" controlId="prefix">
                                        <Form.Label>Prefix</Form.Label>
                                        <Form.Select
                                            aria-label="Prefix"
                                            name="prefix"
                                            value={prefix}
                                            onChange={dropdownSelectChange}>
                                            <option>Select</option>
                                            {prefixList}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="form-group" controlId="suffix">
                                        <Form.Label>Suffix</Form.Label>
                                        <Form.Select
                                            aria-label="Suffix"
                                            name="suffix"
                                            value={suffix}
                                            onChange={dropdownSelectChange}>
                                            <option>Select</option>
                                            {suffixList}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="form-group" controlId="preferredName">
                                        <Form.Label>Preferred Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter preferred name"
                                            value={preferredName}
                                            onChange={e => setPreferredName(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group className="form-group" controlId="emailAddress">
                                        <Form.Label className="required">{roleName == 'Patient' ? 'ACTIVATE Email Address' :'Email Address'}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter email address"
                                            required
                                            disabled={roleName == 'Patient' ? props.permissions.isEhrOrganization : false}
                                            value={emailAddress}
                                            onChange={e => setEmailAddress(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your email address.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    {/**TODO Add dynamica validation that checks for atleast email address or phone number */}

                                    <Form.Group className="form-group" controlId="cellPhoneNumber">
                                        <Form.Label className="required">Cell Phone</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter cell phone"
                                            value={cellPhoneNumber}
                                            disabled={roleName == 'Patient' ? props.permissions.isEhrOrganization : false}
                                            required
                                            onChange={e => setCellPhoneNumber(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter either an email address or cell phone number
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="form-group" controlId="landlineNumber">
                                        <Form.Label>{roleName == 'Patient' ? 'Home Phone' :'Landline'} </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter landline phone"
                                            value={landLineNumber}
                                            disabled={roleName == 'Patient' ? props.permissions.isEhrOrganization : false}
                                            onChange={e => setLandLineNumber(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid phone number
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    {roleName !== 'Patient' ?
                                        <Form.Group controlId="requireChangePassword" hidden={true}>
                                            <Form.Label>Options</Form.Label>
                                            <Form.Check
                                                type="checkbox"
                                                label="User must change password at next login"
                                                readOnly={false}
                                                checked={requireChangePassword}
                                                onChange={e => setRequireChangePassword(e.target.checked)} />
                                        </Form.Group> : null}
                                </Col>
                            </Row>

                            {badSubmissionError.length > 0 ?
                                <p className="validation-error">{badSubmissionError}</p>
                                : <></>
                            }
                            <div className="form-actions">
                                <Button
                                    className="system submit"
                                    type="submit"
                                    variant="primary"
                                    id="submitButton">
                                    Save
                                </Button>
                                <Button className='system cancel'
                                    id="cancelButton"
                                    variant="secondary"
                                    type="button"
                                    onClick={() => props.switchView("users")}>
                                    Cancel
                                </Button>
                            </div>

                        </Form>
                    </div>
                </Container>

                <ModalErrorMessage   
                    showErrorModal={showErrorModal} 
                    errorMessageTitle={errorMessageTitle} 
                    errorMessage={errorMessage} 
                    errorServerMessage={errorServerMessage} 
                    errorUserMessage={errorUserMessage}
                    messageType="error" 
                    setShowErrorModal={setShowErrorModal}  
                />
            </div>
        )
    }

    export default EditUsers
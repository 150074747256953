import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Breadcrumb, Form, Table, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import 'devextreme/dist/css/dx.light.css';
import { ROLE_HEALTH_COACH, ROLE_ORGANIZATION_SUPER_USER, ROLE_PROVIDER, ROLE_DELEGATED_HEALTH_STAFF, ROLE_DIGITAL_HEALTH_NAVIGATOR } from '../constants';
const config = require("../../../config.json");

function EditTeamlet(props: 
    {
        switchView: Function,
        teamletID: string,
        returnTo: string
    }) {
        
    const [name, setName] = useState("");
    const [parentOrgName, setOrgParentName] = useState("");
    const [parentOrgID, setParentOrgID] = useState("");
    const [site, setSite] = useState("");
    const [siteID, setSiteID] = useState("");
    const [pathwayList, setPathwayList] = useState<any[]>([]);
    const [memberList, setMemberList] = useState<any>([]);
    const [healthCoachCount, setHasHealthCoachCount] = useState(0);
    const [providerCount, setProviderCount] = useState(0);
    const [huddleList, setHuddleList] = useState<any[]>([]); 

    const [validated, setValidated] = useState(false);
    const [siteList, setSiteList] = useState<any[]>([]);
    const [organizationList, setOrganizationList] = useState<any[]>([]);
   //Used to track and lookup sites based on the organization selected on the dropdown
    const [orgData, setOrgData] = useState<any[]>([])
    const [displayPathwayList, setDisplayPathwayList] = useState<any[]>([]);
    const [selectableMembers, setSelectableMembers] = useState<any[]>([]);
    const [displayMemberList, setDisplayMemberList] = useState<any[]>([]);
    const [noPathwaySelected, setNoPathwaySelected] = useState("");
    
    const [badUserSubmissionError, setBadUserSubmissionError] = useState("");
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [testValue, setTestValue] = useState("test");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorServerMessage, setErrorServerMessage] = useState("");
    const [errorUserMessage, setErrorUserMessage] = useState("");
    const token = sessionStorage.getItem('ACTIVATEtoken');
   

    //  Init
useEffect(() => {
    //  Abort controller to close long fetch calls
    const getTeamletInfoController = new AbortController();
  
    //  Get Patients
    const fetchTeamletDetails = async () => {
        try {
          await fetch(`${config.activateServer}:${config.activatePORT}/getTeamletByID`, {signal: getTeamletInfoController.signal,
              method: 'POST',
              body: JSON.stringify({ token: token, internalID: props.teamletID}),
              headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
              },
              mode: 'cors'
          }).then(res =>{
            if (!res.ok){
              throw Error('Could not fetch the data for the resource')
            }
            return res.json();
          }).then(teamletDetails =>{
            setName(teamletDetails.name);
            setOrgParentName(teamletDetails.parentHealthCenter.name);
            setParentOrgID(teamletDetails.parentHealthCenter.internalID);
            setPathwayList(teamletDetails.digitalHealthPathways);
            setMemberList(teamletDetails.members);
            setHasHealthCoachCount(teamletDetails.members !== null ? teamletDetails.members.filter((obj : any) => obj.userRole.name === "Health Coach").length : 0);
            setProviderCount(teamletDetails.members !== null ? teamletDetails.members.filter((obj : any) => obj.userRole.name === "Provider").length : 0);
            setSite(teamletDetails.homeHealthCenter.name.trim());
            setSiteID(teamletDetails.homeHealthCenter.internalID);
            setHuddleList(teamletDetails.huddles);
          }).catch(err =>{
            if (err.name === 'AbortError'){
                //console.log('fetch aborted')
            } else {
                getTeamletInfoController.abort();
            }
          })
      
          //  Stop spinner
          //dgAssignedPatients.current?.instance.endCustomLoading();
        } catch (err) {
            //  Stop spinner
            //dgAssignedPatients.current?.instance.endCustomLoading();
        }
    }
  
    // call the function
    fetchTeamletDetails().catch(console.error);
  
    //  Clean-up useEffect
    return () => {
        getTeamletInfoController.abort();
    }
  }, [])

  useEffect(() => {
        loadOrganizations();
        //These functions load my default with our prop data
        loadTeamletMembers(false);
    }, [parentOrgName])

    useEffect(() => {
        //  Check for Org Id
        if (parentOrgID !== ''){
            loadPathways();
        }
    }, [parentOrgID])

    useEffect(() => {
        //  Check for Site and Site ID
        if (siteID !== '' && site !== ''){
            populateAutocomplate(siteID, site);
        }
    }, [siteID, site])
   
     /**
     * @name dropdownSelectChange
     * @description This tracks the dropdown for the organization.  Then we will the autocomplete with people based on the site
     * @param  
     * @returns
    */
    function dropdownSelectChange(event : React.ChangeEvent<HTMLSelectElement>){
        event.preventDefault();
        //Check if we are dropping down the site or the organization dropdown
        if(event.target.name === "primaryOrganization"){
            let orgValue = event.target.options[event.target.selectedIndex].getAttribute('data-value');
            let orgName = event.target.options[event.target.selectedIndex].getAttribute('data-name');
            setOrgParentName(event.target.value)
            if(orgValue) {
                setParentOrgID(orgValue);
            } else {
                setParentOrgID('')
            }

            //  Reset the selected when Orgs change
            let cleanIt: React.SetStateAction<any[]> = [];
            //  Check for non-blank
            if (orgName != null){
                loadSites(orgName, orgData);
            } else {
                setSiteList(cleanIt);
            }

            setDisplayPathwayList(cleanIt)
            setPathwayList(cleanIt)
         } else {
            let siteValue = event.target.options[event.target.selectedIndex].getAttribute('data-value');
            setSite(event.target.value)
            //@ts-ignore
            setSiteID(siteValue);

            populateAutocomplate(siteValue, (event.target.value).replace(/\s/g, ''));
        }
    }

    //Once we have the organization select in the dropdown we then want to fill the autocomplete input with users who are under 
    // the selected organization
    async function populateAutocomplate(healthCenterID : string | null, sitename : string){
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getUsersByHealthCenterWithStaffAndParent`, {
            method: 'POST',
            body: JSON.stringify({ token : token, healthCenterID : healthCenterID }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        })

        try {
            const data = await response.json();
            //  Load the User List here
            let userList : any = [];
            for (let i = 0; i < data.length; i++) {
                //  Filter loaded team members by only these roles: Health Coach, Health provider, Delegated Health Staff, and Super User
                if (data[i].userRole !== null && 
                        (data[i].userRole.value === ROLE_HEALTH_COACH || 
                            data[i].userRole.value === ROLE_PROVIDER || 
                                data[i].userRole.value === ROLE_DELEGATED_HEALTH_STAFF || 
                                    data[i].userRole.value === ROLE_ORGANIZATION_SUPER_USER ||
                                        data[i].userRole.value === ROLE_DIGITAL_HEALTH_NAVIGATOR)) {
                    userList.push({
                        internalID : data[i].internalID,
                        firstName : data[i].firstName,
                        lastName : data[i].lastName,
                        title : data[i].title,
                        ordinal : data[i].ordinal,
                        preferredName : data[i].preferredName,
                        userRole : data[i].userRole,
                        accountStatus : data[i].accountStatus,
                        streetAddresses : data[i].streetAddresses,
                        phoneNumbers : data[i].phoneNumbers,
                        emailAddresses : data[i].emailAddresses,
                        clinicalSpecialties : data[i].clinicalSpecialties,
                        healthCenters : [data[i].healthCenters[0]]
                    })
                }
            }

            setSelectableMembers(userList);

        } catch(err){
            console.log("ERROR " + err);
        }
    }

    async function loadOrganizations()  {
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getHealthCenters`, {
            method: 'POST',
            body: JSON.stringify({ token : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        })

        try {
            const data = await response.json();
            //console.log("LOADING ORGANIZATIONS " + JSON.stringify(data.length));
            await setOrgData(data);
            //Load the table here
            let organizationList = [];
            for (let i = 0; i < data.length; i++) {
                organizationList.push(
                    <option key={i}
                        data-name={data[i].name}
                        data-value={data[i].internalID}>
                            {data[i].name}
                    </option>
                )
            }

            setOrganizationList(organizationList);
            loadSites(parentOrgName, data);
        } catch(err){
            console.log("ERROR " + err);
        }
    }

    //This function will populate the Sites under an organization after a user selects a specific primary org
    function loadSites(orgName : string | null, orgList : any){
        let tempSiteList : any = [];
        let matchSite;
        for(let i = 0; i < orgList.length; i++){
            //Check through the data for the right org
            if(orgList[i].name === orgName){
                matchSite = orgList[i].sites;
            }
        }

        //  Check if matchSite was found
        if (matchSite != undefined){
            for(let j = 0; j < matchSite.length; j++){
                tempSiteList.push(
                    <option key={j}
                        data-name={matchSite[j].name}
                        data-value={matchSite[j].internalID}
                        >
                        {matchSite[j].name}
                    </option>
                )
            }
        }
        setSiteList(tempSiteList)
    }

    //  Add i.e. Ampla ID
    async function loadPathways() {
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getPathways`, {
            method: 'POST',
            body: JSON.stringify({ token : token, organizationID: parentOrgID}),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        })

        try {
            const data = await response.json();
            //console.log("GOT BACK PATHWAY " + JSON.stringify(data));
            //Load the table here
            let pathwayList = [];
            for (let i = 0; i < data.length; i++) {
                pathwayList.push(
                    <Form.Check
                        key={i}
                        id={"pathway_"+i}
                        required={false}
                        label={data[i].name}
                        name={data[i].name}
                        defaultChecked={checkIfChecked(data[i].name)}
                        type="checkbox"
                        onChange={event => addOrRemovePathway(data[i].internalID, event.target.checked)}
                    />
                )
            }

            setDisplayPathwayList(pathwayList);
        } catch(err){
            console.log("ERROR " + err);
        }
    }

    /**
    * @name addOrRemovePathway
    * @description When Pathway Checkboxes are selected and deselected
    * @param  
    * @returns
    */
    function addOrRemovePathway(teamlet : any, event : any) {
        setNoPathwaySelected("");
        //First check if box was check or unchecked
        if (event) {
            let tempAssignedPathway = pathwayList;
            tempAssignedPathway.push(
                {   
                    "internalID" : teamlet,
                    "name" : ""
                }
            );
            setPathwayList(tempAssignedPathway);
        } else {
            let tempAssignedPathway = pathwayList;
            for(let i = 0; i < tempAssignedPathway.length; i++){
                if(tempAssignedPathway[i].internalID === teamlet){
                    tempAssignedPathway.splice(i, 1);
                }
            }
            setPathwayList(tempAssignedPathway);
        }

        //  Check for selected Pathways
        if (pathwayList.length < 1){
            setNoPathwaySelected("You must select at least one Pathway to create a Teamlet.")
        }
    }

    function loadTeamletMemberHeaders() {
        let headerList = ["Name", "Role", "Email", "Action"];
        return headerList.map((key, index) => {
            return <th key={index}>{key}</th>
        })
    }

    //If we are editing we need to pull from the server but otherwise we store info locally
    async function loadTeamletMembers(editMode : boolean){
        if(editMode){

        } else {
            //console.log("LOADING MEMBERS " + JSON.stringify(memberList));
            let tempMemberList : any = [];
            for (let i = 0; i < memberList.length; i++) {
                tempMemberList.push(
                    <tr key={i}>
                        <td>{memberList[i].firstName + " " + memberList[i].lastName}</td>
                        <td>{memberList[i].userRole.name}</td>
                        <td>{memberList[i].emailAddresses[0] != null ? memberList[i].emailAddresses[0].address : ''}</td>
                        <td>
                            <Button
                                id="removeMember"
                                className="action-icon"
                                variant="action"
                                onClick={() => removeTeamletMember(i)}>
                                    <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
                            </Button>
                        </td>
                    </tr>
                )
            }

            setDisplayMemberList(tempMemberList);
        }
    }

    //This function occurs from clicking the trash can icon to remove a Teamlet member in the list
    function removeTeamletMember(index : any){
        setBadUserSubmissionError("");
        let tempMemberList = memberList;
        if(tempMemberList[index].userRole.name === "Health Coach"){
            setHasHealthCoachCount(healthCoachCount-1);
        } else if (tempMemberList[index].userRole.name === "Provider"){
            setProviderCount(providerCount-1);
        }
        tempMemberList.splice(index, 1);
        setMemberList(tempMemberList);
        loadTeamletMembers(false);
    }

    const handleSubmit = async (event : any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
          event.stopPropagation();
          setValidated(true);
        } else if (pathwayList.length === 0){
            setNoPathwaySelected("You must select at least one Pathway to create a Teamlet.")
        } else if (healthCoachCount === 0 || providerCount === 0) {
            //Give the user a warning that their Teamlet team is missing one of these profiles.  They can still
            //continue through the modal if they choose
            setShowWarningModal(true);
        } else {
            updateTeamlet();
        }
    
        //  Reset validation
        setValidated(true);

    };

    /**
    * @name showErrorMessage
    * @description  Error Pop-up
    * @param  
    * @returns 
    */
    function showErrorMessage(toggle : boolean) {
        setShowErrorModal(toggle);

        //  Reset Error messages
        if (!toggle){
            setErrorMessageTitle("")
            setErrorMessage("");
            setErrorServerMessage("");
            setErrorUserMessage("");
        }
    }
   
    /**
    * @name updateTeamlet
    * @description  Updates Teamlet Information
    * @param  
    * @returns 
    */
    async function updateTeamlet(){
        const _response = await fetch(`${config.activateServer}:${config.activatePORT}/updateTeamlet`, {
            method: 'POST',
            body: JSON.stringify({ 
                token : token,
                internalID : props.teamletID,
                name,
                organization : parentOrgName,
                organizationID : parentOrgID,
                pathwayList,
                memberList,
                homeHealthCenterID : siteID,
                homeHealthCenter : site,
            }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        }).then(response => {
            //console.log("GOT RESPONSE " + JSON.stringify(response));
            if(response.ok){
                try {
                    props.switchView(props.returnTo);
                } catch (err) {
                    setBadUserSubmissionError("Error: " + err);
                }
            } else {
                //  Error returned from data service, display to user
                response.json().then(data => {
                    //  Create Message
                    const _statusCode = response.status.toString()
                    const _statusmessage = JSON.stringify(data)

                    setErrorMessageTitle("Error Editing the Teamlet")
                    setErrorMessage("An error occurred saving the Teamlet [" + name + "]");
                    setErrorServerMessage(_statusmessage + " (Code: " + _statusCode + ")");
                    setErrorUserMessage("Please check the data you entered, and re-try.");
                                
                    //  Show Modal
                    showErrorMessage(true);
                
                    setBadUserSubmissionError(_statusmessage);
                })

                
            }
        })
    }

    //This function fires when a user selects an option in the lookup field
    function lookUpValueChanged( e : any){
        var val = e.value;
        //First we find the index in selectable members for who we are adding
        var index = selectableMembers.findIndex(function(item, i){
            return item.internalID === val;
        });
        //Now we remove that user form the selectable members to prevent duplicates
        //setSelectableMembers(selectableMembers.splice(index, 1));

        setTestValue("");
        let tempMemberList = memberList;
        //console.log("ADDING " + JSON.stringify(selectableMembers) + " COMPARING " + JSON.stringify(memberList));
        //Check if the user is already selected
        for(let i = 0; i < tempMemberList.length; i++) {
            if(memberList[i] && selectableMembers[index]){ 
                if(memberList[i].internalID === selectableMembers[index].internalID){
                    setBadUserSubmissionError("This user is already assigned to this Teamlet");
                    return;
                } else {
                    setBadUserSubmissionError("");
                }
            }
        }

        if(selectableMembers[index]){
            setBadUserSubmissionError("");
            //Check for either health provider or health coach
            if(selectableMembers[index].userRole.name === "Health Coach"){
                setHasHealthCoachCount(healthCoachCount+1);
            } else if (selectableMembers[index].userRole.name === "Provider"){
                setProviderCount(providerCount+1);
            }
            tempMemberList.push(selectableMembers[index]);
            setMemberList(tempMemberList);
            loadTeamletMembers(false);
        }
    }

    //Check if checkbox in the pathway list needs to be checked
    function checkIfChecked(pathwayName : string){
        //console.log("LOOPING PATHWAYS " + props.pathwayList);
        for(let j = 0; j < pathwayList.length; j++){
            //console.log("COMPARING " + props.pathwayList[j].name + " " + data[i].name);
            if(pathwayList[j].name === pathwayName){
                return true;
            }
        }
        return false;
    }

    function getDisplayName(item: any) {
        return item ? `${item.firstName} ${item.lastName} ${item.userRole.name}` : '';
    }

    return(
        <div>
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item active>Edit Teamlet</Breadcrumb.Item>
                </Breadcrumb>
                <div className="page-panel">
                

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                

                <Row>
                    <Col md={6} lg={4}>
                        <Form.Group className="form-group" controlId="name">
                            <Form.Label className="required">Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter a Teamlet name"
                                required 
                                onChange={e => setName(e.target.value)}
                                value={name}/>
                            <Form.Control.Feedback type="invalid">
                                Please enter a Teamlet name.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group" controlId='organization'>
                            <Form.Label className="required">Organization</Form.Label>
                            <Form.Select
                                aria-label="Organization"
                                name="primaryOrganization"
                                value={parentOrgName}
                                disabled={memberList.length > 0}
                                required
                                onChange={dropdownSelectChange}>
                                    <option></option>
                                    {organizationList}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                   Please select an Organization
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-group" controlId='site'>
                            <Form.Label className="required">Site</Form.Label>
                            <Form.Select
                                aria-label="Site"
                                name="primarySite"
                                value={site}
                                disabled={memberList.length > 0}
                                required
                                onChange={dropdownSelectChange}>
                                    <option></option>
                                    {siteList}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                   Please select a Site
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                    <Form.Group className="form-group" controlId='pathway'>
                            <Form.Label className="required">Digital Health Pathway</Form.Label>
                            {displayPathwayList.length > 0 ?  
                                <>{displayPathwayList}
                                {noPathwaySelected.length > 0 ? 
                                    <p className="validation-error">{noPathwaySelected}</p>
                                : <></> 
                                }</>
                            : <>
                                <br/>
                                <Form.Label className="font-face-activate-secondary-text">Please select an Organization for Digital Health Pathway options</Form.Label>
                            </> 
                        }
                        </Form.Group>
                    </Col>
                </Row>
                    <Form.Label>Teamlet Members</Form.Label>
                        <Table>
                            <thead>
                                <tr>
                                    {loadTeamletMemberHeaders()}
                                </tr>
                            </thead>
                            <tbody>
                                {displayMemberList}
                            </tbody>
                        </Table>

                        <Lookup
                            disabled={site === ""}
                            label="Add User"
                            noDataText='No Users found for the selected Organization'
                            items={selectableMembers}
                            displayExpr={getDisplayName}
                            valueExpr="internalID"
                            value={testValue}
                            onValueChanged={(e) => lookUpValueChanged(e)}
                            >
                            <DropDownOptions showTitle={false} />
                        </Lookup>
                        
                    {badUserSubmissionError.length > 0 ? 
                      <p className="validation-error">{badUserSubmissionError}</p>
                      : <></> 
                    }

                    <div className="form-actions">
                        <Button
                            className="system submit"
                            type="submit"
                            variant="primary"
                            id="submitButton">
                                Save
                        </Button>
                        <Button className='system cancel'
                            id="cancelButton"
                            variant="secondary"
                            type="button"
                            onClick={() => props.switchView(props.returnTo)}>
                            Cancel
                        </Button>
                    </div>
                </Form>
                </div>
            </Container>

            <Modal show={showWarningModal}>
                <Modal.Header className="system">
                    <h2>Confirm Teamlet Submission</h2>
                </Modal.Header>
                <Modal.Body>
                    Teamlets must have at least one member with the role of Health Coach and one member with the role of Provider
                    <br/><br/>
                    Do you still want to save this Teamlet?
                </Modal.Body>
                <Modal.Footer>
                    <Button className="system submit" variant="primary" onClick={() => updateTeamlet()}>Confirm</Button>
                    <Button className="system cancel" variant="secondary" onClick={() => setShowWarningModal(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showErrorModal}>
                <Modal.Header className="error">
                    <h2>{errorMessageTitle}</h2>
                </Modal.Header>
                <Modal.Body>
                    {errorMessage}<br/>
                    <br/><b>{errorServerMessage}</b><br/>
                    <br/>{errorUserMessage}<br/>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='error submit' onClick={() => setShowErrorModal(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
} 

export default EditTeamlet
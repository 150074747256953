/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form, Card, Navbar, Nav } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import RegistrationFooter from './RegistrationFooter';

const config = require('../../config.json');
const ACTIVATElogo = require('../../Resources/ACTIVATE_logo_small.png');

interface CustomLocation {
    status : string
}

function LockedAccount() {
    const location = useLocation().state as CustomLocation;
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [loginStatus, setLoginStatus] = useState("");

    useEffect(() => {
        if (location) {
            setLoginStatus(location.status);
        }

    }, [location]);

    useEffect(() => {
        //  Handle the Enter key
        const listener = async (event: { code: string; preventDefault: () => void; }) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                //  Enter selected for Forgot your password
               event.preventDefault();
            }
        };
    
        //  Wire-up keydown event
        document.addEventListener("keydown", listener);

        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    function changeValue(event: React.ChangeEvent<HTMLInputElement>){
        setUsername(event.target.value);
    }

    function changeLoginStatus(newStatus : string){
        setLoginStatus(newStatus);
    }
    
    async function promptPasswordReset(){
        //console.log(`FETCHING ${config.activateServer}:${config.activatePORT}/passwordReset`);
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/passwordReset`, {
            method: 'POST',
            body: JSON.stringify({ username : username }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        })

        try {
            const data = await response.json();
            changeLoginStatus("ResetLink");
        } catch(err){
            console.log("ERROR " + err);
        }
    }
    
    function returnToSignIn(){
        navigate('/login', {state: {}});
    }

    return(
        <div className="activate-background">
            <Navbar id="navContainer">
                <Container>
                    <Nav>
                        <img id="ACTIVATElogo" src={ACTIVATElogo} alt="ACTIVATElogo"></img>
                    </Nav>
                </Container>
            </Navbar>
            <Container className="d-flex justify-content-center">
                        {loginStatus === "ForgotPassword" ?
                            <Card className="login-card">
                                <Card.Body>
                                    <Card.Title>
                                        <h1>Hold on!</h1>
                                    </Card.Title>
                                    <p>It looks like you are having trouble remembering your password!</p>
                                    <Form.Group className="form-group">
                                        <p className="validation-error">Your account has been temporarily locked for your protection.</p>
                                    </Form.Group>
                                    <p>You must set a new password before you can continue.</p>
                                    <Form.Group className="login-actions">
                                        <Button
                                            className="submit-button"
                                            variant="primary"
                                            onClick={() => changeLoginStatus("EnterEmail")}>
                                                Reset Password
                                        </Button>
                                    </Form.Group>
                                    <RegistrationFooter/>
                                </Card.Body>
                            </Card>
                        : ""}
                        {loginStatus === "EnterEmail" ?
                        <Card className="login-card">
                            <Card.Body>
                                <Card.Title>
                                    <h1>Forgot your password?</h1>
                                    <p>Enter the username you use to sign in to 
                                        ACTIVATE and we'll send you a link to reset your password.</p>
                                </Card.Title>
                                <Form>
                                    <Form.Group className="form-group">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control
                                            id="username"
                                            data-testid="username"
                                            name="username"
                                            onChange={changeValue}
                                            value={username}
                                            placeholder="Enter your username" />
                                        {usernameError.length === 0 ?
                                        <Form.Text className="text-muted">
                                            {usernameError}
                                        </Form.Text>
                                        : ""}
                                    </Form.Group>
                                    <Form.Group className="login-actions">
                                        <Button
                                            className="submit-button"
                                            disabled={username.length <= 0}
                                            onClick={() => promptPasswordReset()}>
                                                Reset Password
                                        </Button>
                                    </Form.Group>

                                <Form.Group className="login-footer">
                                    <Button variant="link" onClick={() => returnToSignIn()}>Back to Sign In</Button>
                                </Form.Group>
                                </Form>
                                </Card.Body>
                        </Card>
                        : ""}
                        {loginStatus === "ResetLink" ?
                        <Card className="login-card">
                            <Card.Body>
                                <Card.Title>
                                    <h1>Reset link sent!</h1>
                                    <p>A link to reset your password has been sent to your email or smartphone.</p>
                                </Card.Title>

                                <p>Look for a message from ACTIVATE - it will contain a link that you can use to choose a new password.
                                    Once your new password has been saved, you can sign in with your username and new password.
                                </p>
                                <Form.Group className="login-actions">
                                    <Button className="submit-button" onClick={() => returnToSignIn()}>Back to Sign In</Button>
                                </Form.Group>
                                <RegistrationFooter/>
                            </Card.Body>
                        </Card>
                        : ""}
                        {loginStatus === "Blocked" ?
                        <Card className="login-card">
                            <Card.Body>
                                <Card.Title>
                                    <h1>Account locked!</h1>
                                </Card.Title>
                                <p>Too many failed attempts to sign in to this account.</p>
                                <Form.Group className="form-group">
                                    <p className="validation-error">Your account has been temporarily locked for your protection.</p>
                                </Form.Group>
                                <p>You must set a new password before you can continue.</p>
                                <Form.Group className="login-actions">
                                    <Button className="submit-button" onClick={() => changeLoginStatus("EnterEmail")}>Reset Password</Button>
                                </Form.Group>
                                <RegistrationFooter/>
                            </Card.Body>
                        </Card>
                        : ""}
            </Container>
        </div>
    )
} 

export default LockedAccount;
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState, useRef } from 'react';
import { Button, Container, Row, Col, Breadcrumb, Form, Modal } from 'react-bootstrap';
import RadioGroup from 'devextreme-react/radio-group';
import { EmailValidator } from 'commons-validator-js';
import './AddUsers.css';
import { UNSPECIFIED } from '../Shared/constants';

const config = require('../../config.json');

function AddUsers(props: { switchView: (newView: string) => void }) {
    const [validated, setValidated] = useState(false);
    const [role, setRole] = useState("Patient");
    const [userRoleList, setUserRoleList] = useState<any[]>([]);
    const [roleName, setRoleName] = useState<string | null>("");
    const [roleValue, setRoleValue] = useState<string | null>("");
    const [roleID, setRoleID] = useState<string | null>("");
    const [organizationList, setOrganizationList] = useState<any[]>([]);
    const [organizationListOptions, setOrganizationListOptions] = useState<any[]>([]);
    const [assignedOrganizations, setAssignedOrganizations] = useState<any>([]);
    const [assignedOrganizationsArray, setAssignedOrganizationsArray] = useState<any>([]);
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [prefix, setPrefix] = useState(UNSPECIFIED);
    const [suffix, setSuffix] = useState(UNSPECIFIED);
    const [preferredName, setPreferredName] = useState("");
    const [email, setEmail] = useState("");
    const [cellPhone, setCellPhone] = useState("");
    const [prefixList, setPrefixList] = useState<any[]>([]);
    const [suffixList, setSuffixList] = useState<any[]>([]);
    const [landlinePhone, setLandlinePhone] = useState("");
    const [badSubmissionError, setBadSubmissionError] = useState("");
    const [orgChecked, setOrgChecked] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isCellphoneValid, setIsCellphoneValid] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState("");
    //const [assignedTo, setAssignedTo] = useState("");
    
    const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()
        , PNF = require('google-libphonenumber').PhoneNumberFormat
        , PNT = require('google-libphonenumber').PhoneNumberType;


    useEffect(() => {
        loadPrefixes();
        loadSuffixes();
        loadRoles();
        loadOrganizations();
    }, []);

    async function loadPrefixes() {
        const token = sessionStorage.getItem('ACTIVATEtoken');

        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getValues`, {
            method: 'POST',
            body: JSON.stringify({ token: token, type: 'Prefix' }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(async (response: Response) => {
            //  Check for Success
            if (response.ok) {

                try {
                    const data = await response.json();
                    //console.log("GOT BACK PREFIXES " + JSON.stringify(data));
                    //Load the table here  
                    setPrefixList(data.map((i: any) => {
                        return (<option
                            key={i.value}
                            value={i.value}>
                            {i.name}
                        </option>);
                    }));
                } catch (err) {
                    console.log("ERROR " + err);
                }
            }
            else {
                // Handle non-200s
                console.log("Received HTTP status" + response.status.toString());
                handleException(response);
            }
        });
    }


    async function loadSuffixes() {
        const token = sessionStorage.getItem('ACTIVATEtoken');

        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getValues`, {
            method: 'POST',
            body: JSON.stringify({ token: token, type: 'Ordinal' }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(async (response: Response) => {
            //  Check for Success
            if (response.ok) {

                try {
                    const data = await response.json();
                    //console.log("GOT BACK SUFFIXES " + JSON.stringify(data));
                    //Load the table here
                    setSuffixList(data.map((i: any) => {
                        return (<option
                            key={i.value}
                            value={i.value}>
                            {i.name}
                        </option>);
                    }));
                } catch (err) {
                    console.log("ERROR " + err);
                }
            }
            else {
                // Handle non-200s
                console.log("Received HTTP status" + response.status.toString());
                handleException(response);
            }
        });
    }

    function dropdownSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();
        const name = event.target.name;
        if (name === "prefix") {
            setPrefix(event.target.value);
            return;
        }

        if (name === "suffix") {
            setSuffix(event.target.value);
            return;
        }

        if (name === "role") {
            const roleName = event.target.options[event.target.selectedIndex].getAttribute('data-name') ?
                event.target.options[event.target.selectedIndex].getAttribute('data-name') : "";
            const roleValue = event.target.options[event.target.selectedIndex].getAttribute('data-value') ?
                event.target.options[event.target.selectedIndex].getAttribute('data-value') : "";
            const roleID = event.target.options[event.target.selectedIndex].getAttribute('data-id') ?
                event.target.options[event.target.selectedIndex].getAttribute('data-id') : "";
            setRoleName(roleName);
            setRoleValue(roleValue);
            setRoleID(roleID);
        }
    }

    //This function temporarily only loads the patient role
    async function loadRoles() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/getRoleTemplates`, {
            method: 'POST',
            body: JSON.stringify({ token: token }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(async (response: Response) => {
            //  Check for Success
            if (response.ok) {


                try {
                    const data = await response.json();
                    const roleList: any[] = [];
                    for (let i = 0; i < data.length; i++) {
                        //console.log("LOADING TEMPLATES " + i + " " + JSON.stringify(data[i]));
                        if (data[i].name === 'Patient') {
                            //Set default values for Patient
                            setRoleName(data[i].name);
                            setRoleID(data[i].internalID);

                            roleList.push(
                                <option
                                    key={i}
                                    data-name={data[i].userrole.name}
                                    data-value={data[i].userrole.value}
                                    data-id={data[i].internalID}>
                                    {data[i].name}
                                </option>
                            );
                        }
                    }
                    setUserRoleList(roleList);

                } catch (err) {
                    console.log("ERROR " + err);
                    handleException(response);
                }

            } else {
                // Handle non-200s
                console.log("Received HTTP status" + response.status.toString());
                handleException(response);
            }
        });
    }

    async function loadOrganizations() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const _response = await fetch(`${config.activateServer}:${config.activatePORT}/getHealthCenters`, {
            method: 'POST',
            body: JSON.stringify({ token: token }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(async (response: Response) => {
            //  Check for Success
            if (response.ok) {

                try {
                    const data = await response.json();
                    setOrganizationListOptions(data);
                } catch (err) {
                    console.log("ERROR " + err);
                    handleException(response);
                }
            }
            else {
                // Handle non-200s
                console.log("Received HTTP status" + response.status.toString());
                handleException(response);
            }
        });
    }

    const validPhoneNumber = (e: any) => {
        // https://www.ronaldjamesgroup.com/blog/phone-numbers-in-javascript-using-e164-libphonenumber-and-microdata
        // https://www.npmjs.com/package/google-libphonenumber
        let isValid = true;

        try {
            //console.log(phoneUtil.format(tel, PNF.INTERNATIONAL));
            //console.log(phoneUtil.format(tel, PNF.NATIONAL));
            //console.log(phoneUtil.format(tel, PNF.E164));
            isValid = phoneUtil.isValidNumber(phoneUtil.parse(e, 'US'));
        } catch (e) {
            isValid = false;
        }
        setBadSubmissionError('');
        return isValid;
    };

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        setBadSubmissionError('');
        const validator = new EmailValidator();
        const elements = form.elements;
        let isCellphoneValid = true;
        let isEmailValid = true;
        let isOrgAssignedTo = true;
        const cellphoneElement = elements.namedItem('cellPhone');
        const emailElement = elements.namedItem('emailAddress');

        //  Loop through form elements
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];

            //  Check Assigned Orgs List
            if (assignedOrganizations.length === 0) {
                isOrgAssignedTo = false;
                setBadSubmissionError("Please select one Organization for the User");
            }

            if (element.type === "text" && element.id === 'emailAddress') {
                //  Let blanks display normal error message
                if (element.value === '' && validPhoneNumber(cellphoneElement.value) === true) {
                    element.classList.remove('is-invalid');
                    element.required = false;
                }
                if (element.value !== '') {
                    if (validator.isValid(email) === false) {
                        isEmailValid = false;
                        element.classList.add('is-invalid');
                        element.required = true;
                        setIsEmailValid(false);
                    } else {
                        setIsEmailValid(true);
                        element.required = false;
                        element.classList.remove('is-invalid');
                    }
                }
            }

            if (element.type === "text" && element.id === 'cellPhone') {
                if (validator.isValid(emailElement.value) === true && element.value === '') {
                    element.classList.remove('is-invalid');
                    element.required = false;
                }

                if (element.value !== '') {
                    if (validPhoneNumber(cellPhone) === false) {
                        isCellphoneValid = false;
                        element.classList.add('is-invalid');
                        element.required = true;
                        element.nextElementSibling.innerHTML = "Please enter the user cell Phone Number (xxx) xxx-xxxx.";
                    } else {
                        element.classList.remove('is-invalid');
                        element.required = false;
                        element.nextElementSibling.innerHTML = '   ';
                    }
                }
            }

            if (element.type === "text" && element.id === 'landlinePhone') {
                if (element.value !== '' && validPhoneNumber(landlinePhone) === false) {
                    element.classList.add('is-invalid');
                } else {
                    element.classList.remove('is-invalid');
                }
            }
        }

        if (!isEmailValid || !isCellphoneValid || !isOrgAssignedTo) {
            event.stopPropagation();
            setValidated(true);
            return;
        }
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }
        addNewUser();
    };

    
    async function addNewUser() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        const roleTemplate = roleID;
        let posturl;
        
        //  Checking for User or Patient
        if (roleName === 'Patient') { 
            posturl = `${config.activateServer}:${config.activatePORT}/postNewPatient`;
        }
        else { 
            posturl = `${config.activateServer}:${config.activatePORT}/postNewUser`;
        }

        const response = await fetch(posturl, {
            method: 'POST',
            body: JSON.stringify({
                token: token,
                username: '',
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                preferredName,
                email,
                cellPhone,
                landlinePhone,
                roleTemplate,
                assignedOrganizations
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'cors'
        }).then(async (r: Response) => {
            
            //  Check for Success
            if (r.ok) {
                try {
                    props.switchView('users');
                } catch (err) {
                    handleException(r);
                    setBadSubmissionError("Error: " + err);
                }
            }
            else {
                // Handle non-200s
                console.log("Received HTTP status" + r.status.toString());
                handleException(r);
            }
        });
    }

    /**
    * @name handleException
    * @description  Handle non-200 errors
    * @param  
    * @returns 
    */
    async function handleException(_resp: Response) {
        const error = await _resp.text();
        const parsedError= JSON.parse(error);

        let msg = "";
        msg += parsedError.message;

        //  Exception
        setShowErrorModal(true);
        setShowErrorMsg(msg);
    }

    /**
     * @name changeSelectedAssignTo
     * @description  Handles the radio button change event
     * @param  
     * @returns 
    */
    function changeSelectedAssignTo(e: any) {
        //  Service requires an array
        const _orgToAssign = [];
        _orgToAssign.push(e.value);
  
        //  Set State on Radio Buttons
        setAssignedOrganizationsArray(e.value);
        // Send to Service
        setAssignedOrganizations(_orgToAssign);
        //  Reset Error
        setBadSubmissionError('');
    }

    return (

        <div>
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => props.switchView("configuration")}>Configuration</Breadcrumb.Item>
                    <Breadcrumb.Item active>Add User</Breadcrumb.Item>
                </Breadcrumb>
                <div className="page-panel">
                

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col md={6}>
                        <Form.Group className="form-group" controlId="role">
                            <Form.Label className="required">Role</Form.Label>
                            <Form.Select
                                aria-label="Role"
                                name="role"
                                value={role}
                                onChange={e => e.target.value}
                                required>
                                {userRoleList}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Please select a role for this profile.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group" controlId="organizationOptions">
                            <Form.Label className="required">Assigned To</Form.Label>
                            <RadioGroup id="radio-group-with-selection" 
                                items={organizationListOptions}
                                value={assignedOrganizationsArray} 
                                valueExpr="internalID" displayExpr="name" 
                                onValueChanged={changeSelectedAssignTo} />
                        </Form.Group>
                        {badSubmissionError.length > 0 ? 
                            <p className="validation-error">{badSubmissionError}</p>
                            : <></> 
                        }
                    </Col>

                        <Col md={6}>
                        <Form.Group className="form-group" controlId="firstName">
                                <Form.Label className="required">First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter first name"
                                    required
                                    onChange={e => setFirstName(e.target.value)}
                                    value={firstName} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your first name.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="form-group" controlId="firstName">
                                    <Form.Label>Middle Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter middle name"
                                        onChange={e => setMiddleName(e.target.value)}
                                        value={middleName} />
                            </Form.Group>

                            <Form.Group className="form-group" controlId="lastName">
                                <Form.Label className="required">Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter last name"
                                    required
                                    onChange={e => setLastName(e.target.value)}
                                    value={lastName} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your last name.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="form-group" controlId="prefix">
                                <Form.Label>Prefix</Form.Label>
                                <Form.Select
                                    aria-label="Prefix"
                                    name="prefix"
                                    value={prefix}
                                    onChange={dropdownSelectChange}>
                                    <option>Select</option>
                                    {prefixList}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="form-group" controlId="suffix">
                                <Form.Label>Suffix</Form.Label>
                                <Form.Select
                                    aria-label="Suffix"
                                    name="suffix"
                                    value={suffix}
                                    onChange={dropdownSelectChange}>
                                    <option>Select</option>
                                    {suffixList}
                                </Form.Select>
                            </Form.Group>

                        <Form.Group className="form-group" controlId="preferredName">
                            <Form.Label>Preferred Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter preferred name"
                                value={preferredName}
                                onChange={e => setPreferredName(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="form-group" controlId="emailAddress">
                            <Form.Label className="required">ACTIVATE Email Address</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter email address"
                                value={email}
                                required
                                onChange={e => { setEmail(e.target.value); }} />
                            <Form.Control.Feedback type="invalid">
                                Please enter either a valid email address or cell phone number
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group" controlId="cellPhone">
                            <Form.Label className="required">Cell Phone</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter cell phone"
                                required
                                value={cellPhone}
                                onChange={e => setCellPhone(e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter either a valid email address or cell phone number
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group" controlId="landlinePhone">
                            <Form.Label>{roleName == 'Patient' ? 'Home Phone' :'Landline'} </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter landline phone"
                                value={landlinePhone}
                                onChange={e => setLandlinePhone(e.target.value)} />
                        </Form.Group>
                        </Col>

                    </Row>
                    <div className="form-actions">
                        <Button
                            className="system submit"
                            type="submit"
                            variant="primary"
                            id="submitButton">
                                Save
                        </Button>
                        <Button className='system cancel'
                            id="cancelButton"
                            variant="secondary"
                            type="button"
                            onClick={() => props.switchView("users")}>
                            Cancel
                        </Button>
                    </div>
                    <Modal show={showErrorModal}>
                        <Modal.Header className="error">
                            <h2>Database error</h2>
                        </Modal.Header>
                        <Modal.Body>
                            {showErrorMsg}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="error submit" onClick={() => setShowErrorModal(false)}>Ok</Button>
                        </Modal.Footer>
                    </Modal>
                </Form>
              </div>
            </Container>
        </div>
    );
}

export default AddUsers;
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState, useEffect } from 'react';
import { Container, Button, Modal, Breadcrumb } from 'react-bootstrap';
import { DataGrid, Selection, Column, Scrolling, Paging} from 'devextreme-react/data-grid';
import {Button as DevButton} from 'devextreme-react/data-grid';
const config = require('../../../config.json');

function PatientRegistrationView(props: {
  switchView: (newView: string) => void
}) {
  const [showDeletePatientRegistration, setShowDeletePatientRegistration] = useState(false);
  const [registeredPatients, setRegisteredPatients] = useState<any[]>([]);
  const [registeredPatientToDeleteId, setRegisteredPatientToDeleteId] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessageTitle, setErrorMessageTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorServerMessage, setErrorServerMessage] = useState("");
  const [errorUserMessage, setErrorUserMessage] = useState("");

  useEffect(() => {
    loadPatientRegistrations();
  }, []);

 /**
    * @name loadPatientRegistrations
    * @description Load all of the Patients being registered
 */
  async function loadPatientRegistrations() {
    const token = sessionStorage.getItem('ACTIVATEtoken');
    
    await fetch(`${config.activateServer}:${config.activatePORT}/getRegisteredPatients`, {
        method: 'POST',
        body: JSON.stringify({ token : token }),
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        mode : 'cors'
    }).then(async response => {
        //  Check for Success
        if (response.ok)
        {
            try {
                const _json = await response.json();
                setRegisteredPatients(_json);
            } catch (err) {
                console.log("ERROR " + err);
            }
        } else {
            // ToDo:  Handle non-200s
            console.log("Received HTTP status" + response.status.toString());
        }
    }).catch(err =>{
        console.log("Error in getting unregistered patients: " + err);
    });
  }

/**
  * @name determineVisible
  * @description Hides/Shows based on Status
  * @param string 
  * @returns 
*/
function determineVisible(e: any) {
  if (e.row.data.status === "Complete") {
      return false;
  }
  return true;
}

/**
 * trashClick
 * @param e 
 */
async function trashClick(e: any) {
    //  Set Registered Patient ID to delete
    if (e.row.data !== null){
        setRegisteredPatientToDeleteId(e.row.data.internalID);
        setShowDeletePatientRegistration(true);
    }
}

//  Format Huddle Date
function getExpirationDateTimeFormated(e:any){
    return (
        <>
            {new Date(e.data.expires).toDateString()} {new Date(e.data.expires).toLocaleTimeString()}
        </>
    )
}

/**
 * deleteRegistration
 */
async function deleteRegistration() {
    const token = sessionStorage.getItem('ACTIVATEtoken');

    //  Hide Delete Modal
    setShowDeletePatientRegistration(false);

    await fetch(`${config.activateServer}:${config.activatePORT}/unregisterPatient`, {
        method: 'POST',
        body: JSON.stringify({ token : token, patientID: registeredPatientToDeleteId }),
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        mode : 'cors'
    }).then(async response => {
        //  Check for Success
        if (response.ok)
        {
            try {
                const _json = await response.json();
                //  Check for success
                loadPatientRegistrations();
            } catch (err) {
                console.log("ERROR " + err);
            }
        } else {
            //  Error returned from data service, display to user
            response.json().then((data : any) => {            
                //  Create Message
                const _statusCode = response.status.toString();
                const _statusmessage = data !== null ? data.message : "Error Deleting the User registration.";
    
                //  Create Error Message
                setErrorMessageTitle("Error Deleting User Registration")
                setErrorMessage("An error occurred while deleting the registration.");
                setErrorServerMessage(_statusmessage + " (Code: " + _statusCode + ")");
                setErrorUserMessage("");
                            
                //  Show Modal
                setShowErrorModal(true);
            })
        }
    }).catch(err =>{
        console.log("Error in getting unregistered patients: " + err);
    });
  }

  return (
    <div>
      <Container fluid>
        <Breadcrumb>
            <Breadcrumb.Item onClick={() => props.switchView("configuration")}>Configuration</Breadcrumb.Item>
            <Breadcrumb.Item active>Patient Registration</Breadcrumb.Item>
        </Breadcrumb>

          <div className="page-panel">
              <div className="panel-function-header">
                  <Button className="btn-function-button"
                      variant="function"
                      onClick={() => props.switchView("patientRegistrationSelect")}>Register Patient
                   </Button>
              </div>
              
              <DataGrid id="grid-container"
                showColumnLines={false}
                showRowLines={false}
                width={"100%"}
                showBorders={true}
                focusStateEnabled={true}
                dataSource={registeredPatients}
                allowColumnResizing={true}
                noDataText='No patients are currently being registered'>
                    <Scrolling rowRenderingMode='virtual'></Scrolling>
                    <Paging defaultPageSize={10} defaultPageIndex={0} />
                    <Selection mode="none" />
                    <Column dataField="name" width={'20%'} caption="Name" />
                    <Column dataField="emailAddress" width={'10%'} caption="Email Address" />
                    <Column dataField="role" width={'10%'} caption="Role" />
                    <Column dataField="organization" width={'10%'} caption="Organization" />
                    <Column dataField="status" width={'10%'} caption="Status" />
                    <Column dataField="expires" width={'30%'} dataType="date" caption="Expires" cellRender={getExpirationDateTimeFormated} />
                    <Column type="buttons" caption="Action" width={'10%'} alignment={"center"}>
                        <DevButton hint="delete" visible={determineVisible} icon="trash" onClick={trashClick} />
                    </Column>
                    <Column dataField="internalID" caption="Id" visible={false} />
              </DataGrid>
          </div>
      </Container>

      <Modal show={showDeletePatientRegistration}>
          <Modal.Header className="error">
              <h2>Delete Patient Registration</h2>
          </Modal.Header>
          <Modal.Body>
            <b>Are you sure you want to delete this patient registration?</b> <br/><br/>The patient will no longer be able to register for a MyACTIVATE account.
          </Modal.Body>
          <Modal.Footer>
              <Button className="error submit" onClick={() => deleteRegistration() }>Delete</Button>
              <Button className="error cancel" onClick={() => setShowDeletePatientRegistration(false)}>Cancel</Button>
          </Modal.Footer>
      </Modal>

      <Modal show={showErrorModal}>
        <Modal.Header className="error">
            <h2>{errorMessageTitle}</h2>
        </Modal.Header>
        <Modal.Body>
        {errorMessage}<br/>
        <br/><b>{errorServerMessage}</b><br/>
        <br/>{errorUserMessage}<br/>
        </Modal.Body>
        <Modal.Footer>
        <Button className='error submit' onClick={() => setShowErrorModal(false)}>Ok</Button>
        </Modal.Footer>
    </Modal>
    </div>
  );
}

export default PatientRegistrationView;
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { Anchor, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataGrid, { Column, GroupPanel, Pager, Paging, Grouping } from 'devextreme-react/data-grid';
import PermissionService from '../../../Services/PermissionService';
import { ALLPATIENTS, MYPATIENTS, PULSERATE, DIASTOLIC, SYSTOLIC, BLOODGLUCOSE, BLOODPRESSURE } from '../constants';
import { ModalErrorMessage } from '../MessageBox/ModalErrorMessage';
import CustomStore from 'devextreme/data/custom_store';
import { LoadPanel } from 'devextreme-react/load-panel';

const config = require("../../../config.json");

interface TooltipOBJ {
    boundary: string,
    tempstatus: string,
    aboveorbelow: string
}

/* 
 * DataReadingsGrid is called from DataReadingsManager
 * props include noreadings and timeRange (week/twoweeks/month)
 * and the function setSelectedPatientId so the patients link back to the PatientInformation page
*/
function DataReadingsGrid(props:
    {
        permissions: PermissionService,
        switchView: (view: string) => void
        noReadings: boolean
        timeRange: string
        setSelectedPatientId: (patientID: string) => void
        showSpinner: boolean
    }) {

    // page size currently defaults to 10.  This is in place in case requirements change.
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [toggleAssignedPatients, setToggleAssignedPatients] = useState(false);
    const [toggleAllPatients, setToggleAllPatients] = useState(true);
    const [readingsData, setReadingsData] = useState<CustomStore>(new CustomStore());
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [viewMode, setViewMode] = useState(ALLPATIENTS);
    const token = sessionStorage.getItem('ACTIVATEtoken');
   
    //  Location
    const position = { of: '#patientInfo' };

    // This is called initially and then when the timerange, noreadings value or page number changes
    useEffect(() => {
        //  Abort controller to close long fetch calls
        const dataReadingsController = new AbortController();
        
        fetchGridData(dataReadingsController).catch(console.error);

        //  Clean-up useEffect
        return () => {
            dataReadingsController.abort();
        };
    }, [props.timeRange, props.noReadings, pageIndex, viewMode]);

    const fetchGridData = async (dataReadingsController: AbortController) => {
        const thresholds = props.noReadings === true ? 'noreadings' : 'exceedthresholds';
        try {
            const customDataSource = await new CustomStore({
                key: 'internalID',
                load(loadOptions) {
                    return fetch(`${config.activateServer}:${config.activatePORT}/getDataReadingsDetails`, {signal: dataReadingsController.signal,
                        method: 'POST',
                        body: JSON.stringify({ token: token, patients: viewMode.toLowerCase(), thresholds: thresholds, timerange: props.timeRange, pageno: pageIndex, pagesize: pageSize }),
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        },
                        mode: 'cors'
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw Error('Could not fetch the patient');
                            }
                            return response.json();
                        })
                        .then((data) => (
                            {
                                data: formatReadingsData(data.content),
                                totalCount: data.totalElements,
                                summary: [],
                                groupCount: []
                            }
                        ))
                        .catch((e) => {
                            // setReadingsData(new CustomStore());
                            dataReadingsController.abort();
                            throw new Error('Data Loading Patients ' + e);
                        });
                },
            });
            // console.log('NEW READING ' + JSON.stringify(customDataSource));
            setReadingsData(customDataSource);
        } catch (err) {
            setShowErrorModal(true);
        }
        // call the function
    };

    /**
     * @name formatReadingsData
     * @description  We need to combine the systolic and diastolic readings into one
     * @param  
     * @returns 
    */
    function formatReadingsData(currentContent: any) {
        const newContent = currentContent;
        let hasBloodReading = false;
        let systolicReading;
        let diastolicReading;
        let systolicStatus = "WITHIN";
        let diastolicStatus = "WITHIN";
        let systolicHigh;
        let systolicLow;
        let diastolicHigh;
        let diastolicLow;
        let newColumnStatus = 'WITHIN';
        let outlierCount = 0;
        let newReadingTime;
        let overlayMessage = '';
        let readingUnit;
        let thresholdSource;
        for (let i = 0; i < currentContent.length; i++) {
            // First obtain the values needed for the new column
            hasBloodReading = false;
            for (let j = 0; j < currentContent[i].values.length; j++) {
                if (currentContent[i].values[j].readingName === 'Diastolic') {
                    if (currentContent[i].values[j].readingValues.length > 0) {
                        diastolicReading = currentContent[i].values[j].readingValues[0].reading;
                        diastolicStatus = currentContent[i].values[j].readingValues[0].thresholdStatus;
                        diastolicHigh = currentContent[i].values[j].readingValues[0].upperThreshold;
                        diastolicLow = currentContent[i].values[j].readingValues[0].lowerThreshold;
                        thresholdSource = currentContent[i].values[j].readingValues[0].thresholdSource;
                        newReadingTime = currentContent[i].values[j].readingTime;
                    }
                    readingUnit = currentContent[i].values[j].readingUnits;
                    hasBloodReading = true;
                    newContent[i].values.splice(j, 1);
                } else if (currentContent[i].values[j].readingName === 'Systolic') {
                    if (currentContent[i].values[j].readingValues.length > 0) {
                        systolicReading = currentContent[i].values[j].readingValues[0].reading;
                        systolicStatus = currentContent[i].values[j].readingValues[0].thresholdStatus;
                        newReadingTime = currentContent[i].values[j].readingValues[0].readingTime;
                        systolicHigh = currentContent[i].values[j].readingValues[0].upperThreshold;
                        systolicLow = currentContent[i].values[j].readingValues[0].lowerThreshold;
                        thresholdSource = currentContent[i].values[j].readingValues[0].thresholdSource;
                        newReadingTime = currentContent[i].values[j].readingTime;
                    }
                    readingUnit = currentContent[i].values[j].readingUnits;
                    hasBloodReading = true;
                    newContent[i].values.splice(j, 1);
                } else {
                    if (currentContent[i].values[j].readingValues.length > 0) {
                        const status = currentContent[i].values[j].readingValues[0].thresholdStatus;
                        const readingValue = status === "ABOVE" ? '> ' + currentContent[i].values[j].readingValues[0].upperThreshold : '< ' + currentContent[i].values[j].readingValues[0].lowerThreshold;
                        const source = currentContent[i].values[j].readingValues[0].thresholdSource;
                        newContent[i].values[j].readingValues[0]['tooltipMessage'] = `${status === "ABOVE" ? 'Above' : 'Below'} ${status === "ABOVE" ? "high" : "low"} ${source} threshold of ${readingValue}`;
                    } /* else {
                        newContent[i].values[j].readingValues[0]['tooltipMessage'] = `No readings yet.`;
                    } */
                }
            }
            // Now with the values we need to determine the column status.
            // NOTE! In concept there could be a ABOVE and BELOW status but was decided the odds were to unlikely so for now it is not addressed.
            if (systolicStatus === "ABOVE") {
                newColumnStatus = "ABOVE";
                outlierCount = 1;
                overlayMessage += `Above Systolic high ${thresholdSource} threshold of > ${systolicHigh}\n`;
            } else if (systolicStatus === "BELOW") {
                newColumnStatus = "BELOW";
                outlierCount = 1;
                overlayMessage += `Below Systolic low ${thresholdSource} threshold of < ${systolicLow}\n`;
            }

            if (diastolicStatus === "ABOVE") {
                newColumnStatus = "ABOVE";
                outlierCount = 1;
                overlayMessage += `Above Diastolic high ${thresholdSource} threshold of > ${diastolicHigh}\n`;
            } else if (diastolicStatus === "BELOW") {
                newColumnStatus = "BELOW";
                outlierCount = 1;
                overlayMessage += `Below Diastolic low ${thresholdSource} threshold of < ${diastolicLow}\n`;
            } else {
                if (systolicStatus === "WITHIN" && diastolicStatus === "WITHIN") {
                    overlayMessage = 'Both Diastolic and Systolic readings within threshold';
                }
            }

            if (hasBloodReading) {
                // Now we set and add the new value
                newContent[i].values.push(
                    {
                        readingTime: newReadingTime,
                        readingName: BLOODPRESSURE,
                        readingValues: [
                            {
                                reading: `${systolicReading}/${diastolicReading}`,
                                thresholdStatus: newColumnStatus,
                                lowerThreshold: 60,
                                upperThreshold: 90,
                                thresholdSource: thresholdSource,
                                tooltipMessage: overlayMessage
                            }
                        ],
                        readingUnits: readingUnit,
                        outlierCount: outlierCount,
                        totalCount: 1
                    }
                );
            }
            overlayMessage = '';
        }
        return newContent;
    }

    /**
     * @name getAssignedPatients
     * @description  My Patients toggle button click event  
     * @param  
     * @returns 
    */
    function getAssignedPatients() {
        //  Set button state
        setToggleAssignedPatients(true);
        setToggleAllPatients(false);
        //  Set the view mode - My Patients
        setViewMode(MYPATIENTS);
        setPageIndex(0);
    }

    /**
     * @name getAllPatients
     * @description  All Patients toggle button click event  
     * @param  
     * @returns 
    */
    function getAllPatients() {
        //  Set button state
        setToggleAllPatients(true);
        setToggleAssignedPatients(false);
        //  Set the view mode - All Patients
        setViewMode(ALLPATIENTS);
        setPageIndex(0);
    }

    /**
     * @name renderPatientLink
     * @description  Patient Panel Name column render
     * @param  
     * @returns 
    */
    function renderPatientLink(e: any) {
        return (<Anchor onClick={() => viewPatientDetails(e.data)}>{e.value}</Anchor>);
    }

    /**
    * @name viewPatientDetails
    * @description View Patient Information from hyperlink onClick handler
    * @param
    * @returns {json}
    */
    function viewPatientDetails(data: any) {
        const patientID = data.internalID;

        //  Check for found Patient
        if (data != null) {
            props.setSelectedPatientId(patientID);
            props.switchView("patientInformation");
        }
    }

    /**
     * @name pageIndexChange
     * @description  Grid PageIndex change event
     * @param  
     * @returns 
    */
    function pageIndexChange(e: any) {
        setPageIndex(e);
    }


    //  calls formatLastData to take the array of dates returned by the service and format the display for the grid
    const displayLastData = (e: any) => {
        return (
            <>
                {e.data.values === null || e.data.values === undefined || e.data.values.length === 0 ?
                    <p></p>
                    :
                    <>
                        {formatLastData(e).map((item: any, index: number) => {
                            return (<p key={index}>{item}</p>);
                        }
                        )}
                    </>}
            </>
        );
    };

    // called by displayLastData; formats the dates for  returned by the service
    const formatLastData = (e: any) => {
        const lastdataarray = e.data.values === null || e.data.values === undefined || e.data.values.length === 0 ?
            []
            :
            e.data.values.map((item: any, index: number) => {
                const readingDate = new Date(item.readingTime);
                const date = readingDate.getDate().toString().padStart(2, '0');
                const month = readingDate.getMonth() + 1;
                const year = readingDate.getFullYear();
                const displaymonth = month.toString().padStart(2, '0');
                const occurrenceDate = `${displaymonth}/${date}/${year}`;
                const occurrenceTime = new Date(readingDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toLowerCase();
                const displayDate = `${occurrenceDate} ${occurrenceTime}`;
                if (item.readingTime === '' || item.readingTime === undefined) { return <p key={index}></p>; }
                else { return (displayDate); }

            });

        return lastdataarray;
    };

    //  calls formatReadingNames to take the array of readings returned by the service and format the display for the grid
    const displayReadingName = (e: any) => {
        return (
            <>
                {e.data.values === null || e.data.values === undefined || e.data.values.length === 0 ?
                    <p></p>
                    :
                    <>
                        {formatReadingNames(e).map((item: any, index: number) => {
                            return (<p key={index}>{item}</p>);
                        }
                        )}
                    </>}
            </>
        );
    };

    // called by displayReadingName; formats the dates for  returned by the service
    const formatReadingNames = (e: any) => {

        const namesarray = e.data.values === null || e.data.values === undefined || e.data.values.length === 0 ?
            []
            :
            e.data.values.map((item: any, index: number) => {
                if (item.readingName === SYSTOLIC) { return "Blood Pressure"; }
                if (item.readingName === PULSERATE) { return "Heart Rate"; }
                if (item.readingName === BLOODGLUCOSE) {return "Blood Glucose"; }
                if (item.readingName !== DIASTOLIC) { return (item.readingName); }
                return '';
            });

        return namesarray;
    };

    // generic function to determine if the object is empty
    // used to determine if the reading values or tooltips are empty
    function isEmpty(obj: any) {
        for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }
        return true;
    }

    // This function is called from displayReadingReading and from displayReadingUnits
    // params are the readingValues array.  There is a readingValues array for each type of reading
    function getTooltipInfo(readingValues: any) {
        if (readingValues[0]) {
            return readingValues[0].tooltipMessage;
        }
    }

    //  calls formatReadingValues to take the array of readings returned by the service and format the display for the grid
    const displayReadingReading = (e: any) => {
        return (
            e.data.values === null || e.data.values === undefined || e.data.values.length === 0 ?
                <p></p>
                :
                <>
                    {formatReadingValues(e).map((item: any, index: number) => {
                        const tooltip = getTooltipInfo(e.data.values[index].readingValues);

                        if (isEmpty(tooltip)) {
                            return (
                                <div
                                    key={index}> <p>{item}</p>
                                </div>);
                        } else {
                            const tooltipText = `${tooltip} ${e.data.values[index].readingUnits}`;
                            return (
                                <OverlayTrigger
                                    key={index}
                                    overlay={(<Tooltip id="readingsover">{tooltipText} </Tooltip>)}
                                    placement="top-end">
                                    <div> <p key={index}>{item}</p></div>
                                </OverlayTrigger>
                            );
                        }
                    })}
                </>
        );
    };

    // This function is called from displayReadingReading   
    // params are the readingValues array.  There is a readingValues array for each type of reading
    const formatReadingValues = (e: any) => {
        let bloodpressure='';
        let capturedSYSTOLIC = false;
        let capturedDIASTOLIC = false;

        const valuesarray = e === null || e === undefined || e.length === 0 ?
            []
            :
            e.data.values.map((item: any, index: number) => {
                switch (item.readingName) {
                    case BLOODGLUCOSE:
                        if (item.readingValues.length === 0) {
                            return ("n/a");
                        } else {
                            return (item.readingValues[0].reading);
                        }
                    case DIASTOLIC:
                        if (item.readingValues.length > 0) {
                            bloodpressure===''? bloodpressure=item.readingValues[0].reading: bloodpressure=`${bloodpressure}/${item.readingValues[0].reading}`;
                        } else {
                            bloodpressure===''? bloodpressure='0': bloodpressure=`${bloodpressure}/0`;
                        }

                        capturedDIASTOLIC = true;
                      break;
                    case SYSTOLIC:
                        if (item.readingValues.length > 0) {
                            bloodpressure===''? bloodpressure=item.readingValues[0].reading: bloodpressure=`${bloodpressure}/${item.readingValues[0].reading}`;
                        } else {
                            bloodpressure===''? bloodpressure='0': bloodpressure=`0/${bloodpressure}`;
                        }

                        capturedSYSTOLIC = true;
                        break;
                    case PULSERATE:
                        if (item.readingValues.length === 0) {
                            return ("n/a");
                        } else {
                            return (item.readingValues[0].reading);
                        }
                    case BLOODPRESSURE:
                        return (item.readingValues[0].reading);
                    default:
                      // code block
                  }

                  //    Captured BP values
                  if (capturedSYSTOLIC && capturedDIASTOLIC) {
                    return (bloodpressure);
                  }
            });

        return valuesarray;
    };

    // This function is called from displayReadingReading   
    // params are the readingValues array.  There is a readingValues array for each type of reading
    const formatReadingValues2 = (e: any) => {
        let bloodpressure='';
        const valuesarray = e === null || e === undefined || e.length === 0 ?
            []
            :
            e.data.values.map((item: any, index: number) => {
                // console.log(item);

                // bloodpressure is the result of SYSTOLIC/DIASTOLIC readings which comes from two separate
                // readingValues arrays.  bloodpressure is set at the beginning before the function reads
                // through the array of data values.  If there is no readingValues array, this indicates a new
                // device that has no readings yet.  If the type is "Diastolic", there is already and entry for
                // Systolic so it doesn't need a separate "N/a" on the grid.
                if (item.readingValues.length === 0 && item.readingName !==DIASTOLIC) {
                    return ("n/a");
                }
                
                if (item.readingValues.length > 0) {
                    // If this isn't a blood pressure reading, just return the reading value
                    if (item.readingName !== DIASTOLIC && item !== SYSTOLIC) {
                        return (item.readingValues[0].reading);
                    } else {
                        // In case these are ordered alphabetically, the DIASTOLIC reading would come first
                        // otherwise the SYSTOLIC reading might come first                       
                        if (item.readingName === SYSTOLIC) {
                            bloodpressure===''? bloodpressure=item.readingValues[0].reading : bloodpressure=`${item.readingValues[0].reading}/${bloodpressure}`;
                        }
                        if (item.readingName === DIASTOLIC) {
                            bloodpressure===''? bloodpressure=item.readingValues[0].reading: bloodpressure=`${bloodpressure}/${item.readingValues[0].reading}`;
                        }
                    }
                }
            });

        return valuesarray;
    };

    function toCamelCase(str: string) {
        const formatted = str.toLowerCase()
            // Replaces any - or _ characters with a space 
            .replace(/[-_]+/g, ' ')
            // Removes any non alphanumeric characters 
            .replace(/[^\w\s]/g, '')
            // Uppercases the first character in each group immediately following a space 
            // (delimited by spaces) 
            .replace(/ (.)/g, function ($1) { return $1; })
            // Removes spaces 
            .replace(/ /g, '');
        return formatted.substring(0, 1) + formatted.substring(1);
    }

    //  calls formatReadingUnits to take the array of readings returned by the service and format the display for the grid
    const displayReadingUnits = (e: any) => {

        return (
            <>
                {e.data.values === null || e.data.values === undefined || e.data.values.length === 0 ?
                    <p></p>
                    :
                    <>
                        {formatReadingUnits(e).map((item: any, index: number) => {
                            const tooltip = getTooltipInfo(e.data.values[index].readingValues);
                            if (isEmpty(tooltip)) {
                                return (
                                    <div key={index} className='emptyTooltip'> <p key={index}>{item}</p></div>);
                            } else if (props.noReadings) {
                                const tooltipText = `${tooltip} ${e.data.values[index].readingUnits}`;
                                return (
                                    <OverlayTrigger key={index} overlay={(<Tooltip id="readingsover">{tooltipText} </Tooltip>)} placement="top-end">
                                        <div className='noreadingsTooltip'> <p key={index}>{item}</p></div>
                                    </OverlayTrigger>
                                );
                            } else {
                                const tooltipText = `${tooltip} ${e.data.values[index].readingUnits}`;
                                return (
                                    <OverlayTrigger key={index} overlay={(<Tooltip id="readingsover">{tooltipText} </Tooltip>)} placement="top-end">
                                        <div> <p key={index}>{item}</p></div>
                                    </OverlayTrigger>
                                );
                            }
                        }
                        )}
                    </>}
            </>
        );
    };

    // This function is called from displayReadingUnits   
    // params are the readingValues array.  There is a readingValues array for each type of reading
    const formatReadingUnits = (e: any) => {
        const unitsarray = e.data.values === null || e.data.values === undefined || e.data.values.length === 0 ?
            []
            :
            e.data.values.map((item: any, index: number) => {
                if (item.readingName !== DIASTOLIC) {
                    return (item.readingUnits);
                } else return '';
            });

        return unitsarray;
    };

    //  calls formatReadingsOutside to take the array of readings returned by the service and format the display for the grid
    const displayReadingsOutside = (e: any) => {

        return (
            <>
                {e.data.values === null || e.data.values === undefined || e.data.values.length === 0 ?
                    <p></p>
                    :
                    <>
                        {formatReadingsOutside(e).map((item: any, index: number) => {
                            return (
                                <p key={index}>{item}</p>
                            );
                        }

                        )}
                    </>}
            </>
        );
    };

    // This function is called from displayReadingsOutside  
    // params are the readingValues array.  There is a readingValues array for each type of reading
    const formatReadingsOutside = (e: any) => {
        const readingsarray = e.data.values === null || e.data.values === undefined || e.data.values.length === 0 ?
            []
            :
            e.data.values.map((item: any, index: number) => {
                const readingsOutside = `${item.outlierCount} of ${item.totalCount}`;
                if (item.readingName !== DIASTOLIC) {
                    return (readingsOutside);
                } else return '';
            });

        return readingsarray;
    };

    // This function is called from formatPercentOutside   
    // params are the readingValues array.  There is a readingValues array for each type of reading
    const displayPercentageReadings = (e: any) => {
        return (
            <>
                {e.data.values === null || e.data.values === undefined || e.data.values.length === 0 ?
                    <p></p>
                    :
                    <>
                        {formatPercentOutside(e).map((item: any, index: number) => {
                            return (
                                item === "0.00" || isNaN(parseInt(item)) ? "" : <p key={index}>{item}%</p>
                            );
                        })}
                    </>}
            </>
        );
    };

    // This function is called from displayPercentageReadings  
    // params are the readingValues array.  There is a readingValues array for each type of reading
    const formatPercentOutside = (e: any) => {
        const percentagearray = e.data.values === null || e.data.values === undefined || e.data.values.length === 0 ?
            []
            :
            e.data.values.map((item: any, index: number) => {
                const percentOutside = (item.outlierCount / item.totalCount * 100).toFixed(1);
                return (percentOutside);
            });
        return percentagearray;
    };

    const displaynull = () => {
        return <p></p>;
    };

    // This function is called to set the triangle icon to show above or below threshold
    const displayTriangle = (e: any) => {
        return (
            <>
                {e.data.values === null || e.data.values === undefined || e.data.values.length === 0 ?
                    <p></p>
                    :
                    <>
                        {e.data.values.map((item: any, index: number) => {
                            const thresholdarray = item.readingValues.map((value: any, valueindex: number) => {
                                let arrowdirection;
                                if (value.thresholdStatus === "ABOVE") {
                                    arrowdirection = "triangle_up";
                                } else if (value.thresholdStatus === "BELOW") {
                                    arrowdirection = "triangle_down";
                                } else {
                                    arrowdirection = "";
                                }
                                return (<p key={valueindex} style={arrowdirection === "" ? {height: '20px'}: {}} className={arrowdirection}></p>);
                            });
                            return thresholdarray;
                        }
                        )}
                    </>}
            </>
        );
    };

    const lastDataTitle = () => {
        return (props.noReadings === false ? "Last Data Outside Thresholds" : "Last Reported Data Reading");
    };

    return (
        <div>
            <div className="pdrm-data-table">

                <div>
                    <div className="d-flex">
                        <Form.Group>
                            <Form.Label>View</Form.Label><br />
                            <div className="btn-group view-toggle" role="group">
                                <input type="radio" className="btn-check" name="btnradio" id="btnAssignedPatients" onChange={e => {}} onClick={() => { getAssignedPatients(); }} checked={toggleAssignedPatients} />
                                <label className="btn btn-outline-primary" htmlFor="btnAssignedPatients">My Patients</label>
                                <input type="radio" className="btn-check" name="btnradio" id="btnPatients" onChange={e => {}} onClick={() => { getAllPatients(); }} checked={toggleAllPatients} />
                                <label className="btn btn-outline-primary" htmlFor="btnPatients">All Patients</label>
                            </div>
                        </Form.Group>
                    </div>
                    <br/>
                    <div className="d-flex" id="patientInfo">
                        <LoadPanel visible={props.showSpinner} message={"Loading..."} showPane={true} position={position} showIndicator={true}></LoadPanel>
                        <DataGrid id="grid-container"
                            keyExpr="internalID"
                            focusStateEnabled={true}
                            allowColumnResizing={true}
                            columnAutoWidth={false}
                            showBorders={false}
                            dataSource={readingsData}
                            remoteOperations={true}
                            wordWrapEnabled={true}
                            noDataText='No patients have been reported'>
                            <LoadPanel disabled={true} showIndicator={false} showPane={false}></LoadPanel>
                            <Paging defaultPageSize={10} onPageIndexChange={pageIndexChange} pageIndex={pageIndex} />
                            <GroupPanel visible={false} />
                            <Grouping autoExpandAll={false} />
                            <Column dataField="name" caption="Name" allowSorting={false} width={"13%"} cellRender={renderPatientLink} />
                            <Column dataField="medicalRecordNumber" allowSorting={false} width={"12%"} caption="MRN" />
                            <Column dataField="datareadings" allowSorting={false} width={"25%"} headerCellRender={lastDataTitle} dataType={"date"} cellRender={displayLastData} />
                            <Column dataField="readingType" allowSorting={false} width={"12%"} caption="Data Reading" alignment={'left'} cellRender={displayReadingName} />
                            <Column dataField="readingValue" allowSorting={false} width={"7%"} caption="Reading" alignment={'center'} cellRender={displayReadingReading} />
                            <Column dataField="thresholdStatus" allowSorting={false} width={"2%"} alignment={'center'} headerCellRender={displaynull} cellRender={displayTriangle} />
                            <Column dataField="units" allowSorting={false} width={"5%"} caption="Units" alignment={'left'} cellRender={displayReadingUnits} />
                            <Column dataField="internalID" allowSorting={false} caption="internalID" visible={false} />
                            {props.noReadings === false ? <Column dataField="readingsOutside" width={"10%"} alignment={'center'} allowSorting={false} caption="Readings Outside" cellRender={displayReadingsOutside} /> : null}
                            {props.noReadings === false ? <Column dataField="percentReadings" width={"10%"} alignment={'center'} allowSorting={false} caption="Pct. Readings" cellRender={displayPercentageReadings} /> : null}
                            <Pager showNavigationButtons={true} visible={true} displayMode='full' allowedPageSizes='all' showPageSizeSelector={false} />
                        </DataGrid>

                    </div>
                </div>

            </div>

            <ModalErrorMessage
                showErrorModal={showErrorModal}
                errorMessageTitle={"Filtering Patients"}
                errorMessage={"An error occurred retrieving Patients"}
                errorServerMessage={"See console for error message."}
                errorUserMessage={"Please check the filter criteria you entered, and re-try."}
                messageType="error"
                setShowErrorModal={setShowErrorModal}
            />
        </div>
    );
}
export default DataReadingsGrid;
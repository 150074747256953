/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState, useRef } from 'react';
import { Button, Container, Breadcrumb, Modal, Form } from 'react-bootstrap';
import { DataGrid, Selection, Column, Paging} from 'devextreme-react/data-grid';
import {Button as DevButton} from 'devextreme-react/data-grid';
import PermissionService from '../../Services/PermissionService';
const config = require('../../config.json');

function Teamlet(props: {
    switchView: (newView : string) => void, 
    permissions: PermissionService,
    setReturnToView: (view: string) => void;
    setSelectedTeamletID: (Id: string) => void,
    }) {
    
    //  Constants
    const [teamletList, setTeamletList] = useState<any[]>([]);
    const [teamletJSON, setTeamletJSON] = useState<any[]>([]);
    const [deleteID, setDeleteID] = useState("");
    const [showDeleteTeamletModal, setShowDeleteTeamletModal] = useState(false);
    const dgTeamlets = useRef(null);

    useEffect(() => {
        loadTeamlets();
    }, [])

    /**
     * @name loadTeamlets
     * @description Pulls all the currently enrolled users from the server and stores it in an html array
     */
    async function loadTeamlets(){
        // Start Spinner
        //@ts-ignore
        dgTeamlets.current?.instance.beginCustomLoading();

        const token = sessionStorage.getItem('ACTIVATEtoken');
        try {
             const response = await fetch(`${config.activateServer}:${config.activatePORT}/getTeamletsPlus`, {
                method: 'POST',
                body: JSON.stringify({ token : token }),
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode : 'cors'
            })

            const data = await response.json();
            setTeamletJSON(data);
        } catch(err){
            // Stop Spinner
            //@ts-ignore
            dgTeamlets.current?.instance.endCustomLoading();
            console.log("ERROR " + err);
        }

        // Stop Spinner
        //@ts-ignore
        dgTeamlets.current?.instance.endCustomLoading();
    }

    //  Delete Pop-up
    function promptDeleteTeamlet(id : string){
        showDeleteModal(id, true);
    }

    //  Delete Pop-up Toggle
    function showDeleteModal(id : string, toggle : boolean) {
        setDeleteID(id);
        setShowDeleteTeamletModal(toggle);
    }

    /**
     * @name editTeamlet
     * @description Passes the params to the Edit Teamlet UI
     * @param
     * @returns {json}
    */
    //  Edit Teamlet
    function editClick(e : any){
        //  Check for row data
        if (e.row.data !== null) {
            props.setReturnToView("teamlet");
            props.setSelectedTeamletID(e.row.data.internalID);
            props.switchView("editTeamlet");
        }
    }

    //  Delete Teamlet called from the DataGrid
    function trashClick(e : any){
        promptDeleteTeamlet(e.row.data.internalID)
    }
    
    /**
    * @name deleteTeamlet
    * @description Delete Teamlets
    * @param
    * @returns {json}
    */
    async function deleteTeamlet() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
            const response = await fetch(`${config.activateServer}:${config.activatePORT}/deleteTeamlet`, {
                method: 'POST',
                body: JSON.stringify({ 
                    token : token,
                    deleteID : deleteID
                }),
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode : 'cors'
            }).then(async response => {
                //  Check for Success
                if (response.ok)
                {
                    try {
                        const data = await response.json();
                        setDeleteID('');
                        setShowDeleteTeamletModal(false);
                        loadTeamlets();
                    } catch (e) {
                        console.log("ERROR deleting Teamlet " + JSON.stringify(e));
                    }
                } else {
                    console.log("ERROR deleting teamlet ");
                }
        })
    }

    //  Display Member Count in Grid
    function getmemberCount(e: any){
        return (
        <>
            {e.data.members.length}
        </>
        );
    }


    //  Display Pathways in Grid
    function displayPathways(e:any){
        return (
            <>
                {e.data.digitalHealthPathways.map((item: any, index : number)=><span key={index}><p>{item.name}</p></span>)}
            </>
        );
    }

    //  render
    return(
      <div>
          <Container fluid>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => props.switchView("configuration")}>Configuration</Breadcrumb.Item>
                <Breadcrumb.Item active>ACTIVATE Teamlets</Breadcrumb.Item>
              </Breadcrumb>
              <div className="page-panel">
                {props.permissions.checkSubfunctionPermission("CONFIGURATION", "STRUCTURE", "ACTIVATE_TEAMLET", "CREATE") ?
                <div className="panel-function-header">
                    <Button className="btn-function-button"
                       variant="function"
                       onClick={() => props.switchView("addTeamlet")}>Add Teamlet</Button>
                </div>
                : <></>}
                        <DataGrid id="grid-container" 
                            ref={dgTeamlets}
                            showColumnLines={false} 
                            showRowLines={false} 
                            width={"100%"}
                            showBorders={true}
                            focusStateEnabled={true}
                            dataSource={teamletJSON}
                            allowColumnResizing={true}
                            noDataText='No ACTIVATE Teamlets have been loaded'>
                                <Paging defaultPageSize={10} defaultPageIndex={0} />
                                <Selection mode="none" />
                                <Column dataField="name" width={"20%"} caption="Name" />
                                <Column dataField="pathways" width={"30%"} cellRender={displayPathways} caption="Pathways" />
                                <Column dataField="homeHealthCenter.name" width={"20%"} caption="Organization" />
                                <Column dataField="members" width={"10%"} cellRender={getmemberCount} caption="Members" alignment={"center"}  />
                                <Column dataField="assignedPatientCount" width={"10%"} caption="Assigned Patients"  alignment={"center"}/>
                                <Column type="buttons" caption="Action" width={"10%"} alignment={"center"}>
                                    <DevButton hint="edit" icon="edit" visible={true} onClick={editClick} />
                                    <DevButton hint="delete" visible={true} icon="trash" onClick={trashClick} />
                                </Column>
                                <Column dataField="internalID" caption="Id" visible={false} />
                            </DataGrid>
              </div>
            </Container>

            <Modal show={showDeleteTeamletModal}>
                <Modal.Header className="system">
                    <h2>Delete Teamlet?</h2>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this Teamlet?  This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button className="system submit" variant="primary" onClick={() => deleteTeamlet()}>Delete</Button>
                    <Button className="system cancel" variant="secondary" onClick={() => showDeleteModal("", false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>

    );
} 

export default Teamlet;
import React, { useEffect, useState } from 'react';
import { Container, Button, Form, InputGroup, Row, Col, Navbar, Nav, Card } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import RegistrationFooter from './RegistrationFooter';

const config = require('../../config.json');
const ACTIVATElogo = require('../../Resources/ACTIVATE_logo_small.png');

function LockedAccount() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [token, setToken] = useState("");
    const [strPword, setStrPword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
    const [passwordCheck, setPasswordCheck] = useState("");
    const [matchingPassword, setMatchingPassword] = useState(false);
    const [resetView, setResetView] = useState("resetPassword");

    useEffect(() => {
        let registeredToken = searchParams.get("token") ? searchParams.get("token") : "";
        //Check if the url has a token.  If not reroute to the login page
        if (registeredToken === "") {
            returnToSignIn();
        }
        checkTokenValidation(registeredToken);
        //@ts-ignore
        setToken(registeredToken);
    }, [searchParams]);

    async function checkTokenValidation(token : any){
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/validateResetToken`, {
            method: 'POST',
            body: JSON.stringify({ registeredToken : token }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        })

        try {
            const data = await response.json();
            console.log("GOT TOKEN VALIDATION " + JSON.stringify(data));
            if(data.valid === false){
                returnToSignIn();
            }
        } catch(err){
            console.log("ERROR " + err);
        }
    }

    function changeValue(event: React.ChangeEvent<HTMLInputElement>){
        if(event.target.name === "password") {
            setStrPword(event.target.value);
        } else {
            setConfirmPassword(event.target.value);
        }
    }

    function togglePassword(toggle : boolean){
        setShowPassword(toggle);
    }

    function toggleConfirmPassword(toggle : boolean){
        setShowConfirmPassword(toggle);
    }

    function validatePassword(){
        const LEGAL_PASSWORD = /(?=.*?[0-9])(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[#?!@$%()^&*-])(?=\S+$).{8,20}/;
        let isFormatCorrect = LEGAL_PASSWORD.test(strPword);
        if(!isFormatCorrect) {
            setPasswordCheck("Invalid Password Format");
            setValidPassword(false);
        } else {
            setPasswordCheck("");
            setValidPassword(true);
        }
        if(confirmPassword.length > 0){
            checkMatching();
        }
    }

    function checkMatching(){
        if (validPassword === false) {
            setPasswordCheck("Password is invalid");
        }
        else if(strPword !== confirmPassword){
            setPasswordCheck("Passwords do not match");
            setMatchingPassword(false);
        } else {
            setPasswordCheck("Passwords match");
            setMatchingPassword(true);
        }
    }

    async function setNewPassword(){
        //console.log(`FETCHING ${config.activateServer}:${config.activatePORT}/passwordUpdate`);
        const response = await fetch(`${config.activateServer}:${config.activatePORT}/passwordUpdate`, {
            method: 'POST',
            body: JSON.stringify({ token : token, password : strPword }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode : 'cors'
        })

        try {
            const data = await response.json();
            setResetView("allSet");
        } catch(err){
            console.log("ERROR " + err);
        }
    }
    
    function returnToSignIn(){
        navigate('/login', {state: {}});
    }

    return(
        <div className="activate-background">
            <Navbar id="navContainer">
                <Container>
                    <Nav>
                        <img id="ACTIVATElogo" src={ACTIVATElogo} alt="ACTIVATElogo"></img>
                    </Nav>
                </Container>
            </Navbar>
        <Container className="d-flex justify-content-center">
        {resetView === "resetPassword" ?

                    <Card className="login-card">
                        <Card.Body>
                            <Card.Title>
                                <h1>Reset password</h1>
                                <p>Forgotten your password?  No problem, you can choose a new one!</p>
                            </Card.Title>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Choose a password</Form.Label>
                                <br/>
                                <Form.Text font-face-activate-caption>Your password should have the following.
                                    <ul>
                                        <li>It contains at least 8 characters and at most 20 characters.</li>
                                        <li>It contains at least one digit.</li>
                                        <li>It contains at least one upper case alphabet.</li>
                                        <li>It contains at least one lower case alphabet.</li>
                                        <li>It contains at least one special character which includes !@#$%&*()-+=^.</li>
                                        <li>It does not contain any white space.</li>
                                    </ul>
                                </Form.Text>
                                <Form.Label className="required">Password</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Enter password"
                                        name="password"
                                        value={strPword}
                                        onChange={e => setStrPword(e.target.value)}
                                        onBlur={validatePassword}/>
                                    <Button variant="outline-secondary" id="button-addon2" onClick={() => togglePassword(!showPassword)}>
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label className="required">Confirm Password</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type={showConfirmPassword ? "text" : "password"}
                                        placeholder="Confirm password"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onBlur={checkMatching}
                                        onChange={e => setConfirmPassword(e.target.value)}/>
                                        <Button variant="outline-secondary" id="button-addon2" onClick={() => toggleConfirmPassword(!showConfirmPassword)}>
                                            <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye}/>
                                        </Button>
                                </InputGroup>
                            </Form.Group>
                            <Form.Label style={matchingPassword ? { color : 'green' } : { color : 'red'}}>{passwordCheck}</Form.Label><br/>
                            <Form.Group className="login-actions">
                                <Button className="submit-button"
                                    variant="primary"
                                    type="submit"
                                    disabled={strPword === "" || !validPassword || !matchingPassword}
                                    onClick={() => setNewPassword()}>
                                    Save Password
                                </Button>
                            </Form.Group>
                            <RegistrationFooter/>
                        </Card.Body>
                    </Card>
            :
                    <Card className="login-card">
                        <Card.Body>
                            <Card.Title>
                                <h1>All Set!</h1>
                                <p>You may continue to sign in to continue.</p>
                            </Card.Title>
                            <Form.Group className="login-actions">
                                <Button className='submit-button' onClick={() => returnToSignIn()}>Back to Sign In</Button>
                            </Form.Group>
                            <RegistrationFooter/>
                        </Card.Body>
                    </Card>
            }
        </Container>
    </div>
    )
} 

export default LockedAccount;
import React, { useEffect, useState, useRef } from 'react';
import PermissionService from '../../../Services/PermissionService';
import { DataGrid, Column, Selection, Sorting, Paging} from 'devextreme-react/data-grid';
import { Anchor } from 'react-bootstrap';
import CustomStore from 'devextreme/data/custom_store';
import { MYPATIENTS, NONEASSIGNED } from '../constants';

const config = require("../../../config.json");

function MyPatients(props:
{
  switchView: (newView: string) => void,
  permissions: PermissionService,
  setSelectedPatientID: (newId: string) => void,
})
{

const token = sessionStorage.getItem('ACTIVATEtoken');

//  Reference Grid for spinner
const dgPatients = useRef<any>(null);
const [patientList, setPatientList] = useState<any[]>([]);

const [showErrorModal, setShowErrorModal] = useState(false);

//  Paging variables
const [pageSize, setPageSize] = useState(10);
const [pageIndex, setPageIndex] = useState(0);
const [viewMode, setViewMode] = useState(MYPATIENTS);
const [patientSelected, setPatientSelected] = useState('');
const [sortBy, setSortBy] = useState('lastName');
const [sortByDirection, setSortByDirection] = useState('');
const [validationStatus, setValidationStatus] = useState('valid');
const [huddleDate, setHuddleDate] = useState('');
const [pathway, setAssignedPathway] = useState('');
    
//  Init
useEffect(() => {
  // call the function
  fetchPatientData().catch(console.error);
}, [])

//  Get Patients
const fetchPatientData = async () => {
  try {
      dgPatients.current?.instance.beginCustomLoading();
  
      let _huddleDate = huddleDate === 'undefined' ? '': huddleDate ;

      const customDataSource = new CustomStore({
          key: 'internalID',
          load(loadOptions) {
              return fetch(`${config.activateServer}:${config.activatePORT}/getPanelPatientsPaged`, {
                  method: 'POST',
                  body: JSON.stringify({ token: token, pageIndex: pageIndex, pageSize: pageSize, view: viewMode, pathwayname: pathway, name: patientSelected, sortby: sortBy, sortbydirection: sortByDirection, huddledate: _huddleDate}),
                  headers: {
                      'Content-Type': 'application/json',
                      'Accept': 'application/json',
                  },
                  mode: 'cors'
              })
              .then((response) => {
                  if (!response.ok){
                      dgPatients.current?.instance.endCustomLoading();
                      throw Error('Could not fetch the patient')
                  }
                  return response.json()
              })
              .then((data) => (
                  {
                      data: data.patients,
                      totalCount: data.totalElements,
                      summary: [],
                      groupCount: []
                  }
              ))
              .catch(() => { 
                  dgPatients.current?.instance.endCustomLoading();
                  throw new Error('Data Loading Patients'); 
              });
          },
      });

      //@ts-ignore
      setPatientList(customDataSource);

      dgPatients.current?.instance.endCustomLoading();
  } catch (err) {
      dgPatients.current?.instance.endCustomLoading();
  }
}

/**
* @name renderPatientName
* @description Properly renders the Patient's name for the grid
* @param grid row
* @returns 
*/
function renderPatientName(e:any){
  let patientName = <></>

  if (e.data != null){
    patientName = <><Anchor className='plusButton' title="Edit Patient" onClick={(e) => { viewPatientDetails(e) }} id={e.data.internalID}>{e.data.firstName + ' ' + e.data.middleName + ' ' + e.data.lastName}</Anchor></>
  }
  return (
      <p className="actions">{patientName}</p>
  )
}

//  View Patient from hyperlink onClick handler
function viewPatientDetails(e: any) {
  var patientID = e.currentTarget.id

  //  Check for found Organization
  if (e != null) {
      props.setSelectedPatientID(patientID);
      props.switchView("patientInformation");
  }
}

//  Display Pathways in Grid
const displayPathways = (e: any) => {
  return (
      <>
          {e.data.pathwayInstances === null || e.data.pathwayInstances.length === 0  ?
              <>{NONEASSIGNED}</>
          :
          <>
              {e.data.pathwayInstances.map((item: any, index: number) => <p key={index}>{item.name}</p>)}
          </>}
      </>
  );
}
return (
    <div>
          <section>
            <h2>My Patients</h2>
          </section>
          <DataGrid id="grid-container"
            ref={dgPatients}
            dataSource={patientList}
            showColumnLines={false} 
            showRowLines={false}
            showBorders={false}
            focusStateEnabled={false}
            focusedRowEnabled={false}
            autoNavigateToFocusedRow={false}
            allowColumnResizing={true} 
            showColumnHeaders={true}
            width={'100%'}
            noDataText='No Assigned Patients to this Health Provider'>
                    <Selection mode="none" />
                    <Sorting mode="single" />    
                    <Paging defaultPageSize={5} />
                    <Column dataField="internalID" caption="Id" visible={false} />
                    <Column width={'25%'} dataField="firstName" caption="Name" cellRender={renderPatientName} />
                    <Column width={'15%'} dataField="medicalRecordNumber" caption="MRN" />
                    <Column dataField="pathways" allowSorting={true} caption="Pathway" cellRender={displayPathways} />
              </DataGrid>
    </div>
    
  )
}
export default MyPatients